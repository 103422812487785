import { useState } from 'react';
import * as R from 'ramda';


export default table => {

    const [filter, setFilter] = useState([]);

    const filterInteractiveTable = table => {
        if (!table) {
            return table;
        }

        const rows = table.rows || [];
        const filteredRows = rows.filter(row =>
            !filter.find(f => f === row.values[3])
        );

        return { ...table, rows: filteredRows };
    };

    const filterTable = (value, exclude) => {
        if (exclude) {
            setFilter(allFilters => R.append(value, allFilters));
        } else {
            setFilter(allFilters => allFilters.filter(f => {
                return f !== value;
            }));
        }
    };

    return {
        filter,
        filterTable,
        filteredTable: filterInteractiveTable(table)
    };
};