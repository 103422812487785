import {store} from '../store/ProviderStore'
import * as R from 'ramda'
import { REDUCER_UPDATE_DATE } from '../constants/reducers/global-filter';


export const updateDateFilter = (page_id, startDate, endDate, module_field, reducer_name) => {
  return dispatch => {
    
    const dateRange = {
      startDate: startDate.format('YYYY-MM-DD 00:00:00'),
      endDate: endDate.format('YYYY-MM-DD 23:59:59')
    }

    let filters = {};
    filters['data_range'] = dateRange;


    return dispatch({
      type: REDUCER_UPDATE_DATE,
      payload: filters
    })
  }

}