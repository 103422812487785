import {store} from '../store/ProviderStore';
import * as R from 'ramda';
import { REDUCER_UPDATE_DEVICE } from '../constants/reducers/global-filter';

export const selectWtgAndEnvelope = (page_id, subpark_id, device_id, envelope_id, module_field, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    const returnModule = Module.map(module => {
      if (module.id === page_id) {
        const {data_range} = module.filter
        const filter = {
          data_range,
          subparks: subpark_id.constructor == Array ? subpark_id : [subpark_id],
          devices: device_id.constructor == Array ? device_id : [device_id],
          envelope_id: envelope_id.constructor == Array ? envelope_id[0] : envelope_id
        }

        return R.assoc('filter', filter, module)
      }

      return module
    })
    .map(module => {
      if (module.id === page_id) {
        const widgets = module.widgets.map(widget => {
          return R.assoc('data', null, R.assoc('update', true, widget))
        })

        return R.assoc('widgets', widgets, module)
      }

      return module
    })

    dispatch({
      type: reducer_name,
      payload: returnModule
    })
  }
}

export const selectPage = (page_id, module_field, reducer_name) => {
  return dispatch => {
    const { Dashboard, User } = store.getState()
    const { parks_info } = User || {}
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        if (module.id === page_id) {
          return R.assoc('selected', true, module)
        }

        return R.assoc('selected', false,  module)
      })
    });

    const globalFilter = Dashboard.DeviceFilter;
    const { subparks, devices } = globalFilter;

    const nextPageFiltered = Module.filter(page => page.id === page_id);
    const nextPage = nextPageFiltered.length > 0 && nextPageFiltered[0];

    
    if(nextPage && subparks && devices) {
      const payload = (nextPage.page_type && nextPage.page_type > 1) || 
        (nextPage.type && (nextPage.type === 'FILTER_TYPE_WTG' || nextPage.type === 'FILTER_TYPE_WTG_ENVELOPE')) ? 
        { subparks: subparks[0], devices: devices[0] }
      : (nextPage.type !== 'FILTER_TYPE_WTG' && nextPage.type !== 'FILTER_TYPE_WTG_ENVELOPE') 
          && subparks.length === 1 && devices.length === 1 
          && subparks[0] != null && devices[0] != null ?
        {
          subparks,
          devices: parks_info.filter(park => (
            park.subparks.filter(subpark => (
              subpark.id === subparks[0]
            )).length > 0
          ))[0].subparks.filter(subpark => (
            subpark.id === subparks[0]
          ))[0].wtgs.map(device => device.id)
        }
      :
        { subparks, devices }

      
      dispatch({
        type: REDUCER_UPDATE_DEVICE,
        payload
      })
    }
  }
}

export const updateDateFilter = (page_id, startDate, endDate, module_field, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        if (module.id === page_id) {
          const dateRange = {
            startDate: startDate.format('YYYY-MM-DD 00:00:00'),
            endDate: endDate.format('YYYY-MM-DD 23:59:59')
          }

          return R.assocPath(['filter', 'data_range'], dateRange, module )
        }

        return module
      })
    })
  }
}
