import { REDUCER_UPDATE_DEVICE } from '../../../../constants/reducers/global-filter';
import { SET_METADATA } from '../../../../actions/types';
import * as R from 'ramda'

const DEFAULT_STATE = {}


export default (state = DEFAULT_STATE, action) => {
  const {
    type
  } = action

  switch (type) {
    case REDUCER_UPDATE_DEVICE:
      return action.payload;
    case SET_METADATA:
      return state
      // return {
      //   subparks: parks_info.length > 0 && 
      //     parks_info[0].subparks.length > 0 &&
      //     parks_info[0].subparks.id,
      //   devices: parks_info.filter(park => (
      //     park.subparks.filter(subpark => (
      //       subpark.id === subparks[0]
      //     )).length > 0
      //   ))[0].subparks.filter(subpark => (
      //     subpark.id === subparks[0]
      //   ))[0].wtgs.map(device => device.id)
      // }
    default:
      return state;
  }
}
