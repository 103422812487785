import React, {Component} from 'react'
import GridLayout from 'react-grid-layout'
import grey from '@material-ui/core/colors/grey'
import {Button, TextField, Input} from '@material-ui/core'
import { Icon } from 'react-icons-kit'
import {ic_add} from 'react-icons-kit/md/ic_add'
import {ic_send} from 'react-icons-kit/md/ic_send'
import Tooltip from '@material-ui/core/Tooltip'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import * as R from 'ramda'
import uuidv4 from 'uuid/v4'
import {fileText} from 'react-icons-kit/fa/fileText'
import { toast } from 'react-toastify'

import {pageResizeHelper} from '../../../../../../../../../../helpers/page-size-helper'
import {PAGE_SIZE_A4, PAGE_SIZE_TYPE_WIDTH} from '../../../../../../../../../../constants/page-constant'
import GraphicWidgetEdit from '../../../../../../../../../../components/GraphicModel/GraphicWidgetEdit'
import graphicsType from '../../../../../../../../../../data/META/graphics-registry.json'
import { getWidgetTypeProvider, createTemplateProvider, createReportProvider, updateTemplateProvider } from '../../../../../../../../../../providers/get-report-data-provider';
import { ROUTE_MAIN_REPORTS_MANAGEMENT } from '../../../../../../../../../../constants/route-constant';
import { getDataOld } from '../../../../../../../../../../actions/DataGetOld';
import { parks_info } from '../../../../../../../../../../constants/graph-types';
import FilterComponent from '../../../../../../../../../../components/FilterComponent/FilterComponent';
import ReportsNewSelectedGridComponent from './components/ReportsNewSelectedGridComponent/ReportsNewSelectedGridComponent';
import Typography from '@material-ui/core/Typography';


const PAGE_WIDTH_DEFAULT = 1000
const PAGE_HEADER_HEIGHT = 200
const PAGE_FOOTER_HEIGHT = 200
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

class ReportsNewSelectedPageComponent extends Component {
  constructor (props) {
    super (props)

    this.pageEdit = React.createRef()

    this.state = {
      widgetsType: [],
      newDialog: false,
      generateDialog: false,
      generateDescription: '',
      templateDialog: false,
      templateDescription: this.props.edit ? this.props.edit.description : '',
      pageSize: pageResizeHelper(PAGE_SIZE_A4, PAGE_WIDTH_DEFAULT, PAGE_SIZE_TYPE_WIDTH),
      newWidgetDescription: '',
      newWidgetType: null,
      parks_info: []
    }
  }

  getWidgets = async () => {
    try {
      const data = await getWidgetTypeProvider()

      this.setState({
        widgetsType: data
      })
    } catch (error) {
      console.error('getWidgets', error)
    }
  }

  onMouseWheel = (event) => {

    if (event.ctrlKey) {
      event.preventDefault();
    }
  }

  goEditWidget = () => {}
  goRemoveWidget = () => {}

  gridLayoutChange = (positions) => {
    this.props.gridLayoutChange(positions)
  }

  componentDidMount () {
    this.pageEdit.current.addEventListener('mousewheel', this.onMouseWheel)

    getDataOld(parks_info, {})
      (({payload, type}) => {
        if (parks_info.type === type) {
          const {result} = payload
          this.setState({ parks_info: result })
        }
      })

    this.getWidgets()
  }

  componentWillUnmount () {
    this.pageEdit.current.removeEventListener('mousewheel', this.onMouseWheel)
  }

  newWidget = () => {
    this.toogleDialog('newDialog')()
  }

  openDialog = dialog => () => this.setState({ [dialog]: true, newWidgetDescription: '', newWidgetType: null })
  closeDialog = dialog => () => this.setState({ [dialog]: false, newWidgetDescription: '', newWidgetType: null })

  onNewWidgetDescription = (e) => this.setState({ newWidgetDescription: e.target.value })
  onNewWidgetType = (e) => this.setState({ newWidgetType: e.target.value })

  addWidget = () => {
    if (this.state.newWidgetType) {
      this.closeDialog('newDialog')()
  
      this.props.addWidget({
        id: uuidv4(),
        grid: {x: 0, y: 0, h: 1, w: 1},
        widgetType: this.state.newWidgetType,
        description: this.state.newWidgetDescription
      })
    }
  }






  onTemplateDescription = (e) => this.setState({ templateDescription: e.target.value })
  onGenerateDescription = (e) => this.setState({ generateDescription: e.target.value })

  template = () => {
    this.openDialog('templateDialog')()
  }

  saveTemplate = () => {


    if (this.props.edit) {

        const pages = this.props.pages.map(page => {
          const widgets = page.widgets.map(widget => {
            return {
              "position": {
                "x": widget.grid.x,
                "y": widget.grid.y,
                "h": widget.grid.h,
                "w": widget.grid.w
              },
              "widget_id": widget.widgetType.id
            }
          })

          return R.assoc('widgets', widgets)({})
        })

        const template = {
          description: this.state.templateDescription,
          id: this.props.edit.id,
          created_at: this.props.edit.created_at,
          pages
        }

        updateTemplateProvider(template)
          .then(() => {
            toast.success('Template updated successfully!', {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
            })

            this.closeDialog('templateDialog')()

            // this.props.history.push(ROUTE_MAIN_REPORTS_MANAGEMENT)
          })
          .catch(error => {
            console.error('createTemplateProvider', error)
            toast.error('Error updating!', {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            })
          })



    }else{


    if (this.state.templateDescription) {

        const pages = this.props.pages.map(page => {
          const widgets = page.widgets.map(widget => {
            return {
              "position": {
                "x": widget.grid.x,
                "y": widget.grid.y,
                "h": widget.grid.h,
                "w": widget.grid.w
              },
              "widget_id": widget.widgetType.id
            }
          })

          return R.assoc('widgets', widgets)({})
        })

        const template = {
          description: this.state.templateDescription,
          pages
        }

        createTemplateProvider(template)
          .then(() => {
            toast.success('Template saved successfully!', {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
            })

            this.closeDialog('templateDialog')()

            this.props.history.push(ROUTE_MAIN_REPORTS_MANAGEMENT)
          })
          .catch(error => {
            console.error('createTemplateProvider', error)
            toast.error('Error saving!', {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            })
          })
      } else {
        toast.error('Description is required!', {
          position: "top-right",
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      }

    }

  }

  generate = () => {
    this.openDialog('generateDialog')()
  }

  generateReport = (data) => {
    if (this.state.generateDescription) {
      const pages = this.props.pages.map(page => {
        const widgets = page.widgets.map(widget => {
          return {
            "position": {
              "x": widget.grid.x,
              "y": widget.grid.y,
              "h": widget.grid.h,
              "w": widget.grid.w
            },
            "widget_id": widget.widgetType.id
          }
        })
        
        return R.assoc('widgets', widgets)({})
      })
      
      const report = {
        description: this.state.generateDescription,
        pages,
        device_list: data.devices,
        subpark_list: data.subparks,
        range_date_start: data.date_range[0].format(),
        range_date_end: data.date_range[1].format()
      }

      createReportProvider(report)
        .then(() => {
          toast.success('Report in progress!', {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          })

          this.closeDialog('generateDialog')()

          this.props.history.push(ROUTE_MAIN_REPORTS_MANAGEMENT)
        })
        .catch(error => {
          console.error('createReportProvider', error)
          toast.error('Error saving!', {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true
          })
        })
    } else {
      toast.error('Description is required!', {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      })
    }
  }

  render () {
    const {page} = this.props


    const {newDialog} = this.state
    const {widgets: widgetsPage} = page

    const widgets = widgetsPage
      ? widgetsPage
      : []

    return (
      <div
        ref={this.pageEdit}
        style={{
          height: '100%',
          width: '100%',
          padding: '20px',
          overflow: 'auto'
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: 10,
            bottom: 10,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Tooltip
            title="New Widget"
            placement="left"
          >
            <Button
              variant="fab"
              // mini
              color="secondary"
              aria-label="Add"
              // className={classes.button}
              onClick={this.openDialog('newDialog').bind(this)}
            >
              <Icon icon={ic_add} />
            </Button>
          </Tooltip>
          <div
            style={{
              height: 20
            }}
          />
          <Tooltip
            title="Save template"
            placement="left"
          >
            <Button
              variant="fab"
              // mini
              color="secondary"
              aria-label="Add"
              // className={classes.button}
              onClick={this.template.bind(this)}
            >
              <Icon icon={fileText} size={30} />
            </Button>
          </Tooltip>
          <div
            style={{
              height: 20
            }}
          />
          <Tooltip
            title="Generate report"
            placement="left"
          >
            <Button
              variant="fab"
              // mini
              color="secondary"
              aria-label="Add"
              // className={classes.button}
              onClick={this.generate.bind(this)}
            >
              <Icon icon={ic_send} size={30} />
            </Button>
          </Tooltip>
        </div>
        <div
          style={{
            height: this.state.pageSize['height'],
            width: this.state.pageSize['width'],
            border: `1px solid ${grey[200]}`,
          }}
        >
          <div
            style={{
              height: PAGE_HEADER_HEIGHT,
              borderBottom: `1px solid ${grey['200']}`,
              textAlign:'center'
            }}
          >
          <br/>
          <br/>
          <Typography component="h2" variant="display1" gutterBottom>
              Header
            </Typography>
          </div>
          <div
            style={{
              height: (this.state.pageSize['height'] - (PAGE_HEADER_HEIGHT + PAGE_FOOTER_HEIGHT)),
              overflow: 'auto'
            }}
          >
            <ReportsNewSelectedGridComponent
              widgets={widgets}
              types={this.state.widgetsType}
              gridLayoutChange={this.gridLayoutChange.bind(this)}
              goEditWidget={this.goEditWidget}
              goRemoveWidget={this.goRemoveWidget}
              addWidget={this.props.addWidget}
              editWidget={this.props.editWidget}
              removeWidget={this.props.removeWidget}

            />
          </div>
          <div
            style={{
              height: PAGE_FOOTER_HEIGHT,
              borderTop: `1px solid ${grey['200']}`
            }}
          ></div>
        </div>
      
        <Dialog
          onClose={this.closeDialog('newDialog').bind(this)}
          aria-labelledby="dialod-reports-selected-new"
          open={newDialog}
        >
          <DialogTitle id="dialod-reports-selected-new">
            New widget
          </DialogTitle>
          <div>
            <form
              // className={classes.container}
              noValidate
              autoComplete="off"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 500,
                padding: 10
              }}
            >
              <FormControl>
                <InputLabel
                  htmlFor="age-simple"
                >
                  Widget type
                </InputLabel>
                <Select
                  value={this.state.newWidgetType}
                  onChange={this.onNewWidgetType.bind(this)}
                  inputProps={{
                    name: 'description',
                    id: 'id',
                  }}
                >
                  {R.flatten(this.state.widgetsType.map(group => group.widgets)).map((widget) => {
                    return (
                      <MenuItem
                        key={widget.id}
                        value={widget}
                      >
                        {widget.description}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <TextField
                label='Description'
                value={this.state.newWidgetDescription}
                onChange={this.onNewWidgetDescription.bind(this)}
              />
              
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginTop: 20
                }}
              >
                <Button
                  variant="contained"
                  onClick={this.addWidget.bind(this)}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Dialog>



        <Dialog
          onClose={this.closeDialog('generateDialog').bind(this)}
          aria-labelledby="dialod-reports-generate-report"
          open={this.state.generateDialog}
          // scroll={'body'}
          PaperProps={{
            style: {
              maxWidth: 'none',
              maxHeight: 'none',
              overflowX: 'hidden',
              overflowY: 'auto'
            }
          }}
        >
          <DialogTitle id="dialod-reports-generate-report">
            Generate report
          </DialogTitle>
          <div>
           <form
              // className={classes.container}
              noValidate
              autoComplete="off"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 500,
                padding: 10
              }}
            >
              <TextField
                label='Report description'
                value={this.state.generateDescription}
                onChange={this.onGenerateDescription.bind(this)}
                style={{
                  marginBottom: 20
                }}
              />

              <FilterComponent
                types={
                  R.uniq(
                    R.flatten(
                      this.props.pages.map(page => {

                        return page.widgets.map(widget => widget.widgetType.internal_filter)
                      })
                    )
                  )
                }
                confirm={this.generateReport.bind(this)}
              /> 
            </form>
          </div>
        </Dialog>

        <Dialog
          onClose={this.closeDialog('templateDialog').bind(this)}
          aria-labelledby="dialod-reports-template-report"
          open={this.state.templateDialog}
        >
          <DialogTitle id="dialod-reports-template-report">
            Save template
          </DialogTitle>
          <div>
            <form
              // className={classes.container}
              noValidate
              autoComplete="off"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 500,
                padding: 10
              }}
            >
              
              <TextField
                label='Description'
                value={this.state.templateDescription}
                onChange={this.onTemplateDescription.bind(this)}
              />
                            
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginTop: 20
                }}
              >
                <Button
                  variant="contained"
                  onClick={this.saveTemplate.bind(this)}
                >
                  Save template
                </Button>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default ReportsNewSelectedPageComponent
