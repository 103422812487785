import React from 'react'
//import Highcharts from 'highcharts'
import Highcharts from 'highcharts/highstock'

import HighchartsReact from 'highcharts-react-official'
import axios from 'axios'
import {Auth} from 'aws-amplify'
import {withStyles, Button, Menu, MenuItem, Icon, Paper, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, TextField} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { HYDRO_STYLE_HEADER_FILTER } from '../../constants/style-constant'
import { toast } from 'react-toastify'
import FilterThreeComponent from '../../../../../../components/FilterThreeComponent/FilterThreeComponent'
import * as R from 'ramda'
import styled from 'styled-components';
import { CSVLink } from "react-csv";
import XLSX from 'xlsx';


const styles = {
  button: {
    borderRadius: 0
  }
}

const TIMES_PROCEDURES = [
  ['series_history_analog_data_1h', '1h'],
  ['series_history_analog_data_3h', '3h'],
  ['series_history_analog_data_24h', '24h']
]

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
    
  }
`

const TableTdHoverStyled = styled.td`
  cursor: pointer;
  text-align: center;
`

const myExcelXML = (function() {
    let Workbook, WorkbookStart = '<?xml version="1.0"?><ss:Workbook  xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns:html="http://www.w3.org/TR/REC-html40">';
    const WorkbookEnd = '</ss:Workbook>';
    let fs, SheetName = 'SHEET 1',
        styleID = 1, columnWidth = 80,
        fileName = "Histórico", uri, link;

    class myExcelXML {
        constructor(o) {
            let respArray = JSON.parse(o);
            let finalDataArray = [];

            for (let i = 0; i < respArray.length; i++) {
                finalDataArray.push(flatten(respArray[i]));
            }

            let s = JSON.stringify(finalDataArray);
            fs = s.replace(/&/gi, '&amp;');
        }

        downLoad() {
            const Worksheet = myXMLWorkSheet(SheetName, fs);

            WorkbookStart += myXMLStyles(styleID);

            Workbook = WorkbookStart + Worksheet + WorkbookEnd;

            uri = 'data:text/xlsx;charset=utf-8,' + encodeURIComponent(Workbook);
            link = document.createElement("a");
            link.href = uri;
            link.style = "visibility:hidden";
            link.download = fileName + ".xlsx";

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        get fileName() {
            return fileName;
        }

        set fileName(n) {
            fileName = n;
        }

        get SheetName() {
            return SheetName;
        }

        set SheetName(n) {
            SheetName = n;
        }

        get styleID() {
            return styleID;
        }

        set styleID(n) {
            styleID = n;
        }
    }

    const myXMLStyles = function(id) {
        let Styles = '<ss:Styles><ss:Style ss:ID="' + id + '"><ss:Font ss:Bold="1"/></ss:Style></ss:Styles>';

        return Styles;
    }

    const myXMLWorkSheet = function(name, o) {
        const Table = myXMLTable(o);
        let WorksheetStart = '<ss:Worksheet ss:Name="' + name + '">';
        const WorksheetEnd = '</ss:Worksheet>';

        return WorksheetStart + Table + WorksheetEnd;
    }

    const myXMLTable = function(o) {
        let TableStart = '<ss:Table>';
        const TableEnd = '</ss:Table>';

        const tableData = JSON.parse(o);

        if (tableData.length > 0) {
            const columnHeader = Object.keys(tableData[0]);
            let rowData;
            for (let i = 0; i < columnHeader.length; i++) {
                TableStart += myXMLColumn(columnWidth);

            }
            for (let j = 0; j < tableData.length; j++) {
                rowData += myXMLRow(tableData[j], columnHeader);
            }
            TableStart += myXMLHead(1, columnHeader);
            TableStart += rowData;
        }

        return TableStart + TableEnd;
    }

    const myXMLColumn = function(w) {
        return '<ss:Column ss:AutoFitWidth="0" ss:Width="' + w + '"/>';
    }


    const myXMLHead = function(id, h) {
        let HeadStart = '<ss:Row ss:StyleID="' + id + '">';
        const HeadEnd = '</ss:Row>';

        for (let i = 0; i < h.length; i++) {
            const Cell = myXMLCell(h[i].toUpperCase());
            HeadStart += Cell;
        }

        return HeadStart + HeadEnd;
    }

    const myXMLRow = function(r, h) {
        let RowStart = '<ss:Row>';
        const RowEnd = '</ss:Row>';
        for (let i = 0; i < h.length; i++) {
            const Cell = myXMLCell(r[h[i]]);
            RowStart += Cell;
        }

        return RowStart + RowEnd;
    }

    const myXMLCell = function(n) {
        let CellStart = '<ss:Cell>';
        const CellEnd = '</ss:Cell>';

        const Data = myXMLData(n);
        CellStart += Data;

        return CellStart + CellEnd;
    }

    const myXMLData = function(d) {
        let DataStart = '<ss:Data ss:Type="String">';
        const DataEnd = '</ss:Data>';

        return DataStart + d + DataEnd;
    }

    const flatten = function(obj) {
        var obj1 = JSON.parse(JSON.stringify(obj));
        const obj2 = JSON.parse(JSON.stringify(obj));
        if (typeof obj === 'object') {
            for (var k1 in obj2) {
                if (obj2.hasOwnProperty(k1)) {
                    if (typeof obj2[k1] === 'object' && obj2[k1] !== null) {
                        delete obj1[k1]
                        for (var k2 in obj2[k1]) {
                            if (obj2[k1].hasOwnProperty(k2)) {
                                obj1[k1 + '-' + k2] = obj2[k1][k2];
                            }
                        }
                    }
                }
            }
            var hasObject = false;
            for (var key in obj1) {
                if (obj1.hasOwnProperty(key)) {
                    if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                        hasObject = true;
                    }
                }
            }
            if (hasObject) {
                return flatten(obj1);
            } else {
                return obj1;
            }
        } else {
            return obj1;
        }
    }

    return myExcelXML;
})();




class HydroHistoryComponent extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      plantsSelectAnchorEl: null,
      groupSelectAnchorEl: null,
      systemSelectAnchorEl: null,
      variablesSelectAnchorEl: null,
      timeSelectAnchorEl: null,
      loading: false,
      plantSelected: null,
      groupSelected: null,
      systemSelected: null,
      variablesSelected: [],
      timeSelected: null,
      plantsInfo: [],
      names:[],
      start_date : null,
      dataValuesExport:null,
      end_date: null,
      param: null,
      showExport:false,
      variableSearch: '',
      selectVariables: [],
      dataRow: [],
      orderCollum: null,
      procedure_name: 'series_history_analog_data',
      time:null,
      colors: [
        '#4C65A7',
        '#FCDC34',
        '#FC9018',
        '#4C65A7',
        '#B414DC',
        '#FCDC34',
        '#FC9018',
        '#B414DC',
        '#0A70D2'
      ],
      index: 0,
      column_sort: 'group',
      is_crescent: false

    }
  }



  componentDidMount() {
    Auth.currentSession()
      .then(({accessToken}) => {
        const {jwtToken} = accessToken

        return axios.get(`${process.env.REACT_APP_API_1}/v1/api/pch/get-data/variables`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        })
          .then(({data}) => {
            this.setState({plantsInfo: data})
          })
      })
      .catch(error => console.error('error', error))
  }

  handleChangeStart = (e) =>  {

    this.setState({ start_date: e })

  }

  handleChangeEnd = (e) =>  {

    this.setState({ end_date: e })

  }

  shouldComponentUpdate(nextProps, nextState){

      if (this.state != nextState){
        return true;
      }

      return false;
  }



  showLoading = () => (
    <div style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={(30)} />
      <div style={{
        marginTop: '20px'
      }}>
        Loading...
      </div>
    </div>
  )

  setTableDate = (data) => {

  }


  componentWillUpdate(nextProps, nextState) {

    if (nextState.plantSelected && this.state.plantSelected) { 
      if (nextState.plantSelected.plant_id != this.state.plantSelected.plant_id) {
        nextState['selectVariables'] = []
        return true
      }
    }
    else
      return false
    
  }

 

  addToSelecteds = (variable) => {

    let selecteds = this.state.selectVariables

    if (selecteds.includes(variable.id)) {
        let index = selecteds.indexOf(variable.id);
        selecteds.splice(index, 1);        
    } else {
      selecteds.push(variable.id);      
    }
    
    this.setState({ selectVariables: selecteds })

  }





  menuToggle = (param, value) => e => {
    if (value) {
      this.setState({ [param]: e.currentTarget })
    } else {
      this.setState({ [param]: null })
    }
  }

  goFilter = () => {

    if (
      this.state.plantSelected
      && this.state.selectVariables
      && this.state.start_date
    ) {



        let start_date = moment(this.state.start_date);
        let days = moment(this.state.end_date).diff(start_date, 'days') 

        if (days > 15) {

          if (days > 180 ) {

              this.setState({ procedure_name: 'series_history_analog_data_wgroupby', time:'day' })

          }else{
            
              this.setState({ procedure_name: 'series_history_analog_data_wgroupby', time:'hour'  })

          }


        } else{

          this.setState({ procedure_name: 'series_history_analog_data', time:null  })

        }


      Auth.currentSession()
        .then(({accessToken}) => {
          const {jwtToken} = accessToken
          
          let allPromisse = [];

          this.setState({ loading: true });

          this.state.selectVariables.map(ioa => {
          
                   let promise = axios.post(
                              `${process.env.REACT_APP_API_1}/v1/api/pch/get-data`, 
                              {
                                procedure_name: this.state.procedure_name,
                                plant_id: this.state.plantSelected.plant_id,
                                ioa: ioa,
                                start_time: this.state.start_date,
                                end_time: this.state.end_date,
                                time: this.state.time
                              },
                              {
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': jwtToken
                                }
                              }
                            )

                   allPromisse.push(promise)


          })


          



            Promise.all(allPromisse).then( response =>  {

                    let x_axis = [];
                    let series = [];

                    if (this.state.time) {

                      response.map(resp => {
                          x_axis = x_axis.concat(resp.data[0].series_history_analog_data_wgroupby.x_axis);
                          series = series.concat(resp.data[0].series_history_analog_data_wgroupby.series);
                      });

                    } else {

                      response.map(resp => {
                          x_axis = x_axis.concat(resp.data[0].series_history_analog_data.x_axis);
                          series = series.concat(resp.data[0].series_history_analog_data.series);
                      });

                    }

                    let names = series.map(serie => serie.name);
                    names.unshift("Tempo");

                    


                    /*
                    const params = {
                      subtitle: {
                        text: null
                      },
                      // tooltip:{
                      //   enabled:fse
                      // },
                      title: {
                        text: ''
                      },
                      subtitle: {
                        text: ''
                      },
                      plotOptions: {
                        series: {
                          turboThreshold: 1000000
                        }
                      },
                      xAxis: [{
                        categories:  R.uniq(x_axis).map(value => {
                          return this.state.time == "day" ? moment(value).tz('America/Sao_Paulo').format('DD-MM-YYYY HH:mm') : moment(value).tz('America/Sao_Paulo').format('DD-MM-YY HH:mm')
                        })
                      }],
                
                      yAxis: {
                        title: {
                          text: 'Values'
                        }
                      },
                      credits: {
                        enabled: false
                      },
                
                      series: series.map((serie, index) => {
                        return {
                          name: serie.name,
                          data: serie.values,
                          color: this.state.colors[index],
                          turboThreshold: 1000,
                          type: 'line',
                          crosshair: true



                        }
                      })
                    }*/


                  let aux_2 = 0;

                  let aux = series.map((serie, index) => 
                  { 
                    
                    if (index > 0){
                      
                      //console.log("actual", Math.max(...serie.values))

                      //console.log("anterior", Math.max(...series[index-1].values))
                      
                      if (Math.max(...serie.values) - (Math.max(...series[index-1].values)  > 50) ) {

                        aux_2 = aux_2 +1;

                      }
                    }
                    
                    return { 'index': index, 'min': Math.min(...serie.values), 'max': Math.max(...serie.values) }
                  })  


                  var aux_3 = aux.filter(function(item){
                    //COND 1 :
                    return item.max > 100;
                  });

                  const pre_proportions = response.proportions ? response.proportions : Array(aux.length).fill(1)

                  const proportion_sum = pre_proportions.reduce((acc, curr) => acc + curr)

                  const proportions = pre_proportions.map(proportion => proportion  * 100 / proportion_sum)

                  let acc = 0

                  const acc_proportions = proportions.map(proportion => {
                    acc = acc + proportion
                    return acc
                  })

                  /*const yAxis = proportions.map((proportion, index) => {
                    switch (index) {
                      case 0:
                        return {
                            labels: {
                                align: 'right',
                                x: -10
                            },
                            title: {
                                text: 'xxx',
                                style: {
                                  fontSize: 8
                                }
                            },
                            top: '0%',
                            height: `${proportion - 2}%`,
                            min: 0,
                            max: 100,
                            lineWidth: 2,
                            offset: 0
                        }
                      default:
                        return {
                            labels: {
                                align: 'right',
                                x: -10
                            },
                            title: {
                                text: 'xxxx - default',
                                style: {
                                  fontSize: 8
                                }
                            },
                            top: `${acc_proportions[index - 1]}%`,
                            height: `${proportion - 2}%`,
                            offset: 0,
                            lineWidth: 2
                        }
                    }
                  })*/
                  
                  
                  const params = {

                      rangeSelector: {
                        selected: 1,
                        buttons: [],
                        enabled: false,
                        inputEnabled: false
                      },

                      chart: {
                        type: 'line',
                        zoomType: 'xy',
                        height: aux.length <= 3 ? null : aux.length > 3 && aux.length <= 6 ? '50%' : '70%',
                      },
              
                      credits: {
                        enabled: false
                      },
              
                      title: {
                        text: ''
                      },

                      subtitle: {
                        text: ''
                      },

                      plotOptions: {
                        series: {
                          turboThreshold: 1000000,
                          showInNavigator: true
                        }
                      },

                      xAxis: [{
                        categories:  R.uniq(x_axis).map(value => {
                          return this.state.time == "day" ? moment(value).tz('America/Sao_Paulo').format('DD-MM-YYYY HH:mm') : moment(value).tz('America/Sao_Paulo').format('DD-MM-YY HH:mm')
                        })
                      }],
                

                      yAxis: series.map((serie, index) => {
                        switch (index) {
                          case 0:
                            return {
                                labels: {
                                    align: 'right',
                                    x: -10
                                },
                                title: {
                                    text: serie.name,
                                    style: {
                                      fontSize: 10
                                    }
                                },
                                top: '0%',
                                height: `${proportions[index] - 2}%`,
                                min: aux[index].min,
                                max: aux[index].max,
                                lineWidth: 2,
                                offset: 0
                            }
                          default:
                            return {
                                labels: {
                                    align: 'right',
                                    x: -10
                                },
                                title: {
                                    text: serie.name,
                                    style: {
                                      fontSize: 10
                                    }
                                },
                                min: aux[index].min,
                                max: aux[index].max,
                                top: `${acc_proportions[index - 1]}%`,
                                height: `${proportions[index] - 2}%`,
                                offset: 0,
                                lineWidth: 2
                            }
                        }
                      }),

                      
                      tooltip: {
                        split: true,
                        valueDecimals: 2
                      },

                      series: series.map((serie, index) => {
                        return {
                          name: serie.name,
                          data: serie.values,
                          color: this.state.colors[index],
                          turboThreshold: 100000,
                          type: 'line',
                          crosshair: true,
                          yAxis: index
                        }
                      })

                  }


                  const data_table = params;


                  const dataValues = data_table && x_axis ?
                     x_axis.map((axis, indexAxis) => {        

                      return R.flatten([
                       moment(axis).format("DD-MM-YYYY HH:mm"),
                        series ? series.map(serie => {
                          if(serie.type != 'pareto' && serie.values[indexAxis] != null) {
                            let value = serie.values[indexAxis]

                            if(value.constructor == Number) {
                              if(Number.isInteger(value)) {
                                return `${serie.values[indexAxis]} ${serie.unit}`
                              } else {
                                return `${serie.values[indexAxis].toFixed(1)} ${serie.unit}`
                              }
                            }
                          } 
                        }) : []
                      ])
                  }) : [];

       

                const dataValuesExport = data_table && x_axis ?
                     x_axis.map((axis, indexAxis) => {        

                      return R.flatten([
                       moment(axis).format("DD-MM-YYYY HH:mm"),
                        series ? series.map(serie => {
                          if(serie.type != 'pareto' && serie.values[indexAxis] != null) {
                            let value = serie.values[indexAxis]

                            if(value.constructor == Number) {
                              if(Number.isInteger(value)) {
                                return `${serie.values[indexAxis]}`
                              } else {
                                return `${serie.values[indexAxis]}`
                              }
                            }
                          } 
                        }) : []
                      ])
                  }) : [];

       
                    this.setState({ dataValuesExport: dataValuesExport })
                    this.setState({ dataValues: dataValues })
                    this.setState({ param: params })
                    this.setState({ loading: false })       
                    this.setState({ names: names })




            }).catch(error => {

                this.setState({ loading: false })

            })

        })
        .catch(error => console.error('error', error))

    } else {

      toast.error('Necessário selecionar todos os parâmetros!', {
        position: "top-right",
        // autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        // draggable: true
      })

    }
  }


  setParam = (param, value, callback = null) => {
    this.setState({
      [param]: value
    }, () => {
      if (callback) {
        callback()
      }
    })
  }

  setFilter(params) {   
    let order = !this.state.is_crescent
    this.setState({index: params.index, column_sort: params.column_sort, is_crescent: order})    
  }

  orderRows(rows, index, column_sort, is_crescent) {

    let aux_table = []
    let sortOrder = is_crescent ? -1 : 1


    rows.group.length > 1 && (rows.group.map((group, gropupIndex) => {
        group.system.map((system, systemIndex) => {
          system.variables.map((variable, variableIndex) => {
            aux_table.push({'group': group.tag, 'system': system.tag, 'variable': variable.name, 'variable_id': variable.id})
          })
        }) 
      }))

    aux_table.length > 0 && (aux_table.sort((a, b) => {
      
          if(sortOrder == -1) {
              return b[column_sort].localeCompare(a[column_sort]);
          } else {
              return a[column_sort].localeCompare(b[column_sort]);
          }  

      }))
    
    return aux_table

  }




 closeDialogExportOverviewExcel = () => {
  



    let values = this.state.dataValuesExport.map((data, index) => {
        let value = {}
       this.state.names.map((name, Nameindex) => {
          value[name] =   data[Nameindex]
       })

      return value;   
    })


    let worksheet = XLSX.utils.json_to_sheet(values);
    let new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");

    XLSX.writeFile(new_workbook, 'Histórico.xlsx') 


    this.setState({
      showExport: false
    })
  }


 closeDialogExportOverview = () => {
  
    this.setState({
      showExport: false
    })
  }

  goExport() {
    this.setState(state => {
      return {
        showExport: !state.showExport
      }
    })
  }


  render() {
    const {classes} = this.props
    
   if (this.state.loading) {
      return (
          <Paper className={classes.paperElement} elevation={0}>
              {this.showLoading()}
          </Paper>
        )
  }

  let aux = this.state.plantSelected
  let sorted_table = null;

  if(this.state.index >= 0) {
    if(aux) {
      sorted_table = this.orderRows(aux, this.state.index, this.state.column_sort, this.state.is_crescent)
    }
  } 


    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <div
          style={{
            height: HYDRO_STYLE_HEADER_FILTER,
            width: '100%',
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                height: '100%',
                width: `100%`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingLeft: 20,
                paddingRight: 20
              }}
            >
              <div
                style={{
                  border: `1px solid ${grey['200']}`
                }}
              >

                <Button
                  className={classes.button}
                  onClick={this.menuToggle('plantsSelectAnchorEl', true).bind(this)}
                >
                  {this.state.plantSelected ? this.state.plantSelected.plant : 'Selecionar Usina'}
                </Button>
                <Menu
                  id='plants-select-menu'
                  anchorEl={this.state.plantsSelectAnchorEl}
                  open={Boolean(this.state.plantsSelectAnchorEl)}
                  onClose={this.menuToggle('plantsSelectAnchorEl', false).bind(this)}
                >
                  {
                    this.state.plantsInfo.map((plant, indexPlant) => {
                      return (
                        <MenuItem
                          key={indexPlant}
                          onClick={e => {
                            this.menuToggle('plantsSelectAnchorEl', false)(e)
                            this.setState({
                              plantSelected: plant,
                              tables: this.setTableDate(plant),
                              groupSelected: null,
                              systemSelected: null,
                              variablesSelected: []
                            })
                          }}
                        >
                          {plant.plant}
                        </MenuItem>
                      )
                    })
                  }
                </Menu>

                {
                  this.state.plantSelected 
                  //  ?  this.state.plantSelected.group.length > 1 
                    ?
                        (
                        <React.Fragment>
                          <Button
                            className={classes.button}
                            onClick={this.menuToggle('dialogOpenVariables', true).bind(this)}
                            style={{
                              borderLeft: `1px solid ${grey['200']}`
                            }}
                          >
                            {this.state.groupSelected ? this.state.groupSelected.tag :  this.state.selectVariables.length > 0 ? `Selecionadas ${this.state.selectVariables.length}`: 'Selecionar variáveis'}
                          </Button>
                        </React.Fragment>
                        ) 
                    //  : null
                    : null
                }
                        

                <DatePicker
                    customInput={<Button className={classes.button}>  {this.state.start_date? moment(this.state.start_date).format("DD-MM-YYYY HH:mm") : 'Data Inicial'  } </Button>}
                    showTimeSelect
                    selectsStart
                    selected={this.state.start_date}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="time"
                    onChange={this.handleChangeStart}
                    maxDate={new Date()}

                />

                <DatePicker
                    customInput={<Button className={classes.button}> {this.state.end_date? moment(this.state.end_date).format("DD-MM-YYYY HH:mm") : 'Data Final'  } </Button>}
                    showTimeSelect
                    selectsEnd
                    selected={this.state.end_date}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    onChange={this.handleChangeEnd}
                    timeCaption="time"
                    maxDate={new Date()}
                    popperPlacement="bottom-start"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                          boundariesElement: 'viewport'
                        }
                      }}

   

                />


                <Button
                  className={classes.button}
                  onClick={this.goFilter.bind(this)}
                  style={{
                    borderLeft: `1px solid ${grey['200']}`,
                    backgroundColor: 'orange',
                    color: 'white'
                  }}
                >
                  Filtrar
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: `calc(100% - ${HYDRO_STYLE_HEADER_FILTER}px)`
          }}
        >
          <div
            style={{
              height: '80%',
              width: '100%'
            }}
          >
            {
              this.state.param ?
              (<div>
              
                <div style={{float:"right", marginRight: 8}}  >         

                    <Button
                      color="primary"
                      onClick={this.closeDialogExportOverviewExcel}
                      style={{backgroundColor:"green", color:"#fff", margin:4}}
                      autoFocus
                    >
                      Excel
                    </Button>


                  <CSVLink 
                    style={{textDecoration:"none"}}
                    filename={"Histórico.csv"}
                    onClick={this.closeDialogExportOverview}
                    data={this.state.dataValuesExport}  
                    headers={this.state.names}

                  >
                    <Button
                      color="primary"
                      variant="contained" 
                      style={{backgroundColor:"#e65100", color:"#fff"}}
                    >
                      CSV
                    </Button>
                </CSVLink>
                </div>

                <HighchartsReact
                  containerProps={{
                    style: {
                      width: '100%',
                      height: '100%',
                      //marginTop: 30,
                      overflow: 'hidden'
                    }
                  }}
                  // ref={this.refChart}
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={this.state.param}
                />
                </div>
              )
              : null
            }
          </div>
        </div>



        <Dialog
          aria-labelledby="dialog-studio-filter-variables-title"
          aria-describedby='dialog-studio-filter-variables'
          maxWidth={false}
          open={this.state.dialogOpenVariables}

        >
          <DialogTitle
            id="dialog-studio-filter-variables-title"
          >
            Selecionar Variáveis
            <div
              style={{
                minWidth: 600,
                // minHeight: 56
              }}
            >

              <TextField
                id='dialog-studio-filter-variables-text'
                label='Buscar...'
                margin="normal"
                className={classes.textField}
                value={this.state.variableSearch}
                onChange={e => this.setParam('variableSearch', e.target.value)}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                maxHeight: '400px',
                width: '100%'
              }}
            >
              <TableStyled>
                <thead>
                  <tr>
                    <th>
                      X
                    </th>
                    <th style={{textAlign: 'center', margin: 2}}>
                      <Button onClick={this.setFilter.bind(this, {index: 0, column_sort: 'group'})} mini style={{
                        paddingRight: '30px', fontWeight: 'bold'
                      }}>
                        Grupo
                        <Icon>arrow_drop_down</Icon>
                      </Button>
                    </th>
                    <th style={{textAlign: 'center', margin: 2}} >                       
                      <Button onClick={this.setFilter.bind(this, {index: 1, column_sort: 'system'})} mini style={{
                        paddingRight: '30px', fontWeight: 'bold'
                      }}>
                        Sistema
                        <Icon>arrow_drop_down</Icon>
                      </Button> 
                    </th>
                    <th style={{textAlign: 'center', margin: 2}}>                       
                      <Button onClick={this.setFilter.bind(this, {index: 2, column_sort: 'variable'})} mini style={{
                        paddingRight: '30px', fontWeight: 'bold'
                      }}>
                        Variável
                        <Icon>arrow_drop_down</Icon>
                      </Button> 
                    </th>
                  </tr>
                </thead>
                <tbody>
                 {

                    sorted_table != null ? sorted_table.filter(element => {
                             
                        if (this.state.variableSearch.length < 3) {
                          return true
                        }

                        if (this.state.variableSearch === `${element.variable_id}`) {
                          return true
                        }

                        const variableName = R.toUpper(element.variable)
                        const variableSearch = R.toUpper(this.state.variableSearch)

                        if (variableName.search(variableSearch) >= 0) {
                          return true
                        } else {
                          return false
                        }
                      
                    }).map((element, elementIndex) => {  

                      return(
                            <tr  
                              onClick={() => this.addToSelecteds({'name': element.variable, 'id': element.variable_id})}
                            >
                              <td style={{textAlign: 'center', margin: 2}}>
                                  {   
                                    this.state.selectVariables.includes(element.variable_id)
                                    ?
                                      (
                                        <Icon>
                                          check_box
                                        </Icon>
                                      )
                                    :
                                      (
                                        <Icon>
                                          check_box_outline_blank
                                        </Icon>
                                      )
                                  }
                                  </td>
                              <td style={{textAlign: 'center', margin: 2}}>
                                {element.group} 
                              </td>                                 
                              <td style={{textAlign: 'center', margin: 2}}>
                                {element.system} 
                              </td>
                              <td style={{textAlign: 'center', margin: 2}}>
                                {element.variable} 
                              </td>
                         </tr>
                        )

                      
                    })   
                    
                    : 
                    
                    null


                  //this.state.plantSelected ? this.state.plantSelected.group.map((group, groupIndex) => { 
                 /* sorted_table ? sorted_table.map((group, groupIndex) => {
                       return group.system.map((system, systemIndex) => { 
                           return system.variables.filter(variable => {
                             
                                if (this.state.variableSearch.length < 3) {
                                  return true
                                }

                                if (this.state.variableSearch === `${variable.id}`) {
                                  return true
                                }

                                const variableName = R.toUpper(variable.name)
                                const variableSearch = R.toUpper(this.state.variableSearch)

                                if (variableName.search(variableSearch) >= 0) {
                                  return true
                                } else {
                                  return false
                                }


                        }).map((variable, variableIndex) => { 


                                return(
                                  <tr  
                                    onClick={() => this.addToSelecteds(variable)}
                                  >
                                    <td style={{textAlign: 'center', margin: 2}}>
                                     {   this.state.selectVariables.includes(variable.id)
                                        ?
                                        (
                                          <Icon>
                                            check_box
                                          </Icon>
                                        )
                                        :
                                        (
                                          <Icon>
                                            check_box_outline_blank
                                          </Icon>
                                        )
                                      }
                                      </td>
                                      <td style={{textAlign: 'center', margin: 2}}>
                                        {group.tag} 
                                      </td>                                 
                                      <td style={{textAlign: 'center', margin: 2}}>
                                        {system.tag} 
                                      </td>
                                      <td style={{textAlign: 'center', margin: 2}}>
                                        {variable.name} 
                                      </td>
                                   </tr>
                                  )

                             })

                         })

                  } ) : null  */



                 }
                </tbody>
              </TableStyled>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setParam('dialogOpenVariables', false)}
            >
              Fechar
            </Button>
         </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(HydroHistoryComponent)
