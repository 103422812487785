import { Auth } from 'aws-amplify';
import axios from 'axios';
import { URL_NODE_API } from '../constants/url-constant';

export const createNotificationsAnchor = async (anchorDate) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_NODE_API}/user/notification/ring-a-bell`;

    try {
        const { data } = await axios.post(URL, 
            {
                anchorDate
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            }
        );

        const result = data;
        console.log('On getting current notifications', result)
        
        return result;

    } catch (err) {
        console.log('On getting current notifications error', err);
        return false;
    }
}

export const getNotifications = async () => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_NODE_API}/user/notification/ring-a-bell`;

    try {
        const { data } = await axios.get(URL, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        });
        
        const result = data;
        
        return result;

    } catch (err) {
        console.log('On getting current notifications error', err);
        return false;
    }
}

export const getAllNotifications = async () => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_NODE_API}/user/notification/notifications-history`;

    try {
        const { data } = await axios.get(URL, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        });
        const result = data;
        
        return result;

    } catch (err) {
        console.log('On getting current notifications error', err);
        return false;
    }
}

export const setNotificationsCheckpoint = async () => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_NODE_API}/user/notification/checkpoint`;

    try {
        const { data } = await axios.post(URL, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        });
        const result = data;
        
        return result;

    } catch (err) {
        console.log('On setting checkpoint error', err);
        return false;
    }
}