import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import moment from 'moment-timezone'
import {
  withStyles
} from '@material-ui/core'
import GridLayout from 'react-grid-layout'
import * as R from 'ramda'

import WidgetDriver from '../Driver/WidgetDriver'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'


const GRID_WIDTH_PADDING = 20

const styles = theme => ({
  gridElement: {
    width: '100%'
  }
})

class PagePopUp extends Component {
  constructor (props) {
    super (props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    this.state = {
      gridWidth: (elBody.offsetWidth - (GRID_WIDTH_PADDING * 2) - 10)
    }

    this.gridContainerAnchor = React.createRef();
    this.currentDateRange = null;
    this.prevDeviceId = null;
  }

  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={true}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

  // render () {

  //   const {classes, Device, parks, history} = this.props
  //   const deviceSelected = R.find(R.propEq('selected', true))(Device)
  //
  //   if (!deviceSelected) {
  //     return (
  //       <DeviceContent>
  //         <h3>Trying to get page...</h3>
  //       </DeviceContent>
  //     )
  //   }
  //
  //   const {widgets, filter} = deviceSelected;
  //
  //   if(!this.prevDeviceId) {
  //     this.prevDeviceId = deviceSelected.id;
  //     this.currentDateRange = filter.data_range;
  //   } else if(this.prevDeviceId != deviceSelected.id) {
  //     this.prevDeviceId = deviceSelected.id;
  //     deviceSelected.filter.data_range = this.currentDateRange;
  //   } else {
  //     this.currentDateRange = filter.data_range;
  //   }
  //
  //   return (
  //     <DeviceContent>
  //       <BaseGlobalFilter
  //         mode={'view'}
  //         history={history}
  //         page={deviceSelected}
  //         reducer_name={REDUCER_DEVICE_GET_DATA}
  //         module_field={'Device'}
  //       />
  //
  //       <div
  //         style={{
  //           padding: '0 0 0 15px'
  //         }}
  //       >
  //         {this.state.gridWidth ? (
  //           <GridLayout
  //             className={classes.gridElement}
  //             cols={12}
  //             rowHeight={200}
  //             items={50}
  //             width={this.state.gridWidth}
  //             compactType={'horizontal'}
  //             isDraggable={false}
  //             isResizable={false}
  //           >
  //             {
  //               widgets.map((widget, index) => {
  //                 return (
  //                   <div style={{
  //                     overflow: 'hidden'
  //                   }} key={widget.id} data-grid={widget.grid} >
  //                     <WidgetDriver key={`${index}${widget.id}`}
  //                       classes={classes}
  //                       selectedPage={deviceSelected}
  //                       parks={parks}
  //                       history={history}
  //                       widget={widget}
  //                       reducer_name={REDUCER_DEVICE_GET_DATA}
  //                       module_id={'Device'}
  //                     />
  //                   </div>
  //                 );
  //               })
  //             }
  //           </GridLayout>
  //         ) : '' }
  //       </div>
  //     </DeviceContent>
  //   )
//   }
// }

const mapStateToProps = () => {
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PagePopUp))
