import { store } from '../store/ProviderStore';

// export const getCurrentUserIdFromStore = (paramsUsers) => {
//     const { userId } = paramsUsers || {};
//     const Users = (paramsUsers && paramsUsers.Users) || store.getState().Users;
//     console.log(Users, paramsUsers)
//     const filteredUser = Object.values(Users).filter(user => user.isAuthenticated);
//     const id = userId || (filteredUser.length > 0 && filteredUser[0].id); 
//     return id;
// }

// export const getCurrentUserFromStore = (paramsUsers) => {
//     const id = getCurrentUserIdFromStore(paramsUsers.Users);
//     console.log('Params users', paramsUsers.Users, 'CURR USER ID', id);
//     return id ? paramsUsers.Users[id] : null;
// }

export const getAuthedUserId = () => {
    const { AuthedUser } = store.getState();
    return AuthedUser;
}

export const getAuthedUser = () => {
    const { AuthedUser, Users } = store.getState();
    return AuthedUser && Users[AuthedUser];
}