import React, {Component} from 'react'

class FilterParkComponent extends Component {
  render () {
    return (
      <div>
        FilterParkComponent
      </div>
    )
  }
}

export default FilterParkComponent
