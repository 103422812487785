import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment-timezone';
import ClickOutside from 'react-click-outside';
import * as R from 'ramda';
import { toast }  from 'react-toastify';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

import styles from './style';

class DatePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            open: false,
            startDate: moment().subtract(8, 'days').format('YYYY-MM-DD'),
            endDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
            keyDate: 'rangeDate',
            maxDate: null,
            selectionRange: []
        };
    }

    handleClick = event => {
        const { currentTarget } = event;
        const { page } = this.props;
        const { startDate, endDate } = this.state;
        page.data_range = { startDate, endDate };

        this.setState(prevState => ({
            anchorEl: currentTarget,
            page: page,
            open: !prevState.open,
        }));
    }

    close = event => {
        event.stopPropagation();

        this.props.executeUpdate();
        this.props.executeFilter(this.state.page);

        this.setState({
            open: false,
        });
    }

    handleSelect = data => {
        const { parksInfo, forcedMaxDate } = this.props;
        const { rangeDate } = data;

        const currEndDate = forcedMaxDate || parksInfo[0] && parksInfo[0].t_end;

        let startDate = moment(rangeDate.startDate).startOf('day');
        let endDate = moment(rangeDate.endDate).startOf('day');
        const dbEndDate = moment(currEndDate).startOf('day');

        if (startDate > dbEndDate || endDate > dbEndDate) {
            if(startDate > dbEndDate && endDate > dbEndDate) {
                this.showError(dbEndDate);
            }

            startDate = startDate > dbEndDate ? dbEndDate : startDate;
            endDate = endDate > dbEndDate ? dbEndDate : endDate;
        }  

        const range = {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
        };

        this.props.handleChangeDates(range)
    }

    showError = lastDate => {
        const aux = lastDate.format('YYYY-MM-DD');
        const message = "Out of range! End date: " + aux;

        toast.info(message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 3000,
            draggablePercent: 60
        });
    }

    getMaxDate = () => {
        const { parksInfo } = this.props;

        const maxDate = !parksInfo || (
            parksInfo.constructor === Array 
            && parksInfo.length === 0
        )
        ? new Date()
        : parksInfo[0].t_end;


        return maxDate;
    }

    formatMaxDate = (date) => {
        
        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        let endDate = new Date(date);
        return endDate;


    }

    updateDates = ({ startDate, endDate }) => {
        this.setState(prevState => {
            const currStartDate = startDate || prevState.startDate;
            const currEndDate = endDate || prevState.endDate;

            const maxDate = this.getMaxDate();
            const selectionRange = [{
                startDate: moment(currStartDate).startOf('day').toDate(),
                endDate: moment(currEndDate).startOf('day').toDate(),
                key: this.state.keyDate,
            }];
            
            return {
                startDate: currStartDate, 
                endDate: currEndDate,
                maxDate: maxDate,
                selectionRange: selectionRange,
            }
        });
    }

    componentDidMount() {
        this.updateDates(this.props.getDatesUpdated());
    }

    componentWillUpdate(nextProps, nextState) {
        const { startDate, endDate } = nextProps.getDatesUpdated();
        const maxDate = this.getMaxDate();
        const selectionRange = [{
            startDate: moment(startDate).startOf('day').toDate(),
            endDate: moment(endDate).startOf('day').toDate(),
            key: this.state.keyDate,
        }];

        nextState.startDate = startDate;
        nextState.endDate = endDate;
        nextState.maxDate = maxDate;
        nextState.selectionRange = selectionRange;
    }

    render() {
        const { classes, forcedMaxDate } = this.props;
        const { startDate, endDate, maxDate, open, anchorEl, selectionRange } = this.state;
        
        return (
            <div className={classes.container} >
                <Button
                    className={classes.buttonHandler}
                    aria-describedby={open && 'simple-popper'}
                    variant='contained'
                    disableRipple="true"
                    onClick={this.handleClick}
                    disabled={this.props.disabled}
                    disableRipple="true"
                >
                    <div className={classes.dateTextContainer} >
                        <Icon className={classes.dateIcon} >date_range</Icon>
                        <div className={classes.dateRangeItem} >
                            {startDate.split(' ')[0]}
                        </div>
                        <div 
                            className={classes.dateRangeItem} 
                            style={{
                                padding: '0 5px',
                            }}
                        >
                            <Icon className={classes.dashIcon}>remove</Icon>
                        </div>
                        <div className={classes.dateRangeItem} >
                            {endDate.split(' ')[0]}
                        </div>
                    </div>
                </Button>
                <Popper
                    id={open && 'simple-popper'}
                    open={open}
                    anchorEl={anchorEl}
                    placement={this.state.placement || 'bottom'}
                    transition
                    className={classes.popperContainer}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <ClickOutside onClickOutside={this.close.bind(this)}>
                                <Paper>
                                    <DateRangePicker
                                        ranges={selectionRange}
                                        months={2}
                                        direction='horizontal'
                                        onChange={this.handleSelect}
                                        maxDate={this.formatMaxDate(forcedMaxDate || maxDate)}
                                    />
                                </Paper>
                            </ClickOutside>
                        </Fade>   
                    )}
                </Popper>  
            </div>
        );
    }
}

DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles({...DatePicker.props}))(DatePicker);
