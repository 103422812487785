import styled from 'styled-components'

import { AppFull } from '../../../../AppStyle';
// import { STYLE_HEADER_TOOLBAR, STYLE_SUBHEADER_TOOLBAR } from '../../../../constants/style-constant';

const EnvelopeContent = styled(AppFull)`
  height: inherit;
`

export {EnvelopeContent}
