import { store } from '../store/ProviderStore';
import history from '../history';
import {
  CHANGE_ROUTE,
  REDUCER_NAVIGATION_SELECTED_MODULE,
  REDUCER_NAVIGATION_SELECTED_SUBMODULE,
  FROM_PAGE
} from '../constants/reducers/navigation-events';
import variables from '../data/META/navigation-variables';

import { NAVIGATE } from '../actions/types';

export const moduleSelected = (selected_id) => {
  const module_depth = variables.filter(variable => variable.name == 'MODULE_DEPTH');
  const depth = module_depth.length > 0 ? module_depth[0].value : 2;

  return dispatch => {
    dispatch({
      type: REDUCER_NAVIGATION_SELECTED_MODULE,
      payload: {
        module_id: selected_id,
        module_depth: depth
      }
    })
  }
}

export const subModuleSelected = (selected_id) => {
  const submodule_depth = variables.filter(variable => variable.name == 'SUBMODULE_DEPTH');
  const depth = submodule_depth.length > 0 ? submodule_depth[0].value : 3;

  return dispatch => {
    dispatch({
      type: REDUCER_NAVIGATION_SELECTED_SUBMODULE,
      payload: {
        submodule_id: selected_id,
        submodule_depth: depth
      }
    })
  }
}

export const goToPage = (route) => {
  history.push(route);

  return dispatch => {
    dispatch({
      type: CHANGE_ROUTE,
      payload: route
    })
  }
}

export const setFromPage = (from) => {

  return dispatch => {
    dispatch({
      type: FROM_PAGE,
      payload: from
    })
  }
}


// New logics!!!!

// export const navigate = (toModule, modules) => ({
//     type: NAVIGATE,
//     toModule,
//     modules
// });

export const navigate = moduleId => {
  const { Modules } = store.getState();
  const modulesFiltered = Modules && Modules.filter(mod => mod.id === moduleId);
  const submodulesFiltered = modulesFiltered && modulesFiltered.length === 0 && Modules.map(mod => (
    mod.submodules
  )).reduce((acc, curr) => (
    [...acc, ...curr]
  ), []).filter(submodule => submodule && submodule.id === moduleId);

  const preToModule = modulesFiltered.length > 0 ? modulesFiltered : submodulesFiltered;
  const toModule = preToModule && preToModule.length > 0 && preToModule[0];

  return ({
    type: NAVIGATE,
    toModule,
    modules: Modules
  })
};