import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import * as R from 'ramda'

const styles = theme => ({
  itemAsset: {
    padding: '10px 0',
    borderBottom: `1px solid ${grey['200']}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: grey['200']
    }
  }
})

const ASSET_MODEL_ID = 'yuiasoduasyidjasbdhujasbdhyuiasjkdjhausidjkhjsauidjbashdjk'

class InventoryAssetsSidebarComponent extends Component {
  getPerParent = (assets) => {
    const assetsModelId = R.uniq(assets.map(asset => asset.model_id))

    const assetsModel = assetsModelId.map(modelId => {
      const assetsFilter = assets.filter(item => item.model_id === modelId)

      const assetModel = R.head(assetsFilter)

      return {
        id: modelId,
        name: assetModel.model,
        assets: assetsFilter
      }
    })

    return assetsModel
  }

  showAssets = (id) => () => {
    const el = window.document.getElementById(`${ASSET_MODEL_ID}${id}`)
    const elArrowRightIcon = window.document.getElementById(`ArrowRightIcon${ASSET_MODEL_ID}${id}`)
    const elArrowDropDownIcon = window.document.getElementById(`ArrowDropDownIcon${ASSET_MODEL_ID}${id}`)

    if (el.style.display === 'none') {
      el.style.display = 'inline'
      elArrowRightIcon.style.display = 'none'
      elArrowDropDownIcon.style.display = 'inline-block'
    } else {
      el.style.display = 'none'
      elArrowRightIcon.style.display = 'inline-block'
      elArrowDropDownIcon.style.display = 'none'
    }
  }

  render () {
    const {classes, assets} = this.props

    const assetModels = this.getPerParent(assets)

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'auto'
        }}
      >
        <div
          style={{
            height: 'calc(100% - 20px)',
            width: 'calc(100% - 20px)'
          }}
        >
          {assetModels.map(assetModel => {
            return (
              <div
                key={assetModel.id}
              >
                <div
                  className={classes.itemAsset}
                  onClick={this.showAssets(assetModel.id).bind(this)}
                >
                  <ArrowRightIcon
                    id={`ArrowRightIcon${ASSET_MODEL_ID}${assetModel.id}`}
                  />
                  <ArrowDropDownIcon
                    id={`ArrowDropDownIcon${ASSET_MODEL_ID}${assetModel.id}`}
                    style={{
                      display: 'none'
                    }}
                  />
                  <div
                    style={{
                      flex: 1,
                      paddingLeft: '5px'
                    }}
                  >
                    {assetModel.name}
                  </div>
                </div>
                <div
                  id={`${ASSET_MODEL_ID}${assetModel.id}`}
                  style={{
                    display: 'none',
                    marginLeft: '10px'
                  }}
                >
                  {assetModel.assets.map(asset => {
                    return (
                      <div
                        key={asset.id}
                        className={classes.itemAsset}
                        onClick={() => this.props.selectAsset(asset)}
                      >
                        <div
                          style={{
                            flex: 1,
                            paddingLeft: '5px'
                          }}
                        >
                          {asset.name}
                        </div>
                        <ArrowRightIcon />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(InventoryAssetsSidebarComponent)
