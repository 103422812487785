import {store} from '../store/ProviderStore'
import * as R from 'ramda'
import {
  sendTablesAlarms, sendTablesDowntimes, executeBaseRoutine
} from '../providers/crud-status-provider'
import moment from 'moment-timezone'

import { addCheckpoint } from '../providers/get-data-api-n'

export const savePreferences = (props) => {


  const { Dashboard } = store.getState()

  if(window.location.pathname === '/dashboard/pch/monitor') {
    // const payload = localStorage.getItem('test1')
    
    // return addCheckpoint(payload);
  }

  return addCheckpoint(props)
}

export const clearPreferences = () => {
  return addCheckpoint(null, null, true)
}

export const sendAlarmsToSave = (alarm_tables, module_field, page_id, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()

    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    const getPage = Module.filter(page => page_id == page.id)
    const tables = getPage.length > 0 ? getPage[0].data : null

    if(tables) {
      const old_tables_formatted = tables.map(old_table => ({
        device_id: old_table.device_id,
        name: old_table.name,
        data: old_table.rows ?
          old_table.rows.map(row => ({
            "id": row.id,
            "row": [
              row.classification_id,
              moment.tz(row.start_time, 'America/Fortaleza').format(),
              row.comments ? row.comments.map(comment => comment.comment) : null
            ]
          }))
        :
          []
      }))

      const new_tables_formatted = alarm_tables.map(new_table => ({
        device_id: new_table.device_id,
        name: new_table.name,
        data: new_table.rows.map(row => ({
          "id": row.id,
          "row": [
            row.classification_id,
            moment.tz(row.start_time, 'America/Fortaleza').format(),
            row.comments ? row.comments.map(comment => comment.comment) : null
          ]
        }))
      }))

      sendTablesAlarms(old_tables_formatted, new_tables_formatted)
      .then(result => {
        if(result) {
          dispatch({
            type: reducer_name,
            payload: Module.map(page => {
              if(page_id == page.id) {
                page.data = null
              }

              return page
            })
          })
        } else {
          dispatch({
            type: reducer_name,
            payload: Module.map(page => {
              if(page_id == page.id) {
                page.data = page.data.map((device_table, index) => ({
                  ...device_table,
                  rows: tables[index].rows
                }))
              }

              return page
            })
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
    }
  }
}

export const sendDowntimesToSave = (downtime_tables, module_field, page_id, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()

    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    const getPage = Module.filter(page => page_id == page.id)
    const tables = getPage.length > 0 ? getPage[0].data : null

    if(tables) {
      const old_tables_formatted = tables.map(old_table => ({
        device_id: old_table.device_id,
        name: old_table.name,
        data: old_table.rows ?
          old_table.rows.map(row => ({
            "id": row.id,
            "row": [
              moment.tz(row.start_time, 'America/Fortaleza').format(),
              moment.tz(row.end_time, 'America/Fortaleza').format(),
              row.duration,
              row.alarm_id,
              row.system_id,
              row.responsibility_id,
              row.classification_id,
              row.comments ? row.comments.map(comment => comment ? comment.comment : '') : null
            ]
          }))
        :
          []
      }))

      const new_tables_formatted = downtime_tables.map(new_table => ({
        device_id: new_table.device_id,
        name: new_table.name,
        data: new_table.rows.map(row => ({
          "id": row.id,
          "row": [
            moment.tz(row.start_time, 'America/Fortaleza').format(),
            moment.tz(row.end_time, 'America/Fortaleza').format(),
            row.duration,
            row.alarm_id,
            row.system_id,
            row.responsibility_id,
            row.classification_id,
            row.comments ? row.comments.map(comment => comment ? comment.comment : '') : null
          ]
        }))
      }))

      sendTablesDowntimes(old_tables_formatted, new_tables_formatted)
      .then(result => {
        if(result) {
          dispatch({
            type: reducer_name,
            payload: Module.map(page => {
              if(page_id == page.id) {
                page.data = null
              }

              return page
            })
          })
        } else {
          dispatch({
            type: reducer_name,
            payload: Module.map(page => {
              if(page_id == page.id) {
                page.data = page.data.map((device_table, index) => ({
                  ...device_table,
                  rows: tables[index].rows
                }))
              }

              return page
            })
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
    }
  }
}

const crudStatusBaseRoutine = (route, procedure_name, module_field, module_id, reducer_name, dispatch) => {
  const {Dashboard} = store.getState()
  const Module = module_field in Dashboard ? Dashboard[module_field] : []
  const module = R.find(R.propEq('id', module_id))(Module)

  const {filter} = module

  const params = {
    procedure_name: procedure_name,
    time_range: [
      filter.data_range.startDate,
      filter.data_range.endDate
    ],
    timezone: "America/Fortaleza",
    subpark_list: new Array(...new Set(filter.devices.map(value => String(value).slice(0, 2)))).map(value => parseInt(value)),
    device_list: filter.devices
  }

  executeBaseRoutine(route, params)
  .then(result => {
    if(result) {
      dispatch({
        type: reducer_name,
        payload: Module.map(page => {
          if(module_id == page.id) {
            page.data = null
          }

          return page
        })
      })
    } else {
      dispatch({
        type: reducer_name,
        payload: Module
      })
    }
  })
  .catch(error => {
    console.error(error)
  })
}

export const resetAlarmsData = (module_field, page_id, reducer_name) => {
  return dispatch => (crudStatusBaseRoutine('reset_data', 'reset_status_data', module_field, page_id, reducer_name, dispatch))
}

export const resetDowntimesData = (module_field, page_id, reducer_name) => {
  return dispatch => (crudStatusBaseRoutine('reset_data', 'reset_downtime_data', module_field, page_id, reducer_name, dispatch))
}

export const recalculateFromStatus = (module_field, page_id, reducer_name) => {
  return dispatch => (crudStatusBaseRoutine('reset_data', 'recalculate_from_status_data', module_field, page_id, reducer_name, dispatch))
}
