import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as R from "ramda";
import { Auth } from "aws-amplify";

import PropTypes from "prop-types";

import * as ROUTES from "../../constants/route-constant";
import HeaderComponent from "../../components/Header/HeaderComponent";
import HeaderPlaceholder from "../../components/Placeholder/HeaderPlaceholder";
import { store, persistor } from "../../store/ProviderStore";
import { getDynamoClientMeta } from "../../providers/dynamo-client-meta-provider";
import {
    setMetaData,
    setLastCheckpoint
} from "../../actions/RetrieveDataAction";
import { getDataOld } from "../../actions/DataGetOld";
import { getActualState } from "../../providers/get-data-api-n";
import { parks_info } from "../../constants/graph-types";
import HeaderAllComponent from "../../components/HeaderAllComponent/HeaderAllComponent";
import {
    STYLE_SUBHEADER_TOOLBAR,
    STYLE_HEADER_TOOLBAR
} from "../../constants/style-constant";

import DefaultErrorHandler from "../../components/Handler/DefaultErrorHandler";
import { Menu, MenuItem, Button, CircularProgress } from "@material-ui/core";
import EditableContainer from '../../components/Container/EditableContainer'

import AppContext from "../../App"
import { ScrollState } from '../../cache';

import { ROUTE_AUTH } from '../../constants/route-constant';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMetadata, needsMetadata, getOldParksInfo, getModels } from '../../providers/meta-provider';
import { getNotifications, getAllNotifications } from '../../providers/user-provider';
import { deviceHierarchy } from '../../helpers/data-conforming-helper';
import {
    updateEnvelopeFilter,
    updateEnvelopeFilterByDevice
} from "../../actions/EnvelopeFilterActions";


import CircularLoading from '../../components/Loading/CircularLoading';

import { setMetadata } from '../../actions/ClientActions';
import { logout } from '../../actions/AuthAction';
import { navigate } from '../../actions/NavigationActions';
import { NoPrint } from '../../components/Print';

import {
    Icon
} from '@material-ui/core';

class Dashboard2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prevPath: '',
            editMode: false,
            update: false,
            loginError: false
        };
    }


    setDynamoData(dynamoData) {

        const data = dynamoData.Items[0]
        const config = { ...dynamoData.Items[0] }
        store.dispatch(setMetaData(data))

        const pagesToTreat = Object.keys(data).filter(key => (
            data[key].hasOwnProperty('filters') &&
            data[key].hasOwnProperty('pages') &&
            data[key].hasOwnProperty('decorators')
        ));

        pagesToTreat.map(key => (
            data[key] = data[key].pages.map(page => ({
                ...page,
                filter: data[key].filters.hasOwnProperty('per_page')
                    && data[key].filters.per_page.filter(perPage => perPage.page_id === page.id).length === 1
                    ? data[key].filters.per_page.filter(perPage => perPage.page_id === page.id)[0].content
                    : data[key].filters.global,
                widgets: page.widgets.map(widget => {
                    const decorators = data[key].decorators.filter(dec => dec.widget_id === widget.id)[0];
                    return {
                        ...widget,
                        preProcessDecorators: decorators && decorators.preProcessDecorators,
                        postProcessDecorators: decorators && decorators.postProcessDecorators,
                    }
                })
            }))
        ));



        const delfos1_0_pagesToTreat = Object.keys(data.delfos_1_0).filter(key => (
            data.delfos_1_0[key].hasOwnProperty('filters') &&
            data.delfos_1_0[key].hasOwnProperty('pages') &&
            data.delfos_1_0[key].hasOwnProperty('decorators')
        ));

        delfos1_0_pagesToTreat.map(key => (
            data.delfos_1_0[key] = data.delfos_1_0[key].pages.map(page => ({
                ...page,
                filter: data.delfos_1_0[key].filters.hasOwnProperty('per_page')
                    && data.delfos_1_0[key].filters.per_page.filter(perPage => perPage.page_id === page.id).length === 1
                    ? data.delfos_1_0[key].filters.per_page.filter(perPage => perPage.page_id === page.id)[0].content
                    : data.delfos_1_0[key].filters.global,
                widgets: page.widgets.map(widget => {
                    const decorators = data.delfos_1_0[key].decorators.filter(dec => dec.widget_id === widget.id)[0];
                    return {
                        ...widget,
                        preProcessDecorators: decorators && decorators.preProcessDecorators,
                        postProcessDecorators: decorators && decorators.postProcessDecorators,
                    }
                })
            }))
        ));

        return [data, config];
    }

    async componentDidMount() {
        try {
            const dataOld = await getDataOld(parks_info, {});
            store.dispatch(dataOld);

            // setTimeout(async () => {
            const {
                setMetadata,
                Client,
                AuthedUser,
                Notifications,
                currUser
            } = this.props;

            const { metadataScore } = Client || {};
            const hasMetadataChanged = true;
            const metadata = await getMetadata();

            const config = await Promise.resolve(this.getData());

            setMetadata({
                meta: metadata,
                config,
                // notifications, 
                Client,
                AuthedUser
            });
        } catch (err) {
            if (err === 'No current user' || (err.code && err.code === 'NotAuthorizedException')) {
                const { currUser, logout } = this.props;
                logout(currUser && currUser.id, true);
            }
        }
    }
    
    getData = async () => {
        try {
            const dynamoData = await getDynamoClientMeta();

            if (!dynamoData) {
                return null;
            }

            const [data, config] = this.setDynamoData(dynamoData);

            const userPreferences = await getActualState();
            const payload = userPreferences &&
                userPreferences.state &&
                Object.keys(userPreferences.state).length > 0 &&
                {
                    checkpoint: userPreferences.state
                }

            if (payload) {
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        this.setState({
                            ...payload.checkpoint,
                            update: false,
                            user: user.attributes
                        });
                    })
                    .catch(data => {
                        this.setState({
                            ...data,
                            update: false,
                            user: {}
                        })
                    })
            } else {
                Auth.currentAuthenticatedUser()
                    .then(user => {
                        this.setState({
                            ...data,
                            update: false,
                            user: user.attributes
                        });
                    })
                    .catch(data => {
                        this.setState({
                            ...data,
                            update: false,
                            user: {}
                        })
                    })
            }

            return config;
        } catch (err) {
            return null;
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ prevPath: this.props.location })
        }
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    toggleEdtitMode = () => {

        this.setState({ editMode: !this.state.editMode });
    };

    updateProps = (props, value) => {

        let state = this.state;
        state[props] = value
        this.setState({ state });

    };

    moduleRender = Component => props => (
        <DefaultErrorHandler>
            <EditableContainer {...this.state} updateProps={this.updateProps}>
                <Component
                    updateProps={this.updateProps}
                    {...props}
                    {...this.state}
                    {...this.props}
                />
            </EditableContainer>
        </DefaultErrorHandler>
    );


    render() {
        const { modules, user, loginError } = this.state;
        const { pages, Client } = this.props;

        const { metadataScore } = Client || {};

        if (loginError) {
            setTimeout(() => { window.location = '/' }, 1000);

            return (
                <div
                    style={{
                        height: 'inherit',
                        width: '100%',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                    }}
                >
                    <Icon
                        style={{
                            fontSize: '25em',
                            color: '#dbdbdb'
                        }}
                    >
                        no_encryption
                    </Icon>
                    <h3>Unrecognized authed user.</h3>
                </div>
            )
        }

        if (this.state.management && metadataScore != null) {

            return (
                <div
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            height: 'auto',
                            width: '100%',
                            position: 'sticky',
                            top: 0,
                            zIndex: 9999,
                            transform: 'translateY(0)'
                        }}
                    >
                        {
                            modules && (
                                <HeaderAllComponent
                                    id='dashboard-header'
                                    modules={modules}
                                    {...this.props}
                                    {...this.state}
                                    user={user}
                                    toggleEdtitMode={this.toggleEdtitMode}
                                    editMode={this.state.editMode}
                                    data={this.state}
                                />
                            )
                        }
                    </div>

                    <div
                        className="scrollPage"
                    >
                        {
                            Object.keys(this.state).length > 0 && (
                                pages.map((router, indexRouter) => (
                                    <React.Fragment
                                        key={indexRouter}
                                    >
                                        <Route
                                            path={router.path}
                                            render={this.moduleRender(router.component)}
                                        />
                                    </React.Fragment>
                                ))
                            )
                        }
                        {
                            modules && modules.length > 0 && modules.map((itemModule, itemModuleIndex) => (
                                itemModule.submodules && itemModule.submodules.length > 0 && itemModule.route !== R.head(itemModule.submodules).route && (
                                    <Route
                                        key={itemModuleIndex}
                                        exact path={itemModule.route}
                                        render={(props) => (
                                            <Redirect
                                                to={{
                                                    pathname: R.head(itemModule.submodules).route,
                                                    state: { from: props.location }
                                                }}
                                            />
                                        )}
                                    />
                                )
                            )).filter(item => item)
                        }
                        {
                            modules && modules.length > 0 && (
                                <React.Fragment>
                                    <Route
                                        exact path={ROUTES.ROUTE_DASHBOARD}
                                        render={(props) => (
                                            <Redirect
                                                to={{
                                                    pathname: R.head(modules).route,
                                                    state: { from: props.location }
                                                }}
                                            />
                                        )}
                                    />
                                </React.Fragment>
                            )
                        }
                    </div>

                    <NoPrint>
                        <h5
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                textTransform: 'uppercase',
                                color: '#999',
                                padding: '30px 0'
                            }}
                        >Version 2.21.0</h5>
                    </NoPrint>
                </div>
            )

        } else {

            // this.getData();
        }

        return (
            <CircularLoading>
                <span>Getting all metainformation!</span>
            </CircularLoading>
        )
        // return (
        //     <div style={{
        //         position: 'absolute',
        //         height: '100%',
        //         width: '100%',
        //         top: 0,
        //         left: 0,
        //         backgroundColor: 'rgba(255,255,255,.5)',
        //         display: 'flex',
        //         flexDirection: 'column',
        //         justifyContent: 'center',
        //         alignItems: 'center'
        //     }}>
        //         <CircularProgress size={(30)} />
        //         <div style={{
        //             marginTop: '20px'
        //         }}>
        //             Loading...
        //         </div>
        //     </div>
        // )


    }
}
Dashboard2.contextType = AppContext;

const mapStateToProps = ({
    AuthedUser,
    Users,
    Navigation,
    Modules,
    Client,
    Notifications,

    // User
}) => {
    const currUser = Users[AuthedUser];
    const { currModule } = Navigation;

    // const { parks_info: parksInfo } = User;

    return ({
        currUser,
        currModule,
        Modules,
        Client,
        Notifications,
        AuthedUser,

        // parksInfo
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setMetadata,
    updateEnvelopeFilter,
    logout,
    navigate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard2);
