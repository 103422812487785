import React from 'react';
import BaseGlobalFilter from '../../../../components/Header/BaseGlobalFilter';
import CalendarPage from './CalendarPage';
import StatisticsPage from './StatisticsPage';
import usePagesManager from './hooks/usePagesManager';


export default props => {
    const filter = usePagesManager(props);
    const { page, data_range } = filter;

    const renderSelectedPage = () => {
        switch (page.id) {
            case 7001:
                return <StatisticsPage {...props} data_range={data_range} />;
            default:
                return <CalendarPage {...props} />;
        };
    };

    return (
        <>
            <div style={{ maxWidth: '98vw' }}>
                <BaseGlobalFilter {...filter} {...props} />
                {renderSelectedPage()}
            </div>
        </>
    );
};