import React from 'react'
import OnlyPrint from '../OnlyPrint'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import footer from './img/footer.png'
import mainLogo from '../../../assets/reports_images/main_logo.png'

const PrintFooter = ({ classes }) => (
    <OnlyPrint>
        <div className={classes.footer}>
            <img className={classes.footerBackground} src={footer} />
            <img className={classes.footerLogo} src={mainLogo} />
        </div>
    </OnlyPrint>
)

export default withStyles(styles)(PrintFooter)