import React, {Component} from 'react'

import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import * as moment from 'moment'
import static_data from './data_2.json';

import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  LinearProgress,
  Menu,
  Paper,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider,
  Icon,
  Dialog,
  DialogTitle, 
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';

import styles from './styles/styles.js';
import { CSVLink } from "react-csv";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#bdbdbd',
    color: theme.palette.common.black,
    //fontSize: 16,
  },
  body: {
    //fontSize: 14,
  },
}))(TableCell);

class GraphicInteractiveTableHydro3 extends Component {
  constructor (props) {
    super (props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      colors: [
        '#99ff66',
        '#F4645C',  //red
        '#B414DC',
        '#FCDC34',  // yellow
        '#4C65A7',
        '#FC9018',
        '#0A70D2',
        '#99ff66'   // green
      ],
      columns: [],
      column_sort: null,
      is_crescent: false,
      tableKeys : [],
      data:null,
      headers:{availability:0, availability_array:[]}



    }

    this.chartWillRender = this.chartWillRender.bind(this);
    // this.chartDidRender = this.chartDidRender.bind(this);
  }

  componentDidMount () {
    this.props.getSettings(this.getSettings.bind(this));
  }


   showLoading = () => (
    <div style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={(30)} />
      <div style={{
        marginTop: '20px'
      }}>
        Loading...
      </div>
    </div>
  )

  // chartDidRender (state, updating, loading) {
  //   let postProcessDecorators = this.props.graphic.postProcessDecorators;
  //
  //   if(postProcessDecorators) {
  //
  //     setTimeout(() => {
  //       if(!updating && !loading) {
  //         setTimeout(() => {
  //           postProcessDecorators.map(decorator_json => {
  //             const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
  //             const result = decorator(state);
  //
  //             state = result;
  //           });
  //         }, 3000);
  //       } else {
  //         this.chartDidRender(state, updating, loading);
  //       }
  //     }, 200)
  //   }
  // }

  chartWillRender(state) {
    // let preProcessDecorators = this.props.graphic.preProcessDecorators;
    //
    // if(preProcessDecorators) {
    //   preProcessDecorators.map(decorator_json => {
    //     const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
    //     const result = decorator(state);
    //
    //     state = result;
    //   });
    // }
  }

  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = (checkbox, extra_columns) => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);

    if(extra_columns) {
      // ;
      this.setState({prevColumns: this.state.columns});
      this.state.columns = this.state.columns.map((column, index) => (
        extra_columns[String(index)] ? `${column}/\n${extra_columns[String(index)]}` : column
      ));
    }
  };

  caulculateFontSizeHead(grid){


  //   if (grid.h < 2 || grid.w < 2) {
  //       return "7px";
  //   }

  //   if (grid.w < 6) {
  //       return "7px";
  //   }

  //  if (grid.w < 8) {
  //       return "10px";
  //   }

    return "7px";

  }



  shouldComponentUpdate(nextProps, nextState){

    let {items} =  nextProps;
    let event_name = nextProps.widget.event.concat('#', nextProps.subpark);
    let old_event_name = this.props.widget.event.concat('#', this.props.subpark);

    if (this.props.subpark != nextProps.subpark || this.props.showTable != nextProps.showTable || this.props.showExport != nextProps.showExport){
      return true;
    }


    if (items[event_name]) {

      if (items[event_name] ===  this.props.items[old_event_name]) {

          return false;
      }

        return true;

    }

    return false;

  }


  getSettings() {
    // let data = BAR_DECORATOR_LIST.map(decorator => (
    //   this.state[`switch${decorator.name}`] ?
    //   {
    //     name: `${decorator.name}`,
    //     params: 'params_post_treatment' in decorator && decorator.params_post_treatment ?
    //               decorator.params_post_treatment(
    //                 Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //               )
    //               : Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //   }
    //   : null
    // )).filter(decorator => decorator);
    //
    // return data;
  }

  setFilter(params) {
    let order = !this.state.is_crescent
    this.setState({column_sort: params.index, is_crescent: order})
  }



  groupBy(collection, property, date = false) {
    var i = 0, val, index,
        values = [], result = [];
    for (; i < collection.length; i++) {
      
        val = collection[i][property];
      
      if (date) {
        val = new Date(val).toISOString().replace('T', ' ').split('.')[0].slice(0,16); 
      }
        index = values.indexOf(val);
        if (index > -1)
            result[index].push(collection[i]);
        else {
            values.push(val);
            result.push([collection[i]]);
        }
    }
    return result;
  }

  caulculateFontSizeHead(grid){


  //   if (grid.h < 2 || grid.w < 2) {
  //       return "7px";
  //   }

  //   if (grid.w < 6) {
  //       return "9px";
  //   }

  //  if (grid.w < 8) {
  //       return "10px";
  //   }

    return "8px";

  }


  orderRows(rows, column_sort, is_crescent) {
    const sort_anchor = rows.map((row, index) => {

      return {idx: index, sort_value: row.values[column_sort]}
    }).sort((a, b) => {
      if(a.sort_value == 'red' && (b.sort_value == 'green' || b.sort_value == 'yellow')) {
        return is_crescent ? 1 : -1
      } else if (b.sort_value == 'red' && (a.sort_value == 'green' || a.sort_value == 'yellow')) {
        return is_crescent ? -1 : 1
      } else if(a.sort_value == 'yellow' && b.sort_value == 'green') {
        return is_crescent ? 1 : -1
      } else if(b.sort_value == 'yellow' && a.sort_value == 'green') {
        return is_crescent ? -1 : 1
      } else if(a.sort_value == 'green' && b.sort_value == 'yellow') {
        return is_crescent ? -1 : 1
      } else if(b.sort_value == 'green' && a.sort_value == 'yellow') {
        return is_crescent ? 1 : -1
      } else if(a.sort_value == b.sort_value && ['green', 'yellow', 'red'].indexOf(a.sort_value) >= 0) {
        return 0
      } else {
        return a.sort_value < b.sort_value ?
          is_crescent ? -1 : 1
        :
        a.sort_value > b.sort_value ?
          is_crescent ? 1 : -1
        :
          0
      }
    })

    return rows.map((row, index) => rows[sort_anchor[index].idx])
  }

  render () {
    const {classes, showTable, showSettings, graphic, loading, font_size, items, subpark, showExport} = this.props;



    let {headers, data} = this.state;
    // let {headers} = this.state;
    // let static_data = 

    headers = [{"name":'Periodo'}, {"name":'Produzido'},{"name":"Meta"}];

    const {id, widths} = graphic;
    const left_cell_width = 1;
    const max_cells_width = 12 - left_cell_width;




    if (subpark == 1) {
      if (items["table_history_act_power_accumulation_over_the_year#1"]) {
        data = items["table_history_act_power_accumulation_over_the_year#1"];
      }   
    }
    
    if (subpark == 2) {
      if (items["table_history_act_power_accumulation_over_the_year#2"]) {
        data = items["table_history_act_power_accumulation_over_the_year#2"];
      }   
    }
       
    if (!data) {
      return (
          <Paper className={classes.paperElement} elevation={0}>
              {this.showLoading()}
          </Paper>
        )
    }



    return (
              <div>
                <Table className={classes.table}>            
                  <TableHead>
                      <TableRow>
                          {headers.map((key,index) => (
                           <CustomTableCell style={{backgroundColor:'white', paddingTop:'5px', color: 'black', textAlign:'center', paddingLeft:'5px', fontSize:'9px'}}><h3><strong>{key.name}</strong></h3></CustomTableCell>    
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className={classes.row}>
                        <CustomTableCell style={{fontSize:'9px', color: 'black', textAlign:'center', paddingLeft:'3px'}}>Diário</CustomTableCell>    
                        <CustomTableCell style={{fontSize:'9px', color: 'black', textAlign:'center', paddingLeft:'3px'}}>{ parseFloat(data.energia_day).toFixed(2)}</CustomTableCell>    
                        <CustomTableCell style={{fontSize:'9px', color: 'black', textAlign:'center', paddingLeft:'3px'}}>{ parseFloat(data.goal_day).toFixed(2)}</CustomTableCell>    
                      </TableRow>
                      <TableRow className={classes.row} style={{fontSize:'9px'}}>
                        <CustomTableCell style={{fontSize:'9px', color: 'black', textAlign:'center', paddingLeft:'3px'}}>Mensal</CustomTableCell>    
                        <CustomTableCell style={{fontSize:'9px', color: 'black', textAlign:'center', paddingLeft:'3px'}}>{parseFloat(data.energia_month).toFixed(2)}</CustomTableCell>    
                        <CustomTableCell style={{fontSize:'9px', color: 'black', textAlign:'center', paddingLeft:'3px'}}>{parseFloat(data.goal_month).toFixed(2)}</CustomTableCell>    
                      </TableRow> 
                      <TableRow className={classes.row} style={{fontSize:'9px'}}>
                        <CustomTableCell style={{fontSize:'9px', color: 'black', textAlign:'center', paddingLeft:'3px'}}>Anual</CustomTableCell>    
                        <CustomTableCell style={{fontSize:'9px', color: 'black', textAlign:'center', paddingLeft:'3px'}}>{parseFloat(data.energia_year).toFixed(2)}</CustomTableCell>    
                        <CustomTableCell style={{fontSize:'9px', color: 'black', textAlign:'center', paddingLeft:'3px'}}>{parseFloat(data.goal_year).toFixed(2)}</CustomTableCell>    
                      </TableRow> 

                    </TableBody>
                  </Table>

                  <Dialog
                    onClose={this.props.closeDialogExportOverview}
                    open={showExport}
                    aria-labelledby="dialog_overview_export"
                  >
                    <DialogTitle id="dialog_overview_export">
                      Export Graphic Data
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Please, select the file format to export.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>


                        <Button
                          color="primary"
                          style={{backgroundColor:"green", color:"#fff"}}
                          autoFocus
                          onClick={(e) => this.props.closeDialogExportOverviewExcel([
                            ["Diário", parseFloat(data.energia_day), parseFloat(data.goal_day)],
                            ["Mensal", parseFloat(data.energia_month), parseFloat(data.goal_month)],
                            ["Anual", parseFloat(data.energia_year), parseFloat(data.goal_year)]
                          ],["Periodo", "Produzido", "Meta"], "Produção" )}

                        >
                          Excel
                        </Button>


                      <CSVLink 
                        style={{textDecoration:"none"}}
                        filename={"Produção"}
                        onClick={this.props.closeDialogExportOverview}
                        headers={["Periodo", "Produzido", "Meta"]}
                        data={[
                            ["Diário", parseFloat(data.energia_day), parseFloat(data.goal_day)],
                            ["Mensal", parseFloat(data.energia_month), parseFloat(data.goal_month)],
                            ["Anual", parseFloat(data.energia_year), parseFloat(data.goal_year) ]
                          ]}  
                      >
                        <Button
                          color="primary"
                          variant="contained" 
                          style={{backgroundColor:"#e65100", color:"#fff"}}
                        >
                          CSV
                        </Button>
                      </CSVLink>

                      <Button onClick={this.props.closeDialogExportOverview} color="primary">
                        Back
                      </Button>
                    </DialogActions>
                  </Dialog>

                </div>
    )
  }
}

export default withStyles(styles)(GraphicInteractiveTableHydro3);
