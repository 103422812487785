import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as HighchartsMore from 'highcharts/js/highcharts-more'
import { PaginatorTable } from '../../../new-components/Table';
import * as moment from 'moment'
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List
} from '@material-ui/core';
import TimeIntervalSlider from '../../../modules/Dashboard/pages/EventsPage/components/TimeIntervalSlider'
import SliderTooltipLabel from '../../../modules/Dashboard/pages/EventsPage/components/SliderTooltipLabel'
import { } from '../../../'

import styles from './styles/styles.js';
import NoPrint from '../../../components/Print/NoPrint'

import { withTranslation } from 'react-i18next';

const TimeToDateSliderLabel = SliderTooltipLabel(val => new Date(val).toISOString().split('.')[0].replace('T', ' '));

Highcharts.setOptions({
  global:
  {
    useUTC: false
  },
});

HighchartsMore(Highcharts);
Highcharts.Renderer.prototype.symbols.line = function (x, y, width, height) {
  return ['M', x, y + width / 2, 'L', x + height, y + width / 2];
};

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#bdbdbd',
    color: theme.palette.common.black,
    //fontSize: 16,
  },
  body: {
    //fontSize: 14,
  },
}))(TableCell);

class GraphicColumnRange extends Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      colors: [
        '#C0D2FC',
        '#F4645C',
        '#B414DC',
        '#FCDC34',
        '#4C65A7',
        '#FC9018',
        '#0A70D2',
        '#99ff66'
      ],
      first_load: false
    }

    this.chartWillRender = this.chartWillRender.bind(this);
    // this.chartDidRender = this.chartDidRender.bind(this);
  }

  componentDidMount() {
    this.props.getSettings(this.getSettings.bind(this));

    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }

  chartWillRender(state) {

  }

  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = checkbox => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);
  };



  caulculateFontSizeHead(grid) {


    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }


  getSettings() {
  }

  render() {
    const { classes, showTable, showSettings, graphic, pageInfo, font_size, t } = this.props;
    const { data } = graphic;
    const { startDate, endDate } = pageInfo.filter.data_range;

    var on_render_state = {
      ...this.state,
      data: data
    };

    if (on_render_state.data.x_axis) {
      on_render_state.data.x_axis = on_render_state.data.x_axis.map(row => row == 'Average Generator Speed 10M vs Generator Speed St. Dev. 10M' ? 'Generator Speed I'
        : row == 'Average Active Power 10M vs Trafo 3 winding temperature 10M' ? 'Trafo Temperature III'
          : row == 'Average Active Power 10M vs Average Reactive Power 10M' ? 'Reactive Power'
            : row == 'Average Active Power 10M vs Bearing N.D.E. Temperature 10M' ? 'NDE Bearing Temperature'
              : row == 'Average Active Power 10M vs Average Stator Active Power 10M' ? 'Stator Act. Power'
                : row == 'Average Rotor Speed 10M vs Rotor Speed St. Dev. 10M' ? 'Rotor Speed I'
                  : row == 'Average Active Power 10M vs Trafo 2 winding temperature 10M' ? 'Trafo Temperature II'
                    : row == 'Average Active Power 10M vs Generator windings temperature 2 10M' ? 'Gen. Windings Temperature II'
                      : row == 'Average Active Power 10M vs Generators sliprings temperature 10M' ? 'Gen. Slipring'
                        : row == 'Average Active Power 10M vs Generator windings temperature 3 10M' ? 'Gen. Windings Temperature III'
                          : row == 'Average Active Power 10M vs Gearbox bearing temperature 10M' ? 'Gearbox Bearing Temperature'
                            : row == 'Average Active Power 10M vs Average Rotor Speed 10M' ? 'Rotor Speed II'
                              : row == 'Average Wind Speed 10M vs Average Active Power 10M' ? 'Measured Power Curve'
                                : row == 'Average Active Power 10M vs Average Generator Speed 10M' ? 'Generator Speed II'
                                  : row == 'Average Generator Speed 10M vs Average Rotor Speed 10M' ? 'Rotor Speed III'
                                    : row == 'Average Active Power 10M vs Bearing D.E. Temperature 10M' ? 'DE Bearing Temperature'
                                      : row == 'Average Active Power 10M vs Average Grid Voltage 10M' ? 'Grid Voltage'
                                        : row == 'Average Active Power 10M vs Yaw Misalignment = (WTG Nacelle Position) - (MetMast Wind Dir)' ? 'Yaw Misalignment'
                                          : row == 'Average Active Power 10M vs Gearbox oil temperature 10M' ? 'Gearbox Oil'
                                            : row == 'Average Active Power 10M vs Hidraulic group pressure 10M' ? 'Hidraulic Group'
                                              : row == 'Average Active Power 10M vs Average Pitch Angle 10M' ? 'Pitch Angle'
                                                : row == 'Average Active Power 10M vs Generator windings temperature 1 10M' ? 'Gen. Windings Temperature I'
                                                  : row == 'Average Active Power 10M vs Trafo 1 winding temperature 10M' ? 'Trafo Temperature I'
                                                    : row
      );
    }

    let series_classes = on_render_state.data.series ? [...new Set(on_render_state.data.series.map(alarm => alarm.z))] : [];

    let series_values = series_classes.map((class_name, index) => (
      {

        name: class_name == 'green' ? 'Model OK' : class_name == 'orange' || class_name == 'yelow' ? 'Warning' : class_name == 'red' ? 'Critical' : class_name == 'blue' ? 'Intervention' : class_name,

        color: graphic && graphic.id === 30 ? 'red' : class_name == 'green' || class_name == 'Information' ? '#10ac84' : class_name == 'yellow' || class_name == 'Warning' ? '#FCDC34' : class_name == 'red' || class_name == 'Stop' ? '#ee5253' : class_name == 'orange' || class_name == 'Power Limitation' ? '#ff9f43' : class_name == 'Intervention' ? '#1a8cff' : class_name,

        data: on_render_state.data.series.map(ocurrence => (
          ocurrence.z == class_name ?
            {
              x: ocurrence.x,
              type_data: class_name == 'green' ? 'Model OK' : class_name == 'orange' ? 'Warning' : class_name == 'red' ? 'Critical' : class_name == 'blue' ? 'Intervention' : class_name,
              low: Date.parse(moment.utc(ocurrence.y_start).tz("America/Fortaleza")),
              high: Date.parse(moment.utc(ocurrence.y_end).tz("America/Fortaleza")),
              type: 'datetime',
              tooltip: ocurrence.tooltip ?
                ocurrence.tooltip.constructor == Array ?
                  ocurrence.tooltip.map(tooltip => (
                    `<b>${tooltip.key}</b>: ${tooltip.value}<br>`
                  )).join('\n')
                  :
                  ocurrence.tooltip
                :
                `<h3>${on_render_state.data.x_axis[ocurrence.x]}</h3>
                      <h4>${class_name}</h4>
                      <b>Start Time</b>: ${moment(ocurrence.y_start).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')} America/Fortaleza<br>
                      <b>End Time</b>: ${moment(ocurrence.y_end).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')} America/Fortaleza`,
              pointPadding: 0,
              color: ocurrence.color == 'green' || ocurrence.color == 'Information' ? '#10ac84' : ocurrence.color == 'yellow' || ocurrence.color == 'Warning' ? '#fffa65' : ocurrence.color == 'red' || ocurrence.color == 'Stop' ? '#ee5253' : ocurrence.color == 'orange' || ocurrence.color == 'Power Limitation' ? '#ff9f43' : ocurrence.color == 'blue' || ocurrence.color == 'Intervention' ? '#1a8cff' : ocurrence.color
            }
            :
            null
        )).filter(result => result),
      }
    ));



    if (!series_classes[0]) {


      series_classes = on_render_state.data.series ? [...new Set(on_render_state.data.series.map(alarm => alarm.text))] : [];


      if (!series_classes[0]) {
        series_classes = on_render_state.data.series ? [...new Set(on_render_state.data.series.map(alarm => alarm.color))] : [];

      }


      series_values = series_classes.map((class_name, index) => (
        {



          name: class_name,
          color: class_name == 'green' || class_name == 'Information' || class_name == 'Model OK' ? '#10ac84' : class_name == 'yellow' || class_name == 'Warning' || class_name == 'Attention' ? '#ff9f43' : class_name == 'red' || class_name == 'Stop' || class_name == 'Critical' ? '#ee5253' : class_name == 'orange' || class_name == 'Power Limitation' ? '#ff9f43' : class_name,
          data: on_render_state.data.series.map(ocurrence => (
            ocurrence.text == class_name ?
              {
                x: ocurrence.x,
                type_data: class_name == 'green' ? 'Model OK' : class_name == 'orange' ? 'Warning' : class_name == 'red' ? 'Critical' : class_name,
                low: Date.parse(moment.utc(ocurrence.y_start).tz("America/Fortaleza")),
                high: Date.parse(moment.utc(ocurrence.y_end).tz("America/Fortaleza")),
                type: 'datetime',
                tooltip: ocurrence.tooltip ?
                  ocurrence.tooltip.constructor == Array ?
                    ocurrence.tooltip.map(tooltip => (
                      `<b>${tooltip.key}</b>: ${tooltip.value}<br>`
                    )).join('\n')
                    :
                    ocurrence.tooltip
                  :
                  `<h3>${on_render_state.data.x_axis[ocurrence.x]}</h3>
                              <h4>${class_name}</h4>
                              <b>Start Time</b>: ${moment(ocurrence.y_start).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')} America/Fortaleza<br>
                              <b>End Time</b>: ${moment(ocurrence.y_end).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')} America/Fortaleza`,
                pointPadding: 0,
                color: ocurrence.color == 'green' || ocurrence.color == 'Information' ? '#10ac84' : ocurrence.color == 'yellow' || ocurrence.color == 'Warning' ? '#fffa65' : ocurrence.color == 'red' || ocurrence.color == 'Stop' ? '#ee5253' : ocurrence.color == 'orange' || ocurrence.color == 'Power Limitation' ? '#ff9f43' : ocurrence.color
              }
              :
              null
          )).filter(result => result),
        }
      ));


    }



    const format_diff = secs => {
      secs = Math.round(secs / 1000);
      let hours = Math.floor(secs / (60 * 60));
      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);
      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);

      let str_hours = hours < 10 ? `0${hours}` : String(hours);
      let str_minutes = minutes < 10 ? `0${minutes}` : String(minutes);
      let str_seconds = seconds < 10 ? `0${seconds}` : String(seconds);

      return `${str_hours}:${str_minutes}:${str_seconds}`;
    };


    let tableData = {}

    try {
      tableData = {
        'headers': on_render_state.data.series[0].tooltip.map(t => t.key),
        'cross_headers': null,
        'rows': on_render_state.data.series.map(serie => {
          return serie.tooltip.map(t => {
            const { key } = t;
            if (key === 'Duration') {
              return format_diff(Date.parse(moment.utc(serie.y_end).tz("America/Fortaleza")) - Date.parse(moment.utc(serie.y_start).tz("America/Fortaleza")))
            }
            if (key === 'Start Time') {
              return moment(Date.parse(moment.utc(serie.y_start).tz("America/Fortaleza"))).format('YYYY-MM-DD HH:mm:ss')
            }
            if (key === 'End Time') {
              moment(Date.parse(moment.utc(serie.y_end).tz("America/Fortaleza"))).format('YYYY-MM-DD HH:mm:ss')
            }
            return t.value
          })
        }),
      };
    } catch {
      tableData = {
        'headers': ['Event', 'Class', 'Start Time', 'End Time', 'Duration'],
        'cross_headers': null,
        'rows': [].concat.apply([], series_values.map(value => (
          value.data.map(data => (
            new Array(
              on_render_state.data.x_axis[data.x],
              data.type_data,
              moment(data.low).format('YYYY-MM-DD HH:mm:ss'),
              moment(data.high).format('YYYY-MM-DD HH:mm:ss'),
              format_diff(data.high - data.low)
            )
          ))
        ))),
      };
    }


    const final_data = series_values.map(series => (
      {
        ...series,
        showInLegend: true,
        pointPadding: 0
      }
    ))
      .concat([
        {
          // NEED THIS TO PREVENT BUGS WITH SCATTER
          name: 'Glue',
          data: on_render_state.data.x_axis ?
            Array.apply(null, { length: (on_render_state.data.x_axis.length) })
              .map(Number.call, Number)
              .map(x => ({
                x: x,
                low: Date.parse(moment.utc(startDate).tz("America/Fortaleza")),
                high: Date.parse(moment.utc(startDate).tz("America/Fortaleza")),
                type: 'datetime',
                tooltip: false,
                pointPadding: 0
              })
              )
            :
            [],
          showInLegend: false
        },
        {
          name: 'Glue2',
          data: on_render_state.data.x_axis ?
            Array.apply(null, { length: (on_render_state.data.x_axis.length) })
              .map(Number.call, Number)
              .map(x => ({
                x: x,
                low: Date.parse(moment.utc(startDate).tz("America/Fortaleza")),
                high: Date.parse(moment.utc(startDate).tz("America/Fortaleza")),
                type: 'datetime',
                tooltip: false,
                pointPadding: 0
              })
              )
            :
            [],
          showInLegend: false
        },
        {
          name: 'Start Time',
          type: 'scatter',
          data: [].concat.apply([], series_values.map(value => (
            value.data.map(data => (
              new Array(data.x, data.low)
            ))
          ))),
          tooltip: false,
          enableMouseTracking: false
        },
        {
          name: 'End Time',
          type: 'scatter',
          data: [].concat.apply([], series_values.map(value => (
            value.data.map(data => (
              new Array(data.x, data.high)
            ))
          ))),
          tooltip: false,
          enableMouseTracking: false
        }
      ]);


    const params = {
      // colors: on_render_state.colors,
      title: {
        text: null
      },
      chart: {
        type: 'columnrange',
        inverted: true,
        zoomType: 'xy'
      },
      credits: {
        enabled: false
      },
      xAxis:
      {
        max: on_render_state.data.x_axis ? on_render_state.data.x_axis.length - 1 : null,
        categories: on_render_state.data.x_axis,
        useHTML: true,
        labels: {
          staggerLines: 2,
          style: {
            fontSize: `${font_size * 0.5}px`,
            width: 80,
            whiteSpace: 'wrap',
            textOverflow: 'none'

          }
        }
      },
      yAxis:
      {
        min: Date.parse(moment.tz(startDate, "America/Fortaleza")),
        max: Date.parse(moment.tz(endDate, "America/Fortaleza")),
        type: 'datetime',
        title: {
          text: ''
        },
        labels: {
          style: {
            fontSize: `${font_size * 0.6}px`,
          }
        }

      },
      plotOptions: {
        turboThreshold: 100000000,
        series: {
          turboThreshold: 100000000,
          pointWidth: 13,
          stickyTracking: false,
          cursor: 'pointer',
          events: {
          }
        },
        columnrange: {
          turboThreshold: 100000000,
          stickyTracking: false,
          groupPadding: 0,
          grouping: false
        },
        column: {
          turboThreshold: 100000000,
          grouping: false,
          shadow: false,
          align: 'center'
        },
        scatter: {
          turboThreshold: 100000000,
          marker: {
            symbol: 'line',
            lineWidth: 0.5,
            radius: 8,
            lineColor: 'black'
          }
        }
      },
      tooltip:
      {
        enabled: !(graphic.disable_tooltip),
        useHTML: true,
        snap: 500,
        formatter: function () {
          return this.point.tooltip ? this.point.tooltip : false;
        },
        followPointer: true,
        stickyTracking: false,
        style: {
          fontSize: `${font_size * 0.7}px`
        }

      },
      series: final_data,
      exporting:
      {
        enabled: true
      }
    }

    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable || showSettings ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront} style={{ display: showTable || showSettings ? 'none' : undefined }}>
            <HighchartsReact
              containerProps={{
                style: {
                  width: '100%',
                  height: '100%',
                  minWidth: '300px',
                  minHeight: `${on_render_state.data.x_axis ? on_render_state.data.x_axis.length * 50 : 100}px`,
                  overflowX: 'scroll',
                  display: showTable ? 'none' : 'initial'

                }
              }}
              ref={this.refChart}
              highcharts={Highcharts}
              options={params}
            />

            {
              this.props.isAnalysis &&
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {tableData.headers.map((elm, index, array) => {
                      // if(index == array.length - 1) {
                      //   return (
                      //     <TableCell colSpan={1} key={index} numeric>
                      //       {elm}
                      //     </TableCell>
                      //   )
                      // }

                      return (
                        <CustomTableCell colSpan={1} key={index} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}>
                          {elm}
                        </CustomTableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.rows.map((row, indexRow, array) => {
                    return (
                      <TableRow className={classes.row} key={indexRow} style={{ display: !showTable ? 'none' : 'auto' }}>
                        {row.map((cell, indexCell) => {
                          // if(indexCell == array.length) {
                          //   return (
                          //     <TableCell key={indexCell} numeric>
                          //       {cell}
                          //     </TableCell>
                          //   )
                          // }

                          return (
                            <CustomTableCell key={indexCell} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}>
                              {cell}
                            </CustomTableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            }


          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable || showSettings ? undefined : 'none' }}>
            {
              showTable ?
                <PaginatorTable
                  displayHeaders={tableData.headers}
                  disableSelect={true}
                  records={tableData.rows.map(row => row.reduce((acc, curr, index) => ({ ...acc, [tableData.headers[index]]: curr }), {}))}
                  useCustomDisplay={['Start Time', 'End Time']}
                  filterLogic={head => (val, filters) => {
                    if (filters.length === 0 || !val) {
                      return false;
                    }

                    switch (head) {
                      case 'Start Time':
                      case 'End Time':
                        const tVal = new Date(val).getTime();
                        const tLower = filters.length > 0 ? filters[0] : 0;
                        const tUpper = filters.length > 1 ? filters[1] : 999999999999999999999999999999999999;
                        return !(tVal >= tLower && tVal <= tUpper);
                      default:
                        return !(filters.indexOf(val) >= 0);
                    }
                  }}
                  filterDisplay={head => (filterList, onChange, index, column) => {

                    switch (head) {
                      case 'Start Time':
                      case 'End Time':
                        const tValues = tableData.rows.map(row => row.reduce((acc, curr, index) => ({ ...acc, [tableData.headers[index]]: curr }), {})).filter(row => (
                          row[head] != null
                        )).map(row => new Date(row[head]).getTime());

                        const tDateMax = Math.max(...tValues);
                        const tDateMin = Math.min(...tValues);

                        return (
                          <TimeIntervalSlider
                            title={`${t(head)} (${t('in range')})`}
                            submit={value => onChange(value, index, column)}
                            LabelComponent={TimeToDateSliderLabel}
                            defaultValue={column.filterList || [tDateMin, tDateMax]}
                            sliderProps={{
                              max: tDateMax,
                              min: tDateMin,
                              step: 0.01
                            }}
                          />
                        );
                      default:
                        return undefined;
                    }
                  }}
                  chipFormatter={{
                    ['Start Time']: val => {
                      if (val.length > 0) {
                        return `${t('Start Time')}: ${new Date(val[0]).toISOString().split('T')[0] || '--'} - ${new Date(val[1]).toISOString().split('T')[0] || '--'}`;
                      } else {
                        return undefined;
                      }
                    },
                    ['End Time']: val => {
                      if (val.length > 0) {
                        return `${t('End Time')}: ${new Date(val[0]).toISOString().split('T')[0] || '--'} - ${new Date(val[1]).toISOString().split('T')[0] || '--'}`;
                      } else {
                        return undefined;
                      }
                    }
                  }}
                />

                :

                showSettings ?
                  <List>
                  </List>

                  :

                  <NoPrint>
                    <h1 style={{
                      width: '100%',
                      height: 'auto',
                      textAlign: 'center'
                    }}>Returning to Chart...</h1>
                  </NoPrint>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withStyles(styles)(GraphicColumnRange));
