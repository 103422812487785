import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import {Link, Route} from 'react-router-dom'
import styled from 'styled-components'
import React from 'react'

import { ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_LINKS } from '../../../../../../constants/route-constant'

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: black;
  display: block;
  padding: 10px;
  border-bottom: 1px solid ${grey['200']};
  &:hover {
    cursor: pointer;
    background-color: ${grey['200']};
  }
`

const SIDEBAR_WIDTH = 300

const styles = {}

class RegistryPage extends React.Component {
  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            width: SIDEBAR_WIDTH,
          }}
        >
          {
            ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_LINKS.map((item, indexItem) => {
              const itemPaths = item.path.split('/')
              const urlPaths = this.props.location.pathname.split('/')

              return (
                <LinkStyled
                  key={indexItem}
                  to={item.path}
                  style={{
                    backgroundColor: itemPaths[4] === urlPaths[4] ? grey['100'] : null,
                    borderRight: itemPaths[4] === urlPaths[4]
                      ? '3px solid orange'
                      : null
                  }}
                >
                  {item.name}
                </LinkStyled>
              )
            })
          }
        </div>
        <div
          style={{
            flex: '1 0',
            borderLeft: `1px solid ${grey['200']}`
          }}
        >
          {
            ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_LINKS.map((item, indexItem) => {
              const ComponentRoute = item.component

              return (
                <Route
                  key={indexItem}
                  path={item.path}
                  render={props => <ComponentRoute {...props} />}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(RegistryPage)
