import React from 'react'

export default ({ children }) => (
    <table className='table-print'>
        <thead
            id='page-header'
        >
            <tr>
                <td>
                    <div className='page-header-space'></div>
                </td>
            </tr>
        </thead>

        <tbody>
            <tr>
                <td style={{
                    padding: 0,
                    margin: 0
                }} >
                    {children}
                </td>
            </tr>
        </tbody>

        <tfoot
            id='page-footer'
        >
            <tr>
                <td>
                    <div className='page-footer-space'></div>
                </td>
            </tr>
        </tfoot>
    </table>
)