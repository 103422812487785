import React, {Component} from 'react'
import {
  Toolbar,
  withStyles,
  Button,
  Tooltip,
  Typography
} from '@material-ui/core'
import moment from 'moment-timezone'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';
import Icon from '@material-ui/core/Icon';

import {Popper, Fade, Paper, ListSubheader, CircularProgress} from '@material-ui/core'
import * as R from 'ramda'

import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'

import { STYLE_SUBHEADER_TOOLBAR, STYLE_HEADER_TOOLBAR } from '../../constants/style-constant'
import { ROUTE_DASHBOARD_EDIT_MODE } from '../../constants/route-constant'

const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    zIndex: 999
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  paperMenuBackground: {},
  appBar: {
    backgroundColor: 'white'
  },
  buttonIconDropDown: {
    marginLeft: '1px'
  },
  appBarToolbar: {
    borderBottom: `1px solid ${grey['200']}`
  },
  appBarToolbarSubRoot: {
    minHeight: STYLE_SUBHEADER_TOOLBAR
  },
  appBarToolbarSub: {
    borderBottom: `1px solid ${grey['200']}`,
    minHeight: '0',
    height: STYLE_SUBHEADER_TOOLBAR,
    paddingLeft: '0',
    zIndex: 999,
    position: 'fixed',
    top: STYLE_HEADER_TOOLBAR + 1,
    left: 0,
    width: '100%',
    backgroundColor: '#fff'
  },
  buttonMenu: {
    textTransform: 'capitalize',
    fontWeight: '200',
    fontSize: '16px',
    borderRadius: '0',
    zIndex: 999
  },
  buttonMenuSubHeader: {
    textTransform: 'capitalize',
    fontWeight: '200',
    fontSize: '16px',
    borderRight: `1px solid ${grey['200']}`,
    borderLeft: `1px solid ${grey['200']}`,
    minHeight: '12px',
    borderRadius: '0',
    zIndex: 999
  },
  buttonMenuSubHeaderSelect: {
    textTransform: 'capitalize',
    fontWeight: '200',
    fontSize: '16px',
    borderRadius: '0',
    backgroundColor: orange['700'],
    color: 'white',
    minHeight: '24px',
    '&:hover': {
      backgroundColor: orange['900'],
    },
    zIndex: 999
  },
  progressSaving: {
    color: orange[500],
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1000
  }
})

class PageHeaderComponentBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      module: null,
      selected_submodule_id: null,
      saving: false,
      saveOk: null,
      prefBtAnchor: null
    };

    // this.savePreferencesBtClick = this.savePreferencesBtClick.bind(this)
  }

  componentDidMount () {

  }

  goTo(path, save=false){
    this.props.goToPage(path);
  }

  chooseSubmodule (submodule, event) {
    if(this.state.selected_submodule_id != submodule.id) {
      this.state.selected_submodule_id = submodule.id;
      this.forceUpdate();
      this.props.subModuleSelected(this.state.selected_submodule_id);
      this.props.goToPage(submodule.route);
    }
  }

  // savePreferencesBtClick(event) {
  //   this.setState({saving: true, prefBtAnchor: event.currentTarget.getBoundingClientRect()})
  // }

  // componentDidUpdate() {
  //   console.log('ON COMPONENT DID UPDATE')
  //   if(this.state.saving && this.state.saveOk != null) {
  //     setTimeout(() => this.setState({saving: false, saveOk: null}), 1000)
  //   } else if(this.state.saving) {
  //     savePreferences()
  //       .then(result => {
  //         console.log('AFTER ADD CHECKPOINT', result)
  //         this.setState({saving: true, saveOk: true})
  //       })
  //       .catch(error => {
  //         console.log('AFTER ADD CHECKPOINT', error)
  //         this.setState({saving: true, saveOk: false})
  //       })
  //   }
  //
  // }

  render () {
    const {classes, module, submodules, Navigation, modules, edit_mode} = this.props;
    const {module_id, submodule_id, submodule_depth} = Navigation;

    if(!module) {throw 'Module Not Exist!';}
    this.state.module = module;

    const depth = submodule_depth ? submodule_depth : 3;
    const path_tree = window.location.pathname.split('/');
    const route = `${path_tree.filter((value, index) => index <= depth).join('/')}`;

    const selected_submodule = submodules.filter(submodule => 'route' in submodule && submodule.route == route)[0]

    this.state.selected_submodule_id = selected_submodule ? selected_submodule.id : null
    // if(!this.state.selected_submodule_id) {throw 'Route does not correspond to submodule'}

    const is_selected = (submodule, index) => (
      this.state.selected_submodule_id == submodule.id ||
      !this.state.selected_submodule_id && index == 0
    );

    const previous_route = edit_mode && modules ?
        modules.filter(module => module.id == module_id)
                .map(module => module.submodules.filter(submodule => submodule.id == submodule_id)[0].route)[0]
      :
        null

    return (
      <div className={classes.appBarToolbarSubRoot}>
        <Toolbar className={classes.appBarToolbarSub} variant="dense">
          {
            this.state.module.submodules.map((submodule, index) => (
              <Button
                key={index}
                className={classes.buttonMenuSubHeader}
                onClick={this.chooseSubmodule.bind(this, submodule)}
                style={{
                  color: is_selected(submodule, index) ? 'white' : 'black',
                  backgroundColor: is_selected(submodule, index) ? 'orange' : 'white',
                }}
              >
                {submodule.name}
              </Button>
            ))
          }
          <div style={{flex: 1}}/>
          {
          // <div className={classes.wrapper} >
          //   <Button
          //     className={classes.buttonMenuSubHeader}
          //     disabled={this.state.saving}
          //     onClick={this.savePreferencesBtClick}
          //   >
          //     <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>save</Icon>
          //     <Typography variant="h2" component="p"
          //       style={{
          //         fontSize: '1rem',
          //         margin: 0
          //       }}
          //     >
          //       Save Changes
          //     </Typography>
          //   </Button>
            // {this.state.saving && this.state.saveOk == null ?
            //   <CircularProgress
            //     size={24}
            //     className={classes.progressSaving}
            //     style={{
            //       top: `${this.state.prefBtAnchor.y - this.state.prefBtAnchor.height * 1.45}px`,
            //       left: `${this.state.prefBtAnchor.x + this.state.prefBtAnchor.width * 0.45}px`
            //     }}
            //   />
            // : null}
            // {this.state.saving && this.state.saveOk != null ?
            //   <Icon
            //     size={24}
            //     className={classes.progressSaving}
            //     style={{
            //       top: `${this.state.prefBtAnchor.y - this.state.prefBtAnchor.height  * 1.45}px`,
            //       left: `${this.state.prefBtAnchor.x + this.state.prefBtAnchor.width * 0.45}px`
            //     }}
            //   > {this.state.saveOk ? 'check' : 'close'}</Icon> : null}
          // </div>
          }
          {
            module.editable ?
              edit_mode ?
                <Button className={classes.buttonMenuSubHeader} onClick={() => this.goTo(previous_route, true)} >
                  <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>done</Icon>
                  <Typography variant="h2" component="p"
                    style={{
                      fontSize: '1rem',
                      margin: 0
                    }}
                  >
                    Done
                  </Typography>
                </Button>
              :
                <Button className={classes.buttonMenuSubHeader} onClick={() => this.goTo(ROUTE_DASHBOARD_EDIT_MODE)} >
                  <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>create</Icon>
                  <Typography variant="h2" component="p"
                    style={{
                      fontSize: '1rem',
                      margin: 0
                    }}
                  >
                    Edit Mode
                  </Typography>
                </Button>
             : null

          }
        </Toolbar>
      </div>
    )
  }
}

export default withStyles(styles)(PageHeaderComponentBase)
