import React, {
    useEffect,
    useRef
} from 'react';

import {
    Tooltip
} from '@material-ui/core';

const SliderTooltipLabel = (textFormat=val=>val) => props => {
    const { 
        children, 
        open, 
        value 
    } = props;
  
    const popperRef = useRef(null);

    useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });
  
    return (
        <Tooltip
            PopperProps={{
                popperRef,
            }}
            open={open}
            enterTouchDelay={0}
            placement='top'
            title={textFormat(value)}
        >
            {children}
        </Tooltip>
    );
}

export default SliderTooltipLabel;