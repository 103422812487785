import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { withStyles, Paper, Typography, Button, Menu, MenuItem } from '@material-ui/core'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant'
import grey from '@material-ui/core/colors/grey'
import graphTypes from '../../../constants/graph-types'
import * as R from 'ramda'
import GoogleMapComponent from '../../Map/GoogleMapComponent';
import { setDataDirectlyWidget } from '../../../actions/WidgetPageActions';

import styles from './styles/styles.js';

class MapWidget extends Component {
  state = {
    anchorEl: null,
    park: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  setDataDirectlyWidget = (park) => () => {
    const {selectedPage, widget, module_id, reducer_name} = this.props
    this.props.setDataDirectlyWidget(module_id, selectedPage.id, widget.id, park, reducer_name)
    this.handleClose()
  }

  render () {
    const {classes, widget, parks_info} = this.props;
    const { anchorEl } = this.state;
    const { filter } = widget;
    const { subparks, devices: fDevices } = filter || {};

    const graphicType = R.find(R.propEq('type', widget.type))(graphTypes)

    const parksFiltered = parks_info.filter(park => {
      const subparksFiltered = park.subparks.filter(subpark => subparks.indexOf(subpark.id) >= 0);
      return subparksFiltered.length > 0;
    }).map(park => {
      const subparksFiltered = park.subparks.filter(subpark => subparks.indexOf(subpark.id) >= 0);
      return {
        ...park,
        subparks: subparksFiltered.map(subpark => {
          return {
            ...subpark,
            wtgs: subpark.wtgs.filter(device => fDevices.indexOf(device.id) >= 0)
          }
        })
      } 
    });
    
    const devices = R.flatten(parksFiltered.map(park => park.subparks.map(subpark => {
      return subpark.wtgs.filter(wtg => {
        return wtg.latitude && wtg.longitude
      })
    })));


    return (
      <div
        className={classes.mapContent}
      >
        <Paper className={classes.paperElement} elevation={0}>
          <div className={classes.paperBody}>
            <GoogleMapComponent items={devices} park={parksFiltered} />
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = ({User}) => {
  const {parks_info} = User

  return {parks_info}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({setDataDirectlyWidget}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapWidget))
