import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getModels } from '../../../providers/meta-provider';

import { SET_MODELS } from './contants';
import { REDUCER_UPDATE_ENVELOPE_BY_DEVICE } from "../../../constants/reducers/global-filter";

export default props => {
    const { device, onExecute } = props;

    const [ mounted, setMounted ] = useState(false);
    const [ models, setModels ] = useState();
    const [ predictiveModels, setPredictiveModels ] = useState({});
    const [ modelSelected, setModelSelected ] = useState();
    const dispatch = useDispatch();

    const { envelope_id: CurrentModelID } = useSelector(state => state.Dashboard && state.Dashboard.EnvelopeFilter);
    const { devices } = useSelector(state => state.Dashboard && state.Dashboard.DeviceFilter);
    const CurrentDeviceID = devices && devices.length > 0 && devices[0].id;

    useEffect(
        () => {
            ( async () => {
                setModels(await getModels());
                setMounted(true);
            } )();
        },
        [ mounted ]
    );

    const getPredictiveModels = useCallback(
        () => {
            if(mounted && models) {
                const validDevicesInfo = models.filter(device => device.device_id != null);
                return validDevicesInfo.reduce((acc, curr) => {
                    const accumulated = acc || {};
                    const deviceKey = curr.device_id;
                    const groupKey = curr.model_group_id;
                    const modelKey = curr.model_id;

                    const accumulatedGroups = accumulated[deviceKey] && accumulated[deviceKey].groups || {};
                    const group = accumulatedGroups[groupKey] || {};
                    const groupModels = group.models || {};

                    return ({
                        ...accumulated, 
                        [deviceKey]: { 
                            groups: { 
                                ...accumulatedGroups,
                                [groupKey]: {
                                    id: groupKey, 
                                    name: curr.group_name,
                                    tag: curr.group_tag,
                                    models: {
                                        ...groupModels,
                                        [modelKey]: {
                                            id: modelKey,
                                            name: curr.model_name
                                        }
                                    }
                                }
                            }
                        }
                    })
                }, null);
            }
        },
        [ models, mounted ]
    );

    useEffect(
        () => setPredictiveModels(getPredictiveModels()),
        [ models, mounted ]
    );

    useEffect(
        () => {
            if(predictiveModels && dispatch) {
                dispatch({ type: SET_MODELS, predictiveModels });
            }
        },
        [ predictiveModels, dispatch ]
    );

    useEffect(
        () => {
            if(predictiveModels && device) {
                // console.log('On calculating model selected ', predictiveModels)
                console.log('Changing device')
                const selected = predictiveModels[device];
                const defaultGroup = selected && selected.groups && ( selected.groups[-1] || Object.values(selected.groups).length > 0 && Object.values(selected.groups)[0] );
                const modelsValues = defaultGroup && defaultGroup.models && Object.values(defaultGroup.models);
                const defaultModel = modelsValues && modelsValues.length > 0 && modelsValues[0];

                setModelSelected(defaultModel);

                setTimeout(() => dispatchFilter(defaultModel), 200)
            }
        },
        [ predictiveModels, device ]
    );

    const dispatchFilter = useCallback(
        (forcedModel) => {
            if(forcedModel) {
                console.log({forcedModel})
                dispatch({type: REDUCER_UPDATE_ENVELOPE_BY_DEVICE, payload: { envelope_id: forcedModel.id}});
                onExecute(forcedModel.id);
            } else if(modelSelected && CurrentModelID !== modelSelected.id) {
                dispatch({type: REDUCER_UPDATE_ENVELOPE_BY_DEVICE, payload: { envelope_id: modelSelected.id}});
                onExecute(modelSelected.id);
            }
        },
        [ dispatch, modelSelected, onExecute, CurrentModelID ]
    );

    // useEffect(
    //     () => dispatchFilter(),
    //     [ modelSelected, CurrentModelID, mounted ]
    // );

    return { getPredictiveModels, models, predictiveModels, modelSelected, dispatchFilter, setModelSelected };
};