import React from 'react'
import grey from '@material-ui/core/colors/grey'
import {withStyles} from '@material-ui/core'
import styled from 'styled-components'
import {Link, Route} from 'react-router-dom'
import { ROUTE_DASHBOARD_ASSET_MOVEMENT_ASSET_COMPONENT, ROUTES_MOVEMENTS } from '../../../../../../constants/route-constant';
import { getMovAssetServIds, getMovServIds, getStorageIds, getComponentIds, getAssetComponentIds } from '../../../../../../providers/asset-provider'

const styles = {}

const ButtonSidebarStyled = styled(Link)`
  width: calc(100% - 3px);
  min-height: 48px;
  border-bottom: 1px solid ${grey['200']};
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: black;
  &:hover {
    cursor: pointer;
    background-color: ${grey['200']};
  }
`

class MovementPage extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      assetComponent: [],
      assetConsumable: [],
      assetService: [],
      component: [],
      storage_ids:[],
      component_ids:[],
      assets_component_ids:[],
      consumable: [],
      assets_services_ids: [],
      services_ids: [],

    }
  }


 componentDidMount () {
    this.setState({loading: true})

    getMovAssetServIds()
      .then(data => {
        this.setState({assets_services_ids: data})

      })
      .catch(error => {
        this.setState({loading: false})

      })
  
    getMovServIds()
        .then(data => {
          this.setState({services_ids: data})
        })
        .catch(error => {
          this.setState({loading: false})
          console.error('getMovAssetCompProvider', error)
        })
    
    getStorageIds()
        .then(data => {
          this.setState({storage_ids: data})
        })
        .catch(error => {
          this.setState({loading: false})
          console.error('getMovAssetCompProvider', error)
        })
    
    getComponentIds()
        .then(data => {
          this.setState({component_ids: data})
        })
        .catch(error => {
          this.setState({loading: false})
          console.error('getMovAssetCompProvider', error)
        })
    
    getAssetComponentIds()
        .then(data => {
          this.setState({assets_component_ids: data})
        })
        .catch(error => {
          this.setState({loading: false})
          console.error('getMovAssetCompProvider', error)
        })
    
  }


  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            minWidth: 300
          }}
        >
          {
            ROUTES_MOVEMENTS.map((route, indexRoute) => {
              return (
                <ButtonSidebarStyled
                  key={indexRoute}
                  to={route.path}
                  style={{
                    borderRight: this.props.location.pathname === route.path
                      ? '3px solid orange'
                      : null,
                    // color: this.props.location.pathname === route.path
                    //   ? 'white'
                    //   : null
                  }}
                >
                  <span
                    style={{
                      marginLeft: 10
                    }}
                  >
                    {route.name}
                  </span>
                </ButtonSidebarStyled>
              )
            })
          }
        </div>
        <div
          style={{
            flex: '1 0',
            borderLeft: `1px solid ${grey['200']}`
          }}
        >
          {
            ROUTES_MOVEMENTS.map((route, indexRoute) => {
              const Component = route.component

              return (
                <Route
                  key={indexRoute}
                  path={route.path}
                  render={props => {
                    return (
                      <Component
                        {...props} {...this.state}
                      />
                    )
                  }}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(MovementPage)
