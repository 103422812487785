import React from 'react'
import styled from 'styled-components'
import {
  withStyles,
  IconButton,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
  TextField,
  Typography,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import {Edit, Delete, Add, Send} from '@material-ui/icons'
import {grey} from '@material-ui/core/colors'
import {Link} from 'react-router-dom'
import * as R from 'ramda'

import { getStorageComponentProvider, getStorageConsumableProvider, getAssetProvider } from '../../../../../../../../providers/asset-provider';

const PADDING_PAGE = 30
const PAPER_PADDING_PAGE = 20

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  thead tr {    
    height: 65px;
  }

  tbody tr:hover {
    background-color: ${grey['200']};
    cursor: pointer;
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`

const styles = {
  paper: {
    width: `calc(100% - ${(PADDING_PAGE * 3)}px)`,
    padding: PAPER_PADDING_PAGE
  },
  formControl: {
    width: '100%'
  },
  textField: {
    // width: `calc(100% - ${PAPER_PADDING_PAGE}px)`
    width: '100%'
  }
}

class StockItemPage extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      items: [],
      loading: false,
      id: null,
      search: '',
      components: [],
      consumables: [],
      assets: [],
      component: {},
      consumable: {},
      dialogMoveComponent: false,
      dialogMoveConsumable: false
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.match.params.id !== this.props.match.params.id){
      this.setState({loading: true})
      
      Promise.all([
        getStorageComponentProvider(nextProps.match.params.id),
        getStorageConsumableProvider(nextProps.match.params.id),
        getAssetProvider()
      ])
        .then(data => {
          this.setState({
            loading: false,
            components: data[0],
            consumables: data[1],
            assets: data[2]
          })
        })
        .catch(error => {
          this.setState({loading: false})
          console.error('error getStorageProvider', error)
        })
    }
  }

  componentDidMount () {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {

      this.setState({loading: true})
      
      Promise.all([
        getStorageComponentProvider(this.props.match.params.id),
        getStorageConsumableProvider(this.props.match.params.id),
        getAssetProvider()
      ])
        .then(data => {
          this.setState({
            loading: false,
            components: data[0],
            consumables: data[1],
            assets: data[2]
          })
        })
        .catch(error => {
          this.setState({loading: false})
          console.error('error getStorageProvider', error)
        })
    }
  }

  render () {
    const {classes} = this.props

    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: grey['100']
            backgroundColor: 'white'
          }}
        >
          <CircularProgress />
          <div
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div
          style={{
            height: '100%',
            width: '100%',
            overflow: 'auto',
            padding: PADDING_PAGE,
            backgroundColor: grey['100']
          }}
        >
          {/* <Paper
            className={classes.paper}
            style={{
              marginBottom: PAPER_PADDING_PAGE
            }}
          >
            <TextField
              label="Search"
              className={classes.textField}
              value={this.state.search}
              onChange={e => this.setState({search: e.target.value})}
            />
          </Paper> */}

          <Paper
            className={classes.paper}
          >
            <Typography variant="h5" component="h3">
              Components
            </Typography>
            <TableStyled>
              <thead>
                <tr>
                  <th>
                    Model
                  </th>
                  <th>
                    Part number
                  </th>
                  <th>
                    Serial number
                  </th>
                  <th>
                    Supplier
                  </th>
                  <th>
                    Manufacturer
                  </th>
                  <th>
                    Acquired date
                  </th>
                  <th>
                    Last change date
                  </th>
                  <th
                    style={{
                      width: 50,
                      textAlign: 'center'
                    }}
                  >
                    Move
                  </th>
                  {/* <th
                    style={{
                      width: 50,
                      textAlign: 'center'
                    }}
                  >
                    Delete
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {
                  this.state.components
                    .map((item, indexItem) => {
                      return (
                        <tr
                          key={indexItem}
                        >
                          <td>
                            {item.model}
                          </td>
                          <td>
                            {item.part_number}
                          </td>
                          <td>
                            {item.serial_number}
                          </td>
                          <td>
                            {item.supplier}
                          </td>
                          <td>
                            {item.manufacturer}
                          </td>
                          <td>
                            {item.acquired_date}
                          </td>
                          <td>
                            {item.last_change_date}
                          </td>
                          <td>
                            <IconButton
                              aria-label="Send"
                              className={classes.margin}
                              onClick={() => {
                                this.setState({
                                  component: item,
                                  dialogMoveComponent: true,
                                })
                              }}
                            >
                              <Send
                                fontSize="small"
                              />
                            </IconButton>
                          </td>
                          {/* <td>
                            <LinkStyled
                              to={`${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE}/${item.id}/edit`}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes.margin}
                                >
                                <Edit
                                  fontSize="small"
                                  />
                              </IconButton>
                            </LinkStyled>
                          </td>
                          <td>
                            <IconButton
                              aria-label="Delete"
                              className={classes.margin}
                              onClick={() => {
                                this.setState({
                                  id: item.id,
                                  dialogDelete: true,
                                })
                              }}
                            >
                              <Delete
                                fontSize="small"
                              />
                            </IconButton>
                          </td> */}
                        </tr>
                      )
                    })
                }
              </tbody>
            </TableStyled>
          </Paper>
{/* 
          <Paper
            className={classes.paper}
            style={{
              marginTop: PAPER_PADDING_PAGE
            }}
          >
            <Typography variant="h5" component="h3">
              Consumables
            </Typography>
            <TableStyled>
              <thead>
                <tr>
                  <th>
                    Model
                  </th>
                  <th>
                    Part number
                  </th>
                  <th>
                    Supplier
                  </th>
                  <th>
                    Manufacturer
                  </th>
                  <th>
                    Quantity
                  </th>
                  <th>
                    Acquired date
                  </th>
                  <th>
                    Last change date
                  </th>
                  {/* <th
                    style={{
                      width: 50,
                      textAlign: 'center'
                    }}
                  >
                    Edit
                  </th> 
                  <th
                    style={{
                      width: 50,
                      textAlign: 'center'
                    }}
                  >
                    Move
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.consumables
                    .map((item, indexItem) => {
                      return (
                        <tr
                          key={indexItem}
                        >
                          <td>
                            {item.model}
                          </td>
                          <td>
                            {item.part_number}
                          </td>
                          <td>
                            {item.supplier}
                          </td>
                          <td>
                            {item.manufacturer}
                          </td>
                          <td>
                            {item.quantity}
                          </td>
                          <td>
                            {item.acquired_date}
                          </td>
                          <td>
                            {item.last_change_date}
                          </td>
                          {/* <td>
                            <LinkStyled
                              to={`${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE}/${item.id}/edit`}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes.margin}
                                >
                                <Edit
                                  fontSize="small"
                                  />
                              </IconButton>
                            </LinkStyled>
                          </td> 
                          <td>
                            <IconButton
                              aria-label="Send"
                              className={classes.margin}
                              onClick={() => {
                                this.setState({
                                  consumable: item,
                                  dialogMoveConsumable: true,
                                })
                              }}
                            >
                              <Send
                                fontSize="small"
                              />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </TableStyled>
          </Paper>
 */}
          <div
            style={{
              height: 70
            }}
          />

          {/* <div
            style={{
              position: 'fixed',
              right: 20,
              bottom: 10
            }}
          >
            <LinkStyled
              to={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE_CREATE}
            >
              <Button
                variant='fab'
                color="primary"
                >
                <Add />
              </Button>
            </LinkStyled>
          </div> */}
        </div>
        
        {/* MOVE COMPONENT */}
        <Dialog
          open={this.state.dialogMoveComponent}
          // onClose={() => this.setState({dialogMoveComponent: false, component: {}})}
        >
          <DialogTitle>
            Move component
          </DialogTitle>
          <DialogContent>


            <FormControl
              className={classes.formControl}
            >
              <InputLabel
                // htmlFor='select_input_destination_type'
              >
                Destination type
              </InputLabel>
              <Select
                value={this.state.destination_type}
                onChange={e => this.setState({destination_type: e.target.value})}
                // inputProps={{
                //   id: 'select_input_destination_type',
                // }}
              >
                <MenuItem
                  value={1}
                >
                  Storage
                </MenuItem>
                <MenuItem
                  value={2}
                >
                  Asset
                </MenuItem>
              </Select>
            </FormControl>

            {
              this.state.destination_type === 1
              ? (
                <FormControl
                  className={classes.formControl}
                  style={{
                    marginTop: 20
                  }}
                >
                  <InputLabel
                    // htmlFor='select_input_destination_storage'
                  >
                    Storage
                  </InputLabel>
                  <Select
                    value={this.state.storage}
                    onChange={e => this.setState({storage: e.target.value})}
                    // inputProps={{
                    //   id: 'select_input_destination_storage',
                    // }}
                  >
                    {
                      this.props.storages
                        .filter(storage => {
                          return Number(this.props.match.params.id) !== storage.id
                        })
                        .map((storage, indexStorage)=> {
                          return (
                            <MenuItem
                              key={indexStorage}
                              value={storage}
                            >
                              {storage.name}
                            </MenuItem>
                          )
                        })
                    }
                  </Select>
                </FormControl>
              )
              : (
                <React.Fragment>
                  <FormControl
                    className={classes.formControl}
                    style={{
                      marginTop: 20
                    }}
                  >
                    <InputLabel
                      // htmlFor='select_input_asset'
                    >
                      Asset
                    </InputLabel>
                    <Select
                      value={this.state.asset}
                      onChange={e => this.setState({
                        asset: e.target.value,
                        asset_component: {}
                      })}
                      // inputProps={{
                      //   id: 'select_input_asset',
                      // }}
                    >
                      {
                        this.state.assets
                          .map((asset, indexStorage)=> {
                            return (
                              <MenuItem
                                key={indexStorage}
                                value={asset}
                              >
                                {asset.name}
                              </MenuItem>
                            )
                          })
                      }
                    </Select>
                  </FormControl>

                  {
                    this.state.asset
                    ?
                    (
                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: 20
                        }}
                      >
                        <InputLabel
                          // htmlFor='select_input_asset'
                        >
                          Asset Component
                        </InputLabel>
                        <Select
                          value={this.state.asset_component}
                          onChange={e => {
                            this.setState({asset_component: e.target.value})
                          }}

                          // inputProps={{
                          //   id: 'select_input_asset',
                          // }}
                        >
                          {
                            this.state.asset && this.state.asset.components && this.state.asset.components.length > 0
                            ?
                            this.state.asset.components
                              .map((component, indexComponent)=> {
                                
                                return (
                                  <MenuItem
                                    key={indexComponent}
                                    value={component}
                                  >
                                    {component.asset_model_component.name}
                                  </MenuItem>
                                )
                              })
                            : null
                          }
                        </Select>
                      </FormControl>
                    )
                    : null
                  }
                </React.Fragment>
              )
            }


          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({dialogMoveComponent: false, component: {}})}
            >
              Disagree
            </Button>
            <Button
              onClick={() => {



                // this.setState({loading: true})
                
                // deleteStorageProvider(this.state.id)
                //   .then(() => {
                //     this.setState({
                //       dialogMoveComponent: false,
                //       id: '',
                //     })

                //     getStorageProvider()
                //       .then((items) => {
                //         this.setState({
                //           items,
                //           loading: false
                //         })
                //       })
                //       .catch(error => {
                //         console.error('getStorageProvider', error)
                //         this.setState({
                //           loading: false
                //         })
                //       })

                //     toast.success('Success!', {
                //       position: "top-right",
                //       hideProgressBar: true,
                //       closeOnClick: true,
                //       pauseOnHover: true
                //     })
                //   })
                //   .catch(error => {
                //     console.error('updateAssetModelProvider', error)
                //     this.setState({
                //       loading: false
                //     })
                //     toast.error('Save error', {
                //       position: "top-right",
                //       hideProgressBar: true,
                //       closeOnClick: true,
                //       pauseOnHover: true
                //     })
                //   })
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(StockItemPage)
