import React, {Component} from 'react'
import {Route} from 'react-router-dom'

import ReportsNewComponent from './components/ReportsNewComponent/ReportsNewComponent'
import { ROUTE_MAIN_REPORTS_MANAGEMENT, ROUTE_MAIN_REPORTS_MANAGEMENT_NEW } from '../../../../../../constants/route-constant';
import ReportsListContentPage from './pages/ReportsListContentPage/ReportsListContentPage';

class ReportsListPage extends Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <Route
          exact
          path={ROUTE_MAIN_REPORTS_MANAGEMENT}
          component={ReportsListContentPage}
        />

        <Route
          path={ROUTE_MAIN_REPORTS_MANAGEMENT_NEW}
          component={ReportsNewComponent}
        />
      </div>
    )
  }
}

export default ReportsListPage
