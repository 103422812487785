import React, { useState } from 'react';
import PLACEHOLDER_DATA from './placeholder-data.json';
import Table from '../Table/MuiDataTableView';
import { ButtonGroup, Button } from '@material-ui/core';
import TimeIntervalSlider from '../../modules/Dashboard/pages/EventsPage/components/TimeIntervalSlider';
import SliderTooltipLabel from '../../modules/Dashboard/pages/EventsPage/components/SliderTooltipLabel';
import '../../modules/Dashboard/pages/EventsPage/components/styles/ExtraStyles.css';
import { useTranslation } from 'react-i18next';

const TimeToDateSliderLabel = SliderTooltipLabel(val => new Date(val).toISOString().split('.')[0].replace('T', ' '));


export default props => {
    const { data: propsData, ...tableProps } = props;
    const Data = propsData || PLACEHOLDER_DATA;
    const [aggregations, setAggregationOptions] = useState(Object.keys(Data.aggregationsNames || {}));
    const [selectedAgg, selectAgg] = useState('default');

    const data = Data.data[selectedAgg] || Data.data[aggregations.slice(0, 1)[0]] || [];

    const displayHeaders = Data.aggregationsDisplayHeaders[selectedAgg];
    const { t } = useTranslation();


    return (
        <Table
            title={
                <ButtonGroup
                    variant='outlined'
                    size='small'
                    style={{
                        boxShadow: 'none',
                        backgroundColor: 'transparent'
                    }}
                >
                    {
                        aggregations.map(aggKey => (
                            <Button
                                style={{ backgroundColor: aggKey === selectedAgg ? '#dbdbdb' : 'transparent' }}
                                onClick={() => setTimeout(() => selectAgg(aggKey), 100)}
                            >
                                {t(Data.aggregationsNames[aggKey])}
                            </Button>
                        ))
                    }
                </ButtonGroup>
            }
            records={data}
            displayHeaders={displayHeaders}
            headFormatter={header => Data.headerNames[header]}
            background='white'
            disableSelect
            resizeFactor={1}
            rowsPerPageOptions={[
                10,
                20,
                30,
                40,
                50,
                100,
                150,
                200
            ]}
            filterLogic={head => (val, filters) => {
                if (filters.length === 0) {
                    return false;
                }

                const headIndex = Data.timeEntries.indexOf(head);

                if (headIndex >= 0) {
                    const Val = new Date(val).getTime();
                    const lower = filters.length > 0 ? filters[0] : 0;
                    const upper = filters.length > 1 ? filters[1] : 999999999999999999999999999999999999;
                    return !(Val >= lower && Val <= upper);
                } else {
                    return !(filters.indexOf(val) >= 0);
                }
            }}
            useCustomDisplay={Data.timeEntries}
            filterDisplay={head => (filterList, onChange, index, column) => {
                const headIndex = Data.timeEntries.indexOf(head);

                if (headIndex >= 0) {
                    const values = data.filter(row => (
                        row[head] != null
                    )).map(row => new Date(row[head]).getTime());
                    const maxValue = Math.max(...values);
                    const minValue = Math.min(...values);

                    return (
                        <TimeIntervalSlider
                            title={`${t(Data.headerNames[head])} (${t('in range')})`}
                            submit={value => onChange(value, index, column)}
                            LabelComponent={TimeToDateSliderLabel}
                            defaultValue={column.filterList || [minValue, maxValue]}
                            sliderProps={{
                                max: maxValue,
                                min: minValue,
                                step: 0.01
                            }}
                        />
                    );
                } else {
                    return undefined;
                }
            }}
            chipFormatter={
                displayHeaders.map(head => ({
                    [head]: val => (
                        val.length > 0 ?
                            `${Data.headerNames[head]} between ${new Date(val[0]).toISOString().split('.')[0].replace('T', ' ') || '--'} and ${new Date(val[1]).toISOString().split('.')[0].replace('T', ' ') || '--'}`
                            :
                            undefined
                    )
                })).reduce((acc, curr) => ({ ...acc, ...curr }), {})
            }
            {...tableProps}
        />
    );
};