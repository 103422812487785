import React from 'react'
import {withStyles, CircularProgress, Paper, TextField, Button, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core'
import {Send} from '@material-ui/icons'
import {grey} from '@material-ui/core/colors'
import moment from 'moment-timezone'

import { getAssetByIdProvider, getAssetModelProvider, getSystemProvider, updateAssetProvider, createAssetProvider } from '../../../../../../../../../../providers/asset-provider'
import { toast } from 'react-toastify';

const PADDING_PAGE = 60

const styles = {
  paper: {
    width: `calc(100% - ${(PADDING_PAGE * 2)}px)`,
    padding: 20
  },
  formControl: {
    width: '100%'
  }
}

class AssetChangePage extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      loading: false,
      id: null,
      name: '',
      serial_number: '',
      installation_date: moment(),
      commissioning_date: moment(),
      operation_date: moment(),
      asset_models: [],
      asset_model: {}
    }
  }

  componentWillMount () {
    this.setState({
      loading: true
    })

    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      getAssetByIdProvider(this.props.match.params.id)
        .then(item => {
          return getAssetModelProvider()
            .then(asset_models => {
              this.setState({
                loading: false,
                id: item.id,
                name: item.name,
                serial_number: item.serial_number,
                installation_date: moment(item.installation_date),
                commissioning_date: moment(item.commissioning_date),
                operation_date: moment(item.operation_date),
                asset_models,
                asset_model: item.asset_model,
              })
            })
        })
        .catch(error => {
          console.error('error', error)
  
          this.setState({
            loading: false
          })
        })
    } else {
      getAssetModelProvider()
        .then(asset_models => {
          this.setState({
            loading: false,
            id: null,
            name: '',
            serial_number: '',
            installation_date: moment(),
            commissioning_date: moment(),
            operation_date: moment(),
            asset_models,
            asset_model: {},
          })
        })
        .catch(error => {
          console.error('error', error)
  
          this.setState({
            loading: false
          })
        })
    }
  }

  render () {
    const {classes} = this.props

    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: grey['100']
            backgroundColor: 'white'
          }}
        >
          <CircularProgress />
          <div
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          padding: PADDING_PAGE,
          backgroundColor: grey['100']
        }}
      >
        <Paper
          className={classes.paper}
          elevation={1}
        >
          <form
            onSubmit={e => {
              e.preventDefault()

              if (
                this.state.name
                && this.state.serial_number
                && this.state.asset_model.id
              ) {
                this.setState({loading: true})

                if (this.state.id) {
                  updateAssetProvider({
                    id: this.state.id,
                    name: this.state.name,
                    serial_number: this.state.serial_number,
                    installation_date: this.state.installation_date ? this.state.installation_date.toString(): '',
                    commissioning_date: this.state.commissioning_date ? this.state.commissioning_date.toString(): '',
                    operation_date: this.state.operation_date ? this.state.operation_date.toString(): '',
                    asset_model_id: this.state.asset_model.id
                  })
                    .then(() => {
                      this.setState({
                        loading: false
                      })
  
                      toast.success('Success!', {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      })
                    })
                    .catch(error => {
                      console.error('updateAssetProvider', error)
                      this.setState({
                        loading: false
                      })
                      toast.error('Save error', {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      })
                    })
                } else {
                  createAssetProvider({
                    name: this.state.name,
                    serial_number: this.state.serial_number,
                    installation_date: this.state.installation_date ? this.state.installation_date.toString(): '',
                    commissioning_date: this.state.commissioning_date ? this.state.commissioning_date.toString(): '',
                    operation_date: this.state.operation_date ? this.state.operation_date.toString(): '',
                    asset_model_id: this.state.asset_model.id
                  })
                    .then(() => {
                      this.setState({
                        loading: false,
                        name: '',
                        serial_number: '',
                        installation_date: moment(),
                        commissioning_date: moment(),
                        operation_date: moment(),
                        asset_model: {}
                      })
  
                      toast.success('Success!', {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      })
                    })
                    .catch(error => {
                      console.error('createAssetProvider', error)
                      this.setState({
                        loading: false
                      })
  
                      toast.error('Save error', {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      })
                    })
                }
              } else {
                toast.error('Invalid params', {
                  position: "top-right",
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true
                })
              }
            }}
          >
            <FormControl
              className={classes.formControl}
            >
              <InputLabel
                htmlFor="age-simple"
              >
                Asset model
              </InputLabel>
              <Select
                value={this.state.asset_model}
                onChange={(e) => this.setState({asset_model: e.target.value})}
                renderValue={value => {
                  if (!value) {
                    return 'None'
                  }

                  return `${value.name}`
                }}
              >
                {
                  this.state.asset_models.map((asset_model, indexSystem) => {
                    return (
                      <MenuItem
                        key={indexSystem}
                        value={asset_model}
                      >
                        {asset_model.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>

            <TextField
              label="Name"
              className={classes.textField}
              value={this.state.name}
              onChange={e => this.setState({name: e.target.value})}
              style={{
                width: '100%',
                marginTop: 10
              }}
            />

            <TextField
              label="Serial number"
              className={classes.textField}
              value={this.state.serial_number}
              onChange={e => this.setState({serial_number: e.target.value})}
              style={{
                width: '100%',
                marginTop: 10
              }}
            />

            {/* <TextField
              label="Installation date"
              className={classes.textField}
              value={this.state.installation_date}
              onChange={e => this.setState({installation_date: e.target.value})}
              style={{
                width: '100%',
                marginTop: 10
              }}
            /> */}

            <TextField
              id="datetime_local_installation_date"
              label="Installation date"
              type="datetime-local"
              defaultValue={this.state.installation_date.format()}
              className={classes.textField}
              onChange={e => {
                this.setState({installation_date: moment(e.target.value)})
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: '100%',
                marginTop: 10
              }}
            />

            <TextField
              id="datetime_local_commissioning_date"
              label="Commissioning date"
              type="datetime-local"
              defaultValue={this.state.commissioning_date.format()}
              className={classes.textField}
              onChange={e => {
                this.setState({commissioning_date: moment(e.target.value)})
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: '100%',
                marginTop: 10
              }}
            />

            <TextField
              id="datetime_local_operation_date"
              label="Operation date"
              type="datetime-local"
              defaultValue={this.state.operation_date.format()}
              className={classes.textField}
              onChange={e => {
                this.setState({operation_date: moment(e.target.value)})
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                width: '100%',
                marginTop: 10
              }}
            />

            <div
              style={{
                position: 'fixed',
                right: 20,
                bottom: 10
              }}
            >
              <Button
                variant='fab'
                color="primary"
                type='submit'
              >
                <Send />
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(AssetChangePage)
