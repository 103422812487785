import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import InventoryAssetsDetailViewComponent from './components/InventoryAssetsDetailViewComponent/InventoryAssetsDetailViewComponent';
import InventoryAssetsDetailTabsComponent from './components/InventoryAssetsDetailTabsComponent/InventoryAssetsDetailTabsComponent';

const styles = theme => ({
  rootSlots: {
    borderTop: `1px solid ${grey['200']}`,
  }
})

class InventoryAssetsDetailComponent extends Component {
  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {/* {this.props.selectedModel ? this.props.selectedModel.name : ''} */}
        <div
          style={{
            flex: 2,
            overflow: 'auto'
          }}
        >
          <InventoryAssetsDetailViewComponent
            asset={this.props.asset}
          />
        </div>
        <div
          className={classes.rootSlots}
          style={{
            flex: '4',
            maxHeight: '80%'
          }}
        >
          <InventoryAssetsDetailTabsComponent
            asset={this.props.asset}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(InventoryAssetsDetailComponent)
