import React, { Component } from 'react';

import {
    withStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    Input,
    InputLabel,
    Icon
} from '@material-ui/core';

import RestoreIcon from '@material-ui/icons/Restore';
import DeleteIcon from '@material-ui/icons/Delete';
import WidgetIcon from '@material-ui/icons/Widgets';
import PageIcon from '@material-ui/icons/NoteAdd';
import GraphicsCategory from '../../data/META/graphics-registry-category.json';
import Grid from '@material-ui/core/Grid';
import Select2 from '../../components/Menu/Select';
import * as R from 'ramda';
import { graphicsForNewWidget } from '../../constants/graph-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import pageTypes from '../../data/META/page-type.json';

import { 
    editModeChangeTitle,
    resetChange, 
    editModeChangePosition, 
    editModeRemoveWidget, 
    editModeNewWidget, 
    editModeEditWidget, 
    editModeRemove, 
    editModeNew 
} from '../../actions/EditModeAction';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DeleteDialog from '../../modules/Dashboard/pages/EditMode/DeleteDialog';
import DeleteWidgetDialog from '../../modules/Dashboard/pages/EditMode/DeleteWidgetDialog';
import { MultioptionsFab } from '../../new-components/Buttons';

const images_context = require.context('../../assets/preview_images/', false, /\.(png)|(svg)$/);
const images_keys = images_context.keys().map(key => key);
const images = {};

images_keys.map(key => {
    let treated_key = key.split('/');
    treated_key = treated_key[treated_key.length - 1].split('.')[0];
    images[treated_key] = images_context(key);
})

const styles = {
    buttonRight: {
        borderRadius: 0,
        textTransform: 'capitalize',
        fontSize: 12
    },
    buttonLeft: {
        borderRadius: 0,
        textTransform: 'capitalize',
        height: '100%'
    }
};

class EditableContainer extends Component {
    constructor (props) {
        super (props);

        this.state = {
            widgets: [],
            widgetEdit:[],
            wtgUnique:false,
            dialogNewWidget:false,
            selectedWidget:[],
            dialogDeleteOverview:false,
            dialogDelete:false,
            dialogRefresh:false,
            widgetNew:[],
            pageNew:false,
            loading:false,
            edited:false,
            managementSelected:[]
        };
    }

    componentDidMount () {
        const path_tree = window.location.href;
        const route = path_tree.split('/');
        const module_name = route[5];

        const REDUCER_NAME = module_name === 'virtual-clone' ?  
            'envelope' 
        : 
            module_name;

        const wtgUnique = REDUCER_NAME === 'envelope' || 
            REDUCER_NAME ===  'device' ? 
                true 
            : 
                false;

        const Page = this.props[REDUCER_NAME];
        let  managementSelected = {};  

        if (Page) {
            managementSelected =  R.find(R.propEq('selected', true))(Page)
        }

        this.setState({
            managementSelected,
            wtgUnique
        });
    }

    openDialogDeleteOverview = () => {
        this.setState({
            dialogDeleteOverview: true
        });
    }

    openDialogNew = () => {
        this.setState({
            dialogNewWidget: true,
            widgets: [],
            selectedWidget:[],
            dialogRefresh:false,
            widgetNew:[],
            deleteWidgetId:null,
            pageNew:false,
            dialogDelete:false,
            loading:false,
            edited:false,
            GraphicsCategory:GraphicsCategory,
            selectedWidget:null,
            widgetNew: {}
        });
    }

    changeValueTitleEdit = event => {
        const itemTitle = {
            widgetEdit: {
                ...this.state.widgetEdit,
                titleHeader: event.target.value
            }
        };

        this.setState(itemTitle);
    }

    closeDialogResetChanges = () => {
        this.setState({
            dialogRefresh: false
        });
    }

    saveNewPage = (management, reducer_name, action) => () => {
        let value = this.props.editModeNew(management.id, reducer_name, action, this.state.pageNew, this.props);
        this.props.updateProps(reducer_name.toLowerCase(), value);
        this.closeDialogNewPage();
    }

    changeLoading = loading => {
        if (this.state.loading != loading) {
            this.setState({
                loading
            });
        }
    };

    filterByPageType(options, page) {
        let final_options = [];

        if (page) {
            options.forEach(function(category) {
                if (!category.finalOptions) {
                    category.finalOptions = [];
                }

                category.finalOptions = [];
                category.options.forEach(function(opt) {
                    if(!opt.disabled) {
                        if (
                            opt.page_type && opt.page_type.includes(page.type) ||
                            opt.page_type && opt.page_type.includes(page.page_type)
                        ) {
                            category.finalOptions.push(opt);
                        }
                    }
                });

                let new_category = {...category};
                new_category.options = category.finalOptions;
                final_options.push(new_category);
            });
        }

        return final_options;
    }


    openDialogRemove = widget_id => () => {
        this.setState({
            deleteWidgetId: widget_id,
            dialogDelete: true
        });
    }

    calculatePositionWidget = widgets => {
        let final_position = {};

        if (widgets.length > 0) {
            let coordinates = [];

            widgets.forEach(function(widget) {
                if(coordinates[widget.grid.y]) {
                    coordinates[widget.grid.y].push(widget.grid.x);
                } else {
                    coordinates[widget.grid.y] = [];
                    coordinates[widget.grid.y].push(widget.grid.x);
                }
            });

            let last_y_index = coordinates.length -1 ;
            let last_y = coordinates.pop();
            let last_x = Math.max.apply(null, last_y);

            if (last_x + 4 < 12 ) {
                final_position.x = last_x + 4;
                final_position.y = last_y_index;
            } else {
                final_position.x = 0;
                final_position.y = last_y_index + 2;
            }

            return final_position;
        }

        return final_position;
    }

    changeValueSelectTypeNew = (widget) => {
        this.setState({
            selectedWidget: widget
        });

        this.setState(state => {
            const newItem = {
                widgetNew: {
                    ...state.widgetNew,
                    type: widget.value
                }
            };

            return newItem;
        })
    }

  
    saveNewItem = (management, reducer_name, action, grids=null) => () => {
        const positions = this.calculatePositionWidget(management.widgets);
        let new_widget = this.state.widgetNew;

        new_widget.grid = {
            x: positions.x ? positions.x : 0,
            y: positions.y ? positions.y : 0,
            w: 4,
            h: 2
        };

        let value = this.props.editModeNewWidget(
            management.id, 
            reducer_name, 
            action, 
            new_widget, 
            this.props
        );

        this.props.updateProps(reducer_name.toLowerCase(), value);
        this.closeDialogNew();
    }


    saveUpdateItem = (management_id, reducer_name, action) => () => {
        let value = this.props.editModeEditWidget(
            management_id, 
            reducer_name, 
            action, 
            this.state.widgetEdit, 
            this.props
        );

        this.props.updateProps(reducer_name.toLowerCase(), value);
        this.closeDialogEdit();
    }

    changeValueTitleNew = (grids, event) => {
        const positions = this.calculatePositionWidget(grids);

        const itemTitle = {
            widgetNew: {
                ...this.state.widgetNew,
                titleHeader: event.target.value,
                grid: {
                    "x": positions.x,
                    "y": positions.y,
                    "w": 4,
                    "h": 2
                }
            }
        };

        this.setState(itemTitle);
    }

    changeValueNavigateTypeNew = (event, widget) => {
        this.setState({
            selectedWidget: widget
        });

        this.setState(state => {
            const newItem = {
                widgetNew: {
                    ...state.widgetNew,
                    type: widget.value
                }   
            };

            return newItem;
        });
    }

    changeValueNavigateTypeNew = (event, widget) => {
        this.setState({
            selectedWidget: widget
        });

        this.setState(state => {
            const newItem = {
                widgetNew: {
                    ...state.widgetNew,
                    type: widget.value
                }
            };

            return newItem;
        });
    }

    changeValueSelectTypeEdit = widget => {
        this.setState({
            selectedWidget: widget
        });

        this.setState(state => {
            const newItem = {
                widgetEdit: {
                    ...state.widgetEdit,
                    type: widget.value
                }
            };

            return newItem;
        });
    }

    changeValueTypeNewPage = (selected, managementSelected ) => {
        const itemTitle = {
            pageNew: {
                ...this.state.pageNew,
                type: selected.id
            }
        };

        this.setState(itemTitle);
    }

    changeValueTitleNewPage = (managementSelected, event) => {
        const itemTitle = {
            pageNew: {
                ...this.state.pageNew,
                name: event.target.value,
                filter: managementSelected.filter
            }
        };

        this.setState(itemTitle);
    }

    openDialogResetChanges = () => {
        this.setState({
            dialogRefresh: true
        });
    }

    openDialogNewPage = () => {
        this.setState({
            dialogNewPage: true,
            selectedWidget:null,
            pageNew: {}
        });
    }

    closeDialogNew = () => {
        this.setState({
            dialogNewWidget: false,
            widgetNew: {}
        });
    }

    closeDialogNewPage = () => {
        this.setState({
            dialogNewPage: false,
            pageNew:{}
        });
    }

    closeDialogRemove = () => {
        this.setState({
            dialogDelete: false
        });
    }

    resetChanges = () => {
        window.location.reload();
    }

    render() {
        const children = React.Children.map(this.props.children, (child, index) => {
            return React.cloneElement(child, {
                index,
                openDialogRemove: this.openDialogRemove
            });
        });

        const { classes } = this.props;
        let positions = [];

        const path_tree = window.location.href;
        const route = path_tree.split('/');
        const module_name = route[5];

        const REDUCER_NAME = module_name === 'virtual-clone' ?  
            'envelope' 
        : 
            module_name;

        const Page = this.props[REDUCER_NAME];
        let  managementSelected = {};  

        if (Page) {
            managementSelected =  R.find(R.propEq('selected', true))(Page);
        }

        return (
            <React.Fragment>
                <div style={{overflowY:'auto', overflowX: 'auto'}}>
                    { children }
                </div>
               {
                   Page &&
                        <div>
                            {/* DELETE WIDGET */}
                            <DeleteWidgetDialog
                                {...this.props}  
                                deleteWidgetId={ this.state.deleteWidgetId }
                                dialogDelete={ this.state.dialogDelete }  
                                closeDialogRemove={ this.closeDialogRemove }
                            />

                            <div style={{ display: this.props.editMode ? 'block' : 'none' }}>
                                <MultioptionsFab 
                                    baseIcon={<Icon>create</Icon>}
                                    openedIcon={<Icon>lock_opened</Icon>}
                                    activatedIcon={<Icon>lock</Icon>}
                                    style={{
                                        background: '#28a745'
                                    }}
                                    Hidden={
                                        !this.props.editMode
                                    }
                                    actions={[
                                        ...(
                                            !this.state.wtgUnique ?
                                            [
                                                {
                                                icon: <PageIcon />,
                                                name: 'New Page',
                                                onClick: this.openDialogNewPage
                                                }
                                            ]
                                            :
                                            []
                                        ),
                                        {
                                            icon: <WidgetIcon />,
                                            name: 'New Widget',
                                            onClick: this.openDialogNew
                                        },
                                        {
                                            icon: <RestoreIcon />,
                                            name: 'Reset Changes',
                                            onClick: this.openDialogResetChanges
                                        },
                                        ...(
                                            !this.state.wtgUnique ?
                                            [
                                                {
                                                icon: <DeleteIcon />,
                                                name: 'Delete Overview',
                                                onClick: () => this.setState({
                                                    dialogDeleteOverview: true
                                                })
                                                }
                                            ]
                                            :
                                            []
                                        )
                                    ]}
                                />
                                <div>
                                    <div
                                        style={{
                                            flex: 1
                                        }}
                                    >
                                        {/* NEW WIDGET */}
                                        <Dialog
                                            onClose={ this.closeDialogNew }
                                            open={ this.state.dialogNewWidget }
                                            aria-labelledby='dialog_widget_new'
                                            fullWidth={ true }
                                            maxWidth='md'
                                            style={{
                                                // borderTop:'20px'
                                            }}
                                        >
                                                <DialogTitle id='dialog_widget_new'>
                                                    New Widget
                                                </DialogTitle>
                                                <DialogContent>
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={7} >
                                                            <div>
                                                                <FormControl 
                                                                    className={classes.formControl} 
                                                                    style={{
                                                                        width:'250px'
                                                                    }}
                                                                >
                                                                    <InputLabel htmlFor='widget-title-new'>
                                                                        Title
                                                                    </InputLabel>
                                                                    <Input
                                                                        id='widget-title-new'
                                                                        value={ this.state.widgetNew.titleHeader }
                                                                        onChange={ this.changeValueTitleNew.bind(this, positions) }
                                                                    />
                                                                </FormControl>
                                                                <br/>
                                                                <br/>
                                                                <FormControl
                                                                    className={classes.formControl}
                                                                    style={{
                                                                        width:'250px'
                                                                    }}
                                                                >
                                                                    <Select2 
                                                                        options={
                                                                            this.filterByPageType(
                                                                                GraphicsCategory, 
                                                                                managementSelected
                                                                            )
                                                                        }   
                                                                        label='Widget Type'
                                                                        value={ this.state.selectedWidget }
                                                                        onChange={ this.changeValueSelectTypeNew.bind(this) }
                                                                        onKeyDown={ this.changeValueNavigateTypeNew.bind(this) }
                                                                        inputProps={{
                                                                            name: 'widgetType',
                                                                            id: 'widget-type-select'
                                                                        }}
                                                                        renderValue={selected => {
                                                                            if (selected) {
                                                                                const type = R.find(R.propEq('type', selected))(graphicsForNewWidget);
                                                                                return type.description;
                                                                            }

                                                                            return 'None';
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Card 
                                                                className={classes.card} 
                                                                style={{
                                                                    display: this.state.selectedWidget ? 
                                                                        'block'
                                                                    :
                                                                        'none', 
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <CardMedia
                                                                    className={classes.media}
                                                                />
                                                                <br/>
                                                                <br/>
                                                                <CardContent>
                                                                    <Typography 
                                                                        gutterBottom
                                                                        variant='h6'
                                                                        component='h6'
                                                                    >
                                                                        Preview
                                                                    </Typography>
                                                                    {
                                                                        this.state.selectedWidget ?
                                                                            <img 
                                                                                src={
                                                                                    images[
                                                                                        'preview' in this.state.selectedWidget ? 
                                                                                            this.state.selectedWidget.preview 
                                                                                        : 
                                                                                            this.state.selectedWidget.value
                                                                                    ]
                                                                                } 
                                                                                style={{
                                                                                    width:'100%'
                                                                                }}
                                                                            />
                                                                        :
                                                                            null
                                                                    }
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </Grid>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={this.closeDialogNew} color='primary'>
                                                        Cancel
                                                    </Button>
                                                    {
                                                        true ?
                                                            <Button
                                                                onClick={
                                                                    this.saveNewItem(
                                                                        managementSelected, 
                                                                        REDUCER_NAME, 
                                                                        'ACTION_GET_DATA', 
                                                                        positions
                                                                    ).bind(this)
                                                                }
                                                                color='primary'
                                                            >
                                                                Save
                                                            </Button>
                                                        : 
                                                            ''
                                                    }
                                                </DialogActions>
                                        </Dialog>

                                        {/* EDIT WIDGET */}
                                        <Dialog
                                            onClose={ this.closeDialogEdit }
                                            open={ this.state.dialogEditWidget }
                                            aria-labelledby='dialog_widget_edit'
                                            fullWidth={ true }
                                            maxWidth='md'
                                        >
                                            <DialogTitle id='dialog_widget_edit'>
                                                Edit Widget
                                            </DialogTitle>
                                            <DialogContent>
                                                <Grid container spacing={24}>
                                                    <Grid item xs={7}>
                                                        <div>
                                                            <FormControl className={classes.formControl}>
                                                                <InputLabel htmlFor='widget-title-edit'>
                                                                    Title
                                                                </InputLabel>
                                                                <Input
                                                                    id='widget-title-new'
                                                                    value={ this.state.widgetEdit.titleHeader }
                                                                    onChange={ this.changeValueTitleEdit.bind(this) }
                                                                />
                                                            </FormControl>
                                                            <FormControl
                                                                className={classes.formControl}
                                                            >
                                                                <Select2
                                                                    options={
                                                                        this.filterByPageType(
                                                                            GraphicsCategory, 
                                                                            managementSelected
                                                                        )
                                                                    }
                                                                    label='Tipo de widget'
                                                                    value={ this.state.selectedWidget }
                                                                    onChange={ this.changeValueSelectTypeEdit.bind(this) }
                                                                    onKeyDown={ this.changeValueNavigateTypeNew.bind(this) }
                                                                    inputProps={{
                                                                        name: 'widgetType',
                                                                        id: 'widget-type-select'
                                                                    }}
                                                                    renderValue={selected => {
                                                                        if (selected) {
                                                                            const type = R.find(R.propEq('type', selected))(graphicsForNewWidget);
                                                                            return type.description;
                                                                        }

                                                                        return 'None';
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Card 
                                                            className={classes.card} 
                                                            style={{
                                                                display: this.state.selectedWidget ? 
                                                                    'block'
                                                                :
                                                                    'none'
                                                            }}
                                                        >
                                                            <CardMedia
                                                                className={classes.media}
                                                                image= {
                                                                    this.state.selectedWidget ?
                                                                        images[
                                                                            'preview' in this.state.selectedWidget ? 
                                                                                this.state.selectedWidget.preview 
                                                                            : 
                                                                                this.state.selectedWidget.value
                                                                        ]
                                                                    :
                                                                        null
                                                                }
                                                                title=''
                                                            />
                                                            <CardContent>
                                                                <Typography 
                                                                    gutterBottom 
                                                                    variant='h6' 
                                                                    component='h6'
                                                                >
                                                                    Preview
                                                                </Typography>
                                                                {
                                                                    this.state.selectedWidget ?
                                                                        <img 
                                                                            src={
                                                                                images[
                                                                                    'preview' in this.state.selectedWidget ? 
                                                                                        this.state.selectedWidget.preview 
                                                                                    : 
                                                                                        this.state.selectedWidget.value
                                                                                ]
                                                                            } 
                                                                            style={{
                                                                                width:'100%'
                                                                            }}
                                                                        />
                                                                    :
                                                                        null
                                                                }
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={this.closeDialogEdit} color='primary'>
                                                    Cancel
                                                </Button>
                                                {
                                                    true ?
                                                    <Button
                                                        onClick={this.saveUpdateItem(
                                                            1, 
                                                            'REDUCER_NAME', 
                                                            'ACTION_GET_DATA'
                                                            ).bind(this)
                                                        }
                                                        color='primary'
                                                    >
                                                        Update
                                                    </Button>
                                                : 
                                                    ''
                                                }
                                            </DialogActions>
                                        </Dialog>

                                        {/* NEW PAGE */}
                                        <Dialog
                                            onClose={ this.closeDialogNewPage }
                                            open={ this.state.dialogNewPage }
                                            aria-labelledby='dialog_widget_new'
                                            fullWidth={ true }
                                            maxWidth='md'
                                        >
                                            <DialogTitle id='dialog_widget_new'>
                                                New Page
                                            </DialogTitle>
                                            <DialogContent>
                                                <Grid container spacing={24}>
                                                    <Grid item xs={12}>
                                                        <div>
                                                            <FormControl 
                                                                className={classes.formControl} 
                                                                style={{
                                                                    width:'250px'
                                                                }}
                                                            >
                                                                <InputLabel htmlFor='widget-title-new'>
                                                                    Title
                                                                </InputLabel>
                                                                <Input
                                                                    id='widget-title-new'
                                                                    value={ this.state.pageNew.tile }
                                                                    onChange={ this.changeValueTitleNewPage.bind(this, 1) }
                                                                />
                                                            </FormControl>
                                                            <br/>
                                                            <br/>
                                                            <FormControl
                                                                className={classes.formControl}
                                                                style={{
                                                                    width:'250px'
                                                                }}
                                                            >
                                                                <Select2 
                                                                    options={pageTypes} 
                                                                    label='Tipo de pagina'
                                                                    onChange={ this.changeValueTypeNewPage.bind(this) }
                                                                    onKeyDown={ this.changeValueNavigateTypeNew.bind(this) }
                                                                    inputProps={{
                                                                        name: 'widgetType',
                                                                        id: 'widget-type-select'
                                                                    }}
                                                                    renderValue={selected => {
                                                                        if (selected) {
                                                                            const type = R.find(R.propEq('type', selected))(pageTypes);
                                                                            return type.description;
                                                                        }

                                                                        return 'None';
                                                                    }}

                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={ this.closeDialogNewPage } color='primary'>
                                                    Cancel
                                                </Button>
                                                {
                                                    true ? 
                                                        <Button
                                                            onClick={ this.saveNewPage(
                                                                managementSelected, 
                                                                REDUCER_NAME, 
                                                                'ACTION_GET_DATA'
                                                                ).bind(this)
                                                            }
                                                            color='primary'
                                                        >
                                                            Save
                                                        </Button>
                                                : 
                                                    ''
                                                }
                                            </DialogActions>
                                        </Dialog>

                                        {/* RESET ACTION */}
                                        <Dialog
                                            onClose={ this.closeDialogResetChanges }
                                            open={ this.state.dialogRefresh }
                                            aria-labelledby='dialog_widget_new'
                                            fullWidth={ false }
                                            maxWidth='md'
                                        >
                                            <DialogTitle id='confirmation-dialog-title'>
                                                Do you want to reset your changes?
                                            </DialogTitle>
                                            <DialogActions>
                                                <Button 
                                                    variant='contained' 
                                                    onClick={ this.closeDialogResetChanges } 
                                                    style={{
                                                        backgroundColor: '#e65100', 
                                                        color: '#fff'
                                                    }}
                                                >
                                                    No
                                                </Button>
                                                <Button 
                                                    onClick={e => this.resetChanges(
                                                        1, 
                                                        'REDUCER_NAME', 
                                                        'ACTION_GET_DATA'
                                                    )}
                                                >
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        {/* DELETE OVERVIEW ACTION */}
                                        {
                                            this.props.editMode && 
                                            !this.state.wtgUnique && 
                                                <DeleteDialog  
                                                    open={ this.state.dialogDeleteOverview }
                                                    closeDialog={() => this.setState({ dialogDeleteOverview: false })}
                                                    {...this.props} 
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({
    Dashboard, 
    User, 
    Application, 
    Navigation
}) => {
    const { Page } = Dashboard;
    const { parks_info } = User;

    return { 
        Dashboard, 
        Page,  
        parks: parks_info, 
        Application, 
        Navigation 
    };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    editModeChangeTitle,
    editModeChangePosition,
    editModeRemoveWidget,
    editModeNewWidget,
    editModeEditWidget,
    editModeRemove,
    editModeNew,
    resetChange
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditableContainer));
