import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import moment from 'moment-timezone'
import {
  withStyles,
  Button
} from '@material-ui/core'


const GRID_WIDTH_PADDING = 10

const styles = theme => ({
  gridElement: {
    width: '100%'
  }
})

class HydroHeader extends Component {
  constructor (props) {
    super (props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    this.state = {
      gridWidth: elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),
      subpark:this.props.subpark,
    }
  }




  shouldComponentUpdate(nextProps, nextState){

    if (this.props.subpark != nextProps.subpark){

      return true;
    }

    return false;

  }



  render () {
    const {classes} = this.props
 

    return (      
      <React.Fragment>
        <div
          style={{marginTop: 8, display: 'flex', backgroundColor:'#a333333'}}
        >
          <div style={{flex: 5}}></div>
          <h4 style={{flex: 1, textAlign: 'right', color: 'white'}}>Usinas</h4>
          <Button 
            style={{
              flex: 1, 
              backgroundColor: this.props.subpark == 1 ? '#d9d9d9' : '#4d4d4d' , 
              color: this.props.subpark == 1 ? 'black' : 'white',
              margin: 8,
              marginRight: 0
            }}
            onClick={() => this.props.clickPark(1)}
          >
            Pipoca            
          </Button>
          <Button 
            style={{
              flex: 1, 
              backgroundColor: this.props.subpark == 2 ?  '#d9d9d9' : '#4d4d4d', 
              color: this.props.subpark == 2 ? 'black' : 'white' ,
              margin: 8,
              marginLeft: 0
            }}
            onClick={() => this.props.clickPark(2)}
          >
            Serra Das Agulhas        
          </Button>
        </div>        
      </React.Fragment>
    )
  }
}


export default withStyles()(HydroHeader)