import React, {Component} from 'react'

class FilterEnvelope extends Component {
  render () {
    return (
      <div>
        FilterEnvelope
      </div>
    )
  }
}

export default FilterEnvelope
