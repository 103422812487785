import React from 'react'
import { withStyles, Tooltip, LinearProgress } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment-timezone'
import * as R from 'ramda'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import getStatusColorRealtime, { getStatusByTag } from '../../../../../../../../helpers/get-status-color-realtime'
import { ROUTE_MAIN_DEVICE } from '../../../../../../../../constants/route-constant';

const WIDGET_HEADER_HEIGHT = 40

const styles = {
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  tableTh: {
    color: grey['500'],
    textAlign: 'center',
    padding: '8px'
  },
  tableTd: {
    textAlign: 'center',
    padding: '6px',
    color: grey['400']
  }
}

class OPCPortfolioItemWtg extends React.Component {
  render() {
    const { classes } = this.props




    const WtgAll = this.props.item ?
      R.sum(this.props.item.wtgs.map((wtg) => {


        const stateTag = R.find(R.propEq('var_id', 1000))(wtg.realtime) || R.find(R.propEq('var_id', 3))(wtg.realtime)
        const state = stateTag ? stateTag.value : ''

        const maintenanceTag = R.find(R.propEq('var_id', 4))(wtg.realtime)
        const maintenance = maintenanceTag ? maintenanceTag.value_bool : ''

        const powerLimitation = wtg.realtime.filter(R.propEq('is_alarm', true)).some(({ value_bool }) => value_bool)

        const status = getStatusColorRealtime(state, wtg.model_id, maintenance, powerLimitation)

        return 1;

      }))
      :
      0

    const WtgStoppedAll = this.props.item ?
      R.sum(this.props.item.wtgs.map((wtg) => {


        const stateTag = R.find(R.propEq('var_id', 1000))(wtg.realtime) || R.find(R.propEq('var_id', 3))(wtg.realtime)
        const state = stateTag ? stateTag.value : ''

        const maintenanceTag = R.find(R.propEq('var_id', 4))(wtg.realtime)
        const maintenance = maintenanceTag ? maintenanceTag.value_bool : ''

        const powerLimitation = wtg.realtime.filter(R.propEq('is_alarm', true)).some(({ value_bool }) => value_bool)

        let status = {}

        if (stateTag && stateTag.value_string) {
          status = getStatusByTag(stateTag.value_string)
          if (status.color && (status.color == 'red' || status.color == 'blue' || status.color == 'purple' || status.color == 'grey')) {
            return 1;
          }
        } else {
          status = getStatusColorRealtime(state, wtg.model_id, maintenance, powerLimitation)
          if (status.color && status.color == 'red') {
            return 1;
          }
        }

        return 0;

      }))
      :
      0

    const powerAll = this.props.item.wtgs && this.props.item.wtgs.length > 0 ?
      (
        R.sum(this.props.item.wtgs.map((item) => {
          const powerTag = R.find(R.propEq('var_id', 1))(item.realtime)
          const power = powerTag ? powerTag.value : ''

          return power
        }))
      )
      :
      0
    const windAll = this.props.item.wtgs && this.props.item.wtgs.length > 0 ?
      (
        R.median(this.props.item.wtgs.map((item) => {
          const windTag = R.find(R.propEq('var_id', 2))(item.realtime)
          const wind = windTag ? windTag.value : ''

          return wind
        }))
      )
      :
      0

    return (
      <div
        style={{
          color: 'white',
          border: `1px solid ${grey['900']}`,
          width: '100%',
          height: '100%'
        }}
      >
        <div
          className="move-grid-widget"
          style={{
            height: WIDGET_HEADER_HEIGHT,
            width: '100%',
            borderBottom: `1px solid ${grey['900']}`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: 'white',
            flexFlow: 'row wrap'
          }}
        >
          <div
            style={{
              marginLeft: 10
            }}
          >
            {this.props.item.name}
          </div>


          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginLeft: 20
            }}
          >
            WTG Availability:
            <span
              style={{
                marginLeft: 5,
                fontWeight: 900
              }}
            >
              <span>{WtgAll - WtgStoppedAll}</span> / <span>{WtgAll}</span>
            </span>
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginRight: 10,
              flexFlow: 'row wrap'
            }}
          >
            <div>
              Power (MW)
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 900,
                  color: 'white'
                }}
              >
                {(powerAll / 1000).toFixed(1)}
              </span>
            </div>
            <div
              style={{
                marginLeft: 10
              }}
            >
              /
            </div>
            <div
              style={{
                marginLeft: 10
              }}
            >
              Wind Speed (m/s)
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 900,
                  color: 'white'
                }}
              >
                {windAll.toFixed(1)}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
            width: '100%',
            overflow: 'auto'
          }}
        >
          <table className={classes.table}>
            <thead>
              <tr>
                <th
                  className={classes.tableTh}
                >
                  Turbine
                </th>
                <th
                  className={classes.tableTh}
                  colSpan={2}
                  style={{
                    width: 220
                  }}
                >
                  Power (kW)
                </th>
                <th
                  className={classes.tableTh}
                  colSpan={2}
                  style={{
                    width: 220
                  }}
                >
                  Wind Speed (m/s)
                </th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.item.wtgs && this.props.item.wtgs.length > 0 ?
                  (
                    R.reverse(_.sortBy(this.props.item.wtgs, ['name'])).map((item, indexDevice) => {
                      const powerTag = R.find(R.propEq('var_id', 1))(item.realtime)
                      const power = powerTag ? powerTag.value : ''
                      const power_time = powerTag ? powerTag.time : null

                      const maintenanceTag = R.find(R.propEq('var_id', 4))(item.realtime)
                      const maintenance = maintenanceTag ? maintenanceTag.value_bool : ''

                      const stateTag = R.find(R.propEq('var_id', 1000))(item.realtime) || R.find(R.propEq('var_id', 3))(item.realtime)
                      const state = stateTag ? stateTag.value : ''
                      const state_time = stateTag ? stateTag.time : null
                      const change_time = stateTag ? stateTag.updated_at : null

                      const windTag = R.find(R.propEq('var_id', 2))(item.realtime)
                      const wind = windTag ? windTag.value : ''
                      const wind_time = windTag ? windTag.time : null

                      const powerLimitation = item.realtime.filter(R.propEq('is_alarm', true)).some(({ value_bool }) => value_bool)

                      let status = {}

                      if (stateTag && stateTag.value_string) {
                        status = getStatusByTag(stateTag.value_string)
                      } else {
                        status = getStatusColorRealtime(state, item.model_id, maintenance, powerLimitation)
                      }

                      const powerBarValue = ((100 / item.model.specs.rated_power) * power);
                      return (
                        <tr
                          key={indexDevice}
                          style={{
                            backgroundColor: indexDevice % 2 === 0 ? grey['900'] : null
                          }}
                        >
                          <td
                            className={classes.tableTd}
                          >
                            <Tooltip
                              placement="right"
                              title={(
                                <React.Fragment>
                                  <div>
                                    <span>Status:</span>
                                    <br />
                                    <span>{status.name}</span>
                                  </div>
                                  <div>
                                    <span>Last update:</span>
                                    <br />
                                    <span>{moment(state_time).format("YYYY/MM/DD HH:mm:ss")}</span>
                                  </div>
                                  {
                                    change_time &&
                                    <>
                                      <div>
                                        <span>Last changed:</span>
                                        <br />
                                        <span>{moment(change_time).format("YYYY/MM/DD HH:mm:ss")}</span>
                                      </div>
                                      <div>
                                        <span>{`${status.name} for:`}</span>
                                        <br />
                                        <span>{moment.duration(moment(state_time).diff(moment(change_time))).humanize()}</span>
                                      </div>
                                    </>
                                  }
                                </React.Fragment>
                              )}
                            >
                              <Link
                                to={`${ROUTE_MAIN_DEVICE}?wtgs=[${item.id}]`}
                                style={{
                                  textDecoration: 'none'
                                }}
                              >
                                <span
                                  style={{
                                    display: 'block',
                                    minWidth: 75,
                                    color: 'black',
                                    fontWeight: 900,
                                    backgroundColor: status.color,
                                    borderRadius: 4,
                                    padding: '1px 5px',
                                  }}
                                >
                                  {item.name}
                                </span>
                              </Link>
                            </Tooltip>
                          </td>
                          <td
                            className={classes.tableTd}
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            <Tooltip
                              placement="right"
                              title={(
                                <div>
                                  <span>
                                    Last update:
                                </span>
                                  <br />
                                  <span>
                                    {moment(power_time).format("YYYY/MM/DD HH:mm:ss")}
                                  </span>
                                </div>
                              )}
                            >
                              <span>
                                {`${Number(power).toFixed(1)}`}
                              </span>
                            </Tooltip>
                          </td>
                          <td
                            className={classes.tableTd}
                            style={{
                              minWidth: 50
                            }}
                          >
                            <LinearProgress
                              variant="determinate"
                              value={powerBarValue > 100 ? 100 : powerBarValue}
                            />
                          </td>
                          <td
                            className={classes.tableTd}
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            <Tooltip
                              placement="right"
                              title={(
                                <div>
                                  <span>
                                    Last update:
                                </span>
                                  <br />
                                  <span>
                                    {moment(wind_time).format("YYYY/MM/DD HH:mm:ss")}
                                  </span>
                                </div>
                              )}
                            >
                              <span>
                                {`${Number(wind).toFixed(1)}`}
                              </span>
                            </Tooltip>
                          </td>
                          <td
                            className={classes.tableTd}
                            style={{
                              minWidth: 50
                            }}
                          >
                            <LinearProgress
                              variant="determinate"
                              value={((100 / 20) * wind)}
                            />
                          </td>
                        </tr>
                      )
                    })
                  )
                  : (
                    <tr>
                      <td colSpan={5}>
                        No data...
                    </td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(OPCPortfolioItemWtg)