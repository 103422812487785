import React from 'react'
import styled from 'styled-components'
import grey from '@material-ui/core/colors/grey'
import {withStyles} from '@material-ui/core/styles'
import {IconButton, Icon, Dialog, DialogTitle, TextField, Button, DialogActions} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Link, Route} from 'react-router-dom'
import * as R from 'ramda'
import {toast} from 'react-toastify'

import { getAssetModelProvider, createAssetModelProvider, updateAssetModelProvider, deleteAssetModelProvider } from '../../../../../../providers/asset-provider'
import { ROUTE_DASHBOARD_ASSET_ASSET_MODEL } from '../../../../../../constants/route-constant';
import AssetModelContentComponent from './components/AssetModelContentComponent/AssetModelContentComponent';

const styles = {
  button: {
    borderRadius: 0,
    height: '100%',
    borderRight: `1px solid ${grey['200']}`
  },
  inputSearch: {
    '&:focus': {
      outline: 'none'
    }
  },
  textField: {
    width: '100%'
  }
}

const ButtonSidebar = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  &:hover {
    background-color: ${grey['200']}!important;
    cursor: pointer;
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: black;
  flex: 1 0;
`

class AssetModelPage extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      loading: false,
      loadingDialog: false,
      assetModel: [],
      searchAssets: '',
      newName: '',
      newManufacturer: '',
    }
  }

  componentDidMount () {
    this.setState({loading: true})

    getAssetModelProvider()
      .then((assetModel) => {
        this.setState({
          assetModel,
          loading: false
        })
      })
      .catch(error => {
        console.error('getAssetModelProvider', error)
        this.setState({loading: false})
      })
  }

  setParam = (data, callback = null) => this.setState(data, () => {
    if (callback) {
      callback()
    }
  })

  render () {
    const {classes} = this.props

    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress className={classes.progress} />

          <span
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </span>
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            minWidth: 300
          }}
        >

          <div
            style={{
              height: 40,
              width: '100%',
              borderBottom: `1px solid ${grey['200']}`,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              className={classes.inputSearch}
              onChange={e => this.setState({searchAssets: e.target.value})}
              style={{
                // height: '100%',
                // width: '100%',
                flex: '1 0',
                border: 'none',
                margin: 0,
                padding: 0,
                paddingLeft: 10
              }}
            />
            <Tooltip
              title="New item"
            >
              <IconButton
                className={classes.button}
                onClick={() => this.setState({dialogNewItem: true})}
              >
                <Icon>
                  add
                </Icon>
              </IconButton>
            </Tooltip>
          </div>

          <div
            style={{
              height: 'calc(100% - 40px)',
              widows: '100%',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            {
              this.state.assetModel
                .filter(assetModel => {
                  if (this.state.searchAssets.length > 1) {
                    const result = R.toUpper(assetModel.name).search(R.toUpper(this.state.searchAssets))
                    return result >= 0
                  } else {
                    return true
                  }
                })
                .map((assetModel, indexAssetModel) => {
                  return (
                    <div
                      key={indexAssetModel}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: `1px solid ${grey['200']}`
                      }}
                    >
                      <LinkStyled
                        to={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${assetModel.id}`}
                      >
                        <ButtonSidebar>
                          <span>
                            {assetModel.name}
                          </span>
                        </ButtonSidebar>
                      </LinkStyled>
                      <Tooltip
                        title="Edit"
                      >
                        <IconButton
                          // className={classes.button}
                          onClick={() => {
                            this.setState({
                              dialogEdit: true,
                              newName: assetModel.name,
                              newManufacturer: assetModel.manufacturer,
                              newId: assetModel.id,
                            })
                          }}
                          style={{
                            minHeight: '48px',
                            borderRadius: 0,
                            borderLeft: `1px solid ${grey['200']}`
                          }}
                        >
                          <Icon>
                            edit
                          </Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Delete"
                      >
                        <IconButton
                          // className={classes.button}
                          onClick={() => {
                            this.setState({
                              dialogDelete: true,
                              newId: assetModel.id,
                            })
                          }}
                          style={{
                            minHeight: '48px',
                            borderRadius: 0,
                            borderLeft: `1px solid ${grey['200']}`
                          }}
                        >
                          <Icon>
                            delete
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  )
                })
            }
          </div>
        </div>
        <div
          style={{
            flex: '1 0',
            borderLeft: `1px solid ${grey['200']}`
          }}
        >
          <Route
            path={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/:id`}
            render={props => {
              return (
                <AssetModelContentComponent
                  {...props}
                  setParam={this.setParam}
                  assetModel={this.state.assetModel}
                />
              )
            }}
          />
        </div>


        {/* DIALOG NEW ITEM */}
        <Dialog
          open={this.state.dialogNewItem}
          onClose={() => this.setState({dialogNewItem: false})}
        >
          <DialogTitle id="dialog-asset-model-new-item">
            New Item
          </DialogTitle>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            style={{
              minWidth: 500,
              padding: 10
            }}
            onSubmit={e => {
              e.preventDefault()

              if (this.state.newName && this.state.newManufacturer) {
                this.setState({loadingDialog: true})

                createAssetModelProvider({
                  name: this.state.newName,
                  manufacturer: this.state.newManufacturer
                })
                  .then(() => {
                    this.setState({
                      dialogNewItem: false,
                      newName: '',
                      newManufacturer: '',
                    })

                    getAssetModelProvider()
                      .then((assetModel) => {
                        this.setState({
                          assetModel,
                          loadingDialog: false
                        })
                      })
                      .catch(error => {
                        console.error('getAssetModelProvider', error)
                        this.setState({
                          loadingDialog: false
                        })
                      })

                    toast.success('Success!', {
                      position: "top-right",
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true
                    })
                  })
                  .catch(error => {
                    console.error('createAssetModelProvider', error)
                    this.setState({
                      loadingDialog: false
                    })
                    toast.error('Save error', {
                      position: "top-right",
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true
                    })
                  })
              } else {
                toast.error('Invalid params', {
                  position: "top-right",
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true
                })
              }
            }}
          > 
            <TextField
              label="Name"
              className={classes.textField}
              value={this.state.newName}
              onChange={(e) => this.setState({newName: e.target.value})}
              margin="normal"
            />

            <TextField
              label="Manufacturer"
              className={classes.textField}
              value={this.state.newManufacturer}
              onChange={(e) => this.setState({newManufacturer: e.target.value})}
              margin="normal"
            />

            <div
              style={{
                marginTop: 20,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                // className={classes.button}
                type="submit"
                style={{
                  border: `1px solid ${grey['200']}`,
                  borderRadius: 0
                }}
              >
                Save
              </Button>
            </div>
          </form>
        </Dialog>

        {/* EDIT ASSET MODEL */}
        <Dialog
          open={this.state.dialogEdit}
          onClose={() => this.setState({dialogEdit: false})}
        >
          <DialogTitle>
            Edit model
          </DialogTitle>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            style={{
              minWidth: 500,
              padding: 10
            }}
            onSubmit={e => {
              e.preventDefault()

              if (this.state.newName && this.state.newManufacturer) {
                this.setState({loadingDialog: true})

                updateAssetModelProvider({
                  id: this.state.newId,
                  name: this.state.newName,
                  manufacturer: this.state.newManufacturer
                })
                  .then(() => {
                    this.setState({
                      dialogEdit: false,
                      newName: '',
                      newManufacturer: '',
                      newId: '',
                    })

                    getAssetModelProvider()
                      .then((assetModel) => {
                        this.setState({
                          assetModel,
                          loadingDialog: false
                        })
                      })
                      .catch(error => {
                        console.error('getAssetModelProvider', error)
                        this.setState({
                          loadingDialog: false
                        })
                      })

                    toast.success('Success!', {
                      position: "top-right",
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true
                    })
                  })
                  .catch(error => {
                    console.error('updateAssetModelProvider', error)
                    this.setState({
                      loadingDialog: false
                    })
                    toast.error('Save error', {
                      position: "top-right",
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true
                    })
                  })
              } else {
                toast.error('Invalid params', {
                  position: "top-right",
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true
                })
              }
            }}
          > 
            <TextField
              label="Name"
              className={classes.textField}
              value={this.state.newName}
              onChange={(e) => this.setState({newName: e.target.value})}
              margin="normal"
            />

            <TextField
              label="Manufacturer"
              className={classes.textField}
              value={this.state.newManufacturer}
              onChange={(e) => this.setState({newManufacturer: e.target.value})}
              margin="normal"
            />

            <div
              style={{
                marginTop: 20,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                // className={classes.button}
                type="submit"
                style={{
                  border: `1px solid ${grey['200']}`,
                  borderRadius: 0
                }}
              >
                Save
              </Button>
            </div>
          </form>
        </Dialog>
        
        {/* DELETE ASSET MODEL */}
        <Dialog
          open={this.state.dialogDelete}
          onClose={() => this.setState({dialogDelete: false})}
        >
          <DialogTitle>
            Delete model
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => this.setState({dialogDelete: false})}
            >
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.setState({loadingDialog: true})
                
                deleteAssetModelProvider(this.state.newId)
                  .then(() => {
                    this.setState({
                      dialogDelete: false,
                      newId: '',
                    })

                    getAssetModelProvider()
                      .then((assetModel) => {
                        this.setState({
                          assetModel,
                          loadingDialog: false
                        })
                      })
                      .catch(error => {
                        console.error('getAssetModelProvider', error)
                        this.setState({
                          loadingDialog: false
                        })
                      })

                    toast.success('Success!', {
                      position: "top-right",
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true
                    })
                  })
                  .catch(error => {
                    console.error('updateAssetModelProvider', error)
                    this.setState({
                      loadingDialog: false
                    })
                    toast.error('Save error', {
                      position: "top-right",
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true
                    })
                  })
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {
          this.state.loadingDialog
            ?
            (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  backgroundColor: `${grey['200']}`,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 999999999999,
                  opacity: '.5'
                }}
              >
                <CircularProgress className={classes.progress} />

                <span
                  style={{
                    marginTop: 20
                  }}
                >
                  Loading...
                </span>
              </div>
            )
            :
            null
        }
      </div>
    )
  }
}

export default withStyles(styles)(AssetModelPage)
