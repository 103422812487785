import React from 'react';
import { Icon } from "@material-ui/core";


export default ({ names, colors }) => {
    return <div style={{ display: 'flex', alignItems: 'center' }}>
        {names && colors ? names.map((name, index) => {
            const color = colors[index] ? colors[index] : 'black';

            return (
                <>
                    <Icon style={{ color }}>fiber_manual_record</Icon>
                    <span style={{ fontSize: 14, marginRight: 5 }}>{name}</span>
                </>
            );
        }
        ) : ''}
    </div>;
};