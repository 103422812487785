import React, { Component } from 'react';
import { withStyles, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Heatmap from 'highcharts/modules/heatmap.js';
import { Redirect } from 'react-router-dom';
import styles from './styles/styles.js';

import * as R from 'ramda';

Heatmap(Highcharts);


class GraphicHeatMap extends Component {
  constructor(props) {
    super(props);


    const { graphic } = this.props;
    const { id, type } = graphic;

    this.state = {
      chart_id: [type, id].join('-'),
      chart_type: graphic.type,
      x_title: graphic.x_title,
      y_title: graphic.y_title,
      min_color: graphic.min_color,
      max_color: graphic.max_color,
      colorAxis: graphic.colorAxis,
      cluster_colors: graphic.cluster_colors,
      legend: graphic.legend,
      min: graphic.min,
      max: graphic.max,
      disable_tooltip: graphic.disable_tooltip,
      redirects: graphic.redirects
    };

    this.refChart = React.createRef();
  }

  reflow() {
    this.refChart.current.chart.reflow();
  }




  render() {
    const { classes, showTable, graphic, parks_info } = this.props
    const { data } = graphic;

    const { goToPage, min_color, max_color, redirects, colorAxis } = this.state;

    if (goToPage) {
      return <Redirect to={goToPage} />
    }

    let table = false;

    const dataValues = [];


    if (graphic.id === 134) {
      if (data) {
        data.features = ["Efficiency Average (%) ", "Efficiency Min (%) ", "Efficiency Max (%) "];
        dataValues.push([data.average && data.average.toFixed(2), data.min && data.min.toFixed(2), data.max && data.max.toFixed(2)]);
        table = true;
      }
    }


    if (Array.isArray(data) || !data.x_axis || !data.series || data.series.length > 1000) {
      return (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <CircularProgress className={classes.progress} size={(graphic.size * 30)} />
          <div style={{
            marginTop: '20px'
          }}>
            {Array.isArray(data) || !data.x_axis || !data.series ? 'No found data to selected filter.' : 'Too much data for the selected filter, please, change the filter and try again.'}
          </div>
        </div>
      )
    }

    let value_color = z => {
      switch (typeof (z)) {
        case 'string':
          return z;
        default: ;
      }
    };

    let tooltip_formatter = tooltip => {
      let html_body = null;
      if (tooltip) {
        if (tooltip instanceof String) {
          html_body = tooltip
        } else {
          html_body = Object.entries(tooltip).map(category => `
            <h4 style="margin:5px!important"> ${category[0]}</h4>
            <ul style="margin:5px!important">
              ${category[1] ?
              (Array.isArray(category[1]) ?
                (category[1].map(element => `<li>${typeof element === 'number' ? element.toFixed(2) : element}</li>`)
                  .reduce((accumulated, actual) => accumulated + actual))
                :
                `<li> ${typeof category[1] === 'number' ? category[1].toFixed(2) : category[1]} </li>`
              )
              :
              `<li> No Data </li>`
            }
            </ul>`);
        }
      } else {
        html_body = data.series.map(element => `
          <b>${this.state.x_title ? this.state.x_title : `X`}<b>: ${element.x}<br>
          <b>${this.state.y_title ? this.state.y_title : `Y`}<b>: ${element.y}<br>
          <b>Value<b>: ${element.z}<br>
        `);
      }

      html_body = `<div>${html_body}</div>`;
      return html_body;
    };

    const hasColorAxis = (min_color && max_color && !isNaN(data.min) && !isNaN(data.max)) || colorAxis;

    const clickEvent = event => {
      const wtgIndex = event.point.x;
      const wtgId = graphic && graphic.filter && graphic.filter.devices && graphic.filter.devices[wtgIndex];
      const dayIndex = event.point.y;
      const day = data && data.y_axis && data.y_axis[dayIndex];

      if (day && wtgId && parks_info && parks_info[0] && parks_info[0].subparks) {
        const park = R.find(park => R.find(subpark => R.find(R.propEq('id', wtgId))(subpark.wtgs))(park.subparks))(parks_info);
        const subpark = R.find(subpark => R.find(R.propEq('id', wtgId))(subpark.wtgs))(park.subparks);
        const subparkId = subpark.id;
        if (!isNaN(subparkId)) {
          this.setState({ goToPage: `/dashboard/analysis2/${redirects.submodule}?analysis-id=${redirects.analysis}&subpark-id=${subparkId}&wtg-id=${wtgId}&day=${day}` });
        }
      }
    };

    const params = {
      chart: {
        type: 'heatmap',
        height: '100%',
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      xAxis: {
        categories: data.x_axis,
        title: {
          text: this.state.x_title
        }
      },
      yAxis: {
        categories: data.y_axis,
        title: {
          text: this.state.y_title
        }
      },
      colorAxis: colorAxis ?
        colorAxis
        :
        hasColorAxis ? {
          minColor: min_color,
          maxColor: max_color,
          tickInterval: table ? 50 : null,
          min: data.min,
          max: data.max
        } : undefined,
      legend: {
        enabled: hasColorAxis,

        align: (this.state.legend == null
          || this.state.legend.position == null) ? 'center' :
          (this.state.legend.position == 'top'
            || this.state.legend.position == 'bottom') ? 'center' : this.state.legend.position,

        layout: (this.state.legend == null
          || this.state.legend.position == null) ? 'horizontal' :
          (this.state.legend.position == 'top'
            || this.state.legend.position == 'bottom') ? 'horizontal' : 'vertical',

        verticalAlign: (this.state.legend == null
          || this.state.legend.position == null) ? 'top' :
          (this.state.legend.position == 'top'
            || this.state.legend.position == 'bottom') ? 'top' : this.state.legend.position,
      },

      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: redirects ? clickEvent : undefined
            }
          }
        }
      },

      exporting: {
        enabled: true
      },

      tooltip: {
        enabled: !(this.state.disable_tooltip),
        useHTML: true,
        pointFormat: '{point.id}',
        headerFormat: `<h4>{point.key}</h4>`,
        outside: true,
        followPointer: true
      },

      series: [{
        borderWidth: 1,
        dataLabels: {
          enabled: true,
          style: {
            color: '#000000',
            textOutline: '0px'
          }
        },
        data: data.series.map(item => ({
          x: item.x,
          y: item.y,
          color: item.text != null ? value_color(item.text) : '#fff',
          borderColor: 'white',
          borderWidth: 0.2,
          value: item.z != null ? item.z.toFixed(2) : '-',
          name: item.tooltip_name ? item.tooltip_name : `${data.x_axis[item.x]} | ${data.y_axis[item.y]}`,
          id: item.tooltip ? tooltip_formatter(item.tooltip) : ''
        })),
        states: {
          hover: {
            brightness: 0.6,
            halo: {
              attributes: 'stroke-width',
              opacity: 0.4
            },
            lineWidthPlus: 3
          }
        }
      }],
    }

    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront} style={{ display: showTable ? 'none' : undefined }}>
            <HighchartsReact
              containerProps={{
                style: {
                  width: '100%'
                }
              }}
              ref={this.refChart}
              highcharts={Highcharts}
              options={params}
            />
          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable ? undefined : 'none' }}>
            {table && <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {
                    data.features.map((elm, index) => {

                      return (
                        <TableCell colSpan={1} key={index}>
                          {elm}
                        </TableCell>
                      )
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dataValues.map((row, indexRow) => {
                    return (
                      <TableRow key={indexRow}>
                        {row.map((cell, indexCell) => {
                          return (
                            <TableCell key={indexCell}>
                              {cell}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.refChart.current && this.refChart.current.chart.reflow();
    this.props.onRef && this.props.onRef(this);

    if (this.refChart.current && this.refChart.current.chart) {
      const initial_chartHeight = this.props.data.y_axis.length * 60 + 120;
      const initial_chartWidth = 1237;

      if (this.props.data.y_axis && this.props.data.y_axis.length > 40) {
        this.refChart.current.chart.setSize(initial_chartWidth, initial_chartHeight + 800);
      }
      else {
        this.refChart.current && this.refChart.current.chart.setSize(initial_chartWidth, initial_chartHeight);
      }
    }

    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }
}

export default withStyles(styles)(GraphicHeatMap)
