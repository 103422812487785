import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'
import * as R from 'ramda'
import moment from 'moment-timezone'
import { Icon } from 'react-icons-kit'
import {download} from 'react-icons-kit/fa/download'
import {trash} from 'react-icons-kit/fa/trash'
import { getReportAllProvider, downloadProvider, deleteReportProvider } from '../../../../../../../../providers/get-report-data-provider'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { toast } from 'react-toastify'
import { ROUTE_MAIN_REPORTS_MANAGEMENT } from '../../../../../../../../constants/route-constant';


const styles = () => ({
  tableItems: {
    borderCollapse: 'collapse',
    width: '100%',
    '& tr:hover': {
      backgroundColor: '#f5f5f5'
    },
    '& th, & td': {
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    }
  },
  actionButton: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
})

class ReportsListComponent extends Component {
  constructor (props) {
    super (props)

    this.state = {
      items: []
    }
  }

  componentDidMount () {
    getReportAllProvider()
      .then(data => {
        

        this.setState({
          items: data.map(item => {
            return {
              id: item.id,
              title: item.description,
              created: moment(item.created_at).format('LL - LT'),
              status: item.status,
              date_range: `${moment(item.range_date_start).format('LL - LT')} / ${moment(item.range_date_end).format('LL - LT')}`,
              turbines: item.device_list,
              item_request: item
            }
          })
        })
      })
      .catch(error => {
        console.error('getReportAllProvider', error)
      })
      
  }

  deleteReport = item => () => {
   
    if(window.confirm("Delete Report?")){

         deleteReportProvider(item)
          .then(() => {
             getReportAllProvider()
              .then(data => {
                
                this.setState({
                  items: data.map(item => {
                    return {
                      id: item.id,
                      title: item.description,
                      created: moment(item.created_at).format('LL - LT'),
                      status: item.status,
                      date_range: `${moment(item.range_date_start).format('LL - LT')} / ${moment(item.range_date_end).format('LL - LT')}`,
                      turbines: item.device_list,
                      item_request: item
                    }
                  })
                })

                toast.success('Report deleted successfully!', {
                    position: "top-right",
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                  })

              })
              .catch(error => {
                console.error('getReportAllProvider', error)
              })


          })
          .catch(error => {
            toast.error('Error deleting!', {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            })
          })




    }
  }


  downloadReport = item => () => {
    const {s3_report_url, description} = item.item_request

    const a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    a.href = s3_report_url
    a.target = '_blank'
    a.download = description
    a.click()

    // downloadProvider(s3_report_url)
    //   .then(data => {
    //     const blob = new Blob([data], {
    //       type: 'application/pdf'
    //     })
        
    //     const urlDownload = window.URL.createObjectURL(blob)
        
        
    //     const a = document.createElement("a")
    //     document.body.appendChild(a)
    //     a.style = "display: none"
    //     a.href = s3_report_url
    //     a.download = description
    //     a.click()
    //     window.URL.revokeObjectURL(urlDownload)
    //   })
    //   .catch(error => {
    //     console.error('downloadProvider', error)
    //   })
  }

  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'auto'
        }}
      >
      <Table className={classes.table}>

        <TableHead>
          <TableRow>
              <TableCell>
                Title
              </TableCell>
              <TableCell>
                Created
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Date range
              </TableCell>
              <TableCell>
                Turbines
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'center'
                }}
              >
                Actions
              </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {this.state.items.map(item => {
              
              let color = item.status === 'DONE' ? '#00ff033b' : item.status === 'OPEN' ? '#ff000073' : '#ffa50080'
                      
              
              return (
              <TableRow
                  key={item.id}
                  style={{
                      textAlign: 'center',
                      backgroundColor: color
                    }}
                >
                  <TableCell>
                    {item.title}
                  </TableCell>
                  <TableCell>
                    {item.created}
                  </TableCell>
                  <TableCell>
                    {item.status}
                  </TableCell>
                  <TableCell>
                    {item.date_range}
                  </TableCell>
                  <TableCell>
                    {`${item.turbines.length} Turbines`}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    {
                      item.status === 'DONE'
                      ? (
                        <React.Fragment>
                          <Icon
                            icon={download}
                            className={classes.actionButton}
                            onClick={this.downloadReport(item).bind(this)}
                          />
                          <Icon
                            icon={trash}
                            className={classes.actionButton}
                            onClick={this.deleteReport(item).bind(this)}
                            style={{
                              marginLeft: 10
                            }}
                          />
                        </React.Fragment>
                      )
                      : <Icon
                      icon={trash}
                      className={classes.actionButton}
                      onClick={this.deleteReport(item).bind(this)}
                      style={{
                        marginLeft: 10
                      }}
                      />
                    }
                  </TableCell>
               </TableRow>
              )
            })}
        </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(styles)(ReportsListComponent)
