import React from 'react'
import {Route} from 'react-router-dom'

import AssetModelComponentListPage from './pages/AssetModelComponentListPage/AssetModelComponentListPage';
import {
  ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT,
  ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT_EDIT,
  ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT_CREATE
} from '../../../../../../../../constants/route-constant';
import AssetModelComponentChangePage from './pages/AssetModelComponentChangePage/AssetModelComponentChangePage';

class AssetModelPage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          exact
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT}
          render={props => (<AssetModelComponentListPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT_CREATE}
          render={props => (<AssetModelComponentChangePage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT_EDIT}
          render={props => (<AssetModelComponentChangePage {...props} />)}
        />
      </div>
    )
  }
}

export default AssetModelPage
