import React from 'react'
import { DateRangePicker } from 'react-date-range'
import {
  withStyles,
  Button,
  Icon,
  Popper,
  Paper,
  Fade
} from '@material-ui/core'
import ClickOutside from 'react-click-outside'

const styles = {}

class FilterDateComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      datePaperAnchoEl: null,
      open: false
    }

    this.buttonMenuElRef = React.createRef()
  }

  toggleMenu = () => {
    const { open } = this.props

    if (open) {
      this.props.closeMenu()
    } else {
      this.props.openMenu()
    }
  }

  handleClick = (e) => {
    const { currentTarget } = e

    this.setState({
      datePaperAnchoEl: currentTarget,
      open: true
    })
  }

  render() {
    const { classes, buttonStyle = {} } = this.props
    const id = 'sjkaskjjdçaskdaksjhdkasjdh'

    return (
      <div
        ref={this.buttonMenuElRef}
        style={{
          height: 'auto',
          width: 'auto',
        }}
      >
        <Button
          className={classes.buttonHandler}
          aria-describedby={id}
          // variant="contained"
          onClick={this.handleClick}
          style={{
            borderRadius: 0,
            height: '100%',
            ...buttonStyle
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Icon
              style={{
                color: '#bdbdbd',
                marginRight: '5px'
              }}
            >
              date_range
            </Icon>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyItems: 'center'
              }}
            >
              {this.props.startDate ? this.props.startDate.format('YYYY-MM-DD') : ''}
            </div>
            <div style={{
              padding: '0 5px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyItems: 'center'
            }}>
              {/*<ArrowForwardIcon />*/}
              <Icon className={classes.dashIcon}>
                remove
              </Icon>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyItems: 'center'
              }}
            >
              {this.props.endDate ? this.props.endDate.format('YYYY-MM-DD') : ''}
            </div>
          </div>
        </Button>
        <Popper
          id={id}
          open={this.state.open}
          anchorEl={this.state.datePaperAnchoEl}
          // placement={'bottom'}
          transition
          style={{
            zIndex: 99999999,
            // marginTop: marginTop ? marginTop : 0,
            // marginRight: marginRight ? marginRight : 0
          }}
        >
          {({ TransitionProps }) => (
            <Fade
              {...TransitionProps}
              timeout={350}
            >
              <ClickOutside
                onClickOutside={
                  () => {
                    this.setState(
                      {
                        open: false,
                        datePaperAnchoEl: null
                      },
                      () => this.props.closeMenu()
                    )
                  }
                }
              >
                <Paper>
                  <DateRangePicker
                    ranges={[this.props.dateRange]}
                    months={2}
                    direction='horizontal'
                    onChange={this.props.onChange}
                    maxDate={this.props.maxDate}
                  />
                </Paper>
              </ClickOutside>
            </Fade>
          )}
        </Popper>
      </div>
    )
  }
}

export default withStyles(styles)(FilterDateComponent)
