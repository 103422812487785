import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/ActionStyle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Comment from './Comment';

import { SelectorContext } from './Contexts';

class CommentsList extends Component {
    constructor(props) {
        super(props);

        const commentKey = this.getCommentStateName();

        this.state = {
            [commentKey]: ['']
        };
    }

    getCommentStateName = () => {
        const { side } = this.props;
        return `${side != null ? `${side}_` : ''}comment`;
    }

    addComment = position => event => {
        const { sendData } = this.context;
        const commentKey = this.getCommentStateName();

        this.setState(prevState => {
            const comment = [...prevState[commentKey].slice(0, position + 1), '', ...prevState[commentKey].slice(position + 1, prevState[commentKey].length)]
            sendData({ [commentKey]: comment });

            return {
                [commentKey]: comment
            }
        });
    }

    deleteComment = position => event => {
        const { sendData } = this.context;
        const commentKey = this.getCommentStateName();

        this.setState(prevState => {
            const comment = [...prevState[commentKey].slice(0, position), ...prevState[commentKey].slice(position + 1, prevState[commentKey].length)]
            sendData({ [commentKey]: comment });

            return {
                [commentKey]: comment
            }
        });
    }

    changeComment = position => event => {
        const target = event.target;
        const { sendData } = this.context;
        const commentKey = this.getCommentStateName();
    
        this.setState(prevState => {
            const newComments = JSON.parse(JSON.stringify(prevState[commentKey]));
            newComments[position] = target && target.value;
            
            sendData({ [commentKey]: newComments });

            return {
                [commentKey]: newComments
            }
        });
    }

    componentDidMount() {
        const { selectedRows, actionType } = this.context;
        const commentKey = this.getCommentStateName();

        const comment = ((actionType === 'edit' || actionType === 'split') && selectedRows[0].comment) ||
            (actionType === 'merge' && selectedRows.map(row => row.comment).reduce((acc, val) => acc.concat(val), [])) ||
            this.state[commentKey];

        if(actionType === 'merge' || actionType === 'split') {
            const { sendData } = this.context;
            sendData({ [commentKey]: comment });
        }

        this.setState({
            [commentKey]: comment
        });
    }

    render() {
        const { classes } = this.props;
        const commentKey = this.getCommentStateName();
        const comment = this.state[commentKey];

        return (
            <List dense={true}>
                {
                    comment && comment.constructor === Array && (
                        comment.map((comment, index) => (
                            <ListItem>
                                <Comment 
                                    changeComment={this.changeComment(index)} 
                                    text={comment} 
                                />
                                <Button 
                                    className={classes.commentBt}
                                    onClick={this.addComment(index)}
                                >
                                    <Icon>add</Icon>
                                </Button>
                                <Button 
                                    className={classes.commentBt} 
                                    onClick={this.deleteComment(index)}
                                    disabled={index===0}
                                >
                                {
                                    index > 0 && (
                                        <Icon>delete</Icon>
                                    )
                                }
                                </Button>
                            </ListItem>
                        ))
                    )
                }
            </List>
        );
    }
}

CommentsList.contextType = SelectorContext;

export default withStyles(styles)(CommentsList);