import {  
    SET_NOTIFICATIONS,
    VIEW_NOTIFICATIONS
} from '../actions/types';

// const DEFAULT_STATE = {
//     // user: 'some-user-id',
//     lastUpdate: new Date().toISOString().replace('T', ' ').split('.')[0],
//     [`${new Date().toISOString().split('T')[0]} 00:00:00`]: {
//         id: `${new Date().toISOString().split('T')[0]} 00:00:00`,
//         next: `${new Date().toISOString().split('T')[0]} 12:00:00`,
//         powerLimitation: {
//             'Some Power Limitation Alarm': {
//                 alarm: 'Some Power Limitation Alarm',
//                 park:[
//                     'Joao Camara-A'
//                 ],
//                 device: [
//                     'VJ-01',
//                     'VJ-07'
//                 ]
//             }
//         }
//     }
// };

const DEFAULT_STATE = {};

export default (state = DEFAULT_STATE, action) => {
    const { 
        type, 
        notifications, 
        AuthedUser,
        lastUpdate
    } = action;

    switch (type) {
        case SET_NOTIFICATIONS:
            return (
                // AuthedUser === state.user ? 
                //     {
                //         ...state,
                //         lastUpdate: notifications.lastUpdate,
                //         ...notifications.clusters
                //     }
                // : AuthedUser != null ?
                //     {
                //         user: AuthedUser,
                //         lastUpdate: notifications.lastUpdate,
                //         ...notifications.clusters
                //     }
                // :
                //     DEFAULT_STATE
                notifications != null ?
                    {
                        // user: AuthedUser,
                        ...state.clusters || {},
                        lastUpdate: notifications.lastUpdate,
                        ...notifications.clusters
                    }
                :
                    {}
            );
        case VIEW_NOTIFICATIONS:
            return (
                {
                    ...state,
                    lastUpdate
                }
            );
        default:
            return state;
    }
}
