import React, { Fragment } from 'react';

import {
    withStyles,
    Menu,
    MenuItem,
    Avatar,
    Icon,
    Typography
} from '@material-ui/core';

import styles from './NotificationsButtonStyles';
import NotificationItem from './NotificationItem';

// import styled from 'styled-components';
import './ExtraStyles.css';

const NOTIFICATION_KEYS = [
    'powerLimitations',
    'maintenances'
];

const NotificationsView = props => {
    const {
        classes,
        open=true,
        setOpen,
        anchorEl,
        setAnchorEl,
        data={}
    } = props;
    
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const dataValues = Object.values(data);
    
    return (
        <Menu
            id='long-menu'
            autoFocus={false}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            selected={false}
            PaperProps={{
                style: {
                    maxHeight: '50%',
                    transform: 'scale(1, 1) translateZ(5px) translateX(-68%)',
                    width: 300,
                    // overflow: 'visible',
                    overflowY: 'auto'
                },
                paper: {
                    transform: 'scale(1, 1) translateZ(5px) translateX(-68%)',
                    // overflow: 'visible',
                    overflowY: 'auto'
                }
            }}
            MenuListProps={{
                // width: '100%',
                style: {
                    width: '100%',
                    overflowY: 'auto'
                }
            }}
        >
            {
                dataValues.length > 0 ? 
                    dataValues.sort((a, b) => (
                        new Date(a.id).getTime() > new Date(b.id).getTime() ? -1 : new Date(a.id).getTime() < new Date(b.id).getTime() ? 1 : 0
                    )).map(option => (
                        <Fragment>
                            <MenuItem 
                                disabled
                            >
                                <Typography
                                    gutterBottom
                                    alignRight
                                    inline
                                    variant='body1'
                                    component='p'
                                    style={{
                                        fontSize: '.8em',
                                        color: '#5e5e5e',
                                        width: '100%',
                                        textAlign: 'right'
                                    }}
                                >
                                    Notifications until {option.id}
                                </Typography>
                            </MenuItem>
                            {
                                NOTIFICATION_KEYS.map(key => (
                                    option[key] &&
                                    Object.values(option[key]).map(content => (
                                        <NotificationItem 
                                            {...props} 
                                            id={`${key}-${option.id}-${content.alarm}`} 
                                            content={content}
                                            eventType={key}
                                        />
                                    ))
                                ))
                            }
                        </Fragment>
                    ))
                :
                    <Fragment>
                        <MenuItem 
                            disabled
                        >
                            <Icon>inbox</Icon>
                            <Typography
                                gutterBottom
                                alignRight
                                inline
                                variant='body1'
                                component='p'
                                style={{
                                    fontSize: '.8em',
                                    color: '#5e5e5e',
                                    width: '100%',
                                    textAlign: 'right'
                                }}
                            >
                                You have no alarms notifications.
                            </Typography>
                        </MenuItem>
                    </Fragment>
            }
        </Menu>
    );
};

// const SpeakBox = styled.div`
//     overflow: visible;
//     &:before {
//         content: ' ';
//         position: absolute;
//         width: 0;
//         height: 0;
//         left: 100%;
//         top: 6.8%;
//         border: 9px solid;
//         border-color: #dbdbdb50 transparent transparent #dbdbdb50;
//     }
    
//     &:after {
//         content: ' ';
//         position: absolute;
//         width: 0;
//         height: 0;
//         left: 100%;
//         top: 7%;
//         border: 6px solid;
//         border-color: white transparent transparent white;
//     }
// `;

export default withStyles(styles)(NotificationsView);