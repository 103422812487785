import React, {Component} from 'react';
import * as R from 'ramda';

const SeriesColorTreatment = (colors={
    bySerie: {
      Average: ['#FC9018', '#D47C35'],
      Others: ['#4C4C4C', '#04040C']
    }
  }) => {
  const real_SeriesColorTreatment = state => {
    state.data_without_colors = JSON.parse(JSON.stringify(state.data));

    try {
      if('bySerie' in colors && colors.bySerie) {
        let keys = Object.keys(colors.bySerie);

        state.data.series.map((serie, serie_index) => (
          serie.colors = serie.values.map((value, value_index) => (
            keys.map(key => state.data.x_axis[value_index].match(key) ? colors.bySerie[key][serie_index] : null)
                .reduce((acc, curr) => acc ? acc : curr)
          ))
        ));
      }
    } catch (e) {
      state.data = JSON.parse(JSON.stringify(state.data_without_colors));
      delete state.data_without_colors;
    } finally {
      // ;
      return state;
    }
  }

  return(real_SeriesColorTreatment);
};

export default SeriesColorTreatment;
