// ATTENTION: THIS IS ONLY TEMPORARY. PLEASE DELETE AND BURN ME WHEN IT'S TIME

import {
    REDUCER_AUTH_PARKS_INFO
} from '../constants/reducers/auth-action-events';

const DEFAULT_STATE = {
    parks_info: null
};

export default (state = DEFAULT_STATE, action) => {
    const { payload, type } = action;

    switch (type) {
        case REDUCER_AUTH_PARKS_INFO:
            const {result: resultParksInfo} = payload;
            return {...state, parks_info: resultParksInfo};
        default:
            return state;
    }
}