const oldEnerplanMask = (sheet, models, storages) => sheet.map(row => {

    const filteredModels = Object.values(models).filter(model => model.tag && model.tag.indexOf(row['Component Model']) >= 0);
    const filteredStorages = Object.values(storages).filter(store => (row['Entry Stock'] === 'DEFAULT' || row['Entry Stock'] === 'DISCARD') || (store.tag && store.tag.indexOf(row['Entry Stock']) >= 0));

    return ({
        import: true,
        ...row,
        ['Component Model']: filteredModels.length > 0 && filteredModels[0].id,
        ['Entry Stock']: filteredStorages.length > 0 && filteredStorages[0].id,
        ['Associated Documents']: row['Associated Documents'].split(',')
    });
});

const valuesToHeader = {
    'component_model_id': 'Component Model',
    'invoice_number': 'Invoice Number',
    'received_date': 'Received Date',
    'serial_number': 'Serial Number',
    'storage_id': 'Entry Stock',
    'supplier': 'Product Supplier',
    'DEPOSITO': 'Depósito',
    'EMPRESA': 'Empresa',
    'FILIAL': 'Filial',
    'PRODUTO': 'Produto',
    'SEQUENCIA_ITEM_NOTA_FISCAL': 'Sequência Item Nota Fiscal',
    'SERIE_NOTA_FISCAL': 'Série Nota Fiscal',
    'name': 'Component Model Name',
    'part_number': 'Part Number',
    'tag': 'Produto(s)'
};


const enerplanSapiensMask = sheet => {
    let maskedData = sheet.map(row => {
        return {
            Exists: row.exists ? 'Yes' : 'No',
            ...Object.entries(row).reduce((acc, curr) => {

                if (curr[1] !== null && typeof curr[1] === 'object') {

                    if (Array.isArray(curr[1])) {
                        return ({
                            ...acc,
                            [valuesToHeader[curr[0]]]: curr[1].join(', ')
                        });
                    }

                    const fields = Object.entries(curr[1]).reduce((acc, curr) => {
                        return (
                            { ...acc, [valuesToHeader[curr[0]]]: curr[1] }
                        );
                    }, {});

                    return { ...acc, ...fields };
                }

                if (valuesToHeader[curr[0]]) {
                    return (
                        { ...acc, [valuesToHeader[curr[0]]]: curr[1] }
                    );
                }

                return acc;
            }, {})
        };
    });

    return maskedData;
};


export default {
    oldEnerplan: oldEnerplanMask,
    enerplan: enerplanSapiensMask
};