// export const getParksInfo = async () => {
//     const parksInfo = await Promise.resolve(getDataGraphOldProvider('parks_info'));
//     console.log('ON GETTING PARKS INFO', parksInfo);
// }

import { SET_METADATA } from '../actions/types';

export const setMetadata = metadata => ({
    type: SET_METADATA,
    ...metadata
});