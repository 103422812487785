import React from 'react'
import OnlyPrint from '../OnlyPrint'
import container from './Container'
import Typography from '@material-ui/core/Typography'


const SubtitleLine = ({ content }) =>
    <div style={{ padding: '0mm 10mm' }}>
        <Typography style={{ fontFamily: 'inherit' }} variant='body1' >{`*${content}`}</Typography>
    </div>


export default props => {

    const lines = container(props)

    return (
        <OnlyPrint>
            {lines.map((line, index) => <SubtitleLine key={index} {...line} />)}
        </OnlyPrint>
    )
}