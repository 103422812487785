import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'

import { ROUTE_MAIN_PCH_MONITOR } from '../../constants/route-constant';



const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flipContainer: {
        height: '100%',
        width: '100%',
        perspective: '1000px',
        maxHeight: '500px',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    flipContainerFront: {
        height: '100%',
        width: '100%',
        minHeight: '150px',
        //backgroundColor: 'white',
        backgroundColor: 'transparent',
        zIndex: 2,
        transform: 'rotateY(0deg)',
        backfaceVisibility: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        margin: 0
    },
    flipContainerBack: {
        height: '100%',
        width: '100%',
        minHeight: '150px',
        backgroundColor: 'white',
        zIndex: 2,
        transform: 'rotateY(180deg)',
        backfaceVisibility: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        margin: 0
    },
    flipper: {
        height: '100%',
        width: '100%',
        transition: '0.6s',
        transformStyle: 'preserve-3d',
        position: 'relative',
        margin: 0
    }
})

class FlipContainer extends React.Component {
    constructor (props) {
      super (props)
    }

    render() {
        const {
            classes, Front, Back, change_side, FrontButtons, BackButtons,
            FrontButtonsTop, FrontButtonsRight, BackButtonsTop, BackButtonsRight
        } = this.props

        return (
            <div className={classes.flipContainer}>
                <div
                    style={{
                        transform: change_side ? 'rotateY(180deg)': 'none'
                    }}
                    className={classes.flipper}
                >
                {
                    !change_side ?
                        <div className={classes.flipContainerFront}>
                            {
                                FrontButtons != null && FrontButtons.constructor == Array ?
                                    <div 
                                        style={{
                                            position: 'absolute', 
                                            top: FrontButtonsTop,  
                                            right: FrontButtonsRight,
                                            zIndex: 999,
                                            display: 'flex'
                                        }}
                                    >
                                    {
                                        FrontButtons.map((button, index) => (
                                            <div style={{margin: 0, padding: 0, flex: 1}} >
                                                {button}
                                            </div>
                                        ))
                                    }
                                    </div>
                                :
                                    null
                            }
                            {Front}
                        </div>
                    :
                        <div className={classes.flipContainerBack}>
                            {
                                BackButtons != null && BackButtons.constructor == Array ?
                                    <div 
                                        style={{
                                            position: 'absolute', 
                                            top: BackButtonsTop, 
                                            right: BackButtonsRight,
                                            zIndex: 999,
                                            display: 'flex'
                                        }}
                                    >
                                    {
                                        BackButtons.map((button, index) => (
                                            <div style={{margin: 0, padding: 0, flex: 1}} >
                                                {button}
                                            </div>
                                        ))
                                    }
                                    </div>
                                :
                                    null
                            }
                            {Back}
                        </div>
                }
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(FlipContainer)