import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as HighchartsMore from 'highcharts/js/highcharts-more'
import axios from 'axios'
import { URL_NODE_API } from '../../../constants/url-constant'
import { Auth } from 'aws-amplify'
import CircularLoading from '../../../components/Loading/CircularLoading';

import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Button,
  Grid,
  ListItem,
  ListItemText
} from '@material-ui/core';



import {
  graphicsForTypeWindRose
} from '../../../constants/graph-types.js';

import styles from './styles/styles.js';

HighchartsMore(Highcharts);
// Highcharts.setOptions({
//     plotOptions: {
//         series: {
//             animation: false
//         }
//     }
// });

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#bdbdbd',
    color: theme.palette.common.black,
    //fontSize: 16,
  },
  body: {
    //fontSize: 14,
  },
}))(TableCell);


class GraphicWindRose extends Component {

  constructor(props) {

    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      headers: [],
      table: [],
      colors: [
        '#bdbdbd',
        '#767676',
        '#C0D2FC',
        '#F4645C',
        '#E62C20',
        '#4C65A7',
        '#B414DC',
        '#FCDC34',
        '#FC9018',
        '#0A70D2'
      ]
    }

    this.chartWillRender = this.chartWillRender.bind(this);

  }

  chartWillRender(state) { }

  getSettings() { }

  async getTableData(){

    const { accessToken } = await Auth.currentSession()
    const { jwtToken } = accessToken
    const widget = this.props.graphic;
    let headers = [];
    let dataValuesExcel = []

    if (widget.api) {

      const URL = URL_NODE_API + '/management/new-data'
      const params = {
        procedure_name: widget.api,
        time_range: [widget.filter.data_range.startDate, widget.filter.data_range.endDate],
        device_list: widget.filter.devices,
        subpark_list: widget.filter.subparks,
        timezone: 'America/Fortaleza',
      }


      const { data } = await axios.post(URL, params, {
        timeout: 1500000,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      })

      dataValuesExcel = data.data.map((row) => {
        headers = Object.keys(row)
        return Object.values(row);
      })


    }


    this.setState({
      headers: headers,
      table: dataValuesExcel
    })


    return dataValuesExcel;
  }



  componentDidMount() {

    let data = this.getTableData();

    this.props.getSettings(this.getSettings.bind(this));

    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }


  caulculateFontSizeHead(grid) {

    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }


  render() {

    const { classes, showSettings, graphic, loading, showTable } = this.props;
    const { data, type } = graphic;

    var on_render_state = {
      ...this.state,
      data: data
    };

    if (!on_render_state.data) {

      if (type == 'wind_rose_columns') {

        on_render_state.data.series.map(element => {
          let aux = [];
          element.data.map((elm, index) => {
            if (!elm)
              aux.push([on_render_state.x_axis.categories[index], 0]);
            else
              aux.push([on_render_state.x_axis.categories[index], parseFloat((100 * elm).toFixed(2))]);
          })
          element.data = aux;
        });
      }



      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          Error in API request
          </div>
      )
    }

    this.chartWillRender(on_render_state);

    const params = {

      colors: on_render_state.colors,

      title: {
        text: null
      },

      chart: {
        polar: true,
        type: type == 'wind_rose_columns' ? 'column' : 'area',
      },

      credits: {
        enabled: false
      },

      pane: {
        size: '90%',
        startAngle: 0
      },

      xAxis: {
        tickmarkPlacement: 'on',
        type: 'category',
        categories: on_render_state.data.x_axis.categories,
      },

      yAxis: {
        min: 0,
        endOnTick: false,
        showLastLabel: true,
        title: {
          text: ''
        },
        reversedStacks: false,
        labels: {
          formatter: function () {
            return (this.value * 100) + ' %';
          }
        }
      },

      exporting: {
        enabled: true
      },

      legend: {
        enabled: type == 'wind_rose_columns' ? true : false,
        layout: 'vertical',
        verticalAlign: 'top',
        y: 100,
        align: 'right',
        labelFormatter: function () {
          return this.name;
        },
        itemStyle: {
          color: '#000000',
          fontSize: '15px'
        }

      },

      plotOptions: {
        series: {
          stacking: 'normal',
          shadow: false,
          groupPadding: 0,
          pointPlacement: 'on'
        },
        area: {
          stacking: 'normal',
          lineWidth: 0.5,
          lineColor: '#595959',
          color: '#bfbfbf',
          marker: {
            enabled: false
          }
        }
      },

      tooltip: {
        valueSuffix: ' %',
        valueDecimals: 2,
        followPointer: true,
        shared: true,
        style: {
          fontSize: '15px'
        },
        formatter: function () {
          var points = this.points;
          var pointsLength = points.length;
          var tooltipMarkup = pointsLength ? '<span style="font-size: 12px">' + points[0].key + '</span><br/>' : '';
          var index;
          var y_value;

          for (index = 0; index < pointsLength; index += 1) {
            y_value = (points[index].y * 100).toFixed(2);

            if (y_value > 0)
              tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> ' + points[index].series.name + ': <b>' + y_value + ' %</b><br/>';
          }

          return tooltipMarkup;
        }
      },

      series: on_render_state.data.series
    }


    const heightContainer = this.props.isAnalysis ? '100%' : 'inherit'
    const heightTable = this.props.isAnalysis ? '50%' : '100%'

    return (
      
    
      this.state.table.length == 0 ?

        <CircularLoading>
          <p
            style={{
              fontSize: this.state.headerFontSize
            }}
          >
            Please wait a moment while widget loads.
          </p>
        </CircularLoading> : 

        <div className={classes.flipContainer}>

        
        <div
          style={{
            transform: showSettings ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront}>
            <div style={{ height: heightContainer }}>

            {
              !showTable &&
                <HighchartsReact
                containerProps={{
                  style: {
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                  }
                }}
                ref={this.refChart}
                highcharts={Highcharts}
                options={params}
              />
            }

              {
                showTable && <Table className={classes.table}>

                  <TableHead>
                    <TableRow>
                      {this.state.headers.map((elm, index) => {

                        // if (elm === '0') {
                        //   return (
                        //     <CustomTableCell colSpan={1} key={index}> primary={this.state.columns[index]}
                        //       {''}
                        //     </CustomTableCell>
                        //   )
                        // }

                        return (
                          <CustomTableCell primary={this.state.headers[index]} colSpan={1} key={index} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}>
                            {elm}

                          </CustomTableCell>
                        )
                      }

                      )

                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.table.map((row, indexRow) => {
                      return (
                        <TableRow key={indexRow}>
                          {row.map((cell, indexCell) => {
                            return (
                              <CustomTableCell style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px', height: '2px' }} key={indexCell}>
                                {cell}
                              </CustomTableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>

              }
            </div>

          </div>

        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicWindRose);

