
import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { EditingState, SortingState, IntegratedSorting, RowDetailState, DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ListIcon from '@material-ui/icons/List'
import Button from '@material-ui/core/Button'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from '@material-ui/core'
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import moment from 'moment-timezone'

import DatePicker from '../DatePicker/DatePicker'

const getRowId = row => row.id;

 const CurrencyTypeProvider = props => (
    <div>
      <DataTypeProvider
        formatterComponent={CurrencyFormatter}
        onClick = {props.onClick}
        {...props}
      />
    </div>
  );



  const CurrencyFormatter = ({row, value}) => {
    console.log(value)
    return (
      <div>
        <Tooltip title={
          <React.Fragment>
            <h3>Comments</h3>
            <ul style={{
              padding: 0,
              textAlign: 'left',
              listStyleType: 'none'
            }}>
            {
              value && value.constructor == Array ?
                value.map(item => (item ? <li>{item.comment}</li> : null))
              :
                <li>No Comments</li>
            }
            </ul>
          </React.Fragment>
        }
        placement={'left'}>
          <Button
            color="primary"
            aria-label="Add Page"
          >
            <ListIcon />
          </Button>
        </Tooltip>
      </div>
    )
  }


export default class TableEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns: this.props.columns,
      rows: this.props.rows,
      originalRows: this.props.rows,
      utilsData: this.props.utilsData,
      editingRowIds:[],
      defaultSorting: this.props.defaultSorting,
      optionFields: this.props.optionFields,
      customColumns: ['comments'],
      listComement: false,
      selectedRow:{},
      editingStateColumnExtensions: [
        { columnName: 'comments', editingEnabled: false },
      ],
      update: true
    };
  }

  openDialogNewComment(row){
    this.setState({
        listComement: true,
        selectedRow: row,
      })

  }


  closeDialogNewComment(){
      this.setState({
        listComement: false,
      })

  }

  changeEditingRowIds(editingRowIds) {
    this.setState({ editingRowIds });
  }

  onValueChange(event, props, options=null) {
    if(!this.props.downtimes) {
      let propertyName = event.target.name+"_to_change"
      props.row[propertyName] = event.target.value ? event.target.value : event.target.name

      if(options) {
        const eqOption = options.filter(op => props.row[propertyName] == op.value)
        if(eqOption.length > 0) {
          let propertyNameID = event.target.name+"_id"
          props.row[propertyNameID] = eqOption[0].id
        }
      }
    } else {
      let idPropName = event.target.name+'_id'
      props.row[idPropName] = parseInt(event.target.value)

      let propertyName = event.target.name+'_to_change'
      props.row[propertyName] = options[props.row[idPropName]]

      // let innerValueName = event.target.name+'_selector_index'
      // let getNode = [...event.target.childNodes].filter(node => parseInt(node.value) == props.row[idPropName])
      // props.row[innerValueName] = getNode.length > 0 ? getNode[0].index : 0
    }
  }

  commitChangesComments({ added, changed, deleted }) {

    let rows = this.state.selectedRow.comments
    rows = rows ? rows : []
    let selectedRow = this.state.selectedRow

    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0
      rows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      if (window.confirm("Are you sure?")) {
        rows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      }

    }
    if (deleted) {

      if (window.confirm("Are you sure?")) {
        const deletedSet = new Set(deleted);
       rows = rows.filter(row => !deletedSet.has(row.id));

      }

    }
    selectedRow.comments = rows;

    this.setState({ selectedRow:selectedRow });
    this.forceUpdate();
  }

  testea(id){
    return [1];
  }

  commitChanges({ added, changed, deleted }) {
    let {rows}  = this.state
    rows = rows ? rows : []

    if (added) {
      added.map(to_add => {
        if('start_time' in to_add) {
          const values_match_st = to_add.start_time.match(/([0-9]{1,4}){1}(-([0-9]{0,2}){1}(-([0-9]{0,2}){1}){0,1}){0,1}( ((?:[01]?\d|2[0-3]){1}){0,1}(:([0-5][0-9]){1}){0,1}(:([0-5][0-9]){1}){0,1}$){0,1}/)
          if(values_match_st) {
            to_add.start_time = `${values_match_st[1] ? values_match_st[1] : moment().utc().format('YYYY')}-${values_match_st[3] ? values_match_st[3] : '01'}-${values_match_st[5] ? values_match_st[5] : '01'} ${values_match_st[7] ? values_match_st[7] : '00'}:${values_match_st[9] ? values_match_st[9] : '00'}:${values_match_st[11] ? values_match_st[11] : '00'}-03:00`
          } else {
            to_add.start_time = moment().tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')
          }
        }

        if('end_time' in to_add) {
          const values_match_et = to_add.end_time.match(/([0-9]{1,4}){1}(-([0-9]{0,2}){1}(-([0-9]{0,2}){1}){0,1}){0,1}( ((?:[01]?\d|2[0-3]){1}){0,1}(:([0-5][0-9]){1}){0,1}(:([0-5][0-9]){1}){0,1}$){0,1}/)
          if(values_match_et) {
            to_add.end_time = `${values_match_et[1] ? values_match_et[1] : moment().utc().format('YYYY')}-${values_match_et[3] ? values_match_et[3] : '01'}-${values_match_et[5] ? values_match_et[5] : '01'} ${values_match_et[7] ? values_match_et[7] : '00'}:${values_match_et[9] ? values_match_et[9] : '00'}:${values_match_et[11] ? values_match_et[11] : '00'}-03:00`
          } else {
            to_add.end_time = moment().tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')
          }
        }

        if('duration' in to_add) {
          const values_match_duration = to_add.duration.match(/[0-9]*.{1}[0-9]*/)
          if(values_match_duration) {
            to_add.duration = Number(values_match_duration[0])
          } else if (to_add.start_time && to_add.start_time != '' && to_add.start_time != 'Invalid Date' &&
                      to_add.end_time && to_add.end_time != '' && to_add.end_time != 'Invalid Date') {
            to_add.duration = moment.duration(to_add.end_time.diff(to_add.start_time)).asHours()
          } else {
            to_add.duration = '0'
          }
        }

        return to_add
      })

      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      rows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          comments: [],
          ...row,
        })),
      ];

      rows.map(row => {
         row.classification = row.classification_to_change ? row.classification_to_change : row.classification;
         row.alarm = row.alarm_to_change ? row.alarm_to_change : row.alarm;
         row.system = row.system_to_change ? row.system_to_change : row.system;
         row.responsibility = row.responsibility_to_change ? row.responsibility_to_change : row.responsibility;
         row.duration = row.duration_to_change ? row.duration_to_change : row.duration;

         return row
      })
    }

    if (changed) {
      if (window.confirm("Are you sure?")) {
        rows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
        rows = rows.map(row => {
          if ('classification' in row) {
             row.classification = row.classification_to_change ? row.classification_to_change : row.classification
          }

          if ('alarm' in row) {
             row.alarm = row.alarm_to_change ? row.alarm_to_change : row.alarm
          }

          if ('system' in row) {
             row.system = row.system_to_change ? row.system_to_change : row.system
          }

          if ('responsibility' in row) {
             row.responsibility = row.responsibility_to_change ? row.responsibility_to_change : row.responsibility
          }

          if ('duration' in row) {
             row.duration = row.duration_to_change ? row.duration_to_change : row.duration
          }

          return row
        })
      }
    }
    if (deleted) {
      if (window.confirm("Are you sure?")) {
        const deletedSet = new Set(deleted);
       rows = rows.filter(row => !deletedSet.has(row.id));

      }
    }
    // this.setState({
    //   rows: rows,
    //   rowsFromParent: false
    // })
    const rows_changed = this.props.changeRows(rows)
    // const update_new = this.props.disable_update()
    this.setState({rows})
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.update_time > prevProps.update_time) {
      this.setState({
        update: true
      });
    } else {
      this.setState({
        update: false
      });
    }
  }

  render() {
    const { columns, customColumns, sorting, editingStateColumnExtensions, optionFields, defaultSorting, utilsData  } = this.state;
    const {downtimes, page, parks_info, responsibility_selector, system_selector, classification_selector, alarm_selector} = this.props

    let rows = this.state.update ? this.props.rows : this.state.rows
    rows = rows ? rows : []

    const selectors_map = {
      responsibility: responsibility_selector,
      system: system_selector,
      classification: classification_selector,
      alarm: alarm_selector
    }

    const start_time_index = columns.map(column => column.name).indexOf('start_time')
    const end_time_index = columns.map(column => column.name).indexOf('end_time')
    const duration_index = columns.map(column => column.name).indexOf('duration')

    rows = rows.map(row => {
      if(start_time_index >= 0) {
        row.start_time = moment.tz(row.start_time, "America/Fortaleza").format('YYYY-MM-DD HH:mm:ss')
      }
      if(end_time_index >= 0) {
        row.end_time = moment.tz(row.end_time, "America/Fortaleza").format('YYYY-MM-DD HH:mm:ss')
      }
      if(duration_index >= 0) {
        if(!row.duration && row.start_time && row.start_time != '' && row.start_time != 'Invalid Date'
        && row.end_time && row.end_time != '' && row.end_time != 'Invalid Date') {
          let moment_start = moment.tz(row.start_time, 'America/Fortaleza')
          let moment_end = moment.tz(row.end_time, 'America/Fortaleza')

          row.duration = moment.duration(moment_end.diff(moment_start)).asHours()
        }

        row.duration = row.duration.constructor == Number ? row.duration : parseFloat(row.duration)
      }

      return row
    })

    return (
      <div>
      <Paper>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >

        <CurrencyTypeProvider
            for={customColumns}
            onClick={this.openDialogNewComment}
        />


        <SortingState
            defaultSorting={defaultSorting}
          />

          <IntegratedSorting />


          <EditingState
            onCommitChanges={this.commitChanges.bind(this)}
            columnExtensions={editingStateColumnExtensions}
            onEditingRowIdsChange={this.changeEditingRowIds.bind(this)}
            editingRowIds={this.state.editingRowIds}

          />
          <Table
              cellComponent=
              {
                (props) =>
                {

                    if (props.column.name == "comments") {
                      return(
                        <Table.Cell {...props} onClick={(e) => {
                          this.openDialogNewComment(props.row)
                        }}/>
                      )
                    }else{

                      let editingRowIds = [];

                      return(
                         <Table.Cell {...props} onClick={(e) => {
                           editingRowIds.push(props.row.id)
                           props.column.editingEnabled = true;
                           this.testea(props.row.id);
                          this.setState({
                                editingRowIds: editingRowIds,
                            })


                        }}/>
                      )

                    }
                }
              }
          />
          <TableHeaderRow/>
          <TableEditRow
              cellComponent=
              {
                (props) =>
                {
                  if (props.column.name == "comments") {
                      return(
                       <div>
                            <Button
                                color="primary"
                                aria-label="Add Page"
                                onClick={(e) => {
                                    this.openDialogNewComment(props.row)
                                  }
                                }
                              >
                              <ListIcon />
                            </Button>
                        </div>
                      )
                  } else if (!downtimes && optionFields.includes(props.column.name)) {
                      const options = utilsData[props.column.name] ? utilsData[props.column.name] : [];

                      return(
                           <TableEditRow.Cell {...props} >
                               <Select native defaultValue={props.value ? props.value : 0} onChange={e => this.onValueChange(e, props, options)} name={props.column.name}>
                                <option
                                  value="0"
                                  name="0"
                                >
                                  Select
                                </option>
                                {
                                  options.filter(option => !downtimes ? true : option.is_downtime).map(obj =>{
                                      return(<option value={obj.value} name={obj.label}>{obj.label}</option>)
                                  })
                                }
                              </Select>
                            </TableEditRow.Cell>

                        )

                  } else if (downtimes && Object.keys(selectors_map).indexOf(props.column.name) >= 0) {
                    const options = utilsData[props.column.name] ? utilsData[props.column.name] : [];
                    return(
                      <TableEditRow.Cell {...props} >
                          <Select native
                            onChange={e => this.onValueChange(e, props, selectors_map[props.column.name]
                                                .map(obj => ({[obj.id]: obj.name}))
                                                .reduce((acc, curr) => Object.assign(acc, curr)))} name={props.column.name}
                            >
                           <option value="0" name="0">Select</option>
                           {
                             selectors_map[props.column.name].map(obj =>{
                                 return(<option value={obj.id} name={obj.name}>{obj.name}</option>)
                             })
                           }
                         </Select>
                       </TableEditRow.Cell>
                    )
                  } else {
                    return(
                       <TableEditRow.Cell {...props} />
                    )
                  }
                }
              }
           />

          <TableEditColumn
            showAddCommand
            showEditCommand
            showDeleteCommand
          />
        </Grid>

        </Paper>

           <Dialog
                open={this.state.listComement}
                aria-labelledby="dialog_overview_delete"
              >
                <DialogTitle id="dialog_overview_delete">
                  Comentários
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid
                        rows={this.state.selectedRow ? this.state.selectedRow.comments ? this.state.selectedRow.comments : [] : []}
                        columns={[{ name: 'comment', title: 'Comment' }]}
                        getRowId={getRowId}
                      >
                      <EditingState
                        onCommitChanges={this.commitChangesComments.bind(this)}
                        editingRowIds={this.state.editingRowIds}
                      />
                      <Table/>
                      <TableHeaderRow/>
                      <TableEditRow />
                      <TableEditColumn
                        showAddCommand
                        showEditCommand
                        showDeleteCommand
                      />
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button  color="primary" onClick={this.closeDialogNewComment.bind(this)} >
                    Back
                  </Button>
                </DialogActions>
              </Dialog>
      </div>
    );
  }
}
