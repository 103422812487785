import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useContainer from './Container';
import { useTranslation } from 'react-i18next';

import {
    Icon
} from '@material-ui/core';

const DEFAULT_COLORS = [
    '#f15922',
    '#3399ff',
    '#3b3b3b'
];

const View = props => {
    const {
        graphic,
        ...extraProps
    } = props;

    const {
        data,
    } = graphic;

    const {
        chart,
        options,
        setSlices
    } = useContainer(props);

    const { t } = useTranslation();

    setSlices(data.map((row, index) => ({
        ...row,
        color: row.color || (DEFAULT_COLORS.length > index && DEFAULT_COLORS[index]) || undefined
    })));

    return (
        data.length > 0 ?
            <HighchartsReact
                containerProps={{
                    className: 'pie-chart',
                    style: {
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                    }
                }}
                ref={chart}
                highcharts={Highcharts}
                options={options}
            />
            :
            <div style={{
                height: 'inherit',
                width: '100%',
                textAlign: 'center',
                verticalAlign: 'middle',
            }}>
                <Icon style={{
                    fontSize: '5em',
                    color: '#dbdbdb'
                }}>block</Icon>
                <h3>{t("No Data")}</h3>
                <p>{t("Unable to find data for this time range")}</p>
            </div>
    );
};

export default View;