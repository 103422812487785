import {
    useState,
    useCallback,
    useEffect
} from 'react';

import {
    useSelector
} from 'react-redux';

import { executeCustomProvider as DataFetch } from '../../providers/get-data-old-provider';

const useContainer = props => {
    const {
        metaData,
        changeLoading,
        updatedFilters
    } = props;

    const [ data, setData ] = useState(Array(metaData.widgets.length).fill(null));
    const [ mounted, setMounted ] = useState(false);
    const [ analysisId, setAnalysisId ] = useState(metaData.id);

    const filters = useSelector(state => state.Dashboard && {
        devices: state.Dashboard.DeviceFilter && state.Dashboard.DeviceFilter.devices,
        subparks: state.Dashboard.DeviceFilter && state.Dashboard.DeviceFilter.subparks,
        startTime: state.Dashboard.DateFilter && state.Dashboard.DateFilter.data_range && state.Dashboard.DateFilter.data_range.startDate,
        endTime: state.Dashboard.DateFilter && state.Dashboard.DateFilter.data_range && state.Dashboard.DateFilter.data_range.endDate
    });

    const modules = useSelector(state => state.Modules);
    const modulesIdsArray = modules ? 
        modules.map(mod => (
                [
                    mod.id, 
                    ...(
                        mod.submodules ? 
                            mod.submodules.map(submod => submod.id) 
                        : 
                            []
                    )
                ]
            )
        ) 
    : 
        [];

    const modulesIds = modulesIdsArray.reduce((acc, curr) => (
        [...acc, ...curr]
    ), []);

    const getData = useCallback(
        async providerInfo => {
            const time_range = [filters.startTime, filters.endTime];
            const subpark_list = filters.subparks;
            const device_list = filters.devices;

            return await DataFetch({...providerInfo, time_range, subpark_list, device_list});
        },
        [
            filters
        ]
    );

    useEffect(
        () => {
            (async () => {
                if(!mounted) {
                    const newWidgetsData = await Promise.all(metaData.widgets.map(widget => getData(widget.providerInfo)));
                    setData(newWidgetsData);
                    changeLoading(false);
                }
                setMounted(true);
            })();
        },
        [
            mounted,
            setMounted,
            setData,
            getData,
            metaData,
            changeLoading
        ]
    );
    
    useEffect(
        () => {
            (async () => {
                if(updatedFilters) {
                    changeLoading(true);
                    const newWidgetsData = await Promise.all(metaData.widgets.map(widget => getData(widget.providerInfo)));
                    setData(newWidgetsData);
                    changeLoading(false);
                }
            })();
        },
        [
            updatedFilters,
            setData,
            metaData,
            getData,
            changeLoading
        ]
    );

    useEffect(
        () => {
            if(mounted && analysisId !== metaData.id) {
                changeLoading(true);
                setAnalysisId(metaData.id);
                setMounted(false);
            }
        },
        [
            analysisId,
            setAnalysisId,
            mounted,
            setMounted,
            metaData
        ]
    )

    return {
        data,
        setData,
        filters,
        getData,
        modulesIds
    }
};

export default useContainer;