import React from 'react'

class AssetServicePage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        AssetServicePage
      </div>
    )
  }
}

export default AssetServicePage
