import React, {Component} from 'react'

import { LogoContent, Logo } from './LogoStyle'

class LogoComponent extends Component {
  render () {
    return (
      <LogoContent>
        <Logo />
      </LogoContent>
    )
  }
}

export default LogoComponent
