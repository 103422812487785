const RAW_DATA = [
    {
        "alarm": "Generator stator over temperature winding 2",
        "alarm_id": 61,
        "classification": "Out of Electrical Specification",
        "classification_id": 6,
        "classification_initials": "IAONGEL",
        "comment": null,
        "device_id": 1101,
        "duration": 7200,
        "end_time": "Tue, 01 Jan 2019 07:00:00 GMT",
        "is_user_defined": false,
        "source": "auto",
        "start_time": "Tue, 01 Jan 2019 05:00:00 GMT",
        "status_base_id": 4,
        "status_cut_id": 7,
        "wtg": "P2A-01"
    },
    {
        "alarm": null,
        "alarm_id": null,
        "classification": "Full Performance",
        "classification_id": 1,
        "classification_initials": "IAOGFP",
        "comment": null,
        "device_id": 1101,
        "duration": 3600,
        "end_time": "Tue, 01 Jan 2019 05:00:00 GMT",
        "is_user_defined": null,
        "source": "no_source",
        "start_time": "Tue, 01 Jan 2019 04:00:00 GMT",
        "status_base_id": null,
        "status_cut_id": 6,
        "wtg": "P2A-01"
    },
    {
        "alarm": "Yaw limit switch activated",
        "alarm_id": 41,
        "classification": "Environmental - Low Wind Speed",
        "classification_id": 4,
        "classification_initials": "IAONGEN-LW",
        "comment": null,
        "device_id": 1101,
        "duration": 7200,
        "end_time": "Tue, 01 Jan 2019 04:00:00 GMT",
        "is_user_defined": false,
        "source": "auto",
        "start_time": "Tue, 01 Jan 2019 02:00:00 GMT",
        "status_base_id": 2,
        "status_cut_id": 2,
        "wtg": "P2A-01"
    }
]

export const DEFINITION_LEVEL = 'source';
export const START_TIME = 'start_time';
export const END_TIME = 'end_time';
export const DURATION = 'duration';
export const ALARM = 'alarm';
export const CLASSIFICATION = 'classification';
export const RESPONSIBILITY = 'responsibility';
export const COMMENTS = 'comment';
export const CHECKED = 'checked';

// DEFINING TABLE HEADERS LABELS
const DEFINITION_LEVEL_LABEL = '';
const START_TIME_LABEL = 'Start Time';
const END_TIME_LABEL = 'End Time';
const DURATION_LABEL = 'Duration';
const ALARM_LABEL = 'Assoc Alarm';
const CLASSIFICATION_LABEL = 'Classification';
const RESPONSIBILITY_LABEL = 'Responsibility';
const COMMENTS_LABEL = 'Comments';
const CHECKED_LABEL = 'Approved';

const devicesInData = [...new Set(RAW_DATA.map(data => data.wtg))]

export const TEST_DATA = devicesInData.map(device => (
    RAW_DATA.filter(data => data.wtg === device)
        .reduce((acc, curr) => ({
            device: curr.wtg,
            headers: [
                { id: DEFINITION_LEVEL, numeric: false, disablePadding: false, label: DEFINITION_LEVEL_LABEL },
                { id: START_TIME, numeric: false, disablePadding: false, label: START_TIME_LABEL },
                { id: END_TIME, numeric: false, disablePadding: false, label: END_TIME_LABEL },
                { id: DURATION, numeric: true, disablePadding: false, label: DURATION_LABEL },
                { id: ALARM, numeric: false, disablePadding: false, label: ALARM_LABEL },
                { id: CLASSIFICATION, numeric: false, disablePadding: false, label: CLASSIFICATION_LABEL },
                { id: COMMENTS, numeric: false, disablePadding: false, label: COMMENTS_LABEL },
                { id: CHECKED, numeric: false, disablePadding: false, label: CHECKED_LABEL },

            ],
            rows: acc.rows ? [...acc.rows, {
                [DEFINITION_LEVEL]: curr[DEFINITION_LEVEL],
                [START_TIME]: curr[START_TIME],
                [END_TIME]: curr[END_TIME],
                [DURATION]: curr[DURATION],
                [ALARM]: curr[ALARM],
                [CLASSIFICATION]: curr[CLASSIFICATION_LABEL],
                [COMMENTS]: curr[COMMENTS] || [''],
                status_cut_id: curr.status_cut_id,
                checked: curr.checked

            }] : [{
                [DEFINITION_LEVEL]: acc[DEFINITION_LEVEL],
                [START_TIME]: acc[START_TIME],
                [END_TIME]: acc[END_TIME],
                [DURATION]: acc[DURATION],
                [ALARM]: acc[ALARM],
                [CLASSIFICATION]: acc[CLASSIFICATION_LABEL],
                [COMMENTS]: acc[COMMENTS] || [''],
                status_cut_id: acc.status_cut_id,
                checked: acc.checked
            }, {
                [DEFINITION_LEVEL]: curr[DEFINITION_LEVEL],
                [START_TIME]: curr[START_TIME],
                [END_TIME]: curr[END_TIME],
                [DURATION]: curr[DURATION],
                [ALARM]: curr[ALARM],
                [CLASSIFICATION]: curr[CLASSIFICATION_LABEL],
                [COMMENTS]: curr[COMMENTS] || [''],
                status_cut_id: curr.status_cut_id,
                checked: acc.checked

            }]
        }))
));

// EXTRAS
export const rowsAmt = 200;
export const sourceValues = ['no_source', 'auto', 'user'];
export const SOURCE_LABELS = { auto: 'From Device', user: 'User Defined' };
export const ROWS_PER_PAGE = 7;

export const TEST_ACTIONS = [
    // { id: 'create', name: 'Create', selection: 'none' },
    { id: 'split', name: 'Split', selection: 'single' },
    { id: 'edit', name: 'Edit', selection: 'single' },
    { id: 'delete', name: 'Delete', selection: 'single-user' },
    { id: 'merge', name: 'Merge', selection: 'group-adjascent' },
    { id: 'approve', name: 'Approve', selection: 'multiple' },
    { id: 'unapprove', name: 'Unapprove', selection: 'multiple' }


]

export const ACTION_ICONS = {
    merge: 'call_merge',
    split: 'call_split',
    create: 'add',
    approve: 'check',
    unapprove: 'clear',
    delete: 'delete',
    edit: 'create',
}

export const ACTION_SELECTION_PRETTY_NAME = {
    none: 'Select No Rows',
    single: 'Select Just One Row',
    'single-user': 'Select Just One Row USER DEFINED',
    'group-adjascent': 'Select Two or More Adjascent Rows',
    'group-sparse': 'Select Two or More Sparse Rows'
}

export const SELECTORS = [
    { type: 'select', id: 'alarm', name: 'Alarm' },
    { type: 'select', id: 'system', name: 'System' },
    { type: 'select', id: 'classification', name: 'Classification' },
    { type: 'select', id: 'responsibility', name: 'Responsibility' },
    { type: 'date', id: 'start_time', name: 'Start' },
    { type: 'date', id: 'end_time', name: 'End' }
]

export const SELECT_CHOICES = {
    alarm: ['Alarm 1', 'Alarm 2', 'Alarm 3'],
    classification: ['Classification 1', 'Classification 2', 'Classification 3'],
    responsibility: ['Responsibility 1', 'Responsibility 2', 'Responsibility 3'],
}

export const DATE_CHOICES = {
    start_time: '2019-01-01',
    end_time: '2019-01-01'
}