import React, {
    useState,
    useEffect,
    useRef
} from 'react';

import {
    TableCell,
    Tooltip
} from '@material-ui/core';

const View = props => {
    const { children } = props;

    const ref = useRef();
    const [ isTooltipActive, setTooltipActive ] = useState(false);

    useEffect(() => {
        const dom = ref.current;
        if(dom && dom.offsetWidth < dom.scrollWidth) {
            setTooltipActive(true);
        }
    });

    const cell = (
        <TableCell
            {...props}
            ref={ref}
        >
            {children}
        </TableCell>
    );

    return (
        isTooltipActive ?
            <Tooltip
                title={children}
            >
                {cell}
            </Tooltip>
        :
            cell
    )
};

export default View;