import React from 'react';
import {
    FormControl,
    InputAdornment,
    TextField,
    FormHelperText,
    MenuItem
} from '@material-ui/core';
import chance from 'chance';
import { useTranslation } from 'react-i18next';


const Chance = new chance(121211313131212123131);

const OutlinedView = props => {

    const {
        id = Chance.hash(),
        label,
        error,
        required,
        subLabel,
        value,
        changeValueFunction,
        options,
        noValueText = 'Nothing to List',
        style = undefined,
        selectorProps = {}
    } = props;

    const { t } = useTranslation();


    return (
        <FormControl
            variant='outlined'
            style={{
                ...style,
                ...(selectorProps.style || {})
            }}
        >
            <TextField
                select
                variant='outlined'
                label={label}
                value={value}
                onChange={event => changeValueFunction(event && event.target ? event.target.value : null)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position='start'
                        >
                            {subLabel}
                        </InputAdornment>
                    )
                }}
            >
                {
                    options ?
                        Object.values(options).map(option => (
                            <MenuItem
                                dense
                                key={option.id}
                                value={option.id}
                                name={option.name}
                                className={option.name}
                                style={selectorProps.style}
                            >
                                {option.name}
                            </MenuItem>
                        ))
                        :
                        <MenuItem
                            dense
                            key={'no-value'}
                            value={-1}
                            name={'no-value'}
                            className={'no-value'}
                            disabled
                            style={selectorProps.style}
                        >
                            {noValueText}
                        </MenuItem>
                }
            </TextField>

            {
                error && required && !value &&
                <FormHelperText id={`${id}`}>
                    <span
                        style={{
                            textTransform: 'initial',
                            color: 'red',
                            padding: 0,
                            margin: 0,
                            fontSize: '.7em',
                            whiteSpace: 'nowrap',
                            maxWidth: '120px'
                        }}
                    >
                        {t('Please, fill this field!')}
                    </span>
                </FormHelperText>
            }
        </FormControl>

    )
}

export default OutlinedView;