import { 
    REDUCER_AUTH_LOGOUT
} from '../constants/reducers/auth-action-events';

import {
    CLEAR_AUTH_ERROR
} from '../actions/types';

const DEFAULT_STATE = null;

export default (state = DEFAULT_STATE, action) => {
    const { type, error } = action;

    switch (type) {
        case REDUCER_AUTH_LOGOUT:
            return error;
        case CLEAR_AUTH_ERROR:
            return null;
        default:
            return state;
    }
}