import { deviceHierarchy } from '../helpers/data-conforming-helper';

import {  
    SET_METADATA
} from '../actions/types';

const DEFAULT_STATE = {};

export default (state = DEFAULT_STATE, action) => {
    const { 
        type,
        meta,
        Client
    } = action;

    switch (type) {
        case SET_METADATA:
            return meta ?
                deviceHierarchy(meta)
            : Client ?
                deviceHierarchy(Client)
            :
                state;
        default:
            return state;
    }
}
