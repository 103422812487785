import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';


import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 320,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'visible',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    left: 2,
    overflow:'visible',
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 20,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} style={{cursor:'pointer'}} />;
}

function Control(props) {
  return (
    <TextField
      
      InputProps={{
        disableUnderline: true,
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {


  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <Typography
        variant="h3"
        component="p"
        style={{
          fontSize: "1.0rem"
        }}
      >
        {props.children}
      </Typography>
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography> 
  );
}

function SingleValue(props) {
  return (
    <div>
      <Typography
        variant="h2"
        style={{
          marginBottom: 8,
          fontSize: "1.5rem"
        }}  
      >
        {props.children} 
      </Typography>
    </div>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}auto
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {


  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class SelectAutocomplete extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      single: this.props.value,
      multi: null,
      options: this.props.options,
      allOptions: this.props.options,
      filteredOptions: this.props.options,
      keyDownEvent: null
    };
  }



  handleClick = data => value => {
    if (this.state[data.label]) {
      this.setState({
        [data.label]: !this.state[data.label],
      });

      if (this.props.height) {
        this.setState({
          height: this.props.height / 3.6
        });
      }


    } else {
      this.setState({
        [data.label]: true,
      });

      if (this.props.height) {
        this.setState({
          height: this.props.height * 3.6
        });
      }
    }

    if (this.state[data.label]) {
      document.getElementById(data.id).parentElement.parentElement.parentElement.children[1].style.display = 'block';
    } else {
      document.getElementById(data.id).parentElement.parentElement.parentElement.children[1].style.display = 'none';

    }



  };
  //
  // handleChange = (name,value) => value => {
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  render() {
    const { classes, theme } = this.props;


    const formatGroupLabel = (data) => (

      <div>
        <div
          style={{
            display: 'none'
          }}>
          {data.id = data.label.toLowerCase().trim()}
        </div>
        <ListItem
          onClick={this.handleClick(data)}
          onHover={this.handleClick(data)}
          id={data.id}
        >
          <ListItemText>
            <span>{data.label}</span>
          </ListItemText>
          {this.state[data.label] == true ? <ExpandMore /> : <ExpandLess />}
        </ListItem>
      </div>
    );

    const selectStyles = {
      input: base => ({
        ...base,
        // color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root} style={{ height: this.state.height ? this.state.height : this.props.height ? this.props.height : 180 }}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            options={this.state.filteredOptions}
            components={components}
            placeholder={this.props.label}
            value={this.props.value}
            onChange={this.props.onChange.bind(this.state.single)}
            // onKeyDown={this.props.onKeyDown.bind(this.state.keyDownEvent, this)}
            formatGroupLabel={formatGroupLabel.bind(this)}
          />
        </NoSsr>
      </div>
    );
  }
}

SelectAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectAutocomplete);
