import React, {Component} from 'react'
import {
  Toolbar,
  withStyles,
  Button,
  Tooltip,
  Grid,
  Typography
} from '@material-ui/core'
import moment from 'moment-timezone'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';
import Icon from '@material-ui/core/Icon';

import {Popper, Fade, Paper, ListSubheader} from '@material-ui/core'

import MenuTree from '../../../../../components/Menu/MenuTree'
import EnvelopeMenuTree from '../../../../../components/Menu/EnvelopeMenuTree'
import { changeUpdateWidgets } from '../../../../../actions/MenuTreeAction'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import { FILTER_TYPE_WTG_ENVELOPE, FILTER_TYPE_WTG } from '../../../../../constants/management-type';
import * as R from 'ramda'

import { selectPage, updateDateFilter, selectWtgAndEnvelope } from '../../../../../actions/PageActions'
import { retrieveDataDowntimeModule } from '../../../../../actions/RetrieveDataAction'

import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'

import { STYLE_SUBHEADER_TOOLBAR, STYLE_HEADER_TOOLBAR } from '../../../../../constants/style-constant';

const styles = theme => ({
  root: {
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    right: 0,
    maxHeight: 300,
    zIndex: 10
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  paperMenuBackground: {},
  appBar: {
    backgroundColor: 'white'
  },
  buttonIconDropDown: {
    marginLeft: '1px'
  },
  appBarToolbar: {

  },
  appBarToolbarSubRoot: {
    minHeight: STYLE_SUBHEADER_TOOLBAR,
    marginRight: 26,
  },
  appBarToolbarSub: {
    minHeight: '0',
    height: '30px',
    paddingLeft: '0',
    marginLeft: 'auto',
    width: 'auto',
    backgroundColor: '#fff'
  },
  buttonMenu: {
    textTransform: 'capitalize',
    fontWeight: '200',
    fontSize: '16px',
    borderRadius: '0',
    zIndex: 10
  },
  buttonsContainer: {
    position: 'absolute',
    right: 0,
    display: 'flex'
  },
  buttonMenuSubHeader: {
    textTransform: 'capitalize',
    fontWeight: '200',
    fontSize: '16px',
    border: `1px solid ${grey['200']}`,
    minHeight: '40px',
    borderRadius: '0',
    zIndex: 10
  },
  buttonMenuSubHeaderSelect: {
    textTransform: 'capitalize',
    fontWeight: '200',
    fontSize: '16px',
    borderRadius: '0',
    backgroundColor: orange['700'],
    color: 'white',
    minHeight: '40px',
    '&:hover': {
      backgroundColor: orange['900'],
    },
    zIndex: 10
  }
})

const maxDate = moment().subtract(1, 'days')

class DowntimeFilter extends Component {
  state = {
    managementsAnchorEl: null,
    focusedInput: null,
    startDate: moment().subtract(10, 'days'),
    endDate: maxDate,
    updateWidgetInterval: null,
    anchorEl: null,
    open: false,
    currToken: null
  }

  componentDidMount () {
    const elms = window.document.getElementsByClassName('DateRangePickerInput')

    if (elms.length > 0) {
      const el = elms[0]

      el.parentNode.style.height = '100%'
    }

    const {page, reducer_name, module_field} = this.props
    if(!'data' in page || !page.data) {
      this.props.retrieveDataDowntimeModule(module_field, page.id, reducer_name)
    }
  }

  handleChangeDates ({ startDate, endDate }) {
    if (startDate) {
      this.setState({startDate})
    }

    if (endDate) {
      this.setState({endDate})
    }

    if (startDate && endDate) {
      const {page, reducer_name, module_field} = this.props

      this.props.updateDateFilter(page.id, startDate, endDate, module_field, reducer_name)

      if (this.state.updateWidgetInterval) {
        clearTimeout(this.state.updateWidgetInterval)
        this.setState({ updateWidgetInterval: null })
      }

      const interval = setTimeout(() => {
        this.props.retrieveDataDowntimeModule(module_field, page.id, reducer_name)

        this.setState({ updateWidgetInterval: null })
      }, 2000)

      this.setState({ updateWidgetInterval: interval })
    }
  }

  selectPage = (page_id, event) => {
    const {module_field, reducer_name} = this.props

    this.props.selectPage(page_id, module_field, reducer_name)
    this.menuClose.bind(this, 'pagesAnchorEl')()
  }

  goPage (page) {
    const {history} = this.props

    history.push(page)
  }

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  onSelectModel = (page_id, wtg_id, envelope_id) => () => {
    const {module_field, reducer_name} = this.props

    this.handleClick({currentTarget: null})
    // this.props.selectWtgAndEnvelope(page_id, wtg_id, envelope_id, module_field, reducer_name)
  }

  menuOpen (key, event) {
    this.setState({ [key]: event.currentTarget });
  }

  menuClose = (key) => {
    this.setState({ [key]: null });
  }

  render () {
    const {classes, page, Dashboard, mode, parks_info, module_field, reducer_name} = this.props

    const {filter} = page

    const pages = module_field in Dashboard ? Dashboard[module_field] : []

    const wtgs = R.flatten(parks_info.map(park => {
      return park.subparks.map(subpark => {
        return subpark.wtgs.map(wtg => {
          return {
            ...wtg,
            subpark_id: subpark.id,
            subpark_name: subpark.name,
            park_id: park.id,
            park_name: park.name
          }
        })
      })
    }))

    const curr_devices_names = wtgs.filter(wtg => filter.devices.indexOf(wtg.id) >= 0)
                                   .map(wtg => wtg.name);

    const curr_subparks_names = [...new Set(wtgs.filter(wtg => filter.devices.indexOf(wtg.id) >= 0)
                                   .map(wtg => wtg.subpark_name))];

    let curr_envelopes = filter && 'envelope_id' in filter && filter.envelope_id ?
      wtgs.filter(wtg => wtg.envelope.map(envelope => envelope.id).indexOf(filter.envelope_id) >= 0)
          .map(wtg => wtg.envelope.filter(envelope => envelope.id == filter.envelope_id)[0].name)
    :
      null;

    curr_envelopes = curr_envelopes ? curr_envelopes.map(envelope => {
      envelope = envelope == 'Average Generator Speed 10M vs Generator Speed St. Dev. 10M'        ? 'Generator Speed I'
        : envelope == 'Average Active Power 10M vs Trafo 3 winding temperature 10M'      ? 'Trafo Temperature III'
        : envelope == 'Average Active Power 10M vs Average Reactive Power 10M'           ? 'Reactive Power'
        : envelope == 'Average Active Power 10M vs Bearing N.D.E. Temperature 10M'       ? 'NDE Bearing Temperature'
        : envelope == 'Average Active Power 10M vs Average Stator Active Power 10M'      ? 'Stator Act. Power'
        : envelope == 'Average Rotor Speed 10M vs Rotor Speed St. Dev. 10M'              ? 'Rotor Speed I'
        : envelope == 'Average Active Power 10M vs Trafo 2 winding temperature 10M'      ? 'Trafo Temperature II'
        : envelope == 'Average Active Power 10M vs Generator windings temperature 2 10M' ? 'Gen. Windings Temperature II'
        : envelope == 'Average Active Power 10M vs Generators sliprings temperature 10M' ? 'Gen. Slipring'
        : envelope == 'Average Active Power 10M vs Generator windings temperature 3 10M' ? 'Gen. Windings Temperature III'
        : envelope == 'Average Active Power 10M vs Gearbox bearing temperature 10M'      ? 'Gearbox Bearing Temperature'
        : envelope == 'Average Active Power 10M vs Average Rotor Speed 10M'              ? 'Rotor Speed II'
        : envelope == 'Average Wind Speed 10M vs Average Active Power 10M'               ? 'Measured Power Curve'
        : envelope == 'Average Active Power 10M vs Average Generator Speed 10M'          ? 'Generator Speed II'
        : envelope == 'Average Generator Speed 10M vs Average Rotor Speed 10M'           ? 'Rotor Speed III'
        : envelope == 'Average Active Power 10M vs Bearing D.E. Temperature 10M'         ? 'DE Bearing Temperature'
        : envelope == 'Average Active Power 10M vs Average Grid Voltage 10M'             ? 'Grid Voltage'
        : envelope == 'Average Active Power 10M vs Yaw Misalignment = (WTG Nacelle Position) - (MetMast Wind Dir)' ? 'Yaw Misalignment'
        : envelope == 'Average Active Power 10M vs Gearbox oil temperature 10M'          ? 'Gearbox Oil'
        : envelope == 'Average Active Power 10M vs Hidraulic group pressure 10M'         ? 'Hidraulic Group'
        : envelope == 'Average Active Power 10M vs Average Pitch Angle 10M'              ? 'Pitch Angle'
        : envelope == 'Average Active Power 10M vs Generator windings temperature 1 10M' ? 'Gen. Windings Temperature I'
        : envelope == 'Average Active Power 10M vs Trafo 1 winding temperature 10M'      ? 'Trafo Temperature I'
        : envelope
      return envelope;
    })
    :
      null;

    return (
      <div className={classes.appBarToolbarSubRoot}>
        <Grid container spacing={24} style={{alignItems: 'flex-end'}} >
          <Grid key={1} item xs={3}
            style={{
              textAlign: 'center'
            }}
          >
            {
            // <Button
            //   onClick={this.menuOpen.bind(this, 'pagesAnchorEl')}
            //   style={{
            //     marginLeft: 32,
            //     textTransform: 'capitalize',
            //     fontSize: '1.5rem'
            //   }}
            // >
            }
              <Typography variant="h2" component="p"
                style={{
                  textTransform: 'capitalize',
                  fontSize: '1.5rem',
                  marginLeft: 8,
                  marginTop: 8,
                  marginBottom: 8,
                  marginRight: '50px'
                }}
              >
                {page.name}
              </Typography>
            {
            // </Button>
            }
            <Menu
              id="lock-menu"
              anchorEl={this.state.pagesAnchorEl}
              open={Boolean(this.state.pagesAnchorEl)}
              onClose={this.menuClose.bind(this, 'pagesAnchorEl')}
            >
              {
                pages.map((a_page, index) => (
                  <MenuItem
                    key={a_page.id}
                    selected={page.id === a_page.id}
                    onClick={this.selectPage.bind(this, a_page.id)}
                  >
                    {a_page.name}
                  </MenuItem>
                ))
              }
            </Menu>
          </Grid>
          <Grid key={2} item xs={9} >
            <Toolbar className={classes.appBarToolbarSub} variant="dense">
              <div className={classes.buttonsContainer} >
                {
                  'devices' in filter ?
                    <MenuTree
                      buttonMenuSubHeader={classes.buttonMenuSubHeader}
                      page={page}
                      module_field={module_field}
                      reducer_name={reducer_name}
                    >
                      {/*Parks*/}
                      <React.Fragment>
                        <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>
                          toys
                        </Icon>
                        {
                          'type' in page && (page.type == FILTER_TYPE_WTG || page.type == FILTER_TYPE_WTG_ENVELOPE) ?
                            <span>{curr_devices_names[0]}</span>
                          :
                          curr_subparks_names.length >= 2 ?
                            <Tooltip title={curr_subparks_names.join(', ') + ' ~ ' + curr_devices_names.join(', ')}>
                              <span>{`${curr_subparks_names.length} subparks`}</span>
                            </Tooltip>
                          :
                            <span>{curr_subparks_names.join(', ')}</span>
                        }
                      </React.Fragment>
                    </MenuTree>
                  :
                    null
                }
                {
                  'envelope_id' in filter ?
                    <EnvelopeMenuTree
                      buttonMenuSubHeader={classes.buttonMenuSubHeader}
                      page={page}
                      module_field={module_field}
                      reducer_name={reducer_name}
                    >
                      {/*Models*/}
                      <React.Fragment>
                        <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>
                          timeline
                        </Icon>
                        {
                          curr_envelopes.length >= 3 ?
                            <Tooltip title={curr_envelopes.join(', ')}>
                              <span>{`${curr_envelopes.length} models`}</span>
                            </Tooltip>
                          :
                            <span>{curr_envelopes.join(', ')}</span>
                        }
                      </React.Fragment>
                    </EnvelopeMenuTree>
                  :
                    null
                }
                {
                  'data_range' in filter ?
                    <div className={classes.buttonMenuSubHeader} >
                      <DatePicker
                        parks_info={parks_info}
                        handleChangeDates={this.handleChangeDates.bind(this)}
                        page={page}
                      />
                    </div>
                  :
                    null
                }
              </div>
            </Toolbar>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({Dashboard, User}) => {
  return {Dashboard, parks_info: User.parks_info}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateDateFilter,
  changeUpdateWidgets,
  selectPage,
  selectWtgAndEnvelope,
  retrieveDataDowntimeModule
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DowntimeFilter))
