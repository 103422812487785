import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  tbody tr:hover {
    background-color:#f5f5f5;
    cursor: pointer;
  }
`

class AssetModelDetailComponent extends React.Component {
  render () {
    const assetModel = R.find(R.propEq('id', Number(this.props.match.params.id)))(this.props.assetModel)

    if (!assetModel) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          Not data
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          padding: 20
        }}
      >
        <h2>
          {assetModel.name}
        </h2>
        <h4>
          {assetModel.manufacturer}
        </h4>
      </div>
    )
  }
}

export default AssetModelDetailComponent
