import React, { useState } from 'react';

import {
    singleMovement
} from '../../../../../../../providers/asset-provider';

import CircularLoading from '../../../../../../../components/Loading/CircularLoading';
import BaseViewDialog, {
    MovementForm
} from '../../../../../../../new-components/Dialog';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';


const View = props => {
    const {
        component,
        serial,
        openedMovementForm,
        openMovementForm,
        setMovementData,
        movementData,
        saveInfo,
        finishMount,
        setOpened,
        lastMovementLocation,
        models,
        storages,
        savingInfo,
        component_id,
        activeResource,
        getComponents
    } = props;

    const [formError, setFormError] = useState(false);
    const { t } = useTranslation();

    const getSuccessMessage = movement => {
        let message = `${t('Successfully moved component')} ${component} #${serial}`;

        const { destiny_name } = lastMovementLocation || {};
        const { to_storage_id } = movement || {};
        const storage = storages && storages.find && storages.find(s => s.id === to_storage_id)

        if (destiny_name && storage) {
            message += ` ${t('from')} ${destiny_name} ${t('to')} ${storage.name}`;
        }

        message += '!';

        return message;
    };

    return (
        <BaseViewDialog
            title={`${t('Moving Component')}: ${component} #${serial}`}
            confirm={t('Move')}
            disableDelete={true}
            disableTopClose
            opened={openedMovementForm}
            Open={openMovementForm}
            dialogProps={{
                style: {
                    zIndex: 99999
                }
            }}
            contentProps={{
                style: {
                    overflowX: 'hidden'
                }
            }}
            onCancel={() => {
                setMovementData({});
                openMovementForm(false);
            }}
            onConfirm={async () => {
                const canExecute = Object.values(movementData).map(field => (
                    !field.required || (field.required && field.data != null)
                )).reduce((acc, curr) => acc && curr);

                if (canExecute) {
                    saveInfo(true);

                    const extra_info = Object.values(movementData).filter(field => (
                        field.dataField != null && field.extra_info
                    )).map(field => ({
                        [field.dataField]: (
                            typeof (field.dataField) === 'object' &&
                                field.dataField.constructor === Date ?
                                field.data.toISOString().split('T')[0]
                                : field.dataField === 'application_date' ||
                                    field.dataField === 'warranty_application_date' ||
                                    field.dataField === 'application_expiration' ||
                                    field.dataField === 'date_of_next_action' ?
                                    field.data.toISOString().split('T')[0]
                                    :
                                    field.data
                        )
                    })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

                    const movement = Object.values(movementData).filter(field => (
                        field.dataField != null && !field.extra_info
                    )).map(field => ({
                        [field.dataField]: (
                            typeof (field.dataField) === 'object' &&
                                field.dataField.constructor === Date ?
                                field.data.toISOString().split('T')[0]
                                : field.dataField === 'application_date' ||
                                    field.dataField === 'warranty_application_date' ||
                                    field.dataField === 'application_expiration' ||
                                    field.dataField === 'date_of_next_action' ?
                                    field.data.toISOString().split('T')[0]
                                    :
                                    field.data
                        )
                    })).reduce((acc, curr) => ({ ...acc, ...curr }), { component_id, extra_info });

                    const movementRes = await singleMovement(movement);

                    if (!movementRes.error) {
                        try {
                            await getComponents(activeResource.component_model_id, movement.to_storage_id);
                        } catch (e) {
                            console.log(e);
                        }

                        const successMessage = getSuccessMessage(movement);

                        toast.success(successMessage, {
                            position: 'top-right',
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true
                        });
                    } else {
                        toast.error(movementRes.error, {
                            position: 'top-right',
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true
                        });
                    }

                    saveInfo(false);
                    finishMount(false);
                    setMovementData({});
                    openMovementForm(false);
                    setOpened(false);
                } else {
                    setFormError(true);

                }

            }}
        >
            <MovementForm
                fromStorage={lastMovementLocation && lastMovementLocation.destiny_name}
                feedDataOut={setMovementData}
                options={models}
                error={formError}
                storages={storages}
                formProps={{
                    style: {
                        minHeight: '40vh',
                        maxHeight: '55vh',
                    }
                }}
                selectorProps={{
                    style: {
                        zIndex: 999999
                    }
                }}
                activeResource={activeResource}
            />
            {
                savingInfo &&
                <CircularLoading>
                    <span>{t('Moving your component')}</span>
                </CircularLoading>
            }
        </BaseViewDialog >
    );
};

export default View;