const docs = {
    'manuais': {
        id: 'manuais',
        name: 'Manuais',
        content: {
            'inv-manual': {
                id: 'inv-manual',
                name: 'Inventário',
                file: require('./manual_inventario_enerplan.pdf')
            }
        }
    }
}

export default docs;