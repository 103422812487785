import React, {Component} from 'react'
import {withStyles, List, ListSubheader, ListItem, ListItemText, Paper, Checkbox} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { WIDGET_HEADER_HEIGHT } from '../../constants/widget-constant';
import { FILTER_TYPE_PARKS } from '../../constants/filter-types';

const styles = theme => ({
  paperElement: {
    border: `1px solid ${grey['200']}`,
    borderRadius: 0,
    flex: 1
  },
  paperHeader: {
    paddingLeft: '10px',
    minHeight: `${WIDGET_HEADER_HEIGHT}px`,
    borderBottom: `1px solid ${grey['200']}`,
    display: 'flex',
    alignItems: 'center'
  },
  listItems: {
    width: '100%',
    minWidth: 300,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
})

class FilterParks extends Component {

  constructor(props) {
    super(props)

    this.state = {
      params: {}
    }

    this.refChart = React.createRef()
  }

  changeValueFilter(park)  {
    this.props.changeValueFilter(FILTER_TYPE_PARKS, park)
  }

  render () {
    const {classes, parks_info} = this.props

    return (
      <Paper className={classes.paperElement} elevation={0}>
        <div className={classes.paperHeader}>
          <div>
            Parks
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
        <List className={classes.listItems} subheader={<li />}>
          {parks_info.map((park, indexPark) => (
            <ListItem
              key={`filter_park_park_${indexPark}`}
              role={undefined}
              dense
              button
              onClick={this.changeValueFilter(park).bind(this)}
            >
              <Checkbox
                checked={false}
                tabIndex={-1}
              />
              <ListItemText primary={park.park} />
            </ListItem>
          ))}
        </List>
      </Paper>
    )
  }
}

export default withStyles(styles)(FilterParks)
