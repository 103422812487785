import React from 'react';
import EventsTablePage from './EventsTablePage';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

const EventsTablePageParksInfo = props => (
    (
        props.parksInfo && props.parksInfo.constructor === Array && props.parksInfo.length > 0 &&
        <EventsTablePage {...props} />
    ) 
    || <h1>Waiting data of the Complex...</h1>
);

const mapStateToProps = ({ User, Client, Dashboard, DevicesHierarchy }) => {
    const parksInfo = User.parks_info;
    const { DeviceFilter } = Dashboard;

    const hasDeviceData = DeviceFilter && DeviceFilter.devices && DeviceFilter.devices.length >= 0;
    const hasSubparkData = DeviceFilter && DeviceFilter.subparks && DeviceFilter.subparks.length >= 0;

    const initialDevices = DevicesHierarchy ? Object.keys(Object.values(Object.values(DevicesHierarchy)[0].subparks)[0].devices) : [];
    const initialSubparks = DevicesHierarchy ? [Object.keys(Object.values(DevicesHierarchy)[0].subparks)[0]] : [];

    const devices = hasDeviceData ? DeviceFilter.devices : initialDevices.map(device => Number(device));
    const subparks = hasSubparkData ? DeviceFilter.subparks : initialSubparks.map(subpark => Number(subpark));

    return { 
        parksInfo, 
        devices, 
        subparks 
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventsTablePageParksInfo);