import { WIDGET_HEADER_HEIGHT } from '../../../../constants/widget-constant'
import grey from '@material-ui/core/colors/grey'

export default theme => ({
  mapContent: {
    height: '100%',
    width: '100%'
  },
  paperElement: {
    //width: `calc(100% - 4px)`,
    height: `calc(100% - 2px)`,
    //borderRadius: 5,
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerTitle: {
    paddingTop: 10,
    paddingBottom: 10
  },
  paperBody: {
    // height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
    width: '100%',
    //borderRadius: 5,
    flex: 1
  }
})