import React, {
    useState,
    useEffect
} from 'react';
import TextField from '../TextField';
import SelectorDayView from '../Dialog/components/FormContent/components/Selector/DayView';
import Selector from '../Dialog/components/FormContent/components/Selector';
import ComponentImage from '../Dialog/components/FormContent/components/ImageInputWrapper';
import { useTranslation } from 'react-i18next';


const View = props => {

    const {
        setResource,
        activeResource,
        resources,
        error,
        models,
        formProps = {},
        modulePage,
        fileRef
    } = props;

    const { t } = useTranslation();

    const fields_object = resources.fields;

    if (activeResource) {

        Object.keys(fields_object).map(key => {
            if (fields_object[key].type == 'date') {
                if (activeResource[key]) {
                    fields_object[key].data = new Date(String(activeResource[key]))
                }
            }
            else if (fields_object[key].type == 'reference') {
                if (activeResource[key]) {
                    const item = models.filter(model => model.name === activeResource[key]);
                    fields_object[key].data = item[0] ? item[0].id : -1
                }
            }
            else {
                if (activeResource[key]) {
                    fields_object[key].data = String(activeResource[key])
                }
            }
        })
    } else {
        Object.keys(fields_object).map(key => {
            fields_object[key].data = null;
        })
    }

    const defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() + 2);

    const [inputs, setInputValue] = useState(fields_object);

    const changeValueAdapter = id => value => {

        const data = (
            inputs[id].type == 'number' && value < 0 ?
                0
                :
                value
        );

        const payload = {
            [id]: {
                ...inputs[id],
                data
            }
        };

        setInputValue({
            ...inputs,
            ...payload
        });
    }

    useEffect(() => setResource(inputs), [inputs]);


    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: '85vh',
                ...(formProps.style || {})
            }}
        >
            {
                Object.keys(fields_object).map(index => {

                    if (fields_object[index].type === 'date') {

                        inputs[index].data = inputs[index].data ? inputs[index].data : new Date();

                    }

                    return (
                        fields_object[index].type === 'text' || fields_object[index].type === 'number' ?
                            <TextField
                                style={{ flex: '1 0 49%', padding: 1, marginBottom: 8 }}
                                label={`${t(fields_object[index].label)} *`}
                                placeholder={t(fields_object[index].label)}
                                value={inputs[index].data}
                                min={0}
                                type={fields_object[index].type}
                                error={error}
                                type={fields_object[index].type}
                                required={true}
                                changeValueFunction={changeValueAdapter(index)}
                            />
                            : fields_object[index].type === 'date' ?
                                <SelectorDayView
                                    style={{ flex: '1 0 33%', padding: 1, marginBottom: 8 }}
                                    label={`${t(fields_object[index].label)} *`}
                                    error={error}
                                    value={inputs[index].data ? inputs[index].data : new Date()}
                                    changeValueFunction={changeValueAdapter(index)}
                                />

                                : fields_object[index].type === 'reference' ?
                                    <Selector
                                        style={{ flex: '1 0 33%', padding: 1, marginBottom: 10 }}
                                        label={`${t(fields_object[index].label)} *`}
                                        subLabel={''}
                                        error={error}
                                        required={true}
                                        options={models}
                                        value={inputs[index].data}
                                        changeValueFunction={changeValueAdapter(index)}
                                    />

                                    : null
                    )
                })
            }
            {modulePage && modulePage.hasImage && <ComponentImage modelId={activeResource && activeResource.id} fileRef={fileRef} />}
        </div>
    )
}

export default View;

