import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import {
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  DialogActions
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {grey} from '@material-ui/core/colors'
import { toast } from 'react-toastify';
import { getAssetModelProvider, createAssetModelComponentProvider, getSystemProvider, updateAssetModelComponentProvider, deleteAssetModelComponentProvider } from '../../../../../../../../providers/asset-provider';

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  tbody tr:hover {
    background-color:#f5f5f5;
    cursor: pointer;
  }
`

const styles = {
  button: {
    borderRadius: 0,
    height: '100%',
    borderRight: `1px solid ${grey['200']}`
  },
  inputSearch: {
    '&:focus': {
      outline: 'none'
    }
  },
  iconButton: {
    cursor: 'pointer'
  },
  textField: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  }
}

class AssetModelComponentComponent extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      loadingDialog: false,
      dialogNew: false,
      dialogEdit: false,
      dialogDelete: false,
      id: null,
      systems: [],
      name: '',
      part_number: '',
      system: {}
    }
  }

  getSystems = () => {
    this.setState({loadingDialog: true})

    getSystemProvider()
      .then(systems => {
        this.setState({loadingDialog: false, systems})
      })
      .catch(error => {
        console.error('getSystemProvider', error)
        this.setState({loadingDialog: false})
      })
  }

  render () {
    const {classes} = this.props

    const assetModel = R.find(R.propEq('id', Number(this.props.match.params.id)))(this.props.assetModel)

    if (!assetModel) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          >
          Not data
        </div>
      )
    }

    return (
      <React.Fragment>
        <div
          style={{
            height: '100%',
            width: '100%',
            overflow: 'auto'
          }}
        >
          <TableStyled>
            <thead>
              <tr>
                <th>
                  Name
                </th>
                <th>
                  Part Number
                </th>
                <th>
                  System
                </th>
                <th>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {
                assetModel.components.map((item, indexItem) => {
                  return (
                    <tr
                      key={indexItem}
                    >
                      <td>
                        {item.name}
                      </td>
                      <td>
                        {item.part_number}
                      </td>
                      <td>
                        {item.system ? item.system.name: ''}
                      </td>
                      <td
                        style={{
                          width: 50
                        }}
                      >
                        <Tooltip
                          title="Edit"
                        >
                          <EditIcon
                            className={classes.iconButton}
                            onClick={() => {
                              this.setState(
                                {
                                  dialogEdit: true,
                                  id: item.id,
                                  name: item.name,
                                  part_number: item.part_number,
                                  system: item.system,
                                },
                                () => this.getSystems()
                              )
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          title="Delete"
                        >
                          <DeleteIcon
                            className={classes.iconButton}
                            onClick={() => {
                              this.setState({
                                id: item.id,
                                dialogDelete: true
                              })
                            }}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </TableStyled>
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: 10,
            right: 20
          }}
        >
          <Button
            variant='fab'
            color="primary"
            onClick={() => this.setState(
              {dialogNew: true},
              () => this.getSystems()
            )}
          >
            <AddIcon />
          </Button>
        </div>
        
        {/* DIALOG NEW */}
        <Dialog
          open={this.state.dialogNew}
          onClose={() => this.setState({dialogNew: false})}
        >
          <DialogTitle>
            New item
          </DialogTitle>
          <form
            noValidate
            autoComplete="off"
            style={{
              minWidth: 500,
              padding: 10
            }}
            onSubmit={e => {
              e.preventDefault()

              this.setState({loadingDialog: true})

              if (this.state.name
                && this.state.part_number
                && this.state.system
                && this.state.system.id
              ) {
                createAssetModelComponentProvider({
                  asset_model_id: assetModel.id,
                  name: this.state.name,
                  part_number: this.state.part_number,
                  system_id: this.state.system.id
                })
                  .then(() => {
                    return getAssetModelProvider()
                      .then(assetModel => {
                        this.props.setParam(
                          {
                            assetModel
                          },
                          () => {
                            this.setState({
                              loadingDialog: false,
                              dialogNew: false,
                              name: '',
                              part_number: '',
                              system: {}
                            })
                          }
                        )
                      })
                  })
                  .catch(error => {
                    console.error('createAssetModelComponentProvider', error)

                    this.setState({
                      loadingDialog: false
                    })
    
                    toast.error(
                      error.message,
                      {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      }
                    )
                  })
              } else {
                this.setState({
                  loadingDialog: false
                })

                toast.error('Invalid params', {
                  position: "top-right",
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true
                })
              }
            }}
          >
            <FormControl
              className={classes.formControl}
            >
              <InputLabel
                htmlFor="age-simple"
              >
                System
              </InputLabel>
              <Select
                value={this.state.system}
                onChange={(e) => this.setState({system: e.target.value})}
                renderValue={value => {
                  if (!value) {
                    return 'None'
                  }

                  return `${value.name}`
                }}
              >
                {
                  this.state.systems.map((system, indexSystem) => {
                    return (
                      <MenuItem
                        key={indexSystem}
                        value={system}
                      >
                        {system.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>

            <TextField
              label="Name"
              className={classes.textField}
              value={this.state.name}
              onChange={(e) => this.setState({name: e.target.value})}
            />

            <TextField
              label="Part number"
              className={classes.textField}
              value={this.state.part_number}
              onChange={(e) => this.setState({part_number: e.target.value})}
            />

            <div
              style={{
                marginTop: 20,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                type="submit"
                style={{
                  border: `1px solid ${grey['200']}`,
                  borderRadius: 0
                }}
              >
                Save
              </Button>
            </div>
          </form>
        </Dialog>
        
        {/* DIALOG EDIT */}
        <Dialog
          open={this.state.dialogEdit}
          onClose={() => this.setState({dialogEdit: false})}
        >
          <DialogTitle>
            Edit component
          </DialogTitle>
          <form
            noValidate
            autoComplete="off"
            style={{
              minWidth: 500,
              padding: 10
            }}
            onSubmit={e => {
              e.preventDefault()

              this.setState({loadingDialog: true})

              if (this.state.name
                && this.state.part_number
                && this.state.system
                && this.state.system.id
              ) {
                updateAssetModelComponentProvider({
                  id: this.state.id,
                  asset_model_id: assetModel.id,
                  name: this.state.name,
                  part_number: this.state.part_number,
                  system_id: this.state.system.id
                })
                  .then(() => {
                    return getAssetModelProvider()
                      .then(assetModel => {
                        this.props.setParam(
                          {
                            assetModel
                          },
                          () => {
                            this.setState({
                              loadingDialog: false,
                              dialogEdit: false,
                              id: null,
                              name: '',
                              part_number: '',
                              system: {}
                            })
                          }
                        )
                      })
                  })
                  .catch(error => {
                    console.error('updateAssetModelComponentProvider', error)

                    this.setState({
                      loadingDialog: false
                    })
    
                    toast.error(
                      error.message,
                      {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      }
                    )
                  })
              } else {
                this.setState({
                  loadingDialog: false
                })

                toast.error('Invalid params', {
                  position: "top-right",
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true
                })
              }
            }}
          >
            <FormControl
              className={classes.formControl}
            >
              <InputLabel
                htmlFor="age-simple"
              >
                System
              </InputLabel>
              <Select
                value={this.state.system}
                onChange={(e) => this.setState({system: e.target.value})}
                renderValue={value => {
                  if (!value) {
                    return 'None'
                  }

                  return `${value.name}`
                }}
              >
                {
                  this.state.systems.map((system, indexSystem) => {
                    return (
                      <MenuItem
                        key={indexSystem}
                        value={system}
                      >
                        {system.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>

            <TextField
              label="Name"
              className={classes.textField}
              value={this.state.name}
              onChange={(e) => this.setState({name: e.target.value})}
            />

            <TextField
              label="Part number"
              className={classes.textField}
              value={this.state.part_number}
              onChange={(e) => this.setState({part_number: e.target.value})}
            />

            <div
              style={{
                marginTop: 20,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                type="submit"
                style={{
                  border: `1px solid ${grey['200']}`,
                  borderRadius: 0
                }}
              >
                Save
              </Button>
            </div>
          </form>
        </Dialog>
        
        {/* DIALOG DELETE */}
        <Dialog
          open={this.state.dialogDelete}
          onClose={() => this.setState({ dialogDelete: false })}
        >
          <DialogTitle>
            Delete item
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => this.setState({dialogDelete: false})}
            >
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.setState({loadingDialog: true})
                
                deleteAssetModelComponentProvider(this.state.id)
                  .then(() => {
                    return getAssetModelProvider()
                      .then(assetModel => {
                        this.props.setParam(
                          {
                            assetModel
                          },
                          () => {
                            this.setState({
                              loadingDialog: false,
                              dialogDelete: false,
                              dialogEdit: false,
                              id: null
                            })
                          }
                        )
                      })
                  })
                  .catch(error => {
                    console.error('deleteAssetModelComponentProvider', error)
                    this.setState({
                      loadingDialog: false,
                      dialogDelete: false
                    })
                    toast.error('Save error', {
                      position: "top-right",
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true
                    })
                  })
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {
          this.state.loadingDialog
            ?
            (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  backgroundColor: `${grey['200']}`,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 999999999999,
                  opacity: '.5'
                }}
              >
                <CircularProgress className={classes.progress} />

                <span
                  style={{
                    marginTop: 20
                  }}
                >
                  Loading...
                </span>
              </div>
            )
            :
            null
        }
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(AssetModelComponentComponent)
