import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import styles, { LoginContent, LoginForm, LoginFormInputs } from './LoginStyle';

import CircularLoading from '../../../../components/Loading/CircularLoading';
import DelfosImg from '../../../../static-images/delfos_Intelligent_maintenance.png';

import { URL_NEW_VERSION } from '../../../../constants/url-constant';

class LoginView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loading: false
        }
    }

    handleChangeInput = stateName => event => {
        this.setState({
            [stateName]: event.target.value
        })
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({
            username: '',
            password: '',
            loading: true
        });

        const { formSubmit } = this.props;
        const submitRes = await Promise.resolve(formSubmit(this.state));
    }

    componentDidUpdate() {
        const { isAuthenticated, hasError } = this.props;
        const { loading } = this.state;

        if (loading && (isAuthenticated || hasError)) {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        const { classes, isAuthenticated } = this.props;
        const { loading, username, password } = this.state;
        return (
            <LoginContent>
                <Paper
                    className={classes.root}
                    elevation={1}
                >
                    <LoginForm
                        onSubmit={this.handleSubmit}
                    >
                        <div
                            className={classes.logoImgContainer}
                        >
                            <img
                                src={DelfosImg}
                                className={classes.logoImg}
                            />
                            <Typography
                                variant='headline'
                                component='h6'
                                className={classes.logoImgSubtitle}
                            >
                                Artificial Intelligence For The Energy Industry
                            </Typography>
                        </div>
                        <br />
                        <br />
                        {
                            loading ?
                                <CircularLoading
                                    notOverlay={true}
                                >
                                    <span>Please wait while we are getting your data.</span>
                                </CircularLoading>
                                :
                                <Fragment>
                                    <Typography
                                        className={classes.formTitle}
                                        component='p'
                                    >
                                        Entre com suas credenciais:
                                    </Typography>
                                    <LoginFormInputs>
                                        <TextField
                                            className={classes.textField}
                                            label='Usuário'
                                            placeholder='Digite seu nome de usuário'
                                            value={username}
                                            onChange={this.handleChangeInput('username')}
                                        />
                                        <TextField
                                            className={classes.textField}
                                            label='Senha'
                                            placeholder='Digite sua senha'
                                            type='password'
                                            value={password}
                                            onChange={this.handleChangeInput('password')}
                                        />
                                    </LoginFormInputs>
                                    <Button
                                        type='submit'
                                        className={classes.buttonSignIn}
                                        variant='contained'
                                        color='primary'
                                    >
                                        Log In
                                    </Button>
                                </Fragment>
                        }
                    </LoginForm>
                    <Button className={classes.link} href={URL_NEW_VERSION} target="_blank">
                        Go to Version 3.0
                    </Button>
                </Paper>
            </LoginContent>
        )
    }
}

export default withStyles(styles)(LoginView);