import { withStyles } from '@material-ui/core/styles'
import {CircularProgress} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import {Link, Route} from 'react-router-dom'
import styled from 'styled-components'
import React from 'react'

import { ROUTE_DASHBOARD_ASSET_CONTROL_STOCK_LINKS, ROUTE_DASHBOARD_ASSET_CONTROL_STOCK } from '../../../../../../constants/route-constant'
import { getStorageProvider } from '../../../../../../providers/asset-provider';

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: black;
  display: block;
  padding: 10px;
  border-bottom: 1px solid ${grey['200']};
  &:hover {
    cursor: pointer;
    background-color: ${grey['200']};
  }
`

const SIDEBAR_WIDTH = 300

const styles = {}

class StockPage extends React.Component {
  constructor () {
    super ()

    this.state = {
      loading: false,
      storages: []
    }
  }

  componentWillMount () {
    this.setState({loading: true})

    getStorageProvider()
      .then(storages => {
        this.setState({
          loading: false,
          storages
        })
      })
      .catch(error => {
        this.setState({loading: false})
        console.error('error getStorageProvider', error)
      })
  }

  render () {
    const {classes} = this.props

    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: grey['100']
            backgroundColor: 'white'
          }}
        >
          <CircularProgress />
          <div
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            width: SIDEBAR_WIDTH,
          }}
        >
          {
            this.state.storages.map((item, indexItem) => {
              return (
                <LinkStyled
                  key={indexItem}
                  to={`${ROUTE_DASHBOARD_ASSET_CONTROL_STOCK}/${item.id}/item`}
                  // style={{
                  //   backgroundColor: itemPaths[4] === urlPaths[4] ? grey['100'] : null,
                  //   borderRight: itemPaths[4] === urlPaths[4]
                  //     ? '3px solid orange'
                  //     : null
                  // }}
                >
                  {item.name}
                </LinkStyled>
              )
            })
          }
        </div>
        <div
          style={{
            flex: '1 0',
            borderLeft: `1px solid ${grey['200']}`
          }}
        >
          {
            ROUTE_DASHBOARD_ASSET_CONTROL_STOCK_LINKS.map((item, indexItem) => {
              const ComponentRoute = item.component

              return (
                <Route
                  key={indexItem}
                  path={item.path}
                  render={props => <ComponentRoute storages={this.state.storages} {...props} />}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(StockPage)
