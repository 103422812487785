import {
  SET_METADATA
} from '../actions/types';

export default (state=null, action) => {
  const { type, config } = action;
  const { modules } = config || {};


  switch (type) {
    case SET_METADATA:
      return modules || state;
    default:
      return state;
  }
}
