import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './styles/EventsTableStyle';

const TooltipListContent = ({content, classes}) => (
    <React.Fragment>
        <List className={classes.lesserPadding}>
            {
                content.map(text => (
                    <ListItem
                        dense={true}
                        disableGutters={true}
                        alignItems={'flex-start'}
                        // className={classes.smallWhiteText}
                    >
                        <ListItemIcon>
                            <Icon
                                className={`${classes.smallWhiteText} ${classes.right}`}
                            >
                                keyboard_arrow_right
                            </Icon>
                        </ListItemIcon>
                        <ListItemText 
                            primary={text}
                            classes={{ primary: `${classes.smallWhiteText} ${classes.left}` }}
                        />
                    </ListItem>
                ))
            }
        </List>
    </React.Fragment>
);

export default withStyles(styles)(TooltipListContent);