import {  
    SET_METADATA
} from '../actions/types';

const DEFAULT_STATE = {
    id: null,
    name: 'client-name',
    plantTypes: ['wind', 'hydro', 'solar'],
    parks: {},
    subparks: {},
    devices: {},
    circuits: {},
    metmasts: {},
    SCADA: {},
    envelopes: {},
    metadataScore: null,
    Models: []
};

export default (state = DEFAULT_STATE, action) => {
    const { meta, type } = action;

    const { 
        id,
        name,
        parks,
        subparks,
        devices,
        circuits,
        metmasts,
        SCADA,
        envelope,
        score,
        ...extra
    } = meta || {};

    switch (type) {
        case SET_METADATA:
            return meta ? 
                {
                    id,
                    name,
                    parks,
                    subparks,
                    devices,
                    circuits,
                    metmasts,
                    SCADA,
                    plantTypes: ['wind', 'hydro', 'solar'],
                    metadataScore: score,
                    envelopes: envelope,
                    ...extra
                }
            :
                state;
        default:
            return state;
    }
}
