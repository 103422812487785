import * as R from 'ramda'

const getSelectedWTG = (currentPage, client) => {

    const { devices } = client || { devices: [] };
    const wtgId = (currentPage && currentPage.filter && currentPage.filter.devices[0])
        ||
        [];
    const wtg = R.find(R.propEq('id', wtgId))(devices);
    return wtg && wtg.name;
}

const getSelectedSubparks = currentPage =>
    currentPage && currentPage.filter && currentPage.filter.subparks && `${currentPage.filter.subparks.length} Subpark(s)*`;

const getSelectedTimeRange = currentPage => currentPage && currentPage.filter && currentPage.filter.data_range &&
    `${currentPage.filter.data_range.startDate.split(' ')[0]} to ${currentPage.filter.data_range.endDate.split(' ')[0]}`;


export default ({ currentPage, client }) => {
    const title = currentPage && currentPage.name;

    const firstSubtitle = currentPage && currentPage.pageType === 2 ?
        getSelectedWTG(currentPage, client)
        :
        getSelectedSubparks(currentPage);
    
    const secondSubtitle = getSelectedTimeRange(currentPage);


    return {
        title,
        firstSubtitle,
        secondSubtitle
    };
};