import React from 'react';
import dates from 'date-arithmetic';
import BigCalendar from 'react-big-calendar';
import MiniToolbar from './Toolbar2';
//import { changeEventBackgroundColor } from './Calendar';


class YearView extends React.Component {

  render() {
    let { date } = this.props;
    let currYear = date.getFullYear();

    return (
      <div style={{ height: '500px', width: '100%', marginTop: '50px' }}>
        {Array(12).fill(1).map((curr, index) => (
          <div style={{ margin: '10px', float: 'left', height: '300px', width: '23%' }}>
            <BigCalendar
              selectable
              drilldownView={null}
              localizer={this.props.localizer}
              culture='pt'
              components={{
                toolbar: MiniToolbar
              }}
              events={this.props.events}
              defaultView={BigCalendar.Views.MONTH}
              date={new Date(`${currYear}-${(index + 1)}-${10}`)}
              onSelectEvent={this.props.onSelectEvent}
              onSelectSlot={this.props.onSelectSlot}
              eventPropGetter={this.props.eventPropGetter}
            />
          </div>))}
      </div>
    );
  };
};


YearView.navigate = (date, action) => {
  switch (action) {
    case BigCalendar.Navigate.PREVIOUS:
      return dates.add(date, -1, 'year');

    case BigCalendar.Navigate.NEXT:
      return dates.add(date, 1, 'year');

    default:
      return date;
  };
};

YearView.title = date => {
  return `Ano: ${date.getFullYear()}`;
};


export default YearView;