import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import * as R from 'ramda'
import CircularProgress from '@material-ui/core/CircularProgress'

import InventoryAssetsSidebarComponent from './components/InventoryAssetsSidebarComponent/InventoryAssetsSidebarComponent';
import InventoryAssetsDetailComponent from './components/InventoryAssetsDetailComponent/InventoryAssetsDetailComponent';
import { getAssetProvider } from '../../../RegistryPage/providers/asset-provider';

const styles = theme => ({
  assetsContent: {
    borderLeft: `1px solid ${grey['200']}`,
  }
})

class AssetAssetPage extends Component {
  constructor (props) {
    super (props)

    this.state = {
      loading: false,
      asset: null,
      assets: []
    }
  }

  componentDidMount () {
    this.setState({ loading: true })

    this.getAll()
      .then(data => {
        const assets = data[0]
        const asset = R.head(assets)

        this.setState({
          loading: false,
          assets,
          asset
        })
      })
      .catch(error => {
        console.error('componentDidMount', error)

        this.setState({ loading: false })
      })
  }

  getAll = () => {
    return Promise.all([
      getAssetProvider()
    ])
  }

  selectAsset = asset => this.setState({ asset })

  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            width: 300,
            height: '100%'
          }}
        >
          {this.state.loading
            ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex' ,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress />
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 20
                  }}
                >
                  Loading models...
                </div>
              </div>
            )
            : (
              <InventoryAssetsSidebarComponent
                assets={this.state.assets}
                selectAsset={this.selectAsset}
              />
            )}
        </div>
        <div
          className={classes.assetsContent}
          style={{
            flex: 1,
          }}
        >
          <InventoryAssetsDetailComponent
            asset={this.state.asset}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(AssetAssetPage)
