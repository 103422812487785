import React, {
    useState,
    useEffect
} from 'react';

import {
    Selector,
    TextField,
    SelectorDayView
} from './components';

import { useTranslation } from 'react-i18next';
import * as R from 'ramda'

const MovementView = props => {

    const {
        feedDataOut,
        error,
        activeResource,
        storages,
        fromStorage = '--',
        formProps = {},
    } = props;

    const { t } = useTranslation();

    const stateOptions = [
        { id: 'new', name: t('New') },
        { id: 'recondicioned', name: t('Reconditioned') },
        { id: 'under-analysis', name: t('Under Analysis') }
    ];
    const state = activeResource && R.find(R.propEq('name', activeResource['Component Status']))(Object.values(stateOptions));

    const defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() + 2);

    const [inputs, setInputValue] = useState({
        id1: {
            fieldName: 'Storage',
            data: undefined,
            dataField: 'to_storage_id',
            required: true
        },
        id3: {
            fieldName: 'Application Date',
            data: new Date(),
            dataField: 'application_date',
            required: true
        },
        id4: {
            fieldName: 'Application Warranty End',
            data: new Date(),
            dataField: 'warranty_application_date',
            required: false
        },
        id5: {
            fieldName: 'Application Expiration',
            data: new Date(),
            dataField: 'application_expiration',
            required: true
        },
        id6: {
            fieldName: 'Date of Next Application',
            data: new Date(),
            dataField: 'date_of_next_action',
            required: false
        },
        id2: {
            fieldName: 'Component State',
            data: state ? state.id : undefined,
            dataField: 'state',
            required: true
        },
        id7: {
            fieldName: 'Work Order ID',
            data: undefined,
            dataField: 'work_order',
            required: false
        },
        id8: {
            fieldName: 'Executed Procedure',
            data: undefined,
            dataField: 'procedure',
            required: false
        },
        id9: {
            fieldName: 'Assoc. Documents',
            data: undefined,
            dataField: 'documents_application',
            required: false
        },
        id10: {
            fieldName: 'Placement',
            data: activeResource ? activeResource['Placement'] : undefined,
            dataField: 'placement',
            required: false
        }
    });

    const changeValueAdapter = id => value => {
        const minValue = inputs[id].minValue;

        const data = (id === 'id9' && value.split(',')) ||
            (minValue && value < minValue ? minValue : value)

        const payload = id === 'id3' ?
            {
                [id]: {
                    ...inputs[id],
                    data: minValue && value < minValue ? minValue : value
                },
                id4: {
                    ...inputs.id4,
                    data: inputs.id4.data.getTime() - value.getTime() < 0 ?
                        value
                        :
                        inputs.id4.data
                },
                id5: {
                    ...inputs.id5,
                    data: inputs.id5.data.getTime() - value.getTime() < 0 ?
                        value
                        :
                        inputs.id5.data
                },
                id6: {
                    ...inputs.id6,
                    data: inputs.id6.data.getTime() - value.getTime() < 0 ?
                        value
                        :
                        inputs.id6.data
                }
            }
            :
            {
                [id]: {
                    ...inputs[id],
                    data
                }
            };
        setInputValue({
            ...inputs,
            ...payload
        });
    }

    const changeSelectorAdapter = id => value => {
        const minValue = inputs[id].minValue;

        const payload = {
            [id]: {
                ...inputs[id],
                data: minValue && value < minValue ? minValue : value
            }
        };

        setInputValue({
            ...inputs,
            ...payload
        });
    }

    useEffect(() => feedDataOut(inputs), [inputs]);

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '70vh',
                maxHeight: '85vh',
                ...(formProps.style || {})
            }}
        >
            <p style={{ flex: '1 0 33%', padding: 1 }}>
                {t('From')}: {fromStorage}
            </p>
            <Selector
                style={{ flex: '1 0 33%', padding: 1 }}
                label={`${t('Movement')} *`}
                error={error}
                required={true}
                subLabel={t('Move To')}
                options={storages}
                value={inputs['id1'].data}
                changeValueFunction={changeSelectorAdapter('id1')}
            />
            <TextField
                style={{ flex: '1 0 33%', padding: 1 }}
                label={t('Placement')}
                placeholder={t('Placement in the stock')}
                value={inputs['id10'].data}
                changeValueFunction={changeValueAdapter('id10')}
            />
            <SelectorDayView
                style={{ flex: '1 0 20%', padding: 1 }}
                label={`${t('Application Date')} *`}
                value={inputs['id3'].data}
                changeValueFunction={changeValueAdapter('id3')}
            />
            <SelectorDayView
                style={{ flex: '1 0 20%', padding: 1 }}
                label={t('Application Warranty End')}
                value={inputs['id4'].data}
                changeValueFunction={changeValueAdapter('id4')}
                minDate={inputs['id3'].data}
            />
            <SelectorDayView
                style={{ flex: '1 0 20%', padding: 1 }}
                label={`${t('Application Expiration')} *`}
                value={inputs['id5'].data}
                changeValueFunction={changeValueAdapter('id5')}
                minDate={inputs['id3'].data}
            />
            <SelectorDayView
                style={{ flex: '1 0 20%', padding: 1 }}
                label={t('Date of Next Application')}
                value={inputs['id6'].data}
                changeValueFunction={changeValueAdapter('id6')}
                minDate={inputs['id3'].data}
            />
            <Selector
                style={{ flex: '1 0 20%', padding: 1 }}
                label={`${t('Component State')} *`}
                error={error}
                required={true}
                options={stateOptions}
                value={inputs['id2'].data}
                changeValueFunction={changeSelectorAdapter('id2')}
            />
            <TextField
                style={{ flex: '1 0 20%', padding: 1 }}
                label={t('Work Order ID')}
                placeholder={t('Work Order ID')}
                value={inputs['id7'].data}
                changeValueFunction={changeValueAdapter('id7')}
            />
            <TextField
                style={{ flex: '1 0 20%', padding: 1 }}
                label={t('Executed Procedure')}
                placeholder={t('Executed Procedure')}
                value={inputs['id8'].data}
                changeValueFunction={changeValueAdapter('id8')}
            />
            <TextField
                style={{ flex: '1 0 20%', padding: 1 }}
                label={t('Assoc Documents')}
                placeholder={t('Assoc Documents')}
                value={inputs['id9'].data}
                changeValueFunction={changeValueAdapter('id9')}
            />
        </div>
    )
}

export default MovementView;