import React, {Component} from 'react'
import { DateRangePicker } from 'react-date-range'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment-timezone'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

class FilterDateRangeComponent extends Component {
  state = {
    anchorEl: null,
    open: false,
    keyDate: 'rangeDate'
  }

  handleSelect = (data) => {
    const {startDate, endDate} = data[this.state.keyDate]

    this.props.change([
      moment(startDate),
      moment(endDate)
    ])
  }

  render () {
    const selectionRange = {
			startDate: this.props.data[0],
			endDate: this.props.data[1],
			key: this.state.keyDate,
    }

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: 300,
          minWidth: 300,
          border: `1px solid ${grey['200']}`
        }}
      >
        <div
          style={{
            height: 40,
            width: '100%',
            borderBottom: `1px solid ${grey['200']}`
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 20,
              height: '100%',
              width: '100%'
            }}
          >
            DATE RANGE
          </div>
        </div>
        <div
          style={{
            height: 'calc(100% - 40px)',
            width: '100%',
            paddingLeft: '1px'
          }}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            months={2}
            direction='horizontal'
            onChange={this.handleSelect.bind(this)}
            // maxDate={new Date(maxDate)}
          />
        </div>
      </div>
    )
  }
}

export default FilterDateRangeComponent
