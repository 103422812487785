import React from 'react'
import OnlyPrint from '../OnlyPrint'
import headerImg from './img/header.png'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import container from './Container'


export default props => {

    const { title, firstSubtitle, secondSubtitle } = container(props);
    const { header, leftHeader, rightHeaderBackground, headerTitle } = useStyles();

    return (
        <OnlyPrint>
            <header className={header}>
                {/*
                We use a image instead of background image property
                because the user can print without backgrounds images and color
                */}
                <Typography className={headerTitle} variant='h5' >{title}</Typography>
                <img className={rightHeaderBackground} src={headerImg} />
                <section className={leftHeader}>
                    <Typography className={headerTitle}>{firstSubtitle}</Typography>
                    <Typography className={headerTitle}>{secondSubtitle}</Typography>
                </section>
            </header>
        </OnlyPrint>
    )
}

const useStyles = makeStyles({
    header: {
        height: 100,
        width: '100%',
        position: 'fixed',
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    leftHeader: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        color: 'white'
    },
    rightHeaderBackground: {
        height: '100%',
        width: '50%',
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: -1,
    },
    headerTitle: {
        fontFamily: 'inherit',
        margin: '0px 20px',
        width: '45%'
    }
})