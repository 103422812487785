import * as R from "ramda"


export default ({ client, currentPage }) => {

    const subparksIds = currentPage && currentPage.filter && (currentPage.filter.subparks || [])

    const subparks = client && (client.subparks || [])

    const content = subparksIds.map(id => (R.find(R.propEq('id', id))(subparks)).name).join(', ')

    const subtitleLines = [{
        title: 'Subparks',
        content
    }]

    return subtitleLines
}