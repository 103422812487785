import React from 'react';
import useContainer from './Container';
import WidgetDriver from '../../../../../../components/Driver/WidgetDriver';


export default props => {
    const widgetProps = useContainer(props);

    return <div style={{ height: 640, padding: 20 }}>
        <WidgetDriver {...widgetProps} />
    </div>;
};