import React from 'react'
import {withStyles} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'

import StudioBodyChartComponent from './components/StudioBodyChartComponent/StudioBodyChartComponent'

const styles = {}

class StudioBodyComponent extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            flex: 1,
            height: '100%',
            borderLeft: `1px solid ${grey['200']}`,
            backgroundColor: 'white'
          }}
        >
          <StudioBodyChartComponent
            {...this.props}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(StudioBodyComponent)
