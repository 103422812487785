import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { injectGlobal } from 'styled-components';
import './i18n';

import axios from 'axios';

// import Highcharts from 'highcharts';
// import HC_exporting from 'highcharts/modules/exporting';

import 'react-dates/lib/css/_datepicker.css'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import './rc-trigger-index.css'

window.axios = axios;

injectGlobal`
* {
  font-family: 'Montserrat', sans-serif;
}

html, body, #root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(243, 243, 250)
}

[role="dialog"], #user-menu-options, .MuiDialog-root {
  z-index: 9999 !important
}

[role="presentation"][id^=menu-], [role="presentation"][id*=menu-] {
  z-index: 99999999999999999 !important
}

.highcharts-tooltip-container {
  z-index: 9999999999999999999
}


/* PRINT CONFIGURATION */

.table-print {
  border-spacing: 0;
  width: 100%;
}

#page-header, #page-footer {
  display: none;
}

@media print {
  html, body, #root {
    background-color: #fff!important;
  }

  * {
    overflow: visible!important;
  }
  
  .scrollPage div.fit-page {
    height: 145mm !important;
    transform: translateY(40mm) !important;
  }

  .scrollPage .pie-chart .is-inside {
    color: white !important;
  }

  .scrollPage .pie-chart div {
    position: relative !important;
  }

  .scrollPage .pie-chart div.highcharts-data-labels, .scrollPage .pie-chart div.highcharts-data-label {
    position: absolute !important;
  }

  .scrollPage div:not(.print-exception) {
    height: auto !important;
    display: block;
    position: static !important;
  }

  button {
    display: none!important;
  }

  .highcharts-container {
    max-width: 29cm!important;
  }

  .react-grid-layout {
    display: block!important;
    position: static!important;
  }

  .react-grid-item {
    display: block!important;
    position: static!important;
    transform: none!important;
    
    width: 100%!important;
    max-width: 29cm!important;
  }

  .highcharts-tooltip {
    display: none!important;
  }

  .page-header-space {
    height: 100px;
  }
  
  .page-footer-space {
    height: 20vh;
  }

  [class^=GraphicBar-flipContainerBack], [class*=GraphicBar-flipContainerBack] {
    display: none;
  }

  #page-header {display: table-header-group;} 
  #page-footer {display: table-footer-group;}
}

@page {
  size: landscape;
  margin: 0mm;
}

.toast-no-margin {
  margin: 0;
}

/* PRINT CONFIGURATION */
`
// HC_exporting(Highcharts);

// console.log('ON INDEX JS',HC_exporting)

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
