import { REDUCER_UPDATE_ENVELOPE_BY_DEVICE } from "../../../../constants/reducers/global-filter";

const DEFAULT_STATE = {};

export default (state = DEFAULT_STATE, action) => {
  const { type } = action;

  switch (type) {
    case REDUCER_UPDATE_ENVELOPE_BY_DEVICE:
      return action.payload;
    default:
      return state;
  }
};
