import React, { Fragment } from 'react'
import grey from '@material-ui/core/colors/grey'
import * as R from 'ramda'

import { STYLE_HEADER_TOOLBAR, STYLE_SUBHEADER_TOOLBAR } from '../../constants/style-constant'
import HeaderAllLogoComponent from './components/HeaderAllLogoComponent/HeaderAllLogoComponent';
import HeaderAllModulesComponent from './components/HeaderAllModulesComponent/HeaderAllModulesComponent';
import HeaderAllAvatarComponent from './components/HeaderAllAvatarComponent/HeaderAllAvatarComponent';
import HeaderPlaceholder from '../Placeholder/HeaderPlaceholder';
import SubheaderAllComponent from './components/SubheaderAllComponent/SubheaderAllComponent';
import { ROUTER_DARK_HEADERS } from '../../constants/route-constant'
import { NotificationsButtonView } from './components/Notifications';

import CircularLoading from '../Loading/CircularLoading';

import NoPrint from '../Print/NoPrint'

let lastScroll = 0;


const styles = theme => ({
  headerNavbar: {
    opacity: 1
  },
});

class HeaderAllComponent extends React.Component {

  scrollHeader = async scroll => {
    const element = document.getElementById("navBar")

    if (lastScroll > scroll && scroll < 60) {
      element.setAttribute('style', 'transform: translateY(0); transition: all 500ms cubic-bezier(.24,.74,.74,.7)')
    } else {
      element.setAttribute('style', 'transform: translateY(-60px); transition: all 500ms cubic-bezier(.24,.74,.74,.7)')
    }

    lastScroll = scroll
  }

  backgroundColor = (theme) => {
    const element = document.getElementById('root')
    theme ? element.style.backgroundColor = '#333' : element.style.backgroundColor = 'rgb(243, 243, 250)'
  }

  render() {

    if (this.props.modules <= 0) {
      return <HeaderPlaceholder />
    }

    const isDark = R.contains(this.props.location.pathname, ROUTER_DARK_HEADERS)
    this.backgroundColor(isDark)
    //console.log('this.props.modules', this.props.modules)

    return (
      <NoPrint>
        <div
          id="navBar"
        >
          <div>
            <div
              style={{
                // height: `${STYLE_HEADER_TOOLBAR}px`,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                borderBottom: isDark ? '1px solid #606060' : `1px solid ${grey['200']}`,
                backgroundColor: isDark ? '#2D2D2D' : '#FFFFFF'
              }}
            >
              <div>
                <HeaderAllLogoComponent
                  isDark={isDark}
                />
              </div>
              <div
                style={{
                  flex: 1
                }}
              >
                <HeaderAllModulesComponent
                  isDark={isDark}
                  {...this.props}
                />
              </div>
              {/* <div 
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    zIndex: '999999!important',
                    margin: '12px'
                }}
            >
                <NotificationsButtonView />
            </div> */}
              <div>
                <HeaderAllAvatarComponent
                  isDark={isDark}
                  {...this.props}
                />
              </div>
            </div>
          </div>
          <div
            className="headerNavigation"
            style={{
              paddingLeft: 25,
              paddingRight: 15,
              backgroundColor: isDark ? '#2D2D2D' : '#FFFFFF',
              borderBottom: isDark ? 'none' : '1px solid rgb(243,243,250)'
            }}
          >
            <SubheaderAllComponent
              toggleEdtitMode={this.props.toggleEdtitMode}
              isDark={isDark}
              {...this.props}
            />
          </div>
        </div>
      </NoPrint>
    )
  }
}

export default HeaderAllComponent