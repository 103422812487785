import React, {Component, Switch} from 'react'
import {Route, Redirect} from 'react-router-dom'

import {
  Tabs,
  Tab,
  Typography,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  CircularProgress,
  Chip,
  Avatar,
  TextField,
  Paper,
  AppBar,
  Toolbar,
  Grid
} from '@material-ui/core'

import { reverse_loading, loading } from '../Header/HeaderStyle'

class PageWidgetPlaceholder extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const {classes} = this.props
    const width_base = window.innerWidth
    const height_base = window.innerHeight

    return (
        <div
            style={{
                'width': '100%',
                'height': '100%',
                'animation': `${loading} 3s ease`,
                'animation-iteration-count': 'infinite',
                'background-color': '#f3f3f3'
            }}
        >
          <div 
            style={{
              'padding-top': '3%',
              'padding-right': '1%',
              'padding-left': '1%'
            }}
          >
            <div>
              <Chip
                label=''
                color={'#bdbdbd'}
                style={{
                  'animation': `${reverse_loading} 3s ease`,
                  'animation-iteration-count': 'infinite',
                  'width': '18%',
                  'margin-right': '55%',
                }}
              />
              <Chip
                label=''
                color={'#bdbdbd'}
                style={{
                  'animation': `${reverse_loading} 3s ease`,
                  'animation-iteration-count': 'infinite',
                  'width': '12%',
                  'margin-right': '1%',
                }}
              />
              <Chip
                label=''
                color={'#bdbdbd'}
                style={{
                  'animation': `${reverse_loading} 3s ease`,
                  'animation-iteration-count': 'infinite',
                  'width': '12%',
                  'margin-right': '1%',
                }}
              />
            </div>
            <div
              style={{
                'padding-top': '5%',
                'padding-right': '8%',
                'padding-left': '8%'
              }}
            >
              <Grid container spacing={16}>
                <Grid item xs={4}>
                  <Paper elevation={0}
                    style={{
                      'background-color': '#bdbdbd',
                      'width': '100%',
                      'height': `${height_base * 0.6}px`,
                      'animation': `${reverse_loading} 3s ease`,
                      'animation-iteration-count': 'infinite'
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={0}
                    style={{
                      'background-color': '#bdbdbd',
                      'width': '100%',
                      'height': `${height_base * 0.2}px`,
                      'animation': `${reverse_loading} 3s ease`,
                      'animation-iteration-count': 'infinite'
                    }}
                  />

                  <Paper elevation={0}
                    style={{
                      'background-color': '#bdbdbd',
                      'width': '100%',
                      'height': `${height_base * 0.2}px`,
                      'animation': `${reverse_loading} 3s ease`,
                      'animation-iteration-count': 'infinite',
                      'margin-top': '2%'
                    }}
                  />

                  <Grid container spacing={16} style={{'margin-top': '1%'}}>
                    <Grid item xs={6}>
                      <Paper elevation={0}
                        style={{
                          'background-color': '#bdbdbd',
                          'width': '100%',
                          'height': `${height_base * 0.16}px`,
                          'animation': `${reverse_loading} 3s ease`,
                          'animation-iteration-count': 'infinite'
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Paper elevation={0}
                        style={{
                          'background-color': '#bdbdbd',
                          'width': '100%',
                          'height': `${height_base * 0.16}px`,
                          'animation': `${reverse_loading} 3s ease`,
                          'animation-iteration-count': 'infinite'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Paper elevation={0}
                      style={{
                        'background-color': '#bdbdbd',
                        'width': '100%',
                        'height': `${height_base * 0.2}px`,
                        'animation': `${reverse_loading} 3s ease`,
                        'animation-iteration-count': 'infinite'
                      }}
                    />

                    <Paper elevation={0}
                      style={{
                        'background-color': '#bdbdbd',
                        'width': '100%',
                        'height': `${height_base * 0.2}px`,
                        'animation': `${reverse_loading} 3s ease`,
                        'animation-iteration-count': 'infinite'
                      }}
                    />

                    <Paper elevation={0}
                      style={{
                        'background-color': '#bdbdbd',
                        'width': '100%',
                        'height': `${height_base * 0.2}px`,
                        'animation': `${reverse_loading} 3s ease`,
                        'animation-iteration-count': 'infinite'
                      }}
                    />
                  </Grid>
              </Grid>
            </div>
          </div>
        </div>
    )
  }
}

export default PageWidgetPlaceholder
