import React from 'react'

import black_logo from '../../../../assets/headerLogoDelfos.svg'
import white_logo from '../../../../assets/logoDelfos-white.svg'

class HeaderAllLogoComponent extends React.Component {
  render () {
    const logo = this.props.isDark ? white_logo : black_logo;

    return (
      <div
        style={{
          height: '100%',
          width: '150px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            height: '100%',
            minWidth: '100px',
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'left'
          }}
        />
      </div>
    )
  }
}

export default HeaderAllLogoComponent