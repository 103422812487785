import React, {
    useState,
    useEffect,
    useCallback
} from 'react';

import {
    useSelector,
    useDispatch
} from 'react-redux';

import moment from 'moment';

const GlobalDateRangeContainer = props => {
    const {
        children,
        defaultStartTime,
        defaultEndTime,
        tz,
        executeFilter,
        dispatchFilter,
        onExecuteFilter
    } = props;


    const filters = useSelector(state => state.Dashboard && {
        devices: state.Dashboard.DeviceFilter && state.Dashboard.DeviceFilter.devices,
        subparks: state.Dashboard.DeviceFilter && state.Dashboard.DeviceFilter.subparks,
        startTime: state.Dashboard.DateFilter && state.Dashboard.DateFilter.data_range && state.Dashboard.DateFilter.data_range.startDate,
        endTime: state.Dashboard.DateFilter && state.Dashboard.DateFilter.data_range && state.Dashboard.DateFilter.data_range.endDate
    });

    const dispatch = useDispatch();

    const [mounted, setMounted] = useState(false);
    const [start, setStart] = useState(filters && filters.startTime && moment.tz(filters.startTime, 'America/Fortaleza') || defaultStartTime);
    const [end, setEnd] = useState(filters && filters.endTime && moment.tz(filters.endTime, 'America/Fortaleza') || defaultEndTime);

    const onChange = ({ date }) => {
        const startTime = moment.tz(date.startDate, tz);
        const endTime = moment.tz(date.endDate, tz);

        setStart(startTime);
        setEnd(endTime)
    }

    const closeMenu = useCallback(
        () => {
            dispatch({ type: 'REDUCER_UPDATE_DATE', payload: { data_range: { startDate: start.format('YYYY-MM-DD'), endDate: end.format('YYYY-MM-DD') } } });
            onExecuteFilter(start, end);
        },
        [
            start,
            end
        ]
    );

    useEffect(
        () => {
            if (!mounted) {
                setMounted(true);
                onExecuteFilter(start, end);
            }
        },
        [
            start,
            end,
            mounted
        ]
    );

    return React.cloneElement(
        children,
        {
            startDate: start,
            endDate: end,
            dateRange: {
                startDate: start,
                endDate: end,
                key: 'date'
            },
            onChange,
            closeMenu,
            maxDate: moment().subtract(1, "days").toDate()
        }
    );
};

export default GlobalDateRangeContainer;