import React from "react";
import GridLayout from "react-grid-layout";
import { withStyles, Icon, LinearProgress } from "@material-ui/core";
import * as R from "ramda";
import grey from "@material-ui/core/colors/grey";
import _ from "lodash";
import { averageGeolocationHelper } from "../../../../../../../../helpers/average-geolocation-helper";
import moment from 'moment-timezone'

// import moment from 'moment-timezone'
import OPCMonitorItem from "../OPCMonitorItem/OPCMonitorItem";
import WindRoseComponent from "../../../../../../../../components/WindRoseComponent/WindRoseComponent";
import OPCMapComponent from "../../../OPCMapPage/components/OPCMapComponent/OPCMapComponent";
// import OPCMonitorItemGE from '../OPCMonitorItemGE/OPCMonitorItemGE';
import { CircularProgress } from "@material-ui/core";
import { getStatusByTag } from '../../../../../../../../helpers/get-status-color-realtime'

moment.locale('en');

const GRID_WIDTH_PADDING = 10;
const WIDGET_HEADER_HEIGHT = 40
// const WIDGET_HEADER_HEIGHT = 40

const WIDGET_HEIGHT = 7;
const WIDGET_WIDTH = 2;

const HEADER_LABEL = 54;

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%"
  },
  tableTh: {
    color: grey["500"],
    textAlign: "center",
    padding: "8px"
  },
  tableTd: {
    textAlign: "center",
    padding: "8px",
    color: grey["400"]
  }
};

class OPCMonitorList extends React.Component {
  constructor(props) {
    super(props);

    const elBody = window.document.getElementsByTagName("BODY")[0];

    this.state = {
      gridWidth: elBody.offsetWidth - GRID_WIDTH_PADDING * 2,
      center: {
        latitude: 0,
        longitude: 0
      }
    };
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName("body")[0];

    this.setState({
      gridWidth: body.offsetWidth - GRID_WIDTH_PADDING * 2
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizePageWatch);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizePageWatch);
  }

  calcPosition = index => {
    if (index === 0) {
      return {
        x: 0,
        y: 0,
        w: WIDGET_WIDTH,
        h: WIDGET_HEIGHT
      };
    }

    if (index % 2 === 0) {
      return {
        x: 0,
        y: WIDGET_HEIGHT * index,
        w: WIDGET_WIDTH,
        h: WIDGET_HEIGHT
      };
    } else {
      return {
        x: WIDGET_WIDTH,
        y: 0,
        w: WIDGET_WIDTH,
        h: WIDGET_HEIGHT
      };
    }
  };
  render() {

    let WtgStoppedAll = 0;
    let WtgAll = 0;


    this.props.items.map((park, indexWtg) => {

      park.wtgs.map((wtg, indexSubpark) => {

        WtgAll++;
        const stateTag = R.find(R.propEq('var_id', 1000))(wtg.realtime) || R.find(R.propEq('var_id', 3))(wtg.realtime)
        const state = stateTag ? stateTag.value_string : ''

        const status = getStatusByTag(state)

        if (status.color && (status.color == 'red' || status.color == 'blue' || status.color == 'purple' || status.color == 'grey')) {
          WtgStoppedAll++;
        }
      });
    });

    if (this.props.items.length > 0) {
      if (
        this.state.center.latitude === 0 ||
        this.state.center.longitude === 0
      ) {
        this.setState(
          {
            center: averageGeolocationHelper(
              R.flatten(this.props.items.map(subpark => subpark.wtgs))
            )
          },
          console.log("this.state.center", this.state.center)
        );
      }
    }

    const { classes } = this.props;

    const timeAll = R.flatten(
      this.props.items.map(subpark => subpark.wtgs.map(wtg => wtg.realtime.filter(tag => tag.var_id === 1 || tag.var_id === 2 || tag.var_id === 3 || tag.var_id === 4 || tag.var_id === 1000)))
    )

    const timeAllSort = timeAll.sort((a, b) => {
      return moment.utc(a.time).diff(moment.utc(b.time))
    })

    const tags = R.flatten(
      this.props.items.map(subpark => subpark.wtgs.map(wtg => wtg.realtime))
    );

    const powerAll = R.sum(
      tags.filter(tag => tag.var_id === 1).map(tag => tag.value)
    );
    const ratedPowerAll = R.sum(
      R.flatten(this.props.items.map(({ wtgs }) => wtgs.map(wtg => wtg.model.specs.rated_power)))
    );
    const windMedian = (R.sum(
      tags.filter(tag => tag.var_id === 2).map(tag => tag.value)
    ) / tags.filter(tag => tag.var_id === 2).length);

    const itemsGrid = R.concat(_.sortBy(this.props.items, ["name"]), [
      101,
      102
    ]).map((item, indexItem) => {

      const itemWidth = 2;
      const itemHeight = 4;
      const subparkColumns = 2;

      const getItemXPosition = (index, columns, width) => (index % columns) * width
      const getItemYPosition = (index, columns, height) => Math.floor((index / columns)) * height;

      if (item === 101) {
        return (
          <div
            key={indexItem}
            data-grid={{
              x: getItemXPosition(indexItem, subparkColumns, itemWidth),
              y: getItemYPosition(indexItem, subparkColumns, itemHeight),
              w: itemWidth,
              h: itemHeight
            }}
          >
            <div
              style={{
                color: "white",
                border: `1px solid ${grey["900"]}`,
                width: "100%",
                height: "100%"
              }}
            >
              <div
                className="move-grid-widget"
                style={{
                  height: WIDGET_HEADER_HEIGHT,
                  width: "100%",
                  borderBottom: `1px solid ${grey["900"]}`,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: grey["500"]
                }}
              >
                <div
                  style={{
                    marginLeft: 10
                  }}
                >
                  Wind Rose
                </div>
              </div>
              <div
                style={{
                  height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
                  width: "100%",
                  overflow: "auto"
                }}
              >
                <WindRoseComponent items={this.props.items} />
              </div>
            </div>
          </div>
        );
      } else if (item === 102) {
        return (
          <div
            key={indexItem}
            data-grid={{
              x: itemWidth * subparkColumns,
              y: 0,
              w: itemWidth,
              h: itemHeight * 2
            }}
          >
            <div
              style={{
                color: "white",
                border: `1px solid ${grey["900"]}`,
                width: "100%",
                height: "100%"
              }}
            >
              <div
                className="move-grid-widget"
                style={{
                  height: WIDGET_HEADER_HEIGHT,
                  width: "100%",
                  borderBottom: `1px solid ${grey["900"]}`,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: grey["500"]
                }}
              >
                <div
                  style={{
                    marginLeft: 10
                  }}
                >
                  Map
                </div>
              </div>
              <div
                style={{
                  height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
                  width: "100%",
                  overflow: "auto"
                }}
              >
                <OPCMapComponent
                  items={this.props.items}
                  center={this.state.center}
                  defaultZoom={14}
                />
              </div>
            </div>
          </div>
        );
      }

      return (
        <div
          key={indexItem}
          data-grid={{
            x: getItemXPosition(typeof item.ordering === 'number' ? item.ordering - 1 : indexItem, subparkColumns, itemWidth),
            y: getItemYPosition(typeof item.ordering === 'number' ? item.ordering - 1 : indexItem, subparkColumns, itemHeight),
            w: itemWidth,
            h: itemHeight
          }}
        >
          <OPCMonitorItem item={item} />
        </div>
      );
    });

    const powerBarValue = ((100 / ratedPowerAll) * powerAll);
    const windBarValue = ((100 / 20) * windMedian);

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          color: "white",
          overflow: "auto"
        }}
      >
        {this.props.items.length > 0 ?

          <div>
            <div
              style={{
                minHeight: HEADER_LABEL,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexFlow: 'row wrap'
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  Turbines:
                    <span
                    style={{
                      marginLeft: 5,
                      fontWeight: 900
                    }}
                  >
                    {
                      R.flatten(this.props.items.map(subpark => subpark.wtgs))
                        .length
                    }
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  WTG Availability:
                    <span
                    style={{
                      marginLeft: 5,
                      fontWeight: 900
                    }}
                  >
                    <span>{WtgAll - WtgStoppedAll}</span> / <span>{WtgAll}</span>
                  </span>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: 20
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    Power (MW):
                      <span
                      style={{
                        marginLeft: 5,
                        fontWeight: 900
                      }}
                    >
                      {(powerAll / 1000).toFixed(1)}
                    </span>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      marginTop: 3
                    }}
                  >
                    <LinearProgress
                      style={{
                        height: 4
                      }}
                      variant="determinate"
                      value={powerBarValue > 100 ? 100 : powerBarValue}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: 20
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center"
                    }}
                  >
                    Wind Speed (m/s):
                      <span
                      style={{
                        marginLeft: 5,
                        fontWeight: 900
                      }}
                    >
                      {windMedian.toFixed(1)}
                    </span>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      marginTop: 3
                    }}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={windBarValue > 100 ? 100 : windBarValue}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  Last Update:
                    <span
                    style={{
                      marginLeft: 5,
                      fontWeight: 900
                    }}
                  >
                    {
                      R.last(timeAllSort) && R.last(timeAllSort).time
                        ?
                        moment.duration(moment().diff(moment(R.last(timeAllSort).time))).humanize()
                        :
                        null
                    }
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  <Icon style={{ fontSize: 16, color: "green" }}>
                    fiber_manual_record
                        </Icon>
                  <span
                    style={{
                      marginLeft: 5
                    }}
                  >
                    Running
                        </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  <Icon style={{ fontSize: 16, color: "purple" }}>
                    fiber_manual_record
                            </Icon>
                  <span
                    style={{
                      marginLeft: 5
                    }}
                  >
                    Maintenance (Corrective)
                            </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  <Icon style={{ fontSize: 16, color: "blue" }}>
                    fiber_manual_record
                            </Icon>
                  <span
                    style={{
                      marginLeft: 5
                    }}
                  >
                    Maintenance (Preventive)
                            </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  <Icon style={{ fontSize: 16, color: "red" }}>
                    fiber_manual_record
                            </Icon>
                  <span
                    style={{
                      marginLeft: 5
                    }}
                  >
                    Stopped
                            </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  <Icon style={{ fontSize: 16, color: "orange" }}>
                    fiber_manual_record
                            </Icon>
                  <span
                    style={{
                      marginLeft: 5
                    }}
                  >
                    Power limitation
                            </span>
                </div>


                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  <Icon style={{ fontSize: 16, color: "yellow" }}>
                    fiber_manual_record
                            </Icon>
                  <span
                    style={{
                      marginLeft: 5
                    }}
                  >
                    Warning
                            </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  <Icon style={{ fontSize: 16, color: "#878500" }}>
                    fiber_manual_record
                            </Icon>
                  <span
                    style={{
                      marginLeft: 5
                    }}
                  >
                    Low wind
                            </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: 20
                  }}
                >
                  <Icon style={{ fontSize: 16, color: "grey" }}>
                    fiber_manual_record
                            </Icon>
                  <span
                    style={{
                      marginLeft: 5
                    }}
                  >
                    No Communication
                            </span>
                </div>

              </div>
            </div>
            <div
              style={{
                height: `calc(100% - ${HEADER_LABEL}px)`,
                width: "100%"
              }}
            >
              <GridLayout
                className={classes.gridElement}
                width={this.state.gridWidth}
                cols={6}
                rowHeight={100}
                compactType={"horizontal"}
                onLayoutChange={data => console.log("onLayoutChange", data)}
                draggableHandle={'.move-grid-widget'}
              >
                {this.props.items.length > 0 ? itemsGrid : null}
              </GridLayout>
            </div>

          </div>

          :

          <div style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'black',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <CircularProgress size={(30)} />
            <div style={{
              marginTop: '20px',
              color: 'white'
            }}>
              Loading...
                </div>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(OPCMonitorList);