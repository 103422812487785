import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'
import {
  withStyles
} from '@material-ui/core'
import GridLayout from 'react-grid-layout'
import * as R from 'ramda'

import { AppStyleContent } from './ModuleStyle'


import WidgetDriver from '../../../../components/Driver/WidgetDriver'
import BaseGlobalFilter from '../../../../components/Header/BaseGlobalFilter'
import Selector from '../../../../components/Filter/Selector'

import PageWidgetPlaceholder from '../../../../components/Placeholder/PageWidgetPlaceholder'

import { REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA } from "../../../../constants/reducers/widget-page-action-events";
import { applyFilter } from '../../../../helpers/filter-helper';

import { PrintHeader, PrintHeaderSubtitle } from '../../../../components/Print'

import CustomAnalysis from '../../../../new-components/Analysis';

import { updateDateFilter } from '../../../../actions/DateFilterActions';

const PAGE_FIELD = 'management';
const GRID_WIDTH_PADDING = 20

const styles = theme => ({
  gridElement: {
    width: '100%'
  }
})

class AnalysisPage extends Component {
  constructor(props) {
    super(props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    // const { delfos_1_0 } = this.props;
    // const { [PAGE_FIELD]: Module } = delfos_1_0 || {};
    // const { DelfosPreviousManagement } = this.props;
    // const pageSelected = R.find(R.propEq('selected', true))(DelfosPreviousManagement);

    this.state = {
      gridWidth: elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),
      widgets: [],
      currWidget: null,
      loading: false,
      //   currPage: pageSelected,
      updatedFilters: false,
      renderComposed: false,
      customAnalysis: null
    }

    this.gridContainerAnchor = React.createRef()
    this.currentDateRange = null
    this.prevManagementId = null
    this.filterTable = this.filterTable.bind(this);
  }

  //   resizePageWatch = () => {
  //     const body = window.document.getElementsByTagName('body')[0]

  //     this.setState({
  //       gridWidth: body.offsetWidth - (GRID_WIDTH_PADDING * 2)
  //     })
  //   }

  filterTable(filter) {
    this.setState({tableFilter: filter});
  }

  componentDidMount() {
    const { currModule, pageLayout, widgets, devices, subparks, Modules } = this.props;

    if (pageLayout) {
      const urlParams = new URLSearchParams(window.location.search);
      const analysisId = urlParams.get('analysis-id');
      const filterWTG = urlParams.get('wtg-id');
      const filterSubpark = urlParams.get('subpark-id');
      const filterDay = urlParams.get('day');

      const analysisNumber = analysisId != null && Number(analysisId);

      let currModuleId = null;
      if (window.location.pathname === '/dashboard/analysis2/performance') {
        currModuleId = 13;
      }

      const matchPage = Object.assign({}, R.find(R.propEq('pageId', currModuleId || currModule.id))(pageLayout));

      const page = Object.keys(matchPage).length > 0 ?
        matchPage
        : window.location.pathname === '/dashboard/analysis2/management' ?
          pageLayout[0]
          : window.location.pathname === '/dashboard/analysis2/operational' ?
            pageLayout[1]
            : window.location.pathname === '/dashboard/analysis2/reliability' ?
              pageLayout[2]
              :
              pageLayout[3];

      const currWidget = R.find(R.propEq('id', analysisNumber !== false ? analysisNumber : page.selectedGraphicId))(widgets);
      const pageWidgets = widgets && widgets.filter(widget => page.widgets && page.widgets.indexOf(widget.id) >= 0)

      page.name = currWidget ? currWidget.name : '';
      page.widgets = pageWidgets;

      if (filterWTG && filterSubpark) {
        page.filter = { ...page.filter, devices: [Number(filterWTG)], subparks: [Number(filterSubpark)] };
      }

      if (filterDay) {
        const start = `${filterDay} 00:00:00`;
        const end = `${filterDay} 23:59:59`;

        const startDate = moment(start);
        const endDate = moment(end);

        if (startDate._isValid && endDate._isValid) {

          page.filter = {
            ...page.filter, data_range: {
              startDate: start,
              endDate: end,
            }
          };

          this.props.updateDateFilter(null, startDate, endDate);
        }
      }

      let unique = false;

      if (page.type === 'FILTER_TYPE_WTG_ENVELOPE' || page.type === 'FILTER_TYPE_WTG') {
        unique = true;
      }

      this.props.updateEnvelopeFilter(unique ? [page.filter.devices[0]] : (devices || page.filter && page.filter.devices), (subparks || page.filter && page.filter.subparks));

      if (currWidget.isComposed) {
        const customAnalysis = {
          ...currWidget,
          widgets: widgets.filter(widget => currWidget.widgets.indexOf(widget.id) >= 0)
        }

        this.setState({
          renderComposed: true,
          customAnalysis,
          currPage: page,
          widgets: []
        });

        if (!page.filter) {
          page.filter = {};
        }

        this.executeFilter(page, currWidget);
      } else {
        this.setState({
          renderComposed: false,
          customAnalysis: null
        });

        this.executeFilter(page, currWidget);
      }

    }

    window.addEventListener('resize', this.resizePageWatch);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizePageWatch)
  }

  async componentDidUpdate(prevProps, prevState) {
    const promisesToResolve = this.state.widgets
      .filter(widget => Promise.resolve(widget.data) === widget.data)

    if (promisesToResolve.length > 0) {
      const widgetData = this.state.widgets.map(widget => widget.data);
      const promisesRes = await Promise.all(widgetData);



      const widgets = this.state.widgets.map((widget, index) => ({
        ...widget,
        data: promisesRes[index],
        loading: false
      }));



      this.setState({
        widgets
      });
    }

    if (this.state.updatedFilters) {
      setTimeout(() => this.setState({
        updatedFilters: false
      }), 200);
    }
  }

  changeLoading = loading => {
    if (this.state.loading != loading) {
      this.setState({
        loading,
        tableFilter: null
      });
    }
  };



  executeFilter = (payload, currWidget = this.state.currWidget) => {

    const widgets_list = this.state.widgets_list ? this.state.widgets_list : payload.widgets;
    payload.widgets = [currWidget];
    const widgets = applyFilter(payload);
    payload.widgets = widgets_list;

    this.props.updateDateFilter(null, moment(payload.filter.data_range.startDate), moment(payload.filter.data_range.endDate));
    this.setState({
      widgets: widgets,
      currPage: payload,
      currWidget: currWidget,
      widgets_list: widgets_list,
      updatedFilters: true
    });
  };

  selectPage = pageId => {
    const { currModule, pageLayout, widgets } = this.props;

    let currModuleId = null;
    if (window.location.pathname === '/dashboard/analysis2/performance') {
      currModuleId = 13;
    }

    const page = Object.assign({}, R.find(R.propEq('pageId', currModuleId || currModule.id))(pageLayout));
    const currWidget = R.find(R.propEq('id', pageId))(widgets);
    const pageWidgets = widgets.filter(widget => page.widgets && page.widgets.includes(widget.id))

    page.name = currWidget.name;
    page.widgets = pageWidgets;
    page.selectedGraphicId = currWidget.id;

    if (currWidget.isComposed) {
      const customAnalysis = {
        ...currWidget,
        widgets: widgets.filter(widget => currWidget.widgets.indexOf(widget.id) >= 0)
      }

      this.setState({
        renderComposed: true,
        customAnalysis,
        currPage: page,
        widgets: []
      });

      this.executeFilter(page, currWidget);
    } else {
      this.setState({
        renderComposed: false,
        customAnalysis: null
      });

      this.executeFilter(page, currWidget);
    }
  };

  render() {
    const { classes, parks, history, currModule, Client } = this.props;
    const { widgets, currPage, widgets_list, renderComposed, customAnalysis, loading, updatedFilters } = this.state;

    if (!renderComposed && (!currPage || !widgets || widgets.length == 0)) {
      return (
        <AppStyleContent>
          <h1>Information not available...</h1>
        </AppStyleContent>
      )
    }

    return (
      <AppStyleContent>

        <PrintHeader currentPage={currPage} client={Client} />
        {
          currPage.pageType === 2 ?
            ''
            :
            <PrintHeaderSubtitle currentPage={currPage} client={Client} />
        }

        <BaseGlobalFilter
          mode={'view'}
          disabled={this.state.loading}
          history={'history'}
          unique={true}
          page={currPage}
          widgets_list={widgets_list}
          reducer_name={'REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA'}
          module_field={'DelfosPreviousManagement'}
          executeFilter={this.executeFilter}
          goToPage={this.selectPage}
          submodule={true}
          {...this.props}
        >
          <Selector updateFilters={this.executeFilter} page={currPage} />
        </BaseGlobalFilter>

        {
          !renderComposed ?
            <div
              style={{
                padding: '0 0 0 15px', height: '1300px'
              }}
            >
              {this.state.gridWidth ? (
                <GridLayout
                  className={classes.gridElement}
                  cols={12}
                  rowHeight={200}
                  items={50}
                  width={this.state.gridWidth}
                  compactType={'horizontal'}
                  isDraggable={false}
                  isResizable={false}
                >
                  {
                    widgets.map((widget, index) => {


                      if (!widget.grid) {

                        widget.grid = {}
                        widget.grid.h = 3;
                        widget.grid.w = 12;
                        widget.grid.x = 0;
                        widget.grid.y = 0;

                      }

                      return (
                        <div style={{
                        }} key={widget.id} data-grid={widget.grid} >
                          <WidgetDriver key={`${index}${widget.id}`}
                            classes={classes}
                            showTable={false}
                            changeLoading={this.changeLoading}
                            selectedPage={currPage}
                            parks={parks}
                            unique={true}
                            ignoreIcons={true}
                            history={history}
                            widget={widget}
                            isAnalysis={false}
                            reducer_name={'REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA'}
                            module_id={'DelfosPreviousManagement'}
                            filterTable={this.filterTable}
                          />

                          <br />
                          {
                            widget.notable === false ? null :
                              <WidgetDriver key={`${index}${widget.id}`}
                                showTable={true}
                                classes={classes}
                                unique={true}
                                changeLoading={this.changeLoading}
                                selectedPage={currPage}
                                parks={parks}
                                ignoreIcons={true}
                                history={history}
                                widget={widget}
                                isAnalysis={false}
                                reducer_name={'REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA'}
                                module_id={'DelfosPreviousManagement'}
                                tableFilter={this.state.tableFilter}
                              />
                          }


                        </div>
                      );
                    })
                  }
                </GridLayout>
              ) : ''}


            </div>
            :
            <CustomAnalysis
              metaData={customAnalysis}
              changeLoading={this.changeLoading}
              parks={parks}
              history={history}
              selectedPage={currPage}
              moduleLoading={loading}
              updatedFilters={updatedFilters}
            />
        }
      </AppStyleContent>
    )
  }
}



const mapStateToProps = ({ Navigation, User, Dashboard }) => {
  const { currModule } = Navigation

  const {
    devices,
    subparks
  } = Dashboard && Dashboard.DeviceFilter;

  return { currModule, devices, subparks }
};

const mapDispatchToProps = { updateDateFilter };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AnalysisPage))

