import React, { Component } from "react";
import {
  Toolbar,
  withStyles,
  Button,
  Tooltip,
  Grid,
  Typography
} from "@material-ui/core";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import FaceIcon from "@material-ui/icons/Face";
import Icon from "@material-ui/core/Icon";

import { CircularProgress } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import MenuTree from "../Menu/MenuTree";
import EnvelopeMenuTree from "../Menu/EnvelopeMenuTree";
import DatePicker from "../DatePicker/DatePicker";
import * as R from "ramda";

import { updateDateFilter } from "../../actions/DateFilterActions";
import { changeUpdateWidgets } from "../../actions/MenuTreeAction";
// import { selectPage, updateDateFilter, selectWtgAndEnvelope } from '../../actions/PageActions'
import { selectPage, selectWtgAndEnvelope } from "../../actions/PageActions";
import { retrieveDataAlarmsModule } from "../../actions/RetrieveDataAction";
import DefaultErrorHandler from "../Handler/DefaultErrorHandler";

import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";


import {
  STYLE_SUBHEADER_TOOLBAR,
  STYLE_HEADER_TOOLBAR
} from "../../constants/style-constant";
import { store } from "../../store/ProviderStore";

import Select2 from '../../components/Menu/Select';
import { extractAjustedParksInfo } from '../../helpers/parks-info-helper';
import ComponentSelector from '../../components/Filter/ComponentSelector';

import NoPrint from '../Print/NoPrint';

import { PredictiveModelFilter } from '../../new-components/Filters';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    width: "auto",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    right: 0,
    maxHeight: 300,
    zIndex: 10
  },
  listSection: {
    backgroundColor: "inherit"
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0
  },
  paperMenuBackground: {},
  appBar: {
    backgroundColor: "white"
  },
  buttonIconDropDown: {
    marginLeft: "1px"
  },
  stickyActive: {
    alignItems: 'flex-end',
    position: 'fixed',
    borderTop: '1px solid #e2e2e2',
    zIndex: 9999,
    height: 50,
    top: 51,
    backgroundColor: 'white',
    right: 15,
    boxShadow: '4px 7px 3px -8px #000'
  },
  stickyHidden: {
    alignItems: 'flex-end',
    position: 'relative'
  },
  appBarToolbarFixed: {
    minHeight: STYLE_SUBHEADER_TOOLBAR,
    zIndex: "999",
    top: "0"
  },
  appBarToolbarSub: {
    minHeight: "0",
    height: "30px",
    paddingLeft: "0",
    marginLeft: "auto",
    width: "auto"
  },
  buttonMenu: {
    textTransform: "capitalize",
    fontWeight: "200",
    fontSize: "16px",
    borderRadius: "0",
    zIndex: 10
  },
  subTitle: {
    textTransform: 'uppercase',
    fontSize: 10,
    color: '#666'
  },
  buttonsFilterFixed: {
    position: "absolute",
    right: 10,
    display: "flex",
    top: '-2vh'
  },
  buttonsFilterRelative: {
    position: "absolute",
    right: 0,
    display: "flex"
  },
  buttonMenuSubHeader: {
    '&:hover': {
      color: '#FF3300',
      backgroundColor: 'white',
    },
    textTransform: "capitalize",
    fontWeight: "200",
    fontSize: "16px",
    minHeight: "40px",
    borderRadius: "0",
    zIndex: 10,
    backgroundColor: 'white',
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25
  },
  buttonMenuMiddle: {
    '&:hover': {
      color: '#FF3300',
      backgroundColor: 'white',
    },
    textTransform: "capitalize",
    fontWeight: "200",
    fontSize: "16px",
    minHeight: "40px",
    borderRadius: "0",
    zIndex: 10,
    backgroundColor: 'white'
  },
  buttonDatePicker: {
    '&:hover': {
      color: '#FF3300'
    },
    textTransform: "capitalize",
    fontWeight: "200",
    fontSize: "16px",
    minHeight: "40px",
    borderRadius: "0",
    zIndex: 10,
    backgroundColor: 'white',
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25
  },
  leftRoundCorner: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25
  },
  buttonMenuSubHeaderSelect: {
    textTransform: "capitalize",
    fontWeight: "200",
    fontSize: "16px",
    borderRadius: "0",
    backgroundColor: orange["700"],
    color: "white",
    minHeight: "40px",
    "&:hover": {
      backgroundColor: orange["900"]
    },
    zIndex: 10
  },
  buttonPageFixed: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#FF3300'
    },
    top: '-1vh',
    left: 25,
    textTransform: "capitalize",
    minWidth: "100px",
    position: 'relative'
  },
  buttonPageRelative: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#FF3300'
    },
    // top: 20,
    textTransform: "capitalize",
    minWidth: "100px",
    position: 'relative'
  }
});

const maxDate = moment().subtract(1, "days");
let lastScroll = 0;

class BaseGlobalFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      managementsAnchorEl: null,
      focusedInput: null,
      startDate: moment().subtract(10, "days"),
      endDate: maxDate,
      updateWidgetInterval: null,
      anchorEl: null,
      open: false,
      currToken: null,
      parksInfoAttempts: 1,
      filterFixed: false
    };

  }

  componentDidMount() {
    const elms = window.document.getElementsByClassName("DateRangePickerInput");

    this.sizeNavbar()

    if (elms.length > 0) {
      const el = elms[0];

      el.parentNode.style.height = "100%";
    }

    if (this.props.location.search) {
      const params = new URLSearchParams(this.props.location.search);

      if (params.get("date_init") && params.get("date_final")) {
        const dateInit = moment(params.get("date_init"));
        const dateFinal = moment(params.get("date_final"));

        this.props.updateDateFilter(
          'page.id',
          dateInit.startOf("day"),
          dateFinal.startOf("day"),
          'module_field',
          'reducer_name'
        );

        this.setState(
          {
            startDate: dateInit.format("YYYY-MM-DD"),
            endDate: dateFinal.format("YYYY-MM-DD")
          },
          () => {
            this.executeUpdate();
          }
        );
      } else {
        this.updateDates();
      }
    } else {
      // console.log('Not executing router search')
      this.updateDates(true);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { parksInfo, enableEdit, Dashboard } = this.props;
    const { parksInfoAttempts } = this.state;

    if (!parksInfo || parksInfo.length === 0) {
      setTimeout(() => {
        this.setState({
          parksInfoAttempts: parksInfoAttempts > 10 ? 1 : parksInfoAttempts + 1
        });
      }, 200 * parksInfoAttempts);
    } else if (!prevProps.parksInfo || prevProps.parksInfo.length === 0) {
      this.updateDates(true);
      if (enableEdit) {
        setTimeout(() => enableEdit(), 500);
      }
    }

    if (!(prevProps.Dashboard && prevProps.Dashboard.DateFilter && prevProps.Dashboard.DateFilter.data_range) &&
      (Dashboard && Dashboard.DateFilter && Dashboard.DateFilter.data_range)) {
      this.updateDates(true);
    }
  }

  updateDates = (execFilter = false) => {
    const { page, parksInfo, executeFilter, Dashboard } = this.props;
    const { filter } = page || {};
    let { data_range } = filter || {};
    // const { startDate, endDate } = data_range || {};
    data_range = Dashboard.DateFilter.data_range ? Dashboard.DateFilter.data_range : (filter && filter.data_range);

    if (!data_range) {
      return null;
    }

    const startDate = Dashboard.DateFilter.data_range ? data_range.startDate : parksInfo[0] ? moment(parksInfo[0].t_end).subtract(8, 'days').startOf('day').format('YYYY-MM-DD') : data_range.startDate
    const endDate = Dashboard.DateFilter.data_range ? data_range.endDate : parksInfo[0] ? moment(parksInfo[0].t_end).startOf('day').format('YYYY-MM-DD') : data_range.endDate


    if (parksInfo[0]) {

      this.props.updateDateFilter(
        'page.id',
        moment(startDate).startOf("day"),
        moment(endDate).startOf("day"),
        'module_field',
        'reducer_name'
      );

    }


    if (execFilter) {
      executeFilter({
        ...page,
        filter: {
          ...page.filter,
          data_range: {
            startDate,
            endDate
          }
        }
      });
    }

    this.setState({
      startDate,
      endDate
    });
  };

  getDatesUpdated = () => {
    const { startDate, endDate } = this.state;
    return { startDate, endDate };
  };

  updateWidgets(data = null) {
    const { page, reducer_name, module_field } = this.props;
    this.state.currToken = this.props.changeUpdateWidgets(
      module_field,
      page.id,
      reducer_name,
      this.state.currToken
    );
  }

  handleChangeDates({ startDate, endDate }) {

    this.props.updateDateFilter(
      'page.id',
      moment(startDate).startOf("day"),
      moment(endDate).startOf("day"),
      'module_field',
      'reducer_name'
    );


    this.setState({
      startDate,
      endDate
    });
  }

  executeUpdate = () => {
    const { page, reducer_name, module_field } = this.props;
    const { startDate, endDate } = this.state;

    if (!startDate || !endDate) {
      this.props.updateDateFilter(
        page.id,
        moment(startDate).startOf("day"),
        moment(endDate).startOf("day"),
        module_field,
        reducer_name
      );

      this.updateWidgets.bind(this)();
    }
  };

  selectPage = (page, event) => {
    const page_id = this.props.submodule ? page.id : page;

    if (this.props.submodule && page && page.name) {
      const customUrl = this.props && this.props.location && this.props.location.pathname;

      window._paq.push(['setCustomUrl', `${customUrl}/${page.name}`]);
      window._paq.push(['trackPageView']);
    }

    const { module_field, reducer_name } = this.props;

    if (reducer_name) {
      this.props.selectPage(page_id, module_field, reducer_name);
    }
    this.menuClose.bind(this, "pagesAnchorEl")();
    this.props.goToPage(page_id);
  };

  goPage(page) {
    const { history } = this.props;

    history.push(page);
  }

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open
    }));
  };

  onSelectModel = (page_id, wtg_id, envelope_id) => () => {
    const { module_field, reducer_name, page } = this.props;
    const { filter } = page;
    const { wtgs } = this.state;

    this.handleClick({ currentTarget: null });

    // const curr_envelopes = this.getEnvelope(wtgs, {...filter, envelope_id: envelope_id});

    // this.setState(state => ({
    //   anchorEl: null,
    //   open: !state.open,
    //   curr_envelopes
    // }));

    this.props.selectWtgAndEnvelope(
      page_id,
      wtg_id,
      envelope_id,
      module_field,
      reducer_name
    );
  };

  menuOpen(key, event) {
    // window.xxx = event.currentTarget;
    this.setState({ [key]: event.currentTarget });
  }

  menuClose = key => {
    this.setState({ [key]: null });
  }


  formatPagesToMenu(pages) {
    const pagesByCategory = [...new Set(pages.map(page => page.cat))].map(cat => ({ label: cat, options: [] }));

    pagesByCategory.map(pc => {
      pages.map(page => {
        if (page.cat === pc.label) {
          page.label = page.name;
          pc.options.push(page);
        }
      })

    })

    return pagesByCategory;
  }

  sizeNavbar = () => {
    const element = document.getElementById("buttonsFilter").children.length
    element === 3 ? this.setState({ sizePageTitle: 4 }) : this.setState({ sizePageTitle: 7 })
    element === 3 ? this.setState({ sizeFilter: 8 }) : this.setState({ sizeFilter: 5 })
  }

  scrollGlobalFilter = async scroll => {
    const element = document.getElementById("filterNavigation")

    if (scroll >= 102) {
      this.setState({ filterFixed: true })

      if (lastScroll > scroll) {
        element && element.setAttribute('style', 'transition: all 500ms cubic-bezier(.24,.74,.74,.7)')
      } else {
        element && element.setAttribute('style', 'transition: all 500ms cubic-bezier(.24,.74,.74,.7)')
      }

    } else {
      this.setState({ filterFixed: false })
      element && element.setAttribute('style', 'transition: all 400ms cubic-bezier(.24,.74,.74,.7)')
    }

    lastScroll = scroll
  }

  render() {
    const {
      classes,
      page,
      mode,
      parksInfo,
      module_field,
      reducer_name,
      submodule,
      unique,
      Dashboard,
      children,
      scrollY,
      predictive,
      forcedMaxDate,
      t
    } = this.props;

    const {
      filterFixed
    } = this.state;

    const sticky = filterFixed ? classes.stickyActive : classes.stickyHidden
    const buttonsFilter = filterFixed ? classes.buttonsFilterFixed : classes.buttonsFilterRelative
    const buttonPage = filterFixed ? classes.buttonPageFixed : classes.buttonPageRelative

    let props = this.props;



    const childrenWithProps = React.Children.map(children, (child) => {
      const attr = {
        ...child.props,
        filterFixed: this.state.filterFixed,
        style: { height: '100%' }
      }

      return React.cloneElement(child, attr);
    })

    const waitingParksInfo = !parksInfo || parksInfo.length === 0

    const { useDateRange } = page;
    let filterFromRedux = null;


    if (submodule) {
      if (unique) {
        filterFromRedux = page;
      } else {
        filterFromRedux = props[module_field].filter(somePage => somePage.id === page.id)[0];
      }
      // filterFromRedux = props[module_field].filter(somePage => somePage.id === page.id)[0];
    } else {
      filterFromRedux = props[module_field.toLowerCase()].filter(
        somePage => somePage.id === page.id
      )[0];
    }


    // const filter = {...pFilter, envelope_id: filterFromRedux && filterFromRedux.filter.envelope_id}
    const filter = filterFromRedux ? filterFromRedux.filter : {};

    if (
      Dashboard.EnvelopeFilter.envelope_id !== undefined &&
      (filter && filter.envelope_id)
    ) {
      filter.envelope_id = Dashboard.EnvelopeFilter.envelope_id;
    }


    if (filter && filter.envelope_id != null) {
      page.filter.envelope_id = filter.envelope_id;
    }

    // const { curr_envelopes, wtgs } = this.state;

    let pages = [];

    if (submodule) {
      if (unique) {
        pages = this.props.widgets_list;

      } else {
        pages =
          "delfos_1_0" in props
            ? props["delfos_1_0"][
            module_field.toLowerCase().replace("delfosprevious", "")
            ]
            : [];

      }

    } else {
      pages =
        module_field.toLowerCase() in props
          ? props[module_field.toLowerCase()]
          : [];
    }



    // const pages = Module;
    let curr_devices_names = [];
    let curr_subparks_names = [];



    if (!waitingParksInfo) {
      const { parks: infoParks, subparks: infoSubarks, devices: infoDevices } = extractAjustedParksInfo(parksInfo);
      const { devices: currDeviceIds, subparks: currSubparkIds } = this.props.page.filter || {};

      curr_devices_names = currDeviceIds ? currDeviceIds.map(deviceId => infoDevices[deviceId] ? infoDevices[deviceId].name : '') : [];
      curr_subparks_names = currSubparkIds ? currSubparkIds.map(subparkId => infoSubarks[subparkId] ? infoSubarks[subparkId].name : '') : [];
    }

    const wtgs = R.flatten(
      parksInfo.map(park => {
        return park.subparks.map(subpark => {
          return subpark.wtgs.map(wtg => {
            return {
              ...wtg,
              subpark_id: subpark.id,
              subpark_name: subpark.name,
              park_id: park.id,
              park_name: park.name
            };
          });
        });
      })
    );

    let curr_envelopes =
      filter && "envelope_id" in filter && filter.envelope_id
        ? wtgs
          .filter(
            wtg =>
              wtg &&
              wtg.envelope &&
              wtg.envelope
                .map(envelope => envelope.id)
                .indexOf(filter.envelope_id) >= 0
          )
          .map(
            wtg =>
              wtg.envelope.filter(
                envelope => envelope.id == filter.envelope_id
              )[0].name
          )
        : null;

    curr_envelopes = curr_envelopes
      ? curr_envelopes.map(envelope => {
        envelope =
          envelope ==
            "Average Generator Speed 10M vs Generator Speed St. Dev. 10M"
            ? "Generator Speed I"
            : envelope ==
              "Average Active Power 10M vs Trafo 3 winding temperature 10M"
              ? "Trafo Temperature III"
              : envelope ==
                "Average Active Power 10M vs Average Reactive Power 10M"
                ? "Reactive Power"
                : envelope ==
                  "Average Active Power 10M vs Bearing N.D.E. Temperature 10M"
                  ? "NDE Bearing Temperature"
                  : envelope ==
                    "Average Active Power 10M vs Average Stator Active Power 10M"
                    ? "Stator Act. Power"
                    : envelope ==
                      "Average Rotor Speed 10M vs Rotor Speed St. Dev. 10M"
                      ? "Rotor Speed I"
                      : envelope ==
                        "Average Active Power 10M vs Trafo 2 winding temperature 10M"
                        ? "Trafo Temperature II"
                        : envelope ==
                          "Average Active Power 10M vs Generator windings temperature 2 10M"
                          ? "Gen. Windings Temperature II"
                          : envelope ==
                            "Average Active Power 10M vs Generators sliprings temperature 10M"
                            ? "Gen. Slipring"
                            : envelope ==
                              "Average Active Power 10M vs Generator windings temperature 3 10M"
                              ? "Gen. Windings Temperature III"
                              : envelope ==
                                "Average Active Power 10M vs Gearbox bearing temperature 10M"
                                ? "Gearbox Bearing Temperature"
                                : envelope ==
                                  "Average Active Power 10M vs Average Rotor Speed 10M"
                                  ? "Rotor Speed II"
                                  : envelope == "Average Wind Speed 10M vs Average Active Power 10M"
                                    ? "Measured Power Curve"
                                    : envelope ==
                                      "Average Active Power 10M vs Average Generator Speed 10M"
                                      ? "Generator Speed II"
                                      : envelope ==
                                        "Average Generator Speed 10M vs Average Rotor Speed 10M"
                                        ? "Rotor Speed III"
                                        : envelope ==
                                          "Average Active Power 10M vs Bearing D.E. Temperature 10M"
                                          ? "DE Bearing Temperature"
                                          : envelope ==
                                            "Average Active Power 10M vs Average Grid Voltage 10M"
                                            ? "Grid Voltage"
                                            : envelope ==
                                              "Average Active Power 10M vs Yaw Misalignment = (WTG Nacelle Position) - (MetMast Wind Dir)"
                                              ? "Yaw Misalignment"
                                              : envelope ==
                                                "Average Active Power 10M vs Gearbox oil temperature 10M"
                                                ? "Gearbox Oil"
                                                : envelope ==
                                                  "Average Active Power 10M vs Hidraulic group pressure 10M"
                                                  ? "Hidraulic Group"
                                                  : envelope ==
                                                    "Average Active Power 10M vs Average Pitch Angle 10M"
                                                    ? "Pitch Angle"
                                                    : envelope ==
                                                      "Average Active Power 10M vs Generator windings temperature 1 10M"
                                                      ? "Gen. Windings Temperature I"
                                                      : envelope ==
                                                        "Average Active Power 10M vs Trafo 1 winding temperature 10M"
                                                        ? "Trafo Temperature I"
                                                        : envelope;
        return envelope;
      })
      : null;

    // console.log('ON RENDERING BASE GLOBAL FILTER', curr_subparks_names, curr_devices_names, this.props.page.filter)

    return (
      <NoPrint>
        <div
          className={classes.appBarToolbarFixed}
          style={{
            backgroundColor: scrollY ? 'white' : 'transparent',
            padding: scrollY ? '0 12px' : null,
            borderTop: scrollY ? '1px solid rgb(238, 238, 238)' : null,
            boxShadow: scrollY ? '0 -3px 9px #000' : null,
            height: scrollY ? '46px' : null,
            margin: '7px 22px 35px 7px',
            position: scrollY ? 'sticky' : null
          }}
        >
          <Grid id="filterNavigation" className={sticky} container spacing={24}>
            <Grid className={classes.pageNavigation} key={1} item xs={5} md={4}>
              <p
                className={classes.subTitle}
                style={{
                  display: filterFixed ? 'none' : null,
                  marginLeft: !submodule ? 10 : 16,
                  position: 'relative',
                  top: !submodule ? 12 : 0
                }}
              >
                {t('Page Navigation')}
              </p>
              {
                !submodule ?
                  <Button
                    onClick={this.menuOpen.bind(this, "pagesAnchorEl")}
                    disabled={this.props.disabled}
                    className={buttonPage}
                  >
                    <Typography
                      variant="h2"
                      style={{
                        fontSize: "1.5rem"
                      }}
                    >
                      {t(page.name)}
                    </Typography>
                    <Icon>expand_more</Icon>
                  </Button>
                  :
                  <div style={{
                    margin: 10,
                    marginLeft: !filterFixed ? 10 : '1.8vw'
                  }}>
                    <Select2
                      options={this.formatPagesToMenu(pages)}
                      label={
                        <Typography
                          variant="h2"
                          style={{
                            marginTop: !filterFixed ? '-2vh' : undefined,
                            fontSize: "1.5rem",
                            color: 'black'
                          }}
                        >
                          {page.name}
                        </Typography>
                      }
                      value={page.id}
                      onChange={this.selectPage.bind(this)}
                      height={!filterFixed ? '2vh' : '5vh'}
                    />
                  </div>
              }
              {!submodule && <Menu
                id="lock-menu"
                anchorEl={this.state.pagesAnchorEl}
                open={Boolean(this.state.pagesAnchorEl)}
                onClose={this.menuClose.bind(this, "pagesAnchorEl")}
                style={{
                  zIndex: 9999
                }}
              >
                {pages.map((a_page, index) => (
                  <MenuItem
                    key={a_page.id}
                    selected={submodule ? page.selectedGraphicId === a_page.id : page.id === a_page.id}
                    style={{
                      minWidth: `${
                        this.state.pagesAnchorEl
                          ? this.state.pagesAnchorEl.clientWidth
                          : 150
                        }px`
                    }}
                    onClick={this.selectPage.bind(this, a_page.id)}
                  >
                    {t(a_page.name)}
                  </MenuItem>
                ))}
              </Menu>}
            </Grid>
            <Grid key={2} item xs={7} md={8}>
              <Toolbar className={classes.appBarToolbarSub} variant="dense">
                <div
                  id="buttonsFilter"
                  className={buttonsFilter}
                  style={{
                    top: submodule && filterFixed ? '-2.5vh' : undefined
                  }}
                >
                  {filter && "devices" in filter && (
                    [...childrenWithProps]
                  )}
                  {!predictive && filter && "envelope_id" in filter && filter.envelope_id && (
                    <DefaultErrorHandler>
                      <EnvelopeMenuTree
                        buttonMenuSubHeader={classes.buttonMenuMiddle}
                        page={page}
                        module_field={module_field}
                        reducer_name={reducer_name}
                        executeFilter={this.props.executeFilter}
                        style={{
                          opacity: !waitingParksInfo ? 1 : .5,
                          border: scrollY ? 'none' : '1px solid #eeeeee'
                        }}
                      >
                        {/*Models*/}
                        <React.Fragment>
                          {
                            waitingParksInfo && (
                              <CircularProgress
                                size={24}
                                style={{
                                  color: 'orange',
                                  position: 'absolute',
                                  right: '25%',
                                  top: '20%'
                                }}
                              />
                            )
                          }
                          <Icon
                            className={classes.icon}
                            style={{ color: "#bdbdbd", marginRight: "5px" }}
                          >
                            timeline
                                </Icon>
                          {
                            curr_envelopes.length >= 3 ? (
                              <Tooltip title={curr_envelopes.join(", ")}>
                                <span>{`${curr_envelopes.length} models`}</span>
                              </Tooltip>
                            ) : (
                                <span>{curr_envelopes.join(", ")}</span>
                              )
                          }
                        </React.Fragment>
                      </EnvelopeMenuTree>
                    </DefaultErrorHandler>
                  )}
                  {
                    predictive && Dashboard && Dashboard.DeviceFilter && Dashboard.DeviceFilter.devices && Dashboard.DeviceFilter.devices.length === 1 &&
                    <PredictiveModelFilter
                      device={Dashboard.DeviceFilter.devices[0]}
                      onExecute={envelope_id => {
                        const { executeFilter } = this.props;
                        executeFilter(page);
                      }}
                    />
                  }

                  {(useDateRange || (filter && "data_range" in filter)) && (
                    <DefaultErrorHandler>
                      <div
                        className={
                          !filter || filter && !filter.devices && !filter.envelope_id && !this.props.componentsFilter ?
                            `${classes.leftRoundCorner} ${classes.buttonDatePicker}`
                            :
                            classes.buttonDatePicker}
                        style={{
                          opacity: !waitingParksInfo ? 1 : .5
                        }}
                      >
                        {
                          waitingParksInfo && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: 'orange',
                                position: 'absolute',
                                right: '30%',
                                top: '20%'
                              }}
                            />
                          )
                        }
                        <DatePicker
                          parksInfo={parksInfo}
                          handleChangeDates={(data) => this.handleChangeDates(data)}
                          page={page}
                          disabled={waitingParksInfo || this.props.disabled}
                          executeUpdate={this.executeUpdate}
                          getDatesUpdated={this.getDatesUpdated}
                          executeFilter={this.props.executeFilter}
                          forcedMaxDate={forcedMaxDate}
                        />
                      </div>
                    </DefaultErrorHandler>
                  )}
                  {'componentsFilter' in this.props && (
                    <DefaultErrorHandler>
                      <div
                        className={classes.buttonDatePicker}
                        style={{
                          opacity: !waitingParksInfo ? 1 : .5
                        }}
                      >
                        {
                          waitingParksInfo && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: 'orange',
                                position: 'absolute',
                                right: '30%',
                                top: '20%'
                              }}
                            />
                          )
                        }
                        <ComponentSelector />
                      </div>
                    </DefaultErrorHandler>
                  )
                  }
                </div>
              </Toolbar>
            </Grid>
          </Grid>
        </div>
      </NoPrint>
    );
  }
}

const mapStateToProps = ({ Dashboard, User }) => {
  return {
    Dashboard,
    parksInfo: User.parks_info
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateDateFilter,
      changeUpdateWidgets,
      selectPage,
      selectWtgAndEnvelope
    },
    dispatch
  );

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BaseGlobalFilter)));