import {  
    SET_MODELS
} from './contants';

const DEFAULT_STATE = {};

export default (state = DEFAULT_STATE, action) => {
    const { type, predictiveModels } = action;

    switch (type) {
        case SET_MODELS:
            return predictiveModels || state;
        default:
            return state;
    }
}
