import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'

import {Typography, Icon, IconButton, Menu, MenuItem, CircularProgress} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {styles} from './HeaderWithStyle'
import LogoComponent from './LogoComponent';
import { HeaderContent } from './HeaderStyle';

import * as Routes from '../../constants/route-constant';
import history from '../../history';
import {logout, destroy} from '../../actions/AuthAction';
import {
  goToPage,
  moduleSelected,
  subModuleSelected,
  setFromPage
} from '../../actions/NavigationActions';
import ConfirmationDialogRaw from '../Dialog/ConfirmationDialogRaw';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@material-ui/core'

import PageHeaderComponentBase from './PageHeaderComponentBase';

import { savePreferences, clearPreferences } from '../../actions/SendDataActions'
import { CognitoChangePassword } from '../../actions/ApplicationActions'

import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'

import { toast } from 'react-toastify'

class HeaderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      old_password: null,
      new_password: null,
      value: "Delfos",
      selected_module_id: null,
      selected_submodule_id: null,
      modules: null,
      dialogChangePassword:false,
      user_menu_open: false,
      user_menu_anchorEl: null,
      saving: false,
      saveOk: null,
      clearConfirm: false,
      clearing: false,
      clearOk: null,
      prefBtAnchor: null,
      passwords_matches: true,
      new_password: '',
      old_password: '',
      confirm_password: ''
    }

  }

  componentDidUpdate() {
    if(this.state.saving && this.state.saveOk != null) {
      setTimeout(() => this.setState({saving: false, saveOk: null, user_menu_open: false}), 1000)
    } else if(this.state.saving) {
      savePreferences()
        .then(result => {
          this.setState({saving: true, saveOk: true})
        })
        .catch(error => {
          this.setState({saving: true, saveOk: false})
        })
    }

    if(this.state.clearing && this.state.clearOk != null) {
      setTimeout(() => this.setState({clearing: false, clearOk: null, user_menu_open: false}), 1000)
    } else if(this.state.clearing) {
      clearPreferences()
        .then(result => {
          this.setState({clearing: true, clearOk: true, clearConfirm: false})
        })
        .catch(error => {
          this.setState({clearing: true, clearOk: false, clearConfirm: false})
        })
    }

  }

  closeDialogChangePassword = () =>{
    this.setState({ dialogChangePassword: false, user_menu_open: false, new_password: '', confirm_password: '', old_password: '', passwords_matches: true })
  }


  handleClickListItem = () => {
    this.setState({ open: true });
  };

  handleClose = value => {
    this.setState({ value, open: false, clearConfirm: false, user_menu_open: false });
  };
  goPage (page) {
    history.push(page)
  }

  signOut () {
    this.handleClickListItem();
  }

  chooseModule (module, event) {
    if(this.state.selected_module_id != module.id) {
      this.state.selected_module_id = module.id;
      this.state.selected_submodule_id = module.id * 10;
      this.forceUpdate();
      this.props.moduleSelected(this.state.selected_module_id);
      this.props.subModuleSelected(this.state.selected_submodule_id);
      this.props.goToPage(module.route);
    }
  }

  changePassword(){
    if(this.state.new_password.length > 0 && this.state.passwords_matches) {
      const pass_changed = this.props.CognitoChangePassword(this.state.old_password, this.state.new_password)
      // console.log(pass_changed)
      pass_changed.then(({result, details}) => {
        if(!result) {
          console.log('ERROR DETAILS', details)
          toast.error(
            details.code == 'NotAuthorizedException' ?
              'Incorrect old password. Please provide the correct one.'
            :
              details.message,
            {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            }
          )
          this.setState({new_password: '', confirm_password: '', old_password: '', passwords_matches: false})
        } else {
          this.setState({new_password: '', confirm_password: '', old_password: '', passwords_matches: true, user_menu_open: false, user_menu_anchorEl: null})
        }
      }).catch(error => {
        this.setState({new_password: '', confirm_password: '', old_password: '', passwords_matches: true})
      })
    }
  }

  handleClickUserMenu(event) {
    let new_menu_state = !this.state.user_menu_open
    this.setState({
      user_menu_open: new_menu_state,
      user_menu_anchorEl: new_menu_state ? event.currentTarget : null
    })
  }

  handleCloseUserMenu() {
    this.setState({
      user_menu_open: false,
      user_menu_anchorEl: null
    })
  }

  closeDialogDeleteOverview(){
    this.setState({ dialogChangePassword: false });

  }


  changePasswordBtClick(event){
    this.setState({
      dialogChangePassword: true,
      user_menu_open: false,
      user_menu_anchorEl: null
    })
  }


  savePreferencesBtClick(event) {
    this.setState({
      saving: true,
      prefBtAnchor: event.currentTarget.getBoundingClientRect()
    })
  }

  clearPreferencesBtClick(event) {
    this.setState({
      clearConfirm: true
    })
  }

  clearPrefs() {
    this.setState({
      clearConfirm: false,
      clearing: true
    })
  }

  render () {
    const {classes, modules, Navigation} = this.props;
    const {module_id, module_depth} = Navigation;

    if(!modules || modules.length == 0) {throw 'Modules Data Not Exists';}
    this.state.modules = modules;

    const depth = module_depth ? module_depth : 2;
    const path_tree = window.location.pathname.split('/');
    const route = `${path_tree.filter((value, index) => index <= depth).join('/')}`;

    const selected_module = modules.filter(module => 'route' in module && module.route == route)[0]

    this.state.selected_module_id = selected_module ? selected_module.id : null
    // if(!this.state.selected_module_id) {throw 'Route does not correspond to module'}

    if(!this.state.selected_submodule_id) {
      this.state.selected_submodule_id = this.state.selected_module_id * 10;
    }

    const Module = this.state.modules.filter(module => module.id == this.state.selected_module_id)[0];
    const Submodules = Module ? Module.submodules.length > 0 ? Module.submodules : [] : null;

    const FromModule = this.state.modules.filter(module => module.id == module_id)[0]

    return (
      this.state.selected_module_id ?
        <React.Fragment>
          <HeaderContent>
            <AppBar className={classes.appBar} elevation={0}>
              <Toolbar className={classes.appBarToolbar}>
                <LogoComponent />
                {
                  this.props.edit_mode ?
                    <Typography variant="headline" component="h2" style={{marginTop: '1%'}}>
                      {`${FromModule.name} > Edit Mode`}
                    </Typography>
                  :
                    this.state.modules.map((module, moduleIndex) => (
                      !'hide' in module || !module.hide ?
                        <Button
                          key={moduleIndex}
                          onClick={this.chooseModule.bind(this, module)}
                          style={{
                            borderRadius: 0,
                            color: this.state.selected_module_id == module.id ? 'orange' : 'black',
                            borderBottom: `${this.state.selected_module_id == module.id ? '3px' : '0px'} solid orange`,
                          }}
                        >
                          {module.name}
                        </Button>
                      :
                        null
                    ))
                }
                <div style={{ flex: 1 }}></div>
                <IconButton
                  aria-label='User Menu'
                  aria-owns={this.state.user_menu_open ? 'user-menu' : null}
                  aria-haspopup='true'
                  className={classes.button}
                  onClick={this.handleClickUserMenu.bind(this)}
                >
                  <Icon style={{ color:'#bdbdbd', fontSize:'36px'}}>account_circle</Icon>
                </IconButton>
                <Menu
                  id='user-menu'
                  anchorEl={this.state.user_menu_anchorEl}
                  open={this.state.user_menu_open}
                  onClose={this.handleCloseUserMenu.bind(this)}
                  PaperProps={{
                    style: {
                      padding: '0px',
                      maxHeight: '60%',
                      width: '18%',
                      marginTop: `${this.state.user_menu_anchorEl ? this.state.user_menu_anchorEl.getBoundingClientRect().height * 0.9 : 0}px`
                    },
                  }}
                >
                  <MenuItem key={'Header'} disabled={true}
                    disableGutters={true}
                  >
                    User Menu
                  </MenuItem>
                  <MenuItem key={'SavePrefs'}
                    disableGutters={true}
                    disabled={this.state.saving}
                    onClick={this.savePreferencesBtClick.bind(this)}
                  >
                    <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>save</Icon>
                    <Typography variant="h2" component="p"
                      style={{
                        fontSize: '1rem',
                        margin: 0
                      }}
                    >
                      Save Preferences
                    </Typography>
                    {
                      this.state.saving && this.state.saveOk == null ?
                        <CircularProgress
                          size={24}
                          className={classes.progressSaving}
                          style={{
                            top: '25%',
                            left: '47%'
                          }}
                        />
                      :
                        null
                    }
                    {
                      this.state.saving && this.state.saveOk != null ?
                        <Icon
                          size={24}
                          className={classes.progressSaving}
                          style={{
                            top: '25%',
                            left: '45%'
                          }}
                        >
                          {this.state.saveOk ? 'check' : 'close'}
                        </Icon>
                      :
                        null
                    }
                  </MenuItem>
                  <MenuItem key={'ClearPrefs'}
                    disableGutters={true}
                    disabled={this.state.clearing}
                    onClick={this.clearPreferencesBtClick.bind(this)}
                  >
                    <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>delete</Icon>
                    <Typography variant="h2" component="p"
                      style={{
                        fontSize: '1rem',
                        margin: 0
                      }}
                    >
                      Clear Preferences
                    </Typography>
                    <ConfirmationDialogRaw
                      logout={this.clearPrefs.bind(this)}
                      classes={{
                        paper: classes.paper
                      }}
                      open={this.state.clearConfirm}
                      onClose={this.handleClose}
                      value={this.state.value}
                      message='Your preference will be cleared, are you sure?'
                    />
                    {
                      this.state.clearing && this.state.clearOk == null ?
                        <CircularProgress
                          size={24}
                          className={classes.progressSaving}
                          style={{
                            top: '25%',
                            left: '47%'
                          }}
                        />
                      :
                        null
                    }
                    {
                      this.state.clearing && this.state.clearOk != null ?
                        <Icon
                          size={24}
                          className={classes.progressSaving}
                          style={{
                            top: '25%',
                            left: '45%'
                          }}
                        >
                          {this.state.clearOk ? 'check' : 'close'}
                        </Icon>
                      :
                        null
                    }
                  </MenuItem>
                  <MenuItem key={'ChangePassword'}
                    onClick={this.changePasswordBtClick.bind(this)}
                  >
                    <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>lock</Icon>
                    <Typography variant="h2" component="p"
                      style={{
                        fontSize: '1rem',
                        margin: 0
                      }}
                    >
                      Change Password
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={this.signOut.bind(this)}
                    disableGutters={true}
                  >
                    <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>power_settings_new</Icon>
                    <Typography variant="h2" component="p"
                      style={{
                        fontSize: '1rem',
                        margin: 0
                      }}
                    >
                      Sign Out
                    </Typography>
                  </MenuItem>
                  <ConfirmationDialogRaw
                    logout={this.props.logout}
                    destroy={this.props.destroy}
                    classes={{
                      paper: classes.paper
                    }}
                    open={this.state.open}
                    onClose={this.handleClose}
                    value={this.state.value}
                    message='Do you really want to leave?'
                  />
                </Menu>
              </Toolbar>
            </AppBar>
          </HeaderContent>
          <Dialog
              onClose={this.closeDialogChangePassword}
              open={this.state.dialogChangePassword}
              aria-labelledby='dialog_overview_delete'
            >
              <DialogTitle id='dialog_overview_delete'>
                Change Password
              </DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  <TextField
                    className={classes.textField}
                    label='Current Password'
                    placeholder='password'
                    type='password'
                    value={this.state.old_password}
                    onChange={(e) => this.setState({old_password: e.target.value})}
                  />
                  <br/>
                  <TextField
                    className={classes.textField}
                    label='New Password'
                    placeholder='new_password'
                    type='password'
                    value={this.state.new_password}
                    style={{
                      background: !this.state.passwords_matches ? red[500] : null,
                      color: !this.state.passwords_matches ? 'white' : null
                    }}
                    InputProps={{
                      style: {
                        color: !this.state.passwords_matches ? 'white' : null
                      }
                    }}
                    floatingLabelFocusStyle={{
                      color: !this.state.passwords_matches ? 'white' : null
                    }}
                    onChange={(e) => this.setState({new_password: e.target.value})}
                  />
                  <br/>
                  <TextField
                    className={classes.textField}
                    label='Confirm New Password'
                    placeholder='new_password'
                    type='password'
                    value={this.state.confirm_password}
                    style={{
                      background: !this.state.passwords_matches ? red[500] : null,
                      color: !this.state.passwords_matches ? 'white' : null
                    }}
                    InputProps={{
                      style: {
                        color: !this.state.passwords_matches ? 'white' : null
                      }
                    }}
                    onChange={(e) => this.setState({confirm_password: e.target.value, passwords_matches: this.state.new_password.match(e.target.value)})}
                  />

                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeDialogChangePassword} style={{color: orange[500]}}>
                  Cancel
                </Button>
                  <Button
                    onClick={this.changePassword.bind(this)}
                    style={{color: orange[500]}}
                    autoFocus
                  >
                    Save
                  </Button>
              </DialogActions>
            </Dialog>

          <PageHeaderComponentBase
            modules={modules}
            module={Module}
            submodules={Submodules}
            subModuleSelected={this.props.subModuleSelected}
            goToPage={this.props.goToPage}
            Navigation={Navigation}
            edit_mode={this.props.edit_mode}
            FromModule={FromModule}
          />
        </React.Fragment>
      :
        <h1>{'LOADING...'}</h1>
    )
  }
}

HeaderComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = ({Navigation}) => ({Navigation});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout,
  destroy,
  moduleSelected,
  goToPage,
  subModuleSelected,
  setFromPage,
  CognitoChangePassword
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderComponent))
