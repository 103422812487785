export const CUSTOM_AXIS_WTG = 'jkdflkdjhflasjfdhlajsdhflaskjdfh'
export const CUSTOM_AXIS_DATETIME = 'sudnfiusdfiusbdfisubdf'
export const CUSTOM_AXIS_TIME = 'sdhflsjdfhlaksjfhd'
export const CUSTOM_AXIS_DATE = 'usdfbydfh8sdhf8sdhf8s7dhf'
export const CUSTOM_AXIS_VARIABLE = 'lsiudhfoiusdhofshdfo87dhf9s87dfh'
export const CUSTOM_AXIS_SERIES = 'vtgyhuhgt6y7hygt6y7uhygy67uhybgy7uhgyhu'

export default [
  {
    name: 'Series',
    type: CUSTOM_AXIS_SERIES
  },
  {
    name: 'Devices',
    type: CUSTOM_AXIS_WTG
  },
  {
    name: 'Date',
    type: CUSTOM_AXIS_DATETIME
  },
  {
    name: 'Variable',
    type: CUSTOM_AXIS_VARIABLE
  }
]
