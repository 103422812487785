import React, {Component} from 'react'

class ReportsTemplatesPage extends Component {
  render () {
    return (
      <div>
        ERROR TEMPLATE
      </div>
    )
  }
}

export default ReportsTemplatesPage
