import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {withStyles} from '@material-ui/core'
import * as R from 'ramda'
import moment from 'moment-timezone'

import graphTypes from '../../constants/graph-types';
import { FILTER_TYPE_PARKS, FILTER_TYPE_SUBPARKS, FILTER_TYPE_WTGS, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_ENVELOPES } from '../../constants/filter-types';
import FilterParks from './FilterParks'
import FilterSubparks from './FilterSubparks'
import FilterWtgs from './FilterWtgs'
import FilterDateRanger from './FilterDateRanger'
import FilterEnvelope from './FilterEnvelope'
import { setFilterLocalInWidget } from '../../actions/WidgetPageActions';

const styles = theme => ({
  filterContent : {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  filterItem : {

  }
})

class FilterContent extends Component {
  changeValueFilter = (type, item) => {
    const {selectedPage, widget, module_id, reducer_name} = this.props
    const {filter: filterSelectedPage} = selectedPage
    const {filter: filterWidget} = widget




    if (type === FILTER_TYPE_DATE_RANGE) {


      if (item['filterDateRange']) {
        const startDate = moment(item['filterDateRange'].startDate)
        const endDate = moment(item['filterDateRange'].endDate)

        const filter = {
          data_range: {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
          },
          devices: filterWidget.devices.length > 0
            ? filterWidget.devices
            : filterSelectedPage.devices.length > 0
              ? filterSelectedPage.devices
              : []
        }



        this.props.setFilterLocalInWidget(module_id, selectedPage.id, widget.id, filter, reducer_name)
      
          this.forceUpdate();
      }
    }

    const startDate = filterWidget.data_range.startDate
      ? moment(filterWidget.data_range.startDate, 'YYYY-MM-DD')
      : filterSelectedPage.data_range.startDate
        ? moment(filterSelectedPage.data_range.startDate, 'YYYY-MM-DD')
        : moment().subtract(3, 'days')

    const endDate = filterWidget.data_range.endDate
      ? moment(filterWidget.data_range.endDate, 'YYYY-MM-DD')
      : filterSelectedPage.data_range.endDate
        ? moment(filterSelectedPage.data_range.endDate, 'YYYY-MM-DD')
        : moment().subtract(3, 'days')

    if (type === FILTER_TYPE_SUBPARKS) {
      const filter = {
        data_range: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD')
        },
        devices: item
      }

      this.props.setFilterLocalInWidget(module_id, selectedPage.id, widget.id, filter, reducer_name)
    }
  }

  render () {
    const {classes, selectedPage, widget, parks_info} = this.props

    const graphicType = R.find(R.propEq('type', widget.type))(graphTypes)
    const {filters} = graphicType

    return (
      <div className={classes.filterContent}>
        {filters? filters.map((filter, indexFilter) => {
          if (filter === FILTER_TYPE_PARKS) {
            if (R.contains(FILTER_TYPE_SUBPARKS, filters)) {
              return ''
            }

            if (R.contains(FILTER_TYPE_WTGS, filters)) {
              return ''
            }

            return (
              <FilterParks
                key={indexFilter}
                parks_info={parks_info}
                widget={widget}
                selectedPage={selectedPage}
                changeValueFilter={this.changeValueFilter.bind(this)}
              />
            )
          }

          if (filter === FILTER_TYPE_SUBPARKS) {
            if (R.contains(FILTER_TYPE_WTGS, filters)) {
              return ''
            }

            return (
              <FilterSubparks
                key={indexFilter}
                parks_info={parks_info}
                widget={widget}
                selectedPage={selectedPage}
                changeValueFilter={this.changeValueFilter.bind(this)}
              />
            )
          }

          if (filter === FILTER_TYPE_WTGS) {
            return (
              <FilterWtgs
                key={indexFilter}
                parks_info={parks_info}
                widget={widget}
                selectedPage={selectedPage}
                changeValueFilter={this.changeValueFilter.bind(this)}
              />
            )
          }

          if (filter === FILTER_TYPE_DATE_RANGE) {
            return (
              <FilterDateRanger
                key={indexFilter}
                widget={widget}
                selectedPage={selectedPage}
                changeValueFilter={this.changeValueFilter.bind(this)}
              />
            )
          }

          if (filter === FILTER_TYPE_ENVELOPES) {
            return (
              <FilterEnvelope
                key={indexFilter}
                changeValueFilter={this.changeValueFilter.bind(this)}
              />
            )
          }

          return (
            <div key={indexFilter} className={classes.filterItem} >
              {filter}
            </div>
          )
        }): ''}
      </div>
    )
  }
}

const mapStateToProps = ({User}) => {
  const {parks_info} = User

  return {parks_info}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({setFilterLocalInWidget}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FilterContent))
