import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@material-ui/core';


const View = props => {
    const {
        iconName,
        title,
        message
    } = props;

    const { t } = useTranslation();


    return (
        <div
            style={
                {
                    height: 'inherit',
                    width: '100%',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                }
            }
        >
            <Icon
                style={
                    {
                        fontSize: '25em',
                        color: '#dbdbdb'
                    }
                }
            >
                {iconName}
            </Icon>
            <h3>{t(title)}</h3>
            <p>{t(message)}</p>
        </div>
    );
}

export default View;