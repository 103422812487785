import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { URL_API } from '../../../../../../constants/url-constant';

const getUserToken = () => (new Promise((resolve, reject) => {
    Auth.currentSession()
        .then(result => {
            const { accessToken } = result;
            const { jwtToken } = accessToken;

            resolve(jwtToken);
        })
        .catch(reject);
}));


export default props => {

    const { modelId, fileRef, edit } = props || {};

    const [src, setSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [broken, setBroken] = useState(false);

    const updateSrc = (src, file) => {
        setSrc(src);
        if (fileRef) {
            fileRef.current = file ? file : src;
        }
    };

    const onChange = e => {
        if (e && e.target && e.target.files[0]) {
            const file = e.target.files[0];

            updateSrc(URL.createObjectURL(file), file);
        }
    };

    useEffect(() => {
        if (modelId || modelId === 0) {
            setLoading(true);

            getUserToken()
                .then(async token => {
                    await axios.get(`${URL_API}/inventory/download_image`, {
                        params: { id: modelId },
                        headers: { 'Authorization': token }
                    })
                        .then(({ data }) => {
                            const { result, return: state } = data || {};

                            if (state) {
                                updateSrc(result);
                            } else {
                                updateSrc(null);
                            }
                        })
                        .catch(() => { setBroken(true) });
                })
                .catch(() => { setBroken(true) })
                .finally(() => { setLoading(false) });
        }
    }, [modelId]);


    return {
        src,
        onChange,
        loading,
        broken,
        edit
    };
};