import styled from 'styled-components'

import { ROUTE_MAIN_PCH_MONITOR} from '../../constants/route-constant';

import logoBlack from '../../assets/logo_black.png'
import logoWhite from '../../assets/logo.png'

const LogoContent = styled.div``
const Logo = styled.div`
  width: 120px;
  height: 35px;
  background-image: url(${logoBlack});
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
`

export {LogoContent, Logo}
