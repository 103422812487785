import React from 'react';
import {
    makeStyles,
    Icon,
    Tooltip,
    DialogContentText,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    spaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

export default ({ title, tooltip, children, style }) => {
    const { spaceBetween } = useStyles();

    return (
        <DialogContentText style={style}>
            <span className={spaceBetween}>
                {title}
                {
                    tooltip ?
                        <Tooltip title={tooltip} placement='left' interactive>
                            <Icon style={{ fontSize: 16 }}>
                                help
                            </Icon>
                        </Tooltip>
                        :
                        ''
                }
            </span>
            {children}
        </DialogContentText>
    );
};