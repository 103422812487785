import React, { Component } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import * as HighchartsMore from 'highcharts/js/highcharts-more';
import moment from "moment-timezone";
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List
} from '@material-ui/core';
import styles from './styles/styles.js';
import NoPrint from '../../../components/Print/NoPrint';

HighchartsMore(Highcharts);


class GraphicScoreTimeline extends Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      colors: [
        '#bdbdbd',
        '#767676',
        '#C0D2FC',
        '#33cc33',
        '#E62C20',
        '#4C65A7',
        '#B414DC',
        '#FCDC34',
        '#FC9018',
        '#0A70D2'
      ]
    }

    this.chartWillRender = this.chartWillRender.bind(this);
  }

  componentDidMount() {
    this.props.getSettings(this.getSettings.bind(this));

    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }

  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = checkbox => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);
  };

  caulculateFontSizeHead(grid) {
    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }
    if (grid.w < 6) {
      return "10px";
    }
    if (grid.w < 8) {
      return "11px";
    }
    return "12px";
  }

  chartWillRender() { }
  getSettings() { }


  render() {
    const { classes, showTable, showSettings, graphic } = this.props;
    const { data } = graphic;
    const xAxisIsDate = data && data[0] && data[0].x_axis && moment(data[0].x_axis[0]).isValid();

    var on_render_state = {
      ...this.state,
      data: data
    };

    if (!on_render_state.data) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          Error in API request
        </div>
      )
    }

    this.chartWillRender(on_render_state);

    const series_by_axis = [].concat.apply([],
      on_render_state.data.map((axis, index) => (
        axis.series.map(series => (
          {
            ...series,
            yAxis: index,
            data: xAxisIsDate ?
              series.values.map((value, i) =>
                [moment(axis.x_axis[i]).valueOf(), value])
              :
              series.values,
            yAxis: index
          }
        ))
      ))
    );

    const pre_proportions = graphic.proportions ? graphic.proportions : [2, 4, 2];
    const proportion_sum = pre_proportions.reduce((acc, curr) => acc + curr);
    const proportions = pre_proportions.map(proportion => proportion * 100 / proportion_sum);
    let acc = 0;
    const acc_proportions = proportions.map(proportion => {
      acc = acc + proportion;
      return acc;
    });

    const yAxis = proportions.map((proportion, index) => {
      switch (index) {
        case 0:
          return {
            labels: {
              align: 'right',
              x: -10
            },
            title: {
              text: `${on_render_state.data[index].y_name} (${on_render_state.data[index].y_unit})`,
            },
            top: '0%',
            height: `${proportion - 2}%`,
            lineWidth: 2,
            offset: 0
          };
        default:
          return {
            labels: {
              align: 'right',
              x: -10
            },
            title: {
              text: `${on_render_state.data[index].y_name} (${on_render_state.data[index].y_unit})`,
            },
            top: `${acc_proportions[index - 1]}%`,
            height: `${proportion - 2}%`,
            offset: 0,
            lineWidth: 2
          };
      }
    })

    const params = {
      colors: on_render_state.colors,

      credits: {
        enabled: false
      },

      rangeSelector: {
        selected: 1,
        buttons: [],
        enabled: false,
        inputEnabled: false
      },

      title: {
        text: ''
      },

      yAxis: yAxis,

      exporting: {
        enabled: true
      },

      tooltip: {
        split: true,
        valueDecimals: 2
      },

      series: series_by_axis,

      time: {
        timezone: 'America/Fortaleza'
      }
    };

    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable || showSettings ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront} style={{ display: showTable || showSettings ? 'none' : undefined }}>
            <HighchartsReact
              containerProps={{
                style: {
                  width: '100%',
                  height: '100%',
                  marginTop: 30,
                  overflow: 'hidden'
                }
              }}
              ref={this.refChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={params}
            />
          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable || showSettings ? undefined : 'none' }}>
            {
              showTable ?
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={1} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}  ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  </TableBody>
                </Table>
                :
                showSettings ?
                  <List>
                    {
                    }
                  </List>
                  :
                  <NoPrint>
                    <h1 style={{
                      width: '100%',
                      height: 'auto',
                      textAlign: 'center'
                    }}>Returning to Chart...</h1>
                  </NoPrint>
            }
          </div>
        </div>
      </div>
    );
  };
};

export default withStyles(styles)(GraphicScoreTimeline);
