import React from 'react'
import io from 'socket.io-client'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'

import { URL_WEBSOCKET_API } from '../../../../../../constants/url-constant'
import OPCPortfolioList from './components/OPCPortfolioList/OPCPortfolioList';

import { startRefreshTokenLoop } from '../../services';


const replicateProperty = (object, property, newProperty) => (
  { ...object, [newProperty]: object[property] }
)

const copyRealtimeV2ToRealtime = parks => (
  parks.map(park => {
    return {
      ...park,
      subparks: park.subparks.map(subpark => {
        return {
          ...subpark,
          wtgs: subpark.wtgs.map(
            wtg => replicateProperty(wtg, 'realtime_v2', 'realtime')
          )
        }
      })
    }
  })
)


class OPCPorfolioPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      socket: null,
      data: [],
      items: []
    }
  }

  componentWillUnmount() {
    if (this.state.socket) {
      this.state.socket.disconnect()
      this.setState({ socket: null })
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    Auth.currentSession()
      .then(({ accessToken }) => {
        const { jwtToken } = accessToken

        const url = `${URL_WEBSOCKET_API}/opc-coletor`

        const socket = io(url, {
          query: {
            token: jwtToken
          }
        })

        this.setState({ socket })

        socket.on('connect', (data) => console.log('connect', data))
        socket.on('connect_error', data => console.log('connect_error', data))
        socket.on('connect_timeout', data => console.log('connect_timeout', data))
        socket.on('connecting', data => console.log('connecting', data))
        socket.on('disconnect', data => console.log('disconnect', data))
        socket.on('error', data => console.log('error', data))
        socket.on('reconnect', data => console.log('reconnect', data))
        socket.on('reconnect_attempt', async data => {
          try {
            const { accessToken: accessTokenRec } = await Auth.currentSession()
            const { jwtToken: jwtTokenRec } = accessTokenRec

            socket.io.opts.query = { token: jwtTokenRec }
          } catch (error) {
            console.error('ERROR AUTH', error)

            toast.error('Login again!', {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            })
          }
        })
        socket.on('reconnect_failed', data => console.log('reconnect_failed', data))
        socket.on('reconnect_error', data => console.log('reconnect_error', data))
        socket.on('reconnecting', data => console.log('reconnecting', data))
        socket.on('ping', data => console.log('ping', data))
        socket.on('pong', data => console.log('pong', data))

        socket.on('devices_v2', parks => {
          const parksWithRealtime = copyRealtimeV2ToRealtime(parks);
          this.setState({ items: parksWithRealtime });
        });

      })
      .catch(error => {
        this.setState({ loading: false })
        console.error("SOCKET CONN ERROR", error)
      })

    startRefreshTokenLoop(1000 * 60 * 15);
  }

  render() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          color: 'white',
          fontSize: 12
        }}
      >
        <OPCPortfolioList
          data={this.state.data}
          items={this.state.items}
        />
      </div>
    )
  }
}

export default OPCPorfolioPage
