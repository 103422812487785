import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import moment from "moment-timezone";

import {
  getMovCompProvider,
  executeServProvider,
  moveComponentServProvider
} from "../../../../../../../../providers/asset-provider";
import {
  withStyles,
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  Button,
  Input,
  IconButton,
  Tooltip,
  TextField
} from "@material-ui/core";

import Select2 from "../../../../../../../../components/Menu/Select";
import DatePicker from "react-datepicker";
import PageIcon from "@material-ui/icons/NoteAdd";
import Send from "@material-ui/icons/Send";
import { toast } from "react-toastify";

const styles = theme => ({
  inputPageTitle: {
    fontSize: 16,
    marginRight: "20px",
    minWidth: "100%",
    "&:focus": {
      outline: 0
    }
  },
  widgetNew: {
    backgroundColor: "#ededed",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer"
    }
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 320,
    width: "100%"
  }
});

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

class MovementComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      assets_component_ids: null,
      storage_ids: null,
      component_ids: null,
      dialogMoveComponent: false,
      selectedStorageId: null,
      selectedComponentId: null,
      selectedAssetComponentId: null
    };
  }

  saveComponentService = () => {
    if (
      this.state.start_date == null ||
      this.state.selectedComponentId == null
    ) {
      toast.error("Please, fill requesteds fields.", {
        position: "top-right",
        // autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
        // draggable: true
      });

      return;
    }

    this.setState({ loading: true });

    moveComponentServProvider(
      this.state.start_date,
      this.state.selectedComponentId,
      this.state.selectedStorageId,
      this.state.selectedAssetComponentId
    )
      .then(data => {
        this.setState({
          dialogMoveComponent: false
        });

        getMovCompProvider()
          .then(data => {
            this.setState({
              data,
              loading: false
            });

            toast.success("Service executed with success!!!", {
              position: "top-right",
              // autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
              // draggable: true
            });
          })
          .catch(error => {
            this.setState({ loading: false });
            console.error("getMovCompProvider", error);
          });
      })
      .catch(error => {
        this.setState({ dialogSendService: false, loading: false });
        toast.error("Failed on execute service!!!", {
          position: "top-right",
          // autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
          // draggable: true
        });
      });
  };

  mapPropsToSelect = data => {
    let values = data.map(item => {
      let value = {};
      value["label"] = item.name
        ? item.name
        : item.component_model
        ? item.component_model.name
        : item.asset_model_component
        ? `${item.asset_model_component.name} > ${item.asset.name}`
        : null;
      value["id"] = item.id;
      return value;
    });

    return values;
  };

  cancelMoveComponent = () => {
    this.setState({
      dialogMoveComponent: false
    });
  };

  moveComponent = () => {
    this.setState({
      dialogMoveComponent: true
    });
  };

  changeValue = event => {
    this.setState({
      supplier: event.target.value
    });
  };

  changeValueSelectStorage = value => {
    this.setState({
      selectedStorageId: value
    });
  };

  changeValueSelectAssetComponent = value => {
    this.setState({
      selectedAssetComponentId: value
    });
  };

  changeValueSelectComponent = value => {
    this.setState({
      selectedComponentId: value
    });
  };

  handleChangeDate = e => {
    this.setState({ start_date: e });
  };

  componentDidMount() {
    this.setState({ loading: true });

    getMovCompProvider()
      .then(data => {
        this.setState({
          data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        console.error("getMovCompProvider", error);
      });
  }

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress />
          <span
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </span>
        </div>
      );
    }

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          overflow: "auto"
        }}
      >
        <TableStyled>
          <thead>
            <th>Date</th>
            <th>Storage</th>
            <th>Action name</th>
            <th>Product name</th>
            <th>Part number</th>
            <th>Serial number</th>
            <th>Manufacturer</th>
            <th>Supplier</th>
            <th>User</th>
          </thead>
          <tbody>
            {this.state.data.map((item, indexItem) => {
              return (
                <tr key={indexItem}>
                  <td>
                    {moment(item.log.log_time).format("YYYY-MM-DD HH:mm")}
                  </td>
                  <td>{item.storage.name}</td>
                  <td>{item.log.procedure}</td>
                  <td>{item.component.component_model.name}</td>
                  <td>{item.component.component_model.part_number}</td>
                  <td>{item.component.serial_number}</td>
                  <td>{item.component.component_model.manufacturer}</td>
                  <td>{item.component.supplier}</td>
                  <td>{item.log.user_hash}</td>
                </tr>
              );
            })}
          </tbody>
        </TableStyled>

        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px"
          }}
        >
          <Tooltip title="Move Component" placement="left">
            <Button
              variant="fab"
              color="primary"
              aria-label="Add"
              onClick={this.moveComponent.bind(this)}
            >
              <Send />
            </Button>
          </Tooltip>
        </div>

        <Dialog
          open={this.state.dialogMoveComponent}
          aria-labelledby="dialog_widget_new"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="confirmation-dialog-title">
            Move Component
          </DialogTitle>
          <DialogContent>
            <form className={classes.container} noValidate>
              <FormControl className={classes.formControl}>
                <Select2
                  options={this.mapPropsToSelect(this.props.storage_ids)}
                  label="Storage ID"
                  value={this.state.selectedWidget}
                  onChange={this.changeValueSelectStorage.bind(this)}
                  height={30}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <Select2
                  options={this.mapPropsToSelect(this.props.component_ids)}
                  label="Component ID"
                  value={this.state.selectedWidget}
                  onChange={this.changeValueSelectComponent.bind(this)}
                  height={30}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <Select2
                  options={this.mapPropsToSelect(
                    this.props.assets_component_ids
                  )}
                  label="Asset Component ID"
                  value={this.state.selectedWidget}
                  onChange={this.changeValueSelectAssetComponent.bind(this)}
                  height={30}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <DatePicker
                  customInput={
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="widget-title-new">Date</InputLabel>
                      <Input
                        id="widget-title-new"
                        value={
                          this.state.start_date
                            ? moment(this.state.start_date).format(
                                "YYYY-MM-DD HH:mm"
                              )
                            : "Select Date"
                        }
                      />
                    </FormControl>
                  }
                  dateFormat="MMMM d, yyyy"
                  selected={this.state.start_date}
                  onChange={this.handleChangeDate}
                  maxDate={new Date()}
                />
              </FormControl>
            </form>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.cancelMoveComponent}>
              Cancel
            </Button>
            <Button
              onClick={this.saveComponentService}
              style={{ backgroundColor: "#e65100", color: "#fff" }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(MovementComponent);
