import React, {Component} from 'react';
import * as R from 'ramda';

const SeriesColorTreatment = (colors={
    bySerie: {
      Average: ['#FC9018', '#D47C35'],
      Others: ['#4C4C4C', '#04040C']
    }
  }) => {
  const real_SeriesColorTreatment = state => {
    state.data_without_colors = JSON.parse(JSON.stringify(state.data));

    try {
      if('bySerie' in colors && colors.bySerie) {
        state.data.series = state.data.series.map(series => {
          const rawSeriesColor = colors.bySerie[series.name];
          const seriesColor = rawSeriesColor && rawSeriesColor.constructor === Array ? 
            rawSeriesColor 
          :
            [rawSeriesColor];
          
          return {
            ...series,
            colors: [
              ...seriesColor, 
              ...(Array(Math.abs(seriesColor.length - series.values.length)).fill(seriesColor[seriesColor.length - 1]))
            ]
          }
        });
      }
    } catch (e) {
      state.data = JSON.parse(JSON.stringify(state.data_without_colors));
      delete state.data_without_colors;
    } finally {
      return state;
    }
  }

  return(real_SeriesColorTreatment);
};

export default SeriesColorTreatment;
