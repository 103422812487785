import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Auth} from 'aws-amplify'
import {toast} from 'react-toastify'
import * as R from 'ramda'

import styles, { LoginContent, LoginForm, LoginFormInputs } from './LoginStyle'
import {login} from '../../../../actions/AuthAction'
import { parks_info } from '../../../../constants/graph-types'
import { getDataGraphOldProvider } from '../../../../providers/get-data-old-provider'
import {store} from '../../../../store/ProviderStore'
import { REDUCER_AUTH_LOGIN, REDUCER_AUTH_LOGOUT } from '../../../../constants/reducers/auth-action-events'
import { getDynamoClientMeta } from '../../../../providers/dynamo-client-meta-provider'

import DelfosImg from '../../../../static-images/delfos_Intelligent_maintenance.png'
import CircularLoading from '../../../../components/Loading/CircularLoading';

import LoginView from './LoginView';

import { getCurrentUserFromStore } from '../../../../helpers/users-helper';

// import { Authenticator, SignIn } from 'aws-amplify-react';

// const forceSignOut = () => (Auth.signOut());

class LoginComponent extends Component {
    constructor(props) {
        super(props);
    }

    formSubmit = ({ username, password }) => {
        const { login } = this.props;
        login(username, password);
    };

    componentDidUpdate() {
        const { errorCode, errorMessage, isAuthenticated } = this.props;
        
        if(errorCode || errorMessage) {
            console.log('Is authenticated ', isAuthenticated)
            toast.error(`Login Error: ${errorMessage}`, {
                position: 'top-right',
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true
            });
        }
    }

    render() {
        const { errorCode, errorMessage } = this.props;

        return (
            <LoginView 
                formSubmit={this.formSubmit}
                hasError={errorCode || errorMessage}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = ({ AuthedUser, AuthError }) => {
    console.log({ AuthedUser, AuthError })
    return ({
        isAuthenticated: AuthedUser != null,
        errorCode: AuthError && AuthError.code || AuthError,
        errorMessage: AuthError && AuthError.message || AuthError
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    login
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
