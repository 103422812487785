import React from 'react'
import {withStyles, CircularProgress, Paper, TextField, Button} from '@material-ui/core'
import {Send} from '@material-ui/icons'
import {grey} from '@material-ui/core/colors'

import { getConsumableModelByIdProvider, createConsumableModelProvider, updateConsumableModelProvider } from '../../../../../../../../../../providers/asset-provider'
import { toast } from 'react-toastify';

const PADDING_PAGE = 60

const styles = {
  paper: {
    width: `calc(100% - ${(PADDING_PAGE * 2)}px)`,
    padding: 20
  }
}

class ConsumableChangePage extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      loading: false,
      id: null,
      name: '',
      part_number: '',
      unit: '',
      manufacturer: '',
    }
  }

  componentWillMount () {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.setState({
        loading: true
      })
  
      getConsumableModelByIdProvider(this.props.match.params.id)
        .then(item => {
          this.setState({
            loading: false,
            id: item.id,
            name: item.name,
            part_number: item.part_number,
            unit: item.unit,
            manufacturer: item.manufacturer,
          })
        })
        .catch(error => {
          console.error('error', error)
  
          this.setState({
            loading: false
          })
        })
    } else {
      this.setState({
        id: null,
        name: '',
        part_number: '',
        unit: '',
        manufacturer: ''
      })
    }
  }

  render () {
    const {classes} = this.props

    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: grey['100']
            backgroundColor: 'white'
          }}
        >
          <CircularProgress />
          <div
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          padding: PADDING_PAGE,
          backgroundColor: grey['100']
        }}
      >
        <Paper
          className={classes.paper}
          elevation={1}
        >
          <form
            onSubmit={e => {
              e.preventDefault()

              if (this.state.name && this.state.part_number && this.state.unit && this.state.manufacturer) {
                this.setState({loading: true})

                if (this.state.id) {
                  updateConsumableModelProvider({
                    id: this.state.id,
                    name: this.state.name,
                    part_number: this.state.part_number,
                    unit: this.state.unit,
                    manufacturer: this.state.manufacturer
                  })
                    .then(() => {
                      this.setState({
                        loading: false,
                        // id: '',
                        // name: '',
                        // manufacturer: '',
                      })
  
                      toast.success('Success!', {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      })
                    })
                    .catch(error => {
                      console.error('updateConsumableModelProvider', error)
                      this.setState({
                        loading: false
                      })
                      toast.error('Save error', {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      })
                    })
                } else {
                  createConsumableModelProvider({
                    name: this.state.name,
                    part_number: this.state.part_number,
                    unit: this.state.unit,
                    manufacturer: this.state.manufacturer
                  })
                    .then(() => {
                      this.setState({
                        loading: false,
                        name: '',
                        part_number: '',
                        unit: '',
                        manufacturer: '',
                      })
  
                      toast.success('Success!', {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      })
                    })
                    .catch(error => {
                      console.error('createConsumableModelProvider', error)
                      this.setState({
                        loading: false
                      })
  
                      toast.error('Save error', {
                        position: "top-right",
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                      })
                    })
                }
              } else {
                toast.error('Invalid params', {
                  position: "top-right",
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true
                })
              }
            }}
          >
            <TextField
              label="Name"
              className={classes.textField}
              value={this.state.name}
              onChange={e => this.setState({name: e.target.value})}
              style={{
                width: '100%'
              }}
            />

            <TextField
              label="Partnumber"
              className={classes.textField}
              value={this.state.part_number}
              onChange={e => this.setState({part_number: e.target.value})}
              style={{
                width: '100%',
                marginTop: 10
              }}
            />

            <TextField
              label="Partnumber"
              className={classes.textField}
              value={this.state.unit}
              onChange={e => this.setState({unit: e.target.value})}
              style={{
                width: '100%',
                marginTop: 10
              }}
            />

            <TextField
              label="Manufacturer"
              className={classes.textField}
              value={this.state.manufacturer}
              onChange={e => this.setState({manufacturer: e.target.value})}
              style={{
                width: '100%',
                marginTop: 10
              }}
            />

            <div
              style={{
                position: 'fixed',
                right: 20,
                bottom: 10
              }}
            >
              <Button
                variant='fab'
                color="primary"
                type='submit'
              >
                <Send />
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(ConsumableChangePage)
