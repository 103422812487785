import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import ScadaList from './ScadaList';

import CircularLoading from '../../../../../components/Loading/CircularLoading';
import { extractDevicesScadaParksInfo } from '../../../../../helpers/parks-info-helper';

class ScadaContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            fullScadaList: null,
            scadaIdsByDevice: null
        }
    }

    scadaListFromParksInfo = () => {
        const { parksInfo } = this.props;

        if(parksInfo.length > 0) {
            const devicesScada = extractDevicesScadaParksInfo(parksInfo);
            
            const fullScadaList = Object.values(devicesScada).map(device => device.parentPark.scada)
                .reduce((acc, curr) => ({...acc, ...curr}));

            const scadaIdsByDevice = Object.values(devicesScada).map(device => ({
                [device.id]: Object.keys(device.scada).map(key => Number(key))
            })).reduce((acc, curr) => ({...acc, ...curr}));

            return new Promise((resolve, reject) => resolve({
                loading: false,
                fullScadaList,
                scadaIdsByDevice
            }));
        } else {
            return new Promise((resolve, reject) => reject('parks info length 0'));
        }
    }

    async componentDidMount() {
        const { fullScadaList } = this.state;
        
        try {
            if(!fullScadaList) {
                try {
                    this.setState(await Promise.resolve(this.scadaListFromParksInfo()));
                } catch(err) {
                    console.log('On scada page did mount Error ', err);
                }
            }
        } catch(err) {
            console.log('On Mounting Scada Container', err);

            this.setState({
                loading: true
            });
        }
    }

    async componentDidUpdate() {
        const { loading, fullScadaList } = this.state;

        if(loading) {
            setTimeout(() => (
                Promise.resolve(this.scadaListFromParksInfo())
                    .then(newState => this.setState(newState))
            ), 800);
        }
    }

    render () {
        const { loading, fullScadaList } = this.state;

        return (
            !loading ? (
                <ScadaList {...this.props} {...this.state} />
            ) : (
                <CircularLoading notOverlay={true}>
                    <span>Please wait while we are scanning your park.</span>
                </CircularLoading>
            )
        );
    }
}

const mapStateToProps = ({User}) => ({
    parksInfo: User.parks_info
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
    }
    , dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(ScadaContainer);
  