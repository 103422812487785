import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

const styles = () => ({
  pageItem: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  multilineIcon: {
    color: grey['400'],
    fontSize: 150
  }
})

class ReportsSidebarPagesComponent extends Component {
  render () {
    const {classes, pages} = this.props

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          paddingTop: '10px',
          overflow: 'auto'
        }}
      >
        {pages.map((page, pageIndex) => {
            return (
              <div
                key={page.id}
                style={{
                  marginTop: pageIndex !== 0 ? '10px' : 0,
                  backgroundColor: page.selected ? 'rgba(255, 169, 6, .6)' : 'transparent'
                }}
                onClick={() => this.props.goSelectPage(page.id)}
                className={classes.pageItem}
              >
                <div
                  style={{
                    border: `1px solid ${grey['200']}`,
                    width: 'calc(100% - 20px)',
                    margin: '0 auto',
                    height: 250,
                    backgroundColor: 'rgb(255,255,255)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '30px'
                  }}
                >
                  {pageIndex + 1}
                </div>
              </div>
            )
          })}

          <div
            style={{
              marginTop: '10px'
            }}
            onClick={() => this.props.newPage()}
            className={classes.pageItem}
          >
            <div
              style={{
                border: `1px solid ${grey['200']}`,
                width: 'calc(100% - 20px)',
                margin: '0 auto',
                height: 200,
                backgroundColor: grey['100'],
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '10px'
              }}
            >
              <AddIcon
                className={classes.multilineIcon}
              />
            </div>

          </div>
          {pages.length > 1 ?
          <div
            style={{
              marginTop: '10px'
            }}
            onClick={() => this.props.deletePage()}
            className={classes.pageItem}
          >

            <div
              style={{
                border: `1px solid ${grey['200']}`,
                width: 'calc(100% - 20px)',
                margin: '0 auto',
                height: 200,
                backgroundColor: grey['100'],
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '30px'
              }}
            >
              <DeleteIcon
                className={classes.multilineIcon}
              />
            </div>

          </div>
           : null } 
      </div>
    )
  }
}

export default withStyles(styles)(ReportsSidebarPagesComponent)
