import React from 'react';
import Button from '@material-ui/core/Button';

import {
    ACTION_ICONS
} from './placeholder-data/FixedDataFromAPI';
// } from './placeholder-data/TablePlaceholderData';

import { DaypickerContext } from './Contexts';

const DayButton = () => (
    <DaypickerContext.Consumer>
        { 
            provider => {
                return (
                    <Button 
                        onClick={provider.handleToggle}
                        disabled={provider.disabled}
                    >
                        {provider.day}
                    </Button>
                )
            }
        }
    </DaypickerContext.Consumer>
);

export default DayButton;