import {store} from '../store/ProviderStore'
import * as R from 'ramda'
import GraphTypes, { graphTypesForNewService } from '../constants/graph-types'
import {
  getDataAlarmsProvider
} from '../providers/get-data-old-provider'
import moment from 'moment-timezone'

import { parks_info } from '../constants/graph-types'
import { getActualState } from '../providers/get-data-api-n'

export const retrieveDataAlarmsModule = (module_field, module_id, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []
    const module = R.find(R.propEq('id', module_id))(Module)

    const {filter} = module

    const params = {
      procedure_name: 'get_status_data',
      time_range: [
        filter.data_range.startDate,
        filter.data_range.endDate
      ],
      timezone: "America/Fortaleza",
      subpark_list: new Array(...new Set(filter.devices.map(value => String(value).slice(0, 2)))).map(value => parseInt(value)),
      device_list: filter.devices
    }

    getDataAlarmsProvider('get_status_data', params)
    .then(data =>  {
      dispatch({
        type: reducer_name,
        payload: Module.map(module => {
          if (module_id === module.id) {
            let new_module = module
            new_module.data = data
            new_module.from_redux = true
          }

          return module
        })
      })
    })
    .catch(error => {
      console.error(error)
    })
  }
}

export const mountedAlarmsModule = (module_field, module_id, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []
    const module = R.find(R.propEq('id', module_id))(Module)

    dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        if (module_id === module.id) {
          module.from_redux = false
        }

        return module
      })
    })
  }
}

export const retrieveDataDowntimeModule = (module_field, module_id, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []
    const module = R.find(R.propEq('id', module_id))(Module)

    const {filter} = module

    const params = {
      procedure_name: 'get_downtime_data',
      time_range: [
        filter.data_range.startDate,
        filter.data_range.endDate
      ],
      timezone: "America/Fortaleza",
      subpark_list: new Array(...new Set(filter.devices.map(value => String(value).slice(0, 2)))).map(value => parseInt(value)),
      device_list: filter.devices
    }

    getDataAlarmsProvider('get_downtime_data', params)
    .then(data =>  {
      dispatch({
        type: reducer_name,
        payload: Module.map(module => {
          if (module_id === module.id) {
            let new_module = module
            new_module.data = data
            new_module.from_redux = true
          }

          return module
        })
      })
    })
    .catch(error => {
      console.error('getDataAlarmsProvider', error)
    })
  }
}

export const selectWtgAndEnvelope = (module_field, reducer_name, module_id, subpark_id, device_id, envelope_id) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    const returnModule = Module.map(module => {
      if (module.id === module_id) {
        const {data_range} = module.filter
        const filter = {
          data_range,
          subparks: subpark_id.constructor == Array ? subpark_id : [subpark_id],
          devices: device_id.constructor == Array ? device_id : [device_id],
          envelope_id: envelope_id.constructor == Array ? envelope_id[0] : envelope_id
        }

        return R.assoc('filter', filter, module)
      }

      return module
    })
    .map(module => {
      if (module.id === module_id) {
        const widgets = module.widgets.map(widget => {
          return R.assoc('update', true, widget)
        })

        return R.assoc('widgets', widgets, module)
      }

      return module
    })

    dispatch({
      type: reducer_name,
      payload: returnModule
    })
  }
}


export const setMetaData = (payload) => {
  return dispatch => {
    dispatch({
      type: 'REDUCER_SET_META_DATA',
      payload: payload
    })
  }
}

export const setMetaDataOld = (payload) => {
  return dispatch => {
    dispatch({
      type: 'REDUCER_SET_META_DATA',
      payload: payload
    })
  }
}

export const setLastCheckpoint = (data) => {
  return dispatch => {
    dispatch({
      type: 'REDUCER_CHECKPOINT',
      payload: data
    })
  }
}

export const retrieveLastCheckpoint = () => {
  return dispatch => {
    return getActualState()
      .then(resultActualState => {
        // console.log('ON RETRIEVE LAST CHECKPOINT', resultActualState)
        const payload = resultActualState && resultActualState.state && Object.keys(resultActualState.state).length > 0 ?
          { checkpoint: resultActualState.state }
        :
          null

        if(!payload) {
          return false
        }

        dispatch({
          type: 'REDUCER_CHECKPOINT',
          payload: payload
        })

        return true
      })
      .catch(error => {
        console.log('ERROR ON RETRIEVE LAST CHECKPOINT', error)
      })
  }
}
