import React, {
    useState,
    useEffect
} from 'react';
import moment from 'moment';

import AggregatedTable from './new-components/AggregatedTable';

import { Button } from '@material-ui/core';

window.moment = moment;

const ComponentsLab = () => {
    const [ props, setProps ] = useState();
    const [ didMount, setDidMount ] = useState(false);

    return(
        <AggregatedTable />
    );
}

export default ComponentsLab;