import React, { Component } from "react";
import Amplify from "aws-amplify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AWS from "aws-sdk";
import { Auth } from "aws-amplify";
import { AppContent } from "./AppStyle";
import ProviderStore from "./store/ProviderStore";
import awsExports from "./config/aws-exports";
import AppRouter from "./AppRouter";
import ReactHighchart from 'react-highcharts';
import HighchartMore from 'highcharts/highcharts-more';

import register, { unregister } from './registerServiceWorker';

import CircularLoading from './components/Loading/CircularLoading';

//import './providers';

HighchartMore(ReactHighchart.Highcharts);

Amplify.configure(awsExports);
AWS.config.region = process.env.REACT_APP_REGION;


class App extends Component {
    constructor(props) {
        super(props);

        this.contentRef = React.createRef();

        this.state = {
            addListener: false,
            // completed: false
            begin: false,
        };
    }

    async componentDidMount() {
        await Promise.resolve(unregister());
        await Promise.resolve(register());
        // await Promise.resolve(this.contentRef.current.addEventListener('scroll', event => {
        //     window.dispatchEvent(new CustomEvent('app-scrolling', {detail: event.target.scrollTop}));
        // }));
        setTimeout(() => (
            this.setState({
                addListener: true,
                begin: true
            })
        ), 500);
    }

    componentDidUpdate() {
        const { addListener } = this.state;

        if (addListener) {
            try {
                this.contentRef.current.addEventListener('scroll', event => {
                    window.dispatchEvent(new CustomEvent('app-scrolling', { detail: event.target.scrollTop }));
                });

                this.setState({
                    addListener: false,
                    begin: true
                });
            } catch (err) {
                console.log('ON APP UPDATE ', err);
                try {
                    document.getElementById('app-content').addEventListener('scroll', event => {
                        window.dispatchEvent(new CustomEvent('app-scrolling', { detail: event.target.scrollTop }));
                    });

                    this.setState({
                        addListener: false,
                        begin: true
                    });
                } catch (err2) {
                    console.log('ON APP UPDATE AFTER TRY TO USE GET ELEMENT BY ID ', err2);
                    setTimeout(() => this.forceUpdate(), 300);
                }
            }

        }
    }

    render() {
        return (
            <ProviderStore
                loading={
                    <CircularLoading>
                        <span>Getting stored data...</span>
                    </CircularLoading>
                }>
                <AppContent innerRef={this.contentRef} >
                    <AppRouter />
                </AppContent>
                <ToastContainer style={{ zIndex: 9999999 }} />
            </ProviderStore>
        );
    }
}

export default App;