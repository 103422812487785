export const REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA = 'REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA'
export const REDUCER_DELFOS_1_0_MANAGEMENT_CLEAR = 'REDUCER_DELFOS_1_0_MANAGEMENT_CLEAR'
export const REDUCER_DELFOS_1_0_OPERATIONAL_GET_DATA = 'REDUCER_DELFOS_1_0_OPERATIONAL_GET_DATA'
export const REDUCER_DELFOS_1_0_OPERATIONAL_CLEAR = 'REDUCER_DELFOS_1_0_OPERATIONAL_CLEAR'
export const REDUCER_DELFOS_1_0_RELIABILITY_GET_DATA = 'REDUCER_DELFOS_1_0_RELIABILITY_GET_DATA'
export const REDUCER_DELFOS_1_0_RELIABILITY_CLEAR = 'REDUCER_DELFOS_1_0_RELIABILITY_CLEAR'
export const REDUCER_DELFOS_1_0_PERFORMANCE_GET_DATA = 'REDUCER_DELFOS_1_0_PERFORMANCE_GET_DATA'
export const REDUCER_DELFOS_1_0_PERFORMANCE_CLEAR = 'REDUCER_DELFOS_1_0_PERFORMANCE_CLEAR'

export const REDUCER_MANAGEMENT_GET_DATA = 'REDUCER_MANAGEMENT_GET_DATA'
export const REDUCER_MANAGEMENT_CLEAR = 'REDUCER_MANAGEMENT_CLEAR'
export const REDUCER_DEVICE_GET_DATA = 'REDUCER_DEVICE_GET_DATA'
export const REDUCER_DEVICE_CLEAR = 'REDUCER_DEVICE_CLEAR'
export const REDUCER_PREDICTION_GET_DATA = 'REDUCER_PREDICTION_GET_DATA'
export const REDUCER_PREDICTION_CLEAR = 'REDUCER_PREDICTION_CLEAR'
export const REDUCER_VIRTUAL_CLONE_GET_DATA = 'REDUCER_VIRTUAL_CLONE_GET_DATA'
export const REDUCER_VIRTUAL_CLONE_CLEAR = 'REDUCER_VIRTUAL_CLONE_CLEAR'

export const REDUCER_HYDRO_MONITOR_GET_DATA = 'REDUCER_HYDRO_MONITOR_GET_DATA'
export const REDUCER_HYDRO_MONITOR_CLEAR = 'REDUCER_HYDRO_MONITOR_CLEAR'