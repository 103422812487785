import styled from 'styled-components'

import { AppFull } from '../../../../AppStyle';
import { STYLE_SUBHEADER_TOOLBAR, STYLE_HEADER_TOOLBAR } from '../../../../constants/style-constant';

const ManagementContent = styled(AppFull)`
  height: inherit;
  /* overflow-y: auto !important; */
`

export {ManagementContent}
