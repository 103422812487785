import React, { useRef } from 'react';
import { Auth } from 'aws-amplify'
import axios from 'axios';
import XLSX from 'xlsx';
import { URL_API } from '../../../../../../../constants/url-constant';
import importMasks from './importMasks';
import { multipleAcquisitions } from '../../../../../../../providers/asset-provider';
import { Icon } from '@material-ui/core';


const getUserToken = () => (new Promise((resolve, reject) => {
    Auth.currentSession()
        .then(result => {
            const { accessToken } = result;
            const { jwtToken } = accessToken;

            resolve(jwtToken);
        })
        .catch(reject);
}));


export default ({ tag, storages = [], stateOptions = {}, options = {}, records = [], saveInfo = () => { } }) => {

    const refData = useRef(null);

    const processFile = file => {

        let formData = new FormData();
        formData.append('file', file);


        return new Promise((resolve, reject) => {
            getUserToken()
                .then(token => {
                    axios
                        .post(
                            `${URL_API}/inventory/${tag}_import_file`,
                            formData,
                            {
                                headers: {
                                    'Authorization': token
                                }
                            }
                        )
                        .then(res => {
                            const { data: preData } = res;
                            const { data } = preData || {};

                            refData.current = preData;
                            resolve(importMasks[tag](data));
                        })
                        .catch(reject);
                })
                .catch(reject);
        });
    };

    const confirmImport = () => new Promise((resolve, reject) => {

        const handleImportError = () => {
            reject('Failed to import file')
        };

        getUserToken()
            .then(token => {
                axios
                    .post(
                        `${URL_API}/inventory/${tag}_import_confirm`,
                        refData.current,
                        {
                            headers: {
                                'content-type': 'application/json',
                                'Authorization': token
                            }
                        }
                    )
                    .then(({ data }) => {
                        const { rows } = data || { rows: 0 };

                        resolve(`Successfully imported ${rows} row(s)!`);
                    })
                    .catch(handleImportError);
            })
            .catch(handleImportError)
            .finally(() => { refData.current = null });
    });

    const fieldFormatterForPreview = (index, head, row) => {
        switch (head) {
            case 'import':
                return typeof row.import === 'boolean' ? (
                    <Icon
                        style={{
                            fontSize: '1.3em',
                            transform: 'translateY(3px)'
                        }}
                    >
                        {
                            (row.import === true && 'check_box') ||
                            (row.import === false && 'check_box_outline_blank') ||
                            'indeterminate_check_box'
                        }
                    </Icon>
                )
                    :
                    row.import;
            case 'Component State':
                return stateOptions[row[head]] ? stateOptions[row[head]].name : 0;
            case 'Entry Stock':
                return storages[row[head]] ? storages[row[head]].name : 0;
            case 'Component Model':
                return options[row[head]] ? options[row[head]].name : 0;
            case 'Associated Documents':
                return row[head].join(',');
            case 'Acquisition Value':
                return row[head] == "" ? 0 : "";

            default:
                return row[head];
        };
    };

    let output = {
        processFile,
        confirmImport,
        fieldFormatterForPreview
    }

    const oldEnerplanProcessFile = file => new Promise((resolve, reject) => {
        const { type } = file;
        const typeTokens = type.split('/');

        const mimeClass = typeTokens[0];
        const mimeSubclassTokens = typeTokens[1].split('.');
        const classType = `${mimeClass}/${mimeSubclassTokens[mimeSubclassTokens.length - 1]}`;

        const filereader = new FileReader();
        filereader.onload = async event => {
            const data = event.target.result;
            let sheet = {};

            switch (classType) {
                case 'application/sheet':
                    const workbook = XLSX.read(data, {
                        type: 'binary'
                    });
                    const sheet_name_list = workbook.SheetNames;
                    sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]);
                    break;
                default:
                    reject();
                    return;
            }

            const toImportRecords = sheet.map(row => {
                const filteredModels = Object.values(options).filter(model => model.tag && model.tag.indexOf(row['Component Model']) >= 0);
                const filteredStorages = Object.values(storages).filter(store => (row['Entry Stock'] === 'DEFAULT' || row['Entry Stock'] === 'DISCARD') || (store.tag && store.tag.indexOf(row['Entry Stock']) >= 0));

                return ({
                    import: true,
                    ...row,
                    ['Component Model']: filteredModels.length > 0 && filteredModels[0].id,
                    ['Entry Stock']: filteredStorages.length > 0 && filteredStorages[0].id,
                    ['Associated Documents']: row['Associated Documents'].split(',')
                });
            });

            resolve(toImportRecords);
        };


        filereader.readAsBinaryString(file);
    });

    const oldEnerplanConfirmImport = () => {
        const headersMap = {
            'Component Model': 'component_model_id',
            'Acquisition Date': 'acquired_date',
            'Product Supplier': 'supplier',
            'Serial Number': 'serial_number',
            'Entry Stock': 'storage_id',
            'Manufacturer': 'manufacturer',
            'Acquisition Value': 'acquisition_value',
            'Received Date': 'received_date',
            'Invoice Number': 'invoice_number',
            'Fabrication Date': 'manufacturing_date',
            'Expiration Date': 'expire_date',
            'Warranty End Date': 'warranty',
            'Associated Documents': 'documents',
            'Component State': 'state',
            'Comments': 'observation',
            'Placement': 'placement'
        };

        const extraInfoHeaders = ['Placement'];

        saveInfo(true);

        const selectedRecords = records.filter(record => (
            record.import
        ));

        const treatedRecords = selectedRecords.map(record => {

            const extra_info = Object.entries(record).map(([key, value]) => (
                headersMap[key] && extraInfoHeaders.find(header => header === key) ?
                    { [headersMap[key]]: value }
                    :
                    {}
            )).reduce((acc, curr) => ({ ...acc, ...curr }));

            return (
                Object.entries(record).map(([key, value]) => (
                    headersMap[key] && !extraInfoHeaders.find(header => header === key) ?
                        { [headersMap[key]]: value }
                        :
                        {}
                )).reduce((acc, curr) => ({ ...acc, ...curr }), { extra_info })
            );
        });

        treatedRecords.map(record => {
            return Object.keys(record).map(key => {

                if (record[key] == "") {
                    record[key] = null
                }

                return record
            })
        })

        return new Promise((resolve, reject) => {
            multipleAcquisitions(treatedRecords)
                .then(resolve(`Successfully imported file!`))
                .catch(({ error }) => { reject(error) });
        });
    };

    if (tag === 'oldEnerplan') {
        output = { ...output, processFile: oldEnerplanProcessFile, confirmImport: oldEnerplanConfirmImport };
    }

    return output;
};