import {Auth} from 'aws-amplify'
import DynamoDB from 'aws-sdk/clients/dynamodb';

export const saveUserPreferences = async (tables) => {
  try {
    const session = await Auth.currentSession()

    const credentials = await Auth.currentUserCredentials()

    const client_id = session.idToken.payload["custom:client"]

    const user_name = session.idToken.payload["cognito:username"]

    const db = new DynamoDB.DocumentClient({
      credentials: Auth.essentialCredentials(credentials),
      apiVersion: '2012-10-17'
    })



   console.log(tables);

    let params = {
        TableName :"user_preferences",
        Item:{
            "id": user_name,
            "data": tables
        }
    };
   
    db.put(params, function(err, data) {
        if (err) {
            console.log(err)
        } else {
            console.log(data)
        }
    });

    return tables
  } catch (error) {
    console.error('dynamo-client-meta-provider', error)
    return false
  }
}


export const getsUserPreferences = async () => {
  try {
    const session = await Auth.currentSession()

    const credentials = await Auth.currentUserCredentials()

    const client_id = session.idToken.payload["custom:client"]

    const user_name = session.idToken.payload["cognito:username"]


    const db = new DynamoDB.DocumentClient({
      credentials: Auth.essentialCredentials(credentials),
      apiVersion: '2012-10-17'
    })

    const  params = {
      TableName : 'user_preferences',
      KeyConditionExpression: "id = :id",
      ExpressionAttributeValues: {
        ":id": user_name
      }
    }

    const result = await db.query(params).promise()
  

    return result
  } catch (error) {
    console.error('dynamo-client-meta-provider', error)
    return false
  }
}
