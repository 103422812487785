import React, {Component} from 'react'
import moment from 'moment-timezone'
import Button from '@material-ui/core/Button'

import filterTypes from './filter-types'

class FilterComponent extends Component {
  state = {
    parks: [],
    subparks: [],
    devices: [],
    date_range: [
      moment('10-04-2018', 'MM-DD-YYYY').subtract(7, 'days'),
      moment('10-04-2018', 'MM-DD-YYYY')
    ],
    envelopes: []
  }

  confirmFilter = () => {
    this.props.confirm(this.state)
  }

  changeFilter = (type) => (data) => {
    this.setState({ [type]: data })
  }

  render () {
    const components = this.props.types.map(type => {
      const Component = filterTypes[type]

      if (Component) {
        return {type, Component}
      } else {
        return null
      }
    })
    .filter(item => item)

    const propsFilter = (type) => {
      if (type === 'subparks') {
        return {
          changeDevice: (data) => {
            this.changeFilter('devices')(data)
          }
        }
      } else {
        return {}
      }
    }

    return (
      <React.Fragment>
        <div
          style={{
            height: '80%',
            width: 'auto',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignContent: 'stretch',
            alignItems: 'stretch',
          }}
        >
          <React.Fragment>
            {components.map(({Component, type}, indexComponent) => {
              return (
                <div
                  key={indexComponent}
                  style={{
                    flex: 1
                  }}
                >
                  <Component
                    data={this.state[type]}
                    change={this.changeFilter(type).bind(this)}
                    {...propsFilter(type)}
                  />
                </div>
              )
            })}
          </React.Fragment>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 20
          }}
        >
          <Button
            variant="contained"
            onClick={this.confirmFilter.bind(this)}
          >
            Send
          </Button>
        </div>
      </React.Fragment>
    )
  }
}

export default FilterComponent
