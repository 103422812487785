import {
    TEST_DATA,
    START_TIME,
    END_TIME
} from '../placeholder-data/FixedDataFromAPI';
import moment from 'moment';

const adaptDataToStatusTable = (DATA=TEST_DATA) => (
    DATA.map(data => ({
        ...data,
        rows: data.rows.map((row, index) => ({
            ...row,
            [START_TIME]: row[START_TIME] ? moment.tz(row[START_TIME], 'America/Fortaleza').format('YYYY-MM-DD HH:mm:ss') : null,
            [END_TIME]: row[END_TIME] ? moment.tz(row[END_TIME], 'America/Fortaleza').format('YYYY-MM-DD HH:mm:ss') : null,
            id: `row_${index}_ev_table`
        }))
    }))
);

export default adaptDataToStatusTable;