import { keyframes } from 'styled-components';

const notifying = keyframes`
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top;
        transform-origin: top;
    }

    .25%, .5% {
        color: #FF3300;
        -webkit-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top;
        transform-origin: top;
    }

    1%, 1.25% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top;
        transform-origin: top;
    }

    1.5%, 1.75% {
        -webkit-transform: rotate(-60deg);
        transform: rotate(-60deg);
        -webkit-transform-origin: top;
        transform-origin: top;
    }

    2%, 2.25% {
        -webkit-transform: rotate(60deg);
        transform: rotate(60deg);
        -webkit-transform-origin: top;
        transform-origin: top;
    }

    2.5%, 2.75% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top;
        transform-origin: top;
    }

    3%, 3.25% {
        color: #bdbdbd;
        -webkit-transform: rotate(-60deg);
        transform: rotate(-60deg);
        -webkit-transform-origin: top;
        transform-origin: top;
    }

    3.5%, 100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        -webkit-transform-origin: top;
        transform-origin: top;
    }
`;

const styles = theme => ({
    buttonIcon: {
        color: '#bdbdbd'
    },

    buttonSelected: {
        color: '#FF3300'
    },

    incommingUpdates: {
        '-webkit-animation': `${notifying} 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s infinite both`,
        animation: `${notifying} 15s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s infinite both`
    },

    chatBubble: {
        '&:before': {
            content: ' ',
            position: 'absolute',
            width: 0,
            height: 0,
            left: '30px',
            top: '100px',
            border: '25px solid',
            borderColor: '#666 transparent transparent #666'
        }
    }
});

export default styles;