import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'
import {
  withStyles
} from '@material-ui/core'
import GridLayout from 'react-grid-layout'
import * as R from 'ramda'

import { AppStyleContent } from './ModuleStyle'

import { updateFilterSubparks, updateFilterTimeRange } from './actions/UpdateFilterAction'

import WidgetDriver from '../../../../../components/Driver/WidgetDriver'
import BaseGlobalFilter from '../../../../../components/Header/BaseGlobalFilter'
import PageWidgetPlaceholder from '../../../../../components/Placeholder/PageWidgetPlaceholder'

import { REDUCER_DELFOS_1_0_PERFORMANCE_GET_DATA } from "../../../../../constants/reducers/widget-page-action-events";
import { applyFilter } from '../../../../../helpers/filter-helper';
import Selector from '../../../../../components/Filter/Selector'


const PAGE_FIELD = 'performance';
const GRID_WIDTH_PADDING = 20

const styles = theme => ({
  gridElement: {
    width: '100%'
  }
})

class PerformancePage extends Component {
  constructor(props) {
    super(props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    // const { delfos_1_0 } = this.props;
    // const { [PAGE_FIELD]: Module } = delfos_1_0 || {};
    const { DelfosPreviousPerformance } = this.props;
    const pageSelected = R.find(R.propEq('selected', true))(DelfosPreviousPerformance);

    this.state = {
      gridWidth: elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),
      widgets: [],
      currPage: pageSelected,
      forceUpdateFilters: false
    }

    this.gridContainerAnchor = React.createRef()
    this.currentDateRange = null
    this.prevManagementId = null
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName('body')[0]

    this.setState({
      gridWidth: body.offsetWidth - (GRID_WIDTH_PADDING * 2)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizePageWatch);
    // const { delfos_1_0 } = this.props;
    // const { [PAGE_FIELD]: Module } = delfos_1_0 || {};
    // const { DelfosPreviousPerformance } = this.props;
    // const moduleSelected = R.find(R.propEq('selected', true))(DelfosPreviousPerformance);
    // this.executeFilter(moduleSelected);
    this.setState({ forceUpdateFilters: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizePageWatch)
  }



  changeLoading = loading => {
    if (this.state.loading != loading) {
      this.setState({
        loading
      });
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const { DelfosPreviousPerformance } = this.props;
    const pageSelected = R.find(R.propEq('selected', true))(DelfosPreviousPerformance);

    const { forceUpdateFilters } = this.state;

    if ((forceUpdateFilters || prevProps.editMode) && pageSelected.widgets.length != prevState.widgets.length) {


      if (!this.props.Dashboard.DeviceFilter.subparks) {

        this.props.updateEnvelopeFilter(pageSelected.filter.devices, pageSelected.filter.subparks)


      }

      this.executeFilter(pageSelected);
    }

    const promisesToResolve = this.state.widgets
      .filter(widget => Promise.resolve(widget.data) === widget.data)

    if (promisesToResolve.length > 0) {
      const widgetData = this.state.widgets.map(widget => widget.data);
      const promisesRes = await Promise.all(widgetData);
      const widgets = this.state.widgets.map((widget, index) => ({
        ...widget,
        data: promisesRes[index],
        loading: false
      }));

      this.setState({
        widgets
      });
    }


    // const promisesToResolve = this.state.widgets
    //   .filter(widget => Promise.resolve(widget.data) === widget.data)

    // console.log('promisesToResolve', promisesToResolve)
    // if(promisesToResolve.length > 0) {
    //   const widgetData = this.state.widgets.map(widget => widget.data)[0];
    //   const promiseRes = await Promise.resolve(widgetData);
    //   console.log('PROMISES RES', promiseRes, this.state.widgets);
    //   const widgets = this.state.widgets.map((widget, index) => index === 0 ? ({...widget, data: promiseRes[0]}) : widget);
    //   this.setState({
    //     widgets
    //   });
    // }
  }

  executeFilter = payload => {

    payload = this.state.currPage;

    const { DelfosPreviousPerformance } = this.props;
    // let page = R.find(R.propEq('selected', true))(this.props.delfos_1_0[PAGE_FIELD]);7
    const page = R.find(R.propEq('selected', true))(DelfosPreviousPerformance);
    payload.filter = page.filter;

    const widgets = applyFilter(payload);


    this.setState({
      widgets
    });
  };

  selectPage = pageId => {
    // const { delfos_1_0 } = this.props;
    // const { [PAGE_FIELD]: Module } = delfos_1_0 || {};
    const { DelfosPreviousPerformance } = this.props;
    const currPage = R.find(R.propEq('id', pageId))(DelfosPreviousPerformance);
    const widgets = applyFilter(currPage);
    this.setState({
      currPage,
      widgets
    });
  };

  render() {
    const { classes, parks, history } = this.props;
    const { widgets, currPage } = this.state;

    if (!widgets || widgets.length == 0) {
      return (
        <AppStyleContent>
          <PageWidgetPlaceholder />
        </AppStyleContent>
      )
    }

    const h = widgets.length > 1 ? '2400px' : '1800px'


    return (
      <AppStyleContent>
        <BaseGlobalFilter
          routerSearch={this.props.location.search}
          mode={'view'}
          history={history}
          page={currPage}
          reducer_name={REDUCER_DELFOS_1_0_PERFORMANCE_GET_DATA}
          disabled={this.state.loading}
          module_field={'DelfosPreviousPerformance'}
          executeFilter={this.executeFilter}
          goToPage={this.selectPage}
          submodule={true}
          {...this.props}

        >

          <Selector updateFilters={this.executeFilter} page={currPage} />

        </BaseGlobalFilter>

        <div
          style={{
            padding: '0 0 0 15px', height: h
          }}
        >
          {this.state.gridWidth ? (
            <GridLayout
              className={classes.gridElement}
              cols={12}
              rowHeight={210}
              items={50}
              width={this.state.gridWidth}
              compactType={'horizontal'}
              isDraggable={false}
              isResizable={false}
            >
              {
                widgets.map((widget, index) => {
                  return (
                    <div style={{
                    }} key={widget.id} data-grid={widget.grid} >
                      <WidgetDriver key={`${index}${widget.id}`}
                        classes={classes}
                        selectedPage={currPage}
                        isAnalysis={false}
                        ignoreIcons={true}
                        showTable={false}
                        parks={parks}
                        history={history}
                        widget={widget}
                        changeLoading={this.changeLoading}
                        reducer_name={REDUCER_DELFOS_1_0_PERFORMANCE_GET_DATA}
                        module_id={'DelfosPreviousPerformance'}
                      />
                      <br />
                      {
                        !(widget.hasOwnProperty('ignore_icons') && widget.ignore_icons.includes('table')) &&

                        <WidgetDriver key={`${index}${widget.id}`}
                          classes={classes}
                          selectedPage={currPage}
                          isAnalysis={false}
                          showTable={true}
                          ignoreIcons={true}
                          parks={parks}
                          history={history}
                          widget={widget}
                          changeLoading={this.changeLoading}
                          reducer_name={REDUCER_DELFOS_1_0_PERFORMANCE_GET_DATA}
                          module_id={'DelfosPreviousPerformance'}
                        />
                      }
                    </div>
                  );
                })
              }
            </GridLayout>
          ) : ''}
        </div>
      </AppStyleContent>
    )
  }
}

const mapStateToProps = ({ Dashboard, User }) => {
  const { DelfosPreviousPerformance } = Dashboard
  const { parks_info } = User

  return { DelfosPreviousPerformance, parks: parks_info, Dashboard }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateFilterSubparks, updateFilterTimeRange }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PerformancePage))
