import React, { useState, useRef } from 'react';
import useContainer from './Container';
import { makeStyles } from '@material-ui/core/styles';

import { Icon, Button, CircularProgress, Menu, MenuItem, MenuList } from '@material-ui/core';

const useStyles = makeStyles({
    paper: {
        width: props => props.buttonWidth * ( props.showSublist ? 2 : 1 ),
        marginLeft: props => props.buttonWidth * ( props.showSublist ? -1 : 0 ),
        marginTop: props => props.buttonHeight
    },
    list: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'flex-start',
        padding: 0
    }
});

export default props => {
    const { children, ...extraProps } = props;
    const { device } = extraProps;
    console.log('Device in predictive model ', device)
    const { modelSelected, predictiveModels, dispatchFilter, setModelSelected } = useContainer(extraProps);

    const [ openMenu, setMenuOpened ] = useState(false);
    const [ activeGroup, setActiveGroup ] = useState();

    const deviceInfo = predictiveModels && device && predictiveModels[device];
    const { groups } = deviceInfo || {};
    
    const btRef = useRef();
    const { current: dom } = btRef;

    const buttonWidth = dom && dom.getBoundingClientRect().width;
    const buttonHeight = dom && dom.getBoundingClientRect().height;

    const classes = useStyles({ buttonWidth, buttonHeight, showSublist: activeGroup != null });
    // console.log({modelSelected})
    return (
        children || 
            <>
                <Button
                    ref={btRef}
                    disabled={!modelSelected}
                    style={{backgroundColor: 'white'}}
                    onClick={() => setMenuOpened(true)}
                >
                    <Icon style={{color: '#bdbdbd'}}>extension</Icon>
                    <p style={{textTransform: 'capitalize'}}>{ modelSelected && modelSelected.name }</p>
                    {
                        !modelSelected &&
                            <CircularProgress style={{color: 'orange'}} size={24} />
                    }
                </Button>
                {
                    dom &&
                        <Menu
                            anchorEl={dom}
                            keepMounted
                            open={openMenu}
                            onClose={() => {
                                dispatchFilter();
                                setMenuOpened(false);
                            }}
                            classes={classes}
                            variant='menu'
                        >
                            <MenuList style={{width: buttonWidth, padding: 0}} >
                                {
                                    groups && Object.values(groups).length > 0 ?
                                        Object.values(groups).map((group, index) => (
                                            <div 
                                                onMouseEnter={() => setActiveGroup(group)}
                                                onMouseLeave={() => setActiveGroup(null)}
                                                style={{
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            >
                                                <MenuItem 
                                                    key={`group_${group.id}_pos_${index}`}
                                                    style={{
                                                        padding: 0,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        whiteSpace: 'normal',
                                                        textAlign: 'center',
                                                        pointerEvents: 'none'
                                                    }}
                                                >
                                                    <Icon
                                                        style={{
                                                            transform: activeGroup && activeGroup.id === group.id && 'rotateZ(90deg)',
                                                            transition: 'transform .15s linear'
                                                        }}
                                                    >
                                                        keyboard_arrow_down
                                                    </Icon>
                                                    <p>{group.name}</p>
                                                </MenuItem>
                                            </div>    
                                        ))
                                    :
                                        <MenuItem disabled>
                                            <Icon style={{color: '#bdbdbd'}}>block</Icon>
                                            <p>Model groups not available</p>
                                        </MenuItem>
                                }
                            </MenuList>
                            {
                                activeGroup &&
                                    <MenuList 
                                        style={{width: buttonWidth, padding: 0}}
                                        onMouseEnter={() => setActiveGroup(activeGroup)}
                                        onMouseLeave={() => setActiveGroup(null)}
                                    >
                                        {
                                            activeGroup && activeGroup.models && Object.values(activeGroup.models).length > 0 ?
                                                Object.values(activeGroup.models).map((model, index) => (
                                                    <MenuItem 
                                                        key={`model_${model.id}_pos_${index}`}
                                                        style={{
                                                            padding: 0,
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-start',
                                                            whiteSpace: 'normal',
                                                            textAlign: 'center'
                                                        }}
                                                        onClick={() => setModelSelected(model)}
                                                    >
                                                        <Icon style={{marginRight: '2%'}}>{modelSelected.id === model.id ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>
                                                        <p>{model.name}</p>
                                                    </MenuItem>    
                                                ))
                                            :
                                                <MenuItem
                                                    disabled
                                                    style={{
                                                        padding: 0,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        whiteSpace: 'normal',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <Icon style={{color: '#bdbdbd'}}>block</Icon>
                                                    <p>Models not available</p>
                                                </MenuItem>
                                        }
                                    </MenuList>
                            }
                        </Menu>
                }
            </>
    );
};