import React, {Component} from 'react'

class FilterDeviceComponent extends Component {
  render () {
    return (
      <div>
        FilterDeviceComponent
      </div>
    )
  }
}

export default FilterDeviceComponent
