import React, {Component} from 'react'
import {Route, Link} from 'react-router-dom'


import moment from 'moment-timezone'

import { ROUTE_MAIN_PCH_MONITOR, ROUTE_MAIN_PCH_HISTORY, ROUTE_MAIN_PCH_ALARM, ROUTE_MAIN_PCH_MAIN ,ROUTE_MAIN_PCH_CALENDAR } from '../../../../constants/route-constant';
import HydroMonitorComponent from './pages/HydroMonitorComponent/HydroMonitorComponent';
import HydroHistoryComponent from './pages/HydroHistoryComponent/HydroHistoryComponent';
import HydroAlarmComponent from './pages/HydroAlarmComponent/HydroAlarmComponent';
import HydroMainComponent from './pages/HydroMainComponent/HydroMainComponent';

import { getDynamoClientMeta } from '../../../../providers/dynamo-client-meta-provider';

const GRID_WIDTH_PADDING = 10


class HydroPage extends React.Component {
  constructor (props) {
    super (props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    this.state = {
      widgets: [],
      'gridWidth': elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),
      monitor: {},
    }
  

  }

  componentDidMount () {

    getDynamoClientMeta()
      .then(dynamoData => {
        const data = dynamoData.Items[0]
        localStorage.setItem('hydroPreferences', JSON.stringify(data))
        this.setState({
          widgets: data.pages,
          filter_calendar: data.filter_calendar ? data.filter_calendar:false
        })
      })
      .catch(error => console.error('componentWillMount getDynamoClientMeta', error))
  }

  // componentDidUpdate(prevProps, prevState) {
  //   const prefs = JSON.parse(localStorage.getItem('hydroPreferences'));
  //   prefs.modules[0].submodules = prefs.modules[0].submodules.map(submodule => submodule.id === 54 ? this.state.monitor : submodule)
  //   // console.log('ON HYDRO DID UPDATE NEW PREFS', prefs)
  //   localStorage.setItem('hydroPreferences', JSON.stringify(prefs))
  // }

  // changeMonitor = newData => {
  //   this.setState({
  //     monitor: newData
  //   })
  // };

  render () {
    
    
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          backgroundColor: this.props.location.pathname === ROUTE_MAIN_PCH_MONITOR ? '#333333' : 'white',
        }}
      >
        <Route path={ROUTE_MAIN_PCH_MAIN} render={props => <HydroMainComponent {...props} {...this.state} />} />
        <Route path={ROUTE_MAIN_PCH_MONITOR} render={props => <HydroMonitorComponent {...props} {...this.state} />} />

        <Route path={ROUTE_MAIN_PCH_HISTORY} render={props => <HydroHistoryComponent {...props} {...this.state} />} />
        <Route path={ROUTE_MAIN_PCH_ALARM} render={props => <HydroAlarmComponent {...props} {...this.state} />} />
     


      </div>
    )
  }
}

export default HydroPage