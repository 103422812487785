import React, { Fragment } from "react";
import { Auth } from "aws-amplify";
import {
  ROUTER_AUTH_PRIVATE,
  ROUTER_AUTH_PUBLIC
} from "./constants/route-constant";
import RouteWithRedirect from "./components/Router/RouteWithRedirect";
import history from './history';
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import ReactHighchart from 'react-highcharts';
import HighchartMore from 'highcharts/highcharts-more';
import { ROUTE_AUTH } from './constants/route-constant';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// TEST ONLY
import { navigate } from './actions/NavigationActions';
import { logout } from './actions/AuthAction';

import { PrintFooter, PrintLayout } from './components/Print'

import ComponentsLab from './ComponentsLab';

HighchartMore(ReactHighchart.Highcharts);

class AppRouter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null
    }
  }

  async componentDidMount() {
    const { navigate, currUser } = this.props;
    window.executeActionNavigate = navigate;

    window._paq.push(['setUserId', currUser && currUser.name]);
    window._paq.push(['trackPageView']);

    try {
      const authed = Auth.currentAuthenticatedUser();
      if (!authed) {
        await logout(currUser.id, true);
      }
    } catch (err) {
      try {
        await logout(currUser.id, true);
      } catch (err2) {
        console.log('Unable to logout due error: ', err2, '\n\nForcing LOGOUT');
        await logout(currUser.id, false, true);
        window.location = '/'
      }
    }

    const removeAllHighchartsTooltips = () => {
      ReactHighchart.Highcharts.charts.forEach(chart => {
        if (chart) {
          chart.pointer.reset();
          chart.tooltip.hide();
        }
      });
    };

    window.onbeforeprint = removeAllHighchartsTooltips;
  }

  componentDidUpdate(prevProps, prevState) {
    const { currRoute } = this.props;

    if (prevState.redirectTo != null) {
      this.setState({
        redirectTo: null
      })
    }

    if (prevProps.currRoute !== currRoute) {
      if (currRoute) {
        window._paq.push(['setCustomUrl', currRoute]);
        window._paq.push(['trackPageView']);
      }

      this.setState({
        redirectTo: currRoute
      })
    }
  }

  render() {
    const { isAuthenticated } = this.props;
    const { redirectTo } = this.state;

    return (
      <Router>
        <div
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          <Route
            exact
            path='/components-lab'
            component={ComponentsLab}
          />
          {
            isAuthenticated ? (
              <>
                <PrintLayout>
                  {
                    ROUTER_AUTH_PRIVATE.map((router, indexRouter) => (
                      <Fragment>
                        <RouteWithRedirect
                          Component={router.component}
                          routerConst={ROUTER_AUTH_PRIVATE}
                          router={router}
                          key={`RoutePrivate${indexRouter}`}
                          isAuthenticated={isAuthenticated}
                        />
                      </Fragment>
                    ))
                  }
                </PrintLayout>
                <PrintFooter />
              </>
            ) : (
                ROUTER_AUTH_PUBLIC.map((router, indexRouter) => (
                  <RouteWithRedirect
                    Component={router.component}
                    routerConst={ROUTER_AUTH_PUBLIC}
                    router={router}
                    key={`RoutePublic${indexRouter}`}
                    isAuthenticated={isAuthenticated}
                  />
                ))
              )
          }
          {
            redirectTo &&
            <Redirect to={redirectTo} />
          }
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ AuthedUser, Navigation, Users }) => {
  const { currModule } = Navigation;
  const currUser = Users[AuthedUser];

  return ({
    isAuthenticated: AuthedUser != null,
    currRoute: currModule && currModule.route,
    currUser
  });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  navigate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);