import { Auth } from 'aws-amplify';
import axios from 'axios';
import { URL_NODE_API } from '../constants/url-constant';

export const getMetadata = async (score=-1) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_NODE_API}/user/metadata/parks-info`;
    // const CHECK_URL = `${URL_NODE_API}/user/metadata/needs-parks-info`;

    try {
        // const res = await Promise.resolve(axios.get(CHECK_URL, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': jwtToken
        //     },
        //     params: {
        //         score
        //     }
        // }));

        // console.log('ON METADATA CHECK RESPONSE', res)


        const { data } = await axios.get(URL, {
            // timeout: 15000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            },
            params: {
                score
            }
        });

        const result = Object.entries(data) && Object.entries(data).map(entry => (
            {
                [entry[0]]: typeof(entry[1]) === 'object' && entry[1].constructor === Array && entry[1].lenght > 0 ?
                    {
                        ...Object.values(entry[1]).map(value => (
                            value.hasOwnProperty('id') ? 
                                {
                                    [value.id]: {
                                        ...value
                                    }
                                }
                            :
                                value
                        )).reduce((acc, curr) => ({...acc, ...curr}))
                    }
                :
                    entry[1]
            }
        )).reduce((acc, curr) => ({...acc, ...curr}));
        
        return result;

    } catch (err) {
        console.log('On getting parks info error', err);
        return false;
    }
}

export const needsMetadata = async (score=-1) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_NODE_API}/user/metadata/needs-parks-info`;

    try {
        const { data } = await axios.get(URL, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            },
            params: {
                score
            }
        });
        
        return data;

    } catch (err) {
        console.log('On getting parks info error', err);
        return false;
    }
}

export const getOldParksInfo = async () => {
    const {accessToken} = await Auth.currentSession();
    const {jwtToken} = accessToken;
  
    const URL = URL_NODE_API + '/user/parks';

    const { data } = await axios.get(URL, {
        // timeout: 15000,
        headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
        }
    });

    const parksInfo = data && data.parks_info;

    return {parksInfo}
}

export const getModels = async () => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_NODE_API}/user/metadata/models`;

    try {
        const { data } = await axios.get(URL, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        });
        
        return data.models;

    } catch (err) {
        console.log('On getting parks info error', err);
        return false;
    }
}