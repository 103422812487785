import React, { Component } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'



import * as HighchartsMore from 'highcharts/js/highcharts-more'
import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider
} from '@material-ui/core';

import styles from './styles/styles.js';
import NoPrint from '../../../components/Print/NoPrint'

HighchartsMore(Highcharts);
// Highcharts.setOptions({
//     plotOptions: {
//         series: {
//             animation: false
//         }
//     }
// });



class GraphicScoreTimeline extends Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      colors: [
        '#bdbdbd',
        '#767676',
        '#C0D2FC',
        '#33cc33',
        '#E62C20',
        '#4C65A7',
        '#B414DC',
        '#FCDC34',
        '#FC9018',
        '#0A70D2'
      ]
    }

    this.chartWillRender = this.chartWillRender.bind(this);
  }

  componentDidMount() {
    this.props.getSettings(this.getSettings.bind(this));

    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }

  chartWillRender(state) {

  }

  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = checkbox => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);
  };


  caulculateFontSizeHead(grid) {


    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }


  getSettings() {

  }

  render() {
    const { classes, showTable, showSettings, graphic, pageInfo } = this.props;
    const { data: rawData } = graphic;
    const { predictive } = pageInfo || {};

    let data = null;

    try {
      data = typeof (rawData) === 'string' ? JSON.parse(rawData.replace(/NaN/g, 'null')) : rawData;
      data = data.map(sector => ({
        ...sector,
        series: sector.series.map(Series => ({
          ...Series,
          data: Series.data ? Series.data.map(row => ([
            typeof (row[0]) === 'string' ? new Date(row[0]).valueOf() : row[0],
            row[1]
          ]))
            :
            []
        }))
      }));
    } catch (err) {
      return (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div style={{
            marginTop: '20px'
          }}>
            Trying to resolve data...
          </div>
        </div>
      )
    }

    const on_render_state = {
      ...this.state,
      data: data
    };

    if (!on_render_state.data) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          Error in API request
        </div>
      )
    }

    this.chartWillRender(on_render_state);

    const series_by_axis = [].concat.apply([],
      on_render_state.data.map((axis, index) => (
        axis.series.map(series => (
          {
            name: series.name,
            data: series.data,
            yAxis: index
          }
        ))
      ))
    )

    const pre_proportions = graphic.proportions ? graphic.proportions : [1, 4, 2]
    const proportion_sum = pre_proportions.reduce((acc, curr) => acc + curr)
    const proportions = pre_proportions.map(proportion => proportion * 100 / proportion_sum)
    let acc = 0
    const acc_proportions = proportions.map(proportion => {
      acc = acc + proportion
      return acc
    })

    const yAxis = proportions.map((proportion, index) => {
      switch (index) {
        case 0:
          return {
            labels: {
              align: 'right',
              x: -10
            },
            title: {
              text: on_render_state.data[0].name,
              style: {
                fontSize: 8
              }
            },
            top: '0%',
            height: `${proportion - 2}%`,
            min: 0,
            max: 100,
            lineWidth: 2,
            offset: 0
          }
        default:
          return {
            labels: {
              align: 'right',
              x: -10
            },
            title: {
              text: on_render_state.data[index].name,
              style: {
                fontSize: 8
              }
            },
            top: `${acc_proportions[index - 1]}%`,
            height: `${proportion - 2}%`,
            offset: 0,
            lineWidth: 2
          }
      }
    })

    const params = {
      colors: on_render_state.colors,

      credits: {
        enabled: false
      },

      rangeSelector: {
        selected: 1,
        buttons: [],
        enabled: false,
        inputEnabled: false
      },

      title: {
        text: ''
      },

      yAxis: yAxis,

      exporting: {
        enabled: true
      },

      tooltip: {
        split: true,
        valueDecimals: 2
      },

      navigator: {
        maskFill: 'rgba(180, 198, 220, 0.75)',
        series: {
          data: series_by_axis[0].data,
          color: '#FF0000',
          lineWidth: 2
        }
      },

      series: series_by_axis,

      ...(
        predictive ?
          {
            time: {
              timezone: 'America/Fortaleza'
            }
          }
          :
          {}
      )
    }

    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable || showSettings ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront} style={{ display: showTable || showSettings ? 'none' : undefined }}>
            <HighchartsReact
              containerProps={{
                style: {
                  width: '100%',
                  height: '100%',
                  marginTop: 30,
                  overflow: 'hidden'
                }
              }}
              ref={this.refChart}
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={params}
            />
          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable || showSettings ? undefined : 'none' }}>
            {
              showTable ?
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={1} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}  ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  </TableBody>
                </Table>
                :
                showSettings ?
                  <List>
                    {
                    }
                  </List>
                  :
                  <NoPrint>
                    <h1 style={{
                      width: '100%',
                      height: 'auto',
                      textAlign: 'center'
                    }}>Returning to Chart...</h1>
                  </NoPrint>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicScoreTimeline);
