import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'

import imageTest from '../../../../../../../../../../assets/no-image.png'

const styles = theme => ({
  
})

class InventoryAssetsDetailViewComponent extends Component {
  render () {
    

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <div
          style={{
            flex: 2,
            padding: 15
          }}
        >
          <div>
            <span
              style={{
                fontWeight: '500',
                marginRight: 10
              }}
            >
              Name: 
            </span>
            {this.props.asset ? this.props.asset.name : '' }
          </div>
          {/* <div>
            <span
              style={{
                fontWeight: '500',
                marginRight: 10
              }}
            >
              Quantity: 
            </span>
            {this.props.asset ? this.props.asset.name : '' }
          </div> */}


        </div>
        <div
          style={{
            flex: 1,
            backgroundImage: `url(${imageTest})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain'
          }}
        />
      </div>
    )
  }
}

export default withStyles(styles)(InventoryAssetsDetailViewComponent)
