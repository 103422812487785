import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AggregatedTable from '../../new-components/AggregatedTable';
import WidgetDriver from '../../components/Driver/WidgetDriver';
import CircularLoading from '../../components/Loading/CircularLoading'
import { executeCustomProvider as DataFetch } from '../../providers/get-data-old-provider';
import { useTranslation } from 'react-i18next';

export default props => {
    const {
        parks,
        history,
        selectedPage,
        widgets,
        executeFilter,
        setExecuteFilter,
        storages,
        finishMount
    } = props;

    const [
        TableWidget,
        ComponentModelWidget,
        ComponentStoreWidget
    ] = widgets;

    const { startDate: startTime, endDate: endTime } = useSelector(Store => Store.Dashboard.DateFilter.data_range);

    const [mounted, setMounted] = useState(false);
    const [tableData, setTableData] = useState();
    const [componentModelData, setComponentModelData] = useState();
    const [componentStoreData, setComponentStoreData] = useState();
    const { t } = useTranslation();

    const Stores = storages.map(store => ({ [store.id]: store })).reduce((acc, curr) => ({ ...acc, ...curr }));
    useEffect(
        () => {
            (async () => {
                if (executeFilter || !mounted) {
                    setTableData('loading');
                    setComponentModelData('loading');
                    setComponentStoreData('loading');

                    if (TableWidget && TableWidget.providerInfo) {
                        const { movementOverview } = await DataFetch({ start_time: startTime.replace(' ', 'T'), end_time: endTime.replace(' ', 'T'), ...TableWidget.providerInfo }, 'get');

                        setTableData(movementOverview);
                    }

                    if (ComponentModelWidget && ComponentModelWidget.providerInfo) {
                        const { comparativeStore } = await DataFetch({ start_time: startTime.replace(' ', 'T'), end_time: endTime.replace(' ', 'T'), ...ComponentModelWidget.providerInfo }, 'get');

                        setComponentModelData(comparativeStore);
                    }

                    if (ComponentStoreWidget && ComponentStoreWidget.providerInfo) {
                        const { comparativeStore } = await DataFetch({ start_time: startTime.replace(' ', 'T'), end_time: endTime.replace(' ', 'T'), ...ComponentStoreWidget.providerInfo }, 'get');

                        setComponentStoreData(comparativeStore);
                    }

                    finishMount(true);

                    if (!mounted) {
                        setMounted(true);
                    }
                }
            })();
        },
        [executeFilter, TableWidget, startTime, endTime, mounted]
    );

    return (
        <div style={{ margin: 24 }}>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    flexBasis: 'auto',
                    height: '100%'
                }}
                className='fit-page'
            >
                <div style={{ margin: 6, flex: 1, flexGrow: 3, maxWidth: 700 }}>
                    {
                        tableData === 'loading' ?
                            <CircularLoading notOverlay>
                                <span>{`${t("Getting your data")}...`}</span>
                            </CircularLoading>
                            :
                            <AggregatedTable
                                data={tableData}
                                fieldFormatter={(index, head, row) => {
                                    switch (head) {
                                        case 'from_store':
                                        case 'to_store':
                                            const val = Number(row[head]);
                                            return Number.isNaN(val) || Stores[val] == null ? row[head] : Stores[val].name;
                                        default:
                                            return row[head];
                                    }
                                }}
                                onDownload={(buildHead, buildBody, columns, data) => {
                                    const cols = columns.map(column => column.name);

                                    const newColumns = columns.map(column => ({
                                        ...column,
                                        name: column.label
                                    }));

                                    const newData = data.map(row => ({
                                        ...row,
                                        data: row.data.map((cell, index) => {
                                            if (cols[index] === 'from_store' || cols[index] === 'to_store') {
                                                const val = Number(cell);
                                                return Number.isNaN(val) || Stores[val] == null ? cell : Stores[val].name;
                                            }

                                            return cell;
                                        })
                                    }));

                                    const result = `${buildHead(newColumns)}${buildBody(newData)}`;
                                    return result;
                                }}
                            />
                    }
                </div>
                <div
                    style={{
                        margin: 6,
                        flex: 1, flexGrow: 1
                    }}
                >
                    <div style={{ width: 500, height: 300 }}>
                        <WidgetDriver
                            showTable={false}
                            unique={true}
                            ignoreIcons={true}
                            widget={{
                                ...ComponentModelWidget,
                                loading: componentModelData === 'loading',
                                data: componentModelData === 'loading' ?
                                    []
                                    :
                                    componentModelData || [],
                                isPieChartKPI: true,
                                showLegend: false,
                                forceOutside: true
                            }}
                            isAnalysis={false}
                            reducer_name={'REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA'}
                            module_id={'DelfosPreviousManagement'}
                            {...{ parks, history, selectedPage }}
                        />
                    </div>
                    <div style={{ width: 500, height: 300 }}>
                        <WidgetDriver
                            showTable={false}
                            unique={true}
                            ignoreIcons={true}
                            widget={{
                                ...ComponentStoreWidget,
                                loading: componentStoreData === 'loading',
                                data: componentStoreData === 'loading' ?
                                    []
                                    :
                                    componentStoreData || [],
                                isPieChartKPI: true,
                                showLegend: false,
                                forceOutside: true
                            }}
                            isAnalysis={false}
                            reducer_name={'REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA'}
                            module_id={'DelfosPreviousManagement'}
                            {...{ parks, history, selectedPage }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};