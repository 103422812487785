import TopBottom from './TopBottom';
import AxisColorTreatment from './AxisColorTreatment';
import SeriesColorTreatment from './SeriesColorTreatment';
import StackBars from './StackBars';
import Pareto from './Pareto';
import Order from './Order';

const GraphicBarDecorators = {
  TopBottom: TopBottom,
  SeriesColorTreatment: SeriesColorTreatment,
  AxisColorTreatment: AxisColorTreatment,
  StackBars: StackBars,
  Pareto: Pareto,
  Order: Order
};

export default GraphicBarDecorators;
