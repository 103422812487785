import React from 'react';
import {
    Route,
    Redirect
} from 'react-router-dom';
import * as R from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './RouteWithRedirectStyle';

const RouteWithRedirect = ({Component, isAuthenticated, routerConst, router, toggleAuth, classes, moreParams={}}) => (
    <React.Fragment>
        <Route
            path={router.path}
            render={(props) => (
                <Component
                    {...props}
                    toggleAuth={toggleAuth}
                    pages={router.pages}
                    className={classes.contentFull}
                    {...moreParams}
                />
            )}
        />

        <Route
            exact path='/'
            render={(props) => (
                <Redirect
                    to={{
                        pathname: isAuthenticated ? '/dashboard' : '/auth',
                        state: { from: props.location }
                    }}
                />
            )}
        />

        {
            isAuthenticated ?
                <Route
                    path='/auth'
                    render={(props) => (
                        <Redirect
                            to={{
                                pathname: '/dashboard',
                                state: { from: props.location }
                            }}
                        />
                    )}
                />
            :
                <Route
                    path='/dashboard'
                    render={(props) => (
                        <Redirect
                            to={{
                                pathname: '/auth',
                                state: { from: props.location }
                            }}
                        />
                    )}
                />           
        }

        {/* <Route
            render={(props) => (
                props.location !== window.location.pathname &&
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
            )}
        /> */}
    </React.Fragment>
);

export default withStyles(styles)(RouteWithRedirect);