import { PAGE_SIZE_TYPE_HEIGHT, PAGE_SIZE_TYPE_WIDTH } from "../constants/page-constant";

export const pageResizeHelper = (item, value, type) => {
  const otherType = type === PAGE_SIZE_TYPE_HEIGHT
    ? PAGE_SIZE_TYPE_WIDTH
    : PAGE_SIZE_TYPE_HEIGHT

  const sizeChange = item[type]
  const sizeProp = item[otherType]

  const percent = (value / sizeChange)

  const sizeNew = (sizeProp * percent)

  return {
    [type]: value,
    [otherType]: sizeNew
  }
}
