import React, { Component } from 'react'
import { Icon, LinearProgress } from "@material-ui/core";
import * as R from "ramda";
import moment from 'moment-timezone'
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import GridLayout from "react-grid-layout";
import OPCPortfolioItemWtg from "../../modules/Dashboard/pages/OPCPage/pages/OPCPortfolioPage/components/OPCPortfolioItem/OPCPortfolioItemWtg";
import grey from "@material-ui/core/colors/grey";
import OPCMapComponent from "../../modules/Dashboard/pages/OPCPage/pages/OPCMapPage/components/OPCMapComponent/OPCMapComponent";
import WindRoseComponent from "../WindRoseComponent/WindRoseComponent";


const HEADER_LABEL = 80;
const WIDGET_HEADER_HEIGHT = 40

const GRID_WIDTH_PADDING = 10;

class PortfolioSubparkComponent extends Component {


  constructor(props) {
    super(props);

    const elBody = window.document.getElementsByTagName("BODY")[0];

    this.state = {
      gridWidth: elBody.offsetWidth - GRID_WIDTH_PADDING * 2
    };
  }




  render() {

    const { classes } = this.props;

    return (
      <div style={{ backgroundColor: 'rgb(45, 45, 45)', overflowX: 'hidden' }}>
        {this.props.selectedSubpark &&
          <div>
            <Dialog
              onClose={this.props.closeDialogWtg}
              open={this.props.dialogWtg}
              aria-labelledby="dialog_widget_new"
              fullWidth={true}
              fullScreen
            >
              <DialogTitle id="dialog_widget_new" style={{ backgroundColor: 'rgb(45, 45, 45)' }}>


                <span style={{ color: 'white' }}>{this.props.selectedSubpark.name}</span>

                <IconButton style={{ float: 'right', color: 'white' }} edge="start" color="inherit" onClick={this.props.closeDialogWtg} aria-label="Close">
                  <CloseIcon />
                </IconButton>

              </DialogTitle>
              <DialogContent style={{ backgroundColor: 'rgb(45, 45, 45)' }}>


                <div style={{
                  color: "white",
                  fontSize: 12,
                  backgroundColor: 'rgb(45, 45, 45)'
                }}>
                  <div
                    style={{
                      minHeight: HEADER_LABEL,
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexFlow: 'row wrap'
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        Turbines:
                            <span
                          style={{
                            marginLeft: 5,
                            fontWeight: 900
                          }}
                        >
                          {
                            this.props.selectedSubpark && this.props.selectedSubpark.wtgs && this.props.selectedSubpark.wtgs.length
                          }
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginLeft: 20
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          Power (MW):
                              <span
                            style={{
                              marginLeft: 5,
                              fontWeight: 900
                            }}
                          >
                            {(this.props.powerAll / 1000).toFixed(1)}
                          </span>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            marginTop: 3
                          }}
                        >
                          <LinearProgress
                            style={{
                              height: 4
                            }}
                            variant="determinate"
                            value={((100 / this.props.ratedPowerAll) * this.props.powerAll)}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginLeft: 20
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center"
                          }}
                        >
                          Wind Speed (m/s):
                              <span
                            style={{
                              marginLeft: 5,
                              fontWeight: 900
                            }}
                          >
                            {isNaN(this.props.windMedian) ? 0 : this.props.windMedian.toFixed(1)}
                          </span>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            marginTop: 3
                          }}
                        >
                          <LinearProgress
                            style={{
                              // height: 10
                            }}
                            variant="determinate"
                            value={isNaN(this.props.windMedian) ? 0 : ((100 / 20) * this.props.windMedian)}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginLeft: 20
                        }}
                      >
                        Last Update:
                            <span
                          style={{
                            marginLeft: 5,
                            fontWeight: 900
                          }}
                        >
                          {
                            R.last(this.props.timeAllSort) && R.last(this.props.timeAllSort).time
                              ?
                              moment.duration(moment().diff(moment(R.last(this.props.timeAllSort).time))).humanize()
                              :
                              null
                          }
                        </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginLeft: 20
                        }}
                      >
                        <Icon style={{ fontSize: 16, color: "green" }}>
                          fiber_manual_record
                        </Icon>
                        <span
                          style={{
                            marginLeft: 5
                          }}
                        >
                          Running
                        </span>
                      </div>
                      
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginLeft: 20
                        }}
                      >
                        <Icon style={{ fontSize: 16, color: "purple" }}>
                          fiber_manual_record
                            </Icon>
                        <span
                          style={{
                            marginLeft: 5
                          }}
                        >
                          Maintenance (Corrective)
                            </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginLeft: 20
                        }}
                      >
                        <Icon style={{ fontSize: 16, color: "blue" }}>
                          fiber_manual_record
                            </Icon>
                        <span
                          style={{
                            marginLeft: 5
                          }}
                        >
                          Maintenance (Preventive)
                            </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginLeft: 20
                        }}
                      >
                        <Icon style={{ fontSize: 16, color: "red" }}>
                          fiber_manual_record
                            </Icon>
                        <span
                          style={{
                            marginLeft: 5
                          }}
                        >
                          Stopped
                            </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginLeft: 20
                        }}
                      >
                        <Icon style={{ fontSize: 16, color: "orange" }}>
                          fiber_manual_record
                            </Icon>
                        <span
                          style={{
                            marginLeft: 5
                          }}
                        >
                          Power limitation
                            </span>
                      </div>


                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginLeft: 20
                        }}
                      >
                        <Icon style={{ fontSize: 16, color: "yellow" }}>
                          fiber_manual_record
                            </Icon>
                        <span
                          style={{
                            marginLeft: 5
                          }}
                        >
                          Warning
                            </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginLeft: 20
                        }}
                      >
                        <Icon style={{ fontSize: 16, color: "#878500" }}>
                          fiber_manual_record
                            </Icon>
                        <span
                          style={{
                            marginLeft: 5
                          }}
                        >
                          Low wind
                            </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginLeft: 20
                        }}
                      >
                        <Icon style={{ fontSize: 16, color: "grey" }}>
                          fiber_manual_record
                            </Icon>
                        <span
                          style={{
                            marginLeft: 5
                          }}
                        >
                          No Communication
                            </span>
                      </div>
                    </div>


                  </div>
                  <hr />
                  <GridLayout
                    className={classes.gridElement}
                    width={this.state.gridWidth - 20}
                    cols={6}
                    rowHeight={100}
                    compactType={"horizontal"}
                    onLayoutChange={data => console.log("onLayoutChange", data)}
                    draggableHandle={'.move-grid-widget'}
                    preventCollision={true}

                  >
                    <div
                      key={1}
                      data-grid={{
                        x: 0,
                        y: 0,
                        w: 3,
                        h: 4
                      }}
                    >
                      <OPCPortfolioItemWtg item={this.props.selectedSubpark} />
                    </div>

                    <div
                      key={3}
                      data-grid={{
                        x: 0,
                        y: 4,
                        w: 3,
                        h: 3
                      }}
                    >
                      <div
                        style={{
                          color: "white",
                          border: `1px solid ${grey["900"]}`,
                          width: "100%",
                          height: "100%"
                        }}
                      >
                        <div
                          className="move-grid-widget"
                          style={{
                            height: WIDGET_HEADER_HEIGHT,
                            width: "100%",
                            borderBottom: `1px solid ${grey["900"]}`,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: grey["500"]
                          }}
                        >
                          <div
                            style={{
                              marginLeft: 10
                            }}
                          >
                            Wind Rose
                          </div>
                        </div>
                        <div
                          style={{
                            height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
                            width: "100%",
                            overflowX: "hidden",
                            overflow: "auto"
                          }}
                        >
                          <WindRoseComponent items={[this.props.selectedSubpark]} />
                        </div>
                      </div>
                    </div>

                    <div
                      key={4}
                      data-grid={{
                        x: 2,
                        y: 0,
                        w: 3,
                        h: 7
                      }}
                    >
                      <div
                        style={{
                          color: "white",
                          border: `1px solid ${grey["900"]}`,
                          width: "100%",
                          height: "100%"
                        }}
                      >
                        <div
                          className="move-grid-widget"
                          style={{
                            height: WIDGET_HEADER_HEIGHT,
                            width: "100%",
                            borderBottom: `1px solid ${grey["900"]}`,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: grey["500"]
                          }}
                        >
                          <div
                            style={{
                              marginLeft: 10
                            }}
                          >
                            Map
                          </div>
                        </div>
                        <div
                          style={{
                            height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
                            width: "100%",
                            overflow: "auto"
                          }}
                        >
                          <OPCMapComponent
                            items={[this.props.selectedSubpark]}
                            center={this.props.center}
                          />
                        </div>
                      </div>
                    </div>

                  </GridLayout>

                </div>
              </DialogContent>
            </Dialog>
          </div>


        }
      </div>
    )
  }
}

export default PortfolioSubparkComponent
