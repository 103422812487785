import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Checkbox from '@material-ui/core/Checkbox';
import grey from '@material-ui/core/colors/grey'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as R from 'ramda'

const styles = () => ({
  root: {
    width: 'calc(100% - 2px)',
    height: 'calc(100% - 3px)',
    maxWidth: '100%',
    maxHeight: '450px',
    overflow: 'auto',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  listSubheader: {
    backgroundColor: 'white',
    borderBottom: `1px solid ${grey['200']}`
  },
  listItem: {
    borderBottom: `1px solid ${grey['200']}`
  }
});

class FilterSubparkComponent extends Component {
  constructor (props) {
    super (props)

    this.state = {
      checked: []
    }
  }

  toggleItem = (item) => () => {
    const data = R.contains(item.id, this.props.data)
      ? this.props.data.filter(itemFilter => itemFilter !== item.id)
      : R.append(item.id, this.props.data)

    const devices = R.flatten(
      this.props.parks.map(park => {
        const subparks = park.subparks.filter(subpark => R.contains(subpark.id, data))
        const devices = subparks.map(subpark => subpark.wtgs)

        return devices
      })
    )

    this.props.changeDevice(devices.map(device => device.id))
    
    this.props.change(data)
  }

  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: 300,
          minWidth: 300,
          border: `1px solid ${grey['200']}`
        }}
      >
        <div
          style={{
            height: 40,
            width: '100%',
            borderBottom: `1px solid ${grey['200']}`
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 20,
              height: '100%',
              width: '100%'
            }}
          >
            SUBPARKS
          </div>
        </div>
        <div
          style={{
            height: 'calc(100% - 40px)',
            width: '100%'
          }}
        >
          <List dense className={classes.root} subheader={<li />}>
            {this.props.parks.map((park, indexPark) => (
              <li key={`section-${indexPark}`} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader
                    className={classes.listSubheader}
                  >
                    {park.park}
                  </ListSubheader>
                  {park.subparks.map((subpark, indexSubpark) => {
                    return (
                      <ListItem
                        key={`item-${indexPark}-${indexSubpark}`}
                        className={classes.listItem}
                        onClick={this.toggleItem(subpark).bind(this)}
                      >
                        <Checkbox
                          checked={R.contains(subpark.id, this.props.data)}
                        />
                        <ListItemText primary={subpark.name} />
                      </ListItem>
                    )
                  })}
                </ul>
              </li>
            ))}
          </List>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({User}) => {
  const {parks_info} = User

  return { parks: parks_info }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FilterSubparkComponent))
