import React from 'react';
import useContainer from './Container';
import useStyles from './styles';
import DownloadBtn from '../DownloadBtn';
import { List, ListSubheader, ListItem, ListItemText, ListItemSecondaryAction, Icon, Collapse, Divider } from '@material-ui/core';


export default props => {

    const { list, font } = useStyles();

    const { title, items, collapsed, collapse, noData } = useContainer(props);

    const renderListItems = items => {
        return items.map((item, index) => {
            return (
                <>
                    <ListItem className={font} key={index} button dense
                        onClick={() => { collapse(index) }}>
                        <ListItemText
                            className={font}
                            primary={item.primary}
                            disableTypography
                        />
                        <Icon>{`keyboard_arrow_${collapsed[index] ? 'up' : 'down'}`}</Icon>
                    </ListItem>
                    <Collapse in={collapsed[index]}>
                        <List dense>
                            {item.items.map((item, index) => (
                                <>
                                    <ListItem className={font} key={index} dense >
                                        <ListItemText inset className={font} primary={<span>{item.primary}</span>} secondary={<span>{item.secondary}</span>} />
                                        <ListItemSecondaryAction>
                                            <DownloadBtn edge="end" aria-label="Download" onClick={item.onClick} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </>
                            ))}
                        </List>
                    </Collapse>
                </>
            );
        });
    };

    return (
        <List className={list} subheader={
            <ListSubheader className={font}>{title}</ListSubheader>
        } dense>
            {noData ? noData : renderListItems(items)}
        </List>
    );
};