import { useState, useEffect } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { URL_API } from '../../../../../constants/url-constant';

const getConfig = (auth, startDate, endDate) => ({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': auth
    },
    params: {
        timezone: 'America/Fortaleza',
        start_time: startDate,
        end_time: endDate
    }
});

const getToken = () => new Promise(async (resolve, reject) => {
    await Auth.currentSession()
        .then(({ accessToken }) => { resolve(accessToken.jwtToken) })
        .catch(error => reject(error));
});

const handleError = error => { console.log(error) };


export default ({ startDate, endDate }) => {
    const [loading, setLoading] = useState(false);
    const [pieData, setPieData] = useState(null);
    const [tableData, setTableData] = useState(null);

    const fetchStatistics = async (startDate, endDate) => {
        const URL = `${URL_API}/maintenance/get_maintenance_stats`;

        setLoading(true);
        await getToken()
            .then(async token => {
                const { data } = await axios.get(URL, getConfig(token, startDate, endDate));

                if (data) {
                    setPieData(data.pie_chart);
                    setTableData(data.table);
                }
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (startDate, endDate) {
            fetchStatistics(startDate, endDate);
        }
    }, [startDate, endDate]);


    return {
        pieProps: {
            widget: {
                data: pieData,
                loading,
                type: 'calendar_statistics'
            },
            ignoreIcons: true,
            isAnalysis: false,
            showTable: false,
            module_id: 'DelfosPreviousManagement'
        },
        tableProps: {
            widget: {
                data: tableData,
                loading,
                type: 'calendar_statistics_table'
            },
            ignoreIcons: true,
            isAnalysis: false,
            showTable: false,
            module_id: 'DelfosPreviousManagement'
        }
    }
};