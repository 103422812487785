import React from 'react';
import {
    Icon,
    Button,
    withStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';

const styles = theme => ({
    button: {
        '&:hover': {
            backgroundColor: 'red',
            color: '#FF3300'
        },
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        height: '100%',
        textTransform: 'capitalize'
    }
});

const View = props => {
    const { classes } = props;

    return(
        <div 
            style={{
                height: '100%'
            }}
        >
            <Button
                className={classes.button}
                // aria-describedby={open && 'simple-popper'}
                variant='contained'
                disableRipple="true"
                // onClick={this.handleClick}
                // disabled={this.props.disabled}
            >
                <Icon
                    style={{
                        color:'#bdbdbd', 
                        marginRight: '5px'
                    }}
                >
                    local_grocery_store
                </Icon>
                <span
                    style={{
                        color: 'inherit'
                    }}
                >
                    {`${'X'} Components`}
                </span>
            </Button>
        </div>
    )
}

export default withStyles(styles)(View);