import styled from 'styled-components'

import { AppFull } from '../../../../AppStyle'

const LoginContent = styled(AppFull)`
    display: flex
`

const LoginForm = styled.form`
    margin-top: auto;
    margin-bottom: auto;
`

const LoginFormInputs = styled.div`
  margin-top: 20px;
`

export { LoginContent, LoginForm, LoginFormInputs }

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    formTitle: {
        marginBottom: '10px',
    },
    textField: {
        width: '100%',
        marginTop: '10px',
    },
    buttonSignIn: {
        width: '100%',
        marginTop: '40px',
        borderRadius: 0,
        backgroundColor: '#f2711c',
        '&:hover, &$focusVisible': {
            backgroundColor: '#E16619',
        },
    },
    logoImgContainer: {
        textAlign: 'center'
    },
    logoImg: {
        width: '50%',
        height: '50%',
        marginRight: '-5%'
    },
    logoImgSubtitle: {
        marginTop: '-8%',
        fontSize: '.9em',
        color: '#7f7f7f'
    },
    link: {
        margin: '0',
        fontSize: '0.8rem',
        width: '100%',
        textTransform: 'none'
    }
});

export default styles;