import React from 'react'
import {
  withStyles,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Icon,
  Select,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  IconButton
} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import styled from 'styled-components'
import * as R from 'ramda'
import moment from 'moment-timezone'
import {HuePicker} from 'react-color'
import uuidv4 from 'uuid/v4'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import AXIS_TYPES, { CUSTOM_AXIS_VARIABLE } from '../../../../../../constants/custom-axis-types'
import FilterThreeComponent from '../../../../../../components/FilterThreeComponent/FilterThreeComponent'
import FilterDateComponent from '../../../../../../components/FilterDateComponent/FilterDateComponent'
import { getDataGraphOldProvider, getDataToStudioProvider, getVariablesProvider } from '../../../../../../providers/get-data-old-provider'

const styles = {
  buttons: {

  },
  textField: {
    width: '100%'
  },
  formControl: {
    width: '100%',
    marginTop: 10,
  }
}

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`

const TableTdHoverStyled = styled.td`
  cursor: pointer;
  text-align: center;
`

class StudioFilterComponent extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      openMenuFilter: true,
      data: [],
      loading: false,
      showMenu: false,
      dataMenu: [],
      parks_info: [],
      scada_info: [],
      variables_info: [],
      scadaSelected: null,
      scadaAnchoEl: null,
      loadingMenu: false,
      dialogOpenVariables: false,
      dialogCustomXAxis: false,
      dialogCustomYAxis: false,
      variableSearch: '',
      selectVariables: [],
      selectVariableX: null,
      dateKey: 'jkdhakjshdkajsh',
      dateStart: moment().subtract(40, 'days'),
      dateEnd: moment(),
      graphics: [],
      xAxisNewId: null, 
      xAxisNewLabel: '',
      xAxisNewColor: '',
      xAxisNewType: '',
      xAxisNewOpposite: false,
      xAxisNewTypeVariable: '',
      xAxisItems: [],

      yAxisNewId: null,
      yAxisNewLabel: '',
      yAxisNewColor: '',
      yAxisNewType: '',
      yAxisNewOpposite: false,
      yAxisNewTypeVariable: '',
      yAxisItems: []
    }
  }

  componentDidMount () {
    getDataGraphOldProvider()
      .then(({result}) => {
        const dataMenu = result.map(park => {
          const subparks = park.subparks.map(subpark => {
            const wtgs = subpark.wtgs.map(wtg => {
              return {
                id: wtg.id,
                name: wtg.name,
                data: []
              }
            })
    
            return {
              id: subpark.id,
              name: subpark.name,
              data: wtgs
            }
          })
    
          return {
            id: park.id,
            name: park.park,
            data: subparks
          }
        })

        this.setState({
          dataMenu,
          parks_info: result
        })
      })
      .catch(error => console.error('ERROR', error))
  }

  onClickButtonMenu = (param, value) => {
    this.setState({ [param]: value })
  }

  changeDataFilterThree = (data) => {
    const scada_info = R.flatten(
      this.state.parks_info.map(park => {
        if (R.contains(park.id, data.filter(item => item.checked).map(item => item.id))) {
          return park.scada
        }

        return false
      })
      .filter(item => item)
    )

    const scadaSelected = state => state.scadaSelected
      ? R.contains(state.scadaSelected.id, scada_info.map(item => item.id))
        ? state.scadaSelected
        : null
      : null

    this.setState(
      state => ({
        scadaSelected: scadaSelected(state),
        dataMenu: data,
        scada_info,
      }),
      () => this.getVariables()
    )
  }

  getVariables = () => {
    const wtgs = R.flatten(
      this.state.dataMenu.map(park => {
        const subparks = park.data.map(subpark => {
          return subpark.data.filter(wtg => wtg.checked).map(wtg => wtg.id)
        })

        return subparks
      })
    )
    
    if (!this.state.scadaSelected || wtgs.length <= 0) {
      return null
    }

    this.setState({ loadingMenu: true })

    const params = {
      wtgs,
      scada_id: this.state.scadaSelected.id
    }

    getVariablesProvider(params)
      .then(variables_info => {
        this.setState({
          loadingMenu: false,
          variables_info,
          selectVariableX: null,
          selectVariables: []
        })
      })
      .catch(error => {
        this.setState({ loadingMenu: false })
        console.error('getVariablesProvider', error)
      })
  }

  menuToggle = (param, value) => e => {
    if (value) {
      this.setState({ [param]: e.currentTarget })
    } else {
      this.setState({ [param]: null })
    }
  }

  dialogClose = (param, value) => {
    this.setState({
      [param]: value
    })
  }

  changeSelectVariable = variable => {
    if (R.contains(variable.variable_code, this.state.selectVariables.map(item => item.variable_code))) {
      this.setState(state => ({
        selectVariables: state.selectVariables.filter(item => item.variable_code !== variable.variable_code)
      }))
    } else {
      const selectVariables = R.append(variable, this.state.selectVariables)
      
      this.setState({
        selectVariables
      })
    }
  }

  getData = () => {
    const wtgs = R.flatten(
      this.state.dataMenu.map(park => {
        const subparks = park.data.map(subpark => {
          return subpark.data.filter(wtg => wtg.checked).map(wtg => wtg.id)
        })

        return subparks
      })
    )

    const vars = this.state.selectVariables.map(({variable_code}) => `${variable_code}`)

    if (wtgs.length <= 0
      || vars.length <= 0
      // || !this.state.selectVariableX
      || !this.state.scadaSelected
    ) {
      return null
    }

    const params = {
      wtgs,
      vars,
      scada_id: this.state.scadaSelected.id,
      time_range: [
        this.state.dateStart.format(),
        this.state.dateEnd.format()
      ],
      timezone: moment.tz.guess()
    }

    this.setState({loading: true})
    
    getDataToStudioProvider(params)
      .then((data) => {
        this.setState(
          {
            loading: false
          },
          () => {
            this.props.setData({
              data,
              dataMenu: this.state.dataMenu,
              selectVariables: this.state.selectVariables,
              customAxisX: this.state.xAxisItems,
              customAxisY: this.state.yAxisItems
            })
          }
        )

        // this.setState({data})
      })
      .catch(error => {
        console.error('getDataToStudioProvider', error)
        this.setState({loading: false})
      })
  }

  setParam = (param, value, callback = null) => {
    this.setState({
      [param]: value
    }, () => {
      if (callback) {
        callback()
      }
    })
  }

  render () {
    const {classes} = this.props

    const turbines = R.flatten(
      this.state.dataMenu.map(park => {
        const subparks = park.data.map(subpark => {
          return subpark.data.filter(wtg => wtg.checked).map(wtg => wtg.id)
        })

        return subparks
      })
    )

    const dateRange = {
			startDate: this.state.dateStart,
			endDate: this.state.dateEnd,
			key: this.state.dateKey
    }

    return (
      <div
        ref={this.rootElRef}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        {
          this.state.loading
          ?
          (
            <div
              style={{
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                zIndex: 9999999999999,
                backgroundColor: `rgba(${grey['200'], 0.5})`,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress className={classes.progress} />
              <div
                style={{
                  marginTop: 20
                }}
              >
                Loading...
              </div>
            </div>
          )
          :
          null
        }
        <div
          style={{
            height: '100%',
            width: `100%`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingLeft: 20,
            paddingRight: 20
          }}
        >
          <div
            style={{
              flex: 1
            }}
          >
            Data Studio
          </div>
          <div
            style={{
              border: `1px solid ${grey['200']}`,
              display: 'flex',
              flexDirection: 'row',
              height: 40
            }}
          >
            <FilterDateComponent
              // open={this.props.showMenu}
              // openMenu={() => this.onClickButtonMenu('showMenu', true)}
              closeMenu={() => {
                // this.props.setParam('dateStart', this.state.dateStart)
                // this.props.setParam('dateEnd', this.state.dateEnd)
              }}
              startDate={this.state.dateStart}
              endDate={this.state.dateEnd}
              onChange={(data) => {
                const {startDate, endDate} = data[this.state.dateKey]

                this.setParam('dateStart', moment(startDate))
                this.setParam('dateEnd', moment(endDate))
              }}
              dateRange={dateRange}
            />

            <FilterThreeComponent
              open={this.state.showMenu}
              openMenu={() => this.setState({showMenu: true})}
              closeMenu={() => this.setState({showMenu: false})}
              buttonStyle={{
                borderLeft: `1px solid ${grey['200']}`,
                borderRadius: 30,
                height: '100%'
              }}
              label={
                turbines.length > 0
                ?
                (
                  `${turbines.length} Turbines`
                )
                :
                'Select Devices'
              }
              changeData={this.changeDataFilterThree.bind(this)}
              data={this.state.dataMenu}
            />

            {
              this.state.scada_info.length <= 0
              ?
              null
              :
              (
                <React.Fragment>
                  {/* SCADA */}
                  <Button
                    className={classes.button}
                    onClick={e => this.setState({scadaAnchoEl: e.currentTarget})}
                    style={{
                      borderRadius: 0,
                      borderLeft: `1px solid ${grey['200']}`
                    }}
                  >
                    {this.state.scadaSelected ? this.state.scadaSelected.name : 'Select scada'}
                  </Button>
                  <Menu
                    id='scada-select-menu'
                    anchorEl={this.state.scadaAnchoEl}
                    open={Boolean(this.state.scadaAnchoEl)}
                    onClose={e => this.setState({scadaAnchoEl: null})}
                  >
                    {
                      this.state.scada_info.map((scada, indexScada) => {
                        return (
                          <MenuItem
                            key={indexScada}
                            onClick={() => {
                              this.setState(
                                {
                                  scadaAnchoEl: null,
                                  scadaSelected: scada
                                },
                                () => this.getVariables()
                              )
                            }}
                          >
                            {scada.name}
                          </MenuItem>
                        )
                      })
                    }
                  </Menu>
                </React.Fragment>
              )
            }

            {
              this.state.variables_info <= 0
              ?
              null
              :
              (
                <React.Fragment>
                  {/* VARIABLES */}
                  <Button
                    className={classes.button}
                    onClick={() => this.setState({dialogOpenVariables: true})}
                    style={{
                      borderRadius: 0,
                      borderLeft: `1px solid ${grey['200']}`
                    }}
                  >
                    {
                      this.state.selectVariables.length > 0
                      ?
                      this.state.selectVariables.length === 1
                        ?
                        (
                          <span>
                            {this.state.selectVariables[0].variable_name}
                          </span>
                        )
                        :
                        (
                          <span>
                            {this.state.selectVariables.length} selected
                          </span>  
                        )
                      :
                      'Select Variables'
                    }
                  </Button>
                </React.Fragment>
              )
            }

            <Button
              className={classes.button}
              onClick={() => this.setState({dialogCustomXAxis: true})}
              style={{
                borderRadius: 0,
                borderLeft: `1px solid ${grey['200']}`
              }}
            >
              X Axis
            </Button>

            <Button
              className={classes.button}
              onClick={() => this.setState({dialogCustomYAxis: true})}
              style={{
                borderRadius: 0,
                borderLeft: `1px solid ${grey['200']}`
              }}
            >
              Y Axis
            </Button>

            <Button
              style={{
                // borderLeft: `1px solid ${grey['200']}`,
                backgroundColor: 'orange',
                color: 'white',
                height: '100%',
                borderRadius: 0
              }}
              onClick={() => this.getData()}
            >
              Filter
            </Button>






          </div>
          {
            !this.state.loadingMenu
            ?
            null
            :
            (
              <div
                style={{
                  paddingLeft: 20,
                  paddingRight: 20
                }}
              >
                <CircularProgress className={classes.progress} />
              </div>
            )
          }
        </div>

        <Dialog
          open={this.state.dialogOpenVariables}
          onClose={() => this.setState({dialogOpenVariables: false})}
          aria-labelledby="dialog-studio-filter-variables-title"
          aria-describedby='dialog-studio-filter-variables'
          maxWidth={false}
        >
          <DialogTitle
            id="dialog-studio-filter-variables-title"
          >
            Select variables
            <div
              style={{
                minWidth: 600,
                // minHeight: 56
              }}
            >
              <TextField
                id='dialog-studio-filter-variables-text'
                label='Search...'
                margin="normal"
                className={classes.textField}
                value={this.state.variableSearch}
                onChange={e => this.setState({variableSearch: e.target.value})}
              />
            </div>
            <div>
              {
                this.state.selectVariables.length > 0
                ?
                this.state.selectVariables.length === 1
                  ?
                  (
                    <div>
                      {this.state.selectVariables[0].variable_name}
                    </div>
                  )
                  :
                  (
                    <div>
                      {this.state.selectVariables.length} selected
                    </div>  
                  )
                :
                null
              }
            </div>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                maxHeight: '400px',
                width: '100%'
              }}
            >
              <TableStyled>
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: 'center'
                      }}
                    />
                    <th>
                      ID
                    </th>
                    <th>
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.variables_info && this.state.variables_info.length > 0
                    ?
                      this.state.variables_info
                      .filter(variable => {
                        if (this.state.variableSearch.length < 3) {
                          return true
                        }

                        if (this.state.variableSearch === `${variable.variable_code}`) {
                          return true
                        }

                        const variableName = R.toUpper(variable.variable_name)
                        const variableSearch = R.toUpper(this.state.variableSearch)

                        if (variableName.search(variableSearch) >= 0) {
                          return true
                        } else {
                          return false
                        }
                      })
                      .map((variable, indexVariable) => {
                        return (
                          <tr
                            key={indexVariable}
                            onClick={() => this.changeSelectVariable(variable)}
                          >
                            <TableTdHoverStyled>
                              {
                                R.contains(variable.variable_code, this.state.selectVariables.map(item => item.variable_code))
                                ?
                                (
                                  <Icon>
                                    check_box
                                  </Icon>
                                )
                                :
                                (
                                  <Icon>
                                    check_box_outline_blank
                                  </Icon>
                                )
                              }
                            </TableTdHoverStyled>
                            <td>
                              {variable.variable_code}
                            </td>
                            <td>
                              {variable.variable_name}
                            </td>
                          </tr>
                        )
                      })
                    :
                    null
                  }
                </tbody>
              </TableStyled>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({dialogOpenVariables: false})}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* XAXIS */}
        <Dialog
          open={this.state.dialogCustomXAxis}
          onClose={() => this.setState({dialogCustomXAxis: false})}
          aria-labelledby='dialog-studio-custom-xaxis'
          scroll='body'
          // maxWidth='xl'
        >
          <DialogTitle
            id='dialog-studio-custom-xaxis'
          >
            Axis X
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                border: `1px solid ${grey['200']}`,
                padding: 5
              }}
            >
              {/* LABEL */}
              <TextField
                label="Label"
                className={classes.textField}
                value={this.state.xAxisNewLabel}
                onChange={e => this.setState({xAxisNewLabel: e.target.value})}
              />

              {/* COLOR PICKER */}
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyItems: 'center'
                }}
              >
                <div
                  style={{
                    backgroundColor: this.state.xAxisNewColor,
                    width: 100,
                    height: 30,
                    marginBottom: 5
                  }}
                />
                <HuePicker
                  color={this.state.xAxisNewColor}
                  onChange={color => this.setState({xAxisNewColor: color.hex})}
                />
              </div>
              
              {/* SELECT OPPOSITE */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.xAxisNewOpposite}
                    onChange={e => this.setState({xAxisNewOpposite: e.target.checked})}
                  />
                }
                label="Opposite"
              />

              {/* SELECT TYPE */}
              <FormControl
                className={classes.formControl}
              >
                <InputLabel
                  htmlFor="select-axis-type"
                >
                  Type
                </InputLabel>
                <Select
                  value={this.state.xAxisNewType}
                  onChange={e => this.setState({xAxisNewType: e.target.value})}
                  renderValue={selected => selected.name}
                >
                  {
                    AXIS_TYPES.map((axis, indexAxis) => {
                      return (
                        <MenuItem
                          key={indexAxis}
                          value={axis}
                        >
                          {axis.name}
                        </MenuItem>
                      )    
                    })
                  }
                </Select>
              </FormControl>
              
              {/* SELECT VARIABLE */}
              {
                this.state.xAxisNewType.type === CUSTOM_AXIS_VARIABLE
                  ?
                  (
                    <FormControl
                      className={classes.formControl}
                    >
                      <InputLabel
                        htmlFor="select-axis-type-variables"
                      >
                        Variable
                      </InputLabel>
                      <Select
                        value={this.state.xAxisNewTypeVariable}
                        onChange={e => this.setState({xAxisNewTypeVariable: e.target.value})}
                        renderValue={selected => selected.variable_name}
                      >
                        {
                          this.state.selectVariables.map((variable, indexVariable) => {
                            return (
                              <MenuItem
                                key={indexVariable}
                                value={variable}
                              >
                                {variable.variable_name}
                              </MenuItem>
                            )    
                          })
                        }
                      </Select>
                    </FormControl>
                  )
                  :
                  null
              }

              {/* SAVE BUTTON */}
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  className={classes.button}
                  style={{
                    borderRadius: 0,
                    backgroundColor: 'orange',
                    color: 'white'
                  }}
                  onClick={() => {
                    if (
                      this.state.xAxisNewType
                      && this.state.xAxisNewLabel.length
                      && this.state.xAxisNewColor
                    ) {
                      if (!this.state.xAxisNewId) {
                        const item = {
                          id: uuidv4(),
                          type: this.state.xAxisNewType,
                          variable: this.state.xAxisNewTypeVariable,
                          opposite: this.state.xAxisNewOpposite,
                          labels: {
                            format: '{value}',
                            style: {
                              color: this.state.xAxisNewColor
                            }
                          },
                          title: {
                            text: this.state.xAxisNewLabel,
                            style: {
                              color: this.state.xAxisNewColor
                            }
                          }
                        }

                        this.setState(
                          state => ({
                            xAxisItems: R.append(item, state.xAxisItems)
                          }),
                          () => {
                            this.setState({
                              xAxisNewId: null,
                              xAxisNewLabel: '',
                              xAxisNewColor: '',
                              xAxisNewOpposite: false,
                              xAxisNewType: '',
                              xAxisNewTypeVariable: null
                            })
                          }
                        )
                      } else {
                        const item = {
                          id: this.state.xAxisNewId,
                          type: this.state.xAxisNewType,
                          variable: this.state.xAxisNewTypeVariable,
                          opposite: this.state.xAxisNewOpposite,
                          labels: {
                            format: '{value}',
                            style: {
                              color: this.state.xAxisNewColor
                            }
                          },
                          title: {
                            text: this.state.xAxisNewLabel,
                            style: {
                              color: this.state.xAxisNewColor
                            }
                          }
                        }

                        this.setState(
                          state => ({
                            xAxisItems: state.xAxisItems.map(axis => {
                              if (state.xAxisNewId === axis.id) {
                                return item
                              } else {
                                return axis
                              }
                            })
                          }),
                          () => {
                            this.setState({
                              xAxisNewId: null,
                              xAxisNewLabel: '',
                              xAxisNewColor: '',
                              xAxisNewOpposite: false,
                              xAxisNewType: '',
                              xAxisNewTypeVariable: null
                            })
                          }
                        )
                      }
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
            
            <div
              style={{
                width: '100%',
                marginTop: 5,
                border: `1px solid ${grey['200']}`
              }}
            >
              <TableStyled>
                <thead>
                  <tr>
                    <td>
                      Label
                    </td>
                    <td>
                      Color
                    </td>
                    <td>
                      Opposite
                    </td>
                    <td>
                      Type
                    </td>
                    <td>
                      Variable
                    </td>
                    <td>
                      Actions
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.xAxisItems.map((item, indexItem) => {
                      return (
                        <tr
                          key={indexItem}
                        >
                          <td>
                            {item.title.text}
                          </td>
                          <td
                            style={{
                              backgroundColor: item.title.style.color
                            }}
                          />
                          <td>
                            {item.opposite}
                          </td>
                          <td>
                            {item.type.name}
                          </td>
                          <td>
                            {item.type.type === CUSTOM_AXIS_VARIABLE && item.variable ? item.variable.variable_name : ''}
                          </td>
                          <td>
                            <IconButton
                              onClick={() => {
                                this.setState({
                                  xAxisNewId: item.id,
                                  xAxisNewLabel: item.title.text,
                                  xAxisNewColor: item.title.style.color,
                                  xAxisNewOpposite: item.opposite,
                                  xAxisNewType: item.type,
                                  xAxisNewTypeVariable: item.variable
                                })
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                this.setState(state => ({
                                  xAxisItems: state.xAxisItems
                                    .filter(xAxisItem => xAxisItem.id !== item.id)
                                }))
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </TableStyled>
            </div>
            


          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({dialogCustomXAxis: false})}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* YAXIS */}
        <Dialog
          open={this.state.dialogCustomYAxis}
          onClose={() => this.setState({dialogCustomYAxis: false})}
          aria-labelledby='dialog-studio-custom-yaxis'
          scroll='body'
          // maxWidth='xl'
        >
          <DialogTitle
            id='dialog-studio-custom-yaxis'
          >
            Axis Y
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                border: `1px solid ${grey['200']}`,
                padding: 5
              }}
            >
              {/* LABEL */}
              <TextField
                label="Label"
                className={classes.textField}
                value={this.state.yAxisNewLabel}
                onChange={e => this.setState({yAxisNewLabel: e.target.value})}
              />

              {/* COLOR PICKER */}
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyItems: 'center'
                }}
              >
                <div
                  style={{
                    backgroundColor: this.state.yAxisNewColor,
                    width: 100,
                    height: 30,
                    marginBottom: 5
                  }}
                />
                <HuePicker
                  color={this.state.yAxisNewColor}
                  onChange={color => this.setState({yAxisNewColor: color.hex})}
                />
              </div>
              
              {/* SELECT OPPOSITE */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.yAxisNewOpposite}
                    onChange={e => this.setState({yAxisNewOpposite: e.target.checked})}
                  />
                }
                label="Opposite"
              />

              {/* SELECT TYPE */}
              <FormControl
                className={classes.formControl}
              >
                <InputLabel
                  htmlFor="select-axis-type"
                >
                  Type
                </InputLabel>
                <Select
                  value={this.state.yAxisNewType}
                  onChange={e => this.setState({yAxisNewType: e.target.value})}
                  renderValue={selected => selected.name}
                >
                  {
                    AXIS_TYPES.map((axis, indexAxis) => {
                      return (
                        <MenuItem
                          key={indexAxis}
                          value={axis}
                        >
                          {axis.name}
                        </MenuItem>
                      )    
                    })
                  }
                </Select>
              </FormControl>
              
              {/* SELECT VARIABLE */}
              {
                this.state.yAxisNewType.type === CUSTOM_AXIS_VARIABLE
                  ?
                  (
                    <FormControl
                      className={classes.formControl}
                    >
                      <InputLabel
                        htmlFor="select-yaxis-type-variables"
                      >
                        Variable
                      </InputLabel>
                      <Select
                        value={this.state.yAxisNewTypeVariable}
                        onChange={e => this.setState({yAxisNewTypeVariable: e.target.value})}
                        renderValue={selected => selected.variable_name}
                      >
                        {
                          this.state.selectVariables.map((variable, indexVariable) => {
                            return (
                              <MenuItem
                                key={indexVariable}
                                value={variable}
                              >
                                {variable.variable_name}
                              </MenuItem>
                            )    
                          })
                        }
                      </Select>
                    </FormControl>
                  )
                  :
                  null
              }

              {/* SAVE BUTTON */}
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  className={classes.button}
                  style={{
                    borderRadius: 0,
                    backgroundColor: 'orange',
                    color: 'white'
                  }}
                  onClick={() => {
                    if (
                      this.state.yAxisNewType
                      && this.state.yAxisNewLabel.length
                      && this.state.yAxisNewColor
                    ) {
                      if (!this.state.yAxisNewId) {
                        const item = {
                          id: uuidv4(),
                          type: this.state.yAxisNewType,
                          variable: this.state.yAxisNewTypeVariable,
                          opposite: this.state.yAxisNewOpposite,
                          labels: {
                            format: '{value}',
                            style: {
                              color: this.state.yAxisNewColor
                            }
                          },
                          title: {
                            text: this.state.yAxisNewLabel,
                            style: {
                              color: this.state.yAxisNewColor
                            }
                          }
                        }

                        this.setState(
                          state => ({
                            yAxisItems: R.append(item, state.yAxisItems)
                          }),
                          () => {
                            this.setState({
                              yAxisNewId: null,
                              yAxisNewLabel: '',
                              yAxisNewColor: '',
                              yAxisNewOpposite: false,
                              yAxisNewType: '',
                              yAxisNewTypeVariable: null
                            })
                          }
                        )
                      } else {
                        const item = {
                          id: this.state.yAxisNewId,
                          type: this.state.yAxisNewType,
                          variable: this.state.yAxisNewTypeVariable,
                          opposite: this.state.yAxisNewOpposite,
                          labels: {
                            format: '{value}',
                            style: {
                              color: this.state.yAxisNewColor
                            }
                          },
                          title: {
                            text: this.state.yAxisNewLabel,
                            style: {
                              color: this.state.yAxisNewColor
                            }
                          }
                        }

                        this.setState(
                          state => ({
                            yAxisItems: state.yAxisItems.map(axis => {
                              if (state.yAxisNewId === axis.id) {
                                return item
                              } else {
                                return axis
                              }
                            })
                          }),
                          () => {
                            this.setState({
                              yAxisNewId: null,
                              yAxisNewLabel: '',
                              yAxisNewColor: '',
                              yAxisNewOpposite: false,
                              yAxisNewType: '',
                              yAxisNewTypeVariable: null
                            })
                          }
                        )
                      }
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
            
            <div
              style={{
                width: '100%',
                marginTop: 5,
                border: `1px solid ${grey['200']}`
              }}
            >
              <TableStyled>
                <thead>
                  <tr>
                    <td>
                      Label
                    </td>
                    <td>
                      Color
                    </td>
                    <td>
                      Opposite
                    </td>
                    <td>
                      Type
                    </td>
                    <td>
                      Variable
                    </td>
                    <td>
                      Actions
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.yAxisItems.map((item, indexItem) => {
                      return (
                        <tr
                          key={indexItem}
                        >
                          <td>
                            {item.title.text}
                          </td>
                          <td
                            style={{
                              backgroundColor: item.title.style.color
                            }}
                          />
                          <td>
                            {item.opposite}
                          </td>
                          <td>
                            {item.type.name}
                          </td>
                          <td>
                            {item.type.type === CUSTOM_AXIS_VARIABLE && item.variable ? item.variable.variable_name : ''}
                          </td>
                          <td>
                            <IconButton
                              onClick={() => {
                                this.setState({
                                  yAxisNewId: item.id,
                                  yAxisNewLabel: item.title.text,
                                  yAxisNewColor: item.title.style.color,
                                  yAxisNewOpposite: item.opposite,
                                  yAxisNewType: item.type,
                                  yAxisNewTypeVariable: item.variable
                                })
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                this.setState(state => ({
                                  yAxisItems: state.yAxisItems
                                    .filter(yAxisItem => yAxisItem.id !== item.id)
                                }))
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </TableStyled>
            </div>
            


          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({dialogCustomYAxis: false})}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    )
  }
}

export default withStyles(styles)(StudioFilterComponent)
