import { Auth } from 'aws-amplify'
import axios from 'axios'
import { URL_API, URL_API_OLD, URL_NODE_API, URL_FISSION } from '../constants/url-constant'

export const getVariablesProvider = async (params) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken

  const URL = URL_NODE_API + '/studio/get-data/variables'

  const {data} = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getDataToStudioProvider = async (params) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken

  const URL = URL_NODE_API + '/studio/get-data'

  const {data} = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getDataGraphOldProvider = async (name, params) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken

  const URL = URL_NODE_API + '/user/parks'

  const {data} = await axios.get(URL, {
    timeout: 15000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return {result: data.parks_info}
}

export const getParksInfoProvider = async () => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken

  const URL = URL_NODE_API + '/user/parks'

  const {data} = await axios.get(URL, {
    timeout: 15000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data.parks_info
}

export const executeCustomProvider = async (payload, method='post') => {
    const {accessToken} = await Auth.currentSession();
    const {jwtToken} = accessToken;

    const {
        sendKeys=[],
        apiPath,
        ...extraPayload
    } = payload;

    const sendPayload = sendKeys.map(key => ({ [key]: payload[key] || extraPayload[key] })).reduce((acc, curr) => ({...acc, ...curr}), {});
    const url = `${URL_NODE_API}${apiPath}`;

    const {data} = await axios({
      method,
      url,
      ...({
        data: method === 'post' && sendPayload,
        params: method === 'get' ? { ...payload.params, ...sendPayload } : payload.params
      }),
      timeout: 1500000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    });
    
    return data.hasOwnProperty('data') ? data.data : data;
};

export const executePredictionProvider = async payload => {
  const {accessToken} = await Auth.currentSession();
  const {jwtToken} = accessToken;

  const {
      path,
      model_id,
      device_id,
      start_time,
      end_time
  } = payload;

  const URL = `${URL_NODE_API}/prediction/envelope${path}`;

  const {data} = await axios.get(URL, {
      timeout: 1500000,
      headers: {
        Authorization: jwtToken
      },
      params: {
          model_id,
          device_id,
          start_time,
          end_time
      }
  });
  
  return data.hasOwnProperty('data') ? data.data : data;
};

export const getDataGraphNewProvider = async (name = null, oldParams = {}, newParams = null) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken

  const URL = URL_NODE_API + '/management/new-data'
  // const URL = URL_NODE_API + '/management/retrieve_data'

  const params = newParams ? newParams : {
    token: jwtToken,
    procedure_name: name,
    time_range: oldParams.time_range,
    subpark_list: oldParams.subpark,
    timezone: 'America/Fortaleza',
  }

  const {data} = await axios.post(URL, params, {
    timeout: 1500000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  // console.log('newParams', newParams)

  // if (newParams.procedure_name === 'conn_point_producible_comparative_per_day') {
  //   console.log('getDataGraphNewProvider', newParams, data)
  // }
  // console.log('ON DATA PROVIDER RESULT', data)
  return data
}

export const getDataAlarmsProvider = async (name, new_params) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken

  const URL = URL_API + '/crud_status/retrieve_data'

  const params = {
    ...new_params,
    token: jwtToken,
    procedure_name: name,
    timezone: 'America/Fortaleza',
  }

  const {data} = await axios.post(URL, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data
}

export const getDataEnvelopeProvider = async (params) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  // const URL = URL_API + '/envelope/get_data'
  const URL = URL_NODE_API + '/envelope/new-data'

  const {data} = await axios.post(URL, params, {
    timeout: 100000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data
}
