import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone";
import { withStyles, IconButton } from "@material-ui/core";
import GridLayout from "react-grid-layout";
import * as R from "ramda";
import DeleteIcon from '@material-ui/icons/Delete'

import { DeviceContent } from "./DeviceStyle";
import { store } from "../../../../store/ProviderStore";
import WidgetDriver from "../../../../components/Driver/WidgetDriver";
import BaseGlobalFilter from "../../../../components/Header/BaseGlobalFilter";
import Selector from '../../../../components/Filter/Selector'

import PageWidgetPlaceholder from "../../../../components/Placeholder/PageWidgetPlaceholder";

import { REDUCER_DEVICE_GET_DATA, REDUCER_VIRTUAL_CLONE_GET_DATA } from "../../../../constants/reducers/widget-page-action-events";
import { applyFilter } from "../../../../helpers/filter-helper";
import { getParksInfoProvider } from "../../../../providers/get-data-old-provider";
import { REDUCER_UPDATE_DEVICE } from "../../../../constants/reducers/global-filter";
import { updateDateFilter } from "../../../../actions/DateFilterActions";

const PAGE_FIELD = "device";
const GRID_WIDTH_PADDING = 20;

const styles = theme => ({
  gridElement: {
    width: "100%"
  }
});

class DevicePage extends Component {
  constructor(props) {
    super(props);

    const elBody = window.document.getElementsByTagName("BODY")[0];

    const { [PAGE_FIELD]: Module } = this.props;
    const pageSelected = R.find(R.propEq("selected", true))(Module);

    this.state = {
      gridWidth: elBody.offsetWidth - GRID_WIDTH_PADDING * 2,
      widgets: [],
      enableEdit: false,
      currPage: pageSelected,
      forceUpdateFilters: false
    };

    this.gridContainerAnchor = React.createRef();
    this.currentDateRange = null;
    this.prevManagementId = null;
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName("body")[0];

    this.setState({
      gridWidth: body.offsetWidth - GRID_WIDTH_PADDING * 2
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizePageWatch);
    this.setState({forceUpdateFilters: true});
    // const { [PAGE_FIELD]: Module } = this.props;
    // const moduleSelected = R.find(R.propEq("selected", true))(Module);
    // this.executeFilter(moduleSelected);

    const params = new URLSearchParams(this.props.location.search);

    if (
      params.get("wtgs") ||
      (params.get("date_init") && params.get("date_final"))
    ) {
      this.setState({ verifyQueryParams: true });

      getParksInfoProvider()
        .then(async parks_info => {
          const state = store.getState();

          const moduleSelect = R.find(R.propEq("selected", true))(
            state.Dashboard.Device
          );

          const wtgsParksInfo = R.flatten(
            parks_info.map(park => {
              return park.subparks.map(subpark => {
                return subpark.wtgs.map(wtg => {
                  return {
                    ...wtg,
                    subpark_id: subpark.id,
                    subpark_name: subpark.name,
                    park_id: park.id,
                    park_name: park.name
                  };
                });
              });
            })
          );

          const wtg =
            params.get("wtgs") &&
            Array.isArray(JSON.parse(params.get("wtgs"))) &&
            JSON.parse(params.get("wtgs")).length > 0
              ? R.head(JSON.parse(params.get("wtgs")))
              : R.head(moduleSelect.filter.devices);
          const subpark = R.find(R.propEq("id", wtg))(wtgsParksInfo).subpark_id;

          const dateRange =
            params.get("date_init") && params.get("date_final")
              ? {
                  startDate: moment(params.get("date_init")),
                  endDate: moment(params.get("date_final"))
                }
              : {
                  startDate: moment(moduleSelect.filter.data_range.startDate),
                  endDate: moment(moduleSelect.filter.data_range.endDate)
                };

          store.dispatch(dispatch => {
            return dispatch({
              type: REDUCER_UPDATE_DEVICE,
              payload: {
                devices: [wtg],
                subparks: [subpark]
              }
            });
          });

          store.dispatch(
            updateDateFilter(
              null,
              dateRange.startDate,
              dateRange.endDate,
              null,
              null
            )
          );

          const moduleToReducer = (() => {
            const filter = {
              subparks: [subpark],
              devices: [wtg],
              data_range: {
                startDate: dateRange.startDate.format("YYYY-MM-DD 00:00:00"),
                endDate: dateRange.endDate.format("YYYY-MM-DD 23:59:59")
              }
            };

            const widgets = moduleSelect.widgets.map(widget =>
              R.assoc("data", null, R.assoc("update", true, widget))
            );
            const filterAndWidgetsInModule = R.assoc(
              "filter",
              filter,
              R.assoc("widgets", widgets, moduleSelect)
            );

            return filterAndWidgetsInModule;
          })();

          const modulesToReducer = state.Dashboard.Device.map(
            envelopeModule => {
              if (envelopeModule.id !== moduleSelect.id) {
                return envelopeModule;
              }

              return moduleToReducer;
            }
          );

          store.dispatch(dispatch => {
            dispatch({
              type: REDUCER_VIRTUAL_CLONE_GET_DATA,
              payload: modulesToReducer
            });
          });

          this.setState(
            { verifyQueryParams: false, currPage: moduleToReducer },
            () => {
              this.executeFilter(moduleToReducer);
            }
          );
        })
        .catch(error => console.error("ERROR QUERY PARAMS URL", error));
    } else {
      const { [PAGE_FIELD]: Module } = this.props;
      const moduleSelected = R.find(R.propEq("selected", true))(Module);
      this.executeFilter(moduleSelected);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizePageWatch);
  }


  async componentDidUpdate (prevProps, prevState) {

    const { forceUpdateFilters } = this.state;

    const { [PAGE_FIELD]: Module } = this.props;

    let currPage = R.find(R.propEq('selected', true))(Module);

    if ((forceUpdateFilters || prevProps.editMode) && currPage.widgets.length != prevState.widgets.length ) {

      let unique = false;

      if (currPage.type === 'FILTER_TYPE_WTG_ENVELOPE' || currPage.type === 'FILTER_TYPE_WTG') {
        unique = true;
      }

      this.props.updateEnvelopeFilter(unique ? [currPage.filter.devices[0]] : currPage.filter.devices, currPage.filter.subparks)


       this.executeFilter(currPage);
    }



    const promisesToResolve = this.state.widgets
      .filter(widget => Promise.resolve(widget.data) === widget.data)
      
    if(promisesToResolve.length > 0) {
      const widgetData = this.state.widgets.map(widget => widget.data);
      const promisesRes = await Promise.all(widgetData);
      const widgets = this.state.widgets.map((widget, index) => ({
        ...widget,
        data: promisesRes[index],
        loading: false
      }));

      this.setState({
        widgets
      });
    }
}

  changeLoading = loading => {
    if (this.state.loading != loading) {
      this.setState({
        loading
      });
    }
  };

  executeFilter = payload => {
    payload = R.find(R.propEq("selected", true))(this.props[PAGE_FIELD]);
    const widgets = applyFilter(payload);

    this.setState({
      widgets,
      currPage:payload

    });
  };

  selectPage = pageId => {
    const { [PAGE_FIELD]: Module } = this.props;

    let newModule = Module.map(management => {
      if (management.id === pageId) {
        return R.assoc("selected", true, management);
      }

      return R.assoc("selected", false, management);
    });

    const currPage = R.find(R.propEq("id", pageId))(Module);
    const widgets = applyFilter(currPage);

    this.props.updateProps("device", newModule);

    this.setState({
      currPage,
      widgets
    });
  };

  render() {
    const { classes, parks, history } = this.props;
    const { widgets, currPage } = this.state;

    if (!widgets || widgets.length == 0) {
      return (
        <DeviceContent>
            <BaseGlobalFilter
              mode={"view"}
              history={history}
              page={currPage}
              reducer_name={REDUCER_DEVICE_GET_DATA}
              module_field={"Device"}
              executeFilter={this.executeFilter}
              goToPage={this.selectPage}
              disabled={this.state.loading}
              enableEdit={() => this.setState({enableEdit: true})}
              {...this.props}
            >

            <Selector updateFilters={this.executeFilter}   page={currPage} />

         </BaseGlobalFilter>

          <PageWidgetPlaceholder />
        </DeviceContent>
      );
    }

    return (
      <DeviceContent>
        <BaseGlobalFilter
          mode={"view"}
          history={history}
          page={currPage}
          reducer_name={REDUCER_DEVICE_GET_DATA}
          module_field={"Device"}
          executeFilter={this.executeFilter}
          goToPage={this.selectPage}
          disabled={this.state.loading}
          enableEdit={() => this.setState({enableEdit: true})}
          {...this.props}
        >
            <Selector updateFilters={this.executeFilter}   page={currPage} />
        </BaseGlobalFilter>


        <div
          style={{
            padding: '0 0 0 15px'
          }}
        >
          {this.state.gridWidth ? (
            <GridLayout
              className={classes.gridElement}
              cols={12}
              rowHeight={200}
              items={50}
              width={this.state.gridWidth}
              compactType={'horizontal'}
              isDraggable={this.props.editMode}
              isResizable={this.props.editMode}
              preventCollision={true}
            >
              {
                widgets && widgets.map((widget, index) => {
                  return (
                    <div style={{
                      overflow: 'hidden',
                      backgroundColor: this.props.editMode ? '#0000003d' : 'none',
                      paddingBottom: this.props.editMode ? '3px' : 'none',

                    }} key={widget.id} data-grid={widget.grid} >

                      {/* this.props.editMode && <div  style={{ backgroundColor: 'white', textAlign: 'center' }} >
                        <IconButton
                          onClick={this.props.openDialogRemove(widget.id)}
                        >
                          <DeleteIcon />
                        </IconButton>

                        </div>
                      */}
                      <WidgetDriver key={`${index}${widget.id}`}
                        classes={classes}
                        selectedPage={currPage}
                        parks={parks}
                        history={history}
                        changeLoading={this.changeLoading}
                        widget={widget}
                        reducer_name={REDUCER_DEVICE_GET_DATA}
                        module_id={'Device'}
                        isEditing={this.props.editMode}
                        openRemoveDialog={this.props.openDialogRemove(widget.id)}
                      />
                    </div>
                  );
                })
              }
            </GridLayout>
          ) : (
            ""
          )}
        </div>
      </DeviceContent>
    );
  }
}

const mapStateToProps = ({ Dashboard, User }) => {
  const { Device } = Dashboard;
  const { parks_info } = User;

  return { Device, parks: parks_info };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DevicePage));
