import React, {
    useState,
    useEffect,
    useRef
} from 'react';

import {
    TableCell,
    Tooltip
} from '@material-ui/core';

const formatContentToString = content => {
    if(content && content.constructor === Array) {
        return content.join(' / ');
    }

    return content;
};

const formatContentToComponent = content => {
    if(content && content.constructor === Array) {
        return <ul>{content.map(cell => <li style={{padding: 0, marginLeft: -20}}>{cell}</li>)}</ul>;
    }

    return content;
};

const OverflowTextView = props => {
    const { children, style } = props;

    const ref = useRef();
    const [ isTooltipActive, setTooltipActive ] = useState(false);

    useEffect(() => {
        const dom = ref.current;
        if(dom && dom.offsetWidth < dom.scrollWidth) {
            setTooltipActive(true);
        }
    });

    const cell = (
        <div
            {...props}
            ref={ref}
            style={{
                minWidth: '40px',
                maxWidth: '160px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                verticalAlign: 'baseline',
                ...style
            }}
        >
            {formatContentToString(children)}
        </div>
    );

    return (
        isTooltipActive ?
            <Tooltip
                title={formatContentToComponent(children)}
            >
                {cell}
            </Tooltip>
        :
            cell
    )
};

export default OverflowTextView;