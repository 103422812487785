import {store} from '../store/ProviderStore'
import * as R from 'ramda'
import { REDUCER_UPDATE_DEVICE } from '../constants/reducers/global-filter';
import { REDUCER_UPDATE_ENVELOPE_BY_DEVICE } from '../constants/reducers/global-filter';


export const updateEnvelopeFilter = (devices, subparks) => {
  return dispatch => {
    
    let filters = {};
    filters['devices'] = devices && devices.constructor === Array ? devices : [devices];
    filters['subparks'] = subparks && subparks.constructor === Array ? subparks : [subparks];

    if(filters['devices'].length > 0 && filters['subparks'].length > 0 ){

      return dispatch({
        type: REDUCER_UPDATE_DEVICE,
        payload: filters
      })
    }

  }

}


export const updateEnvelopeFilterByDevice = (envelope_id) => {
  return dispatch => {
 
    let filters = {};
    filters['envelope_id'] = envelope_id;

    return dispatch({
      type: REDUCER_UPDATE_ENVELOPE_BY_DEVICE,
      payload: filters
    })
  }

}