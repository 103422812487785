import React from "react";
import { CircularProgress } from "@material-ui/core";

import { getMovAssetConsProvider } from "../../../../../../../../providers/asset-provider";

class MovementAssetConsumable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    getMovAssetConsProvider()
      .then(data => {
        this.setState({
          data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        console.error("getMovAssetConsProvider", error);
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress />
          <span
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </span>
        </div>
      );
    }

    return (
      <div
        style={{
          height: "100%",
          width: "100%"
        }}
      >
        MovementAssetConsumable
      </div>
    );
  }
}

export default MovementAssetConsumable;
