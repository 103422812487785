import {
    useState,
    useEffect
} from 'react';

import { useSelector } from 'react-redux';

import {
    getComponentsHistoryNew,
    getComponentsModelsInfo,
    getStoragesInfo,
    getAcquiredComponents,
    getAcquiredComponentsGrouped,
    getAlertObjects,
    getResourceProvider
} from '../../../../providers/asset-provider';

import * as R from 'ramda';
import { useTranslation } from 'react-i18next';


export const useProps = () => {

    const { t } = useTranslation();

    const stateOptions = {
        ['new']: { id: 'new', name: t('New') },
        ['recondicioned']: { id: 'recondicioned', name: t('Reconditioned') },
        ['under-analysis']: { id: 'under-analysis', name: t('Under Analysis') }
    };

    const convertToRow = ({
        id,
        log_time,
        user_hash,
        procedure,
        procedure_date,
        model,
        part_number,
        serial_number,
        manufacturer,
        supplier,
        previous,
        now,
        acquired_date,
        expire_date,
        manufacturing_date,
        warranty,
        state,
        documents,
        work_order_id,
        time_to_expire,
        time_to_end_warranty,
        data,
        storage,
        application_expiration,
        warranty_application_date,
        received_date,
        acquisition_value,
        extra_info,
        observation,
        invoice_number,
        component_model_id,
        storage_id,
        placement
    }) => {
        let toExpirationTime = time_to_expire;
        let warrantyEnd = time_to_end_warranty;

        if (toExpirationTime == null) {
            const date = new Date(acquired_date);
            date.setFullYear(date.getFullYear() + 2);
            toExpirationTime = Math.floor((date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
        }

        if (warrantyEnd == null) {
            const date = new Date(acquired_date);
            date.setFullYear(date.getFullYear() + 2);
            warrantyEnd = Math.floor((date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
        }

        const statusFiltered = stateOptions && state && Object.values(stateOptions).filter(status => status.id === state);

        let row = {
            id: id,
            component: model,
            serial: serial_number,
            acquisitionDate: acquired_date,
            receivedDate: data && data.receivedDate,
            fabricationDate: manufacturing_date,
            expireDate: toExpirationTime,
            warrantyEnd,
            manufacturer,
            supplier,
            entryStock: storage,
            componentStatus: statusFiltered && statusFiltered.length === 1 && statusFiltered[0].name,
            componentDocuments: documents,
            lastMovementDate: procedure_date,
            lastMovementSupplier: data && data.procedureSupplier,
            workOrderId: work_order_id,
            applicationExpireDate: data && data.applicationExpireDate,
            applicationWarrantyEnd: data && data.applicationWarrantyEnd,
            nextMovementDate: data && data.nextMovementDate,
            lastMovementDocuments: data && data.lastMovementDocuments,
            partNumber: part_number,
            procedure,
            now,
            previous,
            log_time,
            user_hash,
            warrantyEnd: warranty,
            expireDate: expire_date,
            applicationWarrantyEnd: warranty_application_date,
            applicationExpireDate: application_expiration,
            receivedDate: received_date,
            acquisitionValue: acquisition_value,
            placement,
            observation,
            invoice_number,
            component_model_id,
            storage_id
        };

        if (extra_info) {
            row = { ...row, ...extra_info }
        }

        return row;
    }

    const headMapAgreggated = {
        name: 'Component Model',
        part_number: 'Part Number',
        stock_quantity: 'Stock Quantity',
        storage: 'Current Location',
        icms: 'ICMS',
        frete: 'Frete',
        ncm: 'NCM',
        placements: 'Placements',
        last_acquisition_value: 'Last Acquisition Value'
    };


    const headMap = {
        component: 'Component Model',
        serial: 'Serial Number',
        partNumber: 'Part Number',
        acquisitionDate: 'Acquisition Date',
        acquisitionValue: 'Acquisition Value',
        receivedDate: 'Received Date',
        fabricationDate: 'Fabrication Date',
        manufacturer: 'Manufacturer',
        supplier: 'Supplier',
        entryStock: 'Current Location',
        componentStatus: 'Component Status',
        componentDocuments: "Component's Documents",
        lastMovementDate: 'Application Date',
        lastMovementSupplier: 'Application Supplier',
        workOrderId: 'Work Order ID',

        applicationExpireDate: 'To Application Expiration',
        applicationWarrantyEnd: 'To Application Warranty',
        expireDate: 'Expiration Date',
        warrantyEnd: 'End Of Warranty',

        nextMovementDate: 'To Next Application',
        lastMovementDocuments: "Application's Documents",

        placement: 'Placement',
        observation: 'Comments',
        invoice_number: 'Invoice Number',

        FILIAL: 'Filial',
        EMPRESA: 'Empresa',
        DEPOSITO: "Depósito",
        SERIE_NOTA_FISCAL: 'Série Nota Fiscal',
        SEQUENCIA_ITEM_NOTA_FISCAL: 'Sequência Item Nota Fiscal',
        ncm: 'NCM',
        icms: 'ICMS',
        original_part_number: 'Número da Peça Customizado',
        frete: 'Frete'
    };

    const movementsHeadMap = {
        model: 'Component Model',
        part_number: 'Part Number',
        serial_number: 'Serial Number',
        procedure: 'Event',
        procedure_date: 'In',
        origin_name: 'From',
        destiny_name: 'To',
        user_hash: 'Approved By'
    };

    const { AssetsManagement, parks_info: parksInfo } = useSelector(state => state.Dashboard || state.User);

    const currPageFiltered = AssetsManagement && AssetsManagement.filter(page => page.selected);
    const currPage = currPageFiltered && currPageFiltered.length > 0 ? currPageFiltered[0] : null;

    const [modulePage, setModulePage] = useState(currPage);

    const [resources, setResources] = useState(currPage.route || []);
    const [records, setRecords] = useState([]);






    const [activeResource, setActiveResource] = useState(null);

    const [dataUploaded, setDataUploaded] = useState(false);
    const [open, setOpened] = useState(false);
    const [detailInfo, setDetailInfo] = useState({});
    const [rawHistories, setRawHistories] = useState({});
    const [movementHistories, setMovementHistories] = useState({});
    const [Rows, setRows] = useState([]);
    const [RowsAggregated, setRowsAggregated] = useState([]);

    const [mounted, finishMount] = useState(false);

    const [resourceDialogOpened, openResourceDialog] = useState(false);

    const [acquisitionDialogOpened, openAcquisitionDialog] = useState(false);
    const [importDialogOpened, openImportDialog] = useState(false);

    const [alertObjects, setAlertObjects] = useState([]);


    const [resource, setResource] = useState(null);
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);
    const [storages, setStorages] = useState(null);

    const [savingInfo, saveInfo] = useState(false);

    const [models, setmodels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [aggregate, setAggregate] = useState(true);

    useEffect(() => {
        if (!mounted) {
            (async () => {
                const { groupedComponents } = await getAcquiredComponentsGrouped();
                const { componentsHistory } = await getComponentsHistoryNew();
                const modelsData = await getResourceProvider('model');

                const models_list = modelsData.resource.map(resource => {
                    return {
                        "name": resource.name,
                        "id": resource.id
                    }
                })

                setmodels(models_list);


                const ressoursesData = modulePage.route ? await getResourceProvider(modulePage.route) : [];
                const requestAlertObjects = await getAlertObjects();
                setAlertObjects(requestAlertObjects.alertObjects);

                const movements = componentsHistory.map(movement => ({
                    [movement.component_id]: [movement]
                })).reduce((acc, curr) => {
                    const key = Object.keys(curr)[0];
                    return ({
                        ...acc,
                        [key]: [
                            ...(acc[key] || []),
                            ...curr[key]
                        ]
                    });
                }, {});

                const initialRowsGrouped = groupedComponents.map(group => group.total_quantity ?
                    { ...group, stock_quantity: `${group.stock_quantity} / ${group.total_quantity}` }
                    :
                    group
                );

                setRowsAggregated(initialRowsGrouped)
                setMovementHistories(movements);
                setResources(ressoursesData);

                const Options = await getComponentsModelsInfo();
                setOptions(Options);

                const Storages = await getStoragesInfo();
                setStorages(Storages);

                const rawHistories = componentsHistory.map(movement => {
                    return {
                        ...movement,
                        model: movement && movement.component_model_name,
                        serial_number: movement && movement.data && movement.data.serial_number
                    };
                });

                setRawHistories(rawHistories);
                finishMount(true);
            })();
        }
    }, [mounted, activeResource]);


    const getComponents = (component_model_id, storage_id) => new Promise((resolve, reject) => {

        setLoading(true);

        getAcquiredComponents(component_model_id, storage_id)
            .then(({ acquiredComponents }) => {
                const initialRows = acquiredComponents.map(component => convertToRow(component));
                setRows(initialRows);

                resolve();
            })
            .catch(e => { reject(e); })
            .finally(() => {
                setLoading(false);
            });
    });

    const getMovement = async id => new Promise((resolve, reject) => {

        setLoading(true);

        getComponentsHistoryNew(id)
            .then(({ componentsHistory }) => {
                const movements = componentsHistory.map(movement => ({
                    [movement.component_id]: [movement]
                })).reduce((acc, curr) => {
                    const key = Object.keys(curr)[0];
                    return ({
                        ...acc,
                        [key]: [
                            ...(acc[key] || []),
                            ...curr[key]
                        ]
                    });
                }, {});

                resolve(movements);
            })
            .catch(e => { reject(e); })
            .finally(() => {
                setLoading(false);
            });
    });

    const selectModulePage = pageId => {


        let newModuleState = AssetsManagement.map(Module => {
            if (Module.id === pageId) {
                return R.assoc('selected', true, Module)
            }

            return R.assoc('selected', false, Module)
        })

        const newPage = R.find(R.propEq('id', pageId))(newModuleState);
        setModulePage(newPage);
        setAggregate(true);
        finishMount(false);

    };

    const TYPE_GRID = 0;
    const TYPE_COLUMN = 1;

    return {
        stateOptions,
        convertToRow,
        headMap,
        headMapAgreggated,
        movementsHeadMap,
        AssetsManagement,
        parksInfo,
        setRecords,
        records,
        currPage,
        modulePage,
        setModulePage,
        open,
        setDataUploaded,
        dataUploaded,
        models,
        alertObjects,
        setOpened,
        detailInfo,
        setDetailInfo,
        rawHistories,
        setRawHistories,
        movementHistories,
        setResources,
        setMovementHistories,
        Rows,
        RowsAggregated,
        setRows,
        mounted,
        resources,
        finishMount,
        acquisitionDialogOpened,
        openAcquisitionDialog,
        importDialogOpened,
        openImportDialog,
        openResourceDialog,
        resourceDialogOpened,
        data,
        setData,
        resource,
        setResource,
        options,
        setOptions,
        storages,
        activeResource,
        setActiveResource,
        setStorages,
        savingInfo,
        saveInfo,
        selectModulePage,
        TYPE_GRID,
        TYPE_COLUMN,
        getComponents,
        loading,
        setAggregate,
        aggregate,
        getMovement
    };
};

export default {
    useProps
}