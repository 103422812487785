
import React from "react";
import moment from "moment-timezone";
import { withStyles,  IconButton} from "@material-ui/core";
import GridLayout from "react-grid-layout";
import * as R from "ramda";

import { store } from "../../../../store/ProviderStore";
import { EnvelopeContent } from "./EnvelopeStyle";
import WidgetDriver from "../../../../components/Driver/WidgetDriver";
import BaseGlobalFilter from "../../../../components/Header/BaseGlobalFilter";
import PageWidgetPlaceholder from "../../../../components/Placeholder/PageWidgetPlaceholder";
import { REDUCER_VIRTUAL_CLONE_GET_DATA } from "../../../../constants/reducers/widget-page-action-events";
import { applyFilter } from "../../../../helpers/filter-helper";
import {
  REDUCER_UPDATE_ENVELOPE_BY_DEVICE,
  REDUCER_UPDATE_DATE,
  REDUCER_UPDATE_DEVICE
} from "../../../../constants/reducers/global-filter";
import { updateParksFilter } from "../../../../actions/MenuTreeAction";
import { getParksInfoProvider } from "../../../../providers/get-data-old-provider";
import { updateDateFilter } from "../../../../actions/DateFilterActions";
import { REDUCER_MANAGEMENT_GET_DATA } from "../../../../constants/reducers/management-get-data";
import DeleteIcon from '@material-ui/icons/Delete'
import Selector from '../../../../components/Filter/Selector'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { updateFilterSubparks, updateFilterTimeRange } from './actions/UpdateFilterAction'

const PAGE_FIELD = "envelope";
const GRID_WIDTH_PADDING = 10;

const styles = () => ({
  gridElement: {
    width: "100%"
  }
});

class EnvelopePage extends React.Component {
  constructor(props) {
    super(props);

    const elBody = window.document.getElementsByTagName("BODY")[0];

    const { [PAGE_FIELD]: Module } = this.props;
    const pageSelected = R.find(R.propEq("selected", true))(Module);

    this.state = {
      gridWidth: elBody.offsetWidth - GRID_WIDTH_PADDING * 2,
      widgets: [],
      enableEdit: false,
      currPage: pageSelected,
      verifyQueryParams: false,
      enableEdit: false,
      forceUpdateFilters: false
    };

    this.gridContainerAnchor = React.createRef();
    this.currentDateRange = null;
    this.prevManagementId = null;
    this.BaseGlobalFilterRef = React.createRef();
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName("body")[0];

    this.setState({
      gridWidth: body.offsetWidth - GRID_WIDTH_PADDING * 2
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizePageWatch);
    this.setState({forceUpdateFilters: true})
    // const { [PAGE_FIELD]: Module } = this.props;
    // const moduleSelected = R.find(R.propEq("selected", true))(Module);
    // this.executeFilter(moduleSelected);

    // console.log(moment().format(), moment().subtract(2, 'days').format())

    const params = new URLSearchParams(this.props.location.search);

    if (
      params.get("wtgs") ||
      (params.get("date_init") && params.get("date_final")) ||
      (params.get("envelope") && !Number.isNaN(params.get("envelope")))
    ) {
      this.setState({ verifyQueryParams: true });

      getParksInfoProvider()
        .then(async parks_info => {
          const state = store.getState();

          const moduleSelect = R.find(R.propEq("selected", true))(
            state.Dashboard.Envelope
          );

          const wtgsParksInfo = R.flatten(
            parks_info.map(park => {
              return park.subparks.map(subpark => {
                return subpark.wtgs.map(wtg => {
                  return {
                    ...wtg,
                    subpark_id: subpark.id,
                    subpark_name: subpark.name,
                    park_id: park.id,
                    park_name: park.name
                  };
                });
              });
            })
          );

          const wtg =
            params.get("wtgs") &&
            Array.isArray(JSON.parse(params.get("wtgs"))) &&
            JSON.parse(params.get("wtgs")).length > 0
              ? R.head(JSON.parse(params.get("wtgs")))
              : R.head(moduleSelect.filter.devices);
          const subpark = R.find(R.propEq("id", wtg))(wtgsParksInfo).subpark_id;

          const dateRange =
            params.get("date_init") && params.get("date_final")
              ? {
                  startDate: moment(params.get("date_init")),
                  endDate: moment(params.get("date_final"))
                }
              : {
                  startDate: moment(moduleSelect.filter.data_range.startDate),
                  endDate: moment(moduleSelect.filter.data_range.endDate)
                };

          const envelope =
            params.get("envelope") && !Number.isNaN(params.get("envelope"))
              ? Number(params.get("envelope"))
              : moduleSelect.filter.envelope_id;

          store.dispatch(dispatch => {
            return dispatch({
              type: REDUCER_UPDATE_DEVICE,
              payload: {
                devices: [wtg],
                subparks: [subpark]
              }
            });
          });

          store.dispatch(
            updateDateFilter(
              null,
              dateRange.startDate,
              dateRange.endDate,
              null,
              null
            )
          );

          store.dispatch(dispatch => {
            return dispatch({
              type: REDUCER_UPDATE_ENVELOPE_BY_DEVICE,
              payload: {
                envelope_id: envelope
              }
            });
          });

          const moduleToReducer = (() => {
            const filter = {
              subparks: [subpark],
              devices: [wtg],
              data_range: {
                startDate: dateRange.startDate.format("YYYY-MM-DD 00:00:00"),
                endDate: dateRange.endDate.format("YYYY-MM-DD 23:59:59")
              },
              envelope_id: envelope
            };

            const widgets = moduleSelect.widgets.map(widget =>
              R.assoc("data", null, R.assoc("update", true, widget))
            );
            const filterAndWidgetsInModule = R.assoc(
              "filter",
              filter,
              R.assoc("widgets", widgets, moduleSelect)
            );

            return filterAndWidgetsInModule;
          })();

          const modulesToReducer = state.Dashboard.Envelope.map(
            envelopeModule => {
              if (envelopeModule.id !== moduleSelect.id) {
                return envelopeModule;
              }

              return moduleToReducer;
            }
          );

          store.dispatch(dispatch => {
            dispatch({
              type: REDUCER_VIRTUAL_CLONE_GET_DATA,
              payload: modulesToReducer
            });
          });

          this.setState(
            { verifyQueryParams: false, currPage: moduleToReducer },
            () => {
              this.executeFilter(moduleToReducer);
            }
          );
        })
        .catch(error => console.error("ERROR QUERY PARAMS URL", error));
    } else {
      const { [PAGE_FIELD]: Module } = this.props;
      const moduleSelected = R.find(R.propEq("selected", true))(Module);
      this.executeFilter(moduleSelected);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizePageWatch);
  }


  async componentDidUpdate (prevProps, prevState) {

    const { forceUpdateFilters } = this.state;

    const { [PAGE_FIELD]: Module } = this.props;

    let currPage = R.find(R.propEq('selected', true))(Module);

    if ((forceUpdateFilters || prevProps.editMode) && currPage.widgets.length != prevState.widgets.length ) {


      this.props.updateEnvelopeFilter([currPage.filter.devices[0]], currPage.filter.subparks )  
      currPage.filter.devices = [currPage.filter.devices[0]]

      this.executeFilter(currPage);
    }


    const promisesToResolve = !currPage.predictive ? 
      this.state.widgets
        .filter(widget => Promise.resolve(widget.data) === widget.data)
    :
      this.state.widgets.filter(widget => widget.loading);

    if( promisesToResolve.length > 0 ) {
      if( !currPage.predictive ) {
        const widgetData = this.state.widgets.map(widget => widget.data);
      
        const promisesRes = await Promise.all(widgetData);
        const widgets = this.state.widgets.map((widget, index) => ({
          ...widget,
          data: promisesRes[index],
          loading: false
        }));

        this.setState({
          widgets
        });
      } else {
          const { widgets } = this.state;
          const widgetsData = await Promise.all(widgets.map(widget => widget.data.then(res => res).catch(err => {})));
          
          // const widgetsRes = widgetsData.map((data, widgetPos) => ({ ...widgets[widgetPos], data: ( typeof(data) === 'string' && data[0] === '{' ? JSON.parse(data) : data ), loading: false }));

          const widgetsRes = widgetsData.map((data, widgetPos) => ({ ...widgets[widgetPos], data, loading: false }));

          console.log('Updated widgets ', {widgetsRes})

          this.setState({
              widgets: widgetsRes
          });
      }
    }
  }

  changeLoading = loading => {
    if (this.state.loading != loading) {
      this.setState({
        loading
      });
    }
  };

  executeFilter = payload => {
    const widgets = applyFilter(payload);

    this.setState({
      widgets,
      currPage:payload
    });
  };

  selectPage = pageId => {
    const { [PAGE_FIELD]: Module } = this.props;
    const currPage = R.find(R.propEq("id", pageId))(Module);
    const widgets = applyFilter(currPage);
    this.setState({
      currPage,
      widgets
    });
  };

  render() {




    if (this.state.verifyQueryParams) {
      return (
        <EnvelopeContent>
          <PageWidgetPlaceholder />
        </EnvelopeContent>
      );
    }

    const { classes, parks, history } = this.props;
    const { widgets, currPage } = this.state;

    if (!widgets || widgets.length == 0) {
      return (
        <EnvelopeContent>
            <BaseGlobalFilter
              routerSearch={this.props.location.search}
              mode={"view"}
              history={history}
              page={currPage}
              reducer_name={REDUCER_VIRTUAL_CLONE_GET_DATA}
              module_field={"Envelope"}
              executeFilter={this.executeFilter}
              disabled={this.state.loading}
              goToPage={this.selectPage}
              enableEdit={() => this.setState({enableEdit: true})}
              {...this.props}

              // enable or disable this props to change from new to old version
              predictive={currPage.predictive}
            >
              <Selector updateFilters={this.executeFilter}   page={currPage} />
            </BaseGlobalFilter>
          <PageWidgetPlaceholder />
        </EnvelopeContent>
      );
    }

    // console.log('Redring widgets ', widgets)

    return (
      <EnvelopeContent>
        {/* <div style={{ marginTop: "2%" }} /> */}
        <BaseGlobalFilter
          routerSearch={this.props.location.search}
          mode={"view"}
          history={history}
          page={currPage}
          reducer_name={REDUCER_VIRTUAL_CLONE_GET_DATA}
          module_field={"Envelope"}
          executeFilter={this.executeFilter}
          disabled={this.state.loading}
          goToPage={this.selectPage}
          enableEdit={() => this.setState({enableEdit: true})}
          {...this.props}

          // enable or disable this props to change from new to old version
          predictive={currPage.predictive}
        >
            <Selector updateFilters={this.executeFilter}   page={currPage} />
        </BaseGlobalFilter>
        
        <div
          style={{
            // padding: '0 0 0 15px'
            maxHeight: '100%',
            width: this.state.gridWidth,
            margin: '0 auto'
          }}
        >
          {this.state.gridWidth ? (
            <GridLayout
              className={classes.gridElement}
              cols={12}
              rowHeight={200}
              items={50}
              width={this.state.gridWidth}
              compactType={'horizontal'}
              isDraggable={this.props.editMode}
              isResizable={this.props.editMode}
              preventCollision={true}
            >
              {
                widgets && widgets.map((widget, index) => {
                  return (
                    <div style={{
                      overflow: 'hidden',
                      backgroundColor: this.props.editMode ? '#0000003d' : 'none',
                      paddingBottom: this.props.editMode ? '3px' : 'none',

                    }} key={widget.id} data-grid={widget.grid} >

                      { /*this.props.editMode && <div  style={{ backgroundColor: 'white', textAlign: 'center' }} >
                        <IconButton
                          onClick={this.props.openDialogRemove(widget.id)}
                        >
                          <DeleteIcon />
                        </IconButton>

                        </div>
                      */}
                      <WidgetDriver key={`${index}${widget.id}`}
                        classes={classes}
                        selectedPage={currPage}
                        parks={parks}
                        changeLoading={this.changeLoading}
                        history={history}
                        widget={widget}
                        reducer_name={REDUCER_VIRTUAL_CLONE_GET_DATA}
                        module_id={'Envelope'}
                        isEditing={this.props.editMode}
                        openRemoveDialog={this.props.openDialogRemove(widget.id)}
                      />
                    </div>
                  );
                })
              }
            </GridLayout>
          ) : (
            ""
          )}
        </div>
      </EnvelopeContent>
    );
  }
}



const mapStateToProps = ({Dashboard, User}) => {
  // const {Envelope} = Dashboard
  const {parks_info} = User

  return { parks: parks_info, Dashboard }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({updateFilterSubparks, updateFilterTimeRange}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EnvelopePage))
