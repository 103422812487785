import React, { Component } from 'react'

import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import * as moment from 'moment'
import static_data from './data.json';

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { CSVLink } from "react-csv";

import FlipContainer from '../../../components/Container/FlipContainer'

import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  LinearProgress,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  Paper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider,
  Icon,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';

import styles from './styles/styles.js';
import NoPrint from '../../../components/Print/NoPrint'

class GraphicInteractiveTableHydro extends Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      colors: [
        '#99ff66',
        '#F4645C',  //red
        '#B414DC',
        '#FCDC34',  // yellow
        '#4C65A7',
        '#FC9018',
        '#0A70D2',
        '#99ff66'   // green
      ],
      columns: [],
      column_sort: null,
      data: null,
      is_crescent: false,
      dataTable: [],
      filtered_table: [],
      filtered: false,
      filter_selecteds: []
    }

  }

  componentDidMount() {
    this.getSettings(this.getSettings.bind(this));
    setInterval(this.inc, 1000);
  }


  shouldComponentUpdate(nextProps, nextState) {

    let { items } = nextProps;

    if (this.props.subpark != nextProps.subpark || this.props.showTable != nextProps.showTable || this.props.showExport != nextProps.showExport) {

      return true;

    }

    if (this.state.column_sort != nextState.column_sort || this.state.is_crescent != nextState.is_crescent) {

      return true;
    }


    if (this.state.column_sort != nextState.column_sort || this.state.is_crescent != nextState.is_crescent || this.state.filtered != nextState.filtered || this.props.showSettings != nextProps.showSettings || this.props.showTable != nextProps.showTable || this.state.columns != nextState.columns || this.state.dataTable != nextState.dataTable || this.state.filtered_table != nextState.filtered_table) {

      return true;

    }


    if (items["table_alarm_data2#1"] || items["table_alarm_data2#2"]) {


      if (items["table_alarm_data2#1"] === this.props.items["table_alarm_data2#1"] && items["table_alarm_data2#2"] === this.props.items["table_alarm_data2#2"]) {


        return false;
      }

      return true;

    }


    return false;

    // return true;


  }


  showLoading = () => (
    <div style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={(30)} />
      <div style={{
        marginTop: '20px'
      }}>
        Loading...
      </div>
    </div>
  )


  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = (checkbox, extra_columns) => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);

    if (extra_columns) {
      // ;
      this.setState({ prevColumns: this.state.columns });
      this.state.columns = this.state.columns.map((column, index) => (
        extra_columns[String(index)] ? `${column}/\n${extra_columns[String(index)]}` : column
      ));
    }
  };

  getSettings() {
    // let data = BAR_DECORATOR_LIST.map(decorator => (
    //   this.state[`switch${decorator.name}`] ?
    //   {
    //     name: `${decorator.name}`,
    //     params: 'params_post_treatment' in decorator && decorator.params_post_treatment ?
    //               decorator.params_post_treatment(
    //                 Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //               )
    //               : Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //   }this.state.dataTable
    //   : null
    // )).filter(decorator => decorator);
    //
    // return data;
  }

  formatDataToExport(data) {
    return data.map(function (table) {
      return table.values
    });
  }

  setFilter(params) {


    let order = !this.state.is_crescent
    this.setState({ column_sort: params.index, is_crescent: order })
  }

  orderRows(rows, column_sort, is_crescent) {


    const sort_anchor = rows.map((row, index) => {

      return { idx: index, sort_value: row.values[column_sort] }
    }).sort((a, b) => {
      if (a.sort_value == 'red' && (b.sort_value == 'green' || b.sort_value == 'orange')) {
        return is_crescent ? 1 : -1
      } else if (b.sort_value == 'red' && (a.sort_value == 'green' || a.sort_value == 'orange')) {
        return is_crescent ? -1 : 1
      } else if (a.sort_value == 'orange' && b.sort_value == 'green') {
        return is_crescent ? 1 : -1
      } else if (b.sort_value == 'orange' && a.sort_value == 'green') {
        return is_crescent ? -1 : 1
      } else if (a.sort_value == 'green' && b.sort_value == 'orange') {
        return is_crescent ? -1 : 1
      } else if (b.sort_value == 'green' && a.sort_value == 'orange') {
        return is_crescent ? 1 : -1
      } else if (a.sort_value == b.sort_value && ['green', 'orange', 'red'].indexOf(a.sort_value) >= 0) {
        return 0
      } else {

        let date = moment(a.sort_value);

        if (date.isValid() != true) {

          if (column_sort == 4 || column_sort == 5 || column_sort == 3) {

            if (parseInt(a.sort_value.replace("h", "")) && parseInt(b.sort_value.replace("h", ""))) {
              return parseInt(a.sort_value.replace("h", "")) < parseInt(b.sort_value.replace("h", "")) ?
                is_crescent ? -1 : 1
                :
                parseInt(a.sort_value.replace("h", "")) > parseInt(b.sort_value.replace("h", "")) ?
                  is_crescent ? 1 : -1
                  :
                  0


            }

          }


        }

        return a.sort_value < b.sort_value ?
          is_crescent ? -1 : 1
          :
          a.sort_value > b.sort_value ?
            is_crescent ? 1 : -1
            :
            0
      }
    })

    return rows.map((row, index) => rows[sort_anchor[index].idx])
  }


  showVariables(params) {

    let selecteds = this.state.filter_selecteds;


    let column_id = 1; //type
    let aux = []


    if (selecteds.indexOf(params.type) == -1) {

      if (params.type === 'all') {
        selecteds = ['Event', 'Indefinido', 'Warning', 'Trip'];
      }

      selecteds.push(params.type);

    } else {

      if (params.type === 'all') {
        selecteds = [];

      } else {
        if (selecteds.indexOf('all') != -1) {
          selecteds.splice(selecteds.indexOf('all'), 1);
        }
        selecteds.splice(selecteds.indexOf(params.type), 1);
      }

    }

    if (params.filter > 0) {


      params.dataTable.map((element, index) => {

        if (selecteds.indexOf(element.values[column_id]) != -1) {

          aux.push(element)

        }

      }
      );

      this.setState((state) => ({ dataTable: aux }));
      this.setState((state) => ({ filtered: true }));

    }
    else {

      this.setState((state) => ({ dataTable: params.dataTable }));
      this.setState((state) => ({ filtered: false }));

    }


    this.setState((state) => ({ filter_selecteds: selecteds }));


  }


  render() {

    const { classes, showTable, font_size, showSettings, graphic, loading, items, subpark, showExport } = this.props;
    const { id, widths } = graphic;
    let { data, filter_selecteds } = this.state;

    if (subpark == 1) {
      if (items["table_alarm_data2#1"]) {
        data = items["table_alarm_data2#1"];
      }
    }

    if (subpark == 2) {
      if (items["table_alarm_data2#2"]) {
        data = items["table_alarm_data2#2"];
      }
    }

    if (!data) {
      return (
        <Paper className={classes.paperElement} elevation={0}>
          {this.showLoading()}
        </Paper>
      )
    }

    const left_cell_width = 1;
    const max_cells_width = 12 - left_cell_width;


    var on_render_state = {
      ...this.state,
      data: data,
      widths: widths
    };

    this.state.columns = data.columns;



    if (on_render_state.data.rows) {
      let column_id = 1;  //type
      let aux = []
      on_render_state.data.rows.map((element, index) => {
        aux.push(element.values[column_id])
      }
      );
      this.state.filtered_table = aux.filter((el, i, a) => i === a.indexOf(el))
    }

    if (!this.state.filtered) {
      this.state.dataTable = on_render_state.data.rows
    }

    if (this.state.column_sort >= 0) {


      if (on_render_state.data.rows) {
        on_render_state.data.rows = this.orderRows(on_render_state.data.rows, this.state.column_sort, this.state.is_crescent)
        on_render_state.data.rows = on_render_state.data.rows.map(row => {
          if (row.internal_table) {
            row.internal_table.rows = this.orderRows(row.internal_table.rows, this.state.column_sort, this.state.is_crescent)
          }

          return row
        })
      }
    }

    on_render_state.widths = 'widths' in on_render_state.data ?
      on_render_state.data.widths
      : 'widths' in on_render_state ?
        on_render_state.widths
        :
        new Array(on_render_state.data.rows[0].values.length).fill(1)


    if (on_render_state.data.rows) {
      on_render_state.data.rows.map(row => (
        row.values[0] =
        row.values[0] == 'Average Generator Speed 10M vs Generator Speed St. Dev. 10M' ? 'Generator Speed I'
          : row.values[0] == 'Average Active Power 10M vs Trafo 3 winding temperature 10M' ? 'Trafo Temperature III'
            : row.values[0] == 'Average Active Power 10M vs Average Reactive Power 10M' ? 'Reactive Power'
              : row.values[0] == 'Average Active Power 10M vs Bearing N.D.E. Temperature 10M' ? 'NDE Bearing Temperature'
                : row.values[0] == 'Average Active Power 10M vs Average Stator Active Power 10M' ? 'Stator Act. Power'
                  : row.values[0] == 'Average Rotor Speed 10M vs Rotor Speed St. Dev. 10M' ? 'Rotor Speed I'
                    : row.values[0] == 'Average Active Power 10M vs Trafo 2 winding temperature 10M' ? 'Trafo Temperature II'
                      : row.values[0] == 'Average Active Power 10M vs Generator windings temperature 2 10M' ? 'Gen. Windings Temperature II'
                        : row.values[0] == 'Average Active Power 10M vs Generators sliprings temperature 10M' ? 'Gen. Slipring'
                          : row.values[0] == 'Average Active Power 10M vs Generator windings temperature 3 10M' ? 'Gen. Windings Temperature III'
                            : row.values[0] == 'Average Active Power 10M vs Gearbox bearing temperature 10M' ? 'Gearbox Bearing Temperature'
                              : row.values[0] == 'Average Active Power 10M vs Average Rotor Speed 10M' ? 'Rotor Speed II'
                                : row.values[0] == 'Average Wind Speed 10M vs Average Active Power 10M' ? 'Measured Power Curve'
                                  : row.values[0] == 'Average Active Power 10M vs Average Generator Speed 10M' ? 'Generator Speed II'
                                    : row.values[0] == 'Average Generator Speed 10M vs Average Rotor Speed 10M' ? 'Rotor Speed III'
                                      : row.values[0] == 'Average Active Power 10M vs Bearing D.E. Temperature 10M' ? 'DE Bearing Temperature'
                                        : row.values[0] == 'Average Active Power 10M vs Average Grid Voltage 10M' ? 'Grid Voltage'
                                          : row.values[0] == 'Average Active Power 10M vs Yaw Misalignment = (WTG Nacelle Position) - (MetMast Wind Dir)' ? 'Yaw Misalignment'
                                            : row.values[0] == 'Average Active Power 10M vs Gearbox oil temperature 10M' ? 'Gearbox Oil'
                                              : row.values[0] == 'Average Active Power 10M vs Hidraulic group pressure 10M' ? 'Hidraulic Group'
                                                : row.values[0] == 'Average Active Power 10M vs Average Pitch Angle 10M' ? 'Pitch Angle'
                                                  : row.values[0] == 'Average Active Power 10M vs Generator windings temperature 1 10M' ? 'Gen. Windings Temperature I'
                                                    : row.values[0] == 'Average Active Power 10M vs Trafo 1 winding temperature 10M' ? 'Trafo Temperature I'
                                                      : row.values[0]
      ));

      on_render_state.data.rows.map(row => (
        row.values[2] =
        row.values[2] == 'Average Generator Speed 10M vs Generator Speed St. Dev. 10M' ? 'Generator Speed I'
          : row.values[2] == 'Average Active Power 10M vs Trafo 3 winding temperature 10M' ? 'Trafo Temperature III'
            : row.values[2] == 'Average Active Power 10M vs Average Reactive Power 10M' ? 'Reactive Power'
              : row.values[2] == 'Average Active Power 10M vs Bearing N.D.E. Temperature 10M' ? 'NDE Bearing Temperature'
                : row.values[2] == 'Average Active Power 10M vs Average Stator Active Power 10M' ? 'Stator Act. Power'
                  : row.values[2] == 'Average Rotor Speed 10M vs Rotor Speed St. Dev. 10M' ? 'Rotor Speed I'
                    : row.values[2] == 'Average Active Power 10M vs Trafo 2 winding temperature 10M' ? 'Trafo Temperature II'
                      : row.values[2] == 'Average Active Power 10M vs Generator windings temperature 2 10M' ? 'Gen. Windings Temperature II'
                        : row.values[2] == 'Average Active Power 10M vs Generators sliprings temperature 10M' ? 'Gen. Slipring'
                          : row.values[2] == "Average Active Power 10M vs Generator’s sliprings temperature 10M" ? 'Gen. Slipring'
                            : row.values[2] == 'Average Active Power 10M vs Generator windings temperature 3 10M' ? 'Gen. Windings Temperature III'
                              : row.values[2] == 'Average Active Power 10M vs Gearbox bearing temperature 10M' ? 'Gearbox Bearing Temperature'
                                : row.values[2] == 'Average Active Power 10M vs Average Rotor Speed 10M' ? 'Rotor Speed II'
                                  : row.values[2] == 'Average Wind Speed 10M vs Average Active Power 10M' ? 'Measured Power Curve'
                                    : row.values[2] == 'Average Active Power 10M vs Average Generator Speed 10M' ? 'Generator Speed II'
                                      : row.values[2] == 'Average Generator Speed 10M vs Average Rotor Speed 10M' ? 'Rotor Speed III'
                                        : row.values[2] == 'Average Active Power 10M vs Bearing D.E. Temperature 10M' ? 'DE Bearing Temperature'
                                          : row.values[2] == 'Average Active Power 10M vs Average Grid Voltage 10M' ? 'Grid Voltage'
                                            : row.values[2] == 'Average Active Power 10M vs Yaw Misalignment = (WTG Nacelle Position) - (MetMast Wind Dir)' ? 'Yaw Misalignment'
                                              : row.values[2] == 'Average Active Power 10M vs Gearbox oil temperature 10M' ? 'Gearbox Oil'
                                                : row.values[2] == 'Average Active Power 10M vs Hidraulic group pressure 10M' ? 'Hidraulic Group'
                                                  : row.values[2] == 'Average Active Power 10M vs Average Pitch Angle 10M' ? 'Pitch Angle'
                                                    : row.values[2] == 'Average Active Power 10M vs Generator windings temperature 1 10M' ? 'Gen. Windings Temperature I'
                                                      : row.values[2] == 'Average Active Power 10M vs Trafo 1 winding temperature 10M' ? 'Trafo Temperature I'
                                                        : row.values[2]
      ));
    }

    let hs_index = on_render_state.data.columns.indexOf('Health Score');
    if (hs_index >= 0) {
      on_render_state.data.rows.map(row => {
        let hs_value = row.internal_table && row.internal_table.rows ?
          row.internal_table.rows.map(internal_row => internal_row.values[hs_index])
          :
          null

        if (hs_value) {
          row.values[hs_index] = hs_value.reduce((prev, curr) => prev < curr ? prev : curr);
        }

        return row;
      });
    }

    let hs_date_index = on_render_state.data.columns.indexOf('Inicio');
    if (hs_date_index >= 0) {
      on_render_state.data.rows.map(rows => {

        let date = moment(rows.values[hs_date_index].slice(0, 19));

        rows.values[hs_date_index] = date.format('YYYY-MM-DD HH:mm:ss')

        return rows;
      });
    }

    let hs_date_index_2 = on_render_state.data.columns.indexOf('Fim');
    if (hs_date_index_2 >= 0) {
      on_render_state.data.rows.map(rows => {

        let date = moment(rows.values[hs_date_index_2].slice(0, 19));

        if (date.isValid() == true) {

          rows.values[hs_date_index_2] = date.format('YYYY-MM-DD HH:mm:ss')

        }

        return rows;
      });
    }

    return (

      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable || showSettings ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront}>


            <List className={classes.table}>
              <ListItem style={{ padding: 0 }}>
                <Grid container spacing={0} style={{ padding: 0 }}>
                  <Grid key={`${id}1`} item xs={left_cell_width}
                    className={classes.tableItem} direction="column" align="center"
                  >
                    <ListItemText></ListItemText>
                  </Grid>
                  {
                    on_render_state.data.columns.map((name, index) => (
                      <Grid key={`${id}${index}1`} item xs={on_render_state.widths[index]}
                        className={classes.tableItem} direction="column" align="center"
                      >

                        <ListItemText classes={{ primary: classes.listItemText }}
                          primary={this.state.columns[index]}
                        />

                        <IconButton
                          className={classes.margin}
                          style={{
                            width: 32,
                            height: 32,
                            color: '#000000'
                          }}
                          onClick={this.setFilter.bind(this, { name: name, index: index })}
                        >
                          <Icon>arrow_drop_down</Icon>
                        </IconButton>

                      </Grid>

                    ))
                  }
                </Grid>
              </ListItem>
              {
                /*on_render_state.data.rows ?
                on_render_state.data.rows.map((row, index) => {*/

                this.state.dataTable ?
                  this.state.dataTable.map((row, index) => {
                    return (
                      <React.Fragment>
                        <ListItem style={{ padding: 0 }} key={`${id}${index}2`} >
                          <Grid container spacing={0} style={{ padding: 0 }} >
                            <Grid key={`${id}${index}21`} item xs={left_cell_width}
                              className={classes.tableItem} direction="column" align="center"
                            >
                              {
                                row.internal_table ?
                                  <Checkbox
                                    checked={this.state[`${id}${index}21`]}
                                    onChange={this.checkboxToggle(
                                      `${id}${index}21`,
                                      'extra_columns' in row ? row.extra_columns : null
                                    )}
                                    value={`${id}${index}21`}
                                    icon={<Icon>arrow_drop_down</Icon>}
                                    checkedIcon={<Icon>arrow_drop_up</Icon>}
                                  />
                                  :
                                  null
                              }
                            </Grid>
                            {
                              row.values.map((value, index) => {
                                let val_color = null;
                                switch (value) {
                                  case 'red':
                                    val_color = this.state.colors[1];
                                    break;
                                  case 'green':
                                    val_color = this.state.colors[7];
                                    break;
                                  case 'yellow':
                                    val_color = this.state.colors[3];
                                    break;
                                  case 'orange':
                                    val_color = this.state.colors[5];
                                    break;
                                  case 'grey':
                                    val_color = '#dbdbdb';
                                    break;
                                  default:
                                    if (value) {
                                      if (value.constructor == String) {
                                        if (value.indexOf('#') >= 0) {
                                          val_color = value
                                        }
                                      }
                                    }
                                    break;
                                }

                                return (
                                  <Grid key={`${id}${index}22`} item xs={on_render_state.widths[index]}
                                    className={classes.tableItem} direction="column" align="center"
                                  >
                                    {
                                      val_color ?
                                        <Icon className={classes.icon} style={{ color: val_color }}>
                                          brightness_1
                                      </Icon>
                                        :
                                        value && value.constructor == Number && 'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[index] ?
                                          <Grid container spacing={0} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[index] * 25 : 0}px` }}>
                                            <Grid key={`grid_number_${id}${index}22`} item xs={7}
                                              className={classes.tableItemProgress} direction="column" align="right"
                                            >
                                              <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                            </Grid>
                                            <Grid key={`grid_bar_${id}${index}22`} item xs={5}
                                              className={classes.tableItemProgress} direction="column" align="left"
                                            >
                                              <LinearProgress variant="determinate" value={
                                                Math.ceil((parseInt(value) - on_render_state.data.boundaries[index][0]) * 100 / (on_render_state.data.boundaries[index][1] - on_render_state.data.boundaries[index][0]))
                                              } valueBuffer={100} />
                                            </Grid>
                                          </Grid>
                                          :
                                          value && value.constructor == Number ?
                                            <Grid container spacing={0} style={{ padding: 0 }}>
                                              <Grid key={`grid_number_${id}${index}22`} item xs={12}
                                                className={classes.tableItemProgress} direction="column" align="center"
                                              >
                                                <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                              </Grid>
                                            </Grid>
                                            :
                                            value == 0 ?
                                              <Grid container spacing={0} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[index] * 25 : 0}px` }}>
                                                <Grid key={`grid_number_${id}${index}22`} item xs={7}
                                                  className={classes.tableItemProgress} direction="column" align="right"
                                                >
                                                  <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                </Grid>
                                                <Grid key={`grid_bar_${id}${index}22`} item xs={5}
                                                  className={classes.tableItemProgress} direction="column" align="left"
                                                >
                                                  <LinearProgress variant="determinate" value={
                                                    Math.ceil((parseInt(value) - on_render_state.data.boundaries[index][0]) * 100 / (on_render_state.data.boundaries[index][1] - on_render_state.data.boundaries[index][0]))
                                                  } valueBuffer={100} />
                                                </Grid>
                                              </Grid>
                                              :
                                              <ListItemText classes={{ primary: classes.listItemText }} primary={value} />
                                    }
                                  </Grid>
                                );
                              })
                            }
                          </Grid>
                        </ListItem>
                        {
                          row.internal_table ? (
                            <Collapse in={this.state[`${id}${index}21`]} timeout="auto" unmountOnExit>
                              <ListItem style={{ padding: 0 }} key={`collapse_${id}${index}21`}>
                                <Grid container spacing={0} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[index] * 1 : 0}px` }}>
                                  {
                                    row.internal_table ?
                                      row.internal_table.rows.map((item, index) => {
                                        if ('values' in item) {
                                          return item.values.map((value, values_index, array) => {
                                            let val_color = null;
                                            switch (value) {
                                              case 'red':
                                                val_color = this.state.colors[1];
                                                break;
                                              case 'green':
                                                val_color = this.state.colors[7];
                                                break;
                                              case 'yellow':
                                                val_color = this.state.colors[3];
                                                break;
                                              case 'grey':
                                                val_color = '#dbdbdb';
                                                break;
                                              default:
                                                break;
                                            }

                                            return (
                                              values_index == 0 ?
                                                <React.Fragment>
                                                  <Grid key={`${id}${index}${values_index}220`} item xs={left_cell_width}
                                                    className={classes.tableItem} direction="column" align="center"
                                                  >
                                                  </Grid>
                                                  <Grid key={`${id}${index}${values_index}221`} item xs={on_render_state.widths[values_index]}
                                                    className={classes.tableItem} direction="column" align="center"
                                                  >
                                                    {
                                                      val_color ?
                                                        <Icon className={classes.icon} style={{ color: val_color, paddingRight: '0px' }}>
                                                          brightness_1
                                                        </Icon>
                                                        :
                                                        value && value.constructor == Number && 'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[values_index] ?
                                                          <Grid container spacing={0} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[values_index] * 25 : 0}px` }}>
                                                            <Grid key={`grid_number_${id}${index}22`} item xs={7}
                                                              className={classes.tableItemProgress} direction="column" align="right"
                                                            >
                                                              <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                            </Grid>
                                                            <Grid key={`grid_bar_${id}${index}22`} item xs={5}
                                                              className={classes.tableItemProgress} direction="column" align="left"
                                                            >
                                                              <LinearProgress variant="determinate" value={
                                                                Math.ceil((parseInt(value) - on_render_state.data.boundaries[values_index][0]) * 100 / (on_render_state.data.boundaries[values_index][1] - on_render_state.data.boundaries[values_index][0]))
                                                              } valueBuffer={100} />
                                                            </Grid>
                                                          </Grid>
                                                          :
                                                          value && value.constructor == Number ?
                                                            <Grid container spacing={0} style={{ padding: 0 }}>
                                                              <Grid key={`grid_number_${id}${index}22`} item xs={12}
                                                                className={classes.tableItemProgress} direction="column" align="center"
                                                              >
                                                                <ListItemText classes={{ primary: classes.listItemText }} primary={value} />
                                                              </Grid>
                                                            </Grid>
                                                            :
                                                            // val.constructor == Number ?
                                                            //   <Grid container spacing={0} style={{padding: 0}}>
                                                            //     <Grid key={`grid_number_${id}${index}221`} item xs={4}
                                                            //       className={classes.tableItemProgress} direction="column" align="right"
                                                            //     >
                                                            //       <ListItemText classes={{primary:classes.listItemText}} primary={parseInt(value)} />
                                                            //     </Grid>
                                                            //     <Grid key={`grid_bar_${id}${index}221`} item xs={8}
                                                            //       className={classes.tableItemProgress} direction="column" align="left"
                                                            //     >
                                                            //       <LinearProgress variant="determinate" value={
                                                            //         'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[values_index] ?
                                                            //           Math.ceil((parseInt(value) - on_render_state.data.boundaries[values_index][0]) * 100 / (on_render_state.data.boundaries[values_index][1] - on_render_state.data.boundaries[values_index][0]))
                                                            //         :
                                                            //           parseInt(value)
                                                            //       } valueBuffer={100}/>
                                                            //     </Grid>
                                                            //   </Grid>
                                                            // :
                                                            <ListItemText classes={{ primary: classes.listItemText }} primary={value} />
                                                    }
                                                  </Grid>
                                                </React.Fragment>
                                                :
                                                <Grid key={`${id}${index}${values_index}221`} item xs={on_render_state.widths[values_index]}
                                                  className={classes.tableItem} direction="column" align="center"
                                                >
                                                  {
                                                    val_color ?
                                                      <Icon className={classes.icon} style={{ color: val_color, paddingRight: '0px' }}>
                                                        brightness_1
                                                      </Icon>
                                                      :
                                                      value && value.constructor == Number ?
                                                        <Grid container spacing={0} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[values_index] * 25 : 0}px` }}>
                                                          <Grid key={`grid_number_${id}${index}221`} item xs={7}
                                                            className={classes.tableItemProgress} direction="column" align="right"
                                                          >
                                                            <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                          </Grid>
                                                          <Grid key={`grid_bar_${id}${index}221`} item xs={5}
                                                            className={classes.tableItemProgress} direction="column" align="left"
                                                          >
                                                            <LinearProgress variant="determinate" value={
                                                              'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[values_index] ?
                                                                Math.ceil((parseInt(value) - on_render_state.data.boundaries[values_index][0]) * 100 / (on_render_state.data.boundaries[values_index][1] - on_render_state.data.boundaries[values_index][0]))
                                                                :
                                                                parseInt(value)
                                                            } valueBuffer={100} />
                                                          </Grid>
                                                        </Grid>
                                                        :
                                                        value == 0 ?
                                                          <Grid container spacing={0} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[values_index] * 25 : 0}px` }}>
                                                            <Grid key={`grid_number_${id}${index}221`} item xs={7}
                                                              className={classes.tableItemProgress} direction="column" align="right"
                                                            >
                                                              <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                            </Grid>
                                                            <Grid key={`grid_bar_${id}${index}221`} item xs={5}
                                                              className={classes.tableItemProgress} direction="column" align="left"
                                                            >
                                                              <LinearProgress variant="determinate" value={
                                                                'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[values_index] ?
                                                                  Math.ceil((parseInt(value) - on_render_state.data.boundaries[values_index][0]) * 100 / (on_render_state.data.boundaries[values_index][1] - on_render_state.data.boundaries[values_index][0]))
                                                                  :
                                                                  parseInt(value)
                                                              } valueBuffer={100} />
                                                            </Grid>
                                                          </Grid>

                                                          : <ListItemText classes={{ primary: classes.listItemText }} primary={value} />
                                                  }
                                                </Grid>
                                            );
                                          });
                                        } else if ('header' in item && 'value' in item) {
                                          return (
                                            <React.Fragment>
                                              <Grid key={`${id}${index}221`} item xs={1} className={classes.tableItem}>
                                                <ListItemText> </ListItemText>
                                              </Grid>
                                              <Grid key={`${id}${index}222`} item xs={3} className={classes.tableItem}>
                                                <ListItemText>{item.header}</ListItemText>
                                              </Grid>
                                              <Grid key={`${id}${index}223`} item xs={8} className={classes.tableItem}>
                                                <ListItemText>{item.value}</ListItemText>
                                              </Grid>
                                            </React.Fragment>
                                          );
                                        }
                                      })
                                      :
                                      null
                                  }
                                </Grid>
                              </ListItem>
                            </Collapse>
                          ) : null
                        }
                      </React.Fragment>
                    );
                  })
                  :
                  null
              }
            </List>


          </div>

          <div className={classes.flipContainerBack}>
            {

              showSettings ?

                <div style={{ paddingLeft: 2, padding: 0, margin: 0 }}>
                  <div style={{ width: '100%', display: 'flex', border: 'solid 1px #d9d9d9' }} >
                    <h4 style={{ textAlign: 'left', paddingLeft: 16, fontSize: '9px' }}>Filter</h4>
                  </div>
                  <Table className={classes.table} style={{ paddingLeft: 2, fontSize: '9px', padding: '0 !important', border: 'solid 1px #d9d9d9', width: '101%', backgroundColor: 'white' }}>
                    <TableHead>
                      <TableRow style={{
                        height: 15,
                        fontSize: '9px',
                        paddingLeft: '2px'
                      }}>
                        <TableCell
                          padding="checkbox"
                          style={{
                            height: 'auto !important',
                            textAlign: 'center',
                            fontSize: '9px',
                            paddingLeft: '2px'
                          }}
                        >
                          <Checkbox

                            onChange={this.showVariables.bind(this, { type: 'all', dataTable: on_render_state.data.rows, filter: 0 })}

                            checked={filter_selecteds.indexOf('all') != -1}

                            style={{ width: font_size, height: font_size, color: 'black', paddingLeft: '2px' }}

                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '9px' }} />}

                            checkedIcon={<CheckBoxIcon style={{ fontSize: '9px' }} />}

                            indeterminateIcon={<Icon style={{ fontSize: '9px', color: '#5b5b5b' }} >indeterminate_check_box</Icon>}
                          />
                        </TableCell>
                        <TableCell
                          key={'header1'}
                          padding={'default'}
                          style={{
                            height: 'auto !important',
                            textAlign: 'left',
                            fontSize: '9px',
                            paddingLeft: '2px'
                          }}
                        >
                          Select All
                          </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {

                        this.state.filtered_table.map(element => (

                          <TableRow style={{
                            maxHeight: '36px',
                            height: 0,
                            paddingLeft: '2px'
                          }}>
                            <TableCell
                              padding="checkbox"
                              style={{
                                height: 'auto !important',
                                textAlign: 'center',
                                paddingLeft: '2px'
                              }}
                            >
                              <Checkbox

                                onChange={this.showVariables.bind(this, { type: element, dataTable: on_render_state.data.rows, filter: 1 })}

                                style={{ width: font_size, height: font_size, color: 'black', paddingLeft: '2px' }}

                                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '12px' }} />}

                                checked={filter_selecteds.indexOf(element) != -1}

                                checkedIcon={<CheckBoxIcon style={{ fontSize: '9px' }} />}

                                indeterminateIcon={<Icon style={{ fontSize: '9px', color: '#5b5b5b' }} >indeterminate_check_box</Icon>}
                              />
                            </TableCell>
                            <TableCell
                              key={'header1'}
                              padding={'default'}
                              style={{
                                height: 'auto !important',
                                textAlign: 'left',
                                fontSize: '9px',
                                paddingLeft: '2px'
                              }}

                            >
                              {element}
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>

                </div>

                :

                <NoPrint>
                  <h1 style={{
                    width: '100%',
                    height: 'auto',
                    textAlign: 'center'
                  }}>Returning to table...</h1>
                </NoPrint>
            }

          </div>
        </div>

        <Dialog
          onClose={this.props.closeDialogExportOverview}
          open={showExport}
          aria-labelledby="dialog_overview_export"
        >
          <DialogTitle id="dialog_overview_export">
            Export Graphic Data
            </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please, select the file format to export.
              </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button
              color="primary"
              style={{ backgroundColor: "green", color: "#fff" }}
              autoFocus
              onClick={(e) => this.props.closeDialogExportOverviewExcel(this.formatDataToExport(this.state.dataTable), on_render_state.data.columns, "Alarmes")}

            >
              Excel
              </Button>


            <CSVLink
              style={{ textDecoration: "none" }}
              filename={"Alarmes"}
              onClick={this.props.closeDialogExportOverview}
              data={this.formatDataToExport(this.state.dataTable)}
              headers={on_render_state.data.columns}

            >
              <Button
                color="primary"
                variant="contained"
                style={{ backgroundColor: "#e65100", color: "#fff" }}
              >
                CSV
                </Button>
            </CSVLink>

            <Button onClick={this.props.closeDialogExportOverview} color="primary">
              Back
              </Button>
          </DialogActions>
        </Dialog>


      </div>

    )
  }
}

export default withStyles(styles)(GraphicInteractiveTableHydro);
