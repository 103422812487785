import React from 'react';
import useContainer from './Container';
import ImageInput from '../ImageInput';


export default props => {

    const { src, onChange, loading, broken, edit } = useContainer(props);


    return (
        <ImageInput src={src} onChange={onChange} loading={loading} broken={broken} edit={edit} />
    );
};