import React from 'react'
import {
  Route
} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'

import { ROUTE_DASHBOARD_ASSET_ASSET_MODEL, ROUTE_DASHBOARD_ASSET_MOVEMENT, ROUTE_DASHBOARD_ASSET_ASSET, ROUTE_DASHBOARD_ASSET_COMPONENT, ROUTE_DASHBOARD_ASSET_CONSUMABLE, ROUTE_DASHBOARD_ASSET_SERVICE } from '../../../../constants/route-constant'
import AssetModelPage from './pages/AssetModelPage/AssetModelPage'
import MovementPage from './pages/MovementPage/MovementPage';
import AssetAssetPage from './pages/AssetAssetPage/AssetAssetPage';
import ComponentPage from './pages/ComponentPage/ComponentPage';
import ServicePage from './pages/ServicePage/ServicePage';
import ConsumablePage from './pages/ConsumablePage/ConsumablePage';

const styles = {}

class AssetPage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          path={ROUTE_DASHBOARD_ASSET_ASSET_MODEL}
          render={props => (<AssetModelPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_ASSET}
          render={props => (<AssetAssetPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_MOVEMENT}
          render={props => (<MovementPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_COMPONENT}
          render={props => (<ComponentPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONSUMABLE}
          render={props => (<ConsumablePage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_SERVICE}
          render={props => (<ServicePage {...props} />)}
        />
      </div>
    )
  }
}

export default withStyles(styles)(AssetPage)
