import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { URL_API } from '../../../../constants/url-constant';


const onClick = async link => {
    await getToken()
        .then(async token => {
            await getDownloadLink(token, link)
                .then(downloadReport)
                .catch(handleError)
        })
        .catch(handleError);
};

const getConfig = (auth, timeRange) => ({
    params: timeRange,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': auth
    }
});

const getToken = () => new Promise(async (resolve, reject) => {
    await Auth.currentSession()
        .then(({ accessToken }) => { resolve(accessToken.jwtToken) })
        .catch(error => reject(error));
});

const getDownloadLink = async (token, link) => new Promise(async (resolve, reject) => {

    const URL = `${URL_API}/report/download_report`;

    const params = { file: link };

    await axios.post(URL, params, getConfig(token))
        .then(resolve)
        .catch(reject);
});

const downloadReport = response => {
    if (response && response.data && response.data.return) {
        window.location = response.data.return;
    }
};

const handleError = error => { console.log(error) };

const addOnClick = nesterItems => nesterItems.map(nesterItem => (
    {
        ...nesterItem,
        items: nesterItem.items ?
            nesterItem.items.map(nestedItem => (
                {
                    ...nestedItem,
                    onClick: async () => {
                        await onClick(nestedItem.link);
                    }
                }))
            :
            []
    })
);


export default () => {

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [timeRange, setTimeRange] = useState({ start_time: null, end_time: null })


    const dashboard = useSelector(({ Dashboard }) => Dashboard);
    const { DateFilter } = dashboard || {};
    const { data_range } = DateFilter || {};
    const { startDate, endDate } = data_range || {};


    const fetchReports = async ({ start_time, end_time }) => {
        const URL = `${URL_API}/report/get_report_list`;

        setLoading(true);
        await getToken()
            .then(async token => {
                try {
                    const { data } = await axios.get(URL, getConfig(token, { start_time, end_time }));

                    setItems(addOnClick(data.return));
                } catch (e) {
                    setItems([]);
                }
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });

    };

    const executeFilter = () => {
        setTimeRange({ start_time: startDate, end_time: endDate });
    };


    useEffect(() => {
        if (timeRange.start_time && timeRange.end_time) {
            fetchReports({ start_time: timeRange.start_time, end_time: timeRange.end_time });
        }
    }, [timeRange.start_time, timeRange.end_time]);


    return {
        loading,
        items,
        filter: {
            module_field: 'reportpage',
            page: hardcodedPage,
            reportpage: [hardcodedPage],
            executeFilter,
            goToPage: () => { }
        }
    };
};

const hardcodedPage = {
    filter: {
        data_range: {
            endDate: '2019-03-02',
            startDate: '2019-03-01'
        }
    },
    page_type: 2,
    name: "Reports",
    id: 8000,
    type: "FILTER_TYPE_WTG",
    widgets: [],
    selected: true
};