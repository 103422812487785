import React from 'react'
import {Route} from 'react-router-dom'

import AssetConsumableListPage from './pages/AssetConsumableListPage/AssetConsumableListPage';
import { ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE_EDIT, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE_CREATE } from '../../../../../../../../constants/route-constant';
import AssetConsumableChangePage from './pages/AssetConsumableChangePage/AssetConsumableChangePage';

class AssetConsumablePage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          exact
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE}
          render={props => (<AssetConsumableListPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE_CREATE}
          render={props => (<AssetConsumableChangePage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE_EDIT}
          render={props => (<AssetConsumableChangePage {...props} />)}
        />
      </div>
    )
  }
}

export default AssetConsumablePage
