import React from 'react';
import useStatistics from './hooks/useStatisticsPage';
import useFilter from './hooks/useStatisticsFilter';
import WidgetDriver from '../../../../components/Driver/WidgetDriver';


export default ({ data_range }) => {

    const { pieProps, tableProps } = useStatistics(data_range);
    const { widget } = tableProps;
    const { data } = widget;

    const { filter, filteredTable, filterTable } = useFilter(data);

    return (
        <div style={{ margin: 24 }}>
            <div style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: 400
            }} className='fit-page'>
                <div style={{ margin: 6, flex: 4 }}>
                    <WidgetDriver {...pieProps} tableFilter={filter} filterTable={filterTable} />
                </div>
                <div style={{ margin: 6, flex: 7 }}>
                    <WidgetDriver {...tableProps} widget={{ ...widget, data: filteredTable }} />
                </div>
            </div>
        </div>
    );
};