import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import styles from './styles/EventsTableStyle';
import {
    ACTION_SELECTION_PRETTY_NAME
} from './placeholder-data/FixedDataFromAPI';

import TooltipListContent from './TooltipListContent';
import ActionButton from './ActionButton';
import ActionBaseDialog from './ActionBaseDialog';

import { MainForm, SplitForm, DeleteForm, ApproveForm, UnapproveForm } from './DialogComponents';

import { PageContext } from './Contexts';
import { withTranslation } from 'react-i18next';

class EventsTableToolbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            classes,
            deviceName,
            title,
            selectedAmt,
            selectedSparse,
            currActions = [],
            actions: availableActions,
            t
        } = this.props;

        const validMultipleSelection = selectedAmt > 1 && (selectedSparse === false || currActions.find(action => action.selection === 'multiple'));

        return (
            <Toolbar
                className={
                    (selectedAmt == 1 && classes.bgAction) ||
                    (validMultipleSelection && classes.bgAction)
                }
            >
                <div className={classes.title}>
                    <Typography
                        variant='h6'
                        className={`${classes.titleDarkGrey} ${selectedAmt == 1 || validMultipleSelection ? classes.titleWhite : ''}`}
                    >
                        {title || <><span>{'Device '}</span><span><b>{deviceName || 'Without Name'}</b></span></>}
                    </Typography>
                </div>
                <div className={classes.lesserSpacer}>
                    <Chip
                        avatar={
                            <Tooltip
                                title={
                                    <React.Fragment>
                                        <Typography
                                            variant='h5'
                                            className={classes.smallWhiteText}
                                        >
                                            Available Actions
                                        </Typography>
                                        <TooltipListContent content={availableActions && availableActions.map(action => `${action.name} ▶ ${ACTION_SELECTION_PRETTY_NAME[action.selection]}`)} />
                                    </React.Fragment>
                                }
                                enterDelay={100}
                                disableFocusListener={currActions.length > 0}
                                disableHoverListener={currActions.length > 0}
                                disableTouchListener={currActions.length > 0}
                            >
                                <Avatar
                                    className={`${classes.actionsAvatar} ${selectedAmt == 1 || validMultipleSelection ? classes.bgActionAvatar : ''}`}
                                >
                                    {currActions.length > 0 ? currActions.length : '?'}
                                </Avatar>
                            </Tooltip>
                        }
                        label={`${currActions && currActions.length > 0 ? `${currActions.length > 1 ? t('events_more_actions') : t('events_one_action')}` : t('events_no_action')}`}
                        className={`${classes.actionsChip} ${selectedAmt == 1 || validMultipleSelection ? classes.titleWhite : ''}`}
                        color='#666'
                        variant='outlined'
                    />
                </div>
                <div className={classes.spacer} />
                {
                    currActions.map(action => (
                        <PageContext.Consumer>
                            {
                                provider => (
                                    <ActionBaseDialog
                                        {...this.props}
                                        actionType={action.id}
                                        title={t(action.name)}
                                        confirm={t(action.name)}
                                        contentComponent={
                                            ((action.id === 'create' || action.id === 'edit' || action.id === 'merge') && <MainForm />) ||
                                            (action.id === 'split' && <SplitForm />) ||
                                            (action.id === 'delete' && <DeleteForm />) ||
                                            (action.id === 'approve' && <ApproveForm />) ||
                                            (action.id === 'unapprove' && <UnapproveForm />)
                                        }
                                        onActionSuccess={provider.onActionSuccess}
                                    >
                                        <ActionButton action={action} selectedAmt={selectedAmt} />
                                    </ActionBaseDialog>
                                )
                            }
                        </PageContext.Consumer>
                    ))
                }
                {
                    currActions.length === 0 &&
                    <div className={classes.title} style={{ width: '70vw' }} />
                }
            </Toolbar>
        );
    }
};

export default withTranslation()(withStyles(styles)(EventsTableToolbar));