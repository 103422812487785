import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/ActionStyle';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';


const Comment = ({ classes, changeComment, text, t }) => (
    <TextField
        label={t('comment')}
        multiline
        value={text}
        onChange={changeComment}
        className={classes.textField}
        margin='normal'
    />
);

export default withTranslation()(withStyles(styles)(Comment));