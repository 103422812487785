import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    IconButton,
    Dialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions as MuiDialogActions,
    Typography,
    Button,
    Icon
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DefaultTableCell } from '../../../../../../new-components/Table/components';
import { ComponentMovementForm } from '../Forms';
import { useTranslation } from 'react-i18next';


const TYPE_GRID = 0;
const TYPE_COLUMN = 1;

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));


const View = props => {

    const classes = useStyles();
    const { t } = useTranslation();
    const {
        open,
        setOpened,
        component,
        serial,
        applicationExpireDate = null,
        applicationWarrantyEnd = null,
        expireDate = null,
        warrantyEnd = null,
        movements = [],
        details = {},
        sectors = [],
        models,
        storages,
        id: component_id,
        finishMount,
        headMap = {},
        activeResource,
        getComponents
    } = props;

    const timeToExpire = expireDate && new Date(expireDate).getTime() - new Date().getTime();
    const daysToExpire = timeToExpire && Math.ceil(timeToExpire / (1000 * 60 * 60 * 24));

    const timeToWarrantyEnd = warrantyEnd && new Date(warrantyEnd).getTime() - new Date().getTime();
    const daysToWarrantyEnd = timeToWarrantyEnd && Math.ceil(timeToWarrantyEnd / (1000 * 60 * 60 * 24));

    const timeToApplicationExpire = applicationExpireDate && new Date(applicationExpireDate).getTime() - new Date().getTime();
    const daysToApplicationExpire = timeToApplicationExpire && Math.ceil(timeToApplicationExpire / (1000 * 60 * 60 * 24));

    const timeToApplicationWarrantyEnd = applicationWarrantyEnd && new Date(applicationWarrantyEnd).getTime() - new Date().getTime();
    const daysToApplicationWarrantyEnd = timeToApplicationWarrantyEnd && Math.ceil(timeToApplicationWarrantyEnd / (1000 * 60 * 60 * 24));

    const [openedMovementForm, openMovementForm] = useState(false);
    const [movementData, setMovementData] = useState({});

    const [savingInfo, saveInfo] = useState(false);

    const lastMovementLocation = movements.length > 0 && movements[0];

    return (
        <Dialog
            onClose={() => setOpened(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth='md'
            fullWidth
        >
            <MuiDialogTitle
                disableTypography
                className={classes.root}
            >
                <Typography variant='h6'>
                    {component} #{serial}
                </Typography>
                <IconButton
                    aria-label='Close'
                    className={classes.closeButton}
                    onClick={() => setOpened(false)}
                >
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                {
                    sectors.map(sector => (
                        <>
                            <div
                                style={
                                    {
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        minHeight: 55,
                                        flex: 1,
                                        ...(
                                            sector.type === TYPE_GRID ?
                                                {
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap'
                                                }
                                                : sector.type === TYPE_COLUMN ?
                                                    {
                                                        flexDirection: 'column'
                                                    }
                                                    :
                                                    {}
                                        )
                                    }
                                }
                            >
                                {
                                    Object.entries(sector.details).map(([key, value]) => (
                                        headMap.hasOwnProperty(key) &&
                                        <Typography
                                            gutterBottom
                                            style={{
                                                flex: 1,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                fontSize: '.8em',
                                                ...(
                                                    sector.type === TYPE_GRID ?
                                                        {
                                                            flexBasis: '48%'
                                                        }
                                                        : sector.type === TYPE_COLUMN ?
                                                            {
                                                                flexBasis: '100%'
                                                            }
                                                            :
                                                            {}
                                                )
                                            }}
                                        >
                                            <span style={{ flex: 2 }}>
                                                <b>{t(headMap[key])}</b>
                                            </span>
                                            <span
                                                style={
                                                    sector.type === TYPE_COLUMN ?
                                                        { flex: '1 1 60%' }
                                                        :
                                                        { flex: '1 1 20%' }
                                                }
                                            >
                                                {
                                                    value != null ?
                                                        (key === 'acquisitionDate' ||
                                                            key === 'receivedDate' ||
                                                            key === 'fabricationDate' ||
                                                            key === 'lastMovementDate') ?
                                                            value.split('T')[0]
                                                            :
                                                            key === 'acquisitionValue' ?
                                                                value.toFixed ? value.toFixed(2) : value
                                                                :
                                                                value
                                                        :
                                                        '--'
                                                }
                                                {value != null && key === 'nextMovementDate' && ' days'}
                                                {value != null && key === 'acquisitionValue' && ' R$'}
                                            </span>
                                        </Typography>
                                    ))
                                }
                            </div>
                            <br />
                        </>
                    ))
                }
                <div
                    style={
                        {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            flex: 1
                        }
                    }
                >
                    <div
                        style={
                            {
                                flex: '1 1 45%',
                                margin: '8px 8px 16px 0'
                            }
                        }
                    >
                        <Typography
                            style={{
                                fontSize: '.85em',
                                padding: 8,
                                backgroundColor: `
                                    ${daysToExpire == null ? '#e2e3e5' : ''}
                                    ${daysToExpire != null && daysToExpire > 20 ? '#cce5ff' : ''}
                                    ${daysToExpire != null && daysToExpire <= 20 && daysToExpire > 7 ? '#fff3cd' : ''}
                                    ${daysToExpire != null && daysToExpire <= 7 ? '#f8d7da' : ''}
                                `,
                                border: `
                                    1px solid
                                    ${daysToExpire == null ? '#d6d8db' : ''}
                                    ${daysToExpire != null && daysToExpire > 20 ? '#b8daff' : ''}
                                    ${daysToExpire != null && daysToExpire <= 20 && daysToExpire > 7 ? '#ffeeba' : ''}
                                    ${daysToExpire != null && daysToExpire <= 7 ? '#f5c6cb' : ''}
                                `,
                                color: `
                                    ${daysToExpire == null ? '#383d41' : ''}
                                    ${daysToExpire != null && daysToExpire > 20 ? '#004085' : ''}
                                    ${daysToExpire != null && daysToExpire <= 20 && daysToExpire > 7 ? '#856404' : ''}
                                    ${daysToExpire != null && daysToExpire <= 7 ? '#721c24' : ''}
                                `,
                                borderRadius: '4px 4px 0 0',
                                borderBottom: 0,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            {
                                daysToExpire == null ?
                                    <Icon>not_interested</Icon>
                                    : daysToExpire != null && daysToExpire > 20 ?
                                        <Icon>check_circle</Icon>
                                        : daysToExpire != null && daysToExpire <= 20 && daysToExpire > 7 ?
                                            <Icon>warning</Icon>
                                            : daysToExpire != null && daysToExpire <= 7 ?
                                                <Icon>cancel</Icon>
                                                :
                                                <Icon />
                            }
                            <div style={{ width: '16px' }} />
                            <span>{daysToExpire != null && daysToExpire < 0 ? t('Component Expired In') : t('Component Expires In')} - {expireDate != null ? <b>{expireDate.split('T')[0]}</b> : t('Date Not Available')}</span>
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '.85em',
                                padding: 8,
                                backgroundColor: `
                                    ${daysToApplicationExpire == null ? '#e2e3e5' : ''}
                                    ${daysToApplicationExpire != null && daysToApplicationExpire > 20 ? '#cce5ff' : ''}
                                    ${daysToApplicationExpire != null && daysToApplicationExpire <= 20 && daysToApplicationExpire > 7 ? '#fff3cd' : ''}
                                    ${daysToApplicationExpire != null && daysToApplicationExpire <= 7 ? '#f8d7da' : ''}
                                `,
                                border: `
                                    1px solid
                                    ${daysToApplicationExpire == null ? '#d6d8db' : ''}
                                    ${daysToApplicationExpire != null && daysToApplicationExpire > 20 ? '#b8daff' : ''}
                                    ${daysToApplicationExpire != null && daysToApplicationExpire <= 20 && daysToApplicationExpire > 7 ? '#ffeeba' : ''}
                                    ${daysToApplicationExpire != null && daysToApplicationExpire <= 7 ? '#f5c6cb' : ''}
                                `,
                                color: `
                                    ${daysToApplicationExpire == null ? '#383d41' : ''}
                                    ${daysToApplicationExpire != null && daysToApplicationExpire > 20 ? '#004085' : ''}
                                    ${daysToApplicationExpire != null && daysToApplicationExpire <= 20 && daysToApplicationExpire > 7 ? '#856404' : ''}
                                    ${daysToApplicationExpire != null && daysToApplicationExpire <= 7 ? '#721c24' : ''}
                                `,
                                borderRadius: '0 0 4px 4px',
                                borderTop: 0,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            {
                                daysToApplicationExpire == null ?
                                    <Icon>not_interested</Icon>
                                    : daysToApplicationExpire != null && daysToApplicationExpire > 20 ?
                                        <Icon>check_circle</Icon>
                                        : daysToApplicationExpire != null && daysToApplicationExpire <= 20 && daysToApplicationExpire > 7 ?
                                            <Icon>warning</Icon>
                                            : daysToApplicationExpire != null && daysToApplicationExpire <= 7 ?
                                                <Icon>cancel</Icon>
                                                :
                                                <Icon />
                            }
                            <div style={{ width: '16px' }} />
                            <span>{daysToApplicationExpire != null && daysToApplicationExpire < 0 ? t('Application Expired In') : t('Application Expires In')} - {applicationExpireDate != null ? <b>{applicationExpireDate.split('T')[0]}</b> : t('Date Not Available')}</span>
                        </Typography>
                    </div>
                    <div
                        style={
                            {
                                flex: '1 1 45%',
                                margin: '8px 0 16px 8px'
                            }
                        }
                    >
                        <Typography
                            style={{
                                fontSize: '.85em',
                                padding: 8,
                                backgroundColor: `
                                    ${daysToWarrantyEnd == null ? '#e2e3e5' : ''}
                                    ${daysToWarrantyEnd != null && daysToWarrantyEnd > 20 ? '#cce5ff' : ''}
                                    ${daysToWarrantyEnd != null && daysToWarrantyEnd <= 20 && daysToWarrantyEnd > 7 ? '#fff3cd' : ''}
                                    ${daysToWarrantyEnd != null && daysToWarrantyEnd <= 7 ? '#f8d7da' : ''}
                                `,
                                border: `
                                    1px solid
                                    ${daysToWarrantyEnd == null ? '#d6d8db' : ''}
                                    ${daysToWarrantyEnd != null && daysToWarrantyEnd > 20 ? '#b8daff' : ''}
                                    ${daysToWarrantyEnd != null && daysToWarrantyEnd <= 20 && daysToWarrantyEnd > 7 ? '#ffeeba' : ''}
                                    ${daysToWarrantyEnd != null && daysToWarrantyEnd <= 7 ? '#f5c6cb' : ''}
                                `,
                                color: `
                                    ${daysToWarrantyEnd == null ? '#383d41' : ''}
                                    ${daysToWarrantyEnd != null && daysToWarrantyEnd > 20 ? '#004085' : ''}
                                    ${daysToWarrantyEnd != null && daysToWarrantyEnd <= 20 && daysToWarrantyEnd > 7 ? '#856404' : ''}
                                    ${daysToWarrantyEnd != null && daysToWarrantyEnd <= 7 ? '#721c24' : ''}
                                `,
                                borderRadius: '4px 4px 0 0',
                                borderBottom: 0,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            {
                                daysToWarrantyEnd == null ?
                                    <Icon>not_interested</Icon>
                                    : daysToWarrantyEnd != null && daysToWarrantyEnd > 20 ?
                                        <Icon>check_circle</Icon>
                                        : daysToWarrantyEnd != null && daysToWarrantyEnd <= 20 && daysToWarrantyEnd > 7 ?
                                            <Icon>warning</Icon>
                                            : daysToWarrantyEnd != null && daysToWarrantyEnd <= 7 ?
                                                <Icon>cancel</Icon>
                                                :
                                                <Icon />
                            }
                            <div style={{ width: '16px' }} />
                            <span>{daysToWarrantyEnd != null && daysToWarrantyEnd < 0 ? t('Component Warranty Ended In') : t('Component Warranty Ends In')} - {warrantyEnd != null ? <b>{warrantyEnd.split('T')[0]}</b> : t('Date Not Available')}</span>
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '.85em',
                                padding: 8,
                                backgroundColor: `
                                    ${daysToApplicationWarrantyEnd == null ? '#e2e3e5' : ''}
                                    ${daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd > 20 ? '#cce5ff' : ''}
                                    ${daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd <= 20 && daysToApplicationWarrantyEnd > 7 ? '#fff3cd' : ''}
                                    ${daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd <= 7 ? '#f8d7da' : ''}
                                `,
                                border: `
                                    1px solid
                                    ${daysToApplicationWarrantyEnd == null ? '#d6d8db' : ''}
                                    ${daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd > 20 ? '#b8daff' : ''}
                                    ${daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd <= 20 && daysToApplicationWarrantyEnd > 7 ? '#ffeeba' : ''}
                                    ${daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd <= 7 ? '#f5c6cb' : ''}
                                `,
                                color: `
                                    ${daysToApplicationWarrantyEnd == null ? '#383d41' : ''}
                                    ${daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd > 20 ? '#004085' : ''}
                                    ${daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd <= 20 && daysToApplicationWarrantyEnd > 7 ? '#856404' : ''}
                                    ${daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd <= 7 ? '#721c24' : ''}
                                `,
                                borderRadius: '0 0 4px 4px',
                                borderTop: 0,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}
                        >
                            {
                                daysToApplicationWarrantyEnd == null ?
                                    <Icon>not_interested</Icon>
                                    : daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd > 20 ?
                                        <Icon>check_circle</Icon>
                                        : daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd <= 20 && daysToApplicationWarrantyEnd > 7 ?
                                            <Icon>warning</Icon>
                                            : daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd <= 7 ?
                                                <Icon>cancel</Icon>
                                                :
                                                <Icon />
                            }
                            <div style={{ width: '16px' }} />
                            <span>{daysToApplicationWarrantyEnd != null && daysToApplicationWarrantyEnd < 0 ? t('Application Warranty Ended In') : t('Application Warranty Ends In')} - {applicationWarrantyEnd != null ? <b>{applicationWarrantyEnd.split('T')[0]}</b> : t('Date Not Available')}</span>
                        </Typography>
                    </div>
                </div>
                {
                    movements &&
                    movements.length > 0 &&
                    <Table
                        size='small'
                        style={{
                            border: '1px solid #dbdbdb'
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <DefaultTableCell
                                    align='left'
                                    padding='checkbox'
                                    colSpan={2}
                                    style={{ width: '20%', padding: 16 }}
                                >
                                    {t('In (Date)')}
                                </DefaultTableCell>
                                <DefaultTableCell
                                    align='left'
                                    padding='checkbox'
                                    colSpan={2}
                                    style={{ width: '20%', padding: 16 }}
                                >
                                    {t('Event')}
                                </DefaultTableCell>
                                <DefaultTableCell
                                    align='left'
                                    padding='checkbox'
                                    colSpan={2}
                                    style={{ width: '20%', padding: 16 }}
                                >
                                    {t('From')}
                                </DefaultTableCell>
                                <DefaultTableCell
                                    align='left'
                                    padding='checkbox'
                                    colSpan={2}
                                    style={{ width: '20%', padding: 16 }}
                                >
                                    {t('To')}
                                </DefaultTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {movements.map((row, index) => (
                                <TableRow
                                    key={`${row.in}-${row.from}-${row.to}`}
                                    style={{
                                        background: index % 2 === 0 ? 'white' : '#dbdbdb'
                                    }}
                                >
                                    <DefaultTableCell
                                        align='left'
                                        component="th"
                                        scope="row"
                                        padding='default'
                                        colSpan={2}
                                        style={{
                                            maxWidth: '160px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            verticalAlign: 'baseline'
                                        }}
                                    >
                                        {row.procedure_date ? row.procedure_date.split('T')[0] : '--'}
                                    </DefaultTableCell>
                                    <DefaultTableCell
                                        align='left'
                                        padding='default'
                                        colSpan={2}
                                        style={{
                                            maxWidth: '160px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            verticalAlign: 'baseline'
                                        }}
                                    >
                                        {t(row.procedure)}
                                    </DefaultTableCell>
                                    <DefaultTableCell
                                        align='left'
                                        padding='default'
                                        colSpan={2}
                                        style={{
                                            maxWidth: '160px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            verticalAlign: 'baseline'
                                        }}
                                    >
                                        {
                                            (
                                                row.origin_data && row.origin_data.placement ?
                                                    `${row.origin_name} (${row.origin_data.placement})`
                                                    :
                                                    row.origin_name
                                            )
                                            ||
                                            '--'
                                        }
                                    </DefaultTableCell>
                                    <DefaultTableCell
                                        align='left'
                                        padding='default'
                                        colSpan={2}
                                        style={{
                                            maxWidth: '160px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            verticalAlign: 'baseline'
                                        }}
                                    >
                                        {
                                            (
                                                row.destiny_data && row.destiny_data.placement ?
                                                    `${row.destiny_name} (${row.destiny_data.placement})`
                                                    :
                                                    row.destiny_name
                                            )
                                            ||
                                            '--'
                                        }
                                    </DefaultTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
            </MuiDialogContent>
            {
                details.description &&
                <MuiDialogContent dividers>
                    <Typography variant='subtitle2'>
                        {`${t('Description')}:`}
                    </Typography>
                    <br />
                    <Typography gutterBottom >
                        {details.description}
                    </Typography>
                </MuiDialogContent>
            }
            <MuiDialogActions>
                <Button
                    onClick={() => openMovementForm(true)}
                    variant="contained"
                    color="primary"
                >
                    {t('Move Component')}
                </Button>
            </MuiDialogActions>
            <ComponentMovementForm
                {
                ...{
                    component,
                    serial,
                    openedMovementForm,
                    openMovementForm,
                    setMovementData,
                    movementData,
                    saveInfo,
                    finishMount,
                    setOpened,
                    lastMovementLocation,
                    models,
                    storages,
                    savingInfo,
                    component_id,
                    activeResource,
                    details,
                    getComponents
                }
                }
            />
        </Dialog>
    );
};

export default View;