import React, { Fragment } from 'react';

import DevicesContainer from './components/DevicesContainer';
import DateContainer from './components/DateContainer';
import DownloadTypeList from './components/DownloadTypeList';
import ScadaContainer from './components/ScadaContainer';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles/ScadaPage';

import { getVariablesProvider } from '../../../../providers/get-data-old-provider';
import { 
    getAlarmData, 
    getTimeSeriesData, 
    getDowntimesData 
} from '../../../../providers/scada-provider';
import XLSX from 'xlsx';

import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';

import CircularLoading from '../../../../components/Loading/CircularLoading';
import DefaultErrorHandler from '../../../../components/Handler/DefaultErrorHandler';

import { toast } from 'react-toastify';

class ScadaPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            dataToDownload: {},
            generating: false,
            generatingStepText: 'Not Generating'
        }
    }

    downloadData = async event => {
        const { dataToDownload, generating } = this.state;
        const { scadas, devices, dataType, startDate, endDate } = dataToDownload;

        const resultData = {};
        
        if(!generating && dataType.length > 0) {
            this.setState({
                generating: true,
                generatingStepText: `Starting generation of: ${dataType.map(type => type === 'alarms' ? 'Alarms' : type === 'downtimes' ? 'Downtimes' : 'Time Series').join(' | ')} sheet data...`,
            });
        }

        if(dataType.indexOf('timeSeries') >= 0 && scadas.length > 0) {
            const variablesPerScada = await Promise.all(scadas.map(async scadaId => ({
                    [scadaId]: await Promise.resolve(getVariablesProvider({
                        wtgs: devices,
                        scada_id: scadaId
                    }))
                
            })));

            const variablesPerScadaDict = variablesPerScada.reduce((acc, curr) => ({...acc, ...curr}));

            // ATTENTION: This structure is pretty good, but we'll not use it here

            // const allVariablesInfo = Object.values(variablesPerScadaDict).map(scadaVariable => (
            //     scadaVariable.map(variable => ({
            //         [variable.variable_code]: {
            //             ...variable
            //         }
            //     })).reduce((acc, curr) => ({...acc, ...curr}))
            // )).reduce((acc, curr) => ({...acc, ...curr}));

            // const allVariablesInfoList = Object.values(allVariablesInfo);

            this.setState({
                generatingStepText: 'Getting historical timeseries. It will take a time.'
            });

            try {
                const timeSeriesData = await Promise.all(Object.entries(variablesPerScadaDict).map(async entry => {
                    try {
                        const variablesCodes = entry[1].map(variable => String(variable.variable_code));
                        if(variablesCodes.length === 0) {
                            return null;
                        }
                        
                        const ts = await Promise.resolve(getTimeSeriesData({
                            scadaId: entry[0], 
                            variablesCodes, 
                            devices,                    
                            startDate, 
                            endDate
                        }));

                        return ts;
                    } catch (err) {
                        return null
                    }
                }));

                const filteredTimeSeriesData = timeSeriesData.filter(data => data != null);
                const timeSeriesDataRes = filteredTimeSeriesData.length > 0 ? filteredTimeSeriesData.reduce((acc, curr) => [...acc, ...curr]) : [];

                resultData['timeSeriesData'] = timeSeriesDataRes;
            } catch(err) {
                console.log('Error on scada page', err)
                const errorMessage = 'Error on getting data from timeseries. It will not be downloaded!';

                toast.error(errorMessage, {
                    position: 'top-right',
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true
                })
            }
        }

        if(dataType.indexOf('alarms') >= 0) {
            this.setState({
                generatingStepText: 'Getting alarms data.'
            });

            try {
                const alarmsData = await Promise.resolve(getAlarmData({
                    devices,                    
                    startDate, 
                    endDate
                }));

                resultData['alarmsData'] = alarmsData;
            } catch(err) {
                const errorMessage = 'Error on getting alarm data. It will not be downloaded!';

                toast.error(errorMessage, {
                    position: 'top-right',
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true
                })
            }
        }

        if(dataType.indexOf('downtimes') >= 0) {
            this.setState({
                generatingStepText: 'Getting downtime data.'
            });

            try {
                const downtimesData = await Promise.resolve(getDowntimesData({
                    devices,                    
                    startDate, 
                    endDate
                }));

                resultData['downtimesData'] = downtimesData;
            } catch(err) {
                const errorMessage = 'Error on getting downtime data. It will not be downloaded!';

                toast.error(errorMessage, {
                    position: 'top-right',
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true
                })
            }
        }

        this.setState({
            generatingStepText: 'Data aquisition done! Now converting files to sheets.'
        });

        const filesNames = Object.entries(resultData).map(entry => {
            try {
                const worksheet = XLSX.utils.json_to_sheet(entry[1]);
                const new_workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
                const name = `${entry[0]}.xlsx`;
                XLSX.writeFile(new_workbook, name);

                return name;
            } catch(err) {
                return 'Not Generated';
            }
        });

        this.setState({
            generating: false,
            generatingStepText: 'Finished Generation!'
        });
    }

    retrieveData = incomingData => {
        this.setState(prevState => ({
            dataToDownload: {
                ...prevState.dataToDownload,
                ...incomingData
            }
        }));
    }

    render () {
        const { classes } = this.props;
        const { dataToDownload, generating, generatingStepText, generatedData } = this.state;
        const { devices, scadas } = dataToDownload;

        return (
            <div className={classes.root}>
                {
                    generating && (
                        <CircularLoading>
                            <span>{generatingStepText}</span>
                        </CircularLoading>
                    )
                }
                <div 
                    className={classes.actionsArea}
                >
                    <Fab 
                        variant='extended' 
                        size='small'
                        aria-label='download' 
                        className={classes.downloadData}
                        onClick={this.downloadData}
                        disabled={generating}
                    >
                        <Icon>cloud_download</Icon>
                        Download Data
                    </Fab>
                </div>
                <div 
                    className={`${classes.content} ${generating ? classes.divDisabled : ''}`} 
                >
                    <div className={classes.selectorContent} >
                        <DefaultErrorHandler>
                            <DateContainer retrieveData={this.retrieveData}/>
                        </DefaultErrorHandler>
                    </div>
                    <div className={classes.selectorContent}>
                        <DefaultErrorHandler>
                            <DevicesContainer retrieveData={this.retrieveData}/>
                        </DefaultErrorHandler>
                    </div>
                    <div className={classes.selectorContent}>
                        <DefaultErrorHandler>
                            <ScadaContainer retrieveData={this.retrieveData} selectedDevices={devices} />
                        </DefaultErrorHandler>
                    </div>
                    <div className={classes.selectorContent}>
                        <DefaultErrorHandler>
                            <DownloadTypeList retrieveData={this.retrieveData} selectedDevices={devices} selectedScadas={scadas} />
                        </DefaultErrorHandler>
                    </div>
                </div>
            </div>
        );
  }
}

export default withStyles(styles)(ScadaPage)
