import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';


export default ({ open, title, content, cancel = 'Cancel', submit = 'Submit', handleCancel = () => { }, handleSubmit = () => { } }) => {

    if (open) {
        return (
            <Dialog open={open}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color='primary' >{cancel}</Button>
                    <Button onClick={handleSubmit} color='primary' >{submit}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return null
};