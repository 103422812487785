import React from 'react'
import {Route} from 'react-router-dom'

import AssetComponentListPage from './pages/AssetComponentListPage/AssetComponentListPage';
import { ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT_EDIT, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT_CREATE } from '../../../../../../../../constants/route-constant';
import AssetComponentChangePage from './pages/AssetComponentChangePage/AssetComponentChangePage';

class AssetComponentPage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          exact
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT}
          render={props => (<AssetComponentListPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT_CREATE}
          render={props => (<AssetComponentChangePage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT_EDIT}
          render={props => (<AssetComponentChangePage {...props} />)}
        />
      </div>
    )
  }
}

export default AssetComponentPage
