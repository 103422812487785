import React, {Component} from 'react'
import moment from 'moment-timezone'
import {
  withStyles, Icon
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Grid,
  Button
} from '@material-ui/core'

import CircularLoading from '../Loading/CircularLoading'

import { keyframes } from 'styled-components'

const grey_row_changed = keyframes`
  0% {
    background-color: #f9f9f9
  }

  20% {
    background-color: #ffa25b
  }

  100% {
    background-color: #d9d9d9
  }
`

const white_row_changed = keyframes`
  0% {
    background-color: #f9f9f9
  }

  20% {
    background-color: #ffa25b
  }

  100% {
    background-color: white
  }
`

class BlinkTable extends React.Component {
  constructor (props) {
    super (props)

    this.formatCell = this.formatCell.bind(this)
  }

  formatCell(cell, index) {
    const { column_types, icons } = this.props
    const value = column_types[index] ? column_types[index].toLocaleUpperCase() : ''

    if(!cell) {return 'No Data'}

    switch(value) {
      case 'ICON':
        return icons[cell.toLocaleUpperCase()]
      case 'TIMESTAMP':
        return moment(cell).format('YYYY-MM-DD HH:mm:ss')
      case 'FLOAT':
        return cell.toFixed(1)
      case 'LAST_UPDATE':
        return (
          <Tooltip title={moment(cell).format('YYYY-MM-DD HH:mm:ss')} >
            <Icon style={{fontSize: 16, color: 'black'}} >schedule</Icon>
          </Tooltip>
        )
      default:
        return cell
    }
  }

  render () {
    const {classes, table_data, table_colored_rows, fontsize_header, fontsize_rows, table_num } = this.props
    let { widths } = this.props

    // console.log('ON TABLE LOADING', table_data, table_colored_rows)

    if(!table_data) {
      return <CircularLoading />
    }

    if(table_data.header == null || table_data.header.constructor != Array) {
        throw new Error(JSON.stringify(
            { 
            message: 'Bad Format: Header', 
            on_table: table_num
            }
        ))
    }

    if(table_data.rows == null || table_data.rows.constructor != Array) {
        throw new Error(JSON.stringify(
            { 
            message: 'Bad Format: Rows', 
            on_table: table_num 
            }
        ))
    }

    if(!table_data.rows.map(row => row != null && row.constructor == Array).reduce((acc, curr) => acc && curr)) {
        throw new Error(JSON.stringify(
            { 
            message: 'Row Format: Some row is not an array', 
            on_table: table_num 
            }
        ))
    }

    if(widths != null && widths.constructor != Array) {
        throw new Error(JSON.stringify(
            { 
              message: 'Widths should be array', 
              on_table: table_num 
            }
        ))
    }
    // console.log('Before BREAK', table_data)

    if(!widths) {
      widths = table_data.header.map(val => 1)
    }

    if(table_data.header.length != widths.length) {
        throw new Error(JSON.stringify(
            { 
              message: 'Widths should have the same length that table header', 
              on_table: table_num 
            }
        ))
    }

    return (
        <Table style={{padding: '0 !important', border: 'solid 1px #d9d9d9', width: '101%', marginRight: -2}}>
          <TableHead>
            <TableRow 
              style={{
                height: 10
              }}
            >
              {
                table_data.header.map((cell, index) => (
                  <TableCell 
                    colSpan={widths[index]} 
                    style={{
                      fontSize: fontsize_header, 
                      padding: '0 !important', 
                      height: 'auto !important'
                    }}
                  >
                    {
                      cell
                    }
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              table_data.rows.map((row, index) => (
                <TableRow style={{
                  maxHeight: '36px',
                  height: 0,
                  backgroundColor: index % 2 ? '#d9d9d9' : 'white',
                  animation: table_colored_rows != null && table_colored_rows[index] ? `${index % 2 ? grey_row_changed : white_row_changed} 2s ease` : '',
                }}>
                {
                  row.map((cell, index) => (
                    <TableCell 
                      colSpan={widths[index]} 
                      style={{
                        fontSize: fontsize_rows, 
                        padding: '0 !important', 
                        height: 'auto !important'
                      }}
                    >
                      {
                        this.formatCell(cell, index)
                      }
                    </TableCell>
                  ))
                }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>      
    )
  }
}

export default withStyles()(BlinkTable)