import React, {
    useState,
    useEffect
} from 'react';

import ClickOutside from 'react-click-outside';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';

import { 
    makeStyles
} from '@material-ui/core/styles';

const useBaseStyle = makeStyles(theme => ({
    root: {
        // height: 380,
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    }
}));

const View = props => {
    const {
        Hidden,
        baseIcon,
        openedIcon,
        activatedIcon,
        style,
        actions=[]
    } = props;

    const classes = useBaseStyle();
    const [ open, setOpened ] = useState(false);
    const [ activated, setActive ] = useState(false);
    const [ hidden, hide ] = useState(Hidden);

    const handleToggle = () => {
        if(!activated && open) {
            setActive(true);
        } else if(open) {
            setActive(false);
            setOpened(false);
        } else {
            setActive(true);
            setOpened(true);
        }
    };
    const handleClose = () => {
        setActive(false);
        setOpened(false);
    };

    const handleBlur = () => {
        if(!activated) {
            setOpened(false);
        }
    };

    const handleClickOutside = () => {
        if(activated && open) {
            setActive(false);
            setOpened(false);
        }
    };
    
    const handleFocus = () => {
        if(!activated) {
            setOpened(true);
        }
    };

    useEffect(() => {
        hide(Hidden);
        setActive(false);
        setOpened(false);
    }, [Hidden]);

    return (
        <div className={classes.root}>
            <ClickOutside 
                onClickOutside={handleClickOutside}
            >
                <SpeedDial
                    ariaLabel='Options Menu'
                    className={classes.speedDial}
                    
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onClick={handleToggle}
                    onClose={handleClose}
                    onMouseEnter={handleFocus}
                    onMouseLeave={handleBlur}
                    
                    open={open}
                    hidden={hidden}
                    icon={
                        <SpeedDialIcon 
                            icon={baseIcon}
                            openIcon={!activated ? openedIcon : activatedIcon}
                        />
                    }
                    openIcon={openedIcon}

                    ButtonProps={{
                        style
                    }}
                >
                    {
                        actions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                tooltipOpen
                                onClick={() => {
                                    handleClose();
                                    action.onClick();
                                }}
                            />
                        ))
                    }
                </SpeedDial>
            </ClickOutside>
        </div>
    );
}

export default View;