import React, {Component} from 'react'
import {withStyles, CircularProgress} from '@material-ui/core'
import io from 'socket.io-client'
import {Auth} from 'aws-amplify'
import axios from 'axios'

import { URL_NODE_API, URL_WEBSOCKET_API } from '../../../../constants/url-constant'
import MonitorListSubpark from './components/MonitorListSubpark/MonitorListSubpark'

const EVENT_DESCRIPTION = 'subpark'
const EVENT_DIVIDER = '#'
const styles = {}

class MonitorPage extends Component {
  constructor (props) {
    super (props)

    this.state = {
      loading: false,
      socket: null,
      parks_info: [],
      dataSocket: null
    }
  }

  componentDidMount() {
    this.setState({loading: true})

    Auth.currentSession()
      .then((data) => {
        const {jwtToken} = data.accessToken
        
        const url = `${URL_WEBSOCKET_API}/wind-monitor`

        const socket = io(url, {
          query: {
            token: jwtToken
          }
        })
        
        this.setState({socket})

        socket.on('connect', (data) => console.log('connect', data))
        socket.on('connect_error', data => console.log('connect_error', data))
        socket.on('connect_timeout', data => console.log('connect_timeout', data))
        socket.on('connecting', data => console.log('connecting', data))
        socket.on('disconnect', data => console.log('disconnect', data))
        socket.on('error', data => console.log('error', data))
        socket.on('reconnect', data => console.log('reconnect', data))
        socket.on('reconnect_attempt', data => console.log('reconnect_attempt', data))
        socket.on('reconnect_failed', data => console.log('reconnect_failed', data))
        socket.on('reconnect_error', data => console.log('reconnect_error', data))
        socket.on('reconnecting', data => console.log('reconnecting', data))
        socket.on('ping', data => console.log('ping', data))
        socket.on('pong', data => console.log('pong', data))

        const URL = URL_NODE_API + '/user/parks'

        return axios.get(URL, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        })
          .then(({data}) => {
            const {parks_info} = data

            this.setState({
              parks_info,
              loading: false
            })

            parks_info.forEach(park => {
              park.subparks.forEach(subpark => {

                socket.on(`${EVENT_DESCRIPTION}${EVENT_DIVIDER}${park.id}${EVENT_DIVIDER}${subpark.id}`, (data) => {
                  // console.log(`${EVENT_DESCRIPTION}${EVENT_DIVIDER}${park.id}${EVENT_DIVIDER}${subpark.id}`, data)

                  this.setState({
                    [`${EVENT_DESCRIPTION}${EVENT_DIVIDER}${park.id}${EVENT_DIVIDER}${subpark.id}`]: data
                  })
                })
              })
            })
          })
      })
      .catch(error => {
        console.error('auth', error)

        this.setState({loading: false})

        if(this.state.socket) {
          this.state.socket.disconnect()
          this.setState({socket: null})
        }
      })
  }

  componentWillUnmount(){
    if(this.state.socket) {
      this.state.socket.disconnect()
      this.setState({socket: null})
    }
  }

  render () {
    const {classes} = this.props

    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: '#333333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress className={classes.progress} />
        </div>
      )
    }

    if (this.state.parks_info.length <= 0) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: '#333333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white'
          }}
        >
          No parks info...
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: '#333333'
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          <MonitorListSubpark
            {...this.state}
            EVENT_DESCRIPTION={EVENT_DESCRIPTION}
            EVENT_DIVIDER={EVENT_DIVIDER}
          />
        </div>
      </div>
    )
  }
}

// const mapStateToProps = ({User}) => ({ parks_info: User.parks_info })

// const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MonitorPage))

export default withStyles(styles)(MonitorPage)
