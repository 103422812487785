import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';

import { PaginatorTable } from '../../../../../../../new-components/Table';

import BigIconMessage from '../../BigIconMessage';

import TimeIntervalSlider from '../../../../EventsPage/components/TimeIntervalSlider';
import SliderTooltipLabel from '../../../../EventsPage/components/SliderTooltipLabel';
import '../../../../EventsPage/components/styles/ExtraStyles.css';
import { useTranslation } from 'react-i18next';

const TimeToDateSliderLabel = SliderTooltipLabel(val => new Date(val).toISOString().split('.')[0].replace('T', ' '));

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    }
}));


const View = props => {

    const { openResourceDialog, resources, setActiveResource } = props;


    const resources_list = resources.resource;
    const resources_headers = resources.headers;

    const classes = useStyles();
    const { t } = useTranslation();
    const movementsHeadMap = resources.labels;

    const candidatesTimeKeys = [
        'warranty',
        'beginning_operation_date',
        'acquisition_date',
        'manufacturing_date',
        'acquired_date',
        'received_date',
        'beginning_operation_date',
        'acquisition_date',
        'stored_alarm_time',
        'stored_lifetime',
        'stored_warranty_time',
        'working_alarm_time',
        'working_lifetime',
        'working_warranty_time'
    ];

    return (
        <div style={{ margin: '16px' }}>
            {
                resources_list && resources_list.length > 0 ?
                    <Paper className={classes.root}>
                        <div style={{ maxWidth: '96vw' }} >
                            <PaginatorTable
                                records={resources_list}
                                fieldFormatter={(index, head, row) => {
                                    if (row[head] == null) {
                                        return '--';
                                    }

                                    if (candidatesTimeKeys.indexOf(head) >= 0) {
                                        return row[head].split('T')[0];
                                    }

                                    switch (head) {
                                        default:
                                            return row[head];
                                    }
                                }}
                                rowClickAction={(index, row) => () => {
                                    setActiveResource(row)
                                    openResourceDialog(true)
                                }}

                                onDownload={(buildHead, buildBody, columns, data) => {
                                    const columnsValues = columns.map(column => column.name);

                                    const newColumns = columns.map(column => ({
                                        ...column,
                                        name: movementsHeadMap[column.name] != undefined ? movementsHeadMap[column.name] : column.name
                                    }));

                                    const newData = data.map(row => {
                                        const changedData = row.data.map((cell, colIndex) => {
                                            switch (columnsValues[colIndex]) {
                                                default:
                                                    return cell;
                                            }
                                        });

                                        return {
                                            ...row,
                                            data: changedData
                                        }
                                    });

                                    const result = `${buildHead(newColumns)}${buildBody(newData)}`;
                                    return result;
                                }}
                                headFormatter={val => movementsHeadMap[val]}
                                displayHeaders={resources_headers}
                                resizeFactor={1}

                                rowsPerPageOptions={[
                                    10,
                                    20,
                                    30,
                                    50,
                                    100,
                                    150,
                                    200
                                ]}
                                cellMinWidth={'8.5vw'}
                                cellMaxWidth={'13vw'}

                                filterLogic={head => (val, filters) => {
                                    if (filters.length === 0 || !val) {
                                        return false;
                                    }

                                    if (candidatesTimeKeys.indexOf(head) >= 0) {
                                        const tVal = new Date(val).getTime();
                                        const tLower = filters.length > 0 ? filters[0] : 0;
                                        const tUpper = filters.length > 1 ? filters[1] : 999999999999999999999999999999999999;
                                        return !(tVal >= tLower && tVal <= tUpper);
                                    }

                                    switch (head) {
                                        case 'documents':
                                            return !(filters[0][0] == val[0])
                                        default:
                                            return !(filters.indexOf(val) >= 0);
                                    }
                                }}

                                useCustomDisplay={candidatesTimeKeys}

                                filterDisplay={head => (filterList, onChange, index, column) => {
                                    if (candidatesTimeKeys.indexOf(head) >= 0) {
                                        const tValues = resources_list.filter(row => (
                                            row[head] != null
                                        )).map(row => new Date(row[head]).getTime());

                                        const tDateMax = Math.max(...tValues);
                                        const tDateMin = Math.min(...tValues);

                                        return (
                                            <TimeIntervalSlider
                                                title={`${t(movementsHeadMap[head] || head)} (${t('in range')})`}
                                                submit={value => onChange(value, index, column)}
                                                LabelComponent={TimeToDateSliderLabel}
                                                defaultValue={column.filterList || [tDateMin, tDateMax]}
                                                sliderProps={{
                                                    max: tDateMax,
                                                    min: tDateMin,
                                                    step: 0.01
                                                }}
                                            />
                                        );
                                    } else {
                                        return undefined;
                                    }
                                }}
                                chipFormatter={{
                                    ...candidatesTimeKeys.map(text => ({
                                        [text]: val => {
                                            if (val.length > 0) {
                                                return `${t(movementsHeadMap[text] || text)}: ${new Date(val[0]).toISOString().split('T')[0] || '--'} - ${new Date(val[1]).toISOString().split('T')[0] || '--'}`;
                                            } else {
                                                return undefined;
                                            }
                                        }
                                    })).reduce((acc, curr) => ({ ...acc, ...curr }), {})
                                }}
                            />
                        </div>
                    </Paper>
                    :
                    <BigIconMessage
                        iconName='report_off'
                        title={t('Your have no records')}
                        message={t('Please, register one')}
                    />
            }
        </div>
    );
};

export default View;