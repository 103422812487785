import React from 'react';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    Typography,
    IconButton,
    Icon
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const View = props => {

    const { t } = useTranslation();

    const {
        children = (<span>Dialog Content</span>),
        title = 'Title',
        cancel = 'Cancel',
        confirm = 'OK',
        del = 'Delete',
        opened,
        Open,
        dialogProps = {},
        titleProps = {},
        contentProps = {},
        actionsProps = {},
        disableTopClose,
        disableCancel,
        disableDelete,
        onDelete,
        disableConfirm,
        onConfirm = () => Open(false),
        onCancel = () => Open(false),
        scroll = 'body'
    } = props;


    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth='md'
            fullWidth
            open={opened}
            scroll={scroll}
            {...dialogProps}
        >
            <DialogTitle
                disableTypography={true}
                {...titleProps}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    ...(titleProps.style || {})
                }}
            >
                <Typography variant='h6'>
                    {t(title)}
                </Typography>
                {
                    !disableTopClose &&
                    <IconButton
                        aria-label='Close'
                        onClick={() => Open(false)}
                    >
                        <Icon>
                            clear
                            </Icon>
                    </IconButton>
                }
            </DialogTitle>
            <DialogContent
                dividers
                {...contentProps}
            >
                {children}
            </DialogContent>
            <DialogActions
                {...actionsProps}
            >
                {
                    !disableDelete &&
                    <Button
                        onClick={onDelete}
                        variant="contained"
                        color='secondary'
                        style={{ float: 'left', marginRight: '72%' }}
                    >
                        {t(del)}
                    </Button>
                }
                {
                    !disableCancel &&
                    <Button
                        onClick={onCancel}
                        color='primary'
                    >
                        {t(cancel)}
                    </Button>
                }
                {
                    !disableConfirm &&
                    <Button
                        onClick={onConfirm}
                        variant="contained"
                        color='primary'
                    >
                        {t(confirm)}
                    </Button>
                }

            </DialogActions>
        </Dialog>
    );
}

export default View;