import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import DevicesSelectorTable from './DevicesSelectorTable';

import CircularLoading from '../../../../../components/Loading/CircularLoading';
import { extractAjustedParksInfo } from '../../../../../helpers/parks-info-helper';

class DevicesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            selectorInfo: null
        }
    }

    updateParksInfo = () => {
        const { parksInfo, setDevicesFilter } = this.props;

        if(parksInfo.length > 0) {
            const selectorInfo = extractAjustedParksInfo(parksInfo);
            
            if(setDevicesFilter != null) {
                setDevicesFilter(selectorInfo);
            }

            this.setState({
                loading: false,
                selectorInfo
            });
        } else {
            this.forceUpdate();
        }
    }

    componentDidMount() {
        const { selectorInfo } = this.props;
        
        if(Object.entries(selectorInfo).length === 0 ||
            Object.values(selectorInfo).map(selector => (
                !selector || 
                (selector.constructor === Array && selector.filter(val => val != null).length === 0) ||
                Object.values(selector).filter(val => val != null).length === 0
            ))
        ) {
            this.updateParksInfo();
        } else {
            this.setState({
                loading: false
            });
        }
    }

    componentDidUpdate() {
        const { loading } = this.state;

        if(loading) {
            setTimeout( () => this.updateParksInfo(), 200);
        }
    }

    render () {
        const { loading, selectorInfo } = this.state;

        return (
            !loading ? (
                <DevicesSelectorTable {...this.props} selectorInfo={selectorInfo} />
            ) : (
                <CircularLoading notOverlay={true}>
                    <span>Please wait while we are scanning your park.</span>
                </CircularLoading>
            )
        );
    }
}

const mapStateToProps = ({Dashboard, User}) => ({
    selectorInfo: Dashboard.DeviceFilter,
    parksInfo: User.parks_info
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        // setDevicesFilter
    }
    , dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(DevicesContainer);
  