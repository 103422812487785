import {Auth} from 'aws-amplify'
import DynamoDB from 'aws-sdk/clients/dynamodb';

export const getDynamoClientMeta = async () => {
  try {
    const session = await Auth.currentSession()

    const credentials = await Auth.currentUserCredentials()

    const client_id = session.idToken.payload["custom:client"]

    const db = new DynamoDB.DocumentClient({
      credentials: Auth.essentialCredentials(credentials),
      apiVersion: '2012-10-17'
    })

    const  params = {
      TableName : process.env.REACT_APP_DYNAMO_TABLE_CLIENT_META,
      KeyConditionExpression: "id = :id",
      ExpressionAttributeValues: {
        ":id": client_id
      }
    }

    const result = await db.query(params).promise()

    return result
  } catch (error) {
    console.error('dynamo-client-meta-provider', error)
    return false
  }
}
