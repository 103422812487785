import React, { useState, useEffect } from 'react';
import moment from 'moment';

import CircularLoading from '../../../../components/Loading/CircularLoading';
import BaseGlobalFilter from '../../../../components/Header/BaseGlobalFilter';

import { ActionsMenu } from './components/Actions';
import AssetDetails from './components/AssetDetails';

import {
    ResourcePage,
    OverviewPage,
    MovementsPage
} from './components/Pages';

import Templates from '../../../../new-components/AnalysisTemplate';

import { useProps } from './Container';
import { useTranslation } from 'react-i18next';


const AssetsManagementView = incommingProps => {
    const baseProps = useProps();
    const { t } = useTranslation();
    const props = {
        ...baseProps,
        ...incommingProps
    }

    const {
        AssetsManagement,
        parksInfo,
        modulePage,
        open,
        setOpened,
        detailInfo,
        mounted,
        finishMount,
        options,
        storages,
        activeResource,
        selectModulePage,
        headMap,
        TYPE_GRID,
        TYPE_COLUMN,
        widgets,
        parks,
        history,
        selectedPage,
        loading,
        getComponents
    } = props;


    const { id: pageId, type: pageType, enableActions = true, widgetIds, aggregatedDisplayHeader } = modulePage;

    const Widgets = widgets && widgets.map(widget => ({
        [widget.id]: widget
    })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const [executeFilter, setExecuteFilter] = useState(true);

    useEffect(
        () => {
            if (mounted && executeFilter) {
                setTimeout(() => setExecuteFilter(false), 100)
            }
        },
        [executeFilter, mounted]
    );


    return (
        <div className='assets-page'
            style={{
                minHeight: '700px',
                maxWidth: '98vw'
            }}
        >
            {
                modulePage &&
                <BaseGlobalFilter
                    mode={'view'}
                    history={history}
                    page={modulePage}
                    reducer_name={'REDUCER_ASSETS_MANAGEMENT_GET_DATA'}
                    module_field={'AssetsManagement'}
                    assetsmanagement={AssetsManagement}
                    parksInfo={parksInfo}
                    executeFilter={pageInfo => mounted && setExecuteFilter(true)}
                    goToPage={selectModulePage}
                    forcedMaxDate={Templates.hasOwnProperty(pageType) && moment.tz('America/Fortaleza').add(1, 'day').format('YYYY-MM-DD')}
                    {...incommingProps}
                />
            }

            <>
                {
                    mounted && !loading ?
                        ((pageId === 6000 || pageId === 6006) &&
                            <OverviewPage {...props} aggregatedDisplayHeader={aggregatedDisplayHeader} />
                        ) || (pageId === 6001 &&
                            <MovementsPage {...props} />
                        ) || (pageType === 'CRUD' &&
                            <ResourcePage {...props} />
                        ) || (Templates.hasOwnProperty(pageType) &&
                            React.createElement(
                                Templates[pageType],
                                modulePage ?
                                    {
                                        ...modulePage,
                                        parks,
                                        history,
                                        selectedPage,
                                        widgets: widgetIds && widgetIds.map(widget => Widgets && Widgets[widget]),
                                        executeFilter,
                                        setExecuteFilter,
                                        storages,
                                        finishMount
                                    }
                                    :
                                    {}
                            )
                        )
                        :
                        <CircularLoading loading={`${t("Loading")}...`}>
                            <span>{t("Getting information of your components")}</span>
                        </CircularLoading>
                }

                <AssetDetails
                    headMap={headMap}
                    finishMount={finishMount}
                    models={options}
                    storages={storages}
                    open={open}
                    setOpened={setOpened}
                    sectors={[
                        {
                            type: TYPE_GRID,
                            details: {
                                acquisitionDate: detailInfo.acquisitionDate,
                                acquisitionValue: detailInfo.acquisitionValue,
                                receivedDate: detailInfo.receivedDate,
                                fabricationDate: detailInfo.fabricationDate
                            }
                        },
                        {
                            type: TYPE_GRID,
                            details: {
                                manufacturer: detailInfo.manufacturer,
                                supplier: detailInfo.supplier,
                                entryStock: detailInfo.entryStock,
                                placement: detailInfo.placement,
                            }
                        },
                        {
                            type: TYPE_GRID,
                            details: {
                                lastMovementDate: detailInfo.lastMovementDate,
                                nextMovementDate: detailInfo.nextMovementDate,
                                workOrderId: detailInfo.workOrderId,
                                lastMovementSupplier: detailInfo.lastMovementSupplier
                            }
                        },
                        {
                            type: TYPE_COLUMN,
                            details: {
                                componentStatus: detailInfo.componentStatus,
                                componentDocuments: detailInfo.componentDocuments && detailInfo.componentDocuments.join(', '),
                                lastMovementDocuments: detailInfo.lastMovementDocuments && detailInfo.lastMovementDocuments.join(', ')
                            }
                        }
                    ]}
                    {...detailInfo}
                    getComponents={getComponents}
                    activeResource={activeResource}
                />

                {
                    enableActions &&
                    <ActionsMenu
                        {...props}
                        activeResource={activeResource}
                    />
                }

            </>
        </div>
    );
};

export default AssetsManagementView;