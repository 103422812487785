export const extractAjustedParksInfo = parksInfo => {
    // TODO send this reatment to app init level
    // console.log('On extracting parks info', parksInfo)

    const parks = parksInfo.map(park => ({
        [park.id]: {
            id: park.id,
            name: park.park,
            childrenAmt: park.subparks.length,
            selectedChildrenAmt: 0
        }
    })).reduce((acc, curr) => ({...acc, ...curr}));

    const subparks = parksInfo.map(park => (
        park.subparks.map(subpark => ({
            [subpark.id]: {
                parentId: park.id, 
                id: subpark.id, 
                name: subpark.name,
                childrenAmt: subpark.wtgs.length,
                selectedChildrenAmt: 0
            }
        })).reduce((acc, curr) => ({...acc, ...curr}))
    )).reduce((acc, curr) => ({...acc, ...curr}));

    const devices = parksInfo.map(park => (
        park.subparks.map(subpark => (
            subpark.wtgs.map(device => ({
                [device.id]: {
                    parentId: subpark.id, 
                    id: device.id, 
                    name: device.name,
                    isSelected: false     //leaf
                }
            })).reduce((acc, curr) => ({...acc, ...curr}))
        )).reduce((acc, curr) => ({...acc, ...curr}))
    )).reduce((acc, curr) => ({...acc, ...curr}));

    const output = {
        parks,
        subparks,
        devices
    };

    return output;
};

export const extractDevicesScadaParksInfo = parksInfo => {
    const devices = parksInfo.map(park => (
        park.subparks.map(subpark => (
            subpark.wtgs.map(device => ({
                [device.id]: {
                    id: device.id,
                    scada: device.scada_list.length > 0 ?
                            device.scada_list.map(scada => ({[scada.id]: {...scada}}))
                                .reduce((acc, curr) => ({...acc, ...curr}))
                        :
                            {},
                    parentPark: {
                        id: park.id,
                        name: park.park,
                        scada: park.scada.length > 0 ?
                            park.scada.map(scada => ({[scada.id]: {...scada}}))
                                .reduce((acc, curr) => ({...acc, ...curr}))
                        :
                            {}
                    }
                }
            })).reduce((acc, curr) => ({...acc, ...curr}))
        )).reduce((acc, curr) => ({...acc, ...curr}))
    )).reduce((acc, curr) => ({...acc, ...curr}));

    return devices;
};