import React, {Component} from 'react'
import {withStyles, Button, TextField, Input, MenuItem, Select, FormControl, InputLabel} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { Icon } from 'react-icons-kit'
import {fileText} from 'react-icons-kit/fa/fileText'
import { ROUTE_MAIN_REPORTS_MANAGEMENT_NEW, ROUTE_MAIN_REPORTS_MANAGEMENT } from '../../../../../../constants/route-constant';
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import * as R from 'ramda'
import FilterComponent from '../../../../../../components/FilterComponent/FilterComponent';
import { getWidgetTypeProvider, createTemplateProvider, createReportProvider } from '../../../../../../providers/get-report-data-provider';
import { toast } from 'react-toastify'
import pageModel from '../../../../../../data/Reports/report-page.json'
import templateModel from '../../../../../../data/Reports/report-template.json'
import uuidv4 from 'uuid/v4'
import { getTemplateAllProvider } from '../../../../../../providers/get-report-data-provider'




const styles = () => ({
  headerText: {
    color: grey['700'],
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonHeader: {
    height: '100%',
    borderRadius: 0,
    border: `1px solid ${grey['200']}`,
    color: grey['700'],
    marginLeft: 10
  }
})

class ReportsHeaderComponent extends Component {

  constructor (props) {
    super (props)

    this.state = {
      generateDialog: false,
      generateDescription:'',
      pages:[],
      templateSelected:null,
      templates: [],

    }
  }



  listTemplates(){

    getTemplateAllProvider()
      .then(data => {
        this.setState({ templates: data })
      })
      .catch(error => {
        console.error('getTemplateAllProvider', error)
      })

  }

  componentDidMount () {
    this.setState({
      pages: [
        R.assoc('id', uuidv4())(
          R.assoc('selected', true)(pageModel)
        )
      ]
    })

    this.listTemplates();

  }


  openDialog = dialog => () => this.setState({ [dialog]: true, newWidgetDescription: '', newWidgetType: null })
  closeDialog = dialog => () => this.setState({ [dialog]: false, newWidgetDescription: '', newWidgetType: null })

  onGenerateDescription = (e) => this.setState({ generateDescription: e.target.value })


  changedTemplateSelected = (e) => {

    this.setState({ templateSelected: e.target.value, pages: e.target.value.pages })

  } 

    



  generateReport = (data) => {
    if (this.state.generateDescription) {
      const pages = this.state.pages.map(page => {
        const widgets = page.widgets.map(widget => {
          return {
            "position": {
              "x": widget.position.x,
              "y": widget.position.y,
              "h": widget.position.h,
              "w": widget.position.w
            },
            "widget_id": widget.widget.id
          }
        })
        
        return R.assoc('widgets', widgets)({})
      })
      
      const report = {
        description: this.state.generateDescription,
        pages,
        device_list: data.devices,
        subpark_list: data.subparks,
        range_date_start: data.date_range[0].format(),
        range_date_end: data.date_range[1].format()
      }

      createReportProvider(report)
        .then(() => {
          toast.success('Report in progress!', {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          })

          this.closeDialog('generateDialog')()

        })
        .catch(error => {
          console.error('createReportProvider', error)
          toast.error('Error saving!', {
            position: "top-right",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true
          })
        })
    } else {
      toast.error('Description is required!', {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      })
    }
  }

  generate = () => {
    this.openDialog('generateDialog')()
  }


  render () {
    const {classes} = this.props
    const {pages, templates} = this.state

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div
          className={classes.headerText}
        >
          <Icon icon={fileText} size={30} />
          <span
            style={{
              marginLeft: 10
            }}
          >
            Reports
          </span>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            height: '100%'
          }}
        >
          <Button
            className={classes.buttonHeader}
            onClick={this.generate.bind(this)}
          >
            Generate report
          </Button>
        </div>

        <Dialog
          onClose={this.closeDialog('generateDialog').bind(this)}
          aria-labelledby="dialod-reports-generate-report"
          open={this.state.generateDialog}
          // scroll={'body'}
          PaperProps={{
            style: {
              maxWidth: 'none',
              maxHeight: 'none',
              overflowX: 'hidden',
              overflowY: 'auto'
            }
          }}
        >
          <DialogTitle id="dialod-reports-generate-report">
            Generate report
          </DialogTitle>
          <div>
           <form
              // className={classes.container}
              noValidate
              autoComplete="off"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 500,
                padding: 10
              }}
            >
              <TextField
                label='Report description'
                value={this.state.generateDescription}
                onChange={this.onGenerateDescription.bind(this)}
                style={{
                  marginBottom: 20
                }}
              />

          <FormControl>
            <InputLabel
              htmlFor="age-simple"
            >
              Select Template
            </InputLabel>

            <Select
                value={this.state.templateSelected}
                onChange={this.changedTemplateSelected.bind(this)}
                inputProps={{
                  name: 'description',
                  id: 'id',
                }}
              >
              {
                R.flatten(
                  templates.map(tpl => {
                    return(
                      <MenuItem
                        key={tpl.id}
                        value={tpl}
                      >
                        {tpl.description}
                      </MenuItem>
                      )
                  })
                )

              }
              </Select>
          </FormControl>

              <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    maxHeight: '440px'
                  }}
                >
                  <FilterComponent
                    types={
                      R.uniq(
                        R.flatten(
                          pages.map(page => {
                            return page.widgets.map(widget => widget.widget.internal_filter)
                          })
                        )
                      )
                    }
                    confirm={this.generateReport.bind(this)}
                  /> 

              </div>
            </form>
          </div>
        </Dialog>
        
      </div>
    )
  }
}

export default withStyles(styles)(ReportsHeaderComponent)
