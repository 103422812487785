export default theme => ({
  root: {
    flexGrow: 1,
  },
  flipContainer: {
    width: '100%',
    height: `100%`,
    perspective: '1000px',
  },
  flipContainerFront: {
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    transform: 'rotateX(0deg)',
    backfaceVisibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  flipContainerBack: {
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'rotateX(180deg)',
  },
  flipper: {
    height: '100%',
    width: '100%',
    transition: '0.6s',
    transformStyle: 'preserve-3d',
    position: 'relative',
  },
  tableContent: {
    width: '100%',
    height: '100%'
  },
  tableCellHeaderValue: {
    padding: 0,
    textAlign: 'center'
  },
  tableCellValue: {
    padding: 0
  },
  rowHead: {
    height: 40
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    height: 40
  }
})