import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
    Icon,
    IconButton,
    Button
} from '@material-ui/core';

// (props.daysToExpire != null && props.daysToExpire > 20 && '#cce5ff') ||
// (props.daysToExpire != null && props.daysToExpire <= 20 && props.daysToExpire > 7 && '#fff3cd') ||
// (props.daysToExpire != null && props.daysToExpire <= 7 && '#f8d7da')

const Alert = styled.div`
    font-size: .85em;
    padding: 8px;
    background-color: ${props => (
        (props.danger && '#cce5ff') ||
        (props.warning && '#fff3cd') ||
        (props.info && '#f8d7da') ||
        '#e2e3e5'
    )};
    color: ${props => (
        (props.danger && '#004085') ||
        (props.warning && '#856404') ||
        (props.info && '#721c24') ||
        '#383d41'
    )};
    border: 1px solid ${props => (
        (props.danger && '#b8daff') ||
        (props.warning && '#ffeeba') ||
        (props.info && '#f5c6cb') ||
        '#d6d8db'
    )};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
`;

const Header = styled.div`
    width: 100%;
    color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.div`
    color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const Footer = styled.div`
    width: 100%;
    color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
`;

const SpacedRow = styled.div`
    display: grid;
    grid-template-columns: 50% 25% 25%;
    grid-gap: 4px 16px;
`;

const ExpirationAlertView = props => {
    const {
        initVisible,
        showEntries,
        alerts,
        children
    } = props;

    const alertObjects = children || alerts || [];
    const threshold = showEntries || 10;

    const [visible, setVisible] = useState(initVisible === undefined ? alertObjects.length > 0 : initVisible);
    const [entriesAmt, setEntriesAmt] = useState(threshold);
    const { t } = useTranslation();

    const exceding = alertObjects.length - entriesAmt;

    return (
        visible ?
            <>
                <Alert warning>
                    <Header>
                        <Title style={{ marginLeft: 8 }}>
                            <Icon>warning</Icon>
                            <h4 style={{ marginLeft: 4 }}><b>{t('You have')} <strong>{alertObjects.length} {t('alert(s)')}</strong></b></h4>
                        </Title>
                        <div>
                            <IconButton
                                key='close'
                                aria-label='close'
                                color='inherit'
                                onClick={() => setVisible(false)}
                            >
                                <Icon>close</Icon>
                            </IconButton>
                        </div>
                    </Header>
                    <div>
                        <ul>
                            {
                                alertObjects.slice(0, entriesAmt).map(alertObject => (
                                    <li>
                                        <SpacedRow>
                                            <span>{t('Buy')} <em><b>"{alertObject.name}"</b></em>.</span>
                                            <span>{t('Amount')} <small>({t('In Stock')})</small>: <b>{alertObject.stock_quantity}</b>.</span>
                                            <span>{t('Stock Minimum')}: <b>{alertObject.stock_minimum}</b>.</span>
                                        </SpacedRow>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    {
                        alertObjects.length > threshold ?
                            <Footer>
                                <Button
                                    variant='outlined'
                                    color='inherit'
                                    onClick={() => setEntriesAmt(exceding > 0 ? alertObjects.length : threshold)}
                                >
                                    {
                                        exceding > 0 ?
                                            <span>{t("View All Rows")} <small>({t("more")} {exceding})</small></span>
                                            :
                                            <span>{t("View Less Rows")}</span>
                                    }
                                </Button>
                            </Footer>
                            :
                            ''
                    }
                </Alert>

            </>
            :
            <></>
    );
};

export default ExpirationAlertView;