import React, {Component} from 'react';
import * as R from 'ramda';

const Pareto = (apply_to_series=null, ignore=-1) => {
  const real_Pareto = state => {
    try {
      if(state.data.series.filter(serie => serie.name == 'Pareto').length == 0) {
        state.data_without_pareto = JSON.parse(JSON.stringify(state.data));

        let firstBarIndex = state.data.series.map(serie => serie.type == 'bar').indexOf(true);
        firstBarIndex = firstBarIndex >= 0 ? firstBarIndex + 1 : -1;
        if(firstBarIndex == -1) {throw 'No Column to apply pareto.'}

        let to_apply = (
          apply_to_series ?
            apply_to_series.constructor == Array ?
              state.data.series.filter(series => apply_to_series.indexOf(series.name) >= 0)
            :
            apply_to_series.constructor == String ?
              state.data.series.filter(series => series.name == apply_to_series)
            :
              state.data.series.filter(series => series.type == 'bar')
          :
            state.data.series.filter(series => series.type == 'bar')
        );

        let to_apply_pre_processed = [];

        if(to_apply.length > 1) {
          to_apply[0].values.map((value, index) => {
            to_apply_pre_processed.push(R.sum(to_apply.map(series => series.values[index])))
          });
        } else {
          to_apply_pre_processed = to_apply[0].values;
        }

        if(ignore) {
          if(ignore >= 0) {
            to_apply_pre_processed = to_apply_pre_processed.slice(0, ignore);
          } else {
            to_apply_pre_processed = to_apply_pre_processed.slice(0, to_apply_pre_processed.length + ignore);
          }
        }

        let total_sum = R.sum(to_apply_pre_processed);
        let pareto = to_apply_pre_processed.map((value, index) => {
          let partial_sum = R.sum(to_apply_pre_processed.slice(0, index + 1));
          return (partial_sum/total_sum) * 100;
        });

        if(ignore) {
          if(ignore >= 0) {
            pareto = new Array(ignore).fill(null).concat(pareto);
          } else {
            pareto = pareto.concat(new Array(-1 * ignore).fill(null));
          }
        }

        let series_pareto = state.data.series.concat({
          name: 'Pareto',
          type: 'line',
          unit: '%',
          yAxis: 1,
          zIndex: 20,
          values: pareto,
          tooltip: null
        });

        state.data.series = series_pareto;
        state.data.extra_y_axis = {
          title: {
              text: ''
          },
          minPadding: 0,
          maxPadding: 0,
          max: 100,
          min: 0,
          endOnTick: false,
          opposite: true,
          labels: {
              format: "{value}%"
          },
          minorGridLineWidth: 0,
          gridLineWidth: 0
        };
      }
    } catch (e) {
      // ;
      state.data = JSON.parse(JSON.stringify(state.data_without_pareto));
      delete state.data_without_pareto;
      delete state.extra_y_axis;
    } finally {
      state.data.features.push('Pareto');
      state.data.features = new Array(... new Set(state.data.features))

      return state;
    }
  }

  return(real_Pareto);
};

export default Pareto;
