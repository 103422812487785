import { REDUCER_PREDICTION_GET_DATA, REDUCER_PREDICTION_CLEAR } from "../../../../../constants/reducers/widget-page-action-events";
import { REDUCER_SET_META_DATA, REDUCER_CHECKPOINT } from '../../../../../constants/reducers/get-meta-data'
import adjustPages from '../../../../../helpers/merge-page-info'

const DEFAULT_STATE = {}

export default (state = DEFAULT_STATE, action) => {
  const {type} = action

  switch (type) {
    case REDUCER_PREDICTION_GET_DATA:
      return action.payload;
    case REDUCER_PREDICTION_CLEAR:
      return DEFAULT_STATE;
    case REDUCER_CHECKPOINT:
      return 'payload' in action &&
              action.payload &&
              'checkpoint' in action.payload &&
              action.payload.checkpoint &&
              'Prediction' in action.payload.checkpoint &&
              action.payload.checkpoint.Prediction ?
                action.payload.checkpoint.Prediction
              :
                DEFAULT_STATE
    case REDUCER_SET_META_DATA:
      return 'payload' in action &&
              action.payload &&
              'prediction' in action.payload &&
              action.payload.prediction ?
                adjustPages(action.payload.prediction.pages, action.payload.prediction.filters, action.payload.prediction.decorators)
              :
                DEFAULT_STATE
    default:
      return state;
  }
}
