import { REDUCER_DOWNTIME_GET_DATA, REDUCER_DOWNTIME_CLEAR } from "../../../../../constants/reducers/page-get-data";
import { REDUCER_SET_META_DATA, REDUCER_CHECKPOINT } from '../../../../../constants/reducers/get-meta-data'
import adjustPages from '../../../../../helpers/merge-page-info'

const DEFAULT_STATE = {}

export default (state = DEFAULT_STATE, action) => {
  const {type} = action

  switch (type) {
    case REDUCER_DOWNTIME_GET_DATA:
      return action.payload;
    case REDUCER_DOWNTIME_CLEAR:
      return DEFAULT_STATE;
    case REDUCER_CHECKPOINT:
      return 'payload' in action &&
              action.payload &&
              'checkpoint' in action.payload &&
              action.payload.checkpoint &&
              'Downtime' in action.payload.checkpoint &&
              action.payload.checkpoint.Downtime ?
                action.payload.checkpoint.Downtime
              :
                DEFAULT_STATE
    case REDUCER_SET_META_DATA:
      return 'payload' in action &&
              action.payload &&
              'alarms' in action.payload &&
              action.payload.alarms ?
                adjustPages(action.payload.alarms.downtimes, action.payload.alarms['downtimes-filters'])
              :
                DEFAULT_STATE
    default:
      return state;
  }
}
