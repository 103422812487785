import { Auth } from 'aws-amplify';
import axios from 'axios';
import { URL_API } from '../constants/url-constant';
import moment from 'moment';

export const getMaintenances = async params => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    // const URL = `${ORIGIN}/crud_status/get_wtg_status`;
    const URL = `${URL_API}/maintenance/basic_info`;

    try {
        const { data } = await axios.get(
            URL,
            {
                headers: {
                    'Authorization': jwtToken
                },
                params: params
            }
        );

        return data;
    } catch (e) {
        console.log('ERROR ON GETTING MAINTENANCES: ', e);
        return null;
    }
};

export const getMaintenancesEquipments = async (maintenance_ids = []) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/equipments_by_maintenance`;

    try {
        const { data } = await axios.get(
            URL,
            {
                headers: {
                    'Authorization': jwtToken
                },
                params: { maintenance_ids }
            }
        );

        return data;
    } catch (e) {
        console.log('ERROR ON GETTING MAINTENANCES: ', e);
        return null;
    }
}

export const getMaintenancesTags = async (maintenance_ids = []) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/tags_by_maintenance`;

    try {
        const { data } = await axios.get(
            URL,
            {
                headers: {
                    'Authorization': jwtToken
                },
                params: { maintenance_ids }
            }
        );

        return data;
    } catch (e) {
        console.log('ERROR ON GETTING MAINTENANCES: ', e);
        return null;
    }
}

export const getTags = async params => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/tags`;

    try {
        const { data } = await axios.get(
            URL,
            {
                headers: {
                    'Authorization': jwtToken
                },
                params: params
            }
        );

        return data;
    } catch (e) {
        console.log('ERROR ON GETTING TAGS: ', e);
        return null;
    }
}

export const getEquipments = async params => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/equipments`;

    try {
        const { data } = await axios.get(
            URL,
            {
                headers: {
                    'Authorization': jwtToken
                },
                params: params
            }
        );

        return data;
    } catch (e) {
        console.log('ERROR ON GETTING EQUIPMENTS: ', e);
        return null;
    }
}

export const getStatus = async params => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/status`;

    try {
        const { data } = await axios.get(
            URL,
            {
                headers: {
                    'Authorization': jwtToken
                },
                params: params
            }
        );

        return data.map(status => ({ id: status.id, label: status.name }));
    } catch (e) {
        console.log('ERROR ON GETTING EQUIPMENTS: ', e);
        return null;
    }
}

export const getHelpers = async () => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/get_maintenance_help`;

    try {
        const { data } = await axios.get(
            URL,
            {
                headers: {
                    'Authorization': jwtToken
                }
            }
        );
        return data;
    } catch (e) {
        console.log('ERROR ON GETTING EQUIPMENTS: ', e);
        return null;
    };
};

export const newMaintenance = async ({ name, tag_ids, equip_ids, comments, start, end, selectedStatus }) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/new_maintenance`;

    const start_time = moment.tz(start, 'America/Fortaleza').format('YYYY-MM-DD HH:mm:ss');
    const end_time = moment.tz(end, 'America/Fortaleza').format('YYYY-MM-DD HH:mm:ss');

    const { data } = await axios.post(
        URL,
        {
            name,
            tag_ids,
            equip_ids,
            comments,
            start_time,
            end_time,
            status_id: selectedStatus.id
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        }
    );

    return data;
}

export const updateMaintenance = async ({ id, name, tag_ids, equip_ids, comments, start, end, selectedStatus }) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/update_maintenance`;

    const start_time = moment.tz(start, 'America/Fortaleza').format('YYYY-MM-DD HH:mm:ss');
    const end_time = moment.tz(end, 'America/Fortaleza').format('YYYY-MM-DD HH:mm:ss');

    const { data } = await axios.post(
        URL,
        {
            id,
            name,
            tag_ids,
            equip_ids,
            comments,
            start_time,
            end_time,
            status_id: selectedStatus.id
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        }
    );

    return data;
}

export const newTag = async ({ name, color, id = null }) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/new_tag`;

    try {
        const { data } = await axios.post(
            URL,
            {
                name,
                color
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            }
        );

        const newId = data._tag_id[0];
        return { name, color, id: newId != null ? newId : id };
    } catch (e) {
        console.log('Error on Saving New Tag in route', `${URL_API}/maintenance/new_tag`, e);
        return { name, color, id }
    }
}

export const newEquipment = async ({ name, id = null }) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/new_equipment`;

    try {
        const { data } = await axios.post(
            URL,
            {
                name
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            }
        );

        const newId = data._equipment_id[0];
        return { name, id: newId != null ? newId : id };
    } catch (e) {
        console.log('Error on Saving New Tag in route', `${URL_API}/maintenance/new_equipment`, e);
        return { name, id }
    }
}

export const deleteMaintenance = async ({ id }) => {
    const { accessToken } = await Auth.currentSession();
    const { jwtToken } = accessToken;

    const URL = `${URL_API}/maintenance/delete_maintenance`;

    const { data } = await axios.post(
        URL,
        {
            id
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        }
    );

    return data;
}