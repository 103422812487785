import React, {Component} from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import map from '../../data/META/map.json'
import windTurbineIcon from '../../assets/gifs/wind_turbine_transp.gif'
import { averageGeolocationHelper } from '../../helpers/average-geolocation-helper'
import { injectGlobal } from 'styled-components'
import * as R from 'ramda'

injectGlobal`
  a[href^="http://maps.google.com/maps"]{display:none !important}
  a[href^="https://maps.google.com/maps"]{display:none !important}

  .gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
  }
`

const SHOW_WTG = 'wtg'
const SHOW_SUBPARK = 'subpark'
const SHOW_PARK = 'park'
const mapStylesData = []

class GoogleMapItemComponent extends Component {
  constructor (props) {
    super (props)

    this.map = React.createRef()
    this.state = {
      zoom: map.zoom_thesholds.park,
      zoomShow: SHOW_PARK
    }
  }

  changeZoom = () => {
    const zoom = this.map.current.getZoom()

    if (zoom >= map.zoom_thesholds.wtg) {
      this.setState({ zoomShow: SHOW_WTG })
    }

    if (zoom > map.zoom_thesholds.park && zoom < map.zoom_thesholds.wtg) {
      this.setState({ zoomShow: SHOW_SUBPARK })
    }

    if (zoom <= map.zoom_thesholds.park) {
      this.setState({ zoomShow: SHOW_PARK })
    }
  }

  getWtgs = (parks) => {
    return R.flatten(parks.map(park => park.subparks.map(subpark => {
      return subpark.wtgs.filter(wtg => {
        return wtg.latitude && wtg.longitude
      })
    })))
    .map(item => ({
      ...item,
      icon: windTurbineIcon
    }))
  }

  getSubparks = (parks) => {

    if (!Array.isArray(parks)) {
        let parskArray = Array();
        parskArray.push(parks);
        parks = parskArray;
    }

    return R.flatten(parks.map(park => park.subparks.map(subpark => {
      const wtgs = subpark.wtgs.filter(wtg => {
        return wtg.latitude && wtg.longitude
      })

      return {
        ...averageGeolocationHelper(wtgs),
        id: subpark.id,
        name: subpark.name,
        icon: windTurbineIcon
      }
    })));
  }

  getParks = (parks) => {
    return parks.map(park => {
      const geolocations = this.getSubparks(park);

      return {
        ...averageGeolocationHelper(geolocations),
        id: park.id,
        name: park.park,
        icon: windTurbineIcon
      };
    });
  }

  getMarkersToShow = (park) => {
    if (this.state.zoomShow === SHOW_WTG) {
      return this.getWtgs(park)
    }

    if (this.state.zoomShow === SHOW_SUBPARK) {
      return this.getSubparks(park)
    }

    if (this.state.zoomShow === SHOW_PARK) {
      return this.getParks(park)
    }

    return []
  }

  render () {
    const {items, park} = this.props

    const makers = park ? this.getMarkersToShow(park) : []

    const center = averageGeolocationHelper(items)
    const setingCenter = Number.isNaN(center.latitude) && Number.isNaN(center.longitude)
      ? { latitude: -34.397, longitude: 150.644 }
      : center

    return (
      <GoogleMap
        ref={this.map}
        onZoomChanged={this.changeZoom.bind(this)}
        zoom={this.state.zoom}
        center={{
          lat: setingCenter.latitude,
          lng: setingCenter.longitude
        }}
        defaultOptions={{
          styles: mapStylesData,
          mapTypeControl: false,
          rotateControl: false,
          scaleControl: false,
          zoomControl: false,
          streetViewControl: false,
          panControl: false,
          disableDefaultUI: true,
          mapTypeId: 'satellite'
        }}
      >
        {makers.map((item, index) => {
          return (
            <Marker
              key={index}
              defaultIcon={{
                url: item.icon,
                size: {
                  width: 100,
                  height: 100
                }
              }}
              label={{
                color: '#fff',
                fontSize: '18px',
                fontWeight: "bold",
                text: item.name
              }}
              position={{
                lat: item.latitude,
                lng: item.longitude
              }}
            />
          )
        })}
      </GoogleMap>
    )
  }
}

const MapWithAMarker = withScriptjs(withGoogleMap(GoogleMapItemComponent))

class GoogleMapComponent extends Component {
  render () {
    const {items, park} = this.props

    return (
      <MapWithAMarker
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPJbD4u-Cm7JZW6jXb2BZvfrZxhfJCCUw&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        items={items}
        park={park}
      />
    )
  }
}

export default GoogleMapComponent
