import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import * as R from 'ramda'
import * as moment from 'moment'
import {
  withStyles,
  LinearProgress,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Icon,
  Button
} from '@material-ui/core';

import styles from './styles/styles.js';
import { ROUTE_MAIN_DEVICE } from '../../../constants/route-constant';

class GraphicInteractiveTable extends Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      colors: [
        '#99ff66',
        '#F4645C',  //red
        '#B414DC',
        '#FCDC34',  // yellow
        '#4C65A7',
        '#FC9018',
        '#0A70D2',
        '#99ff66'   // green
      ],
      columns: [],
      column_sort: null,
      is_crescent: false
    }

    this.chartWillRender = this.chartWillRender.bind(this);
  }

  componentDidMount() {
    this.props.getSettings(this.getSettings.bind(this));
  }

  chartWillRender() { }

  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = (checkbox, extra_columns) => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);

    if (extra_columns) {
      this.setState({ prevColumns: this.state.columns });
      this.state.columns = this.state.columns.map((column, index) => (
        extra_columns[String(index)] ? `${column}/\n${extra_columns[String(index)]}` : column
      ));
    }
  };

  getSettings() { }

  setFilter(params) {
    let order = !this.state.is_crescent
    this.setState({ column_sort: params.index, is_crescent: order })
  }

  orderRows(rows, column_sort, is_crescent) {
    const sort_anchor = rows.map((row, index) => {

      return { idx: index, sort_value: row.values[column_sort] }
    }).sort((a, b) => {
      if (a.sort_value == 'red' && (b.sort_value == 'green' || b.sort_value == 'yellow')) {
        return is_crescent ? 1 : -1
      } else if (b.sort_value == 'red' && (a.sort_value == 'green' || a.sort_value == 'yellow')) {
        return is_crescent ? -1 : 1
      } else if (a.sort_value == 'yellow' && b.sort_value == 'green') {
        return is_crescent ? 1 : -1
      } else if (b.sort_value == 'yellow' && a.sort_value == 'green') {
        return is_crescent ? -1 : 1
      } else if (a.sort_value == 'green' && b.sort_value == 'yellow') {
        return is_crescent ? -1 : 1
      } else if (b.sort_value == 'green' && a.sort_value == 'yellow') {
        return is_crescent ? 1 : -1
      } else if (a.sort_value == b.sort_value && ['green', 'yellow', 'red'].indexOf(a.sort_value) >= 0) {
        return 0
      } else {
        return a.sort_value < b.sort_value ?
          is_crescent ? -1 : 1
          :
          a.sort_value > b.sort_value ?
            is_crescent ? 1 : -1
            :
            0
      }
    })

    return rows.map((row, index) => rows[sort_anchor[index].idx])
  }

  render() {

    const { classes, showTable, showSettings, graphic, loading } = this.props;
    const { data, id, widths } = graphic;

    const dateRange = 'data_range' in this.props.DateFilter ? this.props.DateFilter.data_range : this.props.moduleSelected.filter.data_range

    const wtgsVerifyLink = R.flatten(this.props.parks_info.map(({ subparks }) => subparks.map(({ wtgs }) => wtgs)))

    const left_cell_width = 1;
    const max_cells_width = 12 - left_cell_width;

    this.state.columns = data.columns;

    var on_render_state = {
      ...this.state,
      data: data.rows ? data : { ...data, rows: [] },
      widths: widths
    };



    if (this.state.column_sort >= 0) {
      if (on_render_state.data.rows) {
        on_render_state.data.rows = this.orderRows(on_render_state.data.rows, this.state.column_sort, this.state.is_crescent)
        on_render_state.data.rows = on_render_state.data.rows.map(row => {
          if (row.internal_table) {
            row.internal_table.rows = this.orderRows(row.internal_table.rows, this.state.column_sort, this.state.is_crescent)
          }

          return row
        })
      }
    }

    on_render_state.widths = 'widths' in on_render_state.data ?
      on_render_state.data.widths
      : 'widths' in on_render_state ?
        on_render_state.widths
        :
        new Array(on_render_state.data.rows[0].values.length).fill(1)

    this.chartWillRender(on_render_state);

    if (on_render_state.data.rows) {
      on_render_state.data.rows.map(row => (
        row.values[0] =
        row.values[0] == 'Average Generator Speed 10M vs Generator Speed St. Dev. 10M' ? 'Generator Speed I'
          : row.values[0] == 'Average Active Power 10M vs Trafo 3 winding temperature 10M' ? 'Trafo Temperature III'
            : row.values[0] == 'Average Active Power 10M vs Average Reactive Power 10M' ? 'Reactive Power'
              : row.values[0] == 'Average Active Power 10M vs Bearing N.D.E. Temperature 10M' ? 'NDE Bearing Temperature'
                : row.values[0] == 'Average Active Power 10M vs Average Stator Active Power 10M' ? 'Stator Act. Power'
                  : row.values[0] == 'Average Rotor Speed 10M vs Rotor Speed St. Dev. 10M' ? 'Rotor Speed I'
                    : row.values[0] == 'Average Active Power 10M vs Trafo 2 winding temperature 10M' ? 'Trafo Temperature II'
                      : row.values[0] == 'Average Active Power 10M vs Generator windings temperature 2 10M' ? 'Gen. Windings Temperature II'
                        : row.values[0] == 'Average Active Power 10M vs Generators sliprings temperature 10M' ? 'Gen. Slipring'
                          : row.values[0] == 'Average Active Power 10M vs Generator windings temperature 3 10M' ? 'Gen. Windings Temperature III'
                            : row.values[0] == 'Average Active Power 10M vs Gearbox bearing temperature 10M' ? 'Gearbox Bearing Temperature'
                              : row.values[0] == 'Average Active Power 10M vs Average Rotor Speed 10M' ? 'Rotor Speed II'
                                : row.values[0] == 'Average Wind Speed 10M vs Average Active Power 10M' ? 'Measured Power Curve'
                                  : row.values[0] == 'Average Active Power 10M vs Average Generator Speed 10M' ? 'Generator Speed II'
                                    : row.values[0] == 'Average Generator Speed 10M vs Average Rotor Speed 10M' ? 'Rotor Speed III'
                                      : row.values[0] == 'Average Active Power 10M vs Bearing D.E. Temperature 10M' ? 'DE Bearing Temperature'
                                        : row.values[0] == 'Average Active Power 10M vs Average Grid Voltage 10M' ? 'Grid Voltage'
                                          : row.values[0] == 'Average Active Power 10M vs Yaw Misalignment = (WTG Nacelle Position) - (MetMast Wind Dir)' ? 'Yaw Misalignment'
                                            : row.values[0] == 'Average Active Power 10M vs Gearbox oil temperature 10M' ? 'Gearbox Oil'
                                              : row.values[0] == 'Average Active Power 10M vs Hidraulic group pressure 10M' ? 'Hidraulic Group'
                                                : row.values[0] == 'Average Active Power 10M vs Average Pitch Angle 10M' ? 'Pitch Angle'
                                                  : row.values[0] == 'Average Active Power 10M vs Generator windings temperature 1 10M' ? 'Gen. Windings Temperature I'
                                                    : row.values[0] == 'Average Active Power 10M vs Trafo 1 winding temperature 10M' ? 'Trafo Temperature I'
                                                      : row.values[0]
      ));

      on_render_state.data.rows.map(row => (
        row.values[2] =
        row.values[2] == 'Average Generator Speed 10M vs Generator Speed St. Dev. 10M' ? 'Generator Speed I'
          : row.values[2] == 'Average Active Power 10M vs Trafo 3 winding temperature 10M' ? 'Trafo Temperature III'
            : row.values[2] == 'Average Active Power 10M vs Average Reactive Power 10M' ? 'Reactive Power'
              : row.values[2] == 'Average Active Power 10M vs Bearing N.D.E. Temperature 10M' ? 'NDE Bearing Temperature'
                : row.values[2] == 'Average Active Power 10M vs Average Stator Active Power 10M' ? 'Stator Act. Power'
                  : row.values[2] == 'Average Rotor Speed 10M vs Rotor Speed St. Dev. 10M' ? 'Rotor Speed I'
                    : row.values[2] == 'Average Active Power 10M vs Trafo 2 winding temperature 10M' ? 'Trafo Temperature II'
                      : row.values[2] == 'Average Active Power 10M vs Generator windings temperature 2 10M' ? 'Gen. Windings Temperature II'
                        : row.values[2] == 'Average Active Power 10M vs Generators sliprings temperature 10M' ? 'Gen. Slipring'
                          : row.values[2] == "Average Active Power 10M vs Generator’s sliprings temperature 10M" ? 'Gen. Slipring'
                            : row.values[2] == 'Average Active Power 10M vs Generator windings temperature 3 10M' ? 'Gen. Windings Temperature III'
                              : row.values[2] == 'Average Active Power 10M vs Gearbox bearing temperature 10M' ? 'Gearbox Bearing Temperature'
                                : row.values[2] == 'Average Active Power 10M vs Average Rotor Speed 10M' ? 'Rotor Speed II'
                                  : row.values[2] == 'Average Wind Speed 10M vs Average Active Power 10M' ? 'Measured Power Curve'
                                    : row.values[2] == 'Average Active Power 10M vs Average Generator Speed 10M' ? 'Generator Speed II'
                                      : row.values[2] == 'Average Generator Speed 10M vs Average Rotor Speed 10M' ? 'Rotor Speed III'
                                        : row.values[2] == 'Average Active Power 10M vs Bearing D.E. Temperature 10M' ? 'DE Bearing Temperature'
                                          : row.values[2] == 'Average Active Power 10M vs Average Grid Voltage 10M' ? 'Grid Voltage'
                                            : row.values[2] == 'Average Active Power 10M vs Yaw Misalignment = (WTG Nacelle Position) - (MetMast Wind Dir)' ? 'Yaw Misalignment'
                                              : row.values[2] == 'Average Active Power 10M vs Gearbox oil temperature 10M' ? 'Gearbox Oil'
                                                : row.values[2] == 'Average Active Power 10M vs Hidraulic group pressure 10M' ? 'Hidraulic Group'
                                                  : row.values[2] == 'Average Active Power 10M vs Average Pitch Angle 10M' ? 'Pitch Angle'
                                                    : row.values[2] == 'Average Active Power 10M vs Generator windings temperature 1 10M' ? 'Gen. Windings Temperature I'
                                                      : row.values[2] == 'Average Active Power 10M vs Trafo 1 winding temperature 10M' ? 'Trafo Temperature I'
                                                        : row.values[2]
      ));
    }

    let hs_index = on_render_state.data.columns.indexOf('Health Score');
    if (hs_index >= 0) {
      on_render_state.data.rows.map(row => {
        let hs_value = row.internal_table && row.internal_table.rows ?
          row.internal_table.rows.map(internal_row => internal_row.values[hs_index])
          :
          null

        if (hs_value) {
          row.values[hs_index] = hs_value.reduce((prev, curr) => prev < curr ? prev : curr);
        }

        return row;
      });
    }
    let hs_date_index = on_render_state.data.columns.indexOf('Alarm Start Time');
    if (hs_date_index >= 0) {
      on_render_state.data.rows.map(rows => {

        let date = moment(rows.values[hs_date_index]);

        rows.values[hs_date_index] = date.tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')

        return rows;
      });
    }
    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showSettings ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <List className={classes.table}>
            <ListItem className={classes.TableHeader}>
              <Grid container spacing={0} style={{ padding: 0 }} className='print-exception'>
                <Grid key={`${id}1`} item xs={left_cell_width}
                  className={classes.tableItem} direction="column" align="center"
                >
                  <ListItemText></ListItemText>
                </Grid>
                {
                  on_render_state.data.columns.map((name, index) => (
                    <Grid key={`${id}${index}1`} item xs={on_render_state.widths[index]}
                      className={classes.tableItem} direction="column" align="center"
                    >
                      <ListItemText classes={{ primary: classes.listItemText }}
                        primary={this.state.columns[index]} />
                      <Button onClick={this.setFilter.bind(this, { name: name, index: index })} mini
                        style={{
                          paddingRight: '30px'
                        }}>
                        <Icon>arrow_drop_down</Icon>
                      </Button>
                    </Grid>
                  ))
                }
              </Grid>
            </ListItem>
            {
              on_render_state.data.rows && on_render_state.data.rows.length > 0 ?
                on_render_state.data.rows.map((row, index) => {
                  return (
                    <React.Fragment>
                      {wtgsVerifyLink.filter(itemWtg => itemWtg.name === row.values[0]).length > 0 ? (
                        <Link
                          to={`${ROUTE_MAIN_DEVICE}?wtgs=[${(R.find(R.propEq('name', row.values[0]))(wtgsVerifyLink)).id}]&date_init=${moment(dateRange.startDate).format('YYYY-MM-DD')}&date_final=${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
                          style={{
                            textDecoration: 'none'
                          }}
                        >
                          <ListItem style={{ padding: 0, borderTop: '1px solid silver' }} key={`${id}${index}2`} className={classes.link} >
                            <Grid container spacing={0} style={{ padding: 0 }} className='print-exception'>
                              <Grid key={`${id}${index}21`} item xs={left_cell_width}
                                className={classes.tableItem} direction="column" align="center"
                              >
                                {
                                  row.internal_table ?
                                    <Checkbox
                                      checked={this.state[`${id}${index}21`]}
                                      onChange={this.checkboxToggle(
                                        `${id}${index}21`,
                                        'extra_columns' in row ? row.extra_columns : null
                                      )}
                                      value={`${id}${index}21`}
                                      icon={<Icon>arrow_drop_down</Icon>}
                                      checkedIcon={<Icon>arrow_drop_up</Icon>}
                                    />
                                    :
                                    null
                                }
                              </Grid>
                              {
                                row.values.map((value, index) => {
                                  let val_color = null;
                                  switch (value) {
                                    case 'red':
                                      val_color = this.state.colors[1];
                                      break;
                                    case 'green':
                                      val_color = this.state.colors[7];
                                      break;
                                    case 'yellow':
                                      val_color = this.state.colors[3];
                                      break;
                                    case 'grey':
                                      val_color = '#dbdbdb';
                                      break;
                                    default:
                                      if (value) {
                                        if (value.constructor == String) {
                                          if (value.indexOf('#') >= 0) {
                                            val_color = value
                                          }
                                        }
                                      }
                                      break;
                                  }

                                  return (
                                    <Grid key={`${id}${index}22`} item xs={on_render_state.widths[index]}
                                      className={classes.tableItem} direction="column" align="center"
                                    >
                                      {
                                        val_color ?
                                          <Icon className={classes.icon} style={{ color: val_color }}>
                                            brightness_1
                                                </Icon>
                                          :
                                          value && value.constructor == Number && 'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[index] ?
                                            <Grid container spacing={2} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[index] * 25 : 0}px` }}>
                                              <Grid key={`grid_number_${id}${index}22`} item xs={7}
                                                className={classes.tableItemProgress} direction="column" align="right"
                                              >
                                                <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                              </Grid>
                                              <Grid key={`grid_bar_${id}${index}22`} item xs={5}
                                                className={classes.tableItemProgress} direction="column" align="left"
                                              >
                                                <LinearProgress variant="determinate" value={
                                                  Math.ceil((parseInt(value) - on_render_state.data.boundaries[index][0]) * 100 / (on_render_state.data.boundaries[index][1] - on_render_state.data.boundaries[index][0]))
                                                } valueBuffer={100} />
                                              </Grid>
                                            </Grid>
                                            :
                                            value && value.constructor == Number ?
                                              <Grid container spacing={0} style={{ padding: 0 }}>
                                                <Grid key={`grid_number_${id}${index}22`} item xs={12}
                                                  className={classes.tableItemProgress} direction="column" align="center"
                                                >
                                                  <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                </Grid>
                                              </Grid>
                                              :
                                              value == 0 ?
                                                <Grid container spacing={2} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[index] * 25 : 0}px` }}>
                                                  <Grid key={`grid_number_${id}${index}22`} item xs={7}
                                                    className={classes.tableItemProgress} direction="column" align="right"
                                                  >
                                                    <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                  </Grid>
                                                  <Grid key={`grid_bar_${id}${index}22`} item xs={5}
                                                    className={classes.tableItemProgress} direction="column" align="left"
                                                  >
                                                    <LinearProgress variant="determinate" value={
                                                      Math.ceil((parseInt(value) - on_render_state.data.boundaries[index][0]) * 100 / (on_render_state.data.boundaries[index][1] - on_render_state.data.boundaries[index][0]))
                                                    } valueBuffer={100} />
                                                  </Grid>
                                                </Grid>
                                                :

                                                <ListItemText classes={{ primary: classes.listItemText }} primary={value} />
                                      }
                                    </Grid>
                                  );
                                })
                              }
                            </Grid>
                          </ListItem>
                        </Link>

                      ) : <ListItem style={{ padding: 0, borderTop: '1px solid silver' }} key={`${id}${index}2`} >
                          <Grid container spacing={0} style={{ padding: 0 }} className='print-exception'>
                            <Grid key={`${id}${index}21`} item xs={left_cell_width}
                              className={classes.tableItem} direction="column" align="center"
                            >
                              {
                                row.internal_table ?
                                  <Checkbox
                                    checked={this.state[`${id}${index}21`]}
                                    onChange={this.checkboxToggle(
                                      `${id}${index}21`,
                                      'extra_columns' in row ? row.extra_columns : null
                                    )}
                                    value={`${id}${index}21`}
                                    icon={<Icon>arrow_drop_down</Icon>}
                                    checkedIcon={<Icon>arrow_drop_up</Icon>}
                                  />
                                  :
                                  null
                              }
                            </Grid>
                            {
                              row.values.map((value, index) => {
                                let val_color = null;
                                switch (value) {
                                  case 'red':
                                    val_color = this.state.colors[1];
                                    break;
                                  case 'green':
                                    val_color = this.state.colors[7];
                                    break;
                                  case 'yellow':
                                    val_color = this.state.colors[3];
                                    break;
                                  case 'grey':
                                    val_color = '#dbdbdb';
                                    break;
                                  default:
                                    if (value) {
                                      if (value.constructor == String) {
                                        if (value.indexOf('#') >= 0) {
                                          val_color = value
                                        }
                                      }
                                    }
                                    break;
                                }

                                return (
                                  <Grid key={`${id}${index}22`} item xs={on_render_state.widths[index]}
                                    className={classes.tableItem} direction="column" align="center"
                                  >
                                    {
                                      val_color ?
                                        <Icon className={classes.icon} style={{ color: val_color }}>
                                          brightness_1
                                      </Icon>
                                        :
                                        value && value.constructor == Number && 'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[index] ?
                                          <Grid container spacing={2} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[index] * 25 : 0}px` }}>
                                            <Grid key={`grid_number_${id}${index}22`} item xs={7}
                                              className={classes.tableItemProgress} direction="column" align="right"
                                            >
                                              <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                            </Grid>
                                            <Grid key={`grid_bar_${id}${index}22`} item xs={5}
                                              className={classes.tableItemProgress} direction="column" align="left"
                                            >
                                              <LinearProgress variant="determinate" value={
                                                Math.ceil((parseInt(value) - on_render_state.data.boundaries[index][0]) * 100 / (on_render_state.data.boundaries[index][1] - on_render_state.data.boundaries[index][0]))
                                              } valueBuffer={100} />
                                            </Grid>
                                          </Grid>
                                          :
                                          value && value.constructor == Number ?
                                            <Grid container spacing={0} style={{ padding: 0 }}>
                                              <Grid key={`grid_number_${id}${index}22`} item xs={12}
                                                className={classes.tableItemProgress} direction="column" align="center"
                                              >
                                                <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                              </Grid>
                                            </Grid>
                                            :
                                            value == 0 && on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[index] ?
                                              <Grid container spacing={2} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[index] * 25 : 0}px` }}>
                                                <Grid key={`grid_number_${id}${index}22`} item xs={7}
                                                  className={classes.tableItemProgress} direction="column" align="right"
                                                >
                                                  <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                </Grid>
                                                <Grid key={`grid_bar_${id}${index}22`} item xs={5}
                                                  className={classes.tableItemProgress} direction="column" align="left"
                                                >
                                                  <LinearProgress variant="determinate" value={
                                                    Math.ceil((parseInt(value) - on_render_state.data.boundaries[index][0]) * 100 / (on_render_state.data.boundaries[index][1] - on_render_state.data.boundaries[index][0]))
                                                  } valueBuffer={100} />
                                                </Grid>
                                              </Grid>
                                              :

                                              <ListItemText classes={{ primary: classes.listItemText }} primary={value} />
                                    }
                                  </Grid>
                                );
                              })
                            }
                          </Grid>
                        </ListItem>}
                      {
                        row.internal_table ? (
                          <Collapse in={this.state[`${id}${index}21`]} timeout="auto" unmountOnExit>
                            <ListItem style={{ padding: 0 }} key={`collapse_${id}${index}21`}>
                              <Grid container spacing={0} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[index] * 1 : 0}px` }}>
                                {
                                  row.internal_table ?
                                    row.internal_table.rows.map((item, index) => {
                                      if ('values' in item) {
                                        return item.values.map((value, values_index, array) => {
                                          let val_color = null;
                                          switch (value) {
                                            case 'red':
                                              val_color = this.state.colors[1];
                                              break;
                                            case 'green':
                                              val_color = this.state.colors[7];
                                              break;
                                            case 'yellow':
                                              val_color = this.state.colors[3];
                                              break;
                                            case 'grey':
                                              val_color = '#dbdbdb';
                                              break;
                                            default:
                                              break;
                                          }

                                          return (
                                            values_index == 0 ?
                                              <React.Fragment>
                                                <Grid key={`${id}${index}${values_index}220`} item xs={left_cell_width}
                                                  className={classes.tableItem} direction="column" align="center"
                                                >
                                                </Grid>
                                                <Grid key={`${id}${index}${values_index}221`} item xs={on_render_state.widths[values_index]}
                                                  className={classes.tableItem} direction="column" align="center"
                                                >
                                                  {
                                                    val_color ?
                                                      <Icon className={classes.icon} style={{ color: val_color, paddingRight: '0px' }}>
                                                        brightness_1
                                                        </Icon>
                                                      :
                                                      value && value.constructor == Number && 'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[values_index] ?
                                                        <Grid container spacing={2} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[values_index] * 25 : 0}px` }}>
                                                          <Grid key={`grid_number_${id}${index}22`} item xs={7}
                                                            className={classes.tableItemProgress} direction="column" align="right"
                                                          >
                                                            <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                          </Grid>
                                                          <Grid key={`grid_bar_${id}${index}22`} item xs={5}
                                                            className={classes.tableItemProgress} direction="column" align="left"
                                                          >
                                                            <LinearProgress variant="determinate" value={
                                                              Math.ceil((parseInt(value) - on_render_state.data.boundaries[values_index][0]) * 100 / (on_render_state.data.boundaries[values_index][1] - on_render_state.data.boundaries[values_index][0]))
                                                            } valueBuffer={100} />
                                                          </Grid>
                                                        </Grid>
                                                        :
                                                        value && value.constructor == Number ?
                                                          <Grid container spacing={0} style={{ padding: 0 }}>
                                                            <Grid key={`grid_number_${id}${index}22`} item xs={12}
                                                              className={classes.tableItemProgress} direction="column" align="center"
                                                            >
                                                              <ListItemText classes={{ primary: classes.listItemText }} primary={value} />
                                                            </Grid>
                                                          </Grid>
                                                          :
                                                          <ListItemText classes={{ primary: classes.listItemText }} primary={value} />
                                                  }
                                                </Grid>
                                              </React.Fragment>
                                              :
                                              <Grid key={`${id}${index}${values_index}221`} item xs={on_render_state.widths[values_index]}
                                                className={classes.tableItem} direction="column" align="center"
                                              >
                                                {
                                                  val_color ?
                                                    <Icon className={classes.icon} style={{ color: val_color, paddingRight: '0px' }}>
                                                      brightness_1
                                                      </Icon>
                                                    :
                                                    value && value.constructor == Number ?
                                                      <Grid container spacing={2} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[values_index] * 25 : 0}px` }}>
                                                        <Grid key={`grid_number_${id}${index}221`} item xs={7}
                                                          className={classes.tableItemProgress} direction="column" align="right"
                                                        >
                                                          <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                        </Grid>
                                                        <Grid key={`grid_bar_${id}${index}221`} item xs={5}
                                                          className={classes.tableItemProgress} direction="column" align="left"
                                                        >
                                                          <LinearProgress variant="determinate" value={
                                                            'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[values_index] ?
                                                              Math.ceil((parseInt(value) - on_render_state.data.boundaries[values_index][0]) * 100 / (on_render_state.data.boundaries[values_index][1] - on_render_state.data.boundaries[values_index][0]))
                                                              :
                                                              parseInt(value)
                                                          } valueBuffer={100} />
                                                        </Grid>
                                                      </Grid>
                                                      :
                                                      value == 0 ?
                                                        <Grid container spacing={2} style={{ padding: 0 }} style={{ paddingRight: `${graphic.grid.w > 5 ? on_render_state.widths[values_index] * 25 : 0}px` }}>
                                                          <Grid key={`grid_number_${id}${index}221`} item xs={7}
                                                            className={classes.tableItemProgress} direction="column" align="right"
                                                          >
                                                            <ListItemText classes={{ primary: classes.listItemText }} primary={String(value).indexOf('.') >= 0 ? parseFloat(value).toFixed(2) : parseInt(value)} />
                                                          </Grid>
                                                          <Grid key={`grid_bar_${id}${index}221`} item xs={5}
                                                            className={classes.tableItemProgress} direction="column" align="left"
                                                          >
                                                            <LinearProgress variant="determinate" value={
                                                              'boundaries' in on_render_state.data && on_render_state.data.boundaries && on_render_state.data.boundaries[values_index] ?
                                                                Math.ceil((parseInt(value) - on_render_state.data.boundaries[values_index][0]) * 100 / (on_render_state.data.boundaries[values_index][1] - on_render_state.data.boundaries[values_index][0]))
                                                                :
                                                                parseInt(value)
                                                            } valueBuffer={100} />
                                                          </Grid>
                                                        </Grid>

                                                        : <ListItemText classes={{ primary: classes.listItemText }} primary={value} />
                                                }
                                              </Grid>
                                          );
                                        });
                                      } else if ('header' in item && 'value' in item) {
                                        return (
                                          <React.Fragment>
                                            <Grid key={`${id}${index}221`} item xs={1} className={classes.tableItem}>
                                              <ListItemText> </ListItemText>
                                            </Grid>
                                            <Grid key={`${id}${index}222`} item xs={3} className={classes.tableItem}>
                                              <ListItemText>{item.header}</ListItemText>
                                            </Grid>
                                            <Grid key={`${id}${index}223`} item xs={8} className={classes.tableItem}>
                                              <ListItemText>{item.value}</ListItemText>
                                            </Grid>
                                          </React.Fragment>
                                        );
                                      }
                                    })
                                    :
                                    null
                                }
                              </Grid>
                            </ListItem>
                          </Collapse>
                        ) : null
                      }
                    </React.Fragment>
                  );
                })
                :
                <div style={{
                  textAlign: 'center',
                  padding: '3%'
                }}>
                  No Data Available...
                </div>
            }
          </List>
        </div>
        <div className={classes.flipContainerBack}>
          <List>

          </List>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicInteractiveTable);
