import React, {Component, Children} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/ActionStyle';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment-timezone';
import ClickOutside from 'react-click-outside';

import { DaypickerContext } from './Contexts';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';

import {
    SELECT_CHOICES
} from './placeholder-data/FixedDataFromAPI';
// } from './placeholder-data/TablePlaceholderData';

import { Calendar } from 'react-date-range';

class SelectorDaypicker extends Component {
    constructor(props) {
        super(props);

        const maxDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

        this.state = {
            open: false,
            anchorEl: null,
            maxDate,
            day: maxDate
        }
    }

    handleToggle = event => {
        const { currentTarget: anchorEl } = event || {};

        this.setState(prevState => ({
            open: !prevState.open,
            anchorEl
        }));
    };

    handleSelect = date => {
        this.setState({
            day: moment(date).format('YYYY-MM-DD')
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if(prevState.open !== this.state.open) {
            const { sendData, selector } = this.props;

            sendData({[selector.id]: this.state.day});
        }
    }

    componentDidMount() {
        const { sendData, selector, selectedRows, actionType } = this.props;

        const day = (actionType === 'edit' && selectedRows[0][selector.id]) ||
            (actionType === 'merge' && selector.id === 'start_time' && moment.min(selectedRows.map(row => moment.tz(row[selector.id], 'America/Fortaleza')))) ||
            (actionType === 'merge' && selector.id === 'end_time' && moment.max(selectedRows.map(row => moment.tz(row[selector.id], 'America/Fortaleza')))) ||
            this.state.day;

        const dayToState = day.constructor !== String ? day.format('YYYY-MM-DD HH:mm:ss') : day;

        sendData({[selector.id]: dayToState});

        this.setState({
            day: dayToState
        });
    }

    render() {
        const { classes, selector, children, actionType } = this.props;
        const { open, maxDate, placement, day, anchorEl } = this.state;

        // console.log('ON DATE PICKER SELECTOR', day)

        return (
            <React.Fragment>
                <DaypickerContext.Provider value={{ 
                    handleToggle: this.handleToggle, 
                    day, 
                    disabled: actionType === 'edit' ||
                        actionType === 'merge'
                }} >
                    {children}
                </DaypickerContext.Provider>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement || 'bottom'}
                    transition
                    className={classes.popperContainer}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <ClickOutside onClickOutside={this.handleToggle} >
                                <Paper>
                                    <Calendar
                                        date={day}
                                        onChange={this.handleSelect}
                                    />
                                </Paper>
                            </ClickOutside>
                        </Fade>   
                    )}
                </Popper>  
            </React.Fragment>
        );
    }
};

export default withStyles(styles)(SelectorDaypicker);