import React from 'react'

import StudioBodyComponent from './components/StudioBodyComponent/StudioBodyComponent'
import StudioFilterComponent from './components/StudioFilterComponent/StudioFilterComponent'
import { CUSTOM_AXIS_WTG, CUSTOM_AXIS_VARIABLE, CUSTOM_AXIS_DATETIME } from '../../../../constants/custom-axis-types';

const STUDIO_HEADER_HEIGHT = 80

class StudioPage extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      data: [],
      selectVariables: [],
      dataMenu: [],
      customAxisX: [
        {
          type: CUSTOM_AXIS_WTG,
          variableId: false,
          opposite: true,
          labels: {
            format: '{value}',
            style: {
              color: '#37D67A'
            }
          },
          title: {
            text: 'Devices',
            style: {
              color: '#37D67A'
            }
          }
        }
      ],
      customAxisY: [
        {
          type: CUSTOM_AXIS_VARIABLE,
          variableId: 88010,
          opposite: false,
          labels: {
            format: '{value}°C',
            style: {
              color: '#BA68C8'
            }
          },
          title: {
            text: 'Temperature',
            style: {
              color: '#BA68C8'
            }
          }
        }
      ]
    }
  }

  setData = (data, callback = null) => {
    this.setState(
      data,
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  setParam = (param, value, callback = null) => {
    this.setState({
      [param]: value
    }, () => {
      if (callback) {
        callback()
      }
    })
  }

  render () {
    return (
      <div
        style={{
          height: "100%",
          width: "100%"
        }}
      >
        <div
          style={{
            height: STUDIO_HEADER_HEIGHT,
            width: '100%'
          }}
        >
          <StudioFilterComponent
            setParam={this.setParam.bind(this)}
            setData={this.setData.bind(this)}
          />
        </div>
        <div
          style={{
            height: `calc(100% - ${STUDIO_HEADER_HEIGHT}px)`,
            width: '100%'
          }}
        >
          <StudioBodyComponent
            {...this.state}
            setParam={this.setParam.bind(this)}
            setData={this.setData.bind(this)}
          />
        </div>
      </div>
    )
  }
}

export default StudioPage
