import React, {Component} from 'react'



class PortfolioContainer extends Component {
    
    render () {

        const { children } = this.props;

        return (
            <div>
                {children}
            </div>
        )
  }
}

export default PortfolioContainer
