import axios from 'axios'
import { URL_NODE_API } from '../constants/url-constant'
import { Auth } from 'aws-amplify'
import * as R from 'ramda'
import * as moment from 'moment'

const format_diff = secs => {
  secs = Math.round(secs / 1000);
  let hours = Math.floor(secs / (60 * 60));
  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);
  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let str_hours = hours < 10 ? `0${hours}` : String(hours);
  let str_minutes = minutes < 10 ? `0${minutes}` : String(minutes);
  let str_seconds = seconds < 10 ? `0${seconds}` : String(seconds);

  return `${str_hours}:${str_minutes}:${str_seconds}`;
};

function verifyDataIsNullOrNumber(value) {
  if (value != null) {
    if (Number.isNaN(value)) {
      return value
    }

    if (String(value).indexOf('.') >= 0) {
      return value.toFixed(2)
    } else {
      return value
    }
  }

  return '-'
}

function getProcessedDatagenericObject(data, widget, dataValuesExcel = []) {

  const table_column_formatter = columns => {
    let first = columns.splice(0, 1)[0];
    columns.splice(columns.length - 1, 0, first);
    return columns;
  };



  const cross_headers = [
    'Power (MW Avg)',
    'Energy (MWh)',
    'KPI Expected',
    'KPI Measured',
    'KPI Delta'
  ];


  const serie_data = (['EXPECTED'].concat(data.kpi).concat(['MEASURED'])).map(column => {
    if (column == 'EXPECTED') {
      return ({
        name: 'Expected',
        y: data.expected_power,
        key: 'expected',
        unit: data.unit
      });
    } else if (column == 'MEASURED') {
      return ({
        name: 'Measured',
        key: 'measured',
        y: data.measured_power,
        isSum: true,
        unit: data.unit
      });
    } else {
      if (column && column.name) {
        return ({
          name: column.name,
          y: column.delta_power,
          key: column.name.toLowerCase().replace(/ /g, '_'),
          real: column.measured,
          goal: column.expected,
          unit: column.unit,
          description: column.name
        })
      } else {
        return ({
          name: '',
          y: '',
          key: '',
          color: '',
          real: '',
          goal: '',
          unit: '',
          description: ''
        });
      }
    }
  });


  const tableData = {
    'headers': table_column_formatter(serie_data.map(bar => bar.name)),
    'rows': data.kpi
      ? [
        data.kpi.map(kpi => kpi.delta_power ? kpi.delta_power.toFixed(2) : 'No Data')
          .concat([
            data.expected_power ? data.expected_power.toFixed(2) : 'No Data',
            data.measured_power ? data.measured_power.toFixed(2) : 'No Data'
          ]),
        data.kpi.map(kpi => kpi.delta_energy ? kpi.delta_energy.toFixed(2) : 'No Data')
          .concat([
            data.expected_energy ? data.expected_energy.toFixed(2) : 'No Data',
            data.measured_energy ? data.measured_energy.toFixed(2) : 'No Data'
          ]),
        data.kpi.map(kpi => kpi.expected ? kpi.expected.toFixed(2) : 'No Data')
          .concat(['-', '-']),
        data.kpi.map(kpi => kpi.measured ? kpi.measured.toFixed(2) : 'No Data')
          .concat(['-', '-']),
        data.kpi.map(kpi => kpi.measured && kpi.expected ? (kpi.measured - kpi.expected).toFixed(2) : 'No Data')
          .concat(['-', '-'])
      ]
      : []

  };

  dataValuesExcel = tableData.rows.map((elm, keyElm) => {
    elm.unshift(cross_headers[keyElm])
    return elm;
  })

  return dataValuesExcel.length > 0 ?
    { 'data': dataValuesExcel, 'names': tableData.headers } :
    getProcessedDataGauge(data, widget)


}

function getProcessedDataCollumRange(dataWidget, widget, dataValuesExcel) {

  let series_classes = dataWidget.series && !dataWidget.series[0].hasOwnProperty('z') ? [...new Set(dataWidget.series.map(alarm => alarm.text))] : [];

  if (!series_classes[0]) {
    series_classes = dataWidget.series ? [...new Set(dataWidget.series.map(alarm => alarm.color))] : [];
    series_classes = series_classes[0] ? series_classes[0] : [];
  }

  let series_values = series_classes.map((class_name, index) => (
    {
      name: class_name,
      color: class_name == 'green' || class_name == 'Information' || class_name == 'Model OK' ? '#10ac84' : class_name == 'yellow' || class_name == 'Warning' || class_name == 'Attention' ? '#ff9f43' : class_name == 'red' || class_name == 'Stop' || class_name == 'Critical' ? '#ee5253' : class_name == 'orange' || class_name == 'Power Limitation' ? '#ff9f43' : class_name,
      data: dataWidget.series.map(ocurrence => (
        ocurrence.text == class_name ?
          {
            x: ocurrence.x,
            type_data: class_name == 'green' ? 'Model OK' : class_name == 'orange' ? 'Warning' : class_name == 'red' ? 'Critical' : class_name,
            low: Date.parse(moment.utc(ocurrence.y_start).tz("America/Fortaleza")),
            high: Date.parse(moment.utc(ocurrence.y_end).tz("America/Fortaleza")),
            type: 'datetime',
            tooltip: ocurrence.tooltip ?
              ocurrence.tooltip.constructor == Array ?
                ocurrence.tooltip.map(tooltip => (
                  `<b>${tooltip.key}</b>: ${tooltip.value}<br>`
                )).join('\n')
                :
                ocurrence.tooltip
              :
              `<h3>${dataWidget.x_axis ? dataWidget.x_axis[ocurrence.x] : dataWidget.xAxis[ocurrence.x]}</h3>
                        <h4>${class_name}</h4>
                        <b>Start Time</b>: ${moment(ocurrence.y_start).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')} America/Fortaleza<br>
                        <b>End Time</b>: ${moment(ocurrence.y_end).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')} America/Fortaleza`,
            pointPadding: 0,
            color: ocurrence.color == 'green' || ocurrence.color == 'Information' ? '#10ac84' : ocurrence.color == 'yellow' || ocurrence.color == 'Warning' ? '#fffa65' : ocurrence.color == 'red' || ocurrence.color == 'Stop' ? '#ee5253' : ocurrence.color == 'orange' || ocurrence.color == 'Power Limitation' ? '#ff9f43' : ocurrence.color
          }
          :
          null
      )).filter(result => result),
    }
  ));

  let tableData = {};

  try {
    tableData = {
      'headers': dataWidget.series[0].tooltip.map(t => t.key),
      'cross_headers': null,
      'rows': dataWidget.series.map(serie => {
        return serie.tooltip.map(t => {
          const { key } = t;
          if (key === 'Duration') {
            return format_diff(Date.parse(moment.utc(serie.y_end).tz("America/Fortaleza")) - Date.parse(moment.utc(serie.y_start).tz("America/Fortaleza")))
          }
          if (key === 'Start Time') {
            return moment(Date.parse(moment.utc(serie.y_start).tz("America/Fortaleza"))).format('YYYY-MM-DD HH:mm:ss')
          }
          if (key === 'End Time') {
            moment(Date.parse(moment.utc(serie.y_end).tz("America/Fortaleza"))).format('YYYY-MM-DD HH:mm:ss')
          }
          return t.value
        })
      }),
    };
  } catch {
    tableData = {
      'headers': ['Event', 'Class', 'Start Time', 'End Time', 'Duration'],
      'cross_headers': null,
      'rows': [].concat.apply([], series_values.map(value => (
        value.data.map(data => (

          new Array(
            dataWidget.x_axis ? dataWidget.x_axis[data.x] : dataWidget.xAxis[data.x],
            data.type_data,
            moment(data.low).format('YYYY-MM-DD HH:mm:ss'),
            moment(data.high).format('YYYY-MM-DD HH:mm:ss'),
            format_diff(data.high - data.low)
          )
        ))
      ))),
    };
  }

  dataValuesExcel = tableData.rows;

  return dataValuesExcel.length > 0 && dataValuesExcel[0][0] ?
    { 'data': dataValuesExcel, 'names': tableData.headers } :
    getProcessedDataScatter(dataWidget, widget, dataValuesExcel)


}

async function getProcessedDataBySp(dataWidget, widget, dataValuesExcel = []) {

  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken
  let headers = [];

  if (widget.api) {

    const URL = URL_NODE_API + '/management/new-data'
    const params = {
      procedure_name: widget.api,
      time_range: [widget.filter.data_range.startDate, widget.filter.data_range.endDate],
      device_list: widget.filter.devices,
      subpark_list: widget.filter.subparks,
      timezone: 'America/Fortaleza',
    }


    const { data } = await axios.post(URL, params, {
      timeout: 1500000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    })

    dataValuesExcel = data.data.map((row) => {
      headers = Object.keys(row)
      return Object.values(row);
    })
  }

  return dataValuesExcel.length > 0 ? {
    data: dataValuesExcel,
    names: headers
  }
    :
    getProcessedPieChart(dataWidget);
}

const getProcessedPieChart = dataWidget => {

  const names = dataWidget.map && dataWidget.map(({ name }) => name);
  const data = names && [dataWidget.map(({ value }) => value)];

  const isValid = names && names.every(name => name);

  return isValid ? { names, data } : getProcessedMultipleSeries(dataWidget);
}


const getProcessedMultipleSeries = dataWidget => {

  let names = [];
  let data = [];

  if (dataWidget && dataWidget[0] && dataWidget[0].x_name && dataWidget[0].x_axis) {
    const series = R.flatten(dataWidget.map(d => d.series));

    names.push(dataWidget[0].x_name);
    names = names.concat(series.map(serie => serie.unit ? `${serie.name} (${serie.unit})` : serie.name));

    const dataWithoutTime = series[0].values.map((value, index) => {
      return series.map(serie => serie.values[index]);
    });

    data = dataWidget[0].x_axis.map((axis, index) => {
      return [axis].concat(dataWithoutTime[index]);
    });
  }

  return names.length > 0 ? { names, data } : getProcessedHeatmap(dataWidget);
};

const getProcessedHeatmap = dataWidget => {
  const isOperationalSummary = dataWidget.series.every(serie => serie.z === null);

  const data = dataWidget.y_axis.map((axis, index) => (
    [axis].concat(dataWidget.series.filter(serie => serie.y === index).map(serie => isOperationalSummary ?
      serie.tooltip_name || '-'
      :
      serie.z || serie.z === 0 ? serie.z : '-'))
  ));

  return { names: [''].concat(dataWidget.x_axis), data };
};

const getDataPieChartDCKPI = (dataWidget, widget, dataValuesExcel = []) => {
  const responsibilities = dataWidget.filter(row => row.name !== 'Availability');
  const availability = dataWidget.filter(row => row.name === 'Availability');
  const ava = availability.length > 0 && availability[0].value;

  const rows = responsibilities.map(row => ava ? [ava, row.name, row.value] : [row.name, row.value]);
  return rows && rows.length && ava ?
    { 'data': rows, 'names': ['Availability (%)', 'Responsible', 'Responsibility (%)'] } :
    getProcessedDataBySp(dataWidget, widget);
};


function getProcessedDataBoxPlot(dataWidget, widget, dataValuesExcel = []) {

  const rows = dataWidget.series && dataWidget.series[0] && dataWidget.series[0].data && dataWidget.series[0].data.map((data, serieIndex) => {

    return new Array(
      dataWidget.xAxis && dataWidget.xAxis.categories[serieIndex],
      data.low,
      data.q1,
      data.median,
      data.q3,
      data.high
    )

  })

  return rows && rows.length && rows[0][0] ?
    { 'data': rows, 'names': ['Low', 'Q1', 'Median', 'Q3', 'High'] } :
    getProcessedDataBySp(dataWidget, widget)
}

function getProcessedDataScoreTimeline(data, widget, dataValuesExcel = []) {
  let rows = {}
  let keys = []

  data[1] && data[1].series && data[1].series.map((serie) => {

    keys.push(serie.name);

    serie.data && serie.data.map((serieData) => {

      if (!rows[serieData[0]]) {
        rows[serieData[0]] = {};
      }

      rows[serieData[0]]['Date'] = moment(serieData[0]).format('YYYY-MM-DD HH:mm:ss');
      rows[serieData[0]][serie.name] = serieData[1];

    });

  });

  dataValuesExcel = R.values(rows).map((serieData) => {

    return R.values(serieData);

  });

  return dataValuesExcel.length > 0 ?
    { 'data': dataValuesExcel, 'names': keys } :
    getProcessedDataBoxPlot(data, widget)
}



function getProcessedDataScatter(data, widget, dataValuesExcel = []) {
  dataValuesExcel = data.scatter ? data.scatter.map((line) => {

    return line.wtg ? [line.wtg, line.x, line.y] : [line.datetime, line.class, line.x, line.y]
  }) : []



  return dataValuesExcel.length > 0 ?
    { 'data': dataValuesExcel, 'names': data.features ? data.features : ['Date', 'Class', 'Active Power (avg) KW', 'Temp. shaft bearing DegC'] } :
    getProcessedDataScoreTimeline(data, widget)
}



function getProcessedDataTable(data, widget, dataValuesExcel = []) {

  dataValuesExcel = data.rows ? data.rows.map(function (table) {
    return table.values.slice(0, table.values.length - 1)
  }) : [];

  return dataValuesExcel.length > 0 ?
    { 'data': dataValuesExcel, 'names': data.columns } :
    getProcessedDataCollumRange(data, widget, dataValuesExcel)
}


function getProcessedDataGauge(data, widget, dataValuesExcel = []) {

  const references = data.series
    ? data.series.filter(series => series.type == 'reference')
    : []

  const bars = data.series
    ? data.series.filter(series => series.type == 'bar').sort((s1, s2) => s2.value - s1.value)
    : []


  const tableDataExcel = {
    'headers': ['Value'],
    'cross_headers': references.concat(bars).map(series => series.name),
    'rows': references.concat(bars).map(series => new Array(
      `${series.value ? series.value : 'No Data'} ${data.unit}`
    ))
  };


  dataValuesExcel = tableDataExcel.rows.map((elm, keyElm) => {
    elm.unshift(tableDataExcel.cross_headers[keyElm])
    return elm;
  })



  return dataValuesExcel.length > 0 ?
    { 'data': dataValuesExcel, 'names': tableDataExcel.headers } :
    getProcessedDataTable(data, widget)



}

function getProcessedDataKeyMetrics(managementKpiData, widget, dataValuesExcel = []) {

  if (Array.isArray(managementKpiData)) {
    const data = managementKpiData.map((elm) => {
      const retultValue = elm.unit === '%' ? R.assoc('value', (elm.value), elm) : elm
      const retultDeltaValue = elm.delta_value_unit === '%' ? R.assoc('delta_value', (elm.delta_value), retultValue) : retultValue

      return retultDeltaValue
    })

    const dataTable = data.map((item) => ({
      '': item.indicator,
      'Actual': {
        value: verifyDataIsNullOrNumber(item.value),
        unit: item.value ? item.unit : ''
      },
      'Expected': {
        value: verifyDataIsNullOrNumber(item.expected),
        unit: item.expected ? item.unit : ''
      },
      'Diff': {
        value: verifyDataIsNullOrNumber(item.delta_value),
        unit: item.delta_value ? item.delta_value_unit : ''
      }
    }))

    const keysTable = R.keys(R.head(dataTable))
    dataValuesExcel = dataTable.map((elm) => {
      return R.values(elm).map((elm) => {
        return elm ? typeof (elm.value) != "undefined" ? elm.value : elm : null
      })
    })

    if (dataValuesExcel[0][0] && dataValuesExcel.length > 0) {
      return { 'data': dataValuesExcel, 'names': keysTable };
    }
  }

  return getProcessedDatagenericObject(managementKpiData, widget);
}

function getProcessedDataBar(data_table, widget, dataValuesExcel = []) {

  if (!dataValuesExcel.lenght > 0) {

    dataValuesExcel = data_table && data_table.x_axis && data_table.features && Array.isArray(data_table.x_axis) ?
      data_table.x_axis.map((axis, indexAxis) => {



        return R.flatten([
          axis,
          data_table.series ? data_table.series.map(serie => {
            if (serie.type != 'pareto' && serie.values && serie.values[indexAxis] != null) {
              let value = serie.values[indexAxis]

              if (value.constructor == Number) {
                if (Number.isInteger(value)) {
                  return `${serie.values[indexAxis]}`
                } else {
                  return `${serie.values[indexAxis]}`
                }
              }
            }
            else {
              return widget.nullEqualsTo ? `${widget.nullEqualsTo} ${serie.unit}` : 'No Data'
            }
          }) : []
        ])
      }) : [];


    return dataValuesExcel.length > 0 ?
      { 'data': dataValuesExcel, 'names': data_table.features ? data_table.features : ['Class', 'Start Time', 'End Time', 'Duration'] } :
      getProcessedDataKeyMetrics(data_table, widget)

  }


  return { 'data': dataValuesExcel, 'names': [] }

}


export const processDataToExport = async (widget, format) => {
  try {
    const data_table = widget.data;
    if (widget.isPieChartKPI) {
      return await getDataPieChartDCKPI(data_table, widget);
    } else {
      return await getProcessedDataBar(data_table, widget);
    }

  } catch (error) {
    console.error('dynamo-client-meta-provider', error)
    return { data: [] }
  }
}