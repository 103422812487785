import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import moment from "moment-timezone";

import { getMovConsProvider } from "../../../../../../../../providers/asset-provider";

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

class MovementConsumable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    getMovConsProvider()
      .then(data => {
        this.setState({
          data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        console.error("getMovConsProvider", error);
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress />
          <span
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </span>
        </div>
      );
    }

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          overflow: "auto"
        }}
      >
        <TableStyled>
          <thead>
            <th>Date</th>
            <th>Storage</th>
            <th>Action name</th>
            <th>Quantity</th>
            <th>Product name</th>
            <th>Part number</th>
            <th>Serial number</th>
            <th>Manufacturer</th>
            <th>Supplier</th>
            <th>User</th>
          </thead>
          <tbody>
            {this.state.data.map((item, indexItem) => {
              return (
                <tr key={indexItem}>
                  <td>
                    {moment(item.log.log_time).format("YYYY-MM-DD HH:mm")}
                  </td>
                  <td>{item.storage.name}</td>
                  <td>{item.log.procedure}</td>
                  <td>
                    {`${item.quantity}${item.consumable.consumable_model.unit}`}
                  </td>
                  <td>{item.consumable.consumable_model.name}</td>
                  <td>{item.consumable.consumable_model.part_number}</td>
                  <td>{item.consumable.serial_number}</td>
                  <td>{item.consumable.consumable_model.manufacturer}</td>
                  <td>{item.consumable.supplier}</td>
                  <td>{item.log.user_hash}</td>
                </tr>
              );
            })}
          </tbody>
        </TableStyled>
      </div>
    );
  }
}

export default MovementConsumable;
