import React, {Component} from 'react'
import {withStyles, Paper} from '@material-ui/core'
import { WIDGET_HEADER_HEIGHT } from '../../constants/widget-constant'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment-timezone'
import { DateRange } from 'react-date-range'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { FILTER_TYPE_DATE_RANGE } from '../../constants/filter-types';

const styles = theme => ({
  paperElement: {
    border: `1px solid ${grey['200']}`,
    borderRadius: 0,
  },
  paperHeader: {
    paddingLeft: '10px',
    minHeight: `${WIDGET_HEADER_HEIGHT}px`,
    borderBottom: `1px solid ${grey['200']}`,
    display: 'flex',
    alignItems: 'center'
  },
})

class FilterDateRanger extends Component {
  constructor (props) {
    super (props)

    this.state = {
      dateRangeKey: 'filterDateRange'
    }
  }

  handleSelect = (range) => {
    this.props.changeValueFilter(FILTER_TYPE_DATE_RANGE, range)
  }

  render () {
    const {classes, management, widget} = this.props

    const startDate = widget.filter.data_range.startDate
      ? moment(widget.filter.data_range.startDate, 'YYYY-MM-DD')
      : management.filter.data_range.startDate
        ? moment(management.filter.data_range.startDate, 'YYYY-MM-DD')
        : moment().subtract(3, 'days')

    const endDate = widget.filter.data_range.endDate
      ? moment(widget.filter.data_range.endDate, 'YYYY-MM-DD')
      : management.filter.data_range.endDate
        ? moment(management.filter.data_range.endDate, 'YYYY-MM-DD')
        : moment().subtract(3, 'days')

    const selectionRange = {
			startDate: startDate.toDate(),
			endDate: endDate.toDate(),
			key: this.state.dateRangeKey,
		}

    return (
      <Paper className={classes.paperElement} elevation={0}>
        <DateRange
          onChange={this.handleSelect.bind(this)}
          moveRangeOnFirstSelection={false}
          ranges={[selectionRange]}
          className={'PreviewArea'}
        />
      </Paper>
    )
  }
}

export default withStyles(styles)(FilterDateRanger)
