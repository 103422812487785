import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Icon,
    Tooltip,
} from '@material-ui/core';
import styles from './styles/EventsTableStyle';
import {
    ROWS_PER_PAGE,
    TEST_DATA,
    TEST_ACTIONS
} from './placeholder-data/FixedDataFromAPI';

import * as R from 'ramda'
import EventsTableToolbar from './EventsTableToolbar';

import { PaginatorTable } from '../../../../../new-components/Table';

import FromDeviceIcon from '../../../../../assets/icons/from-device.svg';
import FromPreApprovedIcon from '../../../../../assets/icons/from-pre-approved.svg';

import TimeIntervalSlider from './TimeIntervalSlider';
import SliderTooltipLabel from './SliderTooltipLabel';
import './styles/ExtraStyles.css';
import { withTranslation } from 'react-i18next';

const stylesMap = {
    checked: {
        minWidth: '20px',
        maxWidth: '40px',
    },
    source: {
        minWidth: '20px',
        maxWidth: '40px',
    },
    duration: {
        minWidth: '160px',
        maxWidth: '160px'
    }
};

const parseFloatHour = hour => {
    const totalSeconds = Number(hour).toFixed(5) * 60 * 60;
    const hh = Math.floor(totalSeconds / 3600);
    const mm = Math.floor((totalSeconds - (hh * 3600)) / 60);
    let ss = totalSeconds - (hh * 3600) - (mm * 60);
    ss = String(Math.round(ss * 100) / 100).split('.')[0];

    return `${hh < 10 ? `0${hh}` : hh}:${mm < 10 ? `0${mm}` : mm}:${ss < 10 ? `0${ss}` : ss}`;
};

const DurationSliderLabel = SliderTooltipLabel(val => parseFloatHour(val));
const TimeToDateSliderLabel = SliderTooltipLabel(val => new Date(val).toISOString().split('.')[0].replace('T', ' '));

class EventsTableFull extends Component {
    constructor(props) {
        super(props);

        const { t } = props;

        this.state = {
            currPage: 0,
            rowsPerPage: ROWS_PER_PAGE,
            selectedAmt: 0,
            rowsInfo: [],
            ready: false,
            filterList: {
                checked: [t('Unapproved')]
            },
            sortState: undefined,
            durationSlider: [50, 80]
        };
    }

    handleChangePage = (event, page) => {
        this.setState({ currPage: page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ currPage: 0, rowsPerPage: event.target.value });
    };

    toggleRow = row => event => {
        this.setState(prevState => ({
            rowsInfo: prevState.rowsInfo.map(rowInfo => (
                {
                    ...rowInfo,
                    isSelected: row.status_cut_id === rowInfo.cutId ? !rowInfo.isSelected : rowInfo.isSelected
                }
            )),
            filterList: null
        }));
    };

    extractInfoFromRows = (actions) => {
        const { records } = this.props;

        const selectedRecords = records.filter(record => record.selected);

        const ids = R.uniq(selectedRecords.map(record => record.deviceId));

        const approveds = selectedRecords.map(record => record.checked).filter(checked => checked == true)
        const hasApproved = approveds.length > 0;

        const uniqId = ids.length == 1;

        const selectedAmt = records.length > 0 && records.map(record => (
            record.selected ? 1 : 0
        )).reduce((acc, curr) => acc + curr);

        const adjascency = selectedAmt > 1 &&
            records && records.map((record, index) => (
                record.selected ? index : null
            )).filter(record => (
                record != null
            )).reduce((acc, curr) => (
                curr - acc === 1 ? curr : 0
            ));




        const selectedAdjascent = adjascency > 0;
        const selectedSparse = !selectedAdjascent;


        const currActions = actions.filter(action => (
            (action.id === "unapprove" && selectedAmt >= 1 && action.selection === 'multiple' && selectedRecords.every(record => record.checked)) ||
            (action.id === "approve" && selectedAmt >= 1 && action.selection === 'multiple' && selectedRecords.every(record => !record.checked)) ||
            (selectedAmt == 0 && action.selection === 'none') ||
            (action.id !== "split" && selectedAmt == 1 && action.selection === 'single' && !hasApproved) ||
            (selectedAmt == 1 && action.selection === 'single' && !hasApproved) ||
            (selectedAmt == 1 && action.selection === 'single-user' && selectedRecords[0].source === 'user' && !hasApproved) ||
            (!hasApproved && uniqId && selectedAmt > 1 && selectedSparse === false && action.selection === 'group-adjascent') ||
            (selectedAmt > 1 && selectedSparse === true && action.selection === 'group-sparse' && !hasApproved)
        ));

        return {
            currActions,
            selectedAdjascent,
            selectedSparse,
            selectedAmt,
            selectedRows: selectedRecords
        };
    };

    componentDidMount() {
        const { data = TEST_DATA[this.props.tableIndex], actions = TEST_ACTIONS } = this.props;

        const rowsInfo = data.rows.map(row => ({
            cutId: row.status_cut_id,
            isSelected: row.isSelected || false
        }));

        this.setState({
            data,
            actions,
            rowsInfo
        });
    }

    componentDidUpdate() {
        const { selectedAmt, ready, data: prevData } = this.state;
        const { data = TEST_DATA[this.props.tableIndex], actions = TEST_ACTIONS } = this.props;

        const extractedRowsInfo = this.extractInfoFromRows(actions);

        if (!ready || JSON.stringify(data) !== JSON.stringify(prevData)) {
            const rowsInfo = data.rows.map(row => ({
                cutId: row.status_cut_id,
                isSelected: false
            }));

            this.setState({
                data,
                actions,
                ready: true,
                rowsInfo,
                ...extractedRowsInfo,
                selectedRows: [],
                selectedAmt: 0,
                selectedAdjascent: false,
                selectedSparse: false,
                currActions: actions.filter(action => action.selection === 'none')
            });
        } else if (extractedRowsInfo.selectedAmt !== selectedAmt) {
            this.setState({
                ...extractedRowsInfo
            });
        }
    }



    render() {
        const { records, onRowClick, infoPerDevices, title, t } = this.props;
        const { filterList, sortState } = this.state;

        const namesMap = {
            checked: t('Approved'),
            source: 'Source',
            deviceName: 'Device',
            start_time: 'Start Time',
            end_time: 'End Time',
            duration: 'Duration (hours)',
            alarm: 'Assoc Alarm',
            system: 'System',
            classification: 'Classification',
            responsibility: 'Responsibility',
            comment: 'Comments',
            user_name: 'User'

        };


        return (
            <PaginatorTable
                filter={true}
                records={records}
                displayHeaders={Object.keys(namesMap)}
                headFormatter={head => namesMap[head]}
                hideCheckboxes={false}
                hideToolbar={false}
                title={title}
                actionsBar={
                    (selectedRows, displayData, setSelectedRows) => (
                        <EventsTableToolbar
                            {...this.state}
                            title={t('Event Actions')}
                            infoPerDevices={infoPerDevices}
                        />
                    )
                }
                fieldFormatter={(index, head, row) => {
                    switch (head) {
                        case 'source':
                            return (
                                row.source !== 'other' &&
                                <Tooltip
                                    title={
                                        row.source === 'scada' ?
                                            t('From Device')
                                            : row.source === 'user' ?
                                                t('User Defined')
                                                :
                                                row.source === 'auto' ?
                                                    t('Pre Approved')
                                                    :
                                                    null
                                    }
                                >
                                    {
                                        row.source === 'scada' ?
                                            <img
                                                src={FromDeviceIcon}
                                                style={{
                                                    transform: 'translateY(3px)',
                                                    width: '26px'
                                                }}
                                            />
                                            :
                                            row.source === 'auto' ?
                                                <img
                                                    src={FromPreApprovedIcon}
                                                    style={{
                                                        transform: 'translateY(3px)',
                                                        width: '26px'
                                                    }}
                                                />
                                                :
                                                <Icon
                                                    style={{
                                                        fontSize: '1.3em',
                                                        transform: 'translateX(4px) translateY(3px)'
                                                    }}
                                                >
                                                    {
                                                        (row.source === 'user' && 'person')
                                                    }
                                                </Icon>
                                    }
                                </Tooltip>
                            );
                        case 'checked':
                            return row[head] ? 'Yes' : 'No';
                        case 'duration':
                            const duration = parseFloatHour(Number(row[head]));
                            return duration;
                        default:
                            return row[head];
                    }
                }}
                rowClickAction={onRowClick}
                resizeFactor={1}
                rowsPerPageOptions={[
                    10,
                    20,
                    30,
                    40,
                    50,
                    100,
                    150,
                    200
                ]}
                onDownload={(buildHead, buildBody, columns, data) => {
                    const columnsValues = columns.map(column => column.name);

                    const newColumns = columns.map(column => ({
                        ...column,
                        name: column.label
                    }));

                    const newData = data.map(row => {
                        const changedData = row.data.map((cell, colIndex) => {
                            switch (columnsValues[colIndex]) {
                                case 'source':
                                    return (
                                        cell === 'scada' ?
                                            t('From Device')
                                            : cell === 'user' ?
                                                t('User Defined')
                                                : cell === 'auto' ?
                                                    t('Pre Approved')
                                                    :
                                                    t('No Source')
                                    );
                                default:
                                    return cell;
                            }
                        });

                        return {
                            ...row,
                            data: changedData
                        }
                    });

                    const result = `${buildHead(newColumns)}${buildBody(newData)}`;
                    return result;
                }}
                columnStyleFormatter={head => stylesMap[head]}
                filterFormatter={(index, head, row) => {
                    switch (head) {
                        case 'source':
                            return (
                                row.source === 'scada' ?
                                    t('From Device')
                                    : row.source === 'user' ?
                                        t('User Defined')
                                        : row.source === 'auto' ?
                                            t('Pre Approved')
                                            :
                                            t('No Source')
                            );
                        case 'checked':
                            return t(row[head] ? 'Approved' : 'Unapproved');
                        case 'comment':
                            return undefined;
                        default:
                            return row[head];
                    }
                }}
                filterLogic={head => (val, filters) => {
                    if (filters.length === 0) {
                        return false;
                    }

                    switch (head) {
                        case 'source':
                            return !(
                                (val === 'scada' && filters.indexOf(t('From Device')) >= 0) ||
                                (val === 'user' && filters.indexOf(t('User Defined')) >= 0) ||
                                (val === 'auto' && filters.indexOf(t('Pre Approved')) >= 0) ||
                                (val === 'other' && filters.indexOf(t('No Source')) >= 0)
                            );
                        case 'checked':
                            return !(
                                (val && filters.indexOf(t('Approved')) >= 0) ||
                                (!val && filters.indexOf(t('Unapproved')) >= 0)
                            );
                        case 'duration':
                            const duration = Number(val);
                            const dLower = filters.length > 0 ? filters[0] : 0;
                            const dUpper = filters.length > 1 ? filters[1] : 999999999999999999999999999999999999;
                            return !(duration >= dLower && duration <= dUpper);
                        case 'start_time':
                            const sVal = new Date(val).getTime();
                            const sLower = filters.length > 0 ? filters[0] : 0;
                            const sUpper = filters.length > 1 ? filters[1] : 999999999999999999999999999999999999;
                            return !(sVal >= sLower && sVal <= sUpper);
                        case 'end_time':
                            const eVal = new Date(val).getTime();
                            const eLower = filters.length > 0 ? filters[0] : 0;
                            const eUpper = filters.length > 1 ? filters[1] : 999999999999999999999999999999999999;
                            return !(eVal >= eLower && eVal <= eUpper);
                        default:
                            return !(filters.indexOf(val) >= 0);
                    }
                }}
                useCustomDisplay={['duration', 'start_time', 'end_time']}
                filterDisplay={head => (filterList, onChange, index, column) => {
                    switch (head) {
                        case 'duration':
                            const durationsValues = records.filter(row => (
                                row.duration != null
                            )).map(row => row.duration);
                            const durationMax = Math.max(...durationsValues);
                            const durationMin = Math.min(...durationsValues);

                            return (
                                <TimeIntervalSlider
                                    title={t('Duration (in range)')}
                                    submit={value => onChange(value, index, column)}
                                    LabelComponent={DurationSliderLabel}
                                    defaultValue={column.filterList || [durationMin, durationMax]}
                                    sliderProps={{
                                        max: durationMax,
                                        min: durationMin,
                                        step: 0.01
                                    }}
                                />
                            );
                        case 'start_time':
                            const startTimeValues = records.filter(row => (
                                row.start_time != null
                            )).map(row => new Date(row.start_time).getTime());
                            const startTimeMax = Math.max(...startTimeValues);
                            const startTimeMin = Math.min(...startTimeValues);

                            return (
                                <TimeIntervalSlider
                                    title={t('Start Time (in range)')}
                                    submit={value => onChange(value, index, column)}
                                    LabelComponent={TimeToDateSliderLabel}
                                    defaultValue={column.filterList || [startTimeMin, startTimeMax]}
                                    sliderProps={{
                                        max: startTimeMax,
                                        min: startTimeMin,
                                        step: 0.01
                                    }}
                                />
                            );
                        case 'end_time':
                            const endTimeValues = records.filter(row => (
                                row.end_time != null
                            )).map(row => new Date(row.end_time).getTime());
                            const endTimeMax = Math.max(...endTimeValues);
                            const endTimeMin = Math.min(...endTimeValues);

                            return (
                                <TimeIntervalSlider
                                    title={t('End Time (in range)')}
                                    submit={value => onChange(value, index, column)}
                                    LabelComponent={TimeToDateSliderLabel}
                                    defaultValue={column.filterList || [endTimeMin, endTimeMax]}
                                    sliderProps={{
                                        max: endTimeMax,
                                        min: endTimeMin,
                                        step: 0.01
                                    }}
                                />
                            );
                        default:
                            return undefined;
                    }
                }}
                initialFilterList={filterList}
                sortState={sortState}
                onFilterChange={(changedColumn, filterListState) => {
                    if (changedColumn) {
                        const keyIndex = Object.keys(namesMap).indexOf(changedColumn);
                        const newFilterList = {
                            ...filterList,
                            [changedColumn]: filterListState &&
                                filterListState[keyIndex] &&
                                filterListState[keyIndex].length > 0 ?
                                filterListState[keyIndex]
                                :
                                undefined
                        };

                        if (keyIndex >= 0) {
                            this.setState({
                                filterList: newFilterList
                            });
                        }
                    } else {
                        // Reset action
                        this.setState({
                            filterList: {}
                        });
                    }
                }}
                onColumnSortChange={(changedColumn, direction) => {
                    this.setState({
                        sortState: {
                            [changedColumn]: direction === 'descending' ? 'desc' : 'asc'
                        }
                    })
                }}
                chipFormatter={{
                    checked: val => t(val),
                    duration: val => {
                        if (val.length > 0) {
                            return `${t('Durations between')} ${parseFloatHour(Number(val[0])) || '--'} ${t('and')} ${parseFloatHour(Number(val[1])) || '--'}`;
                        } else {
                            return undefined;
                        }
                    },
                    start_time: val => {
                        if (val.length > 0) {
                            return `${t('Starts between')} ${new Date(val[0]).toISOString().split('.')[0].replace('T', ' ') || '--'} ${t('and')} ${new Date(val[1]).toISOString().split('.')[0].replace('T', ' ') || '--'}`;
                        } else {
                            return undefined;
                        }
                    },
                    end_time: val => {
                        if (val.length > 0) {
                            return `${t('Ends between')} ${new Date(val[0]).toISOString().split('.')[0].replace('T', ' ') || '--'} ${t('and')} ${new Date(val[1]).toISOString().split('.')[0].replace('T', ' ') || '--'}`;
                        } else {
                            return undefined;
                        }
                    }
                }}
            />
        )
    }
};

export default withTranslation()(withStyles(styles)(EventsTableFull));