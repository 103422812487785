import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as HighchartsMore from 'highcharts/js/highcharts-more'
import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import SolidGauge from 'highcharts/modules/solid-gauge';
import * as moment from 'moment'
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider,
  Icon
} from '@material-ui/core';

import styles from './styles/styles.js';
import NoPrint from '../../../components/Print/NoPrint'

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

// Highcharts.setOptions({
//     plotOptions: {
//         series: {
//             animation: false
//         }
//     }
// });

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2E2E2E',
    color: theme.palette.common.white
  }
}))(TableCell);

class GraphicGauge extends Component {

  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      bar_colors: [
        '#C0D2FC',
        '#F4945C',
        '#FC9018',
        '#0A70D2'
      ],
      ref_colors: [
        '#F4645C',
        '#B414DC',
        '#FCDC34',
        '#4C65A7'
      ]
    }

    this.chartWillRender = this.chartWillRender.bind(this);
    // this.chartDidRender = this.chartDidRender.bind(this);
  }

  componentDidMount() {
    this.props.getSettings(this.getSettings.bind(this));

    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }

  // chartDidRender (state, updating, loading) {
  //   let postProcessDecorators = this.props.graphic.postProcessDecorators;
  //
  //   if(postProcessDecorators) {
  //
  //     setTimeout(() => {
  //       if(!updating && !loading) {
  //         setTimeout(() => {
  //           postProcessDecorators.map(decorator_json => {
  //             const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
  //             const result = decorator(state);
  //
  //             state = result;
  //           });
  //         }, 3000);
  //       } else {
  //         this.chartDidRender(state, updating, loading);
  //       }
  //     }, 200)
  //   }
  // }

  caulculateFontSizeHead(grid) {


    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }


  chartWillRender(state) {
    // let preProcessDecorators = this.props.graphic.preProcessDecorators;
    //
    // if(preProcessDecorators) {
    //   preProcessDecorators.map(decorator_json => {
    //     const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
    //     const result = decorator(state);
    //
    //     state = result;
    //   });
    // }
  }

  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = checkbox => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);
  };



  getSettings() {
    // let data = BAR_DECORATOR_LIST.map(decorator => (
    //   this.state[`switch${decorator.name}`] ?
    //   {
    //     name: `${decorator.name}`,
    //     params: 'params_post_treatment' in decorator && decorator.params_post_treatment ?
    //               decorator.params_post_treatment(
    //                 Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //               )
    //               : Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //   }
    //   : null
    // )).filter(decorator => decorator);
    //
    // return data;
  }

  render() {
    const { classes, showTable, showSettings, graphic, loading, font_size } = this.props;
    const { data } = graphic;


    var on_render_state = {
      ...this.state,
      data: data
    };

    this.chartWillRender(on_render_state);

    const maxValue = on_render_state.data.series
      ? on_render_state.data.series.map(series => series.value).reduce((acc, curr) => acc > curr ? acc : curr) * 1.1
      : null

    const references = on_render_state.data.series
      ? on_render_state.data.series.filter(series => series.type == 'reference')
      : []

    const bars = on_render_state.data.series
      ? on_render_state.data.series.filter(series => series.type == 'bar').sort((s1, s2) => s2.value - s1.value)
      : []

    const tableData = {
      'headers': ['Value'],
      'cross_headers': references.concat(bars).map(series => series.name),
      'rows': references.concat(bars).map(series => new Array(
        `${series.value ? series.value.toFixed(2) : 'No Data'} ${on_render_state.data.unit}`
      ))
    };

    // `${graphic.grid.h == 1 ? 45 : graphic.grid.h == 2 ? 55 : graphic.grid.h == 3 ? 65 : 75}%`

    const params = {
      // colors: on_render_state.colors,
      chart: {
        type: 'solidgauge'
      },
      plotShadow: false,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingBottom: 0,
      credits: {
        enabled: false
      },
      title: null,
      pane: {
        center: ['50%', '75%'],
        size: `${font_size * 12.5 < 140 ? font_size * 12.5 : 140}%`,
        startAngle: -110,
        endAngle: 110,
        background: null
      },

      tooltip: {
        enabled: false
      },

      // the value axis
      yAxis: {
        // stops: [
        //     [0.1, '#55BF3B'], // green
        //     [0.5, '#DDDF0D'], // yellow
        //     [0.9, '#DF5353'] // red
        // lineWidth: 2,
        // ],
        min: 0,
        max: maxValue,
        title: {
          y: -70
        },
        labels: {
          enabled: false
        },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        minorTickLength: 0,
        tickColor: '#ffffff',
        tickPositions: null,
        tickPosition: 'inside',
        plotBands: references.map((reference, index) => ({
          from: reference.value,
          to: reference.value + maxValue * 0.005,
          color: on_render_state.ref_colors[index],
          innerRadius: '75%',
          outerRadius: '100%',
          thickness: '5%',
          zIndex: 100
        }))
      },

      plotOptions: {
        solidgauge: {
          innerRadius: 75,
          radius: 100,
          dataLabels: {
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      series: [{
        name: '',
        data: [
          {
            name: 'Background',
            y: maxValue,
            color: '#e0e0e0'
          },
          ...bars.map((bar, index) => (
            {
              name: bar.name,
              color: on_render_state.bar_colors[index],
              y: bar.value
            }
          ))
        ],
        dataLabels: {
          format: references.map((series, index) => (`
              <div style="text-align:center">
                <span style="color: ${on_render_state.ref_colors[index]}; font-size: ${font_size}px; vertical-align: top; margin-right: 5px;">&#9632</span>
                <span style="font-size:${font_size * 0.8}px;color:grey;margin-right=2%">${series.name}:</span>
                <span style="font-size:${font_size * 0.9}px;color:black">${series.value ? series.value.toFixed(2) : 0}</span>
                <span style="font-size:${font_size * 0.7}px;color:silver">${on_render_state.data.unit}</span>
              </div>
            `)).concat(bars.map((series, index) => (`
              <div style="text-align:center">
                <span style="color: ${on_render_state.bar_colors[index]}; font-size: ${font_size}px; vertical-align: top; margin-right: 5px;">&#9632</span>
                <span style="font-size:${font_size * 0.8}px;color:grey;margin-right=2%">${series.name}:</span>
                <span style="font-size:${font_size * 0.9}px;color:black">${series.value ? series.value.toFixed(2) : 0}</span>
                <span style="font-size:${font_size * 0.7}px;color:silver">${on_render_state.data.unit}</span>
              </div>
            `))).join('')
        },
        tooltip: {
          valueSuffix: on_render_state.data.unit
        }
      }],
      // [{
      //   name: 'Speed',
      //   data: [{
      //       y: 175,
      //       name: "Point2",
      //       color: on_render_state.colors[0]
      //   }],
      //   dataLabels: {
      //       format: '<div style="text-align:center"><span style="font-size:25px;color:' +
      //           ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y}</span><br/>' +
      //              '<span style="font-size:12px;color:silver">MWh</span></div>'
      //   },
      //   tooltip: {
      //       valueSuffix: ' km/h'
      //   }
      // }],
      exporting:
      {
        enabled: true
      }
    }

    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable || showSettings ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront} style={{ display: showTable || showSettings ? 'none' : undefined }}>
            <HighchartsReact
              containerProps={{
                style: {
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden'
                }
              }}
              ref={this.refChart}
              highcharts={Highcharts}
              // constructorType={'stockChart'}
              options={params}
            />
          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable || showSettings ? undefined : 'none' }}>
            {
              showTable ?
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.rowHead}>
                      <CustomTableCell colSpan={1}></CustomTableCell>
                      {tableData.headers.map((elm, index) => {
                        if (elm === '0') {
                          return (
                            <CustomTableCell colSpan={1} key={index}>
                              {''}
                            </CustomTableCell>
                          )
                        }

                        return (
                          <CustomTableCell colSpan={1} key={index} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }} >
                            {elm}
                          </CustomTableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.rows.map((row, index) => new Array(tableData.cross_headers[index]).concat(row))
                      .map((row, indexRow) => {
                        return (
                          <TableRow className={classes.row} key={indexRow}>
                            {row.map((cell, indexCell) => {
                              return (
                                <CustomTableCell key={indexCell} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px', height: '2px' }}>
                                  {cell}
                                </CustomTableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>

                :

                false ?
                  <List>
                    {
                      // <MenuItem className={classes.applyBtn}>
                      //   <Typography align='center' style={
                      //     {
                      //       width: '100%',
                      //       color: '#ffffff'
                      //     }
                      //   }>Apply Settings</Typography>
                      // </MenuItem>
                    }

                    {
                      //   BAR_DECORATOR_LIST.map((decorator, index) => (
                      //     <div>
                      //       <ListItem
                      //         key={decorator.name}
                      //       >
                      //         <Switch
                      //           checked={this.state[`switch${decorator.name}`]}
                      //           onChange={this.checkboxToggle(`switch${decorator.name}`)}
                      //           value={decorator.name}
                      //         />
                      //         <ListItemText inset primary={`${decorator.name}`} className={classes.liLabel} />
                      //       </ListItem>
                      //       <Collapse in={this.state[`switch${decorator.name}`]} timeout="auto" unmountOnExit>
                      //         <List component="div" disablePadding>
                      //           <ListItem>
                      //             <Grid container spacing={24}>
                      //               {
                      //                 decorator.params_input_types.map((param_type, param_index) => {
                      //                   if(param_type.match('text_field')) {
                      //                     let type = param_type.split('_').pop();
                      //                     let text_field_state = `${decorator.name}_text-field-${type}_${decorator.params_names[param_index]}`;
                      //                     return (
                      //                       <Grid item xs={2} className={classes.liSubItems}>
                      //                         <TextField
                      //                           className={classes.liSubItemsTextArea}
                      //                           label={`${decorator.params_names[param_index]}`}
                      //                           value={this.state[text_field_state]}
                      //                           onChange={this.numberChange(text_field_state)}
                      //                           type={type}
                      //                           InputLabelProps={{
                      //                             shrink: true,
                      //                           }}
                      //                         />
                      //                       </Grid>
                      //                     )
                      //                   } else if(param_type.match('checkbox')) {
                      //                     let checkbox_state = `${decorator.name}_checkbox_${decorator.params_names[param_index]}`;
                      //                     return (
                      //                       <Grid item xs={2} className={classes.liSubItems}>
                      //                         <span>{`${decorator.params_names[param_index]}`}</span>
                      //                         <br/>
                      //                         <Checkbox
                      //                           checked={this.state[checkbox_state]}
                      //                           onChange={this.checkboxToggle(checkbox_state)}
                      //                           value={decorator.params_names[param_index]}
                      //                         />
                      //                       </Grid>
                      //                     )
                      //                   }
                      //                 })
                      //               }
                      //             </Grid>
                      //           </ListItem>
                      //         </List>
                      //       </Collapse>
                      //       <Divider />
                      //     </div >
                      // ))
                    }
                  </List>

                  :

                  <NoPrint>
                    <h3 style={{
                      width: '100%',
                      height: 'auto',
                      textAlign: 'center'
                    }}>Returning to Chart...</h3>
                  </NoPrint>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicGauge);
