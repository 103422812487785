import React, { useState } from 'react';

import {
    singleAcquisition,
    deleteAcquisition,
    updateAcquisition,
    multipleAcquisitions
} from '../../../../../../../providers/asset-provider';

import CircularLoading from '../../../../../../../components/Loading/CircularLoading';
import BaseViewDialog, {
    FormContentView
} from '../../../../../../../new-components/Dialog';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const View = props => {

    const {
        finishMount,
        acquisitionDialogOpened,
        openAcquisitionDialog,
        data,
        setData,
        setActiveResource,
        activeResource,
        options,
        storages,
        savingInfo,
        saveInfo,
        setRecords,
        setDataUploaded,
        modulePage,
        getComponents
    } = props;

    const [errorFormResource, setErrorFormResource] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const { t } = useTranslation();


    return (
        <React.Fragment>
            <BaseViewDialog
                title={activeResource ? t('Edit Component') : t('Component Acquisition')}
                confirm={activeResource ? t('Edit') : t('Register')}
                disableTopClose
                scroll='paper'
                opened={acquisitionDialogOpened}
                Open={openAcquisitionDialog}
                dialogProps={{
                    style: {
                        zIndex: 99999
                    }
                }}
                onDelete={async () => { setOpenDialogDelete(true) }}
                onConfirm={async () => {
                    const canExecute = Object.values(data).map(field => (
                        !field.required || (field.required && field.data != null)
                    )).reduce((acc, curr) => acc && curr);

                    if (canExecute) {
                        saveInfo(true);

                        const extra_info = Object.values(data).filter(field => (
                            field.acquisitionField != null && field.extra_info
                        )).map(field => ({
                            [field.acquisitionField]: (
                                typeof (field.acquisitionField) === 'object' &&
                                    field.acquisitionField.constructor === Date ?
                                    field.data.toISOString().split('T')[0]
                                    :
                                    field.data
                            )
                        })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

                        const acquisition = Object.values(data).filter(field => (
                            field.acquisitionField != null && !field.extra_info
                        )).map(field => ({
                            [field.acquisitionField]: (
                                typeof (field.acquisitionField) === 'object' &&
                                    field.acquisitionField.constructor === Date ?
                                    field.data.toISOString().split('T')[0]
                                    :
                                    field.data
                            )
                        })).reduce((acc, curr) => ({ ...acc, ...curr }), { extra_info });

                        let acquisitionRes = {}
                        const quantity = Number(acquisition.quantity);

                        if (activeResource) {
                            acquisitionRes = await updateAcquisition(activeResource, acquisition);
                            if (!acquisitionRes.error) {
                                await getComponents(acquisition.component_model_id, acquisition.storage_id)
                                    .catch(e => { console.log(e) });
                            }
                        } else {
                            if (quantity && quantity > 1) {
                                acquisitionRes = await multipleAcquisitions(Array(quantity).fill(acquisition));
                            } else {
                                acquisitionRes = await singleAcquisition(acquisition);
                            }
                        }

                        if (!acquisitionRes.error) {
                            const successMessage = `${activeResource ? t('Successfully edited component') : t("Successfully acquired component")}
                        ${options[acquisition.component_model_id].name} #${acquisition.serial_number}!`

                            toast.success(successMessage, {
                                position: 'top-right',
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true
                            });
                        } else {
                            toast.error(t(acquisitionRes.error), {
                                position: 'top-right',
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true
                            });
                        }

                        setActiveResource(false);
                        openAcquisitionDialog(false);
                        setData(null);
                        finishMount(false);
                        saveInfo(false);
                        setRecords([]);
                        setDataUploaded(false);
                    } else {

                        setErrorFormResource(true)
                    }
                }}
            >
                {
                    savingInfo &&
                    <CircularLoading>
                        <span>{`${t('Saving your acquisition')}.`}</span>
                    </CircularLoading>
                }
                <FormContentView
                    feedDataOut={setData}
                    options={options}
                    error={errorFormResource}
                    activeResource={activeResource}
                    storages={storages}
                    modelImg={modulePage.hasImage}
                    selectorProps={{
                        style: {
                            zIndex: 999999
                        }
                    }}
                />
            </BaseViewDialog>
            <BaseViewDialog
                title={t('Delete Component')}
                confirm={t('Delete')}
                disableTopClose
                opened={openDialogDelete}
                disableDelete={true}
                Open={setOpenDialogDelete}
                dialogProps={{
                    style: {
                        zIndex: 9999999
                    }
                }}
                onConfirm={async () => {

                    saveInfo(true);

                    const acquisitionRes = await deleteAcquisition(activeResource)

                    if (!acquisitionRes.error) {
                        const successMessage = t('Successfully on delete');
                        await getComponents(activeResource.component_model_id, activeResource.storage_id)
                            .catch(e => { console.log(e) });

                        toast.success(successMessage, {
                            position: 'top-right',
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true
                        });
                    } else {
                        toast.error(acquisitionRes.error, {
                            position: 'top-right',
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true
                        });
                    }

                    setActiveResource(false);
                    openAcquisitionDialog(false);
                    setData(null);
                    finishMount(false);
                    saveInfo(false);
                    setRecords([]);
                    setDataUploaded(false);
                    setOpenDialogDelete(false)


                }
                }
            >

                {t('delete_warning_inventory')}
                {
                    savingInfo &&
                    <CircularLoading>
                        <span>{t("Saving your acquisition")}</span>
                    </CircularLoading>
                }
            </BaseViewDialog>

        </React.Fragment >

    );
}

export default View;