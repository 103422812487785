import styled from 'styled-components'
import grey from '@material-ui/core/colors/grey'

const GraphicKeyMatricsContent = styled.div`
  height: 100%;
  width: 100%;
  font-size: 12px;
  color: ${grey[700]};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`

const GraphicKeyMatricsContentPrimaryItem = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`

export {GraphicKeyMatricsContent, GraphicKeyMatricsContentPrimaryItem}
