import React, {Component} from 'react';
import * as R from 'ramda';

const condition_function = (data, axis, condition_expression) => {
    switch (axis) {
      case 'x':
        let condition_results = Object.keys(data.series).map(key => (
          data.series[key].values.map(v => eval(condition_expression))
        ));
        return condition_results;
      default:
        return null;
    }
};

const AxisColorTreatment = (condition_expression='v == null', axis='x', color='#ff3a3a') => {
  const real_AxisColorTreatment = state => {
    state.data_without_axis_colors = JSON.parse(JSON.stringify(state.data));

    try{
      let results = condition_function(state.data, axis, condition_expression);
      let combinations = results.reduce((acc, curr) => (
          curr.map((logic_val, index) => (logic_val || acc[index]) && (acc[index] != logic_val))
        )
      );

      let labels = null;
      switch (axis) {
        case 'x':
          labels = state.data.x_axis_color ?
                state.data.x_axis_color.concat(Array(state.data.x_axis.length - state.data.x_axis_color.length).fill(null))
                : Array(state.data.x_axis.length).fill(null);
          break;
        default:
          throw 'Need to receive an axis';
          break;
      }

      combinations.map((needs_recoloring, index) => {
        if(needs_recoloring) {
          labels[index] = '#ff2b39';
        }
      });

      state.data.x_axis_color = labels;

    } catch (e) {
      // ;
      state.data = JSON.parse(JSON.stringify(state.data_without_axis_colors));
      delete state.data_without_axis_colors;
    } finally {
      // ;
      return state;
    }
  }

  return(real_AxisColorTreatment);
};

export default AxisColorTreatment;
