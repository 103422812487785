import React, {Component} from 'react'
import grey from '@material-ui/core/colors/grey'

import ReportsHeaderComponent from '../../../../components/ReportsHeaderComponent/ReportsHeaderComponent'
import ReportsListComponent from '../../components/ReportsListComponent/ReportsListComponent'
import ReportsListTemplateComponent from '../../components/ReportsListTemplateComponent/ReportsListTemplateComponent';
import ReportsHeaderTemplateComponent from '../../../../components/ReportsHeaderTemplateComponent/ReportsHeaderTemplateComponent';

const HEADER_HEIGHT = 40
const PADDING_PAGE = 20

class ReportsListContentPage extends Component {
  goPage = (page, data = false) => {


    if (!data) {

        this.props.history.push(page)
    }


    this.props.history.push({pathname: page, state: { data: data } })


  }

  render () {
    return (
      <div
        id="ReportsListContentPage"
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            flex: 1,
            margin: PADDING_PAGE,
            paddingLeft: PADDING_PAGE,
            paddingRight: PADDING_PAGE,
            border: `1px solid ${grey['200']}`
          }}
        >
          <div
            style={{
              height: HEADER_HEIGHT,
              width: '100%',
              paddingTop: PADDING_PAGE,
              paddingBottom: PADDING_PAGE,
              borderBottom: `1px solid ${grey['200']}`
            }}
          >
            <ReportsHeaderComponent
              goPage={this.goPage}
            />
          </div>
          <div
            style={{
              height: `calc(100% - ${HEADER_HEIGHT + (PADDING_PAGE * 2)}px)`,
              overflow: 'auto'
            }}
          >
            <ReportsListComponent  goPage={this.goPage}/>
          </div>
        </div>

        <div
          style={{
            flex: 1,
            margin: PADDING_PAGE,
            paddingLeft: PADDING_PAGE,
            paddingRight: PADDING_PAGE,
            border: `1px solid ${grey['200']}`
          }}
        >
          <div
            style={{
              height: HEADER_HEIGHT,
              width: '100%',
              paddingTop: PADDING_PAGE,
              paddingBottom: PADDING_PAGE
            }}
          >
            <ReportsHeaderTemplateComponent
              goPage={this.goPage}
            />
          </div>
          <div
            style={{
              height: `calc(100% - ${HEADER_HEIGHT + (PADDING_PAGE * 2)}px)`,
              overflow: 'auto'
            }}
          >
            <ReportsListTemplateComponent goPage={this.goPage} />
          </div>
        </div>
      </div>
    )
  }
}

export default ReportsListContentPage
