import React from 'react'
import {Route} from 'react-router-dom'

import AssetModelConsumableListPage from './pages/AssetModelConsumableListPage/AssetModelConsumableListPage';
import {
  ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE,
  ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE_EDIT,
  ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE_CREATE
} from '../../../../../../../../constants/route-constant';
import AssetModelConsumableChangePage from './pages/AssetModelConsumableChangePage/AssetModelConsumableChangePage';

class AssetModelPage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          exact
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE}
          render={props => (<AssetModelConsumableListPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE_CREATE}
          render={props => (<AssetModelConsumableChangePage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE_EDIT}
          render={props => (<AssetModelConsumableChangePage {...props} />)}
        />
      </div>
    )
  }
}

export default AssetModelPage
