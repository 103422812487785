export default theme => ({
  root: {
    flexGrow: 1,
  },
  flipContainer: {
    width: '100%',
    height: `100%`,
    perspective: '1000px',
  },
  flipContainerFront: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    transform: 'rotateX(0deg)',
    backfaceVisibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between'
  },
  flipContainerBack: {
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'rotateX(180deg)',
  },
  flipper: {
    height: '100%',
    width: '100%',
    transition: '0.6s',
    transformStyle: 'preserve-3d',
    position: 'relative',
  },
  tableContent: {
    width: '100%',
    height: '100%'
  },
  tableCellHeaderValue: {
    padding: 0,
    textAlign: 'center'
  },
  tableCellValue: {
    padding: 0
  },
  listItem: {
    '&:focus': {
      outline: '0px !important',
      '-webkit-appearance': 'none'
    },
    paddingTop: '0px !important'
  },
  liContainer: {
    margin: 0
  },
  liLabel: {
    fontSize: '2.6vh'
  },
  liSubItems: {
    fontSize: '1.8vh',
    textAlign: 'center',
    padding: '0px !important',
  },
  liSubItemsTextArea: {
    maxWidth: '100%',
  },
  liSubItemsCheckbox: {

  },
  applyBtn: {
    backgroundColor: '#F4945C',
    color: 'white',

    '&:hover': {
      backgroundColor: '#F4645C'
    }
  }
})