import React, {Component} from 'react'
import {Route, Redirect} from 'react-router-dom'
import { ROUTE_MAIN_REPORTS, ROUTE_MAIN_REPORTS_MANAGEMENT, ROUTE_MAIN_REPORTS_TEMPLATES } from '../../../../constants/route-constant';
import ReportsListPage from './pages/ReportsListPage/ReportsListPage';
import ReportsTemplatesPage from './pages/ReportsTemplatesPage/ReportsTemplatesPage';
// import { STYLE_HEADER_TOOLBAR, STYLE_SUBHEADER_TOOLBAR } from '../../../../constants/style-constant';

class ReportsPage extends Component {
  render () {
    return (
      <div
        id="ReportsPage"
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route path={ROUTE_MAIN_REPORTS_MANAGEMENT} component={ReportsListPage} />
        <Route path={ROUTE_MAIN_REPORTS_TEMPLATES} component={ReportsTemplatesPage} />

        <Route exact path={ROUTE_MAIN_REPORTS} render={props => {
          return (
            <Redirect
              to={{
                pathname: ROUTE_MAIN_REPORTS_MANAGEMENT,
                state: { from: props.location }
              }}
            />
          )
        }} />
      </div>
    )
  }
}

export default ReportsPage
