import React from 'react';
import useContainer from './Container';
import { Icon, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import uuidv4 from 'uuid/v4';
import { useTranslation } from 'react-i18next'


const id = uuidv4();

const useStyles = makeStyles(() => ({
    input: {
        display: 'none'
    },
    button: {
        textTransform: 'none',
        height: 'inherit',
        width: '100%',
        height: 400,
        border: '4px dashed #dbdbdb'
    },
    btnLabel: {
        width: '100%',
        height: '100%'
    },
    img: {
        objectFit: 'contain',
        width: '100%',
        height: '100%'
    },
    iconWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '100%',
        alignItems: 'center'
    },
    icon: {
        fontSize: '15em',
        color: '#dbdbdb'
    },
    messageContent: {
        textAlign: 'center'
    }
}));


export default props => {

    const { src, onChange, loading, message, disabled } = useContainer(props);
    const { input, button, btnLabel, img, iconWrapper, icon, messageContent } = useStyles();
    const { t } = useTranslation();


    return (
        <>
            <input
                className={input}
                accept='.jpg, .jpeg, .png'
                id={id}
                type='file'
                onChange={onChange}
                disabled={disabled}
            />
            <label htmlFor={id} style={{ width: '100%' }}>
                <Button
                    variant="raised"
                    component='span'
                    className={button}
                    classes={{ label: btnLabel }}
                    disabled={disabled}
                >
                    {
                        loading ?
                            <CircularProgress color='inherit' />
                            :
                            src ?
                                <img
                                    className={img}
                                    src={src} />
                                :
                                <div className={iconWrapper}>
                                    <Icon className={icon}>{message.icon}</Icon>
                                    <h3 className={messageContent} >{t(message.content)}</h3>
                                </div>
                    }
                </Button>
            </label>
        </>
    );
};