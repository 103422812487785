import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Button,
  Grid,
  ListItem,
  ListItemText
} from '@material-ui/core';
import * as R from 'ramda';

import styles from './styles/styles.js';

import ReactHighchart from 'react-highcharts';
import HighchartMore from 'highcharts/highcharts-more';

// ReactHighchart.Highcharts.setOptions({
//     plotOptions: {
//         series: {
//             animation: false
//         }
//     }
// });
HighchartMore(ReactHighchart.Highcharts);

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#bdbdbd',
    color: theme.palette.common.black,
    //fontSize: 16,
  },
  body: {
    //fontSize: 14,
  },
}))(TableCell);


class GraphicBoxplot extends React.Component {


  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      columns: [],
      column_sort: 2,
      is_crescent: false,
      tableData: null,
      tableColumns: null
    }

  }

  componentDidMount() {
    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }

  state = {
    value: 0,
    showTable: false,
    showBoxPlot: true,
    series: []
  };

  changeChart = () => {
    this.setState(state => ({ showTable: !this.state.showTable }));
    this.setState(state => ({ showBoxPlot: !this.state.showBoxPlot }));
  };



  static formatTooltip(tooltip, x = this.x, y = this.y, series = this.series) {
    return `<b>${x}</b><br/>${series.name}: ${y}`;
  }


  static getConfig = (series, xAxis, yAxis) => ({
    chart: {
      type: 'boxplot',
      height: 290,
    },
    exporting: {
      enabled: true
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: xAxis,
    tooltip: {
      headerFormat: '<em>Amostra de dados</em><br/>'
    },
    yAxis: yAxis,

    series: series

  })


  caulculateFontSizeHead(grid) {


    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }

  setFilter(params) {

    let order = !this.state.is_crescent
    this.setState({ column_sort: params.index, is_crescent: order })

  }

  orderRows(rows, column_sort, is_crescent) {

    let keys = ['low', 'q1', 'median', 'q3', 'high'];

    let aux = rows.series[0];
    let aux_table = [];

    aux_table = aux.data ? aux.data.map(row => (
      keys.map(key => ({ [key]: row[key] })).reduce((acc, curr) => ({ ...acc, ...curr }))
    ))
      :
      [];

    const sort_anchor = aux_table.map((row, index) => {

      //keys.map(key => ({[key]: row[key]})).reduce((acc, curr) => ({...acc, ...curr}))

      return { idx: index, sort_value: Object.values(row)[column_sort], sorted_column: Object.values(rows.xAxis.categories)[index] }
    }).sort((a, b) => {

      return a.sort_value < b.sort_value ?
        is_crescent ? -1 : 1
        :
        a.sort_value > b.sort_value ?
          is_crescent ? 1 : -1
          :
          0
    })

    this.state.columns = sort_anchor.map(function (value, index) { return value.sorted_column; })

    return aux_table.map((row, index) => aux_table[sort_anchor[index].idx])
  }



  render() {
    let showBoxPlot = this.state.showBoxPlot;
    const { classes, showTable, showSettings, graphic, loading } = this.props;
    const { data, id, widths } = graphic;

    var on_render_state = {
      ...this.state,
      data: data ? data : {
        x_axis: null,
        series: [],
        extra_y_axis: [],
        widths: widths
      }
    };

    this.state.render_data = on_render_state
    this.state.columns = null;

    let aux = this.state.render_data.data
    let sorted_table = null;

    if (this.state.column_sort >= 0) {
      if (aux) {
        sorted_table = this.orderRows(aux, this.state.column_sort, this.state.is_crescent)
      }
    }

    const data_table = on_render_state.data_to_table ? on_render_state.data_to_table : on_render_state.data;


    let rows = [];
    let table = [];

    data.series.forEach(function (element) {
      // element.data.forEach(function(data) {

      //   rows.push(element.data);


      //   let serieArray = [] ;
      //   serieArray.push(element.name);
      //   serieArray.push(data.low);
      //   serieArray.push(data.q1);
      //   serieArray.push(data.median);
      //   serieArray.push(data.q3);
      //   serieArray.push(data.high);
      //   table.push(serieArray);

      // });


    });

    this.state.tableData = {
      'headers': ['Low', 'Q1', 'Median', 'Q3', 'High'],
      //'cross_headers': on_render_state.data.xAxis.categories.map(item => item ? item : 'No Data'),
      //'rows': on_render_state.data.series
      'cross_headers': this.state.columns.map(item => item ? item : 'No Data'),
      'rows': sorted_table
        ?
        //on_render_state.data.xAxis.categories.map((item, id) => item ? [

        this.state.columns.map((item, id) => item ? [

          item,

          /*on_render_state.data.series[0].data[id].low ? on_render_state.data.series[0].data[id].low.toFixed(2) : 'No Data',

          on_render_state.data.series[0].data[id].q1 ? on_render_state.data.series[0].data[id].q1.toFixed(2) : 'No Data',

          on_render_state.data.series[0].data[id].median ? on_render_state.data.series[0].data[id].median.toFixed(2) : 'No Data',

          on_render_state.data.series[0].data[id].q3 ? on_render_state.data.series[0].data[id].q3.toFixed(2) : 'No Data',                

          on_render_state.data.series[0].data[id].high ? on_render_state.data.series[0].data[id].high.toFixed(2) : 'No Data',*/

          sorted_table[id].low ? sorted_table[id].low.toFixed(2) : 'No Data',

          sorted_table[id].q1 ? sorted_table[id].q1.toFixed(2) : 'No Data',

          sorted_table[id].median ? sorted_table[id].median.toFixed(2) : 'No Data',

          sorted_table[id].q3 ? sorted_table[id].q3.toFixed(2) : 'No Data',

          sorted_table[id].high ? sorted_table[id].high.toFixed(2) : 'No Data',

        ] : 'No Data')
        : []
    };

    return (
      <div className={classes.flipContainer}>
        <div
          className={classes.flipper}
        >
          <div className={classes.flipContainerFront}
          >
            {
              !showTable &&
              <ReactHighchart
                containerProps={{
                  style: {
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                  }
                }}
                config={GraphicBoxplot.getConfig(data.series, data.xAxis, data.yAxis)} styleByCss={true} />
            }
            {
              showTable && <Table className={classes.table}>

                <TableHead>
                  <TableRow>
                    <CustomTableCell colSpan={1}></CustomTableCell>
                    {this.state.tableData.headers.map((elm, index) => {

                      if (elm === '0') {
                        return (
                          <CustomTableCell colSpan={1} key={index}> primary={this.state.columns[index]}
                            {''}
                          </CustomTableCell>
                        )
                      }

                      return (
                        <CustomTableCell primary={this.state.columns[index]} colSpan={1} key={index} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}>
                          {elm}

                          {
                            on_render_state.data.series.map((name, c_index) => (

                              <Button onClick={this.setFilter.bind(this, { name: name, index: index })} mini style={{
                                paddingRight: '30px'
                              }}>
                                <Icon>arrow_drop_down</Icon>
                              </Button>

                            ))
                          }


                        </CustomTableCell>
                      )
                    }

                    )

                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.tableData.rows.map((row, indexRow) => {
                    return (
                      <TableRow className={classes.row} key={indexRow}>
                        {row.map((cell, indexCell) => {
                          return (
                            <CustomTableCell style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px', height: '2px' }} key={indexCell}>
                              {cell}
                            </CustomTableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>

            }


          </div>
        </div>
      </div>
    );
  }
}

GraphicBoxplot.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GraphicBoxplot);