import React, {Component} from 'react'
import { WIDGET_HEADER_HEIGHT } from '../../constants/widget-constant'
import grey from '@material-ui/core/colors/grey'
import { withStyles, Paper, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MultilineChartIcon from '@material-ui/icons/MultilineChart'

const styles = theme => ({
  paperElement: {
    width: `calc(100% - 4px)`,
    height: `calc(100% - 2px)`,
    border: `1px solid ${grey['200']}`,
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: grey['100']
  },
  paperHeader: {
    paddingLeft: '10px',
    minHeight: `${WIDGET_HEADER_HEIGHT}px`,
    borderBottom: `1px solid ${grey['200']}`,
    display: 'flex',
    alignItems: 'center'
  },
  paperBody: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  multilineIcon: {
    color: grey['400'],
    fontSize: 150
  }
})

class GraphicWidgetEdit extends Component {
  render () {
    const {classes, widget} = this.props

    const headerFontSize = Math.floor(widget.grid.w * 2 + widget.grid.h * 1.5);

    return (
      <Paper className={classes.paperElement} elevation={0}>
        <div className={classes.paperHeader}>
          <div
            style={{
              flex: 1
            }}
          >
          <Typography variant="headline" component="h5" style={{fontSize: `${headerFontSize < 10 ? 10 : headerFontSize > 16 ? 16 : headerFontSize}px`}}>
            {widget.titleHeader}
          </Typography>
          </div>
          {this.props.noEdit ? '': (
            <div
              style={{
                flex: 0,
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',

              }}
            >
              <IconButton
                onClick={this.props.goEdit}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={this.props.goRemove}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </div>
        <div className={classes.paperBody}>
          <MultilineChartIcon
            className={classes.multilineIcon}
          />
        </div>
      </Paper>
    )
  }
}

export default withStyles(styles)(GraphicWidgetEdit)
