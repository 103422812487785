import React, { Fragment, Component } from 'react';
import {withStyles} from '@material-ui/core/styles';

import { Auth } from 'aws-amplify'

import CircularLoading from '../../../../components/Loading/CircularLoading';

import {
    List,
    ListSubheader,
    ListItem,
    ListItemIcon,
    ListItemText,
    Icon,
    Collapse
} from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: '1vh 1vw 1vh 1vw',
        display: 'block',
        overflowY: 'auto',
        margin: '2vh 0 0 15%',
        height: '97%',
        width: '70%'
    },
    center: {
        height: 'inherit',
        width: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    list: {
        width: '99%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
    bigPicture: {
        fontSize: '25em',
        color: '#dbdbdb'
    }
});

class DocumentationPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            docs: null
        }
    }

    async componentDidMount() {
        const { idToken } = await Promise.resolve(Auth.currentSession());
        const { payload } = idToken || {};
        const clientId = payload['custom:client'];
        
        try {
            const { default: docs } = await Promise.resolve(require(`../../../../assets/docs/client|${clientId}`));
            
            const categories = Object.values(docs).map(category => ({
                [category.id]: {
                    id: category.id,
                    isOpen: true
                }
            })).reduce((acc, curr) => ({...acc, ...curr}));

            this.setState({
                loading: false,
                docs,
                ...categories
            });
        } catch(err) {
            this.setState({
                loading: false
            });
        }        
    }

    handleClick = categoryId => event => {
        this.setState(prevState => ({
            [categoryId]: {
                ...prevState[categoryId],
                isOpen: !prevState[categoryId].isOpen
            }
        }));
    }

    render () {
        const { classes } = this.props;
        const { loading, docs } = this.state;

        return (
            loading ? (
                <CircularLoading>
                    <span>Getting your documentation.</span>
                </CircularLoading>
            )
            :
            (
                <div className={classes.root}>
                    {
                        docs ? (
                            <List
                                component='div'
                                subheader={
                                    <ListSubheader component='h5'>
                                        Documentation List
                                    </ListSubheader>
                                }
                                className={classes.list}
                            >
                                {
                                    Object.values(docs).map(category => (
                                        <Fragment>
                                            <ListItem 
                                                button 
                                                onClick={this.handleClick(category.id)}
                                                key={category.id}
                                            >
                                                <ListItemIcon>
                                                    <Icon>category</Icon>
                                                </ListItemIcon>
                                                <ListItemText 
                                                    inset 
                                                    primary={category.name}
                                                />
                                                {
                                                    this.state[category.id].isOpen ? 
                                                        <Icon>keyboard_arrow_down</Icon> 
                                                    : 
                                                        <Icon>keyboard_arrow_up</Icon> 
                                                }
                                            </ListItem>
                                            <Collapse 
                                                in={this.state[category.id].isOpen} 
                                                timeout='auto' 
                                                // unmountOnExit
                                            >
                                                <List 
                                                    component='div' 
                                                    disablePadding
                                                >
                                                    {
                                                        Object.values(category.content).map(doc => (
                                                            <ListItem 
                                                                button 
                                                                className={classes.nested}
                                                                key={doc.id}
                                                                onClick={() => setTimeout(() => window.open(doc.file, '_blank'), 150)}
                                                            >
                                                                <ListItemIcon>
                                                                    <Icon>description</Icon>
                                                                </ListItemIcon>
                                                                <ListItemText 
                                                                    inset 
                                                                    panimate
                                                                    primary={doc.name}
                                                                />
                                                                {/* <a src={doc.file}>{doc.name}</a> */}
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            </Collapse>
                                        </Fragment>
                                    ))
                                }
                            </List>
                        )
                        :
                        (
                            <div className={classes.center}>
                                <Icon className={classes.bigPicture}>link_off</Icon>
                                <h3>Sorry, no documentation is available.</h3>
                                <p>Please send an email feedback to <a>contato@delfosim.com</a></p>
                            </div>
                        )
                    }
                </div>
            )
        );
  }
}

export default withStyles(styles)(DocumentationPage);