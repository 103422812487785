import React from 'react';
import Autosuggest from 'react-autosuggest';
import { TextField } from '@material-ui/core';
import './TagSuggestStyle.css';

const TagSuggest = tags => props => {
    const handleOnChange = (e, { newValue, method }) => {
        if (method === 'enter') {
            e.preventDefault();
        } else {
            props.onChange(e);
        }
    };

    const inputValue = (props.value && props.value.trim().toLowerCase()) || '';
    const inputLength = inputValue.length;

    let suggestions = tags.filter((tag) => {
        return tag.name.toLowerCase().slice(0, inputLength) === inputValue
    });

    return (
        <Autosuggest
            ref={props.ref}
            suggestions={suggestions}
            shouldRenderSuggestions={(value) => value && value.trim().length > 0}
            getSuggestionValue={(suggestion) => suggestion.name}

            renderSuggestion={(suggestion) => (
                suggestion.name
            )}

            renderInputComponent={props => <TextField {...props} />}

            inputProps={{ ...props, onChange: handleOnChange, fullWidth: true, variant: 'outlined', margin: 'dense' }}
            onSuggestionSelected={(e, { suggestion }) => {
                props.addTag(suggestion.name)
            }}
            onSuggestionsClearRequested={() => { }}
            onSuggestionsFetchRequested={() => { }}
        />
    )
};

export default TagSuggest;