import React from 'react'
import { withStyles, Tooltip, LinearProgress } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment-timezone'
import * as R from 'ramda'
import _ from 'lodash'
import getStatusColorRealtime, { getStatusByTag } from '../../../../../../../../helpers/get-status-color-realtime'



const WIDGET_HEADER_HEIGHT = 40
const styles = {
  table: {
    borderCollapse: 'collapse',
    width: '100%',
  },
  tableTh: {
    color: grey['500'],
    textAlign: 'center',
    padding: '8px'
  },
  tableTd: {
    textAlign: 'center',
    padding: '6px',
    color: grey['400']
  }
}

class OPCPortfolioItem extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      dialogWtg: false
    }
  }

  getStoppedWtgsBySubpark(subpark) {
    return R.sum(
      subpark.wtgs.map(wtg => {

        const stateTag = R.find(R.propEq('var_id', 1000))(wtg.realtime) || R.find(R.propEq('var_id', 3))(wtg.realtime);
        const state = stateTag ? stateTag.value : '';

        const maintenanceTag = R.find(R.propEq('var_id', 4))(wtg.realtime);
        const maintenance = maintenanceTag ? maintenanceTag.value_bool : '';

        const powerLimitation = wtg.realtime.filter(R.propEq('is_alarm', true)).some(({ value_bool }) => value_bool);

        let status = {};

        if (stateTag && stateTag.value_string) {
          status = getStatusByTag(stateTag.value_string);
          if (status.color && (status.color == 'red' || status.color == 'blue' || status.color == 'purple' || status.color == 'grey')) {
            return 1;
          }
        } else {
          status = getStatusColorRealtime(state, wtg.model_id, maintenance, powerLimitation);
          if (status.color && status.color == 'red') {
            return 1;
          }
        }

        return 0;

      })
    )
  }

  getPowerBySubpark(subpark) {

    return R.sum(subpark.wtgs.map((wtg) => {
      const powerTag = R.find(R.propEq('var_id', 1))(wtg.realtime)


      const power = powerTag ? powerTag.value : ''

      return power

    }))

  }


  getWindBySubpark(subpark) {
    const all = subpark.wtgs.map((wtg) => {
      const windTag = R.find(R.propEq('var_id', 2))(wtg.realtime)
      const wind = windTag ? windTag.value : ''
      return wind
    })

    return R.sum(all) / all.length
  }



  getRatedPowerBySubpark(subpark) {
    const all = subpark.wtgs.map((wtg) => {
      const power = wtg.model.specs.rated_power;
      return power;
    })

    return R.sum(all)
  }

  getWindTimeBySubpark(subpark) {


    let timeAll = [];

    subpark.wtgs.map((wtg, indexWtg) => {

      const windTag = R.find(R.propEq('var_id', 2))(wtg.realtime)

      if (windTag) {

        timeAll = timeAll.concat(windTag)

      }

    })

    const timeAllSort = timeAll.sort((a, b) => {
      return moment.utc(a.time).diff(moment.utc(b.time))
    })

    const last = R.last(timeAllSort) && R.last(timeAllSort).time ? moment.duration(moment().diff(moment(R.last(timeAllSort).time))).humanize() : null
    return last;
  }


  openDialogWtg(subpark) {

    this.props.selectSubpark(subpark);

  }

  getPowerTimeBySubpark(subpark) {

    let timeAll = [];

    subpark.wtgs.map((wtg, indexWtg) => {

      const powerTag = R.find(R.propEq('var_id', 1))(wtg.realtime)

      timeAll = timeAll.concat(powerTag)

    })

    const timeAllSort = timeAll.sort((a, b) => {
      return moment.utc(a.time).diff(moment.utc(b.time))
    })

    const last = R.last(timeAllSort) && R.last(timeAllSort).time ? moment.duration(moment().diff(moment(R.last(timeAllSort).time))).humanize() : null

    return last;

  }


  render() {
    const { classes } = this.props
    let timeAll = [];

    const WtgAll = this.props.item.subparks && this.props.item.subparks.length > 0 ?
      R.sum(this.props.item.subparks.map(subpark => subpark.wtgs.length))
      :
      0;


    const WtgStoppedAll = this.props.item.subparks && this.props.item.subparks.length > 0 ?
      R.sum(this.props.item.subparks.map(this.getStoppedWtgsBySubpark))
      :
      0;


    const powerAll = this.props.item.subparks && this.props.item.subparks.length > 0 ?
      R.sum((
        this.props.item.subparks.map((subpark, indexSubpark) => {

          return R.sum(subpark.wtgs.map((wtg) => {
            const powerTag = R.find(R.propEq('var_id', 1))(wtg.realtime)


            const power = powerTag ? powerTag.value : ''

            return power

          }))
        })
      ))
      :
      0;



    const windAllItems = this.props.item.subparks && this.props.item.subparks.length > 0 ?
      this.props.item.subparks.map((subpark) => {
        const all = subpark.wtgs.map((wtg) => {
          const windTag = R.find(R.propEq('var_id', 2))(wtg.realtime)
          const wind = windTag ? windTag.value : ''

          return wind
        })

        return R.sum(all) / all.length
      })
      :
      []

    const windAll = R.sum(windAllItems) / windAllItems.length

    return (
      <div
        style={{
          color: 'white',
          border: `1px solid ${grey['900']}`,
          width: '100%',
          height: '100%'
        }}
      >


        <div
          className="move-grid-widget"
          style={{
            height: WIDGET_HEADER_HEIGHT,
            width: '100%',
            borderBottom: `1px solid ${grey['900']}`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            // color: grey['500']
            color: 'white',
            // fontSize: 12
            flexFlow: 'row wrap'
          }}
        >
          <div
            style={{
              marginLeft: 10
            }}
          >
            {this.props.item.name}
          </div>

          <div style={{
            marginLeft: 10
          }}>
            WTG Availability:
                <span
              style={{
                marginLeft: 10,
                fontWeight: 900
              }}
            >
              <span>{WtgAll - WtgStoppedAll}</span> / <span>{WtgAll}</span>
            </span>

          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginRight: 10,
              // fontSize: 12,
              flexFlow: 'row wrap'
            }}
          >

            <div>
              Power (MW)
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 900,
                  color: 'white'
                }}
              >
                {(powerAll / 1000).toFixed(1)}
              </span>
            </div>
            <div
              style={{
                marginLeft: 10
              }}
            >
              /
            </div>
            <div
              style={{
                marginLeft: 10
              }}
            >
              Wind Speed (m/s)
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 900,
                  color: 'white'
                }}
              >
                {windAll.toFixed(1)}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
            width: '100%',
            overflow: 'auto'
          }}
        >
          <table className={classes.table}>
            <thead>
              <tr>
                {/* <th
                  className={classes.tableTh}
                >
                  Status
                </th> */}
                <th
                  className={classes.tableTh}
                >
                  Subpark
                </th>

                <th
                  className={classes.tableTh}
                >
                  WTG Unavailability
                </th>

                <th
                  className={classes.tableTh}
                  colSpan={2}
                  style={{
                    width: 220
                  }}
                >
                  Power (kW)
                </th>
                <th
                  className={classes.tableTh}
                  colSpan={2}
                  style={{
                    width: 220
                  }}
                >
                  Wind Speed (m/s)
                </th>
              </tr>
            </thead>
            <tbody>
              {
                this.props.item.subparks && this.props.item.subparks.length > 0 ?
                  (
                    R.reverse(_.sortBy(this.props.item.subparks, ['name'])).map((subpark, indexSubpark) => {
                      // R.find(R.propEq('var_id', 1))(item.realtime)
                      const power = this.getPowerBySubpark(subpark);
                      const power_time = this.getPowerTimeBySubpark(subpark);

                      const wind = this.getWindBySubpark(subpark);
                      const wind_time = this.getWindTimeBySubpark(subpark);

                      const stoppedWtgs = this.getStoppedWtgsBySubpark(subpark);

                      const powerBarValue = ((100 / this.getRatedPowerBySubpark(subpark)) * power);

                      return (
                        <tr
                          key={indexSubpark}
                          style={{
                            backgroundColor: indexSubpark % 2 === 0 ? grey['900'] : null
                          }}
                        >
                          <td
                            className={classes.tableTd}
                          >
                            <a
                              onClick={this.openDialogWtg.bind(this, subpark)}
                              style={{
                                textDecoration: 'none',
                                cursor: 'pointer'
                              }}
                            >
                              <span
                                style={{
                                  display: 'block',
                                  minWidth: 75,
                                  color: 'white',
                                  fontWeight: 900,
                                  // border: `1px solid ${status.color}`,
                                  color: 'white',
                                  borderRadius: 4,
                                  padding: '1px 5px',
                                }}
                              >
                                {subpark.name}
                              </span>
                            </a>
                          </td>


                          <td
                            className={classes.tableTd}
                          >
                            {
                              stoppedWtgs > 0 ?
                                <span
                                  style={{
                                    backgroundColor: 'red',
                                    borderRadius: 4,
                                    padding: '1px 5px',
                                    color: 'white',
                                    fontWeight: 900
                                  }}
                                >
                                  {stoppedWtgs}
                                </span>
                                :
                                <span>{'-'}</span>
                            }
                          </td>


                          <td
                            className={classes.tableTd}
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            <Tooltip
                              placement="right"
                              title={(
                                <div>
                                  <span>
                                    Last update:
                                </span>
                                  <br />
                                  <span>
                                    {power_time}
                                  </span>
                                </div>
                              )}
                            >
                              <span>
                                {`${Number(power).toFixed(1)}`}
                                {/* 0  2400 */}
                              </span>
                            </Tooltip>
                          </td>
                          <td
                            className={classes.tableTd}
                            style={{
                              minWidth: 50
                            }}
                          >
                            <LinearProgress
                              style={{
                                // height: 10
                              }}
                              variant="determinate"
                              value={powerBarValue > 100 ? 100 : powerBarValue}
                            />
                          </td>
                          <td
                            className={classes.tableTd}
                            style={{
                              textAlign: 'right'
                            }}
                          >
                            <Tooltip
                              placement="right"
                              title={(
                                <div>
                                  <span>
                                    Last update:
                                </span>
                                  <br />
                                  <span>
                                    {wind_time}
                                  </span>
                                </div>
                              )}
                            >
                              <span>
                                {`${Number(wind).toFixed(1)}`}
                                {/* 0  2400 */}
                              </span>
                            </Tooltip>
                          </td>
                          <td
                            className={classes.tableTd}
                            style={{
                              minWidth: 50
                            }}
                          >
                            <LinearProgress
                              style={{
                                // height: 10
                              }}
                              variant="determinate"
                              value={((100 / 20) * wind)}
                            />
                          </td>
                        </tr>
                      )
                    })
                  )
                  : (
                    <tr>
                      <td colSpan={5}>
                        No data...
                    </td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(OPCPortfolioItem)