import React, { Component } from 'react'
import HighchartsReact from 'highcharts-react-official'
import * as HighchartsMore from 'highcharts/js/highcharts-more'
import Highcharts from 'highcharts';
import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider
} from '@material-ui/core';

import styles from './styles/styles.js';
import NoPrint from '../../../components/Print/NoPrint'

HighchartsMore(Highcharts);
// Highcharts.setOptions({
//     plotOptions: {
//         series: {
//             animation: false
//         }
//     }
// });

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2E2E2E',
    color: theme.palette.common.white
  }
}))(TableCell);

class GraphicWaterfall extends Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      colors: [
        '#bdbdbd',
        '#767676',
        '#C0D2FC',
        '#F4645C',
        '#E62C20',
        '#4C65A7',
        '#B414DC',
        '#FCDC34',
        '#FC9018',
        '#0A70D2'
      ]
    }

    this.chartWillRender = this.chartWillRender.bind(this);
    // this.chartDidRender = this.chartDidRender.bind(this);
  }

  componentDidMount() {
    this.props.getSettings(this.getSettings.bind(this));

    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }

  // chartDidRender (state, updating, loading) {
  //   let postProcessDecorators = this.props.graphic.postProcessDecorators;
  //
  //   if(postProcessDecorators) {
  //
  //     setTimeout(() => {
  //       if(!updating && !loading) {
  //         setTimeout(() => {
  //           postProcessDecorators.map(decorator_json => {
  //             const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
  //             const result = decorator(state);
  //
  //             state = result;
  //           });
  //         }, 3000);
  //       } else {
  //         this.chartDidRender(state, updating, loading);
  //       }
  //     }, 200)
  //   }
  // }

  chartWillRender(state) {
    // let preProcessDecorators = this.props.graphic.preProcessDecorators;
    //
    // if(preProcessDecorators) {
    //   preProcessDecorators.map(decorator_json => {
    //     const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
    //     const result = decorator(state);
    //
    //     state = result;
    //   });
    // }
  }

  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = checkbox => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);
  };

  getSettings() {
    // let data = BAR_DECORATOR_LIST.map(decorator => (
    //   this.state[`switch${decorator.name}`] ?
    //   {
    //     name: `${decorator.name}`,
    //     params: 'params_post_treatment' in decorator && decorator.params_post_treatment ?
    //               decorator.params_post_treatment(
    //                 Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //               )
    //               : Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //   }
    //   : null
    // )).filter(decorator => decorator);
    //
    // return data;
  }


  caulculateFontSizeHead(grid) {


    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }


  render() {
    const { classes, showTable, showSettings, showHelp, graphic, loading } = this.props;
    const { data } = graphic;

    ;

    // BAR_DECORATOR_LIST.map(decorator => {
    //   if(Object.keys(this.state).indexOf(`${decorator.name}__switch`) == -1) {
    //     let new_switch = new Object();
    //     new_switch[`${decorator.name}__switch`] = false;
    //     this.setState(new_switch);
    //
    //     decorator.params_input_types.map((input, index) => {
    //       if(input.match('checkbox')) {
    //         let new_checkbox = new Object();
    //         new_checkbox[`${decorator.name}_checkbox_${decorator.params_names[index]}`] = false;
    //         this.setState(new_checkbox);
    //       } else if(input.match('text_field')) {
    //         if(input.split('_').pop() == 'number') {
    //           let new_number = new Object();
    //           new_number[`${decorator.name}_text-field-number_${decorator.params_names[index]}`] = 0;
    //           this.setState(new_number);
    //         }
    //       }
    //     });
    //   }
    // });

    var on_render_state = {
      ...this.state,
      data: data
    };

    if (!on_render_state.data) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          Error in API request
        </div>
      )
    }

    this.chartWillRender(on_render_state);

    ;

    const serie_data = (['EXPECTED'].concat(on_render_state.data.kpi).concat(['MEASURED'])).map(column => {
      if (column == 'EXPECTED') {
        return ({
          name: 'Expected',
          y: on_render_state.data.expected_power,
          key: 'expected',
          color: this.state.colors[0],
          unit: on_render_state.data.unit
        });
      } else if (column == 'MEASURED') {
        return ({
          name: 'Measured',
          key: 'measured',
          y: on_render_state.data.measured_power,
          isSum: true,
          color: this.state.colors[0],
          unit: on_render_state.data.unit
        });
      } else {
        if (column && column.name) {
          return ({
            name: column.name,
            y: column.delta_power,
            key: column.name.toLowerCase().replace(/ /g, '_'),
            color: this.state.colors[column.delta_power >= 0 ? 2 : 3],
            real: column.measured,
            goal: column.expected,
            unit: column.unit,
            description: column.name
          })
        } else {
          return ({
            name: '',
            y: '',
            key: '',
            color: '',
            real: '',
            goal: '',
            unit: '',
            description: ''
          });
        }
      }
    });

    const table_column_formatter = columns => {
      let first = columns.splice(0, 1)[0];
      columns.splice(columns.length - 1, 0, first);
      return columns;
    };

    const cross_headers = [
      'Power (MW Avg)',
      'Energy (MWh)',
      'KPI Expected',
      'KPI Measured',
      'KPI Delta'
    ];


    const tableData = {
      'headers': table_column_formatter(serie_data.map(bar => bar.name)),
      'cross_headers': cross_headers,
      'rows': on_render_state.data.kpi
        ? [
          on_render_state.data.kpi.map(kpi => kpi.delta_power ? kpi.delta_power.toFixed(2) : 'No Data')
            .concat([
              on_render_state.data.expected_power ? on_render_state.data.expected_power.toFixed(2) : 'No Data',
              on_render_state.data.measured_power ? on_render_state.data.measured_power.toFixed(2) : 'No Data'
            ]),
          on_render_state.data.kpi.map(kpi => kpi.delta_energy ? kpi.delta_energy.toFixed(2) : 'No Data')
            .concat([
              on_render_state.data.expected_energy ? on_render_state.data.expected_energy.toFixed(2) : 'No Data',
              on_render_state.data.measured_energy ? on_render_state.data.measured_energy.toFixed(2) : 'No Data'
            ]),
          on_render_state.data.kpi.map(kpi => kpi.expected ? kpi.expected.toFixed(2) : 'No Data')
            .concat(['-', '-']),
          on_render_state.data.kpi.map(kpi => kpi.measured ? kpi.measured.toFixed(2) : 'No Data')
            .concat(['-', '-']),
          on_render_state.data.kpi.map(kpi => kpi.measured && kpi.expected ? (kpi.measured - kpi.expected).toFixed(2) : 'No Data')
            .concat(['-', '-'])
        ]
        : []
    };

    const params = {
      colors: on_render_state.colors,
      title: {
        text: null
      },
      chart: {
        type: 'waterfall',
        zoomType: 'xy'
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'Power (MW Avg)'
        },
        labels: {
          formatter: function () {
            return `${this.value.toFixed(0)}`;
          }
        },
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        waterfall: {
          dataLabels: {
            enabled: true,
            y: -20,
            verticalAlign: 'top'
          },
          dashStyle: 'Dash',
          borderWidth: 0,
          lineWidth: 0.5
        }
      },
      tooltip: {
        // enabled: false
        shared: false,
        formatter: function () {
          var text = '';
          if (this.point.name == 'Expected' || this.point.name == 'Measured') {
            text = '<b>' + this.point.name + '</b> <br>' + 'Energy: ' + Highcharts.numberFormat(this.y, 1) + ' MW';
          }
          else {
            text = '<b>' + this.point.name + '</b> <br>' + Highcharts.numberFormat(this.point.real, 1) + ' ' + this.point.unit + ' | ' + Highcharts.numberFormat(this.point.goal, 1) + ' ' + this.point.unit;
          }

          return text;
        }
      },

      exporting: {
        enabled: true,
        menuItems: ['downloadPNG']
      },
      series: [{
        data: serie_data,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y.toFixed(2);
          },
          borderWidth: 0,
          color: 'black',
          style: {
            fontWeight: 'bold',
            align: 'top'
          },
        },
      }]
    }

    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable || showSettings || showHelp ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront} style={{ display: showTable || showSettings || showHelp ? 'none' : undefined }}>
            <HighchartsReact
              containerProps={{
                style: {
                  width: '100%',
                  height: '100%',
                  display: showTable ? 'none' : 'block',
                  marginTop: 30,
                  overflow: 'hidden'
                }
              }}
              ref={this.refChart}
              highcharts={Highcharts}
              // constructorType={'stockChart'}
              options={params}
            />
            {
              this.props.isAnalysis &&
              <Table className={classes.table} style={{ marginTop: 20 }}  >
                <TableHead>
                  <TableRow className={classes.rowHead}>
                    <CustomTableCell colSpan={1}></CustomTableCell>
                    {tableData.headers.map((elm, index) => {
                      if (elm === '0') {
                        return (
                          <CustomTableCell colSpan={1} key={index}>
                            {''}
                          </CustomTableCell>
                        )
                      }

                      return (
                        <CustomTableCell colSpan={1} key={index} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }} >
                          {elm}
                        </CustomTableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.rows.map((row, index) => new Array(tableData.cross_headers[index]).concat(row))
                    .map((row, indexRow) => {
                      return (
                        <TableRow key={indexRow} className={classes.row}>
                          {row.map((cell, indexCell) => {
                            return (
                              <CustomTableCell key={indexCell} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }} >
                                {cell}
                              </CustomTableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>

            }

          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable || showSettings || showHelp ? undefined : 'none' }}>
            {
              showTable ?
                <Table id="pageAnalysis" className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.rowHead}>
                      <CustomTableCell colSpan={1}></CustomTableCell>
                      {tableData.headers.map((elm, index) => {
                        if (elm === '0') {
                          return (
                            <CustomTableCell colSpan={1} key={index}>
                              {''}
                            </CustomTableCell>
                          )
                        }

                        return (
                          <CustomTableCell colSpan={1} key={index} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }} >
                            {elm}
                          </CustomTableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.rows.map((row, index) => new Array(tableData.cross_headers[index]).concat(row))
                      .map((row, indexRow) => {
                        return (
                          <TableRow key={indexRow} className={classes.row}>
                            {row.map((cell, indexCell) => {
                              return (
                                <CustomTableCell
                                  component={indexCell === 0 ? "th" : null}
                                  scope={indexCell === 0 ? "row" : null}
                                  key={indexCell} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid) }} >
                                  {cell}
                                </CustomTableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>

                :

                showSettings ?
                  <List>
                    {
                      // <MenuItem className={classes.applyBtn}>
                      //   <Typography align='center' style={
                      //     {
                      //       width: '100%',
                      //       color: '#ffffff'
                      //     }
                      //   }>Apply Settings</Typography>
                      // </MenuItem>
                    }

                    {
                      //   BAR_DECORATOR_LIST.map((decorator, index) => (
                      //     <div>
                      //       <ListItem
                      //         key={decorator.name}
                      //       >
                      //         <Switch
                      //           checked={this.state[`switch${decorator.name}`]}
                      //           onChange={this.checkboxToggle(`switch${decorator.name}`)}
                      //           value={decorator.name}
                      //         />
                      //         <ListItemText inset primary={`${decorator.name}`} className={classes.liLabel} />
                      //       </ListItem>
                      //       <Collapse in={this.state[`switch${decorator.name}`]} timeout="auto" unmountOnExit>
                      //         <List component="div" disablePadding>
                      //           <ListItem>
                      //             <Grid container spacing={24}>
                      //               {
                      //                 decorator.params_input_types.map((param_type, param_index) => {
                      //                   if(param_type.match('text_field')) {
                      //                     let type = param_type.split('_').pop();
                      //                     let text_field_state = `${decorator.name}_text-field-${type}_${decorator.params_names[param_index]}`;
                      //                     return (
                      //                       <Grid item xs={2} className={classes.liSubItems}>
                      //                         <TextField
                      //                           className={classes.liSubItemsTextArea}
                      //                           label={`${decorator.params_names[param_index]}`}
                      //                           value={this.state[text_field_state]}
                      //                           onChange={this.numberChange(text_field_state)}
                      //                           type={type}
                      //                           InputLabelProps={{
                      //                             shrink: true,
                      //                           }}
                      //                         />
                      //                       </Grid>
                      //                     )
                      //                   } else if(param_type.match('checkbox')) {
                      //                     let checkbox_state = `${decorator.name}_checkbox_${decorator.params_names[param_index]}`;
                      //                     return (
                      //                       <Grid item xs={2} className={classes.liSubItems}>
                      //                         <span>{`${decorator.params_names[param_index]}`}</span>
                      //                         <br/>
                      //                         <Checkbox
                      //                           checked={this.state[checkbox_state]}
                      //                           onChange={this.checkboxToggle(checkbox_state)}
                      //                           value={decorator.params_names[param_index]}
                      //                         />
                      //                       </Grid>
                      //                     )
                      //                   }
                      //                 })
                      //               }
                      //             </Grid>
                      //           </ListItem>
                      //         </List>
                      //       </Collapse>
                      //       <Divider />
                      //     </div >
                      // ))
                    }
                  </List>

                  :

                  showHelp ?

                    <h1 style={{
                      width: '100%',
                      height: 'auto',
                      textAlign: 'center'
                    }}> HELP!!! </h1>

                    :
                    <NoPrint>
                      <h1 style={{
                        width: '100%',
                        height: 'auto',
                        textAlign: 'center'
                      }}>Returning to Chart...</h1>
                    </NoPrint>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicWaterfall);
