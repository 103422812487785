import React, {Component} from 'react'
import Dimensions from 'react-dimensions'
import GridLayout from 'react-grid-layout'
import * as R from 'ramda'

class GridChartComponent extends Component {
  gridLayoutChange = (data) => {
    this.props.gridLayoutChange(data)
  }

  render () {
    const itemsY = this.props.page.widgets.map(widget => widget.position.y)
    const maxY = R.reduce(R.max, -Infinity, itemsY)

    const filterItemsMaxToHeight = this.props.page.widgets.filter(widget => widget.position.y >= maxY)
      .map(widget => widget.position.h)

    const maxH = R.reduce(R.max, -Infinity, filterItemsMaxToHeight)

    const rowHeight = ((this.props.containerHeight - 120) / (maxY + maxH))

    return (
      <GridLayout
        // className='layout'
        cols={6}
        rowHeight={rowHeight}
        width={this.props.containerWidth}
        autoSize={true}
        onLayoutChange={this.gridLayoutChange.bind(this)}
        compactType={'horizontal'}
        isDraggable={false}
        isResizable={false}
      >
        {this.props.page.widgets.map(widget => {
          return (
            <div
              key={widget.id}
              data-grid={widget.position}
            >
              <div
                style={{
                  backgroundImage: `url(${widget.s3_image_url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                  backgroundPosition: 'center',
                  height: '100%',
                  width: '100%'
                }}
              />
            </div>
          )
        })}
      </GridLayout>
    )
  }
}

export default Dimensions()(GridChartComponent)
