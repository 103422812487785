import React from 'react'
import grey from '@material-ui/core/colors/grey'
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import {
  MenuItem,
  Icon
} from '@material-ui/core'

class FilterThreeItemComponent extends React.Component {


  onClick (item){

    if(!this.props.device && item.data.length > 0) {
      return;
    }

    this.props.changeItemChecked(item.hash)

  }


  render () {
    const {
      item,
      itemArray,
      parentLeft,
      parentTop,
      MENU_WIDTH
    } = this.props

    const left = (parentLeft - MENU_WIDTH)
    const top = parentTop
    


    return (
      <div
        style={{
          // border: `1px solid ${grey['200']}`
        }}
      >
        
        
        <MenuItem
          onMouseEnter={() => this.props.showExtensionMenu(item.hash, itemArray)}
          onClick={() => this.onClick(item)}
        >


            {
                 
                 !this.props.device 
                  ? 
                  
                  item.data.length == 0 ?
                  
                    item.checked
                      ?
                      (
                        <Icon>radio_button_checked</Icon>
                      )
                      :                        
                      (
                        <Icon>
                          radio_button_unchecked
                        </Icon>
                      )
                    : null
                 :
                 item.checked
                  ?
                  (
                    <Icon>
                      check_box
                    </Icon>
                  )
                  :
                  item.indeterminate
                  ?
                  (
                    <IndeterminateCheckBoxIcon/>                  
                  )
                  :                        
                  (
                    <Icon>
                      check_box_outline_blank
                    </Icon>
                  )
              }
          {item.name} 
        </MenuItem>
        {
          <div
            id={item.hash}
            style={{
              display: 'none',
              backgroundColor: 'white',
              position: 'fixed',
              border: `1px solid ${grey['200']}`,
              width: MENU_WIDTH,
              maxHeight: this.props.bodyHeight - top,
              left,
              top,
              overflow: 'auto'
            }}
          >
            {
              this.props.renderDataMenu(item.data, left, top, true)
            }
          </div>
        }
      </div>
    )
  }
}

export default FilterThreeItemComponent
