import {store} from '../store/ProviderStore'
import * as R from 'ramda'
import {Auth} from 'aws-amplify'

export const updateApplicationEditModeManagement = (editMode) => {
  return dispatch => {
    const {Application} = store.getState()
    const {management} = Application
  }
}

export const newManagementPage = () => {
  return dispatch => {
    const {Application} = store.getState()
    const {management} = Application
  }
}

export const CognitoChangePassword = (old_pass, new_pass) => {
  return dispatch => (
    Auth.currentAuthenticatedUser()
      .then(user => {
          return Auth.changePassword(user, old_pass, new_pass);
      })
      .then(data => {
        dispatch({
          type: 'REDUCER_CHANGE_PASSWORD',
          payload: data
        })
        return {result: true}
      })
      .catch(err => {
        return {result: false, details: err}
      })
  )
}
