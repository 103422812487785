const styles = theme => ({
    root: {
        padding: '1vh 1vw 1vh 1vw',
        display: 'block',
        overflow: 'auto',
        margin: '2vh 0 0 0',
        height: '97%',
        width: '98%'
    },
    content: {
        display: 'inline-flex',
    }, 
    selectorContent: {
        width: '25%',
        maxWidth: '25%',
        minHeight: '50%',
        maxHeight: '50%',
        flex: 1,
        overflowX: 'hidden',
        overflowY: 'hidden',
        margin: '0 1vw 0 1vw'
    },
    downloadData: {
        background: '#ff3300',
        color: 'white',
        margin: '0 1vw 0 0',
        '&:hover': {
            background: '#ff5d18'
        }
    },
    actionsArea: {
        width: '100%',
        textAlign: 'right',
        margin: '3vh 0 0 0',
        position: 'sticky',
        top: 0,
        zIndex: 2
    },
    divDisabled: {
        pointerEvents: 'none',
        opacity: '0.8'
    }
});

export default styles;