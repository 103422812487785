import React from 'react';
import useContainer from './Container';
import { IconButton, Icon, CircularProgress } from '@material-ui/core';

export default uncheckedProps => {

    const { checkedProps, loading } = useContainer(uncheckedProps);

    return loading ?
        <CircularProgress size={24} />
        :
        <IconButton {...checkedProps}>
            <Icon>save_alt</Icon>
        </IconButton>;
};