import React from 'react'
import GridLayout from 'react-grid-layout'
import {withStyles, Icon} from '@material-ui/core'
import * as R from 'ramda'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment-timezone'
import MonitorSubpark from '../MonitorSubpark/MonitorSubpark';

const GRID_WIDTH_PADDING = 10
const WIDGET_HEADER_HEIGHT = 40

const WIDGET_HEIGHT = 2
const WIDGET_WIDTH = 2

const HEADER_LABEL = 54

const styles = {
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  tableTh: {
    color: grey['500'],
    textAlign: 'center',
    padding: '8px'
  },
  tableTd: {
    textAlign: 'center',
    padding: '8px',
    color: grey['400']
  }
}

class MonitorListSubpark extends React.Component {
  constructor (props) {
    super (props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    this.state = {
      gridWidth: elBody.offsetWidth - (GRID_WIDTH_PADDING * 2)
    }
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName('body')[0]

    this.setState({
      gridWidth: body.offsetWidth - (GRID_WIDTH_PADDING * 2)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizePageWatch)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizePageWatch)
  }

  calcPosition = (index) => {
    if (index === 0) {
      return {
        x: 0,
        y: 0,
        w: WIDGET_WIDTH,
        h: WIDGET_HEIGHT
      }
    }

    if (index % 2 === 0) {
      return {
        x: 0,
        y: WIDGET_HEIGHT * index,
        w: WIDGET_WIDTH,
        h: WIDGET_HEIGHT
      }
    } else {
      return {
        x: WIDGET_WIDTH,
        y: 0,
        w: WIDGET_WIDTH,
        h: WIDGET_HEIGHT
      }
    }
  }

  getItemsGlobal = (parks) => {
    return parks.map(park => {
      return {
        subpark_id: park.id,
        subpark_name: park.park,
        devices: park.subparks.map(subpark => {
          const {EVENT_DESCRIPTION, EVENT_DIVIDER} = this.props
          const label = `${EVENT_DESCRIPTION}${EVENT_DIVIDER}${park.id}${EVENT_DIVIDER}${subpark.id}`
          const dataProps = this.props[label] ? this.props[label] : null

          if (!dataProps)
            return null

          const powerValues = dataProps.data.map(device => {
            return device.data.power
          })

          const windSpeedValues = dataProps.data.map(device => {
            return device.data.wind_speed
          })

          const ratedPowerValues = dataProps.data.map(device => {
            return device.rated_power
          })

          const sampleTimeValues = dataProps.data.map(device => {
            return device.data.sample_time
          })

          const statusValues = dataProps.data.map(device => {
            return device.data.status
          })

          const stopped = dataProps.data.map(device => {
            return device.data.power <= 0 && device.data.wind_speed > 3
          })
          .filter(item => item)
          .length
          
          const running = dataProps.data.map(device => {
            return device.data.power <= 0 && device.data.wind_speed > 3
          })
          .filter(item => !item)
          .length

          return {
            device_id: dataProps.subpark_id,
            device_name: dataProps.subpark_name,
            power: R.sum(powerValues),
            wind_speed: R.median(windSpeedValues),
            rated_power: R.sum(ratedPowerValues),
            sample_time: sampleTimeValues[0],
            status: statusValues[0],
            stopped,
            running
          }
        })
        .filter(item => item)
      }
    })
  }

  getItemsDetailed = (parks) => {
    return R.flatten(
      parks.map((park) => {
        const subparks =  park.subparks.map((subpark, indexSubpark) => {
          const {EVENT_DESCRIPTION, EVENT_DIVIDER} = this.props

          const label = `${EVENT_DESCRIPTION}${EVENT_DIVIDER}${park.id}${EVENT_DIVIDER}${subpark.id}`

          const dataProps = this.props[label] ? this.props[label] : null

          if (!dataProps) {
            return false
          }

          return {
            subpark_id: dataProps.subpark_id,
            subpark_name: dataProps.subpark_name,
            devices: dataProps.data.map((device) => ({
              ...device.data,
              ...R.omit(['data'], device),
              stopped: false,
              running: false
            }))
          }
        })
        .filter(item => item)

        return subparks.length > 0 ? subparks : false
      })
      .filter(item => item)
    )
  }

  render () {
    const {classes} = this.props

    const globalComponents = this.getItemsGlobal(this.props.parks_info)
    const detailedComponents = this.getItemsDetailed(this.props.parks_info)
    
    const items = [
      ...globalComponents,
      ...detailedComponents
    ]

    const prePowerGlobal = R.sum(R.flatten(globalComponents.map(item => item.devices.map(subitem => subitem.power))))
    const powerGlobal = Number(prePowerGlobal) === NaN ? 0 : Number(prePowerGlobal).toFixed(1)

    const preWindSpeedGlobal = R.median(R.flatten(globalComponents.map(item => item.devices.map(subitem => subitem.wind_speed))))
    const windSpeedGlobal = preWindSpeedGlobal === NaN
      ? 0
      : Number(preWindSpeedGlobal) === NaN
        ? 0
        : Number(preWindSpeedGlobal).toFixed(1)

    const stoppedGlobal = R.sum(R.flatten(globalComponents.map(item => item.devices.map(subitem => subitem.stopped))))

    const runningGlobal = R.sum(R.flatten(globalComponents.map(item => item.devices.map(subitem => subitem.running))))
    
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          color: 'white',
          overflow: 'auto'
        }}
      >
        <div
          style={{
            height: HEADER_LABEL,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          {/* <div
            style={{
              flex: 1
            }}
          /> */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              // paddingLeft: 15
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginLeft: 20
              }}
            >
              {/* <Icon style={{fontSize:18, color:'red'}} >stop</Icon> */}
              Turbines
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 900
                }}
              >
                {stoppedGlobal + runningGlobal}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginLeft: 20
              }}
            >
              <Icon style={{fontSize:18, color:'red'}} >stop</Icon>
              Stopped
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 900
                }}
              >
                {stoppedGlobal}
              </span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginLeft: 20
              }}
            >
              <Icon style={{fontSize:18, color:'#40b728'}} >play_arrow</Icon>
              Running
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 900
                }}
              >
                {runningGlobal}
              </span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginLeft: 20
              }}
            >
              Power (kW)
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 900
                }}
              >
                {powerGlobal}
              </span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginLeft: 20
              }}
            >
              Wind Speed (m/s)
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 900
                }}
              >
                {windSpeedGlobal}
              </span>
            </div>

          </div>
        </div>
        <div
          style={{
            height: `calc(100% - ${HEADER_LABEL}px)`,
            width: '100%'
          }}
        >
          <GridLayout
            className={classes.gridElement}
            width={this.state.gridWidth}
            cols={4}
            rowHeight={200}
            compactType={'horizontal'}
            items={R.flatten(this.props.parks_info.map(park => park.subparks)).length}
          >
            {
              items.map((item, indexItem) => {
                return (
                  <div
                    key={indexItem}
                    data-grid={this.calcPosition(indexItem)}
                  >
                    <MonitorSubpark
                      data={item}
                    />
                  </div>
                )
              })
            }
          </GridLayout>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(MonitorListSubpark)
