import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as HighchartsMore from 'highcharts/js/highcharts-more'
import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider
} from '@material-ui/core';
import static_data from './data.json';

import styles from './styles/styles.js';

import NoPrint from '../../../components/Print/NoPrint'

HighchartsMore(Highcharts);
Highcharts.setOptions({
  plotOptions: {
    series: {
      animation: false
    }
  }
});

class GraphicAreaWithZones extends Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      colors: [
        '#bdbdbd',
        '#767676',
        '#C0D2FC',
        '#F4645C',
        '#E62C20',
        '#4C65A7',
        '#B414DC',
        '#FCDC34',
        '#FC9018',
        '#0A70D2'
      ]
    }

    this.chartWillRender = this.chartWillRender.bind(this);

  }

  componentDidMount() {
    this.props.getSettings(this.getSettings.bind(this));

    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }

  chartWillRender(state) {

  }

  getSettings() {

  }


  caulculateFontSizeHead(grid) {

    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }


  render() {
    const { classes, showTable, showSettings, graphic, loading } = this.props;
    const data = static_data;

    var on_render_state = {
      ...this.state,
      data: data
    };

    if (!on_render_state.data) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          Error in API request
        </div>
      )
    }

    this.chartWillRender(on_render_state);

    const serie_data = (['Flow'].concat(on_render_state.data.kpi).concat(['Goal'])).map(column => {
      if (column == 'Flow') {
        return ({
          name: 'Expected',
          y: on_render_state.data.expected_power,
          key: 'expected',
          color: this.state.colors[0],
          unit: on_render_state.data.unit
        });
      } else if (column == 'Goal') {
        return ({
          name: 'Measured',
          key: 'measured',
          y: on_render_state.data.measured_power,
          isSum: true,
          color: this.state.colors[0],
          unit: on_render_state.data.unit
        });
      } else {
        if (column && column.name) {
          return ({
            name: column.name,
            y: column.delta_power,
            key: column.name.toLowerCase().replace(/ /g, '_'),
            color: this.state.colors[column.delta_power >= 0 ? 2 : 3],
            real: column.measured,
            goal: column.expected,
            unit: column.unit,
            description: column.name
          })
        } else {
          return ({
            name: '',
            y: '',
            key: '',
            color: '',
            real: '',
            goal: '',
            unit: '',
            description: ''
          });
        }
      }
    });


    const params = {

      colors: on_render_state.colors,

      exporting: {
        enabled: true
      },

      title: {
        text: null
      },

      chart: {
        type: 'areaspline',
        zoomType: 'xy'
      },

      credits: {
        enabled: false
      },

      xAxis: {
        type: 'category'
      },

      yAxis: {
        title: {
          text: ''
        },
        labels: {
          formatter: function () {
            return `${this.value.toFixed(0)}`;
          }
        },
      },

      legend: {
        enabled: false
      },

      tooltip: {
        shared: false,
      },

      series: data.series
    }

    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable || showSettings ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront} style={{ display: showTable || showSettings ? 'none' : undefined }}>
            <HighchartsReact
              containerProps={{
                style: {
                  width: '100%',
                  height: '100%',
                  marginTop: 30,
                  overflow: 'hidden'
                }
              }}
              ref={this.refChart}
              highcharts={Highcharts}
              options={params}
            />
          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable || showSettings ? undefined : 'none' }}>
            {

              /*
              showTable ?
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={1}></TableCell>
                      {tableData.headers.map((elm, index) => {
                        if (elm === '0') {
                          return (
                            <TableCell colSpan={1} key={index}>
                              {''}
                            </TableCell>
                          )
                        }

                        return (
                          <TableCell colSpan={1} key={index} style={{fontSize:this.caulculateFontSizeHead(graphic.grid),  padding: '1px 2px 1px'}} >
                            {elm}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.rows.map((row, index) => new Array(tableData.cross_headers[index]).concat(row))
                      .map((row, indexRow) => {
                        return (
                          <TableRow key={indexRow}>
                            {row.map((cell, indexCell) => {
                              return (
                                <TableCell key={indexCell} style={{fontSize:this.caulculateFontSizeHead(graphic.grid),  padding: '1px 2px 1px'}} >
                                  {cell}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>

              :

              showSettings ?
                <List>
                  {
                   
                  }

                  {
                   
                  }
                </List>

              :

                <h1 style={{
                  width: '100%',
                  height: 'auto',
                  textAlign: 'center'
                }}>Returning to Chart...</h1>


                */
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicAreaWithZones);
