export const objectArraysAreEqual = (curr, prev) => {
    if(curr != null && prev != null && curr.length === prev.length && curr.length > 0) {
        const comparisons = curr.map((value, index) => (
            JSON.stringify(value, Object.keys(value).sort()) === JSON.stringify(prev[index], Object.keys(prev[index]).sort())
        ));
        const res = comparisons.reduce((acc, curr) => (acc && curr));
        return res;
    } else if (curr != null && prev != null && curr.length === 0) {
        return true;
    } else {
        return false;
    }
}