import React, { Component } from 'react'
import FilterThreeComponent from '../../../components/FilterThreeComponent/FilterThreeComponent'
import grey from '@material-ui/core/colors/grey'
import {toast} from 'react-toastify';

const DEVICE_PAGE_TYPE = 2;
const PARK_PAGE_TYPE = 3;
const SUBPARK_PAGE_TYPE = 1;
const FILTER_TYPE_WTG_ENVELOPE = 'FILTER_TYPE_WTG_ENVELOPE';
const FILTER_TYPE_WTG = 'FILTER_TYPE_WTG';


class View extends Component {



    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            dataMenu:[],
            loading: false,
        }

    }


    componentDidMount(){
        const {
            parks_info
        } = this.props;


        if(parks_info) {
            try {
                const dataMenu = this.treatedParksData(parks_info);
                this.setState({ dataMenu: dataMenu });
            } catch(err) {
                console.log('Ignoring ERROR on mounting devices selector ', err);
            }       
        }
    }
    
    subparksSelecteds = (dataMenu, devicePage = false) => {

        let filter = {}
        let subparks_list = [];
        let subparks_list_ids = [];
        let device_list = [];
        let device_list_ids = [];
        let subparks_list_all = [];
        
        dataMenu.map(park => {

            if(park.checked || park.indeterminate || devicePage ){

                return park.data.map(subpark => {

                    if(subpark.checked ){
                        subparks_list.push(subpark)
                        subparks_list_ids.push(subpark.id)

                    }

                    if(devicePage){
                        subparks_list_all.push(subpark)

                    }
                    
                    return null

                })

            }

            return null;


        })

        
      
        
        if(devicePage){
            
            device_list = []
            device_list_ids = [];
            subparks_list = []
            subparks_list_ids = [];

            subparks_list_all.map(subpark => {
                subpark.data.map(device => {
                    
                    
                    if(device.checked && device_list.length == 0){
                        device_list.push(device)
                        device_list_ids.push(device.id)
                        subparks_list.push(subpark.id)
                        subparks_list_ids.push(subpark.id)


                    }
                })
            })



        }else{


            device_list = []
            device_list_ids = [];

            subparks_list.map(subpark => {
                subpark.data.map(device => {
                    
                    if(device.checked || !devicePage){
                        device_list.push(device)
                        device_list_ids.push(device.id)
    
                    }
                })
            })

        }


        filter.device_list = device_list_ids;
        filter.subparks_list = subparks_list_ids

        return filter;


    }

    treatedParksData = (parks, pages = {}) => {

        const { Dashboard, page } = this.props;

        let devices_list = Dashboard.DeviceFilter.devices ? Dashboard.DeviceFilter.devices : [];
        let subparks_list = Dashboard.DeviceFilter.subparks ?  Dashboard.DeviceFilter.subparks : [];


        return parks.map(park => {

          let selectedParks = [];

          const subparks = Object.values(park.subparks).map(subpark => {


            if( Array.isArray(subparks_list)  && subparks_list.indexOf(subpark.id) != -1 || subparks_list === subpark.id){
                selectedParks.push(subpark.id)
            }
              
            const wtgs = Object.values(subpark.devices).map(wtg => {
    
            return {
                id: wtg.id,
                checked: Array.isArray(devices_list) && devices_list.indexOf(wtg.id) != -1  || devices_list === wtg.id ?  true : false,
                name: wtg.name,
                data: []
              }
            }) 
    
            return {
              checked: Array.isArray(subparks_list)  && subparks_list.indexOf(subpark.id) != -1 || subparks_list === subpark.id ?  true : false,
              id: subpark.id,
              name: subpark.name,
              data: wtgs
            }
          })

          return {
            checked: selectedParks.length == Object.values(park.subparks).length ? true : false,
            indeterminate: selectedParks.length > 0 ? true : false,
            id: park.id,
            name: park.name,
            data: subparks
          }
        })
    
      }


    componentDidUpdate(prevProps, prevState) {
        const {
            parks_info
        } = this.props;


        if(prevProps.parks_info !== parks_info){
            let dataMenu = this.treatedParksData(parks_info)
            this.setState({ dataMenu: dataMenu })           
        }
     }
    


    closeMenu = () => {
        const { page, dataMenu, customCloseAction } = this.props;
 
        if(!customCloseAction) {
            if(page.page_type == SUBPARK_PAGE_TYPE || page.type == SUBPARK_PAGE_TYPE || !(page.page_type || page.type) ){


                let filter = this.subparksSelecteds(this.state.dataMenu);
                filter.data_range = {}
            

                if(filter.device_list.length > 0){

                    this.props.updateEnvelopeFilter(filter.device_list, filter.subparks_list )
                    this.props.updateFilters(page);
        
                }else{

                    toast.error('Filter will not be executed, please select at least one device!', {
                        position: 'top-right',
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                    });
                }

                
                

            }

            if(page.page_type == PARK_PAGE_TYPE){

                let filter = this.subparksSelecteds(this.state.dataMenu, true);
        
                filter.data_range = {}

                if(filter.device_list.length > 0){

                    this.props.updateEnvelopeFilter(filter.device_list, filter.subparks_list )
                    this.props.updateFilters(page);
        
                }else{

                    toast.error('Filter will not be executed, please select at least one device!', {
                        position: 'top-right',
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                    });
                }
        
            }
            
            if(page.type == FILTER_TYPE_WTG || page.page_type == DEVICE_PAGE_TYPE || this.props.page && this.props.page.type && this.props.page.type == FILTER_TYPE_WTG_ENVELOPE ){

                let filter = this.subparksSelecteds(this.state.dataMenu, true);
                filter.data_range = {}

                if(filter.device_list.length > 0){

                    this.props.updateEnvelopeFilter(filter.device_list, filter.subparks_list )
                    this.props.updateFilters(page);
        
                }else{

                    toast.error('Filter will not be executed, please select at least one device!', {
                        position: 'top-right',
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true
                    });
                }

            }
        } else {
            const devicesInfo = this.subparksSelecteds(this.state.dataMenu);
            customCloseAction(devicesInfo);
        }
    

        this.setState({ showMenu: false })
      }


      shouldComponentUpdate(nextProps, nextState){

        if(this.props != nextProps || this.state != nextState){
            return true;
        }
        
        return false;

    }


    changeDataFilterThree = (data, event) => {

        let radio = false;
        let parksUpdateds = [];

        if (!radio) {

            parksUpdateds = data.map(element => {

                let childsSelecteds = []

                element.data && element.data.map(child => {

                    if (child.checked) {

                        childsSelecteds.push(child)

                    }

                    return child.data && child.data.map(granChild => {
                        return granChild;
                    })

                })


                if (childsSelecteds.length > 0) {
                    element['indeterminate'] = true;
                    element['checked'] = false;

                } else {
                    element['indeterminate'] = false;

                }


                if (childsSelecteds.length == element.data.length) {
                    element['checked'] = true;
                }


                return element;

            })
        }

        this.setState(
            state => ({
                dataMenu: data,
            })
        )
    }


    render() {

        const { children, Dashboard, devices } = this.props;
        // console.log('Selector props ', this.props, 'Selector state ', this.state)
        return (
            <FilterThreeComponent
                open={this.state.showMenu}
                filterFixed={this.props.filterFixed}
                device={this.props.page && this.props.page.type && this.props.page.type == FILTER_TYPE_WTG_ENVELOPE || this.props.page.type == FILTER_TYPE_WTG || this.props.page && this.props.page.page_type == DEVICE_PAGE_TYPE || this.props.page && this.props.page.page_type == PARK_PAGE_TYPE  ? false : true}
                openMenu={() => this.setState({ showMenu: true })}
                closeMenu={() => this.closeMenu()}
                label={
                    this.props.page && this.props.page.type && this.props.page.type == FILTER_TYPE_WTG_ENVELOPE || this.props.page.type == FILTER_TYPE_WTG || this.props.page.type == FILTER_TYPE_WTG || this.props.page && this.props.page.page_type == DEVICE_PAGE_TYPE || this.props.page && this.props.page.page_type == PARK_PAGE_TYPE && Dashboard.DeviceFilter.devices && Dashboard.DeviceFilter.devices.length > 0 ? devices[Dashboard.DeviceFilter.devices && Dashboard.DeviceFilter.devices[0] ? Dashboard.DeviceFilter.devices[0] : Dashboard.DeviceFilter.devices ] && devices[Dashboard.DeviceFilter.devices && Dashboard.DeviceFilter.devices[0] ? Dashboard.DeviceFilter.devices[0] : Dashboard.DeviceFilter.devices ].name  :   Dashboard.DeviceFilter.subparks && Dashboard.DeviceFilter.subparks.length ? Dashboard.DeviceFilter.subparks.length+" Subparks" : " 0 Subparks"
                }
                level={this.props.page && this.props.page.type && this.props.page.type == FILTER_TYPE_WTG_ENVELOPE || this.props.page.type == FILTER_TYPE_WTG || this.props.page && this.props.page.page_type == DEVICE_PAGE_TYPE || this.props.page && this.props.page.page_type == PARK_PAGE_TYPE ? 3 : 2}
                data={this.state.dataMenu}
                changeData={this.changeDataFilterThree.bind(this)}

            />
        )
    }
}

export default View