import React from 'react'
import { Route } from 'react-router-dom'

import OPCPortfolioPage from './pages/OPCPortfolioPage/OPCPortfolioPage';
import OPCMonitorPage from './pages/OPCMonitorPage/OPCMonitorPage';
import OPCMapPage from './pages/OPCMapPage/OPCMapPage';
import OPCOverviewPage from './pages/OPCOverview';
import { ROUTE_MAIN_OPC_MONITOR, ROUTE_MAIN_OPC_PORTFOLIO, ROUTE_MAIN_OPC_MAP, ROUTE_MAIN_OPC_OVERVIEW } from '../../../../constants/route-constant';


class OPCPage extends React.Component {
  render() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          path={ROUTE_MAIN_OPC_MONITOR}
          render={props => (<OPCMonitorPage {...props} />)}
        />

        <Route
          path={ROUTE_MAIN_OPC_PORTFOLIO}
          render={props => (<OPCPortfolioPage {...props} />)}
        />

        <Route
          path={ROUTE_MAIN_OPC_MAP}
          render={props => (<OPCMapPage {...props} />)}
        />

        <Route
          path={ROUTE_MAIN_OPC_OVERVIEW}
          render={() => (<OPCOverviewPage {...this.props} />)}
        />
      </div>
    )
  }
}

export default OPCPage
