import {combineReducers} from 'redux'

import ManagementReducer from '../pages/ManagementPage/reducers/ManagementReducer';
import PredictionReducer from '../pages/PredictionPage/reducers/PredictionReducer';
import DeviceReducer from '../pages/DevicePage/reducers/DeviceReducer';
import EnvelopeReducer from '../pages/EnvelopePage/reducers/EnvelopeReducer';
// import PageReducer from '../pages/AlaemsPage/reducers/PageReducer';
import AlarmReducer from '../pages/AlarmsPage/reducers/AlarmsReducer';
import DowntimeReducer from '../pages/AlarmsPage/reducers/DowntimeReducer';
import DelfosPreviousManagementReducer from '../pages/Delfos-1-0/ManagementPage/reducers/ManagementReducer';
import DelfosPreviousOperationalReducer from '../pages/Delfos-1-0/OperationalPage/reducers/OperationalReducer';
import DelfosPreviousReliabilityReducer from '../pages/Delfos-1-0/ReliabilityPage/reducers/ReliabilityReducer';
import DelfosPreviousPerformanceReducer from '../pages/Delfos-1-0/PerformancePage/reducers/PerformanceReducer';
import DateFilterReducer from './globalFilter/DateFilterReducer';
import DeviceFilterReducer from './globalFilter/DeviceFilterReducer';
import EnvelopeFilterReducer from './globalFilter/EnvelopeFilterReducer';
import AssetsManagementReducer from '../pages/AssetsManagement/reducers/AssignToDashboardReducer';


export default combineReducers({
  Management: ManagementReducer,
  Prediction: PredictionReducer,
  Device: DeviceReducer,
  Envelope: EnvelopeReducer,
  Alarm: AlarmReducer,
  Downtime: DowntimeReducer,
  DateFilter:DateFilterReducer,
  DeviceFilter:DeviceFilterReducer,
  EnvelopeFilter:EnvelopeFilterReducer,
  DelfosPreviousManagement: DelfosPreviousManagementReducer,
  DelfosPreviousOperational: DelfosPreviousOperationalReducer,
  DelfosPreviousReliability: DelfosPreviousReliabilityReducer,
  DelfosPreviousPerformance: DelfosPreviousPerformanceReducer,
  AssetsManagement: AssetsManagementReducer
})