/* eslint-disable react/no-multi-comp */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

class ConfirmationDialogRaw extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: props.value
    };
  }

  // TODO
  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleEntering = () => {
    // this.radioGroupRef.focus();
  };

  handleCancel = () => {
    this.props.onClose(this.props.value);
  };

  handleNo = () => {
    this.props.logout(true);
    // localStorage.clear();
    this.props.onClose(this.state.value);
  };

  handleOk = () => {
    // localStorage.clear();
    this.props.logout()
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value, ...other } = this.props;

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">{this.props.message}</DialogTitle>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            No
          </Button>
          {
          // <Button onClick={this.handleNo}>
          //   No
          // </Button>
          }
          <Button variant="contained" onClick={this.handleOk.bind(this)} style={{backgroundColor:"#e65100", color:"#fff"}}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func,
  value: PropTypes.string
};

const styles = theme => ({
  // root: {
  //   width: "100%",
  //   maxWidth: 360,
  //   backgroundColor: theme.palette.background.paper
  // },
  // paper: {
  //   width: "80%",
  //   maxHeight: 435
  // }
});

export default withStyles(styles)(ConfirmationDialogRaw);
