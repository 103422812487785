export const FILTER_TYPE_PARKS = 'parks'
export const FILTER_TYPE_SUBPARKS = 'subparks'
export const FILTER_TYPE_WTGS = 'wtgs'
export const FILTER_TYPE_DATE_RANGE = 'date_range'
export const FILTER_TYPE_ENVELOPES = 'envelopes'

export default [
  FILTER_TYPE_PARKS,
  FILTER_TYPE_SUBPARKS,
  FILTER_TYPE_WTGS,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_ENVELOPES
]
