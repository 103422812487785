import React, { Component } from 'react'
import {
  Paper,
  Typography,
  withStyles,
  IconButton,
  CircularProgress,
  Chip,
  Avatar,
  Icon
} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import SettingsIcon from '@material-ui/icons/Settings'
import HelpIcon from '@material-ui/icons/Help'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import FilterListIcon from '@material-ui/icons/FilterList'
import TableChartIcon from '@material-ui/icons/TableChart'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import { WIDGET_HEADER_HEIGHT } from '../../constants/widget-constant'
import Tooltip from '@material-ui/core/Tooltip'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as R from 'ramda'
import { DateRange } from 'react-date-range'
import moment from 'moment-timezone'
import ExportContainer from '../../components/Container/ExportContainer'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { toggleWidgetGlobalFilter } from '../../modules/Dashboard/pages/ManagementPage/actions/UpdateFilterAction'
import {
  getDataWidget,
  setPostProcessDecoratorsWidget,
  setPreProcessDecoratorsWidget,
  setFilterLocalInWidget

} from '../../actions/WidgetPageActions';
import graphTypes from '../../constants/graph-types';
import FilterContent from '../Filter/FilterContent';
import { CSVDownload } from "react-csv";
import XLSX from 'xlsx';

import html2canvas from 'html2canvas';
import { toast } from 'react-toastify'

import NoPrint from '../Print/NoPrint'
import { withTranslation } from 'react-i18next';

const className = 'class-name-for-style',
  filename = 'Excel-file',
  fields = {
    "index": "Index",
    "guid": "GUID"
  },
  style = {
    padding: "5px"
  },
  data = [
    { index: 0, guid: 'asdf231234' },
    { index: 1, guid: 'wetr2343af' }
  ];


const styles = theme => {
  return {
    root: {
      flexGrow: 1,
    },
    paperElement: {
      width: `calc(100% - 4px)`,
      height: `calc(100% - 2px)`,
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column'
    },
    paperHeader: {
      paddingLeft: '10px',
      // minHeight: `${WIDGET_HEADER_HEIGHT}px`,
      borderBottom: `1px solid ${grey['200']}`,
      display: 'flex',
      alignItems: 'center'
    },
    header: {
      padding: 10,
      display: 'flex',
      justifyContent: 'space-between'
    },
    headerTitle: {
      paddingTop: 10,
      // textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
      // overflowX: 'hidden'
    },
    actionsButtons: {
    },
    paperBody: {
      // height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
      width: '100%',
      height: '100%',
      borderRadius: 5,
      overflowY: 'auto',
      flex: 1
    },
    dialogSettingsContent: {
      minHeight: 50,
      minWidth: 100,
      padding: theme.spacing.unit
    },
    listSubparks: {
      maxHeight: '400px',
      overflow: 'auto'
    },
    listSubparksHeader: {
      backgroundColor: 'white'
    }
  }
}

const LightTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: 16,
  },
}))(Tooltip);

const DATEPICKER_DATE_KEY = 'zljkhclaksdjfhlajshdfljkasdhflkjashfljkashdflkjasdhfljkasfh'

class GraphicWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      table: this.props.showTable ? this.props.showTable : false,

      help: false,

      dialogSettings: false,
      filterOpen: false,
      showExport: false,
      filterAnchorEl: null,
      filterPlacement: null,
      loading: false,
      settingsMenuAnchor: null,
      settings: false,
      getSettings: null,
      closeDialogExportOverview: null,
      closeDialogExportOverviewExcel: null,
      localFilter: false,
      anchorLocalFilter: null,

      startDate: moment().subtract(10, 'days').toDate(),
      endDate: moment().toDate(),

      retries: 0,

      refChart: null
    }

    this.timeout = null;
  }

  filterToggle = event => {
    const { currentTarget } = event
    this.setState(
      state => {
        return {
          filterAnchorEl: currentTarget,
          filterOpen: !state.filterOpen
        }
      }
    )
  }




  exportdData = (format, data) => {
    return (<CSVDownload data={data} target="_blank" />)

  }

  closeDialogExportOverviewExcel = (dataValues, names, filename, treated = false) => {

    let values = [];

    if (treated) {


      values = dataValues;

    } else {

      values = dataValues.map((data, index) => {
        let value = {}
        names.map((name, Nameindex) => {
          value[name] = data[Nameindex]
        })
        return value;
      })

    }


    let worksheet = XLSX.utils.json_to_sheet(values);
    let new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");

    XLSX.writeFile(new_workbook, filename + '.xlsx')




    this.setState({
      showExport: false
    })
  }

  closeDialogExportOverview = () => {
    this.setState({
      showExport: false
    })
  }

  goExport() {
    this.setState(state => {
      return {
        showExport: !state.showExport
      }
    })
  }

  goFlip() {
    this.setState(state => {
      return {
        table: !state.table
      }
    })
  }




  goFlipHelp() {
    this.setState(state => {
      return {
        help: !state.help
      }
    })
  }

  saveSvg = (svgEl, name) => {
    svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    var svgData = svgEl.outerHTML;
    var preface = '<?xml version="1.0" standalone="no"?>\r\n';
    var svgBlob = new Blob([preface, svgData], { type: "image/svg+xml;charset=utf-8" });
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  downloadImage = async event => {

    const { refChart } = this.state;
    const { current } = refChart || {};
    const { chart } = current || {};
    const { container } = current || {};
    const { renderer } = chart || {};
    const { box: image } = renderer || {};

    window.testChart = refChart;

    const { widget, selectedPage, parks_info, Dashboard } = this.props;

    const graphicType = R.find(R.propEq('type', widget.type))(graphTypes);
    const name = this.props.unique ? '' : widget.titleHeader ? widget.titleHeader : graphicType ? graphicType.description : '' || '';


    if (container) {
      const img = document.createElement('div');
      img.style.padding = '10px';
      img.style.width = 'fit-content';
      img.style.height = 'fit-content';

      const title = document.createElement('h4');
      title.textContent = widget.name || name;

      img.appendChild(title);

      if (selectedPage) {
        const date = document.createElement('h5');
        date.textContent = selectedPage.filter && selectedPage.filter.data_range &&
          `${selectedPage.filter.data_range.startDate.split(' ')[0]} to ${selectedPage.filter.data_range.endDate.split(' ')[0]}`

        img.appendChild(date);

        const allSubparks = R.flatten(parks_info.map(park => park.subparks));

        const filteredSubparks = selectedPage.filter.subparks.map(id => (
          R.find(R.propEq('id', id))(allSubparks))
        );

        const filter = document.createElement('h5');

        if (selectedPage.pageType == 2) {
          filter.textContent = R.find(R.propEq('id', selectedPage.filter.devices[0]))(R.flatten(
            filteredSubparks.map(subpark => subpark.wtgs)
          )).name;
        } else {
          filter.textContent = filteredSubparks.map(subpark => subpark.name).join(', ');
        }
        img.appendChild(filter);
      } else if (Dashboard && Dashboard.DateFilter && Dashboard.DateFilter.data_range) {
        const date = document.createElement('h5');
        date.textContent = `${Dashboard.DateFilter.data_range.startDate.split(' ')[0]} to ${Dashboard.DateFilter.data_range.endDate.split(' ')[0]}`

        img.appendChild(date);
      }

      img.appendChild(container.firstChild ? container.firstChild.cloneNode(true) : container.cloneNode(true));

      document.getElementsByTagName('BODY')[0].append(img);

      const canvas = await Promise.resolve(html2canvas(img), { scale: 1 });
      const link = document.createElement('a');
      link.download = `${widget.name || name}.png`;
      link.href = canvas.toDataURL();
      link.click();
      img.remove();
    } else {

      toast.error("Sorry we could not export your Image, please try again in few minutes.", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      })
    }

    this.closeDialogExportOverview();
  }

  dialogSettingsToggle() {
    this.setState(({ dialogSettings }) => ({ dialogSettings: !dialogSettings }))
  }

  toggleGlobalFilter() {
    const { selectedPage, ...widget } = this.props
    this.props.toggleWidgetGlobalFilter(selectedPage.id, widget.id)
  }

  filterChangeSubparkSelected() { }

  setPreProcessDecorators(module_id, selectedPage_id, widget_id, data, reducer_name) {
    this.props.setPreProcessDecoratorsWidget(module_id, selectedPage_id, widget_id, data, reducer_name);
  }

  setPostProcessDecorators(module_id, selectedPage_id, widget_id, data, reducer_name) {
    // this.props.setPostProcessDecoratorsWidget(selectedPage_id, widget_id, data, reducer_name)
  }

  settingsMenuToggle() {
    let { selectedPage, widget, module_id, reducer_name } = this.props;

    this.setState(state => ({ settings: !state.settings }));
    if (this.state.settings && this.state.getSettings) {
      let data = this.state.getSettings();
      this.setPreProcessDecorators(module_id, selectedPage.id, widget.id, data, reducer_name);
    }
  }

  // export const setFilterLocalInWidget = (module_field, module_id, widget_id, filter, reducer_name) => {

  toggleFilterLocal = () => {
    const { selectedPage, widget, module_id, reducer_name } = this.props


    let id = widget.id;
    let page_id = selectedPage.id;

    if (!widget.filter && widget.filterGlobal) {

      widget.filter = selectedPage.filter;
    }

    this.setState(state => ({
      localFilter: !state.localFilter
    }))
  }

  changeDateLocalFilter = (date) => {
    if (date[DATEPICKER_DATE_KEY]) {
      const startDate = date[DATEPICKER_DATE_KEY].startDate
      const endDate = date[DATEPICKER_DATE_KEY].endDate
      this.setState({
        startDate,
        endDate
      })
    }
  }

  showLoading = () => (
    <div style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={(30)} />
      <div style={{
        marginTop: '20px'
      }}>
        Loading...
      </div>
    </div>
  )

  componentDidMount() {
    // console.log('WIDGET MOUNTED', this.props.isWidgetLoading)
    // if(this.props.isWidgetLoading) {
    //   this.props.widgetFinishedLoading();
    // }
  }

  render() {
    const {
      classes,
      children,
      selectedPage,
      widget,
      module_id,
      reducer_name,
      real_time,
      openRemoveDialog,
      t
    } = this.props


    const moduleSelected = R.find(R.propEq('selected', true))(this.props.Dashboard[this.props.module_id])

    // console.log('moduleSelected', moduleSelected)

    // console.log('ON RENDERING WIDGET', this.props, widget)

    if (!widget) {
      // console.log('OH MY FUCK WIDGET IS UNDEFINED!')
      return null
    }

    const headerFontSize = widget && widget.grid ? Math.floor(widget.grid.w * 2 + widget.grid.h * 1.5) : '1em';
    // const headerFontSize = 6;
    const font_size = headerFontSize < 10 ? 10 : headerFontSize > 16 ? 16 : headerFontSize;

    const childrenWithProps = React.Children.map(children, (child) => {
      const attr = {
        ...child.props,
        showTable: this.state.table,
        showSettings: this.state.settings,

        showHelp: this.state.help,
        showExport: this.state.showExport,

        loading: this.state.loading,
        getSettings: func => (this.setState({ getSettings: func })),
        closeDialogExportOverview: this.closeDialogExportOverview,
        closeDialogExportOverviewExcel: this.closeDialogExportOverviewExcel,
        exportdData: this.exportdData,
        parks_info: this.props.parks_info,
        moduleSelected,
        DateFilter: this.props.Dashboard.DateFilter,

        font_size: font_size,

        sendRefChart: refChart => this.setState({ refChart })
      }

      return React.cloneElement(child, attr);
    })

    const graphicType = R.find(R.propEq('type', widget.type))(graphTypes);

    if (widget.hydro) {
      widget.data = {}
    }

    if (!widget.data) {
      return (
        <Paper className={classes.paperElement} elevation={0}>
          <div>
            {this.showLoading()}
          </div>
        </Paper>
      )
    }

    const paperStyle = this.props && this.props.widget && this.props.widget.id === 2 && this.props.showTable ? { backgroundColor: 'transparent' } : {};


    return (
      <ExportContainer showExport={this.state.showExport} widget={widget} closeDialogExportOverview={this.closeDialogExportOverview} downloadImage={this.downloadImage}>
        <div
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          <Paper className={classes.paperElement} elevation={0} style={paperStyle}>
            <NoPrint>
              {!this.props.showTable &&
                <div className={classes.header}>
                  <div className={classes.headerTitle}>
                    <span>{this.props.unique ? '' : widget.titleHeader ? widget.titleHeader : graphicType ? graphicType.description : '' || ''}</span>
                  </div>
                  <div className={classes.actionsButtons}>
                    {
                      widget.header_note ?
                        <Chip
                          avatar={
                            <Avatar style={{
                              backgroundColor: '#847474',
                              background: '#847474',
                              borderColor: '#847474'
                            }}>
                              <Icon className={classes.icon} style={{ color: '#FFFFFF' }}>
                                info
                    </Icon>
                            </Avatar>
                          }
                          label={widget.header_note}
                          className={classes.chip}
                          variant="outlined"
                          style={{ backgroundColor: '#bdbdbd', color: '#FFFFFF' }}
                        />
                        :
                        null
                    }



                    {
                      widget.hasOwnProperty('ignore_icons') && widget.ignore_icons.includes('table') || this.props.ignoreIcons || this.props.isEditing ? null :
                        (
                          <Tooltip title={this.state.table ? 'Chart' : 'Table'}>
                            <IconButton onClick={this.goFlip.bind(this)}
                              style={{
                                // width: `${Math.ceil(font_size <= 10 ? font_size : 10) * 2.2}px`,
                                // height: `${Math.ceil(font_size <= 10 ? font_size : 10) * 2.2}px`,
                                // marginRight: `${(font_size <= 10 ? font_size : 10) * 0.6}px`,
                                color: 'black'
                              }}
                            >
                              {
                                this.state.table ?
                                  <ShowChartIcon style={{
                                    fontSize: `${Math.ceil(font_size <= 10 ? font_size : 10) * 1.5}px`
                                  }}
                                  />
                                  :
                                  <TableChartIcon style={{
                                    fontSize: `${Math.ceil(font_size <= 10 ? font_size : 10) * 1.5}px`
                                  }}
                                  />
                              }
                            </IconButton>
                          </Tooltip>
                        )
                    }
                    {
                      widget.hasOwnProperty('ignore_icons') && widget.ignore_icons.includes('help') || this.props.isEditing ? null :
                        widget.data.help &&
                        (<LightTooltip title={widget.data.help} placement="bottom-end">
                          <IconButton style={{ color: 'black' }}>
                            {
                              <Icon style={{
                                fontSize: `${Math.ceil(font_size <= 10 ? font_size : 10) * 1.5}px`
                              }}>
                                help
                              </Icon>
                            }
                          </IconButton>
                        </LightTooltip>)
                    }
                    {
                      widget.hasOwnProperty('ignore_icons') && widget.ignore_icons.includes('download') || this.props.isEditing ? null :
                        (<Tooltip title={t("Export")}>
                          <IconButton onClick={this.goExport.bind(this)} style={{
                            // width: `${Math.ceil(font_size <= 10 ? font_size : 10) * 2.2}px`,
                            // height: `${Math.ceil(font_size <= 10 ? font_size : 10) * 2.2}px`,
                            color: 'black'
                          }}
                          >
                            {
                              this.state.help ?
                                <ShowChartIcon style={{
                                  fontSize: `${Math.ceil(font_size <= 10 ? font_size : 10) * 1.5}px`
                                }}
                                />
                                :
                                <DownloadIcon style={{
                                  fontSize: `${Math.ceil(font_size <= 10 ? font_size : 10) * 1.5}px`
                                }}
                                />
                            }
                          </IconButton>
                        </Tooltip>)
                    }
                    {
                      this.props.isEditing ? (
                        <Tooltip title='Delete this Widget'>
                          <IconButton
                            onClick={openRemoveDialog}
                            style={{
                              color: 'black'
                            }}
                          >
                            {
                              <Icon style={{
                                fontSize: `${Math.ceil(font_size <= 10 ? font_size : 10) * 1.5}px`
                              }}
                              >
                                delete
                            </Icon>
                            }
                          </IconButton>
                        </Tooltip>
                      )
                        : null
                    }

                  </div>
                </div>}
            </NoPrint>
            <div className={classes.paperBody} style={{ overflowY: widget.type == 'bullet_hydro' ? 'hidden' : 'auto' }}>
              {this.state.localFilter && !real_time ? (
                <FilterContent
                  widget={widget}
                  selectedPage={selectedPage}
                  module_id={module_id}
                  reducer_name={reducer_name}
                />
              ) : childrenWithProps}
            </div>

          </Paper>

          <div>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row'
            }}>
            </div>
          </div>

        </div>
      </ExportContainer>
    )
  }
}

const mapStateToProps = ({ User, Dashboard }) => {
  const { parks_info } = User

  return { parks_info, Dashboard }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleWidgetGlobalFilter, setFilterLocalInWidget, setPostProcessDecoratorsWidget, setPreProcessDecoratorsWidget }, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphicWidget)))

// export default withStyles(styles)(GraphicWidget);