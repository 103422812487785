import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment-timezone';

import CircularLoading from '../../../../../components/Loading/CircularLoading';
import DateSelector from './DateSelector';

class DateContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            startDate: null,
            endDate: null
        }
    }

    componentDidMount() {
        const { filterDate } = this.props;
        
        if(!filterDate || Object.entries(filterDate).length === 0) {
            this.setState({
                loading: false,
                startDate: moment().subtract(8, 'days'),
                endDate: moment().subtract(1, 'days'),
            });
        } else {
            this.setState({
                loading: false,
                startDate: moment(filterDate.startDate),
                endDate: moment(filterDate.endDate),
            });
        }
    }

    // componentDidUpdate() {
    //     const { loading } = this.state;

    //     if(loading) {
    //         setTimeout( () => this.updateParksInfo(), 200);
    //     }
    // }

    render () {
        const { loading, startDate, endDate } = this.state;

        return (
            !loading ? (
                <DateSelector {...this.props} date={{startDate, endDate}} />
            ) : (
                <CircularLoading notOverlay={true}>
                    <span>Please wait while we are getting date range info from devices.</span>
                </CircularLoading>
            )
        );
    }
}

const mapStateToProps = ({Dashboard, User}) => ({
    filterDate: Dashboard.DateFilter && Dashboard.DateFilter.data_range 
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
    }
    , dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(DateContainer);
  