import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './styles/BaseSelectorStyles';

const COL_WIDTH = {
    width: 'calc(100% / 3)'
};

class DevicesSelectorTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parks: {},
            subparks: {},
            devices: {},
            parksOrder: [],
            subparksOrder: [],
            devicesOrder: [],
            hoveringParkId: null,
            hoveringSubparkId: null,
            firstLoad: false
        }
    }

    extractInfoFromSelector = selectorInfo => {
        if(!selectorInfo) {
            return false;
        } else {
            const parksOrder = Object.values(selectorInfo.parks).map(park => park.id);
            const subparksOrder = parksOrder.map(parentId => (
                Object.values(selectorInfo.subparks).filter(subpark => subpark.parentId === parentId)
            )).reduce((acc, curr) => (
                [...acc, ...curr]
            )).map(subpark => subpark.id);
            const devicesOrder = subparksOrder.map(parentId => (
                Object.values(selectorInfo.devices).filter(device => device.parentId === parentId)
            )).reduce((acc, curr) => (
                [...acc, ...curr]
            )).map(device => device.id);

            return {
                ...selectorInfo,
                parksOrder,
                subparksOrder,
                devicesOrder
            };
        }
    }

    executeFirstLoad = () => {
        const { selectorInfo } = this.props;
        const loadRes = this.extractInfoFromSelector(selectorInfo);

        if(loadRes) {
            this.setState({
                ...loadRes,
                firstLoad: true
            });
        }    
    }

    componentDidMount() {
        this.executeFirstLoad();
    }

    componentDidUpdate() {
        const { firstLoad } = this.state;

        if(!firstLoad) {
            setTimeout(() => {
                this.executeFirstLoad();
            }, 500);
        }
    }

    handleClickPark = parkId => e => {
        const { parks, subparks, devices } = this.state;

        const selectedPark = parks[parkId];
        const selectedSubparks = Object.values(subparks).filter(subpark => subpark.parentId === parkId);
        const selectedSubparksIds = selectedSubparks.map(subpark => subpark.id);
        const selectedDevices = Object.values(devices).filter(device => selectedSubparksIds.indexOf(device.parentId) >= 0);

        this.setState(prevState => ({
            parks: {
                ...prevState.parks, 
                [parkId]: {
                    ...selectedPark, 
                    selectedChildrenAmt: selectedPark.selectedChildrenAmt > 0 ? 0 : selectedPark.childrenAmt
                }
            },
            subparks: {
                ...prevState.subparks,
                ...selectedSubparks.map(subpark => ({
                    [subpark.id]: {
                        ...subpark,
                        selectedChildrenAmt: selectedPark.selectedChildrenAmt > 0 ? 0 : subpark.childrenAmt
                    }
                })).reduce((acc, curr) => ({...acc, ...curr}))
            },
            devices: {
                ...prevState.devices,
                ...selectedDevices.map(device => ({
                    [device.id]: {
                        ...device,
                        isSelected: selectedPark.selectedChildrenAmt === 0
                    }
                })).reduce((acc, curr) => ({...acc, ...curr}))
            }
        }));
    } 

    handleClickSubpark = subparkId => e => {
        const { parks, subparks, devices } = this.state;

        const selectedSubpark = subparks[subparkId];
        const selectedDevices = Object.values(devices).filter(device => device.parentId === selectedSubpark.id);
        const parentPark = parks[selectedSubpark.parentId];

        this.setState(prevState => ({
            parks: {
                ...prevState.parks, 
                [parentPark.id]: {
                    ...parentPark, 
                    selectedChildrenAmt: selectedSubpark.selectedChildrenAmt === selectedSubpark.childrenAmt ? 
                        parentPark.selectedChildrenAmt - 1
                    : 
                        parentPark.selectedChildrenAmt + 1
                }
            },
            subparks: {
                ...prevState.subparks,
                [selectedSubpark.id]: {
                    ...selectedSubpark,
                    selectedChildrenAmt: selectedSubpark.selectedChildrenAmt > 0 ? 0 : selectedSubpark.childrenAmt
                }
            },
            devices: {
                ...prevState.devices,
                ...selectedDevices.map(device => ({
                    [device.id]: {
                        ...device,
                        isSelected: selectedSubpark.selectedChildrenAmt === 0
                    }
                })).reduce((acc, curr) => ({...acc, ...curr}))
            }
        }));
    } 

    handleClickDevice = deviceId => e => {
        const { parks, subparks, devices } = this.state;

        const selectedDevice = devices[deviceId];
        const parentSubpark = subparks[selectedDevice.parentId];
        const parentPark = parks[parentSubpark.parentId];

        this.setState(prevState => ({
            parks: {
                ...prevState.parks, 
                [parentPark.id]: {
                    ...parentPark, 
                    selectedChildrenAmt: (
                        selectedDevice.isSelected && 
                        parentSubpark.selectedChildrenAmt === parentSubpark.childrenAmt && 
                        parentPark.selectedChildrenAmt - 1
                    ) ||
                    (
                        !selectedDevice.isSelected && 
                        parentSubpark.selectedChildrenAmt === parentSubpark.childrenAmt - 1 && 
                        parentPark.selectedChildrenAmt + 1
                    ) ||
                    parentPark.selectedChildrenAmt
                }
            },
            subparks: {
                ...prevState.subparks,
                [parentSubpark.id]: {
                    ...parentSubpark, 
                    selectedChildrenAmt: selectedDevice.isSelected ? 
                        parentSubpark.selectedChildrenAmt - 1
                    : 
                        parentSubpark.selectedChildrenAmt + 1
                }
            },
            devices: {
                ...prevState.devices,
                [selectedDevice.id]: {
                    ...selectedDevice,
                    isSelected: !selectedDevice.isSelected
                }
            }
        }));
    } 

    componentDidUpdate(prevProps, prevState) {
        const { parks, subparks, devices } = this.state;
        const { retrieveData } = this.props;

        if (
            (JSON.stringify(parks) !== JSON.stringify(prevState.parks)) ||
            (JSON.stringify(subparks) !== JSON.stringify(prevState.subparks)) ||
            (JSON.stringify(devices) !== JSON.stringify(prevState.devices))
        ) {
            const devicesToDownload = Object.values(devices).filter(device => device.isSelected);
            const devicesIdsToDownload = devicesToDownload.map(device => device.id);
            const devicesParentsToDownload = devicesToDownload.map(device => device.parentId);
            const subparksToDownload = Object.values(subparks).filter(subpark => devicesParentsToDownload.indexOf(subpark.id) >= 0);
            const subparksIdsToDownload = subparksToDownload.map(subpark => subpark.id);
            const subparksParentsToDownload = subparksToDownload.map(subpark => subpark.parentId);
            const parksToDownload = Object.values(parks).filter(park => subparksParentsToDownload.indexOf(park.id) >= 0);
            const parksIdsToDownload = parksToDownload.map(park => park.id);

            retrieveData({ 
                parks: parksIdsToDownload, 
                subparks: subparksIdsToDownload, 
                devices: devicesIdsToDownload
            });
        }
    }

    onExpandPark = parkId => event => {
        this.setState(prevState => ({
            hoveringParkId: parkId !== prevState.hoveringParkId ? parkId : null,
            hoveringSubparkId: parkId !== prevState.hoveringParkId ? (
                        prevState.subparks && 
                        Object.values(prevState.subparks).filter(subpark => (
                            subpark.parentId === parkId)
                        )[0]
                    ) 
                :
                    null
        }));
    }

    onExpandSubpark = subparkId => event => {
        this.setState(prevState => ({
            hoveringSubparkId: subparkId !== prevState.hoveringSubparkId ? 
                    subparkId 
                : 
                    null
        }));
    }

    render () {
        const { classes } = this.props;
        const { parks, subparks, devices, parksOrder, subparksOrder, devicesOrder, hoveringParkId, hoveringSubparkId } = this.state;

        return (
            <Paper className={classes.root}>
                <div className={classes.table} >
                    <List 
                        className={classes.column}
                        style={COL_WIDTH}
                    >
                        <ListItem
                            key={`park-header`}
                            style={{
                                textAlign: 'center'
                            }}
                            className={classes.rowContent}
                        >
                            <ListItemText primary={'Park'} />
                        </ListItem>
                        {
                            parksOrder.map(parkId => {
                                const park = parks[parkId];
                                const filteredSubparks = Object.values(subparks).filter(subpark => subpark.parentId === parkId);

                                return (
                                    <ListItem
                                        key={`park-${parkId}`}
                                        align='left'
                                        className={classes.rowContent}
                                    >
                                        <IconButton
                                            classes={{
                                                root: classes.selectBt
                                            }}
                                            onClick={this.handleClickPark(parkId)}
                                        >
                                            <Icon
                                                classes={{
                                                    root: classes.smallBt
                                                }}
                                            >
                                                {
                                                    (
                                                        filteredSubparks.map(subpark => subpark.selectedChildrenAmt === 0)
                                                            .reduce((acc, curr) => acc && curr)
                                                        && 'check_box_outline_blank'
                                                    ) ||
                                                    (
                                                        filteredSubparks.map(subpark => subpark.selectedChildrenAmt !== subpark.childrenAmt)
                                                            .reduce((acc, curr) => acc || curr)
                                                        && 'indeterminate_check_box'
                                                    ) ||
                                                    (
                                                        filteredSubparks.map(subpark => subpark.selectedChildrenAmt === subpark.childrenAmt)
                                                            .reduce((acc, curr) => acc && curr)
                                                        && 'check_box'
                                                    )
                                                }
                                            </Icon>
                                        </IconButton>
                                        {
                                            park.name.length > 10 ?
                                                (
                                                    <Tooltip title={park.name}>
                                                        <ListItemText 
                                                            onClick={this.onExpandPark(parkId)}
                                                            classes={{
                                                                root: classes.cellText,
                                                                primary: classes.cellText
                                                            }}
                                                            primary={park.name}
                                                        />
                                                    </Tooltip>
                                                )
                                            :
                                                (
                                                    <ListItemText 
                                                        onClick={this.onExpandPark(parkId)}
                                                        classes={{
                                                            root: classes.cellText,
                                                            primary: classes.cellText
                                                        }}
                                                        primary={park.name}
                                                    />
                                                )
                                        }
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                    <List 
                        className={classes.column}
                        style={COL_WIDTH}
                    >
                        <ListItem
                            key={`subpark-header`}
                            style={{
                                textAlign: 'center'
                            }}
                            className={classes.rowContent}
                        >
                            <ListItemText primary={'Subpark'} />
                        </ListItem>
                        {
                            subparksOrder.filter(subparkId => hoveringParkId === subparks[subparkId].parentId)
                                .map(subparkId => {
                                    const subpark = subparks[subparkId];
                                    
                                    return (
                                        <ListItem
                                            key={`subpark-${subparkId}`}
                                            align='left'
                                            className={classes.rowContent}
                                        >
                                            <IconButton
                                                classes={{
                                                    root: classes.selectBt
                                                }}
                                                onClick={this.handleClickSubpark(subparkId)}
                                            >
                                                <Icon
                                                    classes={{
                                                        root: classes.smallBt
                                                    }}
                                                >
                                                    {
                                                        (subpark.selectedChildrenAmt === 0 && 'check_box_outline_blank') ||
                                                        (subpark.selectedChildrenAmt !== subpark.childrenAmt && 'indeterminate_check_box') ||
                                                        (subpark.selectedChildrenAmt === subpark.childrenAmt && 'check_box')
                                                    }
                                                </Icon>
                                            </IconButton>
                                            {
                                                subpark.name.length > 10 ?
                                                    (
                                                        <Tooltip title={subpark.name}>
                                                            <ListItemText 
                                                                onClick={this.onExpandSubpark(subparkId)}
                                                                classes={{
                                                                    root: classes.cellText,
                                                                    primary: classes.cellText
                                                                }}
                                                                primary={subpark.name} 
                                                            />
                                                        </Tooltip>
                                                    )
                                                :
                                                    (
                                                        <ListItemText 
                                                            onClick={this.onExpandSubpark(subparkId)}
                                                            classes={{
                                                                root: classes.cellText,
                                                                primary: classes.cellText
                                                            }}
                                                            primary={subpark.name} 
                                                        />
                                                    )
                                            }
                                        </ListItem>
                                    );
                            })
                        }
                    </List>
                    <List 
                        className={classes.column}
                        style={COL_WIDTH}
                    >
                        <ListItem
                            key={`device-header`}
                            style={{
                                textAlign: 'center'
                            }}
                            className={classes.rowContent}
                        >
                            <ListItemText primary={'Device'} />
                        </ListItem>
                        {
                            devicesOrder.filter(deviceId => hoveringSubparkId === devices[deviceId].parentId)
                                .map(deviceId => {
                                const device = devices[deviceId];
                                
                                return (
                                    <ListItem
                                        key={`device-${deviceId}`}
                                        align='left'
                                        className={classes.rowContent}
                                    >
                                        <IconButton
                                            classes={{
                                                root: classes.selectBt
                                            }}
                                            onClick={this.handleClickDevice(deviceId)}
                                        >
                                            <Icon
                                                classes={{
                                                    root: classes.smallBt
                                                }}
                                            >
                                                {
                                                    device.isSelected ? 
                                                        'check_box' 
                                                    : 
                                                        'check_box_outline_blank'
                                                }
                                            </Icon>
                                        </IconButton>
                                        {
                                            device.name.length > 10 ?
                                                (
                                                    <Tooltip title={device.name}>
                                                        <ListItemText 
                                                            classes={{
                                                                root: classes.cellLeafText,
                                                                primary: classes.cellLeafText
                                                            }}
                                                            primary={device.name} 
                                                        />
                                                    </Tooltip>
                                                )
                                            :
                                                (
                                                    <ListItemText 
                                                        classes={{
                                                            root: classes.cellLeafText,
                                                            primary: classes.cellLeafText
                                                        }}
                                                        primary={device.name} 
                                                    />
                                                )
                                        }
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(DevicesSelectorTable);