export const deviceHierarchy = ({parks, subparks, devices}) => (
    Object.values(parks).map(park => ({
        [park.id]: {
            ...park,
            subparks: Object.values(subparks).filter(subpark => (
                subpark.park_id === park.id
            )).map(subpark => ({
                [subpark.id]: {
                    ...subpark,
                    devices: Object.values(devices).filter(device => (
                        device.subpark_id === subpark.id
                    )).map(device => ({
                        [device.id]: {
                            ...device
                        }
                    })).reduce((acc, curr) => ({...acc, ...curr}))
                }
            })).reduce((acc, curr) => ({...acc, ...curr}))
        }
    })).reduce((acc, curr) => ({...acc, ...curr}))
);