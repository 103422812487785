export const maxFieldOnJson = (arr, prop) => {
  let max;
  for (let i = 0; i < arr.length; i++) {
    if (!max || parseInt(arr[i][prop]) > parseInt(max[prop]))
      max = arr[i];
  }
  return max;
}

export const fillQuadrant = (data, params, max_y) => {
  var plotLineX = data.plotLineX;
  var plotLineY = data.plotLineY;
  var quadrant = [{
    showInLegend: false,	//inferior izquierda
    name: 'D',
    data: [[0, 0], [0, plotLineY[0]], [plotLineX[0], plotLineY[0]],
    [plotLineX[0], 0]],
    color: "#f8cba7",
    zIndex: 0,
    type: 'area'
  },
  {
    showInLegend: false,	//inferior direita
    name: 'C',
    data: [[plotLineX[0], 0], [plotLineX[0], plotLineY[0]],
    [100, plotLineY[0]], [100, 0]],
    color: "#f1edb4",
    zIndex: 1,
    type: 'area'
  },
  {
    showInLegend: false,	//superior direta
    name: 'A',
    data: [[plotLineX[0], plotLineY[0]], [100, plotLineY[0]], [100, max_y],
    [plotLineX[0], max_y]],
    color: "#54CCA1",
    zIndex: 0,
    type: 'area'
  },
  {
    showInLegend: false,	//superior izquierda
    name: 'B',
    data: [[0, plotLineY[0]], [plotLineX[0], plotLineY[0]],
    [plotLineX[0], max_y], [0, max_y]],
    color: "#f1edb4",
    zIndex: 0,
    type: 'area'
  },
  ]
  quadrant.forEach(e => {
    params.series.push(e)
  })
  return params
}

export const plotLines = (data, params) => {
  params.xAxis['plotLines'] = []
  params.yAxis['plotLines'] = []

  data.plotLineX.forEach((e, i) => {
    params.xAxis['plotLines'].push({
      zIndex: 3,
      value: e,
      color: 'red',
      dashStyle: 'shortdash',
      width: 1,
      label: {
        text: data.textLineX[i] + ': ' + e.toFixed(1) + ' %',
        style: {
          fontSize: "12px",
          fontWeight: "bold"
        }
      }
    })
  })

  data.plotLineY.forEach((e, i) => {
    params.yAxis['plotLines'].push({
      zIndex: 3,
      value: e,
      color: 'red',
      dashStyle: 'shortdash',
      width: 1,
      label: {
        text: data.textLineY[i] + ': ' + e.toFixed(1) + ' %',
        style: {
          fontSize: "12px",
          fontWeight: "bold"
        }
      }
    })
  })
  return params
}
export function findMinMax(arr) {
  let min = arr[0].y, max = arr[0].y;

  for (let i = 1, len = arr.length; i < len; i++) {
    let v = arr[i].y;
    min = (v < min) ? v : min;
    max = (v > max) ? v : max;
  }

  return [min, max];
}
