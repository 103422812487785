import { useState } from 'react';
import * as R from 'ramda';

export default ({ title, items }) => {

    const checkedItems = Array.isArray(items) ? items : [];

    const [collapsed, setCollapsed] = useState(checkedItems.map(() => false));

    const collapse = index => {
        setCollapsed(R.update(index, !collapsed[index], collapsed));
    };


    return {
        title,
        items: checkedItems,
        collapsed,
        collapse,
        noData: checkedItems.length <= 0 ? 'No data available' : null
    };
};