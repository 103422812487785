import {  
    REDUCER_AUTH_LOGOUT, 
    REDUCER_AUTH_LOGIN 
} from '../constants/reducers/auth-action-events';

const DEFAULT_STATE = null;

export default (state = DEFAULT_STATE, action) => {
    const { type, id } = action;

    switch (type) {
        case REDUCER_AUTH_LOGIN:
            return id;
        case REDUCER_AUTH_LOGOUT:
            return null;
        default:
            return state;
    }
}
