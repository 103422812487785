import { REDUCER_AUTH_PARKS_INFO } from "./reducers/auth-action-events";
import GraphicsRegistry from '../data/META/graphics-registry.json';

export const parks_info = {
  type: REDUCER_AUTH_PARKS_INFO,
  description: 'CLIENT INFO',
  name: 'parks_info'
};

const generateGraphType = graphic => (
  {
    reducer_type: `REDUCER_DATA_${graphic.procedure.toUpperCase()}`,
    type: graphic.procedure,
    description: graphic.description,
    name: graphic.procedure,
    filters: graphic.internal_filters
  }
);

export const graphicsForTypeBar = GraphicsRegistry.graphicsForTypeBar.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeStackedBar = GraphicsRegistry.graphicsForTypeStackedBar.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypePareto = GraphicsRegistry.graphicsForTypePareto.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeWaterfall = GraphicsRegistry.graphicsForTypeWaterfall.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeColumnRange = GraphicsRegistry.graphicsForTypeColumnRange.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeInteractiveTable = GraphicsRegistry.graphicsForTypeInteractiveTable.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeGauge = GraphicsRegistry.graphicsForTypeGauge.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeBoxplot = GraphicsRegistry.graphicsForTypeBoxplot.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeMetrics = GraphicsRegistry.graphicsForTypeMetrics.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeScoreTimeline = GraphicsRegistry.graphicsForTypeScoreTimeline.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeScatter = GraphicsRegistry.graphicsForTypeScatter.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeImage = GraphicsRegistry.graphicsForTypeImage.map(graphic => (generateGraphType(graphic)));
export const graphicsWithoutType = GraphicsRegistry.graphicsWithoutType.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeHeatMap = GraphicsRegistry.graphicsForTypeHeatMap.map(graphic => (generateGraphType(graphic)));
export const graphicsForTypeRealtimeTable = GraphicsRegistry.graphicsForTypeRealtimeTable.map(graphic => (generateGraphType(graphic)))

export const graphicsForTypeWindRose = GraphicsRegistry.graphicsForTypeWindRose.map(graphic => (generateGraphType(graphic)))

export const graphicsForTypeAreaWithZones = GraphicsRegistry.graphicsForTypeAreaWithZones.map(graphic => (generateGraphType(graphic)))

export const graphicsForTypeLevelFluency = GraphicsRegistry.graphicsForTypeLevelFluency.map(graphic => (generateGraphType(graphic)))

export const graphicsForTypePieChart = GraphicsRegistry.graphicsForTypePieChart.map(graphic => (generateGraphType(graphic)));

export const graphicsForMultipleSeries = GraphicsRegistry.graphicsForMultipleSeries.map(graphic => (generateGraphType(graphic)));

export const graphicsForNewWidget = [
  ...graphicsForTypeBar,
  ...graphicsForTypeStackedBar,
  ...graphicsForTypePareto,
  ...graphicsForTypeWaterfall,
  ...graphicsForTypeColumnRange,
  ...graphicsForTypeBoxplot,
  ...graphicsForTypeInteractiveTable,
  ...graphicsForTypeGauge,
  ...graphicsForTypeMetrics,
  ...graphicsForTypeScoreTimeline,
  ...graphicsForTypeScatter,
  ...graphicsForTypeImage,
  ...graphicsWithoutType,
  ...graphicsForTypeHeatMap,
  ...graphicsForTypeWindRose,
  ...graphicsForTypeAreaWithZones,
  ...graphicsForTypeLevelFluency,
  ...graphicsForTypePieChart,
  ...graphicsForMultipleSeries
];

export const graphTypesForNewService = graphicsForNewWidget;

export default graphicsForNewWidget;
