import React from 'react'
import {withStyles, Button} from '@material-ui/core'
import * as R from 'ramda'
import {Link} from 'react-router-dom'

import { ROUTE_MAIN_PCH_MONITOR } from '../../../../constants/route-constant';

const styles = {
    button: {
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#FF3300'
        },
        borderRadius: 0,
        textTransform: 'capitalize',
        textDecoration: 'none'
    }
}

class HeaderAllModulesComponent extends React.Component {

    componentDidMount () {
        // console.log(this.props)
    }

    goModule = item => () => {
        this.props.history.push(item.route)
    }

    toggleHover = () => {
        this.setState({hover: !this.state.hover})
    }

    render () {
    const { classes, isDark, modules, navigate } = this.props;
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
         }}
      >
        {
          modules
          .filter(item => item.id !== 99)
          .map((item, itemIndex) => {
            return (
              <div
                key={itemIndex}
              >
                 <Link
                  className={classes.button}
                  to={item.route}
                  // onClick={() => navigate(item)}
                > 
                  <Button
                    disableRipple="true"
                    className={classes.button}                    
                    // onClick={this.goModule(item).bind(this)}
                    style={{
                      //color: isDark ? 'white' : 'black',
                      color: this.props.location.pathname.search(item.route) >= 0
                        ? '#FF3300'
                        : isDark ? 'white' : null
                    }}
                    onClick={() => navigate(item.id)}
                  >
                    {item.name}
                  </Button>
                </Link> 
              </div>
            )
          })
        }
      </div>
    )
  }
}

export default withStyles(styles)(HeaderAllModulesComponent)