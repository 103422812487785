export const PAGE_SIZE_TYPE_HEIGHT = 'height'
export const PAGE_SIZE_TYPE_WIDTH = 'width'

export const PAGE_SIZE_A4 = {
  width: 2480,
  height: 3508
}

export const PAGE_TYPE_ALARMS = 'PAGE_TYPE_ALARMS'
export const PAGE_TYPE_DOWNTIME = 'PAGE_TYPE_DOWNTIME'
