import { Auth } from 'aws-amplify'
import axios from 'axios'
import { URL_NODE_API } from '../constants/url-constant'

export const getWidgetTypeProvider = async () => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = URL_NODE_API + '/reports/widget'

  const {data} = await axios.get(URL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data
}

export const getReportAllProvider = async () => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = URL_NODE_API + '/reports/report'

  const {data} = await axios.get(URL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data
}

export const getTemplateAllProvider = async () => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = URL_NODE_API + '/reports/template'

  const {data} = await axios.get(URL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data
}

export const createTemplateProvider = async (body) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = URL_NODE_API + '/reports/template'

  const {data} = await axios.post(URL, body, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data
}





export const deleteTemplateProvider = async (body) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = URL_NODE_API + '/reports/template'

  const request = axios.create({
        headers: {
            Authorization: jwtToken
        }
  });


  const {data}  = await request.delete(URL, { data: body});

  return data
}


export const deleteReportProvider = async (body) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = URL_NODE_API + '/reports/report'

  const request = axios.create({
        headers: {
            Authorization: jwtToken
        }
  });


  const {data}  = await request.delete(URL, { data: body});

  return data
}


export const updateTemplateProvider = async (body) => {

  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = URL_NODE_API + '/reports/template'

  const {data} = await axios.put(URL, body, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data
}

export const createReportProvider = async (body) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = URL_NODE_API + '/reports/report'

  const {data} = await axios.post(URL, body, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data
}

export const downloadProvider = async (url) => {
  const {data} = await axios.get(url)

  return data
}
