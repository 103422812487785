import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from "moment-timezone";
import { Auth } from 'aws-amplify';
import { URL_NODE_API } from '../../../../../../constants/url-constant';


const getGraphData = async (token, params) => new Promise(async (resolve, reject) => {

    const URL = `${URL_NODE_API}/management/new-data`;

    await axios.post(URL, params, getConfig(token))
        .then(resolve)
        .catch(reject);
});

const getConfig = auth => ({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': auth
    }
});

const getToken = () => new Promise(async (resolve, reject) => {
    await Auth.currentSession()
        .then(({ accessToken }) => { resolve(accessToken.jwtToken) })
        .catch(error => reject(error));
});

const mapToOneProp = (arr, prop) => arr.map(element => element[prop]);

const handleError = e => { console.log(e) };

const getQuery = props => (props && props.realtime && props.realtime.overview && props.realtime.overview.query) || 'last_days_10m_mngt_data';


export default props => {

    const [loading, setLoading] = useState();
    const [data, setData] = useState();
    const client = useSelector(({ Client }) => Client);

    const device_list = (client && client.devices && mapToOneProp(client.devices, 'id')) || [];
    const subpark_list = (client && client.subparks && mapToOneProp(client.subparks, 'id')) || [];
    const procedure_name = getQuery(props);
    const now = moment();

    const params = {
        device_list,
        procedure_name,
        time_range: [now.format('YYYY-MM-DD'), `${now.format('YYYY-MM-DD')} 23:59:59`],
        subpark_list,
        timezone: 'America/Fortaleza',
    };

    useEffect(() => {
        setLoading(true);

        getToken()
            .then(token => getGraphData(token, params))
                .then(response => { setData(response.data) })
                .catch(handleError)
            .catch(handleError)
            .finally(() => { setLoading(false) });
    }, []);

    return {
        widget: {
            data,
            loading,
            type: procedure_name
        },
        ignoreIcons: true,
        isAnalysis: false,
        showTable: false,
        module_id: 'DelfosPreviousManagement'
    };
};