import styled from 'styled-components'

const MenuTreeContent = styled.div``

const MenuTreeListContent = styled.div``

const MenuTreeSubitemContent = styled.div``

const styles = props => theme => ({
    buttonMenu: {
        zIndex: 1200
    },
    contentButtonList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 50,
        cursor: 'pointer',
        paddingLeft: 10,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,.1)'
        }
    }
})

export {MenuTreeContent, MenuTreeListContent, MenuTreeSubitemContent, styles}
