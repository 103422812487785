import React, { useState, useRef } from 'react';

import {
    createResource,
    deleteResource,
    updateResource,
    importModelImage
} from '../../../../../../providers/asset-provider';

import CircularLoading from '../../../../../../components/Loading/CircularLoading';
import BaseViewDialog from '../../../../../../new-components/Dialog';
import Form from '../../../../../../new-components/Form';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const ResourceCreateForm = props => {
    const {
        finishMount,
        resourceDialogOpened,
        openResourceDialog,
        resource,
        activeResource,
        setActiveResource,
        modulePage,
        savingInfo,
    } = props;


    const [errorFormResource, setErrorFormResource] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const fileRef = useRef(null);

    const { t } = useTranslation();


    return (
        <div>
            <BaseViewDialog
                title={`${activeResource ? t('Detail of') : t('Create')} ${t(modulePage.name)}`}
                confirm={t('Save')}
                disableTopClose
                opened={resourceDialogOpened}
                disableDelete={!activeResource}
                Open={openResourceDialog}
                dialogProps={{
                    style: {
                        zIndex: 99999
                    }
                }}
                onDelete={async () => {

                    setOpenDialogDelete(true)

                }}
                onConfirm={async () => {

                    const canExecute = resource && Object.values(resource).map(field => (
                        !field.required || (field.required && field.data != null)
                    )).reduce((acc, curr) => acc && curr);

                    if (canExecute) {

                        const fields = {}


                        Object.keys(resource).map(index => {

                            if (resource[index].data) {

                                fields[index] = resource[index].data

                            }

                        })


                        let resourceRes = null;
                        let importMessage = '';

                        try {

                            if (activeResource) {
                                resourceRes = await updateResource(fields, modulePage.route);

                                if (modulePage.hasImage && fileRef && fileRef.current) {
                                    const { error: importError } = await importModelImage(activeResource.id, fileRef.current);
                                    if (importError) {
                                        importMessage = `. ${t("But it wasn't able to edit image")}`;
                                    } else {
                                        importMessage = `. ${t('And successfully edited image')}!`;
                                    }
                                }
                            } else {
                                resourceRes = await createResource(fields, modulePage.route);
                                const { acquisitiacquisitionResultonResult } = resourceRes || {};

                                const [res] = acquisitiacquisitionResultonResult || [];
                                const { create_component_model: createdModel } = res || {};
                                const { id: createdModelId } = createdModel || {};

                                if (createdModelId && modulePage.hasImage && fileRef && fileRef.current) {
                                    const { error: importError } = await importModelImage(createdModelId, fileRef.current);

                                    if (importError) {
                                        importMessage = `. ${t("But it wasn't able to import image")}`;
                                    } else {
                                        importMessage = `. ${t('And successfully imported image')}!`;
                                    }
                                }
                            }

                            if (!resourceRes.error) {
                                const successMessage = `${t('Successfully registered')}!`

                                toast.success(`${t(successMessage)}${importMessage}`, {
                                    position: 'top-right',
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true
                                });
                            } else {
                                toast.error(`${t(resourceRes.error)}${importMessage}`, {
                                    position: 'top-right',
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }


                            openResourceDialog(false);
                            finishMount(false)
                            setActiveResource(null)

                        } catch (e) {

                            toast.error(e.message, {
                                position: 'top-right',
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true
                            });
                        }


                    } else {
                        setErrorFormResource(true)
                    }
                }}
            >
                {
                    savingInfo &&
                    <CircularLoading>
                        <span>{t('Saving your acquisition')}</span>
                    </CircularLoading>
                }
                <Form {...props} error={errorFormResource} fileRef={fileRef} />
            </BaseViewDialog>

            <BaseViewDialog
                title={`${t('Delete')} ${t(modulePage.name)}`}
                confirm={t('Delete')}
                disableTopClose
                opened={openDialogDelete}
                disableDelete={true}
                Open={setOpenDialogDelete}
                dialogProps={{
                    style: {
                        zIndex: 9999999
                    }
                }}
                onConfirm={async () => {
                    let resourceRes = null;


                    if (activeResource) {
                        resourceRes = await deleteResource(activeResource, modulePage.route);
                    }

                    if (resourceRes && !resourceRes.error) {
                        const successMessage = `${t('Successfully deleted')}!`

                        toast.success(successMessage, {
                            position: 'top-right',
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true
                        });
                    } else {
                        toast.error(resourceRes && resourceRes.error || t('Fail on Delete'), {
                            position: 'top-right',
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true
                        });
                    }

                    openResourceDialog(false);
                    setActiveResource(null)
                    finishMount(false)
                    setOpenDialogDelete(false)

                }}>

                {t('delete_warning_inventory')}
                {
                    savingInfo &&
                    <CircularLoading>
                        <span>{t('Saving your acquisition')}</span>
                    </CircularLoading>
                }
            </BaseViewDialog>

        </div>
    );
}

export default ResourceCreateForm;