import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import styles from './styles/BaseSelectorStyles';

const DEFAULT_TYPES = [
    {name: 'Alarms', id: 'alarms'},
    {name: 'Time Series', id: 'timeSeries'},
    {name: 'Downtimes', id: 'downtimes'}
];

const COL_WIDTH = {
    width: 'calc(100% / 2)'
};

class DownloadTypeList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectAll: false
        };
    }

    checkSimpleButtonDisabled = someType => {
        const { selectedDevices, selectedScadas } = this.props;

        return (
            someType.id !== 'timeSeries' ?
                !selectedDevices || 
                selectedDevices.length === 0
            :
                !selectedDevices || 
                selectedDevices.length === 0 ||
                !selectedScadas || 
                selectedScadas.length === 0
        );
    }

    checkSelectAllButtonDisabled = () => {
        const { selectedDevices } = this.props;
        
        return (
            !selectedDevices || 
            selectedDevices.length === 0
        );
    }

    componentDidMount() {
        const { types: typesToSet } = this.props;

        const preTreatedTypes = typesToSet || DEFAULT_TYPES;
        const types = preTreatedTypes.map(someType => ({
            [someType.id]: {
                ...someType,
                selected: false,
                enabled: !this.checkSimpleButtonDisabled(someType)
            }
        })).reduce((acc, curr) => ({...acc, ...curr}));

        this.setState({
            types
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { types } = this.state;
        const { retrieveData, selectedDevices, selectedScadas } = this.props;

        if(
            types &&
            (JSON.stringify(selectedDevices) !== JSON.stringify(prevProps.selectedDevices) ||
            JSON.stringify(selectedScadas) !== JSON.stringify(prevProps.selectedScadas))
        ) {
            this.setState(otherState => ({
                types: Object.values(otherState.types).map(someType => ({
                    [someType.id]: {
                        ...someType,
                        selected: false,
                        enabled: !this.checkSimpleButtonDisabled(someType)
                    }
                })).reduce((acc, curr) => ({...acc, ...curr}))
            }));
        }

        if (JSON.stringify(types) !== JSON.stringify(prevState.types)) {
            retrieveData({ 
                dataType: Object.values(types).filter(someType => someType.enabled && someType.selected)
                    .map(someType => someType.id)
            });
        }
    }

    handleClick = typeId => event => {
        this.setState(prevState => ({
            selectAll: false,
            types: {
                ...prevState.types,
                [typeId]: {
                    ...prevState.types[typeId],
                    selected: !prevState.types[typeId].selected
                }
            }
        }));
    }

    handleClickAll = event => {
        setTimeout(() => this.setState({selectAll: false}), 400);
        this.setState(prevState => ({
            selectAll: !prevState.selectAll,
            types: Object.values(prevState.types).map(someType => ({
                [someType.id]: {
                    ...someType,
                    selected: !prevState.selectAll
                }
            })).reduce((acc, curr) => ({...acc, ...curr}))
        }));
    }

    render () {
        const { classes, selectedDevices, selectedScadas } = this.props;
        const { types, selectAll } = this.state;

        return (
            <Paper className={classes.root}>
                {
                    types && (
                        <div className={classes.table} >
                            <List 
                                className={classes.column}
                                style={COL_WIDTH}
                            >
                                <ListItem
                                    key={`download-types-action-header`}
                                    style={{
                                        textAlign: 'center'
                                    }}
                                    className={classes.rowContent}
                                >
                                    <ListItemText 
                                        primary='Actions'
                                    />
                                </ListItem>
                                <ListItem
                                    key='download-types-action'
                                    align='left'
                                    className={classes.rowContent}
                                    style={{
                                        height: '85%'
                                    }}
                                    disabled={this.checkSelectAllButtonDisabled()}
                                >
                                    <IconButton
                                        classes={{
                                            root: classes.selectBt
                                        }}
                                        onClick={this.handleClickAll}
                                        disabled={this.checkSelectAllButtonDisabled()}
                                    >
                                        <Icon
                                            classes={{
                                                root: classes.smallBt
                                            }}
                                        >
                                            {
                                                selectAll ? 'radio_button_checked' : 'radio_button_unchecked'
                                            }
                                        </Icon>
                                    </IconButton>
                                    <ListItemText 
                                        classes={{
                                            root: classes.cellLeafText,
                                            primary: classes.cellLeafText
                                        }}
                                        primary='Select All' 
                                    />
                                </ListItem>
                            </List> 
                            <List 
                                className={classes.column}
                                style={COL_WIDTH}
                            >
                                <ListItem
                                    key={`download-types-header`}
                                    style={{
                                        textAlign: 'center'
                                    }}
                                    className={classes.rowContent}
                                >
                                    <ListItemText 
                                        primary='Data Type' 
                                    />
                                </ListItem>
                                {
                                    Object.values(types).map(someType => (
                                        <ListItem
                                            key={`type-${someType.id}`}
                                            align='left'
                                            className={classes.rowContent}
                                            disabled={!someType.enabled}
                                        >
                                            <IconButton
                                                classes={{
                                                    root: classes.selectBt
                                                }}
                                                onClick={this.handleClick(someType.id)}
                                                disabled={!someType.enabled}
                                            >
                                                <Icon
                                                    classes={{
                                                        root: classes.smallBt
                                                    }}
                                                >
                                                    {
                                                        someType.selected ? 'check_box' : 'check_box_outline_blank'
                                                    }
                                                </Icon>
                                            </IconButton>
                                            <ListItemText 
                                                classes={{
                                                    root: classes.cellLeafText,
                                                    primary: classes.cellLeafText
                                                }}
                                                primary={someType.name} 
                                            />
                                        </ListItem>
                                    ))
                                }
                            </List>                   
                        </div>
                    )
                }
            </Paper>
        );
    }
}

export default withStyles(styles)(DownloadTypeList);