import React, { Component } from 'react'
import ReactDOM from "react-dom"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'
import {
  withStyles,
  Icon

} from '@material-ui/core'
import GridLayout from 'react-grid-layout'
import * as R from 'ramda'

import ManagementPageFilter from './components/ManagementPageFilter/ManagementPageFilter'

import { ManagementContent } from './ManagementStyle'

import WidgetDriver from '../../../../components/Driver/WidgetDriver'
import BaseGlobalFilter from '../../../../components/Header/BaseGlobalFilter'
import Selector from '../../../../components/Filter/Selector'

import { REDUCER_MANAGEMENT_GET_DATA } from "../../../../constants/reducers/widget-page-action-events"

import PageWidgetPlaceholder from '../../../../components/Placeholder/PageWidgetPlaceholder'
import { getDataGraphNewProvider } from '../../../../providers/get-data-old-provider';
import { applyFilter } from '../../../../helpers/filter-helper';
import { truncate } from 'fs';
import { updateFilterSubparks, updateFilterTimeRange } from './actions/UpdateFilterAction'

import DeleteIcon from '@material-ui/icons/Delete'


const DEVICE_PAGE_TYPE = 3;
const PAGE_FIELD = 'management';
const GRID_WIDTH_PADDING = 10;

const styles = theme => ({
  gridElement: {
    width: '100%'
  }
})

class ManagementPage extends Component {
  constructor(props) {
    super(props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    const { [PAGE_FIELD]: Module } = this.props;

    let pageSelected = [];

    if (Module) {

      pageSelected = R.find(R.propEq('selected', true))(Module);


    }

    this.state = {
      gridWidth: elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),
      widgets: [],
      dataMenu: [],
      parks: this.props.parks_info,
      showMenu: false,
      loading: false,
      currPage: pageSelected,
      enableEdit: false,
      forceUpdateFilters: false
    }

    this.gridContainerAnchor = React.createRef()
    this.currentDateRange = null
    this.prevManagementId = null
    this.element = null
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName('body')[0]

    this.setState({
      gridWidth: body.offsetWidth - (GRID_WIDTH_PADDING * 2)
    })
  }

  componentDidMount() {


    window.addEventListener('resize', this.resizePageWatch);
    this.setState({ forceUpdateFilters: true })
    // const { [PAGE_FIELD]: Module } = this.props;
    // const moduleSelected = R.find(R.propEq('selected', true))(Module);
    // this.executeFilter(moduleSelected);
    // console.log('MOUNTING MANAGEMENT PAGE')
  }

  // TODO: Remove unecessary updates and consequently unecessary requests

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log('ON CHECKING UPDATE CONDITIONS ', nextProps, this.props)
  //   const shouldUpdate = nextState.firstExec ||
  //     nextState.loading ||
  //     !Object.is(nextState.widgets, this.state.widgets) ||
  //     nextState.gridWidth !== this.state.gridWidth;
  //   return shouldUpdate;
  // }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizePageWatch)
  }

  async componentDidUpdate(prevProps, prevState) {
    const { forceUpdateFilters } = this.state;
    const { [PAGE_FIELD]: Module, Dashboard, parks_info } = this.props;

    let currPage = R.find(R.propEq('selected', true))(Module);

    if ((forceUpdateFilters || prevProps.editMode) && currPage.widgets.length != prevState.widgets.length) {

      if(!this.props.Dashboard.DeviceFilter.subparks){

        this.props.updateEnvelopeFilter(currPage.filter.devices, currPage.filter.subparks )  
      
      
      }
        
      this.executeFilter(currPage);

    


       
    }
 


    const promisesToResolve = this.state.widgets
      .filter(widget => Promise.resolve(widget.data) === widget.data)

    if (promisesToResolve.length > 0) {
      const widgetData = this.state.widgets.map(widget => widget.data);
      const promisesRes = await Promise.all(widgetData);
      const widgets = await Promise.all(this.state.widgets.map(async (widget, index) => {
        const newData = await Promise.resolve(promisesRes[index]);
        return ({
          ...widget,
          data: newData,
          loading: false
        });
      }));

      this.setState({
        widgets
      });
    }

  }

  executeFilter = payload => {


    const widgets = applyFilter(payload);

    this.setState({
      widgets: widgets,
      currPage: payload,
      forceUpdateFilters: false
    });
  };

  changeLoading = loading => {
    if (this.state.loading != loading) {
      this.setState({
        loading
      });
    }
  };







  selectPage = pageId => {
    const { [PAGE_FIELD]: Module } = this.props;

    let newModule = Module.map(management => {

      if (management.id === pageId) {
        return R.assoc('selected', true, management)
      }

      return R.assoc('selected', false, management)
    })

    const currPage = R.find(R.propEq('id', pageId))(Module);
    const widgets = applyFilter(currPage);

    this.props.updateProps('management', newModule)


    this.setState({
      currPage: currPage,
      widgets: widgets
    });
  };

  render() {
    const { classes, history, fixedFilter } = this.props;
    const { widgets, devices, currPage, parks_info } = this.state;


    if (!widgets || widgets.length == 0 || !currPage) {
      return (
        <ManagementContent>

          <BaseGlobalFilter
              mode={'view'}
              history={history}
              page={currPage}
              reducer_name={REDUCER_MANAGEMENT_GET_DATA}
              module_field={'Management'}
              executeFilter={this.executeFilter}
              goToPage={this.selectPage}
              disabled={this.state.loading}
              enableEdit={() => this.setState({ enableEdit: true })}
              {...this.props}
            >
              

              <Selector updateFilters={this.executeFilter}   page={currPage} />

            </BaseGlobalFilter>  

            
        </ManagementContent>
      )
    }
    return (
      <ManagementContent>


         <BaseGlobalFilter
            mode={'view'}
            history={history}
            page={currPage}
            reducer_name={REDUCER_MANAGEMENT_GET_DATA}
            module_field={'Management'}
            executeFilter={this.executeFilter}
            goToPage={this.selectPage}
            disabled={this.state.loading}
            enableEdit={() => this.setState({ enableEdit: true })}
            {...this.props}
          >
            

            <Selector updateFilters={this.executeFilter}   page={currPage} />

          </BaseGlobalFilter>  

        <div
          style={{
            maxHeight: '100%',
            width: this.state.gridWidth,
            margin: '0 auto'
          }}
        >
          {this.state.gridWidth ? (
            <GridLayout
              className={classes.gridElement}
              cols={12}
              rowHeight={200}
              items={50}
              width={this.state.gridWidth}
              compactType={'horizontal'}
              isDraggable={this.props.editMode}
              isResizable={this.props.editMode}
              preventCollision={true}
            >
              {
                widgets &&
                widgets.map((widget, index) => {
                  return (
                    <div style={{
                      backgroundColor: this.props.editMode ? '#0000003d' : 'none',
                      paddingBottom: this.props.editMode ? '3px' : 'none',

                    }} key={widget.id} data-grid={widget.grid} >

                      { /*
                        this.props.editMode && <div  style={{ backgroundColor: 'white', textAlign: 'center' }} >
                        <IconButton
                          onClick={this.props.openDialogRemove(widget.id)}
                        >
                          <DeleteIcon />
                        </IconButton>

                        </div>
                      */ }
                      <WidgetDriver
                        key={`${index}${widget.id}`}
                        classes={classes}
                        selectedPage={currPage}
                        parks={parks_info}
                        history={history}
                        changeLoading={this.changeLoading}
                        widget={widget}
                        reducer_name={REDUCER_MANAGEMENT_GET_DATA}
                        module_id={'Management'}
                        isEditing={this.props.editMode}
                        openRemoveDialog={this.props.openDialogRemove(widget.id)}
                      />
                    </div>
                  );
                })
              }
            </GridLayout>
          ) : ''}
        </div>
      </ManagementContent>
    )
  }
}

const mapStateToProps = ({ Dashboard, User, DevicesHierarchy, Client }) => {
  const { Management } = Dashboard
  const { subparks, devices, parks } = Client

  return { Dashboard, Management, parks_info: Object.values(DevicesHierarchy), subparks, devices, parks }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateFilterSubparks, updateFilterTimeRange }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManagementPage))
