import React from 'react';

import {
    TablePagination,
    TableRow
} from '@material-ui/core';

import PaginatorActions from './components/PaginatorActions';

import { useTranslation } from 'react-i18next';


const View = props => {

    const {
        count,
        page,
        rowsPerPage,
        setPage,
        setRowsPerPage,
        rowsPerPageOptions = [8, 16, 32]
    } = props;

    const { t } = useTranslation();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };
    

    return (
        <TableRow>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                colSpan={12}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                    native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={PaginatorActions}
                labelRowsPerPage={`${t('Rows per page')}:`}
            />
        </TableRow>
    );
};

export default View;