import axios from 'axios'
import {Auth} from 'aws-amplify'

import { URL_NODE_API } from '../../../../../constants/url-constant'

const URL_API = `${URL_NODE_API}/inventory`

export const getAssetComponentByAssetIdProvider = async (id) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = `${URL_API}/asset-component/${id}/asset`

  const {data} = await axios.get(URL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return data
}