import React, {Component} from 'react'
import queryString from 'query-string'
import axios from 'axios'
import {injectGlobal} from 'styled-components'
import grey from '@material-ui/core/colors/grey'
// import { renderToStaticMarkup } from 'react-dom/server'

import { pageResizeHelper } from '../../helpers/page-size-helper'
import { PAGE_SIZE_A4, PAGE_SIZE_TYPE_WIDTH } from '../../constants/page-constant'
import logoOmega from '../../assets/report/omega/omega.png'
import headerBlue from '../../assets/report/header.png'
import { URL_NODE_API_PUBLIC } from '../../constants/url-constant'
import imageFooter from '../../assets/report/footer.png'
import imagePagesInfo from '../../assets/report/pages_info.png'
import imageDelfosLogo from '../../assets/report/main_logo.png'
import GridChartComponent from './components/GridChartComponent/GridChartComponent';

injectGlobal`
  .highcharts-root, .highcharts-background {
    width: 100%!important;
    height: 100%!important;
  }
`

const HEADER_HEIGHT_PERCENT = 10
const FOOTER_HEIGHT_PERCENT = 10

class Report extends Component {
  state = {
    pageHeight: 0,
    pageWidth: 0,
    pages: []
  }

  constructor (props) {
    super (props)

    this.state = {
      pageHeight: 0,
      pageWidth: 0,
      pages: [],
      report: {}
    }
  }

  resizePage = () => {
    const w = window.innerWidth;
    const h = window.innerHeight;

    return pageResizeHelper(PAGE_SIZE_A4, w, PAGE_SIZE_TYPE_WIDTH)
  }

  resizePageWatch = () => {
    const {width, height} = this.resizePage()

    const body = window.document.getElementsByTagName('body')[0]

    body.style.height = (height * this.state.pages.length) + 'px'
    body.style.width = width + 'px'

    this.setState({
      pageHeight: height,
      pageWidth: width
    })
  }

  componentDidMount () {
    const {token} = queryString.parse(this.props.location.search)
    
    window.addEventListener("resize", this.resizePageWatch)

    const {width, height} = this.resizePage()

    axios.get(`${URL_NODE_API_PUBLIC}/report?token=${token}`)
      .then(({data}) => {
        const body = window.document.getElementsByTagName('body')[0]

        body.style.height = (height * data.pages.length) + 'px'
        body.style.width = width + 'px'

        this.setState({
          report: data,
          pages: data.pages,
          pageHeight: height,
          pageWidth: width
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  gridLayoutChange = (e) => {
    
  }

  pageView = (page, indexPage) => {
    return (
      <div
        key={page.id}
        style={{
          width: this.state.pageWidth,
          height: this.state.pageHeight,
        }}
      >
        <div
          style={{
            height: `${HEADER_HEIGHT_PERCENT}%`,
            width: '100%',
            display: 'flex'            ,
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: `1px solid ${grey['200']}`
          }}
        >
          <div
            style={{
              height: '50%',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'stretch',
              justifyItems: 'flex-start'
            }}
          >
            <div
              style={{
                flex: 1,
                // backgroundImage: `url(${logoOmega})`,
                backgroundImage: `url(https://s3.amazonaws.com/delfosim-dashboard-report-images/logo/${this.state.report.client_id}.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center'
              }}
            />
            <div
              style={{
                flex: 1,
                backgroundImage: `url(${headerBlue})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundPosition: 'right',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  color: 'white',
                  fontWeight: 700,
                  marginLeft: '50px',
                  width: 'calc(100% - 50px)',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    // backgroundColor: 'yellow',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      flex: 1
                    }}
                  >
                    {this.state.report.description}
                  </div>
                  <div
                    style={{
                      minWidth: 100,
                      height: '100%',
                      maxHeight: '30px',
                      backgroundImage: `url(${imagePagesInfo})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {`page ${(indexPage + 1)}/${this.state.pages.length}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: `${(100 - (HEADER_HEIGHT_PERCENT + FOOTER_HEIGHT_PERCENT))}%`,
            width: '100%',
            overflow: 'auto'
          }}
        >
          <GridChartComponent
            page={page}
            gridLayoutChange={this.gridLayoutChange.bind(this)}
          />
        </div>
        <div
          style={{
            height: `${FOOTER_HEIGHT_PERCENT}%`,
            width: '100%',
            backgroundImage: `url(${imageFooter})`,
            backgroundPosition: 'left bottom',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          
        </div>
      </div>
    )
  }

  render () {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          overflow: 'auto'
        }}
      >
        {this.state.pages.map((page, indexPage) => this.pageView(page, indexPage))}
      </div>
    )
  }
}

export default Report
