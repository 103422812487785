import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    list: {
        backgroundColor: theme.palette.background.paper,
        textAlign: 'center',
        width: '100%',
        borderRadius: '5px'
    },
    font: {
        fontFamily: 'inherit'
    }
}));