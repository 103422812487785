import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';


export default class MiniToolbar extends Toolbar {
	render() {
		return (
			<div className="rbc-toolbar">
				<div className="rbc-toolbar-label"><strong>{this.props.label}</strong></div>
			</div>
		);
	};
};