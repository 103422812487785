export const averageGeolocationHelper = (coords, map) => {

  if (!coords || coords.length <= 0) {
    return {
      latitude: 0.0,
      longitude: 0.0
    }
  }

  if (coords.length === 1) {
    return coords[0];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (let coord of coords) {
    let latitude = coord.latitude * Math.PI / 180;
    let longitude = coord.longitude * Math.PI / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  // Tentativa de calcular proporção: Falhou

  // const mapDiv = map && map.current && map.current.getDiv();
  // const mapBounds = mapDiv && mapDiv.getBoundingClientRect();
  // const { width, height } = mapBounds || {width: window.innerWidth, height: window.innerHeight};

  // console.log('MAP BOUNDS? ', mapBounds)
  
  let total = coords.length;

  x = x  / total;
  y = y  / total;
  z = z / total;

  let centralLongitude = Math.atan2(y, x);
  let centralSquareRoot = Math.sqrt(x * x + y * y);
  let centralLatitude = Math.atan2(z, centralSquareRoot);

  
  return {
    latitude: centralLatitude * 180 / Math.PI,
    longitude: centralLongitude * 180 / Math.PI
  };
}