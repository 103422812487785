import React, { Component } from 'react'
import { Icon, LinearProgress } from "@material-ui/core";
import * as R from "ramda";
import moment from 'moment-timezone'


const HEADER_LABEL = 80;

const GRID_WIDTH_PADDING = 10;

class PortfolioHeaderComponent extends Component {


    constructor(props) {
        super(props);

        const elBody = window.document.getElementsByTagName("BODY")[0];

        this.state = {
            gridWidth: elBody.offsetWidth - GRID_WIDTH_PADDING * 2
        };
    }




    render() {
        const powerBarValue = ((100 / this.props.ratedPowerAll) * this.props.powerAll);

        return (
            <div
                style={{
                    minHeight: HEADER_LABEL,
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexFlow: 'row wrap'
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginLeft: 20
                        }}
                    >
                        Parks:
                        <span
                            style={{
                                marginLeft: 5,
                                fontWeight: 900
                            }}
                        >
                            {
                                this.props.items.length
                            }
                        </span>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginLeft: 20
                        }}
                    >
                        WTG Availability:
                        <span
                            style={{
                                marginLeft: 5,
                                fontWeight: 900
                            }}
                        >
                            <span>{this.props.WtgAll - this.props.WtgStoppedAll}</span> / <span>{this.props.WtgAll}</span>
                        </span>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginLeft: 20
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                            }}
                        >
                            Power (MW):
                        <span
                                style={{
                                    marginLeft: 5,
                                    fontWeight: 900
                                }}
                            >
                                {(this.props.powerAll / 1000).toFixed(1)}
                            </span>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                marginTop: 3
                            }}
                        >
                            <LinearProgress
                                style={{
                                    height: 4
                                }}
                                variant="determinate"
                                value={powerBarValue > 100 ? 100 : powerBarValue}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginLeft: 20
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center"
                            }}
                        >
                            Wind Speed (m/s):
                        <span
                                style={{
                                    marginLeft: 5,
                                    fontWeight: 900
                                }}
                            >
                                {isNaN(this.props.windMedian) ? 0 : this.props.windMedian.toFixed(1)}
                            </span>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                marginTop: 3
                            }}
                        >
                            <LinearProgress
                                style={{
                                    // height: 10
                                }}
                                variant="determinate"
                                value={isNaN(this.props.windMedian) ? 0 : ((100 / 20) * this.props.windMedian)}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginLeft: 20
                        }}
                    >
                        Last Update:
                        <span
                            style={{
                                marginLeft: 5,
                                fontWeight: 900
                            }}
                        >
                            {
                                R.last(this.props.timeAllSort) && R.last(this.props.timeAllSort).time
                                    ?
                                    moment.duration(moment().diff(moment(R.last(this.props.timeAllSort).time))).humanize()
                                    :
                                    null
                            }
                        </span>
                    </div>

                </div>
            </div>
        )
    }
}

export default PortfolioHeaderComponent
