import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/ActionStyle';
import SelectorInput from './SelectorInput';
import SelectorDaypicker from './SelectorDaypicker';
import DayButton from './DayButton';
import Typography from '@material-ui/core/Typography';
import { SELECTORS } from './placeholder-data/FixedDataFromAPI';
import { SelectorContext } from './Contexts';
import { withTranslation } from 'react-i18next';


const SelectorsList = ({ classes, isVertical, selectors = SELECTORS, side = null, t }) => {
    return (
        <SelectorContext.Consumer>
            {
                provider => (
                    selectors && selectors.constructor === Array && (
                        selectors.map(selector => (
                            (selector.type === 'select' && (
                                <SelectorInput selector={selector} {...provider} side={side} />
                            )) ||
                            (selector.type === 'date' && (
                                <SelectorDaypicker selector={selector} {...provider} >
                                    <div className={isVertical && classes.horizontalAlign}>
                                        <Typography
                                            variant='subtitle1'
                                        >
                                            {t(selector.name)}
                                        </Typography>
                                        <DayButton />
                                    </div>
                                </SelectorDaypicker>
                            ))
                        ))
                    )
                )
            }
        </SelectorContext.Consumer>
    )
};

export default withTranslation()(withStyles(styles)(SelectorsList));