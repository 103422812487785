export const URL_API_OLD = process.env.REACT_APP_API_3

export const URL_API = process.env.REACT_APP_API_2

export const URL_NODE_API = `${process.env.REACT_APP_API_1}/v1/api`
export const URL_WEBSOCKET_API = process.env.REACT_APP_API_WEBSOCKET

export const URL_NODE_API_ASSET = `${process.env.REACT_APP_API_1}/v1/api-asset`
// export const URL_NODE_API_ASSET = URL_NODE_API

export const URL_NODE_API_REPORT = `${process.env.REACT_APP_API_1}/v1/api`
export const URL_NODE_API_PUBLIC = `${URL_NODE_API_REPORT}/public`

export const URL_FISSION = `${process.env.REACT_APP_FISSION_API || 'http://0.0.0.0:8001'}/pnp`;

export const URL_NEW_VERSION = process.env.REACT_APP_URL_NEW_VERSION || 'https://dashboard.delfos.im';