import React, {Component} from 'react';
import * as R from 'ramda';

const StackBars = () => {
  const real_StackBars = state => {
    try {
      state.data_without_stack = JSON.parse(JSON.stringify(state.data));

      let enable_stacking = state.StackBars__switch;

      state.data = R.assoc('enable_stacking', enable_stacking, state.data);
    } catch (e) {
      state.data = JSON.parse(JSON.stringify(state.data_without_stack));
      delete state.data_without_stack;
    } finally {
      return state;
    }
  }

  return(real_StackBars);
};

export default StackBars;
