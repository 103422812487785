import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as R from "ramda";
import _ from "lodash";
import HighchartsMore from "highcharts/js/highcharts-more";

HighchartsMore(Highcharts);

class WindRoseComponent extends React.Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();
  }

  render() {
   


    if (this.props.items.length <= 0) {
      return ('Loading...')
    }

    const windData = _.sortBy(
      R.flatten(this.props.items.map(({ wtgs }) => wtgs)).map(wtg => {
        const windSpeedTag = R.find(R.propEq("var_id", 2))(wtg.realtime);
        const windSpeed = windSpeedTag ? windSpeedTag.value : 0;

        const windDirTag = R.find(R.propEq("var_id", 23))(wtg.realtime);
        const windDir = windDirTag ? windDirTag.value : 0;

        return {
          id: wtg.id,
          name: wtg.name,
          windDir,
          windSpeed
        };
      }),
      ["windDir"]
    );

    const sumSin2 =
      R.sum(
        windData.map(
          wtg =>
            wtg.windSpeed *
            window.Math.sin((wtg.windDir * window.Math.PI) / 180)
        )
      ) / windData.length;
    const sumCos2 =
      R.sum(
        windData.map(
          wtg =>
            wtg.windSpeed *
            window.Math.cos((wtg.windDir * window.Math.PI) / 180)
        )
      ) / windData.length;

    const averageSpeed = window.Math.sqrt(
      R.sum([window.Math.pow(sumSin2, 2), window.Math.pow(sumCos2, 2)])
    );

    const averageDir =
      window.Math.atan2(sumSin2, sumCos2) * (180 / window.Math.PI);

    // const sumSin = R.sum(windData.map(wtg => window.Math.sin((wtg.windDir)*window.Math.PI/(180))))
    // const sumCos = R.sum(windData.map(wtg => window.Math.cos((wtg.windDir)*window.Math.PI/(180))))
    // const average = (window.Math.atan2(sumSin, sumCos)*(180/window.Math.PI))

    // console.log("average", sumSin2, sumCos2, averageDir, averageSpeed);

    const categories = [
      "N",
      "NNE",
      "NE",
      "ENE",
      "E",
      "ESE",
      "SE",
      "SSE",
      "S",
      "SSW",
      "SW",
      "WSW",
      "W",
      "WNW",
      "NW",
      "NNW"
    ];

    const params = {
      series: [
        {
          type: "area",
          data: windData.map(wtg => ({
            id: wtg.id,
            name: wtg.name,
            x: wtg.windDir ? wtg.windDir : 0 ,
            y: wtg.windSpeed ? wtg.windSpeed :0
          }))
        },
        // {
        //   type: "windbarb",
        //   data: [
        //     {
        //       // id: wtg.id,
        //       name: 'Average',
        //       x: averageSpeed,
        //       y: averageDir
        //     }
        //   ],
        //   name: "Wind",
        //   color: Highcharts.getOptions().colors[1],
        //   showInLegend: false,
        //   tooltip: {
        //     valueSuffix: " m/s"
        //   }
        // }
        {
          type: "column",
          borderColor: "red",
          color: "red",
          borderWidth: "6",
          data: [
            {
              name: "Average",
              x: averageDir,
              y: averageSpeed
            }
          ]
        }
      ],
      chart: {
        polar: true,
        // type: 'columnpyramid',
        backgroundColor: "rgba(255, 255, 255, 0.0)"
      },
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      pane: {
        size: "85%"
      },
      legend: {
        enabled: false
        // align: "right",
        // verticalAlign: "top",
        // y: 100,
        // layout: "vertical"
      },
      xAxis: {
        min: 0,
        max: 360,
        type: "",
        tickInterval: 22.5,
        tickmarkPlacement: "on",
        labels: {
          formatter: function() {
            return categories[this.value / 22.5] + "°";
          },
          style: {
            color: "#ffffff"
          }
        }
      },
      yAxis: {
        min: 0,
        max: 20,
        endOnTick: false,
        showFirstLabel: false,
        showLastLabel: false,
        labels: {
          style: {
            color: "#ffffff"
          },
          formatter: function() {
            return this.value;
          }
        }
        // reversedStacks: false
      },
      tooltip: {
        borderWidth: 0,
        borderRadius: 0,
        shadow: false,
        useHTML: true,
        formatter: function() {
          return `
              <div class="tooltip">
                <div>
                  <span>Name: </span><span style="font-weight: 700;">${
                    this.key
                  }</span>
                </div>
                <div>
                  <span>Direction: </span><span style="font-weight: 700;">${Number(
                    this.x
                  ).toFixed(1)}°</span>
                </div>
                <div>
                  <span>Wind speed: </span><span style="font-weight: 700;">${Number(
                    this.y
                  ).toFixed(1)} m/s</span>
                </div>
              </div>
            `;
        }
      },
      // tooltip: {
      //   valueSuffix: "%"
      // },
      plotOptions: {
        // column: {
        //   pointWidth: 40
        // },
        series: {
          stacking: "normal",
          shadow: false,
          groupPadding: 0,
          pointPlacement: "on"
          // marker: {
          //   symbol: 'triangle'
          // }
        }
      }
    };

    return (
      <div
        style={{
          height: "100%",
          width: "100%"
        }}
      >
        <HighchartsReact
          containerProps={{
            style: {
              width: "100%",
              height: "100%",
              // marginTop: 30,
              overflow: "hidden"
            }
          }}
          ref={this.refChart}
          highcharts={Highcharts}
          // constructorType={'stockChart'}
          options={params}
        />
      </div>
    );
  }
}

export default WindRoseComponent;
