import React, {Component} from 'react';
import SelectorsList from '../SelectorsList';
import CommentsList from '../CommentsList';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles/ActionStyle';

class MainForm extends Component { 
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, isVertical } = this.props;

        return (
            <React.Fragment>
                <div className={!isVertical ? classes.horizontalAlign : classes.verticalAlign}>
                    <SelectorsList {...this.props} />
                </div>
                <div className={classes.verticalAlign}>
                    <CommentsList {...this.props} />
                </div>
            </React.Fragment>
        );
    }
};

export default withStyles(styles)(MainForm);