import { useState } from 'react';

export default props => {
    
    const [loading, setLoading] = useState(false);

    const onClick = props.onClick ?
        async () => {
            setLoading(true);
            await props.onClick();
            setLoading(false);
        }
        :
        null;

    const disabled = onClick ? false : true;

    return {
        checkedProps: { disabled, ...props, onClick },
        loading
    };
};
