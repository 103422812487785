import * as R from 'ramda'
import uuidv4 from 'uuid/v4'

import {store} from '../store/ProviderStore'
import { REDUCER_MANAGEMENT_GET_DATA } from '../constants/reducers/management-get-data';
import { REDUCER_PAGE_GET_DATA } from '../constants/reducers/page-get-data';

// import dataManagement from '../../../../../data/data-management.json'

export const editModeChangeTitle = (management_id, reducer_name, action, value, data = null) => {
  return dispatch => {
    const Dashboard = data

    const Module = reducer_name.toLowerCase() in Dashboard ? Dashboard[reducer_name.toLowerCase()] : []

    return Module.map(manag => {
        if (manag.id === management_id) {
          return R.assoc('name', value, manag)
        }

        return manag
      })
  }
}

export const editModeChangePosition = (management_id, reducer_name, action, positions, data = null) => {
  return dispatch => {
    const Dashboard = data
    const Module = reducer_name.toLowerCase() in Dashboard ? Dashboard[reducer_name.toLowerCase()] : []
        

    return Module.map(manag => {
        if (manag.id === management_id) {
          const widgets = manag.widgets.map((widget) => {
            const position = R.find(R.propEq('i', `${widget.id}`))(positions)

            if (position) {
              return R.assoc('grid', {
                ...widget.grid,
                x: position.x,
                y: position.y,
                h: position.h,
                w: position.w,
              }, widget)
            }

            return widget
          })

          return R.assoc('widgets', widgets, manag)
        }

        return manag
      })
  }
}

export const editModeRemoveWidget = (management_id, reducer_name, action, widget_id, data = null) => {
  return dispatch => {
    const Dashboard = data
    const Module = reducer_name.toLowerCase() in Dashboard ? Dashboard[reducer_name.toLowerCase()] : []


    return Module.map(management => {
        if (management.id === management_id) {
          const widgets = management.widgets.map(widget => {


            if (widget.id === widget_id) {
              return false
            }

            return widget
          })
          .filter(widget => widget)


          return R.assoc('widgets', widgets, management)
        }

        return management
      })
  }
}

export const editModeNewWidget = ( module_id, reducer_name, action, widgetNew, data = null) => {
  return dispatch => {
  
    const Dashboard = data
    const Module = reducer_name.toLowerCase() in Dashboard ? Dashboard[reducer_name.toLowerCase()] : []

    const widget = {
      ...widgetNew,
      update: true,
      filter: null,
      filterGlobal: true,
      postProcessDecorators: null,
      preProcessDecorators: null,
      id: uuidv4()
    }

      return Module.map(module => {
        if (module.id === module_id) {
          const widgets = R.append(widget, module.widgets)

          return R.assoc('widgets', widgets, module)
        }

        return module
      })
  
  }
}

export const editModeEditWidget = (management_id, reducer_name, action, widgetEdit, data = null) => {
  return dispatch => {

    const Dashboard = data
    const Module = reducer_name.toLowerCase() in Dashboard ? Dashboard[reducer_name.toLowerCase()] : []


    return Module.map(management => {
        if (management.id === management_id) {
          const widgets = management.widgets.map(widget => {
            if (widget.id === widgetEdit.id) {
              return {
                ...widgetEdit,
                update: true,
                data: false
              }
            }

            return widget
          })

          return R.assoc('widgets', widgets, management)
        }

        return management
      })
  }
}


export const resetChange = (management_id, reducer_name, action, oldPage, data = null) => {
  return dispatch => {

    const Dashboard = data
    const Module = reducer_name.toLowerCase() in Dashboard ? Dashboard[reducer_name.toLowerCase()] : []
    return Module;

  }

}

export const editModeNew = (management_id, reducer_name, action, newPage, data = null) => {
  return dispatch => {

    const Dashboard = data
    const Module = reducer_name.toLowerCase() in Dashboard ? Dashboard[reducer_name.toLowerCase()] : []
    let currPage = R.find(R.propEq('selected', true))(Module);


    let id = uuidv4();
    const management = {
      ...newPage,
      id: id,
      filter: currPage.filter,
      selected:true,
      widgets:[]
    }

    return R.append(management,
          Module.map(management => {
          if (management.id === id) {
            return R.assoc('selected', true, management)
          }

            return R.assoc('selected', false, management)
        }))
  }
}

export const editModeRemove = (management_id, reducer_name, action, data = null) => {
  return dispatch => {
    const Dashboard = data
    const Module = reducer_name.toLowerCase() in Dashboard ? Dashboard[reducer_name.toLowerCase()] : []

    return Module.map(management => {
        if (management.id === management_id) {
          return false
        }

        return management
      })
      .filter(management =>  management)
      .map((management, indexManagement) => {
        if (indexManagement === 0) {
          return R.assoc('selected', true, management)
        }

        return management
      })
  }
}
