import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as HighchartsMore from 'highcharts/js/highcharts-more'
import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import SolidGauge from 'highcharts/modules/solid-gauge';
import * as moment from 'moment'
import static_data from './data.json';
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Menu,
  Paper,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Icon
} from '@material-ui/core';

import styles from './styles/styles.js';
import { CSVLink } from "react-csv";
import NoPrint from '../../../components/Print/NoPrint'

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

Highcharts.setOptions({
  plotOptions: {
    series: {
      animation: false
    }
  }
});



class GraphicGaugeHydro extends Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      bar_colors: [
        '#ffff80',  // yellow
        '#ff4d4d',  // red
        '#b3ffb3',  // green        
        '#000000'
      ],
      ref_colors: [
        '#F4645C',
        '#B414DC',
        '#FCDC34',
        '#4C65A7'
      ]
    }

    this.chartWillRender = this.chartWillRender.bind(this);
    // this.chartDidRender = this.chartDidRender.bind(this);
  }

  componentDidMount() {
    this.props.getSettings(this.getSettings.bind(this));
  }

  showLoading = () => (
    <div style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={(30)} />
      <div style={{
        marginTop: '20px'
      }}>
        Loading...
      </div>
    </div>
  )

  // chartDidRender (state, updating, loading) {
  //   let postProcessDecorators = this.props.graphic.postProcessDecorators;
  //
  //   if(postProcessDecorators) {
  //
  //     setTimeout(() => {
  //       if(!updating && !loading) {
  //         setTimeout(() => {
  //           postProcessDecorators.map(decorator_json => {
  //             const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
  //             const result = decorator(state);
  //
  //             state = result;
  //           });
  //         }, 3000);
  //       } else {
  //         this.chartDidRender(state, updating, loading);
  //       }
  //     }, 200)
  //   }
  // }

  caulculateFontSizeHead(grid) {


    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }


  chartWillRender(state) {
    // let preProcessDecorators = this.props.graphic.preProcessDecorators;
    //
    // if(preProcessDecorators) {
    //   preProcessDecorators.map(decorator_json => {
    //     const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
    //     const result = decorator(state);
    //
    //     state = result;
    //   });
    // }
  }

  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = checkbox => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);
  };

  getSettings() {
    // let data = BAR_DECORATOR_LIST.map(decorator => (
    //   this.state[`switch${decorator.name}`] ?
    //   {
    //     name: `${decorator.name}`,
    //     params: 'params_post_treatment' in decorator && decorator.params_post_treatment ?
    //               decorator.params_post_treatment(
    //                 Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //               )
    //               : Object.keys(this.state)
    //                       .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('switch')))
    //                       .map(key => this.state[key])
    //   }
    //   : null
    // )).filter(decorator => decorator);
    //
    // return data;
  }


  shouldComponentUpdate(nextProps, nextState) {

    let { items } = nextProps;
    let event_name = nextProps.widget.event.concat('#', nextProps.subpark);
    let old_event_name = this.props.widget.event.concat('#', this.props.subpark);

    if (this.props.subpark != nextProps.subpark || this.props.showTable != nextProps.showTable || this.props.showExport != nextProps.showExport) {
      return true;
    }


    if (this.state.column_sort != nextState.column_sort || this.state.is_crescent != nextState.is_crescent) {

      return true;
    }


    if (items[event_name]) {

      if (items[event_name] === this.props.items[old_event_name]) {

        return false;
      }

      return true;

    }

    return false;

  }

  render() {

    const { items, subpark, classes, widget, showTable, showSettings, showExport } = this.props;
    let { data } = this.state;

    let graphic = widget;
    let event_name = widget.event.concat('#', subpark);

    let font_size = 16

    data = items[event_name];

    if (!data) {
      return (
        <Paper className={classes.paperElement} elevation={0}>
          {this.showLoading()}
        </Paper>
      )
    }

    var on_render_state = {
      ...this.state,
      data: data
    };

    this.chartWillRender(on_render_state);

    const maxValue = on_render_state.data.series
      ? on_render_state.data.series.map(series => series.value).reduce((acc, curr) => acc > curr ? acc : curr) * 1.1
      : null


    const goal = on_render_state.data.series
      ? on_render_state.data.series.filter(series => series.name == 'Meta')
      : []

    const references = on_render_state.data.series
      ? on_render_state.data.series.filter(series => series.type == 'reference')
      : []


    const bars = on_render_state.data.series
      ? on_render_state.data.series.filter(series => series.type == 'bar').sort((s1, s2) => s2.value - s1.value)
      : []

    const tableData = {
      'headers': ['Value'],
      'cross_headers': references.concat(bars).map(series => series.name),
      'rows': references.concat(bars).map(series => new Array(
        `${series.value ? series.value.toFixed(2) : 'No Data'} ${on_render_state.data.unit}`
      ))
    };


    const tableDataExcel = {
      'headers': ['Value'],
      'cross_headers': references.concat(bars).map(series => series.name),
      'rows': references.concat(bars).map(series => new Array(
        `${series.value ? series.value : 'No Data'} ${on_render_state.data.unit}`
      ))
    };


    // `${graphic.grid.h == 1 ? 45 : graphic.grid.h == 2 ? 55 : graphic.grid.h == 3 ? 65 : 75}%`

    const params = {
      // colors: on_render_state.colors,
      chart: {
        type: 'solidgauge'
      },
      plotShadow: false,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingBottom: 0,
      credits: {
        enabled: false
      },
      title: null,
      pane: {
        center: ['50%', '85%'],
        size: `${font_size * 12.5 < 140 ? font_size * 12.5 : 150}%`,
        startAngle: -90,
        endAngle: 90,
        background: null
      },

      tooltip: {
        enabled: false
      },

      // the value axis
      yAxis: {
        // stops: [
        //     [0.1, '#55BF3B'], // green
        //     [0.5, '#DDDF0D'], // yellow
        //     [0.9, '#DF5353'] // red
        // lineWidth: 2,
        // ],
        min: 0,
        max: maxValue,
        title: null,
        labels: {
          x: maxValue + (maxValue - goal[0].value),
          y: maxValue + (maxValue - goal[0].value) + 5,
          enabled: true,
          formatter: function () {
            return "Meta: " + this.value.toFixed(2);
          }
        },
        /*labels: {
            //x:30,
            //y:30,
            enabled: true,
             formatter: function() {
                return "Meta: " + this.value.toFixed(2);
          }
        },*/
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        tickColor: '#ffffff',
        tickPositions: [goal[0].value],
        tickPosition: 'outside',
        plotBands: references.map((reference, index) => ({
          from: reference.value,
          to: reference.value + maxValue * 0.005,
          color: '#000000',
          innerRadius: '75%',
          outerRadius: '100%',
          thickness: '5%',
          zIndex: 100
        }))

      },

      plotOptions: {
        solidgauge: {
          innerRadius: 75,
          radius: 100,
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      series: [{
        name: '',
        data: [
          {
            name: 'Background',
            y: maxValue,
            color: '#b3ffb3'
          },
          ...bars.map((bar, index) => (
            {
              name: bar.name,
              color: on_render_state.bar_colors[index],
              y: bar.value
            }
          ))
        ],
        dataLabels: {
          format: `<div style="text-align:center">
                      <span style="font-size:${font_size * 1}px;color:black">${references[0].value ? references[0].value.toFixed(2) : 0}</span><br/>
                      <span style="font-size:${font_size * 0.6}px;color:silver">${references[0].name} em ${on_render_state.data.unit}</span>
                    </div>`


          /*references.map((series, index) => (
            series.name == 'Valor' ?  
            `<div style="text-align:center">
              <span style="font-size:${font_size * 1}px;color:black">${series.value ? series.value.toFixed(2) : 0}</span><br/>
              <span style="font-size:${font_size * 0.6}px;color:silver">${series.name} em ${on_render_state.data.unit}</span>
            </div>
          `: null))*/
          /*.concat(bars.map((series, index) => (`
            <div style="text-align:center">
              <span style="color: ${on_render_state.bar_colors[index]}; font-size: ${font_size}px; vertical-align: top; margin-right: 5px;">&#9632</span>
              <span style="font-size:${font_size * 0.8}px;color:grey;margin-right=2%">${series.name}:</span>
              <span style="font-size:${font_size * 0.9}px;color:black">${series.value ? series.value.toFixed(2) : 0}</span>
              <span style="font-size:${font_size * 0.7}px;color:silver">${on_render_state.data.unit}</span>
            </div>
          `))).join('')*/
        },
        tooltip: {
          valueSuffix: on_render_state.data.unit
        }
      }],
      // [{
      //   name: 'Speed',
      //   data: [{
      //       y: 175,
      //       name: "Point2",
      //       color: on_render_state.colors[0]
      //   }],
      //   dataLabels: {
      //       format: '<div style="text-align:center"><span style="font-size:25px;color:' +
      //           ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y}</span><br/>' +
      //              '<span style="font-size:12px;color:silver">MWh</span></div>'
      //   },
      //   tooltip: {
      //       valueSuffix: ' km/h'
      //   }
      // }],
      exporting:
      {
        enabled: false
      }
    }

    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable || showSettings ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront}>
            <HighchartsReact
              containerProps={{
                style: {
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden'
                }
              }}
              ref={this.refChart}
              highcharts={Highcharts}
              // constructorType={'stockChart'}
              options={params}
            />
          </div>
          <div className={classes.flipContainerBack}>
            {
              showTable ?
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={1}></TableCell>
                      {tableData.headers.map((elm, index) => {
                        if (elm === '0') {
                          return (
                            <TableCell colSpan={1} key={index}>
                              {''}
                            </TableCell>
                          )
                        }

                        return (
                          <TableCell colSpan={1} key={index} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }} >
                            {elm}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.rows.map((row, index) => new Array(tableData.cross_headers[index]).concat(row))
                      .map((row, indexRow) => {
                        return (
                          <TableRow key={indexRow}>
                            {row.map((cell, indexCell) => {
                              return (
                                <TableCell key={indexCell} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px', height: '2px' }}>
                                  {cell}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>

                :

                false ?
                  <List>
                    {
                      // <MenuItem className={classes.applyBtn}>
                      //   <Typography align='center' style={
                      //     {
                      //       width: '100%',
                      //       color: '#ffffff'
                      //     }
                      //   }>Apply Settings</Typography>
                      // </MenuItem>
                    }

                    {
                      //   BAR_DECORATOR_LIST.map((decorator, index) => (
                      //     <div>
                      //       <ListItem
                      //         key={decorator.name}
                      //       >
                      //         <Switch
                      //           checked={this.state[`switch${decorator.name}`]}
                      //           onChange={this.checkboxToggle(`switch${decorator.name}`)}
                      //           value={decorator.name}
                      //         />
                      //         <ListItemText inset primary={`${decorator.name}`} className={classes.liLabel} />
                      //       </ListItem>
                      //       <Collapse in={this.state[`switch${decorator.name}`]} timeout="auto" unmountOnExit>
                      //         <List component="div" disablePadding>
                      //           <ListItem>
                      //             <Grid container spacing={24}>
                      //               {
                      //                 decorator.params_input_types.map((param_type, param_index) => {
                      //                   if(param_type.match('text_field')) {
                      //                     let type = param_type.split('_').pop();
                      //                     let text_field_state = `${decorator.name}_text-field-${type}_${decorator.params_names[param_index]}`;
                      //                     return (
                      //                       <Grid item xs={2} className={classes.liSubItems}>
                      //                         <TextField
                      //                           className={classes.liSubItemsTextArea}
                      //                           label={`${decorator.params_names[param_index]}`}
                      //                           value={this.state[text_field_state]}
                      //                           onChange={this.numberChange(text_field_state)}
                      //                           type={type}
                      //                           InputLabelProps={{
                      //                             shrink: true,
                      //                           }}
                      //                         />
                      //                       </Grid>
                      //                     )
                      //                   } else if(param_type.match('checkbox')) {
                      //                     let checkbox_state = `${decorator.name}_checkbox_${decorator.params_names[param_index]}`;
                      //                     return (
                      //                       <Grid item xs={2} className={classes.liSubItems}>
                      //                         <span>{`${decorator.params_names[param_index]}`}</span>
                      //                         <br/>
                      //                         <Checkbox
                      //                           checked={this.state[checkbox_state]}
                      //                           onChange={this.checkboxToggle(checkbox_state)}
                      //                           value={decorator.params_names[param_index]}
                      //                         />
                      //                       </Grid>
                      //                     )
                      //                   }
                      //                 })
                      //               }
                      //             </Grid>
                      //           </ListItem>
                      //         </List>
                      //       </Collapse>
                      //       <Divider />
                      //     </div >
                      // ))
                    }
                  </List>

                  :

                  <NoPrint>
                    <h3 style={{
                      width: '100%',
                      height: 'auto',
                      textAlign: 'center'
                    }}>Returning to Chart...</h3>
                  </NoPrint>
            }
          </div>

          <Dialog
            onClose={this.props.closeDialogExportOverview}
            open={showExport}
            aria-labelledby="dialog_overview_export"
          >
            <DialogTitle id="dialog_overview_export">
              Export Graphic Data
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please, select the file format to export.
              </DialogContentText>
            </DialogContent>
            <DialogActions>


              <Button
                color="primary"
                style={{ backgroundColor: "green", color: "#fff" }}
                autoFocus
                onClick={(e) => this.props.closeDialogExportOverviewExcel([tableDataExcel.rows.reduce((r, a) => r.concat(a))], tableDataExcel.cross_headers, widget.titleHeader)}

              >
                Excel
              </Button>

              <CSVLink
                style={{ textDecoration: "none" }}
                filename={widget.titleHeader}
                onClick={this.props.closeDialogExportOverview}
                data={tableDataExcel.rows.map((row, index) => new Array(tableDataExcel.cross_headers[index]).concat(row))}

              >
                <Button
                  color="primary"
                  variant="contained"
                  style={{ backgroundColor: "#e65100", color: "#fff" }}
                >
                  CSV
                </Button>
              </CSVLink>

              <Button onClick={this.props.closeDialogExportOverview} color="primary">
                Back
              </Button>
            </DialogActions>
          </Dialog>


        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicGaugeHydro);
