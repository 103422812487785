import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

const CircularLoading = props => (
    <div style={{
        position: props.notOverlay ? 'relative' : 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        backgroundColor: props.notOverlay ? '#ffffff' : '#ffffff80',
        background: props.notOverlay ? '#ffffff' : '#ffffff80',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        zIndex: 999999999999
    }}>
        <CircularProgress size={(30)} />
        <div style={{
            marginTop: '20px'
        }}>
            <p>{props.loading ? props.loading : "Loading..."}</p>
            {props.children}
        </div>
    </div>
);

export default CircularLoading;