import {store} from '../store/ProviderStore'
import * as R from 'ramda'
import GraphTypes, { graphTypesForNewService } from '../constants/graph-types'
import {
  getDataGraphOldProvider,
  getDataGraphNewProvider,
  getDataEnvelopeProvider,
  getDataAlarmsProvider
} from '../providers/get-data-old-provider'
import moment from 'moment-timezone'
import { FILTER_TYPE_WTG_ENVELOPE } from '../constants/management-type';
import { addCheckpoint } from '../providers/get-data-api-n';
import file from '../helpers/fetch';
import WebWorker from '../helpers/WebWorker';
import {URL_API, URL_API_OLD} from '../constants/url-constant'
import {Auth} from 'aws-amplify'

export const TYPE_FILTER_GLOBAL = 'TYPE_FILTER_GLOBAL'
export const TYPE_PROVIDER_NEW = 'TYPE_PROVIDER_NEW'



const getDataFilterGlobal = async (widgetParams = null, date, nameRequest, subparksManag, devicesManag, newProvider = true, alarmsProvider = false) => {
  const {User} = store.getState()
  const {parks_info} = User

  const park01 = R.head(parks_info)

  const devices = devicesManag.length > 0
    ? devicesManag
    : R.flatten(parks_info.map(park => {
        return park.subparks.map(subpark => {
          return subpark.wtgs.map(wtg => wtg.id)
        })
      }))

  const subpark_list = [...new Set(devices.map(device => parseInt(String(device).substring(0,2))))]

  // const subpark_list = subparksManag.length > 0 ?
  //   subparksManag
  // :
  //   R.flatten(parks_info.map(park => {
  //     return park.subparks.filter(subpark => {
  //       const filter = subpark.wtgs.filter(wtg => {
  //         return R.contains(wtg.id, devices)
  //       })
  //
  //       return filter.length > 0
  //     })
  //   })
  //   .filter(park => park.length > 0))
  //   .map(subpark => subpark.id)

  if (newProvider) {
    const params = {
      time_range: [
        date.startDate ? date.startDate : moment().subtract(10, 'days').format('YYYY-MM-DD'),
        date.endDate ? date.endDate : moment().format('YYYY-MM-DD')
      ],
      subpark_list: subpark_list,
      device_list: devices,
      procedure_name: nameRequest,
      timezone: 'America/Fortaleza',
    }

    const result =  await getDataGraphNewProvider(null, null, params)

    if (widgetParams) {
      setDataWidget(widgetParams.module_field, widgetParams.module_id, widgetParams.widget_id, result, widgetParams.reducer_name, widgetParams.dispatch)
    }

    return {}
  } else if(alarmsProvider) {
    const params = {
      time_range: [
        date.startDate ? date.startDate : moment().subtract(10, 'days').format('YYYY-MM-DD'),
        date.endDate ? date.endDate : moment().format('YYYY-MM-DD')
      ],
      subpark_list: subpark_list,
      device_list: devices
    }

    return await getDataAlarmsProvider(nameRequest, params)
  }

  const params = {
    subpark: subpark_list,
    "time_range": [
      date.startDate? date.startDate: park01? moment(park01.t_end).subtract(10, 'days').format('YYYY-MM-DD'): moment().subtract(10, 'days').format('YYYY-MM-DD'),
      date.endDate? date.endDate: park01? moment(park01.t_end).format('YYYY-MM-DD'): moment().format('YYYY-MM-DD')
    ],
    timezone: 'America/Fortaleza'
  }

  const resultOld = await getDataGraphOldProvider(nameRequest, params)

  if (resultOld.result) {
    return resultOld.result
  }

  return resultOld
}

const setDataWidget = async (module_field, module_id, widget_id, data, reducer_name, dispatch) => {
  const {Dashboard} = store.getState()
  const Module = module_field in Dashboard ? Dashboard[module_field] : []

  dispatch({
    type: reducer_name,
    payload: Module.map(module => {
      if (module_id === module.id) {
        const widget = module.widgets.map(wid => {
          if (widget_id === wid.id) {
            const setData = R.assoc('data', data, wid)
            const setNotUpdate = R.assoc('update', false, setData)

            return setNotUpdate
          }

          return wid
        })

        return R.assoc('widgets', widget, module)
      }

      return module
    })
  })


  // const result = (async () => await addCheckpoint(store))()
}

const setUpdate = async (module_field, module_id, widget_id, reducer_name, dispatch, update) => {
  const {Dashboard} = store.getState()
  const Module = module_field in Dashboard ? Dashboard[module_field] : []

  dispatch({
    type: reducer_name,
    payload: Module.map(module => {
      if (module_id === module.id) {
        const widget = module.widgets.map(wid => {
          if (widget_id === wid.id) {
            const setNotUpdate = R.assoc('update', update, wid)

            return setNotUpdate
          }

          return wid
        })

        return R.assoc('widgets', widget, module)
      }

      return module
    })
  })
}

export const getDataWidget = (module_field, module_id, widget, reducer_name, filterLocal = false, filter = false) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    const module = R.find(R.propEq('id', module_id))(Module)

    const graphType = R.find(R.propEq('type', widget.type))(GraphTypes)
    const getInNewProvider = R.contains(widget.type, graphTypesForNewService.map(graph => graph.type))

    if (!graphType) {
      return setUpdate(module_field, module_id, widget.id, reducer_name, dispatch, false)
    }

    if (widget.filterGlobal) {
      const {filter} = module

      if (module.type === FILTER_TYPE_WTG_ENVELOPE) {
        const params = {
          procedure_name: graphType.name,
          time_range: [
            filter.data_range.startDate,
            filter.data_range.endDate
          ],
          timezone: "America/Fortaleza",
          device_list: filter.devices,
          envelope_id: filter.envelope_id
        }

        getDataEnvelopeProvider(params)
          .then(data =>  {
            //
            setDataWidget(module_field, module_id, widget.id, data, reducer_name, dispatch)
          })
          .catch(error => {
            console.error(graphType.name, error)

            setUpdate(module_field, module_id, widget.id, reducer_name, dispatch, false)
          })

      } else {

         let params = {
            module_field : module_field,
            module_id: module_id,
            widget_id:widget.id,
            reducer_name: reducer_name,
            dispatch: dispatch
         }

        getDataFilterGlobal(params, filter.data_range, graphType.name, filter.subparks, filter.devices, getInNewProvider)
          .then((data) => {
            if (data) {
              // setDataWidget(module_field, module_id, widget.id, data, reducer_name, dispatch)
            } else {
              setUpdate(module_field, module_id, widget.id, reducer_name, dispatch, false)
            }
          })
          .catch(error => {
            console.error(graphType.name, error)

            setUpdate(module_field, module_id, widget.id, reducer_name, dispatch, false)
          })
      }
    } else {
      //
      setUpdate(module_field, module_id, widget.id, reducer_name, dispatch, false)
    }
  }
}

export const setPostProcessDecoratorsWidget = (module_field, module_id, widget_id, data, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        if (module_id === module.id) {
          const widget = module.widgets.map(wid => {
            if (widget_id === wid.id) {
              return R.assoc('postProcessDecorators', data, wid)
            }

            return wid
          })

          return R.assoc('widgets', widget, module)
        }

        return module
      })
    })
  }
}

export const setPreProcessDecoratorsWidget = (module_field, module_id, widget_id, data, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        if (module_id === module.id) {
          const widget = module.widgets.map(wid => {
            if (widget_id === wid.id) {
              return R.assoc('preProcessDecorators', data, wid)
            }

            return wid
          })

          return R.assoc('widgets', widget, module)
        }

        return module
      })
    })
  }
}

export const setDataDirectlyWidget = (module_field, module_id, widget_id, data, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        if (module_id === module.id) {
          const widget = module.widgets.map(wid => {
            if (widget_id === wid.id) {
              return R.assoc('data', data, wid)
            }

            return wid
          })

          return R.assoc('widgets', widget, module)
        }

        return module
      })
    })
  }
}

export const setFilterLocalInWidget = (module_field, module_id, widget_id, filter, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        if (module_id === module.id) {
          const widget = module.widgets.map(wid => {
            if (widget_id === wid.id) {
              return R.assoc('filter', filter, wid)
            }

            return wid
          })

          return R.assoc('widgets', widget, module)
        }

        return module
      })
    })
  }
}
