import windPowerIconRed from '../assets/icons/wind-mill-red.svg'
import windPowerIconYellow from '../assets/icons/wind-mill-yellow.svg'
import windPowerIconGreen from '../assets/icons/wind-mill-green.svg'
import windPowerIconBlue from '../assets/icons/wind-mill-blue.svg'
import windPowerIconOrange from '../assets/icons/wind-mill-orange.svg'
import windPowerIconPurple from '../assets/icons/wind-mill-purple.svg'
import windPowerIconGrey from '../assets/icons/wind-mill-grey.svg'
import windPowerIcon from '../assets/icons/wind-mill.svg'

const getStatusALSTOM = (value) => {
  switch (value) {
    case 64:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Cabo retorcido'
      }
    case 65:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Inicio automatico'
      }
    case 66:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Erro de vento'
      }
    case 70:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Erro grave'
      }
    case 71:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Erro do Grid(rede)'
      }
    case 76:
      return {
        color: '#878500',
        icon: windPowerIcon,
        name: 'Vento baixo'
      }
    case 77:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Freio de manutenção acionado'
      }
    case 87:
      return {
        color: '#878500',
        icon: windPowerIcon,
        name: 'Vento alto'
      }
    case 102:
      return {
        color: 'yellow',
        icon: windPowerIconYellow,
        name: 'Aviso'
      }
    case 109:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Freio de manutenção levantado'
      }
    case 118:
      return {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'OK'
      }
    case 165:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Parada manual'
      }
    default:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Not status'
      }
  }
}

const getStatusGE = (value) => {
  switch (value) {
    case 1:
      return {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'Online'
      }
    case 2:
      return {
        color: 'yellow',
        icon: windPowerIconYellow,
        name: 'Impacted'
      }
    case 3:
      return {
        color: '#878500',
        icon: windPowerIcon,
        name: 'Available'
      }
    case 4:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Stopped'
      }
    case 5:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Tripped'
      }
    default:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Not status'
      }
  }
}

const getStatusWEG = (value) => {
  switch (value) {
    case 3:
      return {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'OK'
      }
    case 4:
      return {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'OK'
      }
    case 5:
      return {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'OK'
      }
    case 7:
      return {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'OK'
      }
    case 8:
      return {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'OK'
      }
    case 9:
      return {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'OK'
      }
    case 10:
      return {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'OK'
      }
    case 2:
      return {
        color: '#878500',
        icon: windPowerIcon,
        name: 'Low wind'
      }
    case 1:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Stop'
      }
    case 6:
      return {
        color: 'red',
        icon: windPowerIconRed,
        name: 'Stop'
      }
    default:
      return {
        color: 'grey',
        icon: windPowerIconRed,
        name: 'Not status'
      }
  }
}

const verifyPowerLimitation = (result) => {
  if (result.color !== 'red') {
    return {
      color: 'orange',
      icon: windPowerIconOrange,
      name: 'Power limitation'
    }
  } else {
    return result
  }
}

export default (value, model, maintenance = false, powerLimitation = false) => {
  if (maintenance) {
    return {
      color: 'blue',
      icon: windPowerIconBlue,
      name: 'Maintenance'
    }
  }

  switch (model) {
    case 5101:
      const resultALSTOM = getStatusALSTOM(value)

      if (powerLimitation) {
        return verifyPowerLimitation(resultALSTOM)
      } else {
        return resultALSTOM
      }
    case 3104:
      const resultGE = getStatusGE(value)

      if (powerLimitation) {
        return verifyPowerLimitation(resultGE)
      } else {
        return resultGE
      }
    case 6101:
      const resultWEG = getStatusWEG(value)

      if (powerLimitation) {
        return verifyPowerLimitation(resultWEG)
      } else {
        return resultWEG
      }
    default:
      return value === 1 ? {
        color: 'green',
        icon: windPowerIconGreen,
        name: 'Online'
      } : {
          color: 'grey',
          icon: windPowerIconRed,
          name: 'Not status'
        }
  }
}

export const getStatusByTag = tag => {
  switch (tag) {
    case 'st1':
      return {
        name: 'Running',
        color: 'green',
        icon: windPowerIconGreen
      }
    case 'st2':
      return {
        name: 'Low Wind',
        color: '#878500',
        icon: windPowerIcon
      }
    case 'st3':
      return {
        name: 'Warning',
        color: 'yellow',
        icon: windPowerIconYellow
      }
    case 'st4':
      return {
        name: 'Power Limitation',
        color: 'orange',
        icon: windPowerIconOrange
      }
    case 'st5':
      return {
        name: 'Stopped',
        color: 'red',
        icon: windPowerIconRed
      }
    case 'st6':
      return {
        name: 'Maintenance(Corrective)',
        color: 'purple',
        icon: windPowerIconPurple
      }
    case 'st7':
      return {
        name: 'Maintenance(Preventive)',
        color: 'blue',
        icon: windPowerIconBlue
      }
    default:
      return {
        name: 'No Communication',
        color: 'grey',
        icon: windPowerIconGrey
      }
  }
}