import { Auth } from 'aws-amplify';
import axios from 'axios';

import { URL_API } from '../../../../../../constants/url-constant'
// const ORIGIN = 'http://0.0.0.0:5000';

export const getWtgStatus = async params => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  // const URL = `${ORIGIN}/crud_status/get_wtg_status`;
  const URL = `${URL_API}/crud_status/get_wtgs_events`;

  try {
    const { data } = await axios.post(
      URL,
      {
        ...params
      },
      {
        headers: {
          'Authorization': jwtToken
        }
      }

    )

    return data
  } catch (e) {
    console.log('ERROR ON GETTING WTG STATUS: ', e)
    return null
  }
}

export const getAlarms = async ({ scada_ids }) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_API}/crud_status/get_alarms`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Authorization': jwtToken
      },
      params: { scada_ids: scada_ids }
    }
  )

  return data
}

export const getClassifications = async () => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_API}/crud_status/get_classifications`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getSystems = async () => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_API}/crud_status/get_systems`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getResponsibilities = async () => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_API}/crud_status/get_responsibilities`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const editStatus = async ({ contract_id, system_id, classification_id, responsibility_id, alarm_id, comment }) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { username } = payload || {};


  const URL = `${URL_API}/crud_status/edit_event`;

  const { data } = await axios.post(
    URL,
    {
      user_name: username,
      system_id,
      contract_id,
      classification_id,
      responsibility_id,
      alarm_id,
      comment
    },
    {
      headers: {
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const createStatus = async ({ device_id, start_time, end_time, system_id, classification_id, responsibility_id, alarm_id, comment }) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_API}/crud_status/create_event`;

  // console.log('ON CREATE STATUS ACTION', { device_id, start_time, end_time, classification_id, alarm_id, comment})

  const { data } = await axios.post(
    URL,
    {
      device_id: device_id || null,
      start_time: start_time || null,
      end_time: end_time || null,
      tz: 'America/Fortaleza',
      system_id: system_id || null,
      classification_id: classification_id || null,
      responsibility_id: responsibility_id || null,
      alarm_id: alarm_id || null,
      comment: comment || null
    },
    {
      headers: {
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const aproveStatus = async (payload) => {

  const { approved, status_base_id, status_cut_id, checked_ids } = payload;
  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload: awsPayload } = accessToken;
  const { username } = awsPayload || {};

  const URL = `${URL_API}/crud_status/check_event`;

  const { data } = await axios.post(
    URL,
    {
      user_name: username,
      approved,
      checked_ids
    },
    {
      headers: {
        'Authorization': jwtToken
      }
    }
  )

  return data
}


export const deleteStatus = async ({ contract_id }) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { username } = payload || {};

  const URL = `${URL_API}/crud_status/delete_event`;

  const { data } = await axios.post(
    URL,
    {
      user_name: username,
      contract_id
    },
    {
      headers: {
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const mergeStatus = async ({ contract_id, contract_ids, system_id, classification_id, responsibility_id, alarm_id, comment }) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { username } = payload || {};


  const URL = `${URL_API}/crud_status/merge_event`;

  const { data } = await axios.post(
    URL,
    {
      user_name: username,
      contract_ids,
      system_id,
      classification_id,
      responsibility_id,
      alarm_id,
      comment
    },
    {
      headers: {
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const splitStatus = async ({ contract_id, cut_time, left_status, right_status }) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { username } = payload || {};

  const URL = `${URL_API}/crud_status/split_event`;

  const { data } = await axios.post(
    URL,
    {
      user_name: username,
      contract_id,
      cut_time,
      left_status,
      right_status,
      tz: 'America/Fortaleza'
    },
    {
      headers: {
        'Authorization': jwtToken
      }
    }
  )

  return data
}