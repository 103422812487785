import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import {Auth} from 'aws-amplify'
import {
  withStyles,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Tooltip
} from '@material-ui/core'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import * as R from 'ramda'

import { URL_NODE_API_ASSET } from '../../../../../../constants/url-constant'
import { grey } from '@material-ui/core/colors';
import { toast } from 'react-toastify';

const URL = `${URL_NODE_API_ASSET}/inventory/component`
const URL_ITEM = `${URL_NODE_API_ASSET}/inventory/component/item`

const styles = {
  textField: {
    width: '100%',
    marginBottom: 10
  },
  thIconButton: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${grey['200']};

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid ${grey['200']};
  }

  tbody tr.primary-item:hover {
    background-color: #f5f5f5;
  }
`

class ComponentPage extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      loading: false,
      data: [],
      item: {},
      component: {},
      dialogNew: false,
      dialogNewLoading: false,
      itemNewName: '',
      itemNewPartNumber: '',
      itemNewManufacturer: '',
      
      dialogNewItem: false,
      dialogNewItemLoading: '',
      itemNewItemSerialNumber: '',
      itemNewItemSupplier: '',

      dialogDeleteItem: false,

      dialogDeleteSubItem: false,

      dialogEdit: false,

      dialogEditItem: false
    }
  }

  componentDidMount () {
    this.setState({loading: true})

    this.getItems()
      .then(({data}) => {
        this.setState({
          data,
          loading: false
        })
      })
      .catch(error => {
        console.error('ERROR', error)
        this.setState({loading: false})
      })
  }

  getItems = () => {
    return Auth.currentSession()
      .then(({accessToken}) => {
        const {jwtToken} = accessToken

        return axios.get(
          URL,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': jwtToken
            }
          }
        )
      })
  }

  render () {
    const {classes} = this.props

    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress className={classes.progress} />

          <span
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </span>
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          padding: '0 10'
        }}
      >
        <div
          style={{
            height: `100%`,
            width: 'calc(100% - 20px)',
            marginLeft: 10,
            marginTop: 10
          }}
        >
          <TableStyled>
            <thead>
              <tr>
                <th></th>
                <th>
                  Name
                </th>
                <th>
                  Part number
                </th>
                <th>
                  Manufacturer
                </th>
                <th
                  style={{
                    textAlign: 'right'
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.data.map((item, indexItem) => {
                  return (
                    <React.Fragment
                      key={indexItem}
                    >
                      <tr
                        className="primary-item"
                      >
                        {
                          item.components.length > 0
                          ?
                          (
                            <td
                              className={classes.thIconButton}
                              style={{
                                width: '30px'
                              }}
                              onClick={() => {
                                this.setState(state => ({
                                  data: state.data.map(dataItem => {
                                    if (dataItem.id === item.id) {
                                      return R.assoc('show', !item.show, item)
                                    }

                                    return dataItem
                                  })
                                }))
                              }}
                            >
                              {
                                item.show
                                ?
                                (
                                  <KeyboardArrowDown />
                                )
                                :
                                (
                                  <KeyboardArrowRight />
                                )
                              }
                            </td>
                          )
                          :
                          (
                            <td
                              style={{
                                width: '30px'
                              }}
                            />
                          )
                        }   
                        <td>
                          {item.name}
                        </td>
                        <td>
                          {item.part_number}
                        </td>
                        <td>
                          {item.manufacturer}
                        </td>
                        <td
                          style={{
                            width: 80,
                            textAlign: 'right'
                          }}
                        >
                          <Tooltip
                            placement="bottom"
                            title="New item"
                          >
                            <AddIcon
                              className={classes.thIconButton}
                              onClick={() => this.setState({
                                dialogNewItem: true,
                                item
                              })}
                            />
                          </Tooltip>
                          <Tooltip
                            placement="bottom"
                            title="Edit"
                          >
                            <EditIcon
                              className={classes.thIconButton}
                              onClick={() => {
                                this.setState({
                                  dialogEdit: true,
                                  item
                                })
                              }}
                            />
                          </Tooltip>

                          {
                            item.components.length > 0
                            ? null
                            :
                            (
                              <Tooltip
                                placement="bottom"
                                title="Delete"
                              >
                                <DeleteIcon
                                  className={classes.thIconButton}
                                  onClick={
                                    () => this.setState({
                                      dialogDeleteItem: true,
                                      item
                                    })
                                  }
                                />
                              </Tooltip>
                            )
                          }
                        </td>
                      </tr>

                      {
                        item.show
                        ?
                        (
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                padding: 20
                              }}
                            >
                              <TableStyled>
                                <thead>
                                  <tr>
                                    <th>
                                      Suplier
                                    </th>
                                    <th>
                                      Serial number
                                    </th>
                                    <th>
                                      Acquired date
                                    </th>
                                    <th
                                      style={{
                                        textAlign: 'right'
                                      }}
                                    >
                                      Actions
                                    </th>
                                  </tr>           
                                </thead>
                                <tbody>
                                  {
                                    item.components.map((component, indexComponent) => {
                                      return (
                                        <tr
                                          key={indexComponent}
                                          className="primary-item"
                                        >
                                          <td>
                                            {component.supplier}
                                          </td>
                                          <td>
                                            {component.serial_number}
                                          </td>
                                          <td>
                                            {component.acquired_date}
                                          </td>
                                          <td
                                            style={{
                                              width: 50,
                                              textAlign: 'right'
                                            }}
                                          >
                                            <Tooltip
                                              placement="bottom"
                                              title="Edit"
                                            >
                                              <EditIcon
                                                className={classes.thIconButton}
                                                onClick={() => {
                                                  this.setState({
                                                    dialogEditItem: true,
                                                    component
                                                  })
                                                }}
                                              />
                                            </Tooltip>

                                            <Tooltip
                                              placement="bottom"
                                              title="Delete"
                                            >
                                              <DeleteIcon
                                                className={classes.thIconButton}
                                                onClick={
                                                  () => this.setState({
                                                    dialogDeleteSubItem: true,
                                                    component
                                                  })
                                                }
                                              />
                                            </Tooltip>
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                              </TableStyled>
                            </td>
                          </tr>
                        )
                        :
                        null
                      }
                    </React.Fragment>
                  )
                })
              }
            </tbody>
          </TableStyled>
        </div>

        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            right: '20px'
          }}
        >
          <Button
            variant='fab'
            color='primary'
            onClick={() => this.setState({dialogNew: true})}
          >
            <AddIcon />
          </Button>
        </div>
        
        {/* NEW ITEM MODEL */}
        <Dialog
          open={this.state.dialogNew}
          onClose={() => this.setState({dialogNew: false})}
        >
          <DialogTitle>
            New item model
          </DialogTitle>
          {
            this.state.dialogNewLoading
            ?
            (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress className={classes.progress} />

                <span
                  style={{
                    marginTop: 20
                  }}
                >
                  Loading...
                </span>
              </div>
            )
            :
            null
          }
          <DialogContent>
            <form
              onSubmit={e => {
                e.preventDefault()

                if (!this.state.itemNewName
                  || !this.state.itemNewPartNumber
                  || !this.state.itemNewManufacturer
                ) {
                  toast.error(
                    'Inválid params',
                    {
                      position: "top-right",
                      // autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      // draggable: true
                    }
                  )
                  return
                }

                this.setState({dialogNewLoading: true})

                Auth.currentSession()
                  .then(({accessToken}) => {
                    const {jwtToken} = accessToken

                    return axios.post(
                      URL,
                      {
                        name: this.state.itemNewName,
                        part_number: this.state.itemNewPartNumber,
                        manufacturer: this.state.itemNewManufacturer
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': jwtToken
                        }
                      }
                    )
                      .then(() => this.getItems())
                      .then(({data}) => {
                        this.setState({
                          dialogNewLoading: false,
                          data,
                          dialogNew: false,
                          itemNewName: '',
                          itemNewPartNumber: '',
                          itemNewManufacturer: ''
                        })

                        toast.success(
                          'Success!',
                          {
                            position: "top-right",
                            // autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            // draggable: true
                          }
                        )
                      })
                  })
                  .catch(error => {
                    console.error('NOT USER', error)
                    this.setState({dialogNewLoading: false})
                    toast.error(
                      'Request error',
                      {
                        position: "top-right",
                        // autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        // draggable: true
                      }
                    )
                  })
              }}
            >
              <TextField
                label="Name"
                className={classes.textField}
                value={this.state.itemNewName}
                onChange={e => this.setState({itemNewName: e.target.value})}
              />
              <TextField
                label="Part number"
                className={classes.textField}
                value={this.state.itemNewPartNumber}
                onChange={e => this.setState({itemNewPartNumber: e.target.value})}
              />
              <TextField
                label="Manufacturer"
                className={classes.textField}
                value={this.state.itemNewManufacturer}
                onChange={e => this.setState({itemNewManufacturer: e.target.value})}
              />

              <div
                style={{
                  marginTop: 20,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    borderRadius: 0,
                    backgroundColor: 'orange',
                    color: 'white'
                  }}
                >
                  Save
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
        
        {/* NEW ITEM */}
        <Dialog
          onClose={() => this.setState({dialogNewItem: false})}
          open={this.state.dialogNewItem}
        >
          <DialogTitle>
            New item
          </DialogTitle>
          {
            this.state.dialogNewItemLoading
            ?
            (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress className={classes.progress} />

                <span
                  style={{
                    marginTop: 20
                  }}
                >
                  Loading...
                </span>
              </div>
            )
            :
            null
          }
          <DialogContent>
            <form
              onSubmit={e => {
                e.preventDefault()

                if (!this.state.itemNewItemSerialNumber
                  || !this.state.itemNewItemSupplier
                ) {
                  toast.error(
                    'Inválid params',
                    {
                      position: "top-right",
                      // autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      // draggable: true
                    }
                  )
                  return
                }

                this.setState({dialogNewItemLoading: true})

                Auth.currentSession()
                  .then(({accessToken}) => {
                    const {jwtToken} = accessToken

                    return axios.post(
                      URL_ITEM,
                      {
                        component_model_id: this.state.item.id,
                        serial_number: this.state.itemNewItemSerialNumber,
                        supplier: this.state.itemNewItemSupplier
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': jwtToken
                        }
                      }
                    )
                      .then(() => this.getItems())
                      .then(({data}) => {
                        this.setState({
                          dialogNewItemLoading: false,
                          data,
                          item: {},
                          dialogNewItem: false,
                          itemNewItemSerialNumber: '',
                          itemNewItemSupplier: ''
                        })

                        toast.success(
                          'Success!',
                          {
                            position: "top-right",
                            // autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            // draggable: true
                          }
                        )
                      })
                  })
                  .catch(error => {
                    console.error('NOT USER', error)
                    this.setState({dialogNewItemLoading: false})
                    toast.error(
                      'Request error',
                      {
                        position: "top-right",
                        // autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        // draggable: true
                      }
                    )
                  })
              }}
            >
              <TextField
                label="Serial number"
                className={classes.textField}
                value={this.state.itemNewItemSerialNumber}
                onChange={e => this.setState({itemNewItemSerialNumber: e.target.value})}
              />
              <TextField
                label="Supplier"
                className={classes.textField}
                value={this.state.itemNewItemSupplier}
                onChange={e => this.setState({itemNewItemSupplier: e.target.value})}
              />

              <div
                style={{
                  marginTop: 20,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    borderRadius: 0,
                    backgroundColor: 'orange',
                    color: 'white'
                  }}
                >
                  Save
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
        
        {/* DELETE ITEM MODEL */}
        <Dialog
          onClose={
            () => this.setState({
              dialogDeleteItem: false,
              deleteItem: {}
            })
          }
          open={this.state.dialogDeleteItem}
        >
          <DialogTitle>
            Delete item model
          </DialogTitle>
          {
            this.state.dialogNewItemLoading
            ?
            (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress className={classes.progress} />

                <span
                  style={{
                    marginTop: 20
                  }}
                >
                  Loading...
                </span>
              </div>
            )
            :
            null
          }
          <DialogActions>
            <Button
              onClick={
                () => this.setState({
                  dialogDeleteItem: false,
                  deleteItem: {}
                })
              }
              style={{
                borderRadius: 0,
                backgroundColor: 'red',
                color: 'white'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.setState({dialogNewItemLoading: true})

                Auth.currentSession()
                  .then(({accessToken}) => {
                    const {jwtToken} = accessToken

                    return axios.delete(
                      `${URL}?id=${this.state.item.id}`,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': jwtToken
                        }
                      }
                    )
                      .then(() => this.getItems())
                      .then(({data}) => {
                        this.setState({
                          data,
                          item: {},
                          dialogDeleteItem: false,
                          dialogNewItemLoading: false,
                        })

                        toast.success(
                          'Success!',
                          {
                            position: "top-right",
                            // autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            // draggable: true
                          }
                        )
                      })
                  })
                  .catch(error => {
                    console.error('NOT USER', error)
                    this.setState({dialogNewItemLoading: false})
                    toast.error(
                      'Request error',
                      {
                        position: "top-right",
                        // autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        // draggable: true
                      }
                    )
                  })
              }}
              style={{
                borderRadius: 0,
                backgroundColor: 'orange',
                color: 'white'
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* DELETE ITEM */}
        <Dialog
          onClose={
            () => this.setState({
              dialogDeleteSubItem: false,
              component: {}
            })
          }
          open={this.state.dialogDeleteSubItem}
        >
          <DialogTitle>
            Delete item
          </DialogTitle>
          {
            this.state.dialogNewItemLoading
            ?
            (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress className={classes.progress} />

                <span
                  style={{
                    marginTop: 20
                  }}
                >
                  Loading...
                </span>
              </div>
            )
            :
            null
          }
          <DialogActions>
            <Button
              onClick={
                () => this.setState({
                  dialogDeleteSubItem: false,
                  component: {}
                })
              }
              style={{
                borderRadius: 0,
                backgroundColor: 'red',
                color: 'white'
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.setState({dialogNewItemLoading: true})

                Auth.currentSession()
                  .then(({accessToken}) => {
                    const {jwtToken} = accessToken

                    return axios.delete(
                      `${URL_ITEM}?id=${this.state.component.id}`,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': jwtToken
                        }
                      }
                    )
                      .then(() => this.getItems())
                      .then(({data}) => {
                        this.setState({
                          data,
                          component: {},
                          dialogDeleteSubItem: false,
                          dialogNewItemLoading: false,
                        })

                        toast.success(
                          'Success!',
                          {
                            position: "top-right",
                            // autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            // draggable: true
                          }
                        )
                      })
                  })
                  .catch(error => {
                    console.error('NOT USER', error)
                    this.setState({dialogNewItemLoading: false})
                    toast.error(
                      'Request error',
                      {
                        position: "top-right",
                        // autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        // draggable: true
                      }
                    )
                  })
              }}
              style={{
                borderRadius: 0,
                backgroundColor: 'orange',
                color: 'white'
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* EDIT ITEM MODEL */}
        <Dialog
          open={this.state.dialogEdit}
          onClose={() => this.setState({dialogEdit: false})}
        >
          <DialogTitle>
            Edit item model
          </DialogTitle>
          {
            this.state.dialogNewLoading
            ?
            (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress className={classes.progress} />

                <span
                  style={{
                    marginTop: 20
                  }}
                >
                  Loading...
                </span>
              </div>
            )
            :
            null
          }
          <DialogContent>
            <form
              onSubmit={e => {
                e.preventDefault()

                if (!this.state.item
                  || !this.state.item.name
                  || !this.state.item.part_number
                  || !this.state.item.manufacturer
                ) {
                  toast.error(
                    'Inválid params',
                    {
                      position: "top-right",
                      // autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      // draggable: true
                    }
                  )
                  return
                }

                this.setState({dialogNewLoading: true})

                Auth.currentSession()
                  .then(({accessToken}) => {
                    const {jwtToken} = accessToken

                    return axios.put(
                      URL,
                      {
                        ...this.state.item
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': jwtToken
                        }
                      }
                    )
                      .then(() => this.getItems())
                      .then(({data}) => {
                        this.setState({
                          dialogNewLoading: false,
                          data,
                          dialogEdit: false,
                          item: {}
                        })

                        toast.success(
                          'Success!',
                          {
                            position: "top-right",
                            // autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            // draggable: true
                          }
                        )
                      })
                  })
                  .catch(error => {
                    console.error('NOT USER', error)
                    this.setState({dialogNewLoading: false})
                    toast.error(
                      'Request error',
                      {
                        position: "top-right",
                        // autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        // draggable: true
                      }
                    )
                  })
              }}
            >
              <TextField
                label="Name"
                className={classes.textField}
                value={this.state.item.name}
                onChange={e => {
                  const value = e.target.value

                  this.setState(
                    state => ({
                      item: R.assoc('name', value, state.item)
                    })
                  )
                }}
              />
              <TextField
                label="Part number"
                className={classes.textField}
                value={this.state.item.part_number}
                onChange={e => {
                  const value = e.target.value

                  this.setState(
                    state => ({
                      item: R.assoc('part_number', value, state.item)
                    })
                  )
                }}
              />
              <TextField
                label="Manufacturer"
                className={classes.textField}
                value={this.state.item.manufacturer}
                onChange={e => {
                  const value = e.target.value

                  this.setState(
                    state => ({
                      item: R.assoc('manufacturer', value, state.item)
                    })
                  )
                }}
              />

              <div
                style={{
                  marginTop: 20,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    borderRadius: 0,
                    backgroundColor: 'orange',
                    color: 'white'
                  }}
                >
                  Save
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
        
        {/* EDIT ITEM */}
        <Dialog
          open={this.state.dialogEditItem}
          onClose={() => this.setState({dialogEditItem: false})}
        >
          <DialogTitle>
            Edit item
          </DialogTitle>
          {
            this.state.dialogNewLoading
            ?
            (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress className={classes.progress} />

                <span
                  style={{
                    marginTop: 20
                  }}
                >
                  Loading...
                </span>
              </div>
            )
            :
            null
          }
          <DialogContent>
            <form
              onSubmit={e => {
                e.preventDefault()

                if (!this.state.component
                  || !this.state.component.serial_number
                  || !this.state.component.supplier
                ) {
                  toast.error(
                    'Inválid params',
                    {
                      position: "top-right",
                      // autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      // draggable: true
                    }
                  )
                  return
                }

                this.setState({dialogNewLoading: true})

                Auth.currentSession()
                  .then(({accessToken}) => {
                    const {jwtToken} = accessToken

                    return axios.put(
                      URL_ITEM,
                      {
                        ...this.state.component
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': jwtToken
                        }
                      }
                    )
                      .then(() => this.getItems())
                      .then(({data}) => {
                        this.setState({
                          dialogNewLoading: false,
                          data,
                          dialogEditItem: false,
                          component: {}
                        })

                        toast.success(
                          'Success!',
                          {
                            position: "top-right",
                            // autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            // draggable: true
                          }
                        )
                      })
                  })
                  .catch(error => {
                    console.error('NOT USER', error)
                    this.setState({dialogNewLoading: false})
                    toast.error(
                      'Request error',
                      {
                        position: "top-right",
                        // autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        // draggable: true
                      }
                    )
                  })
              }}
            >
              <TextField
                label="Serial number"
                className={classes.textField}
                value={this.state.component.serial_number}
                onChange={e => {
                  const value = e.target.value

                  this.setState(
                    state => ({
                      component: R.assoc('serial_number', value, state.component)
                    })
                  )
                }}
              />
              <TextField
                label="Supplier"
                className={classes.textField}
                value={this.state.component.supplier}
                onChange={e => {
                  const value = e.target.value

                  this.setState(
                    state => ({
                      component: R.assoc('supplier', value, state.component)
                    })
                  )
                }}
              />

              <div
                style={{
                  marginTop: 20,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    borderRadius: 0,
                    backgroundColor: 'orange',
                    color: 'white'
                  }}
                >
                  Save
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
        
      
      
      
      
      
      </div>
    )
  }
}

export default withStyles(styles)(ComponentPage)
