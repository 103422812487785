import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'
import { GraphicKeyMatricsContent, GraphicKeyMatricsContentPrimaryItem } from './GraphicKeyMatricsStyle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import CircularProgress from '@material-ui/core/CircularProgress';
import CountUp from 'react-countup'

import styles from './styles/styles.js';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2E2E2E',
    color: theme.palette.common.white
  }
}))(TableCell);

class GraphicKeyMatrics extends Component {
  verifyDataIsNullOrNumber(value) {
    if (value != null) {
      if (Number.isNaN(value)) {
        return value
      }

      if (String(value).indexOf('.') >= 0) {
        return value.toFixed(2)
      } else {
        return value
      }
    }

    return '-'
  }

  render() {
    const { classes, graphic, data: managementKpiData, showTable, font_size } = this.props

    if (!managementKpiData || managementKpiData.length <= 0 || !Array.isArray(managementKpiData)) {
      return (
        <div>
          No data...
        </div>
      )
    }

    // if (managementKpiData.length <= 0) {
    //   return (
    //     <div style={{
    //       width: '100%',
    //       height: '100%',
    //       display: 'flex',
    //       flexDirection: 'column',
    //       justifyContent: 'center',
    //       alignItems: 'center'
    //     }}>
    //       <CircularProgress className={classes.progress} size={(graphic.size * 30)} />
    //       <div style={{
    //         marginTop: '20px'
    //       }}>
    //         Carregando dados...
    //       </div>
    //     </div>
    //   )
    // }

    const data = managementKpiData.map((elm) => {
      const retultValue = elm.unit === '%' ? R.assoc('value', (elm.value), elm) : elm
      const retultDeltaValue = elm.delta_value_unit === '%' ? R.assoc('delta_value', (elm.delta_value), retultValue) : retultValue

      return retultDeltaValue
    })

    const dataPrimaryHead = R.head(data)

    const dataPrimary = dataPrimaryHead ? dataPrimaryHead : {}
    const dataSubitems = R.slice(1, data.length, data)

    const dataTable = data.map((item) => ({
      '0': item.indicator,
      'Actual': {
        value: this.verifyDataIsNullOrNumber(item.value),
        unit: item.value ? item.unit : ''
      },
      'Expected': {
        value: this.verifyDataIsNullOrNumber(item.expected),
        unit: item.expected ? item.unit : ''
      },
      'Diff': {
        value: this.verifyDataIsNullOrNumber(item.delta_value),
        unit: item.delta_value ? item.delta_value_unit : ''
      }
    }))

    const keysTable = R.keys(R.head(dataTable))
    const dataTableValues = dataTable.map((elm) => {
      return R.values(elm)
    })

    return (
      <GraphicKeyMatricsContent>
        <div className={classes.flipContainer}>
          <div style={{
            transform: showTable ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}>
            <div className={classes.flipContainerFront} style={{ display: showTable ? 'none' : undefined }}>
              <GraphicKeyMatricsContentPrimaryItem>
                <div className={classes.primaryContent} >
                  <div className={classes.primaryContentIndicator} style={{
                    fontSize: `${font_size}px`,
                    textAlign: 'center'
                  }}>
                    {dataPrimary.indicator}
                  </div>
                  <div className={classes.primaryContentValue} >
                    <span style={{
                      color: dataPrimary.value >= dataPrimary.expected || dataPrimary.expected == null ? 'black' : red[700],
                      fontSize: `${(font_size * 2.25)}px`,
                    }}>
                      <CountUp
                        start={0}
                        end={this.verifyDataIsNullOrNumber(dataPrimary.value)}
                        duration={0.8}
                        decimals={String(dataPrimary.value).indexOf('.') >= 0 ? 1 : 0}
                      />
                    </span>
                    {
                      dataPrimary.unit != '' ?
                        <span style={{
                          marginLeft: 15,
                          color: dataPrimary.value >= dataPrimary.expected || dataPrimary.expected == null ? 'black' : red[700],
                          fontSize: `${(font_size * 2.25)}px`,
                          display: dataPrimary.value ? 'inline' : 'none'
                        }}>
                          {dataPrimary.unit}
                        </span>
                        :
                        null
                    }
                  </div>
                  <div style={{
                    textAlign: 'center',
                    fontSize: `${(font_size * 0.8)}px`,
                  }}>
                    {
                      dataPrimary.expected != null ?
                        `
                          ${(
                          dataPrimary.value == dataPrimary.expected ?
                            ''
                            :
                            dataPrimary.value > dataPrimary.expected ?
                              '⬆️'
                              :
                              '⬇️'
                        )}
                          ${this.verifyDataIsNullOrNumber(dataPrimary.delta_value)}
                          ${dataPrimary.delta_value_unit}`
                        :
                        null
                    }
                  </div>
                </div>
              </GraphicKeyMatricsContentPrimaryItem>
              <div className={classes.subitemsDiv} >
                <div className={classes.subitemsContent} >
                  {dataSubitems.map((elm, index) => {
                    return (
                      <div key={index} className={classes.subitemsItem} >
                        <div style={{
                          textAlign: 'center',
                          fontSize: `${font_size * 0.8}px`,
                        }}>
                          {elm.indicator}
                        </div>
                        <div style={{
                          textAlign: 'center',
                        }}>
                          <span style={{
                            color: elm.value > elm.expected || elm.expected == null ? 'black' : red[700],
                            fontSize: `${font_size}px`,
                          }}>
                            {/* {this.verifyDataIsNullOrNumber(elm.value)} */}
                            <CountUp
                              start={0}
                              end={this.verifyDataIsNullOrNumber(elm.value)}
                              duration={0.8}
                              decimals={String(elm.value).indexOf('.') >= 0 ? 1 : 0}
                            />
                          </span>
                          {
                            dataPrimary.unit != '' ?
                              <span style={{
                                marginLeft: 15,
                                color: elm.value > elm.expected || elm.expected == null ? 'black' : red[700],
                                fontSize: `${font_size}px`,
                                display: elm.value ? 'inline' : 'none'
                              }}>
                                {elm.unit}
                              </span>
                              :
                              null
                          }
                        </div>
                        <div style={{
                          textAlign: 'center',
                          fontSize: `${font_size * 0.8}px`,
                        }}>
                          {
                            elm.expected != null ?
                              `
                                ${(
                                elm.value == elm.expected ?
                                  ''
                                  :
                                  elm.value > elm.expected ?
                                    '⬆️'
                                    :
                                    '⬇️'
                              )}
                                ${this.verifyDataIsNullOrNumber(elm.delta_value)}
                                ${elm.delta_value_unit}`
                              :
                              null
                          }
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={classes.flipContainerBack} style={{ display: showTable ? undefined : 'none' }}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.rowHead}>
                    {keysTable.map((elm, index) => {
                      if (elm === '0') {
                        return (
                          <CustomTableCell colSpan={1} key={index}>
                            {''}
                          </CustomTableCell>
                        )
                      }

                      return (
                        <CustomTableCell className={classes.tableCellHeaderValue} colSpan={2} key={index}>
                          {elm}
                        </CustomTableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTableValues.map((row, indexRow) => {
                    return (
                      <TableRow className={classes.row} key={indexRow}>
                        {row.map((cell, indexCell) => {
                          if (typeof cell === 'object') {
                            return R.values(cell).map((cellValue, indexCellValue) => {
                              return (
                                <CustomTableCell key={indexCellValue} className={classes.tableCellValue}>
                                  <div style={{
                                    textAlign: indexCellValue === 0 ? 'right' : 'left',
                                    marginLeft: indexCellValue === 1 ? '5px' : '0',
                                    marginRight: indexCellValue === 0 ? '5px' : '0'
                                  }}>
                                    {cellValue}
                                  </div>
                                </CustomTableCell>
                              )
                            })
                          }

                          return (
                            <CustomTableCell key={indexCell}>
                              <div>
                                {cell}
                              </div>
                            </CustomTableCell>
                          )
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </GraphicKeyMatricsContent>
    )
  }
}

export default withStyles(styles)(GraphicKeyMatrics)
