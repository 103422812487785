import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import moment from 'moment-timezone'
import {
  withStyles
} from '@material-ui/core'
import GridLayout from 'react-grid-layout'
import * as R from 'ramda'

import SpreadsheetComponent from 'react-spreadsheet-component';

import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import DowntimeFilter from './components/DowntimeFilter'
import Modules from '../../../../data/META/modules-registry.json';
import TableEdit from '../../../../components/Table/Table';
import Button from '@material-ui/core/Button'

import classification from '../../../../data/META/classification.json';
import system from '../../../../data/META/system.json';
import responsability from '../../../../data/META/responsability.json';
import alarm from '../../../../data/META/alarm.json';
import duration from '../../../../data/META/duration.json';


import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import LinearProgress from '@material-ui/core/LinearProgress';

import { REDUCER_DOWNTIME_GET_DATA } from "../../../../constants/reducers/page-get-data"

import { DowntimesContent } from './DowntimeStyle'

import { sendDowntimesToSave, resetDowntimesData, recalculateFromStatus } from '../../../../actions/SendDataActions'

const GRID_WIDTH_PADDING = 20

const styles = theme => ({
  gridElement: {
    width: '100%'
  }
})

class DowntimePage extends Component {
  constructor (props) {
    super (props)

    const elBody = window.document.getElementsByTagName('BODY')[0]


    this.state = {
      gridWidth: (elBody.offsetWidth - (GRID_WIDTH_PADDING * 2) - 10),
      pageSelected: null,
      data: null
    }

    this.gridContainerAnchor = React.createRef();
    this.currentDateRange = null;
    this.prevPageId = null;
  }

  componentDidMount() {

  }

  handleClick = (device_table, event) => {
   this.setState(state => ({[`${device_table.name}_is_open`]: !state[`${device_table.name}_is_open`]}));
  };

  saveAllTables = (page_id, event) => {
    const tables = this.state.data.map(device_table => ({
      rows: this.state[`${device_table.name}_rows`] &&
            this.state[`${device_table.name}_rows`].constructor == Array ?
        this.state[`${device_table.name}_rows`].map(row => {
          let comments = row.comments
          if(comments) {
            if(comments.length == 0) {
              row.comments = null
            }
          }

          return row
        })
      :
        [],
      device_id: device_table.device_id,
      name: device_table.name
    }))

    this.props.sendDowntimesToSave(tables, 'Downtime', page_id, REDUCER_DOWNTIME_GET_DATA)
  }

  resetData = () => {
    this.props.resetDowntimesData('Downtime', this.state.pageSelected.id, REDUCER_DOWNTIME_GET_DATA)
  }

  recalculateData = () => {
    this.props.recalculateFromStatus('Downtime', this.state.pageSelected.id, REDUCER_DOWNTIME_GET_DATA)
  }

  LoadingBar = () => (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        top: '16%',
        right: '0%',
        zIndex: 999
      }}
    >
      <LinearProgress color="secondary" />
    </div>
  )

  render () {
    const {classes, Downtime, Navigation, parks, history} = this.props

    const MODULE = Modules.filter( module => module.id == Navigation.module_id);
    let submodule_id = Navigation.submodule_id? Navigation.submodule_id : 0;
    let SUBMODULE = null;

    if (Navigation.submodule_id) {
        SUBMODULE = MODULE[0].submodules.filter( sm => sm.id == Navigation.submodule_id);
    }else{

        SUBMODULE = MODULE[0].submodules;
    }

    const pageSelected = R.find(R.propEq('selected', true))(Downtime)

    if (!pageSelected) {
      return (
        <div>
          LOADING...
        </div>
      )
    }

    const {data, filter, from_redux} = pageSelected;

    if(!this.prevPageId) {
      this.prevPageId = pageSelected.id;
      this.currentDateRange = filter.data_range;
    } else if(this.prevPageId != pageSelected.id) {
      this.prevPageId = pageSelected.id;
      pageSelected.filter.data_range = this.currentDateRange;
    } else {
      this.currentDateRange = filter.data_range;
    }

    this.state.data = !from_redux && 'data' in this.state && this.state.data ? this.state.data : data
    this.state.pageSelected = pageSelected

    if(!this.state.data) {
      return (
        <DowntimesContent>
          {/* {this.LoadingBar()} */}
          <DowntimeFilter
            mode={'view'}
            history={history}
            page={pageSelected}
            reducer_name={REDUCER_DOWNTIME_GET_DATA}
            module_field={'Downtime'}
          />
          <div style={{
            height: window.innerHeight
          }}>
            <span style={{
              position: 'absolute',
              left: '46.5%',
              top: '60%'
            }}>Loading...</span>
          </div>
        </DowntimesContent>
      )
    }

    this.state.data.map(table => {
      if(table.rows) {
        table.rows.map(row => {
          if('start_time' in row) {
            row.start_time = moment(row.start_time).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')
          }
          if('end_time' in row) {
            row.end_time = moment(row.end_time).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss')
          }

          return row
        })
      }

      return table
    })

    return (
      <DowntimesContent>
        <DowntimeFilter
          mode={'view'}
          history={history}
          page={pageSelected}
          reducer_name={REDUCER_DOWNTIME_GET_DATA}
          module_field={'Downtime'}
        />

        <div
          style={{
            padding: '0 0 0 15px'
          }}
        >

            <List
              component="nav"
              className={classes.root}
            >
            {
              this.state.data.map(device_table => {
                this.state[`${device_table.name}_device_name`] = device_table.name
                this.state[`${device_table.name}_is_open`] =
                  `${device_table.name}_is_open` in this.state ?
                    this.state[`${device_table.name}_is_open`]
                  :
                    'is_open' in device_table ? device_table.is_open : true
                this.state[`${device_table.name}_columns`] = device_table.columns
                this.state[`${device_table.name}_rows`] = device_table.rows
                this.state[`${device_table.name}_default_sorting`] = device_table.defaultSorting
                this.state[`${device_table.name}_option_fields`] = device_table.optionFields
                this.state[`${device_table.name}_utils_data`] = device_table.utilsData ?
                  device_table.utilsData
                :
                  { classification: classification }

                return (
                  <React.Fragment>
                    <ListItem button onClick={this.handleClick.bind(this, device_table)}>
                      <ListItemText inset primary={this.state[`${device_table.name}_device_name`]} />
                      {this.state[`${device_table.name}_is_open`] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state[`${device_table.name}_is_open`]} timeout="auto">
                      <List component="div" disablePadding>
                          <ListItem className={classes.nested}>
                              <TableEdit
                                columns={this.state[`${device_table.name}_columns`]}
                                rows={this.state[`${device_table.name}_rows`]}
                                changeRows={rows => {this.state[`${device_table.name}_rows`] = rows; return this.state[`${device_table.name}_rows`]}}
                                defaultSorting={this.state[`${device_table.name}_default_sorting`]}
                                optionFields={this.state[`${device_table.name}_option_fields`]}
                                utilsData={this.state[`${device_table.name}_utils_data`]}
                                downtimes={true}
                                responsibility_selector={device_table.responsibility_seletor}
                                system_selector={device_table.system_seletor}
                                classification_selector={device_table.classification_seletor}
                                alarm_selector={device_table.alarm_list_seletor}
                                device_id={device_table.device_id}
                                update_time={moment()}
                              />
                          </ListItem>
                      </List>
                    </Collapse>
                  </React.Fragment>
                )
              })
            }
            </List>

          <div
            style={{
              position: 'fixed',
              bottom: '17%',
              right: '1%'
            }}
          >
            <Tooltip
              title="Save Changes"
              aria-label="Save Changes"
              placement='left'
            >
              <Button
                variant="fab"
                color="primary"
                aria-label="Save Changes"
                onClick={this.saveAllTables.bind(this, pageSelected.id)}
              >
                <Icon>save</Icon>
              </Button>
            </Tooltip>
          </div>

          <div
            style={{
              position: 'fixed',
              bottom: '10%',
              right: '1.5%'
            }}
          >
            <Tooltip
              title="Recalculate From Status"
              aria-label="Recalculate From Status"
              placement='left'
            >
              <Button
                variant="fab"
                color="primary"
                aria-label="Recalculate From Status"
                onClick={this.recalculateData.bind(this)}
                mini
              >
                <Icon>autorenew</Icon>
              </Button>
            </Tooltip>
          </div>

          <div
            style={{
              position: 'fixed',
              bottom: '3%',
              right: '1.5%'
            }}
          >
            <Tooltip
              title="Reset To Default"
              aria-label="Reset To Default"
              placement='left'
            >
              <Button
                variant="fab"
                color="primary"
                aria-label="Reset To Default"
                onClick={this.resetData.bind(this)}
                mini
              >
                <Icon>undo</Icon>
              </Button>
            </Tooltip>
          </div>
        </div>
      </DowntimesContent>
    )
  }
}

const mapStateToProps = ({Dashboard, User, Navigation}) => {
  const {Downtime} = Dashboard
  const {parks_info} = User

  return { Downtime, Navigation, parks: parks_info }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  sendDowntimesToSave,
  resetDowntimesData,
  recalculateFromStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DowntimePage))
