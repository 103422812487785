// const config = {
//   ApplicationId: '',
//   identityPoolId: 'us-east-1:cdc0b300-5465-40ad-b6dd-bf605bd141a7',
//   region: 'us-east-1',
//   userPoolId: 'us-east-1_YesSzbdpP',
//   userPoolWebClientId: '6tmujvj3mcjim5f7ek3ehmmglf',
//   API: {
//     endpoints: [
//       {
//         name: "delfosim-serverless-test-dev-data-index",
//         endpoint: "https://0nkdr8bd1a.execute-api.us-east-1.amazonaws.com/dev/data-index",
//         service: "lambda",
//         region: "us-east-1"
//       }
//     ]
//   }
// }

const config = {
  ApplicationId: process.env.REACT_APP_APPLICATION_ID,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
}

export default config
