const treatData = RAW_DATA => {
    if (!RAW_DATA) {
        return [];
    }

    const DEFINITION_LEVEL_SOURCE = 'new_source';
    const DEFINITION_LEVEL = 'source';
    const START_TIME = 'start_time';
    const END_TIME = 'end_time';
    const DURATION = 'duration';
    const ALARM = 'alarm';
    const SYSTEM = 'system';
    const CLASSIFICATION = 'classification';
    const RESPONSIBILITY = 'responsibility';
    const COMMENTS = 'comment';
    const USER = 'user_name';

    // DEFINING TABLE HEADERS LABELS
    const DEFINITION_LEVEL_LABEL = '';
    const START_TIME_LABEL = 'Start Time';
    const END_TIME_LABEL = 'End Time';
    const DURATION_LABEL = 'Duration';
    const ALARM_LABEL = 'Assoc Alarm';
    const SYSTEM_LABEL = 'System';
    const CLASSIFICATION_LABEL = 'Classification';
    const RESPONSIBILITY_LABEL = 'Responsibility';
    const COMMENTS_LABEL = 'Comments';
    const USER_LABEL = 'Users';

    const devicesInData = [...new Set(RAW_DATA.map(data => data && data.wtg))].filter(data => data != null && data !== false)

    const DATA = devicesInData.map(device => {
        const filteredRawData = RAW_DATA.filter(data => data && data.wtg === device);

        if (filteredRawData.length > 1) {
            return filteredRawData.reduce((acc, curr) => ({
                device: curr.wtg,
                deviceId: curr.device_id,
                scada_ids: curr.scada_ids,
                headers: [
                    { id: DEFINITION_LEVEL, numeric: false, disablePadding: false, label: DEFINITION_LEVEL_LABEL },
                    { id: START_TIME, numeric: false, disablePadding: false, label: START_TIME_LABEL },
                    { id: END_TIME, numeric: false, disablePadding: false, label: END_TIME_LABEL },
                    { id: DURATION, numeric: true, disablePadding: false, label: DURATION_LABEL },
                    { id: ALARM, numeric: false, disablePadding: false, label: ALARM_LABEL },
                    { id: SYSTEM, numeric: false, disablePadding: false, label: SYSTEM_LABEL },
                    { id: CLASSIFICATION, numeric: false, disablePadding: false, label: CLASSIFICATION_LABEL },
                    { id: RESPONSIBILITY, numeric: false, disablePadding: false, label: RESPONSIBILITY_LABEL },
                    { id: COMMENTS, numeric: false, disablePadding: false, label: COMMENTS_LABEL },
                    { id: USER, numeric: false, disablePadding: false, label: USER_LABEL },

                ],
                rows: acc.rows ? [...acc.rows, {
                    [DEFINITION_LEVEL]: curr[DEFINITION_LEVEL_SOURCE],
                    [START_TIME]: curr[START_TIME],
                    [END_TIME]: curr[END_TIME],
                    [DURATION]: curr[DURATION],
                    [ALARM]: curr[ALARM],
                    [SYSTEM]: curr[SYSTEM],
                    [CLASSIFICATION]: curr[CLASSIFICATION],
                    [RESPONSIBILITY]: curr[RESPONSIBILITY],
                    [COMMENTS]: curr[COMMENTS] || [''],
                    [USER]: curr[USER],
                    contract_id: curr.contract_id,
                    checked: curr.checked,
                    status_cut_id: curr.status_cut_id,
                    status_base_id: curr.status_base_id
                }] : [{
                    [DEFINITION_LEVEL]: acc[DEFINITION_LEVEL_SOURCE],
                    [START_TIME]: acc[START_TIME],
                    [END_TIME]: acc[END_TIME],
                    [DURATION]: acc[DURATION],
                    [ALARM]: acc[ALARM],
                    [SYSTEM]: acc[SYSTEM],
                    [CLASSIFICATION]: acc[CLASSIFICATION],
                    [RESPONSIBILITY]: acc[RESPONSIBILITY],
                    [COMMENTS]: acc[COMMENTS] || [''],
                    [USER]: acc[USER],
                    contract_id: acc.contract_id,
                    checked: acc.checked,
                    status_cut_id: acc.status_cut_id,
                    status_base_id: acc.status_base_id
                }, {
                    [DEFINITION_LEVEL]: curr[DEFINITION_LEVEL_SOURCE],
                    [START_TIME]: curr[START_TIME],
                    [END_TIME]: curr[END_TIME],
                    [DURATION]: curr[DURATION],
                    [ALARM]: curr[ALARM],
                    [SYSTEM]: curr[SYSTEM],
                    [CLASSIFICATION]: curr[CLASSIFICATION],
                    [RESPONSIBILITY]: curr[RESPONSIBILITY],
                    [COMMENTS]: curr[COMMENTS] || [''],
                    [USER]: curr[USER],
                    contract_id: curr.contract_id,
                    checked: curr.checked,
                    status_cut_id: curr.status_cut_id,
                    status_base_id: curr.status_base_id
                }]
            }))
        } else {
            const data = filteredRawData[0];
            return {
                device: data.wtg,
                deviceId: data.device_id,
                scada_ids: data.scada_ids,
                headers: [
                    { id: DEFINITION_LEVEL, numeric: false, disablePadding: false, label: DEFINITION_LEVEL_LABEL },
                    { id: START_TIME, numeric: false, disablePadding: false, label: START_TIME_LABEL },
                    { id: END_TIME, numeric: false, disablePadding: false, label: END_TIME_LABEL },
                    { id: DURATION, numeric: true, disablePadding: false, label: DURATION_LABEL },
                    { id: ALARM, numeric: false, disablePadding: false, label: ALARM_LABEL },
                    { id: SYSTEM, numeric: false, disablePadding: false, label: SYSTEM_LABEL },
                    { id: CLASSIFICATION, numeric: false, disablePadding: false, label: CLASSIFICATION_LABEL },
                    { id: RESPONSIBILITY, numeric: false, disablePadding: false, label: RESPONSIBILITY_LABEL },
                    { id: COMMENTS, numeric: false, disablePadding: false, label: COMMENTS_LABEL },
                    { id: USER, numeric: false, disablePadding: false, label: USER_LABEL },
                ],
                rows: [{
                    [DEFINITION_LEVEL]: data[DEFINITION_LEVEL_SOURCE],
                    [START_TIME]: data[START_TIME],
                    [END_TIME]: data[END_TIME],
                    [DURATION]: data[DURATION],
                    [ALARM]: data[ALARM],
                    [SYSTEM]: data[SYSTEM],
                    [CLASSIFICATION]: data[CLASSIFICATION],
                    [RESPONSIBILITY]: data[RESPONSIBILITY],
                    [COMMENTS]: data[COMMENTS] || [''],
                    [USER]: data[USER],
                    contract_id: data.contract_id,
                    checked: data.checked,
                    status_cut_id: data.status_cut_id,
                    status_base_id: data.status_base_id
                }]
            };
        }
    });

    return DATA;
}

export default treatData;