import React from 'react'
import {withStyles, Icon, LinearProgress, Tooltip} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment-timezone'

const WIDGET_HEADER_HEIGHT = 40

const styles = {
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  tableTh: {
    color: grey['500'],
    textAlign: 'center',
    padding: '8px'
  },
  tableTd: {
    textAlign: 'center',
    padding: '8px',
    color: grey['400']
  }
}

class MonitorSubpark extends React.Component {
  render () {
    const {classes, data} = this.props
    
    // console.log('data', data)

    // const subpark = {}

    // return (
    //   <div>
    //     TESTE
    //   </div>
    // )

    return (
      <div
        style={{
          color: 'white',
          border: `1px solid ${grey['900']}`,
          width: '100%',
          height: '100%'
        }}
      >
        <div
          style={{
            height: WIDGET_HEADER_HEIGHT,
            width: '100%',
            borderBottom: `1px solid ${grey['900']}`,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              marginLeft: 10,
              color: grey['500']
            }}
          >
            {data.subpark_name}
          </div>
        </div>
        <div
          style={{
            height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
            width: '100%',
            overflow: 'auto'
          }}
        >
          <table className={classes.table}>
            <thead>
              <tr>
                <th
                  className={classes.tableTh}
                >
                  Turbine
                </th>
                <th
                  className={classes.tableTh}
                >
                  Status
                </th>
                <th
                  className={classes.tableTh}
                  colSpan={2}
                >
                  Power (kW)
                </th>
                <th
                  className={classes.tableTh}
                  colSpan={2}
                >
                  Wind Speed (m/s)
                </th>
                <th
                  className={classes.tableTh}
                >
                  Last Update
                </th> 
              </tr>
            </thead>
            <tbody>
              {
                data && data.devices.length > 0 ?
                (
                  data.devices.map((device, indexDevice) => {
                    return (
                      <tr
                        key={indexDevice}
                        style={{
                          backgroundColor: indexDevice % 2 === 0 ? grey['900'] : null
                        }}
                      >
                        <td className={classes.tableTd}>
                          {device.device_name}
                        </td>

                        {
                          !device.running && !device.stopped
                          ?
                          (
                            <td className={classes.tableTd}>
                              {// device.data.status
                                device.power <= 0 && device.wind_speed > 3
                                ?
                                (
                                  <Icon style={{fontSize:16, color:'red'}} >stop</Icon>
                                )
                                :
                                (
                                  <Icon style={{fontSize:16, color:'#40b728'}} >play_arrow</Icon>
                                )
                              }
                            </td>
                          )
                          :
                          (
                            <React.Fragment>
                              <td
                                className={classes.tableTd}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Icon style={{fontSize:16, color:'#40b728'}} >play_arrow</Icon> {device.running}
                                </span>

                                <span
                                  style={{
                                    marginLeft: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Icon style={{fontSize:16, color:'red'}} >stop</Icon> {device.stopped}
                                </span>
                              </td>
                            </React.Fragment>
                          )
                        }



                        <td
                          className={classes.tableTd}
                          style={{
                            textAlign: 'right'
                          }}
                        >
                          {`${Number(device.power).toFixed(1)}`}
                          {/* 0  2400 */}
                        </td>
                        <td
                          className={classes.tableTd}
                          style={{
                            minWidth: 50
                          }}
                        >
                          <LinearProgress
                            variant="determinate"
                            value={((100 / device.rated_power) * device.power)}
                          />
                        </td>
                        <td
                          className={classes.tableTd}
                          style={{
                            textAlign: 'right'
                          }}
                        >
                          {`${Number(device.wind_speed).toFixed(1)}`}
                          {/* 0  20 */}
                        </td>
                        <td
                          className={classes.tableTd}
                          style={{
                            minWidth: 50
                          }}
                        >
                          <LinearProgress
                            variant="determinate"
                            value={((100 / 20) * device.wind_speed)}
                          />
                        </td>
                        <td className={classes.tableTd}>
                          {/* {moment(device.data.sample_time).format('YYYY-MM-DD HH:mm:ss')} */}
                          {/* {moment(device.data.sample_time).duration().humanize(true)} */}
                          {moment.duration(moment().diff(moment(device.sample_time))).humanize()}
                        </td>
                      </tr>
                    )
                  })
                )
                : (
                  <tr>
                    <td colSpan={5}>
                      No data...
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>

        </div>
      </div>
    )
  }
}

export default withStyles(styles)(MonitorSubpark)
