export default (theme) => ({
    footer: {
        width: '100%',
        height: '20%',
        position: 'fixed',
        bottom: 0
    },
    footerBackground: {
        width: '100%',
        height: '100%',
        position: 'absolute'
    },
    footerLogo: {
        height: '45%',
        width: 'auto',
        position: 'absolute',
        bottom: '-7%',
        left: '2%'
    }
})