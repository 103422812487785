export default props => {

    const { src, onChange = () => { }, loading: uncheckedLoading, broken, edit = true } = props || {};

    const loading = broken ? false : uncheckedLoading;


    let message = null;
    if (!src) {
        message = uploadMessage;
    }
    if (broken) {
        message = brokenMessage;
    }
    if (!edit && !src) {
        message = noImgMessage;
    }

    return {
        src,
        onChange,
        loading,
        message,
        disabled: uncheckedLoading || broken || !edit
    };
};

const brokenMessage = {
    content: 'Failed to Access Your Image',
    icon: 'block'
};

const uploadMessage = {
    content: 'Click Here to Upload Photo',
    icon: 'add_a_photo'
};

const noImgMessage = {
    content: 'Has No Image',
    icon: 'block'
};