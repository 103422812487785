const styles = props => theme => ({
    container: {
        height: '100%',
    },
    dateTextContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    dateIcon: { 
        color:'#bdbdbd', 
        marginRight: '5px'
    },
    dateRangeItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyItems: 'center'
    },
    popperContainer: {
        zIndex: 99,
        marginTop: props.marginTop || 0,
        marginRight: props.marginRight || 0,
    },
    typography: {
        padding: theme.spacing.unit * 2,
    },
    buttonHandler: {
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#FF3300'
        },
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        height: '100%'
    },
    dashIcon: {
        fontSize: '16px',
    }
});

export default styles;