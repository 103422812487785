import React, {Component} from 'react'
import Button from '@material-ui/core/Button'
import * as R from 'ramda'
import Modules from '../../../../data/META/modules-registry.json';
import { editModeRemove} from '../../../../actions/EditModeAction'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import { withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Select, MenuItem, Input, IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'


const styles = theme => ({
  inputPageTitle: {
    fontSize: 16,
    marginRight: '20px',
    minWidth: '100%',
    '&:focus': {
      outline: 0
    }
  },
  widgetNew : {
    backgroundColor: '#ededed',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 320,
    width: '100%',
  }
})


class DeleteWidgetDialog extends Component {
  constructor (props) {
    super(props);

    this.state = {
      deleteWidgetId: this.props.deleteWidgetId,
      dialogDelete: this.props.dialogDelete
    }

  }

  goRemove = (management_id, reducer_name, action, widget_id) => () => {
    let value = this.props.editModeRemoveWidget(management_id, reducer_name, action, widget_id, this.props)
    
    this.props.updateProps(reducer_name.toLowerCase(), value )
    this.closeDialogRemove()
  }



  closeDialogDeleteOverview = () => {
    this.setState({
      dialogDeleteOverview: false
    })
  }

  openDialogDeleteOverview = () => {
    this.setState({
      dialogDeleteOverview: true
    })
  }

  closeDialogRemove = () => {
    this.props.closeDialogRemove()

    }


  goDeleteOverview = (management_id, reducer, action) => () => {
    let value = this.props.editModeRemove(management_id, reducer, action, this.props)
    this.props.updateProps(reducer.toLowerCase(), value )
    this.closeDialogDeleteOverview()
  }




    render(){



      const {classes, Management,  history, Navigation, deleteWidgetId, dialogDelete} = this.props

      let Dashboard = this.props;

      const MODULE = Modules.filter( module => module.id == Navigation.module_id);
      let submodule_id = Navigation.submodule_id? Navigation.submodule_id : 0;
      let SUBMODULE = null;

      let positions = [];

      const path_tree = window.location.href;

      const route = path_tree.split('/');

      const module_name = route[5]

      const REDUCER_NAME = module_name === 'virtual-clone' ?  'envelope' : module_name;

      const Page = REDUCER_NAME.toLowerCase() in Dashboard ? Dashboard[REDUCER_NAME.toLowerCase()] : []


      const managementSelected = R.find(R.propEq('selected', true))(Page)



     return(
      <div>
         <Dialog
          onClose={this.closeDialogRemove}
          open={dialogDelete}
          aria-labelledby="dialog_widget_delete"
        >
          <DialogTitle id="dialog_widget_delete">
            Delete widget
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete the selected widget?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialogRemove} color="primary">
              Back
            </Button>
            {managementSelected ? (
              <Button
                onClick={this.goRemove(managementSelected.id, REDUCER_NAME, 'ACTION_GET_DATA', deleteWidgetId).bind(this)}
                color="primary"
                autoFocus
              >
                Delete
              </Button>
            ) : ''}
          </DialogActions>
        </Dialog>
      </div>      
        )
      }


}


const mapStateToProps = ({classes, Management,  history, Navigation, Dashboard}) => {
    

    return { classes, Management,  history, Navigation, Dashboard }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  editModeRemove,
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(DeleteWidgetDialog))


