import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/ActionStyle';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CircularLoading from '../../../../../components/Loading/CircularLoading';
import { DialogContext, SelectorContext, PageContext } from './Contexts';
import {
    editStatus,
    createStatus,
    deleteStatus,
    mergeStatus,
    aproveStatus,
    splitStatus
} from './data-get/requests';
import { toast } from 'react-toastify'
import { withTranslation } from 'react-i18next';


class ActionBaseDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            executeAction: false,
            data: {},
            payload: {}
        };
    }

    toggleDialog = () => {
        this.setState(prevState => ({
            opened: !prevState.opened
        }));
    }

    prepareCloseDialog = () => {
        const { data: toSend } = this.state;
        const { selectedRows } = this.props;

        const selectedRow = selectedRows[0];

        const payload = {
            status_cut_id: selectedRow && selectedRow.status_cut_id,
            approved: selectedRow.approved,
            contract_id: selectedRow.contract_id,
            system_id: toSend.system,
            classification_id: toSend.classification || selectedRow && selectedRow.classification,
            responsibility_id: toSend.responsibility,
            alarm_id: toSend.alarm || selectedRow && selectedRow.alarm,
            comment: toSend.comment || selectedRow && selectedRow.comment,
            start_time: toSend.start_time || selectedRow && selectedRow.start_time,
            end_time: toSend.end_time || selectedRow && selectedRow.end_time,
            cut_time: toSend.cut_time,
            left_status: {
                system_id: toSend.left_system,
                classification_id: toSend.left_classification,
                responsibility_id: toSend.left_responsibility,
                alarm_id: toSend.left_alarm,
                comment: toSend.left_comment || null
            },
            right_status: {
                system_id: toSend.right_system,
                classification_id: toSend.right_classification,
                responsibility_id: toSend.right_responsibility,
                alarm_id: toSend.right_alarm,
                comment: toSend.right_comment || null
            }
        }


        this.setState({
            executeAction: true,
            payload
        });
    }

    callData = data => {
        this.setState(prevState => ({
            data: { ...prevState.data, ...data }
        }));
    }

    async componentDidUpdate() {
        const { actionType, data, selectedRows, onActionSuccess = () => null, t } = this.props;
        const { opened, executeAction, payload } = this.state;

        let successActions = null
        if (opened && executeAction) {
            try {
                if (actionType === 'edit') {
                    const res = await editStatus(payload);
                    successActions = payload;
                } else if (actionType === 'create') {
                    const res = await createStatus({ ...payload, device_id: data.deviceId });
                    successActions = { ...payload, device_id: data.deviceId };
                } else if (actionType === 'delete') {
                    const res = await deleteStatus(payload);
                    successActions = payload;
                } else if (actionType === 'merge') {
                    const toMerge = { ...payload, contract_ids: selectedRows.map(row => row.contract_id) };
                    const res = await mergeStatus(toMerge);
                    successActions = toMerge;
                } else if (actionType === 'split') {
                    const res = await splitStatus(payload);
                    successActions = payload;
                }
                else if (actionType === 'approve') {

                    payload.approved = true;
                    //payload.checked_ids = [payload.contract_id]
                    payload.checked_ids = selectedRows.map(row => row.contract_id);
                    const res = await aproveStatus(payload);
                    successActions = payload;
                }
                else if (actionType === 'unapprove') {

                    payload.approved = false;
                    //payload.checked_ids = [payload.contract_id]
                    payload.checked_ids = selectedRows.map(row => row.contract_id);
                    const res = await aproveStatus(payload);
                    successActions = payload;
                }

                this.setState({
                    opened: false,
                    executeAction: false
                });

                toast.success(t('events_success_action', { actionType }), {
                    position: 'top-right',
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true
                });

                await this.context.refreshTables(true);

            } catch (err) {
                console.log('Error on${actionType}', actionType, err.toString());
                toast.error(t('events_failed_action', { actionType }), {
                    position: 'top-right',
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true
                });

                this.setState({
                    opened: false,
                    executeAction: false,
                    success: null
                });
            }
        }
    }

    render() {
        const {
            classes,
            children,
            contentComponent = (<span>Content</span>),
            title = 'Title',
            cancel = 'Cancel',
            confirm = 'OK',
            selectedRows,
            actionType,
            infoPerDevices,
            t
        } = this.props;

        const { opened, executeAction } = this.state;

        // NOTE: We only can do this because it's certainly that user cannot triggers
        // actions using two different devices
        const deviceId = selectedRows &&
            selectedRows.length > 0 &&
            selectedRows[0].deviceId

        return (
            <React.Fragment>
                <DialogContext.Provider value={{ toggleDialog: this.toggleDialog }} >
                    {children}
                </DialogContext.Provider>
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth='md'
                    fullWidth
                    open={opened}
                    className={classes.root}
                    scroll='body'
                >
                    <DialogTitle className={executeAction && classes.transparentBg}>
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <React.Fragment>
                            {
                                executeAction && (
                                    <CircularLoading
                                        loading={t('loading')}
                                    >
                                        <span>{t('Please wait until the action is finished')}</span>
                                    </CircularLoading>
                                )
                            }
                            <SelectorContext.Provider value={
                                {
                                    sendData: this.callData,
                                    selectedRows,
                                    actionType,
                                    deviceAlarms: infoPerDevices[deviceId].alarms,
                                    deviceClassifications: infoPerDevices[deviceId].classifications,
                                    deviceResponsibilities: infoPerDevices[deviceId].responsibilities,
                                    deviceSystems: infoPerDevices[deviceId].systems
                                }
                            }
                            >
                                <div className={executeAction && classes.transparentBg} >
                                    {contentComponent}
                                </div>
                            </SelectorContext.Provider>
                        </React.Fragment>
                    </DialogContent>
                    <DialogActions className={executeAction && classes.transparentBg}>
                        <Button
                            onClick={this.toggleDialog}
                            color='primary'
                        >
                            {t(cancel)}
                        </Button>
                        <Button
                            onClick={this.prepareCloseDialog}
                            variant='contained'
                            color='primary'
                        >
                            {t(confirm)}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

ActionBaseDialog.contextType = PageContext;

export default withTranslation()(withStyles(styles)(ActionBaseDialog));