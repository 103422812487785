import {combineReducers} from 'redux';

import DasboardReducers from '../modules/Dashboard/reducers';
import UserReducer from './UserReducer';
import AuthedUserReducer from './AuthedUserReducer';
import AuthErrorReducer from './AuthErrorReducer';
import NavigationReducer from './NavigationReducer';
import ModulesReducer from './ModulesReducer';
import ClientReducer from './ClientReducer';
import HierarchyReducer from './HierarchyReducer';
import NotificationsReducer from './NotificationsReducer';
import EnvelopeHierarchyReducer from './EnvelopeHierarchyReducer';

import OldParksInfoReducer from './OldParksInfoReducer';

import { PredictiveModelReducer } from '../new-components/Filters/PredictiveModel';

export default combineReducers({
    Dashboard: DasboardReducers,
    Users: UserReducer,
    AuthedUser: AuthedUserReducer,
    Client: ClientReducer,
    Modules: ModulesReducer,
    Navigation: NavigationReducer,
    DevicesHierarchy: HierarchyReducer,
    // EnvelopesHierarchy: EnvelopeHierarchyReducer,
    Notifications: NotificationsReducer,
    AuthError: AuthErrorReducer,

    User: OldParksInfoReducer,
    PredictiveModels: PredictiveModelReducer
})
