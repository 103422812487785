import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import GridLayout from 'react-grid-layout'

import {
  withStyles
} from '@material-ui/core'

import * as R from 'ramda'

import {
  GRAPHIC_TYPE_KEY_MATRICS,
  GRAPHIC_TYPE_HEAT_MAP,
  GRAPHIC_TYPE_SCATTER_PLOT,
  GRAPHIC_TYPE_MAP,
  GRAPHIC_TYPE_HYDRO_GAUGE,
  GRAPHIC_TYPE_AVAILABILITY_PER_MONTH_HYDRO,
  GRAPHIC_TYPE_INTERACTIVE_TABLE_HYDRO,
  GRAPHIC_TYPE_INTERACTIVE_TABLE_HYDRO_2,
  GRAPHIC_TYPE_POWER_CURVE_SCATTER_HYDRO
} from '../../constants/graphic-types';

import GraphicWidget from '../GraphicModel/GraphicWidget';
import GraphicKeyMatrics from '../GraphicModel/GraphicKeyMatrics/GraphicKeyMatrics';
import GraphicHeatMap from '../GraphicModel/GraphicHeatMap/GraphicHeatMap';
import GraphicWaterfall from '../GraphicModel/GraphicWaterfall/GraphicWaterfall';
import GraphicColumnRange from '../GraphicModel/GraphicColumnRange/GraphicColumnRange';
import GraphicBar from '../GraphicModel/GraphicBar/GraphicBar';
import GraphicBarHydro from '../GraphicModel/GraphicBar/GraphicBarHydro';
import GraphicBarHydro2 from '../GraphicModel/GraphicBar/GraphicBarHydro2';
import GraphicBarHydro3 from '../GraphicModel/GraphicBar/GraphicBarHydro3';

import GraphicInteractiveTableHydro2 from '../GraphicModel/GraphicInteractiveTable/GraphicInteractiveTableHydro2';
import GraphicInteractiveTableHydro from '../GraphicModel/GraphicInteractiveTable/GraphicInteractiveTableHydro';
import GraphicInteractiveTable from '../GraphicModel/GraphicInteractiveTable/GraphicInteractiveTable';
import GraphicGauge from '../GraphicModel/GraphicGauge/GraphicGauge';
import GraphicGaugeHydro from '../GraphicModel/GraphicGauge/GraphicGaugeHydro';

import GraphicBarDecorators from '../GraphicModel/GraphicBar/decorators/GraphicBarDecorators';
import GraphicScatter from '../GraphicModel/GraphicScatter/GraphicScatter';

import GraphicBoxplot from '../GraphicModel/GraphicBoxplot/GraphicBoxplot';
import GraphicScoreTimeline from '../GraphicModel/GraphicScoreTimeline/GraphicScoreTimeline';
import GraphicImage from '../GraphicModel/GraphicImage/GraphicImage';
import MapWidget from '../GraphicModel/MapWidget/MapWidget';
import GraphicRealTimeTable from '../GraphicModel/GraphicRealTimeTable/GraphicRealTimeTable'

import GraphicWindRose from '../GraphicModel/GraphicWindRose/GraphicWindRose'
import GraphicAreaWithZones from '../GraphicModel/GraphicAreaWithZones/GraphicAreaWithZones'
import GraphicLevelFluency from '../GraphicModel/GraphicLevelFluency/GraphicLevelFluency'

import DefaultErrorHandler from '../Handler/DefaultErrorHandler'

import GraphicPieChart from '../GraphicModel/PieChart';

import GraphicMultipleSeries from '../GraphicModel/GraphicMultipleSeries/GraphicMultipleSeries';

import CircularLoading from '../../components/Loading/CircularLoading';

import {
  getDataWidget
} from '../../actions/WidgetPageActions';

import {
  graphicsForTypeBar,
  graphicsForTypeStackedBar,
  graphicsForTypePareto,
  graphicsForTypeWaterfall,
  graphicsForTypeColumnRange,
  graphicsForTypeInteractiveTable,
  graphicsForTypeGauge,
  graphicsForTypeBoxplot,
  graphicsForTypeMetrics,
  graphicsForTypeScoreTimeline,
  graphicsForTypeScatter,
  graphicsForTypeImage,
  graphicsForTypeHeatMap,
  graphicsForTypeRealtimeTable,
  graphicsForTypeWindRose,
  graphicsForTypeAreaWithZones,
  graphicsForTypeLevelFluency,
  graphicsForTypePieChart,
  graphicsForMultipleSeries
} from '../../constants/graph-types';
import { PieChart } from '@material-ui/icons';

const styles = theme => ({

})

class WidgetDriver extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isWidgetLoading: false,
      retries: 0,
      shouldGetData: true,
      headerFontSize: 6,
    }

    this.failureComponent = widget => (
      <React.Fragment>
        <h3>Failure on Loading Wiget</h3>
        <br />
        <p>Please try to change filter range.</p>
      </React.Fragment>
    );

    this.widgetComponent = (content, widget, selectedPage, module_id, reducer_name, real_time) => (
      <DefaultErrorHandler>
        <GraphicWidget
          selectedPage={selectedPage}
          showTable={this.props.showTable}
          unique={this.props.unique}
          isAnalysis={this.props.isAnalysis}
          ignoreIcons={this.props.ignoreIcons}
          widget={widget}
          module_id={module_id}
          reducer_name={reducer_name}
          real_time={real_time}
          {...this.state}
          key={`${widget.id}-${module_id}`}
          isEditing={this.props.isEditing}
          openRemoveDialog={this.props.openRemoveDialog}
          // enableLoading={this.props.enableLoading}
        >
          {content}
        </GraphicWidget>
      </DefaultErrorHandler>
    );

    this.timeout = null;
    this.retries = 0;
  }

  getData = (moduleId, selectedPageId, widget, reducerName) => () => {
    // this.props.getDataWidget(moduleId, selectedPageId, widget, reducerName);
    // console.log('getting data', moduleId, selectedPageId, widget, reducerName)
  }

  widgetFinishedLoading = () => {
    this.setState({
      isWidgetLoading: false
    });
  }

  componentDidMount() {
    const { widget } = this.props;
    const headerFontSize = widget && widget.grid ? Math.floor(widget.grid.w * 2 + widget.grid.h * 1.5) : '1em';
    this.setState({
      headerFontSize: headerFontSize > 6 ? headerFontSize : 6
    })
  }

  render() {
    const { classes, selectedPage, parks, history, module_id, reducer_name, items, subpark } = this.props;

    const { widget } = this.props;
    const { data, type, id, grid, loading } = widget || {};

    if ('changeLoading' in this.props) {


      this.props.changeLoading(loading)

    }

    return (
      loading ? (

        this.widgetComponent(
          (
            <CircularLoading notOverlay={true}>
              <p
                style={{
                  fontSize: this.state.headerFontSize
                }}
              >
                Please wait a moment while widget loads.
              </p>
            </CircularLoading>
          ),
          widget, selectedPage, module_id, reducer_name
        )
      )
        : !data || data.error && !type === GRAPHIC_TYPE_MAP ? (
          this.widgetComponent(
            (
              <React.Fragment>
                <h5>Error</h5>
                <p>{data && data.message.toString()}</p>
              </React.Fragment>
            ),
            widget, selectedPage, module_id, reducer_name
          )
        )
          : type === GRAPHIC_TYPE_MAP ? (
            this.widgetComponent(
              (
                <MapWidget
                  selectedPage={selectedPage}
                  widget={widget}
                  parks={parks}
                  module_id={module_id}
                  reducer_name={reducer_name}
                />
              ),
              widget, selectedPage, module_id, reducer_name
            )
          )
            : graphicsForTypeMetrics.filter(graphic => type === graphic.type).length > 0 ? (
              this.widgetComponent(
                (<GraphicKeyMatrics data={data} graphic={widget} pageInfo={selectedPage} />),
                widget, selectedPage, module_id, reducer_name
              )
            )
              : type === GRAPHIC_TYPE_HEAT_MAP ? (
                this.widgetComponent(
                  (<GraphicHeatMap data={data} graphic={widget} pageInfo={selectedPage} />),
                  widget, selectedPage, module_id, reducer_name
                )
              )
                : type === GRAPHIC_TYPE_POWER_CURVE_SCATTER_HYDRO ? (
                  this.widgetComponent(
                    (<GraphicBarHydro2 data={data} graphic={widget} pageInfo={selectedPage} items={items} subpark={subpark} />),
                    widget, selectedPage, module_id, reducer_name
                  )
                )
                  : type === GRAPHIC_TYPE_INTERACTIVE_TABLE_HYDRO ? (
                    this.widgetComponent(
                      (<GraphicInteractiveTableHydro data={data} graphic={widget} pageInfo={selectedPage} items={items} subpark={subpark} />),
                      widget, selectedPage, module_id, reducer_name
                    )
                  )
                    : type === GRAPHIC_TYPE_INTERACTIVE_TABLE_HYDRO_2 ? (
                      this.widgetComponent(
                        (<GraphicInteractiveTableHydro2 data={data} graphic={widget} pageInfo={selectedPage} items={items} subpark={subpark} />),
                        widget, selectedPage, module_id, reducer_name
                      )
                    )
                      : type === GRAPHIC_TYPE_HYDRO_GAUGE ? (
                        this.widgetComponent(
                          (<GraphicGaugeHydro data={data} graphic={widget} pageInfo={selectedPage} items={items} subpark={subpark} />),
                          widget, selectedPage, module_id, reducer_name
                        )
                      )
                        : type === GRAPHIC_TYPE_AVAILABILITY_PER_MONTH_HYDRO ? (
                          this.widgetComponent(
                            (<GraphicBarHydro data={data} graphic={widget} pageInfo={selectedPage} items={items} subpark={subpark} />),
                            widget, selectedPage, module_id, reducer_name
                          )
                        )
                          : graphicsForTypeWaterfall.filter(graphic => type === graphic.type).length > 0 ? (
                            this.widgetComponent(
                              (<GraphicWaterfall data={data} graphic={widget} pageInfo={selectedPage} isAnalysis={this.props.isAnalysis} />),
                              widget, selectedPage, module_id, reducer_name
                            )
                          )
                            : graphicsForTypeScatter.filter(graphic => type === graphic.type).length > 0 ? (
                              this.widgetComponent(
                                (<GraphicScatter data={data} graphic={widget} pageInfo={selectedPage} isAnalysis={this.props.isAnalysis}
                                  filterTable={this.props.filterTable} tableFilter={this.props.tableFilter} />),
                                widget, selectedPage, module_id, reducer_name
                              )
                            )
                              : graphicsForTypeColumnRange.filter(graphic => type === graphic.type).length > 0 ? (
                                this.widgetComponent(
                                  (<GraphicColumnRange data={data} graphic={widget} pageInfo={selectedPage} isAnalysis={this.props.isAnalysis} />),
                                  widget, selectedPage, module_id, reducer_name
                                )
                              )
                                : graphicsForTypeBar.filter(graphic => type === graphic.type).length > 0 ? (
                                  this.widgetComponent(
                                    (<GraphicBar data={data} graphic={{
                                      ...widget,
                                      preProcessDecorators: [
                                        {
                                          name: 'SeriesColorTreatment',
                                          params: [
                                            {
                                              bySerie: {
                                                ...(widget.seriesColors || {})
                                              }
                                            }
                                          ]
                                        }
                                      ]
                                    }} pageInfo={selectedPage} isAnalysis={this.props.isAnalysis} />),
                                    widget, selectedPage, module_id, reducer_name
                                  )
                                )
                                  : graphicsForTypeStackedBar.filter(graphic => type === graphic.type).length > 0 ? (
                                    this.widgetComponent(
                                      (
                                        <GraphicBar
                                          data={data}
                                          graphic={{
                                            ...widget,
                                            preProcessDecorators: [
                                              {
                                                name: 'SeriesColorTreatment',
                                                params: [
                                                  {
                                                    bySerie: {
                                                      ...(widget.seriesColors || {})
                                                    }
                                                  }
                                                ]
                                              },
                                              {
                                                name: 'StackBars',
                                                params: []
                                              }
                                            ]
                                          }}
                                          pageInfo={selectedPage}
                                          isAnalysis={this.props.isAnalysis}
                                        />
                                      ),
                                      widget,
                                      selectedPage,
                                      module_id,
                                      reducer_name
                                    )
                                  )
                                    : graphicsForTypePareto.filter(graphic => type === graphic.type).length > 0 ? (
                                      this.widgetComponent(
                                        (
                                          <GraphicBar
                                            data={data}
                                            graphic={{
                                              ...widget,
                                              preProcessDecorators: [
                                                {
                                                  name: 'Order',
                                                  params: [
                                                    'DESC'
                                                  ]
                                                },
                                                {
                                                  name: 'TopBottom',
                                                  params: [
                                                    10,
                                                    'top'
                                                  ]
                                                },
                                                {
                                                  name: 'Pareto',
                                                  params: []
                                                },
                                                {
                                                  name: 'SeriesColorTreatment',
                                                  params: [
                                                    {
                                                      bySerie: {
                                                        ...(widget.seriesColors || {})
                                                      }
                                                    }
                                                  ]
                                                },
                                                {
                                                  name: 'StackBars',
                                                  params: []
                                                }
                                              ]
                                            }}
                                            pageInfo={selectedPage}
                                            isAnalysis={this.props.isAnalysis}
                                          />
                                        ),
                                        widget,
                                        selectedPage,
                                        module_id,
                                        reducer_name
                                      )
                                    )
                                      : graphicsForTypeInteractiveTable.filter(graphic => type === graphic.type).length > 0 ? (
                                        this.widgetComponent(
                                          (<GraphicInteractiveTable data={data} graphic={widget} pageInfo={selectedPage} />),
                                          widget, selectedPage, module_id, reducer_name
                                        )
                                      )
                                        : graphicsForTypeGauge.filter(graphic => type === graphic.type).length > 0 ? (
                                          this.widgetComponent(
                                            (<GraphicGauge data={data} graphic={widget} pageInfo={selectedPage} />),
                                            widget, selectedPage, module_id, reducer_name
                                          )
                                        )
                                          : graphicsForTypeBoxplot.filter(graphic => type === graphic.type).length > 0 ? (
                                            this.widgetComponent(
                                              (<GraphicBoxplot data={data} graphic={widget} pageInfo={selectedPage} isAnalysis={this.props.isAnalysis} />),
                                              widget, selectedPage, module_id, reducer_name
                                            )
                                          )
                                            : graphicsForTypeScoreTimeline.filter(graphic => type === graphic.type).length > 0 ? (
                                              this.widgetComponent(
                                                (<GraphicScoreTimeline data={data} graphic={widget} pageInfo={selectedPage} />),
                                                widget, selectedPage, module_id, reducer_name
                                              )
                                            )
                                              : graphicsForTypeHeatMap.filter(graphic => type === graphic.type).length > 0 ? (
                                                this.widgetComponent(
                                                  (<GraphicHeatMap data={data} graphic={widget} pageInfo={selectedPage} />),
                                                  widget, selectedPage, module_id, reducer_name
                                                )
                                              )
                                                : graphicsForTypeImage.filter(graphic => type === graphic.type).length > 0 ? (
                                                  this.widgetComponent(
                                                    (<GraphicImage data={data} graphic={widget} pageInfo={selectedPage} />),
                                                    widget, selectedPage, module_id, reducer_name
                                                  )
                                                )
                                                  : graphicsForTypeWindRose.filter(graphic => type === graphic.type).length > 0 ? (
                                                    this.widgetComponent(
                                                      (<GraphicWindRose data={data} graphic={widget} pageInfo={selectedPage} />),
                                                      widget, selectedPage, module_id, reducer_name
                                                    )
                                                  )
                                                    : graphicsForTypeAreaWithZones.filter(graphic => type === graphic.type).length > 0 ? (
                                                      this.widgetComponent(
                                                        (<GraphicBarHydro3 data={data} graphic={widget} pageInfo={selectedPage} items={items} subpark={subpark} />),
                                                        widget, selectedPage, module_id, reducer_name
                                                      )
                                                    )
                                                      : graphicsForTypeLevelFluency.filter(graphic => type === graphic.type).length > 0 ? (
                                                        this.widgetComponent(
                                                          (<GraphicLevelFluency data={data} graphic={widget} pageInfo={selectedPage} items={items} subpark={subpark} />),
                                                          widget, selectedPage, module_id, reducer_name
                                                        )
                                                      )
                                                        : graphicsForTypeRealtimeTable.filter(graphic => type === graphic.type).length > 0 ? (
                                                          this.widgetComponent(
                                                            (
                                                              <GraphicRealTimeTable
                                                                last_update={widget.last_update}
                                                                data={widget.data}
                                                                procedure_name={widget.procedure_name}
                                                                payload_id={widget.payload_id}
                                                                data_type={widget.data_type}
                                                                column_types={widget.column_types}
                                                                subpark_ids={widget.filter && widget.filter.subparks ? widget.filter.subparks : null}
                                                                wtgs_ids={widget.filter && widget.filter.devices ? widget.filter.devices : null}
                                                              />
                                                            ),
                                                            widget, null, null, null, true
                                                          )
                                                        )
                                                          : ( type === 'pie-chart' || graphicsForTypePieChart.filter(graphic => type === graphic.type).length > 0 ) ? (
                                                            this.widgetComponent(
                                                              <GraphicPieChart
                                                                data={data}
                                                                graphic={widget}
                                                                pageInfo={selectedPage}
                                                                items={items}
                                                                subpark={subpark}
                                                                filterTable={this.props.filterTable}
                                                                tableFilter={this.props.tableFilter}
                                                              />,
                                                              widget,
                                                              selectedPage,
                                                              module_id,
                                                              reducer_name
                                                            )
                                                          ) : graphicsForMultipleSeries.filter(graphic => type === graphic.type).length > 0 ? (
                                                            this.widgetComponent(
                                                              <GraphicMultipleSeries data={data} graphic={widget} pageInfo={selectedPage} />,
                                                              widget,
                                                              selectedPage,
                                                              module_id,
                                                              reducer_name
                                                            )
                                                          ) :
                                                              (<h4>Graphic None Type</h4 >)
    )
  }
}

// const mapStateToProps = () => ({});
// const mapDispatchToProps = (dispatch) => bindActionCreators({getDataWidget}, dispatch);

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WidgetDriver));
export default withStyles(styles)(WidgetDriver);