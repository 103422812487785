import React from 'react';

import Auth from "../modules/Auth/Auth";
import Report from "../modules/Report/Report";
import Dashboard2 from "../modules/Dashboard/Dashboard2";
import Dashboard from '../modules/Dashboard/Dashboard';
import ManagementPage from "../modules/Dashboard/pages/ManagementPage/ManagementPage";
import AnalysisPage from "../modules/Dashboard/pages/Delfos-1-0/AnalysisPage";
import Delfos10ManagementPage from "../modules/Dashboard/pages/Delfos-1-0/ManagementPage/ManagementPage";
import Delfos10OperationalPage from "../modules/Dashboard/pages/Delfos-1-0/OperationalPage/OperationalPage";
import Delfos10PerformancePage from "../modules/Dashboard/pages/Delfos-1-0/PerformancePage/PerformancePage";
import Delfos10ReliabilityPage from "../modules/Dashboard/pages/Delfos-1-0/ReliabilityPage/ReliabilityPage";
import PredictionPage from "../modules/Dashboard/pages/PredictionPage/PredictionPage";
import DevicePage from "../modules/Dashboard/pages/DevicePage/DevicePage";
import EnvelopePage from "../modules/Dashboard/pages/EnvelopePage/EnvelopePage";
import ReportsPage from "../modules/Dashboard/pages/ReportsPage/ReportsPage";
import AlarmsPage from "../modules/Dashboard/pages/AlarmsPage/AlarmsPage";
import DowntimePage from "../modules/Dashboard/pages/AlarmsPage/DowntimePage";
import StudioPage from "../modules/Dashboard/pages/StudioPage/StudioPage";
import MonitorPage from "../modules/Dashboard/pages/MonitorPage/MonitorPage";
import HydroPage from "../modules/Dashboard/pages/HydroPage/HydroPage";
import CalendarPage from "../modules/Dashboard/pages/CalendarPage/PagesManager";
import EditMode from "../modules/Dashboard/pages/EditMode/EditMode";
import AssetPage from "../modules/Dashboard/pages/AssetPage/AssetPage";
import MovementAssetComponent from "../modules/Dashboard/pages/AssetPage/pages/MovementPage/pages/MovementAssetComponent/MovementAssetComponent";
import MovementAssetConsumable from "../modules/Dashboard/pages/AssetPage/pages/MovementPage/pages/MovementAssetConsumable/MovementAssetConsumable";
import MovementAssetService from "../modules/Dashboard/pages/AssetPage/pages/MovementPage/pages/MovementAssetService/MovementAssetService";
import MovementComponent from "../modules/Dashboard/pages/AssetPage/pages/MovementPage/pages/MovementComponent/MovementComponent";
import MovementConsumable from "../modules/Dashboard/pages/AssetPage/pages/MovementPage/pages/MovementConsumable/MovementConsumable";
import AssetControlPage from "../modules/Dashboard/pages/AssetControlPage/AssetControlPage";
import AssetModelPage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/AssetModelPage/AssetModelPage";
import AssetModelComponentPage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/AssetModelComponentPage/AssetModelComponentPage";
import AssetModelConsumablePage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/AssetModelConsumablePage/AssetModelConsumablePage";
import RegistryAssetPage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/AssetPage/AssetPage";
import AssetComponentPage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/AssetComponentPage/AssetComponentPage";
import AssetConsumablePage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/AssetConsumablePage/AssetConsumablePage";
import AssetServicePage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/AssetServicePage/AssetServicePage";
import ComponentPage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/ComponentPage/ComponentPage";
import ConsumablePage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/ConsumablePage/ConsumablePage";
import ServicePage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/ServicePage/ServicePage";
import StoragePage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/StoragePage/StoragePage";
import AssetModelServicePage from "../modules/Dashboard/pages/AssetControlPage/pages/RegistryPage/pages/AssetModelServicePage/AssetModelServicePage";
import StockItemPage from "../modules/Dashboard/pages/AssetControlPage/pages/StockPage/pages/StockItemPage/StockItemPage";
import OPCPage from "../modules/Dashboard/pages/OPCPage/OPCPage";
import ScadaPage from "../modules/Dashboard/pages/ScadaPage/ScadaPage";
// import EventsTablePage from '../modules/Dashboard/pages/EventsPage/EventsTablePage';
import EventsTablePageParksInfo from '../modules/Dashboard/pages/EventsPage/EventsTablePageParksInfo';
import DocumentationPage from '../modules/Dashboard/pages/DocumentationPage/DocumentationPage';
import ReportPage from '../modules/Dashboard/pages/ReportPage';

import AssetsManagement from '../modules/Dashboard/pages/AssetsManagement';

export const ROUTE_AUTH = '/auth'

export const ROUTE_REPORT = '/report'

export const ROUTE_DASHBOARD = '/dashboard'

// ======================== Update 2019-09-25 ===========================================
export const ROUTE_ANALYSIS = `${ROUTE_DASHBOARD}/analysis`
export const ROUTE_ANALYSIS_MANAGEMENT = `${ROUTE_ANALYSIS}/management`
export const ROUTE_ANALYSIS_OPERATIONAL = `${ROUTE_ANALYSIS}/operational`
export const ROUTE_ANALYSIS_RELIABILITY = `${ROUTE_ANALYSIS}/reliability`
export const ROUTE_ANALYSIS_PERFORMANCE = `${ROUTE_ANALYSIS}/performance`

export const ROUTE_ANALYSIS_2 = `${ROUTE_DASHBOARD}/analysis2`
export const ROUTE_ANALYSIS_2_MANAGEMENT = `${ROUTE_ANALYSIS_2}/management`
export const ROUTE_ANALYSIS_2_OPERATIONAL = `${ROUTE_ANALYSIS_2}/operational`
export const ROUTE_ANALYSIS_2_RELIABILITY = `${ROUTE_ANALYSIS_2}/reliability`
export const ROUTE_ANALYSIS_2_PERFORMANCE = `${ROUTE_ANALYSIS_2}/performance`



export const ROUTE_OPERATION_AND_MAINTENANCE = `${ROUTE_DASHBOARD}/operation-and-maintenance`
export const ROUTE_ASSETS_MANAGEMENT = `${ROUTE_OPERATION_AND_MAINTENANCE}/assets-management`
export const ROUTE_CALENDAR = `${ROUTE_OPERATION_AND_MAINTENANCE}/calendar`
export const ROUTE_EVENTS = `${ROUTE_OPERATION_AND_MAINTENANCE}/events`
export const ROUTE_INVENTORY = `${ROUTE_OPERATION_AND_MAINTENANCE}/inventory`
export const ROUTE_INVENTORY_REGISTRY = `${ROUTE_INVENTORY}-registry`
export const ROUTE_INVENTORY_MOVEMENT = `${ROUTE_INVENTORY}-movement`
export const ROUTE_INVENTORY_STOCK = `${ROUTE_INVENTORY}-stock`

export const ROUTE_INFO = `${ROUTE_DASHBOARD}/info`
export const ROUTE_INFO_SCADA_DATA = `${ROUTE_INFO}/scada-data`
export const ROUTE_INFO_REPORTS = `${ROUTE_INFO}/reports-management`
export const ROUTE_INFO_DOCUMENTATION = `${ROUTE_INFO}/docs`
export const ROUTE_INFO_REPORT = `${ROUTE_INFO}/reports`

// ======================================================================================

export const ROUTE_MAIN = `${ROUTE_DASHBOARD}/main`
export const ROUTE_MAIN_MANAGEMENT = `${ROUTE_MAIN}/management`
export const ROUTE_MAIN_MANAGEMENT_EDIT = `${ROUTE_MAIN}/management-edit`
export const ROUTE_MAIN_PREDICTION = `${ROUTE_MAIN}/prediction`
export const ROUTE_MAIN_DEVICE = `${ROUTE_MAIN}/device`
export const ROUTE_MAIN_VIRTUAL_CLONE = `${ROUTE_MAIN}/virtual-clone`

export const ROUTE_MAIN_REPORTS = `${ROUTE_DASHBOARD}/reports`
export const ROUTE_MAIN_REPORTS_MANAGEMENT = `${ROUTE_MAIN_REPORTS}/management`
export const ROUTE_MAIN_REPORTS_MANAGEMENT_NEW = `${ROUTE_MAIN_REPORTS_MANAGEMENT}/new`
export const ROUTE_MAIN_REPORTS_TEMPLATES = `${ROUTE_MAIN_REPORTS}/templates`

export const ROUTE_MAIN_MONITOR = `${ROUTE_DASHBOARD}/monitor`

export const ROUTE_MAIN_OPC = `${ROUTE_DASHBOARD}/opc`
export const ROUTE_MAIN_OPC_MONITOR = `${ROUTE_MAIN_OPC}/monitor`
export const ROUTE_MAIN_OPC_MAP = `${ROUTE_MAIN_OPC}/map`
export const ROUTE_MAIN_OPC_PORTFOLIO = `${ROUTE_MAIN_OPC}/portfolio`
export const ROUTE_MAIN_OPC_OVERVIEW = `${ROUTE_MAIN_OPC}/overview`

export const ROUTE_MAIN_STUDIO = `${ROUTE_DASHBOARD}/studio`

export const ROUTE_MAIN_CALENDAR = `${ROUTE_DASHBOARD}/calendar`
export const ROUTE_MAIN_SCADA = `${ROUTE_DASHBOARD}/scada`

export const ROUTE_MAIN_PCH = `${ROUTE_DASHBOARD}/pch`
export const ROUTE_MAIN_PCH_MONITOR = `${ROUTE_MAIN_PCH}/monitor`
export const ROUTE_MAIN_PCH_HISTORY = `${ROUTE_MAIN_PCH}/history`
export const ROUTE_MAIN_PCH_ALARM = `${ROUTE_MAIN_PCH}/alarm`
export const ROUTE_MAIN_PCH_MAIN = `${ROUTE_MAIN_PCH}/main`


export const ROUTER_DARK_HEADERS = [
  ROUTE_MAIN_PCH_MONITOR,
  ROUTE_MAIN_MONITOR,
  ROUTE_MAIN_OPC_MONITOR,
  ROUTE_MAIN_OPC_PORTFOLIO

]

export const ROUTE_DELFOS_1_0 = `${ROUTE_DASHBOARD}/delfos-1-0`
export const ROUTE_DELFOS_1_0_MANAGEMENT = `${ROUTE_DELFOS_1_0}/management`
export const ROUTE_DELFOS_1_0_OPERATIONAL = `${ROUTE_DELFOS_1_0}/operational`
export const ROUTE_DELFOS_1_0_RELIABILITY = `${ROUTE_DELFOS_1_0}/reliability`
export const ROUTE_DELFOS_1_0_PERFORMANCE = `${ROUTE_DELFOS_1_0}/performance`

export const ROUTE_DASHBOARD_INVENTORY = `${ROUTE_DASHBOARD}/inventory`
export const ROUTE_DASHBOARD_INVENTORY_ASSETS = `${ROUTE_DASHBOARD_INVENTORY}/assets`
export const ROUTE_DASHBOARD_INVENTORY_COMPONENTS = `${ROUTE_DASHBOARD_INVENTORY}/components`

// ASSET CONTROL
export const ROUTE_DASHBOARD_ASSET_CONTROL = `${ROUTE_DASHBOARD}/asset-control`
export const ROUTE_DASHBOARD_ASSET_CONTROL_OVERVIEW = `${ROUTE_DASHBOARD_ASSET_CONTROL}/overview`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY = `${ROUTE_DASHBOARD_ASSET_CONTROL}/registry`
export const ROUTE_DASHBOARD_ASSET_CONTROL_MOVEMENT = `${ROUTE_DASHBOARD_ASSET_CONTROL}/movement`
export const ROUTE_DASHBOARD_ASSET_CONTROL_STOCK = `${ROUTE_DASHBOARD_ASSET_CONTROL}/stock`

//--------------------------------------------------------------------------------------------------------------
// STOCK
export const ROUTE_DASHBOARD_ASSET_CONTROL_STOCK_ITEM = `${ROUTE_DASHBOARD_ASSET_CONTROL_STOCK}/:id/item`

//--------------------------------------------------------------------------------------------------------------
// REGISTRY

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/asset-model`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/asset-model-component`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/asset-model-consumable`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_SERVICE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/asset-model-service`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_SERVICE_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_SERVICE}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_SERVICE_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_SERVICE}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/asset`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/asset-component`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/asset-consumable`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_SERVICE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/asset-service`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_SERVICE_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_SERVICE}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_SERVICE_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_SERVICE}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/component`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/consumable`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_SERVICE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/service`

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}/storage`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE_EDIT = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE}/:id/edit`
export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE_CREATE = `${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE}/create`

export const ROUTE_DASHBOARD_ASSET_CONTROL_STOCK_LINKS = [
  {
    path: ROUTE_DASHBOARD_ASSET_CONTROL_STOCK_ITEM,
    name: 'Items',
    component: StockItemPage
  }
]

export const ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_LINKS = [
  {
    path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL,
    name: 'Asset Model',
    component: AssetModelPage
  },
  {
    path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_COMPONENT,
    name: 'Asset Model Component',
    component: AssetModelComponentPage
  },
  // {
  //   path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_CONSUMABLE,
  //   name: 'Asset Model Consumable',
  //   component: AssetModelConsumablePage
  // },
  // {
  //   path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_MODEL_SERVICE,
  //   name: 'Asset Model Service',
  //   component: AssetModelServicePage
  // },
  {
    path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET,
    name: 'Asset',
    component: RegistryAssetPage
  },
  // {
  //   path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_COMPONENT,
  //   name: 'Asset Component',
  //   component: AssetComponentPage
  // },
  // {
  //   path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CONSUMABLE,
  //   name: 'Asset Consumable',
  //   component: AssetConsumablePage
  // },
  // {
  //   path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_SERVICE,
  //   name: 'Asset Service',
  //   component: AssetServicePage
  // },
  {
    path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT,
    name: 'Component',
    component: ComponentPage
  },
  // {
  //   path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE,
  //   name: 'Consumable',
  //   component: ConsumablePage
  // },
  // {
  //   path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_SERVICE,
  //   name: 'Service',
  //   component: ServicePage
  // },
  {
    path: ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE,
    name: 'Storage',
    component: StoragePage
  }
]

export const ROUTE_DASHBOARD_ASSET = `${ROUTE_DASHBOARD}/asset`
export const ROUTE_DASHBOARD_ASSET_ASSET_MODEL = `${ROUTE_DASHBOARD_ASSET}/asset-model`

export const ROUTE_DASHBOARD_ASSET_ASSET = `${ROUTE_DASHBOARD_ASSET}/asset-item`

export const ROUTE_DASHBOARD_ASSET_COMPONENT = `${ROUTE_DASHBOARD_ASSET}/component`
export const ROUTE_DASHBOARD_ASSET_CONSUMABLE = `${ROUTE_DASHBOARD_ASSET}/consumable`
export const ROUTE_DASHBOARD_ASSET_SERVICE = `${ROUTE_DASHBOARD_ASSET}/service`

export const ROUTE_DASHBOARD_ASSET_MOVEMENT = `${ROUTE_DASHBOARD_ASSET}/movement`
export const ROUTE_DASHBOARD_ASSET_MOVEMENT_ASSET_COMPONENT = `${ROUTE_DASHBOARD_ASSET_MOVEMENT}/asset-component`
export const ROUTE_DASHBOARD_ASSET_MOVEMENT_ASSET_CONSUMABLE = `${ROUTE_DASHBOARD_ASSET_MOVEMENT}/asset-consumable`
export const ROUTE_DASHBOARD_ASSET_MOVEMENT_ASSET_SERVICE = `${ROUTE_DASHBOARD_ASSET_MOVEMENT}/asset-service`
export const ROUTE_DASHBOARD_ASSET_MOVEMENT_COMPONENT = `${ROUTE_DASHBOARD_ASSET_MOVEMENT}/component`
export const ROUTE_DASHBOARD_ASSET_MOVEMENT_CONSUMABLE = `${ROUTE_DASHBOARD_ASSET_MOVEMENT}/consumable`

export const ROUTES_MOVEMENTS = [
  {
    name: 'Component',
    path: ROUTE_DASHBOARD_ASSET_MOVEMENT_COMPONENT,
    component: MovementComponent
  },
  {
    name: 'Consumable',
    path: ROUTE_DASHBOARD_ASSET_MOVEMENT_CONSUMABLE,
    component: MovementConsumable
  },
  {
    name: 'Asset Component',
    path: ROUTE_DASHBOARD_ASSET_MOVEMENT_ASSET_COMPONENT,
    component: MovementAssetComponent
  },
  {
    name: 'Asset Consumable',
    path: ROUTE_DASHBOARD_ASSET_MOVEMENT_ASSET_CONSUMABLE,
    component: MovementAssetConsumable
  },
  {
    name: 'Asset Service',
    path: ROUTE_DASHBOARD_ASSET_MOVEMENT_ASSET_SERVICE,
    component: MovementAssetService
  }
]

export const ROUTE_DASHBOARD_REGISTRY = `${ROUTE_DASHBOARD}/assets-management`
export const ROUTE_DASHBOARD_REGISTRY_INVENTORY = `${ROUTE_DASHBOARD_REGISTRY}/assets-model`
export const ROUTE_DASHBOARD_ASSETS_MANAGEMENT_ASSETS = `${ROUTE_DASHBOARD_REGISTRY}/assets`
export const ROUTE_DASHBOARD_ASSETS_MANAGEMENT_COMPONENTS = `${ROUTE_DASHBOARD_REGISTRY}/components`

export const ROUTE_DASHBOARD_REGISTRY_ASSETS = `${ROUTE_DASHBOARD_REGISTRY}/assets`
export const ROUTE_DASHBOARD_REGISTRY_COMPONENTS = `${ROUTE_DASHBOARD_REGISTRY}/components`
export const ROUTE_DASHBOARD_REGISTRY_CONSUMABLES = `${ROUTE_DASHBOARD_REGISTRY}/consumables`

export const ROUTE_DASHBOARD_MAP = `${ROUTE_DASHBOARD}/map`
export const ROUTE_DASHBOARD_MONITOR = `${ROUTE_DASHBOARD}/monitor`
export const ROUTE_DASHBOARD_OPERATIONAL = `${ROUTE_DASHBOARD}/operational`
export const ROUTE_DASHBOARD_RELIABILITY = `${ROUTE_DASHBOARD}/reliability`
export const ROUTE_DASHBOARD_PERFOMANCE = `${ROUTE_DASHBOARD}/perfomance`
export const ROUTE_DASHBOARD_OPERATIONAL_REPORTS = `${ROUTE_DASHBOARD}/operational-reports`
export const ROUTE_DASHBOARD_DOWNLOAD_DATA = `${ROUTE_DASHBOARD}/download-data`
export const ROUTE_DASHBOARD_PREDICTION = `${ROUTE_DASHBOARD}/prediction`

export const ROUTE_DASHBOARD_EDIT_MODE = `${ROUTE_DASHBOARD}/edit-mode`
export const ROUTE_DASHBOARD_EDIT_MODE_DRAG_N_DROP = `${ROUTE_DASHBOARD_EDIT_MODE}/drag-n-drop`

export const ROUTE_DASHBOARD_EVENTS = `${ROUTE_DASHBOARD}/events`

export const ROUTE_DASHBOARD_EVENTS_TABLE = `${ROUTE_DASHBOARD_EVENTS}/table`
// export const ROUTE_DASHBOARD_EVENTS_DOWNTIMES = `${ROUTE_DASHBOARD_EVENTS}/downtimes`

const reportRoute = {
  path: ROUTE_REPORT,
  component: Report
}

export const ROUTER_AUTH_PUBLIC = [
  {
    path: ROUTE_AUTH,
    component: Auth
  },
  reportRoute
]

export const ROUTER_AUTH_PRIVATE = [
  {
    path: ROUTE_DASHBOARD,
    component: Dashboard2,
    // component: Dashboard,
    pages: [
      // MAIN
      {
        path: ROUTE_MAIN_MANAGEMENT,
        component: ManagementPage
      },
      {
        path: ROUTE_MAIN_MANAGEMENT_EDIT,
        component: EditMode
      },
      {
        path: ROUTE_MAIN_PREDICTION,
        component: PredictionPage
      },
      {
        path: ROUTE_MAIN_DEVICE,
        component: DevicePage
      },
      {
        path: ROUTE_MAIN_VIRTUAL_CLONE,
        component: EnvelopePage
      },
      // DELFOS 1.0
      {
        path: ROUTE_DELFOS_1_0_MANAGEMENT,
        component: Delfos10ManagementPage
      },
      {
        path: ROUTE_DELFOS_1_0_OPERATIONAL,
        component: Delfos10OperationalPage
      },
      {
        path: ROUTE_DELFOS_1_0_PERFORMANCE,
        component: Delfos10PerformancePage
      },
      {
        path: ROUTE_DELFOS_1_0_RELIABILITY,
        component: Delfos10ReliabilityPage
      },
      // ANALYSIS = DELFOS 1.0
      {
        path: ROUTE_ANALYSIS_MANAGEMENT,
        component: Delfos10ManagementPage
      },
      {
        path: ROUTE_ANALYSIS_OPERATIONAL,
        component: Delfos10OperationalPage
      },
      {
        path: ROUTE_ANALYSIS_PERFORMANCE,
        component: Delfos10PerformancePage
      },
      {
        path: ROUTE_ANALYSIS_RELIABILITY,
        component: Delfos10ReliabilityPage
      },
      // ANALYSIS 2 = DELFOS 1.0
      {
        path: ROUTE_ANALYSIS_2_MANAGEMENT,
        component: AnalysisPage
      },
      {
        path: ROUTE_ANALYSIS_2_OPERATIONAL,
        component: AnalysisPage
      },
      {
        path: ROUTE_ANALYSIS_2_PERFORMANCE,
        component: AnalysisPage
      },
      {
        path: ROUTE_ANALYSIS_2_RELIABILITY,
        component: AnalysisPage
      },

      // REPORTS
      {
        path: ROUTE_MAIN_REPORTS,
        component: ReportsPage
      },
      // EVENTS
      {
        path: ROUTE_DASHBOARD_EVENTS_TABLE,
        component: EventsTablePageParksInfo
      },
      // {
      //   path: ROUTE_DASHBOARD_EVENTS_DOWNTIMES,
      //   component: DowntimePage
      // },
      // STUDIO
      {
        path: ROUTE_MAIN_STUDIO,
        component: StudioPage
      },
      // MONITOR
      {
        path: ROUTE_MAIN_MONITOR,
        component: MonitorPage
      },
      // HYDRO
      {
        path: ROUTE_MAIN_PCH,
        component: HydroPage
      },
      // CALENDAR
      {
        path: ROUTE_MAIN_CALENDAR,
        component: CalendarPage
      },
      // ASSET CONTROL
      {
        path: ROUTE_DASHBOARD_ASSET_CONTROL,
        component: AssetControlPage
      },
      // OPC MONITOR
      {
        path: ROUTE_MAIN_OPC,
        component: OPCPage
      },
      {
        path: ROUTE_MAIN_SCADA,
        component: ScadaPage
      },
      //O&M
      {
        path: ROUTE_CALENDAR,
        component: CalendarPage
      },
      {
        path: ROUTE_ASSETS_MANAGEMENT,
        component: AssetsManagement
      },
      {
        path: ROUTE_EVENTS,
        component: EventsTablePageParksInfo
      },
      {
        path: ROUTE_INVENTORY_REGISTRY,
        name: 'Asset',
        component: RegistryAssetPage
      },
      {
        path: ROUTE_INVENTORY_MOVEMENT,
        component: AssetControlPage
      },
      {
        path: ROUTE_INVENTORY_STOCK,
        name: 'Storage',
        component: StoragePage
      },
      //INFO
      {
        path: ROUTE_INFO_SCADA_DATA,
        component: ScadaPage
      },
      {
        path: ROUTE_INFO_REPORTS,
        component: ReportsPage
      },
      {
        path: ROUTE_INFO_DOCUMENTATION,
        component: DocumentationPage
      },
      {
        path: ROUTE_INFO_REPORT,
        component: ReportPage
      }
    ]
  },
  reportRoute
]