import React from 'react';
import {Route, Link} from 'react-router-dom';
import {withStyles, Button, Icon} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

import { ROUTE_MAIN_MANAGEMENT_EDIT } from '../../../../constants/route-constant';

const styles = {
  buttonRight: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#FF3300'
    },
    borderRadius: 0,
    textTransform: 'capitalize',
    height: '100%',
    fontSize: 12
  },
  buttonLeft: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#FF3300'
    },
    borderRadius: 0,
    textTransform: 'capitalize',
    height: '100%'
  }
};

class SubheaderAllComponent extends React.Component {
  render () {
    const { classes, modules, navigate } = this.props;

    // console.log('ON SUBHEADER ALL COMPONENT', modules)

    return (
      <div
        style={{
          height: 36,
          width: '100%',
          paddingTop: 7,
          paddingBottom: 7
        }}
      >
        {
          modules && modules.map((itemModule, itemModuleIndex) => {
            return (
              <React.Fragment>
                <Route
                  key={itemModuleIndex}
                  path={itemModule.route}
                  render={props => {
                    return (
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        {
                          itemModule.submodules.map((submodule, submoduleIndex) => {
                            // console.log('ON RENDERING SUBMODULE', submodule)
                            return (
                              <React.Fragment>
                                <Link
                                  to={submodule.route}
                                  // onClick={() => navigate(submodule)}
                                  style={{
                                    textDecoration: 'none'
                                  }}
                                >
                                  <Button
                                    key={submoduleIndex}
                                    className={classes.buttonRight}
                                    disableRipple="true"
                                    style={{
                                      backgroundColor: this.props.location.pathname.search(submodule.route) >= 0
                                        ? '#FF3300'
                                        : this.props.isDark
                                          ? 'transparent'
                                          : null,
                                      color: this.props.location.pathname.search(submodule.route) >= 0
                                        ? 'white'
                                        : this.props.isDark
                                          ? 'white'
                                          : null,
                                      borderRadius: this.props.location.pathname.search(submodule.route) >= 0
                                        ? 25
                                        : null
                                    }}
                                    onClick={() => navigate(submodule.id)}
                                  >
                                    {submodule.name}
                                  </Button>
                                </Link>
                              </React.Fragment>
                            )
                          })
                        }

                        <div
                          style={{
                            flex: 1
                          }}
                        />
                        {
                          itemModule.printable &&
                            <Button
                              disableRipple='true'
                              className={classes.buttonLeft}
                              disableRipple='true'
                              onClick={() => {
                                window.print()
                              }}
                            >
                              <Icon
                                className={classes.icon}
                                style={{
                                  color:'#bdbdbd',
                                  marginRight: '5px'
                                }}
                              >
                                print  
                              </Icon>
                              Print
                            </Button>
                        }
                        {
                          itemModule.editable ?
                            this.props.location.pathname === ROUTE_MAIN_MANAGEMENT_EDIT
                              ? (
                                <Button
                                  // key={submoduleIndex}
                                  disableRipple="true"
                                  onClick={() => this.props.history.goBack()}
                                  className={classes.buttonLeft}
                                  disableRipple="true"
                                  // style={{
                                  //   backgroundColor: this.props.location.pathname === submodule.route
                                  //     ? 'orange'
                                  //     : null,
                                  //   color: this.props.location.pathname === submodule.route
                                  //     ? 'white'
                                  //     : null
                                  // }}
                                >
                                  <Icon
                                    className={classes.icon}
                                    style={{
                                      color:'#bdbdbd',
                                      marginRight: '5px'
                                    }}
                                  >
                                    done
                                  </Icon>
                                  Done
                                </Button>
                              )
                              : (
                                  <Button
                                    // key={submoduleIndex}
                                    disableRipple="true"
                                    className={classes.buttonLeft}
                                    disableRipple="true"
                                    // style={{
                                    //   backgroundColor: this.props.location.pathname === submodule.route
                                    //     ? 'orange'
                                    //     : null,
                                    //   color: this.props.location.pathname === submodule.route
                                    //     ? 'white'
                                    //     : null
                                    // }}
                                      onClick={this.props.toggleEdtitMode}

                                  >
                                    <Icon
                                      className={classes.icon}
                                      style={{
                                        color:'#bdbdbd',
                                        marginRight: '5px'
                                      }}
                                    >
                                      {this.props.editMode ? 'done' : 'create'}  
                                    </Icon>
                                    {this.props.editMode ? 'Done' : 'Edit Mode'}  
                                  </Button>
                              )

                            // (
                            //   <React.Fragment>
                            //     <Route
                            //       // key={itemModuleIndex}
                            //       path={itemModule.route}
                            //       render={props => {
                            //         return (
                            //           <Link
                            //             to={ROUTE_MAIN_MANAGEMENT_EDIT}
                            //             style={{
                            //               textDecoration: 'none'
                            //             }}
                            //           >
                            //             <Button
                            //               // key={submoduleIndex}
                            //               className={classes.buttonLeft}
                            //               // style={{
                            //               //   backgroundColor: this.props.location.pathname === submodule.route
                            //               //     ? 'orange'
                            //               //     : null,
                            //               //   color: this.props.location.pathname === submodule.route
                            //               //     ? 'white'
                            //               //     : null
                            //               // }}
                            //             >
                            //               <Icon
                            //                 className={classes.icon}
                            //                 style={{
                            //                   color:'#bdbdbd',
                            //                   marginRight: '5px'
                            //                 }}
                            //               >
                            //                 create
                            //               </Icon>
                            //               Edit Mode
                            //             </Button>
                            //           </Link>
                            //         )
                            //       }}
                            //     />

                            //     <Route
                            //       // key={itemModuleIndex}
                            //       path={ROUTE_MAIN_MANAGEMENT_EDIT}
                            //       render={props => {
                            //         return (
                            //           <Link
                            //             to={ROUTE_MAIN_MANAGEMENT_EDIT}
                            //             style={{
                            //               textDecoration: 'none'
                            //             }}
                            //           >
                            //             <Button
                            //               // key={submoduleIndex}
                            //               className={classes.buttonLeft}
                            //               // style={{
                            //               //   backgroundColor: this.props.location.pathname === submodule.route
                            //               //     ? 'orange'
                            //               //     : null,
                            //               //   color: this.props.location.pathname === submodule.route
                            //               //     ? 'white'
                            //               //     : null
                            //               // }}
                            //             >
                            //               <Icon
                            //                 className={classes.icon}
                            //                 style={{
                            //                   color:'#bdbdbd',
                            //                   marginRight: '5px'
                            //                 }}
                            //               >
                            //                 view_quilt
                            //               </Icon>
                            //               View Mode
                            //             </Button>
                            //           </Link>
                            //         )
                            //       }}
                            //     />
                            //   </React.Fragment>
                            // )


                            // edit_mode ?
                              // <Button
                              //   className={classes.buttonMenuSubHeader}
                              //   // onClick={() => this.goTo(previous_route, true)}
                              // >
                              //   <Icon
                              //     className={classes.icon}
                              //     style={{
                              //       color:'#bdbdbd',
                              //       marginRight: '5px'
                              //     }}
                              //   >
                              //     view_quilt
                              //   </Icon>
                              //   <Typography
                              //     variant="h2"
                              //     component="p"
                              //     style={{
                              //       fontSize: '1rem',
                              //       margin: 0
                              //     }}
                              //   >
                              //     View Mode
                              //   </Typography>
                              // </Button>
                          //   :
                          //     <Button className={classes.buttonMenuSubHeader} onClick={() => this.goTo(ROUTE_DASHBOARD_EDIT_MODE)} >
                          //       <Icon className={classes.icon} style={{ color:'#bdbdbd', marginRight: '5px'}}>create</Icon>
                          //       <Typography variant="h2" component="p"
                          //         style={{
                          //           fontSize: '1rem',
                          //           margin: 0
                          //         }}
                          //       >
                          //         Edit Mode
                          //       </Typography>
                          //     </Button>
                          : null

                        }
                      </div>
                    )
                  }}
                />
              </React.Fragment>
            )
          })
        }
      </div>
    )
  }
}

export default withStyles(styles)(SubheaderAllComponent);