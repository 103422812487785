import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import grey from '@material-ui/core/colors/grey'
import * as R from 'ramda'
import InventoryAssetsComponentTab from './tabs/InventoryAssetsComponentTab/InventoryAssetsComponentTab';
import InventoryAssetsConsumableTab from './tabs/InventoryAssetsConsumableTab/InventoryAssetsConsumableTab';
import { getAssetComponentByAssetIdProvider } from '../../../../../../../RegistryPage/providers/asset-component-provider';
import { getAssetConsumableByAssetIdProvider } from '../../../../../../../RegistryPage/providers/asset-consumable-provider';

const HEIGHT_SIZE = 48

const styles = theme => ({
  appBarTabs: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: `1px solid ${grey['200']}`,
    height: `${HEIGHT_SIZE}px`
  },
  tabs: {

  }
})

class InventoryAssetsDetailTabsComponent extends Component {
  constructor (props) {
    super (props)

    this.state = {
      tab: 0,
      
      

      componentSystems: [],
      consumableSystems: [],

      itemsLoading: false
    }
  }

  changeTabs = (e, value) => {
    this.setState({tab: value})
  }

  getItems = (idAsset) => {
    return Promise.all([
      getAssetComponentByAssetIdProvider(idAsset),
      getAssetConsumableByAssetIdProvider(idAsset)
    ])
  }

  componentDidMount() {
    // 
  }

  getPerSystem = (data) => {
    const slotsSystemId = R.uniq(data.map(item => item.system_id))
    const systems = slotsSystemId.map(system_id => {
      const system = R.head(data.filter(component => component.system_id === system_id)
        .map(component => {
          return {
            id: component.system_id,
            name: component.system
          }
        }))

      return {
        ...system,
        data: data.filter(component => component.system_id === system_id)
      }
    })

    return systems
  }

  componentWillReceiveProps (props) {
    if (this.props.asset) {
      if (props.asset !== this.props.asset) {
        this.setState({ itemsLoading: true })

        this.getItems(props.asset.id)
          .then(data => {
            const componentSystems = this.getPerSystem(data[0])
            const consumableSystems = this.getPerSystem(data[1])

            this.setState({
              componentSlots: data[0],
              componentSystems,
              consumableSystems,
              itemsLoading: false
            })
          })
          .catch(error => {
            console.error('componentWillReceiveProps', 'this.getItems', error)

            this.setState({
              itemsLoading: false
            })
          })
      }
    }
  }

  toggleChildrenItem = (itemsKey, idItem) => {
    this.setState(state => ({
      [itemsKey]: state[itemsKey].map(item => {
        if (item.id === idItem) {
          return {
            ...item,
            show: !item.show
          }
        }

        return item
      })
    }))
  }

  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <div
          style={{
            height: `${HEIGHT_SIZE}px`,
            width: '100%'
          }}
        >
          <AppBar
            position="static"
            className={classes.appBarTabs}
          >
            <Tabs
              className={classes.tabs}
              value={this.state.tab}
              onChange={this.changeTabs}
              indicatorColor={'secondary'}
              textColor={'secondary'}
            >
              <Tab label="Component" />
              <Tab label="Consumable" />
              {/* <Tab label="Services" /> */}
            </Tabs>
          </AppBar>
        </div>
        <div
          style={{
            height: `calc(100% - ${HEIGHT_SIZE}px)`,
            width: '100%',
            overflow: 'auto'
          }}
        >
          {this.state.tab === 0 && <InventoryAssetsComponentTab
            items={this.state.componentSlots}
            systems={this.state.componentSystems}
            itemsLoading={this.state.itemsLoading}
            toggleChildrenItem={this.toggleChildrenItem}
          />}
          {this.state.tab === 1 && <InventoryAssetsConsumableTab
            items={this.state.consumableSlots}
            systems={this.state.consumableSystems}
            itemsLoading={this.state.itemsLoading}
            toggleChildrenItem={this.toggleChildrenItem}
          />}
          {/* {this.state.tab === 2 && <RegistryAssetsServicesTab
            items={this.state.services}
            itemsLoading={this.state.itemsLoading}
          />} */}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(InventoryAssetsDetailTabsComponent)
