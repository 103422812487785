import React, {Component} from 'react'
// import { DateRangePicker } from 'react-dates'
// import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core';
// import * as R from 'ramda'
// import { injectGlobal } from 'styled-components'
// import 'react-dates/initialize'

// injectGlobal`
//   .DateRangePicker {
//     display: block!important;
//   }
// `

// const maxDate = moment().subtract(1, 'days')

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  filterContent: {
    display: 'flex',
    flexDirection: 'row'
  }
})

class ManagementPageFilter extends Component {
  // constructor (props) {
  //   super (props)

  //   this.state = {
  //     focusedInput: null,
  //     startDate: null,
  //     endDate: null,
  //   }
  // }

  // subparksHandleChange = event => {
  //   this.props.changeFilterSubparks(event.target.value)
  // }

  // handleChangeDates ({ startDate, endDate }) {
  //   if (startDate && endDate) {
  //     this.props.changeFilterTimeRange({ startDate, endDate })
  //   }
  // }

  render () {
    const {classes, management} = this.props

    const FILTER_PADDING = 30

    return (
      <div style={{
        width: `calc(100% - ${(FILTER_PADDING * 2)})`,
        minHeight: '64px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: FILTER_PADDING,
        marginRight: FILTER_PADDING
      }}>
        <div>
          {management.name}
        </div>
        <div style={{ flex: 1 }}></div>
        <div className={classes.filterContent}>
          {/* <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox">
              Subparks
            </InputLabel>
            <Select
              multiple
              value={this.props.subparksSelect}
              onChange={this.subparksHandleChange.bind(this)}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={selected => selected.map(elm => elm.name).join(', ')}
              MenuProps={MenuProps}
            >
              {this.props.subparks.map(
                (item, index) => {

                  const filterSelected = this.props.subparksSelect.filter(elm => elm.id === item.id)

                  // 

                  const checked = filterSelected.length > 0

                  return (
                    <MenuItem key={index} value={item}>
                      <Checkbox checked={checked} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  )
                }
              )}
            </Select>
          </FormControl> */}

          {/* <DateRangePicker
            startDatePlaceholderText="Start date"
            endDatePlaceholderText="End date"
            // small={true}
            numberOfMonths={2}
            displayFormat="DD-MM-YYYY"
            isOutsideRange={date => date.isAfter(maxDate)}
            startDate={this.props.timeRange.startDate}
            startDateId="1"
            endDate={this.props.timeRange.endDate}
            endDateId="2"
            onDatesChange={this.handleChangeDates.bind(this)}
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => this.setState({ focusedInput })}
          /> */}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ManagementPageFilter)