import React, {
    useState
} from 'react';

import { PaginatorTable } from '../../../../../../../new-components/Table';

import BigIconMessage from '../../BigIconMessage';
import { ExpirationAlert } from '../../Alert';

import TimeIntervalSlider from '../../../../EventsPage/components/TimeIntervalSlider';
import SliderTooltipLabel from '../../../../EventsPage/components/SliderTooltipLabel';
import '../../../../EventsPage/components/styles/ExtraStyles.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const TimeToDateSliderLabel = SliderTooltipLabel(val => new Date(val).toISOString().split('.')[0].replace('T', ' '));

const convertRowToRecord = (row, headMap) => {
    let record = { ...row };

    Object.keys(row).map(key => {
        record[headMap[key]] = row[key]
    });

    return record;
};


const View = props => {
    const {
        headMap,
        Rows,
        RowsAggregated,
        headMapAgreggated,
        alertObjects,
        openAcquisitionDialog,
        setDetailInfo,
        setActiveResource,
        modulePage,
        setOpened,
        getComponents,
        setAggregate,
        aggregate,
        getMovement,
        aggregatedDisplayHeader
    } = props;

    const { id: pageId } = modulePage;

    const [filter, setFilter] = useState(null);
    const [canClick, setCanClick] = useState(true);
    const { t } = useTranslation();

    const records = Rows.map(row => convertRowToRecord(row, headMap));
    const recordsAggregated = RowsAggregated.map(row => convertRowToRecord(row, headMapAgreggated));

    const drilUp = (filter) => {
        if (filter === 'Component Model') {
            setFilter(null);
            setAggregate(true);
        }
    }


    return (
        <div style={{ margin: '16px' }}>
            {
                RowsAggregated.length > 0 ?
                    <div style={{ maxWidth: '96vw' }} >
                        <ExpirationAlert>
                            {alertObjects}
                        </ExpirationAlert>

                        <br />

                        {aggregate ?
                            <PaginatorTable
                                records={recordsAggregated}
                                rowClickAction={(index, row) => () => {
                                    const { component_model_id, storage_id } = row;
                                    getComponents(component_model_id, storage_id)
                                        .then(() => {
                                            setFilter(row['Component Model']);
                                            setAggregate(false)
                                        })
                                        .catch(() => {
                                            toast.error(t('error_request_specific_component'), {
                                                position: 'top-right',
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true
                                            });
                                        });
                                }}
                                resizeFactor={1}
                                rowsPerPageOptions={[
                                    10,
                                    20,
                                    30,
                                    50,
                                    100,
                                    150,
                                    200
                                ]}
                                displayHeaders={aggregatedDisplayHeader || [
                                    'Part Number',
                                    'Component Model',
                                    'Current Location',
                                    'Stock Quantity'
                                ]}
                                cellMinWidth={'8.5vw'}
                                cellMaxWidth={'13vw'}
                                drillsDown={true}
                            />
                            :
                            <PaginatorTable
                                records={records}
                                onFilterChange={drilUp}
                                initialFilterList={records && records[0] && records[0]['Component Model'] ? { 'Component Model': [records[0]['Component Model']] } : {}}
                                fieldFormatter={(index, head, row) => {
                                    if (row[head] == null) {
                                        return '--';
                                    }

                                    switch (head) {
                                        case 'acquisitionDate':
                                        case 'Acquisition Date':
                                        case 'Expiration Date':
                                        case 'Application Date':
                                        case 'receivedDate':
                                        case 'fabricationDate':
                                        case 'lastMovementDate':
                                        case 'applicationExpireDate':
                                        case 'applicationWarrantyEnd':
                                        case 'expireDate':
                                        case 'warrantyEnd':
                                            return row[head].split('T')[0];
                                        default:
                                            return row[head];
                                    }
                                }}
                                rowClickAction={(index, row) => () => {
                                    if (canClick) {

                                        if (pageId === 6006) {
                                            const filteredRow = filter ? Rows.filter(r => r.component === filter) : Rows;
                                            row['id'] = filteredRow[index].id;
                                            setActiveResource(row);
                                            openAcquisitionDialog(true);
                                        } else {
                                            const filteredRow = filter ? Rows.filter(r => r.component === filter) : Rows;

                                            getMovement(filteredRow[index].id)
                                                .then(movementHistories => {
                                                    try {
                                                        setActiveResource(row);
                                                        setDetailInfo({
                                                            ...filteredRow[index],
                                                            movements: movementHistories[filteredRow[index].id]
                                                        });
                                                        setCanClick(false);
                                                        setOpened(true);
                                                        setCanClick(true);
                                                    } catch{
                                                        toast.error(t('error_request_specific_component_log'), {
                                                            position: 'top-right',
                                                            hideProgressBar: true,
                                                            closeOnClick: true,
                                                            pauseOnHover: true
                                                        });
                                                    }
                                                })
                                                .catch(() => {
                                                    toast.error(t('error_request_specific_component_log'), {
                                                        position: 'top-right',
                                                        hideProgressBar: true,
                                                        closeOnClick: true,
                                                        pauseOnHover: true
                                                    });
                                                });
                                        }
                                    }
                                }}
                                resizeFactor={1}
                                rowsPerPageOptions={[
                                    10,
                                    20,
                                    30,
                                    50,
                                    100,
                                    150,
                                    200
                                ]}
                                displayHeaders={[
                                    'Part Number',
                                    'Component Model',
                                    'Serial Number',
                                    'Placement',
                                    'Current Location',
                                    'Acquisition Date',
                                    'Expiration Date',
                                    'Manufacturer',
                                    'Supplier',
                                    'Application Date'
                                ]}
                                cellMinWidth={'8.5vw'}
                                cellMaxWidth={'13vw'}
                                filterLogic={head => (val, filters) => {
                                    if (filters.length === 0 || !val) {
                                        return false;
                                    }

                                    switch (head) {
                                        case 'acquisitionDate':
                                        case 'Acquisition Date':
                                        case 'Application Date':
                                        case 'lastMovementDate':
                                        case 'applicationExpireDate':
                                        case 'Expiration Date':
                                            const tVal = new Date(val).getTime();
                                            const tLower = filters.length > 0 ? filters[0] : 0;
                                            const tUpper = filters.length > 1 ? filters[1] : 999999999999999999999999999999999999;
                                            return !(tVal >= tLower && tVal <= tUpper);

                                        case 'documents':
                                            return !(filters[0][0] == val[0])
                                        default:
                                            return !(filters.indexOf(val) >= 0);
                                    }
                                }}
                                useCustomDisplay={[
                                    'Expiration Date',
                                    'Acquisition Date',
                                    'Application Date'
                                ]}
                                filterDisplay={head => (filterList, onChange, index, column) => {
                                    switch (head) {
                                        case 'Expiration Date':
                                        case 'Acquisition Date':
                                        case 'Application Date':
                                            const tValues = records.filter(row => (
                                                row[head] != null
                                            )).map(row => new Date(row[head]).getTime());

                                            const tDateMax = Math.max(...tValues);
                                            const tDateMin = Math.min(...tValues);

                                            return (
                                                <TimeIntervalSlider
                                                    title={`${t(head)} (${t('in range')})`}
                                                    submit={value => onChange(value, index, column)}
                                                    LabelComponent={TimeToDateSliderLabel}
                                                    defaultValue={column.filterList || [tDateMin, tDateMax]}
                                                    sliderProps={{
                                                        max: tDateMax,
                                                        min: tDateMin,
                                                        step: 0.01
                                                    }}
                                                />
                                            );
                                        default:
                                            return undefined;
                                    }
                                }}
                                chipFormatter={{
                                    ['Expiration Date']: val => {
                                        if (val.length > 0) {
                                            return `${t('Expires between')} ${new Date(val[0]).toISOString().split('T')[0] || '--'} ${t('and')} ${new Date(val[1]).toISOString().split('T')[0] || '--'}`;
                                        } else {
                                            return undefined;
                                        }
                                    },
                                    ['Acquisition Date']: val => {
                                        if (val.length > 0) {
                                            return `${t('Acquired between')} ${new Date(val[0]).toISOString().split('T')[0] || '--'} ${t('and')} ${new Date(val[1]).toISOString().split('T')[0] || '--'}`;
                                        } else {
                                            return undefined;
                                        }
                                    },
                                    ['Application Date']: val => {
                                        if (val.length > 0) {
                                            return `${t('Application expires between')} ${new Date(val[0]).toISOString().split('T')[0] || '--'} ${t('and')} ${new Date(val[1]).toISOString().split('T')[0] || '--'}`;
                                        } else {
                                            return undefined;
                                        }
                                    }
                                }}
                            />}

                    </div>
                    :
                    <BigIconMessage
                        iconName='report_off'
                        title='Your inventory is empty'
                        message={`${t("You have made no acquisitions")}. ${t("To make them you should use the action buttom bellow")}`}
                    />
            }
        </div>
    );
};

export default View;