import styled, { keyframes } from 'styled-components'
import { STYLE_HEADER_TOOLBAR } from '../../constants/style-constant';

const HeaderContent = styled.div`
  min-height: ${STYLE_HEADER_TOOLBAR}px;
`;


const loading = keyframes`
  0% {
    background-color: #f3f3f3
  }

  50% {
    background-color: #d9d9d9
  }

  100% {
    background-color: #f3f3f3
  }
`

const reverse_loading = keyframes`
  0% {
    background-color: #d9d9d9
  }

  50% {
    background-color: #f3f3f3
  }

  100% {
    background-color: #d9d9d9
  }
`

const AppBarLoading = styled.div`
  animation: ${loading} 3s ease;
  animation-iteration-count: infinite;
  background-color: #f3f3f3;
  z-index: 999
`

export { HeaderContent, AppBarLoading, reverse_loading, loading }
