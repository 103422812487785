import React, {Component} from 'react'
import moment from 'moment-timezone'
import axios from 'axios'
import {Auth} from 'aws-amplify'
import {withStyles, CircularProgress} from '@material-ui/core'
import HydroHeaderComponent from '../../components/HydroHeader'
import {
  Grid,
  Button,
  IconButton,
  Icon
} from '@material-ui/core'

import { keyframes } from 'styled-components'
import io from 'socket.io-client'
import { URL_WEBSOCKET_API } from '../../../../../../constants/url-constant';

import ChangeableTable from './components/ChangeableTable'
import VariablesSelector from './components/VariablesSelector'
import FlipContainer from '../../../../../../components/Container/FlipContainer'
import { getsUserPreferences, saveUserPreferences } from '../../../../../../providers/dynamo-client-user-prefererence-provider';

const GRID_WIDTH_PADDING = 10

const PROCEDURE_NAMES = [
  ['real_time_analog', [1, 2]],
  ['real_time_single', [1, 2]],
]


class HydroMonitorComponent extends React.Component {
  constructor (props) {
    super (props)
    const elBody = window.document.getElementsByTagName('BODY')[0]
    this.clickPark = this.clickPark.bind(this);
    this.selectVar = this.selectVar.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.updateTableData = this.updateTableData.bind(this);


    this.state = {
      gridWidth: elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),
      loading:false,
      plantsInfo: null,
      firstLoading:true,
      subpark:1,
      selecteds_vars:[],
      table_colors:[],
      tables: [
        {"name":"Variáveis 1", "header":["IOA", "Grupo", "Nome da Variável", "Valor"], "rows": [], "filtered_rows": [],  "loading":true , "colors":[], "side":false, "filter":false, "selecteds_vars":[]},
        {"name":"Variáveis 2", "header":["IOA", "Grupo", "Nome da Variável", "Valor"], "rows": [], "filtered_rows": [], "loading":true, "colors":[], "side":false, "filter":false, "selecteds_vars":[] },
        {"name":"Variáveis 3", "header":["IOA", "Grupo", "Nome da Variável", "Valor"], "rows": [], "filtered_rows": [], "loading":true, "colors":[], "side":false, "filter":false, "selecteds_vars":[] },
        {"name":"Alarmes", "header":["IOA", "Alarme", "Inicio"], "rows": [], "loading":true, "filtered_rows": [], "colors":[], "side":false, "filter":false, "selecteds_vars":[] }
      ],
      table:{},
      history:[],
      socket: null,
    }

   
    this.table_base_props = {
      FrontButtonsTop: '1%',
      FrontButtonsRight: '5%',
      BackButtonsTop: '1%',
      BackButtonsRight: '5%'
    }

    this.currentDateRange = null
    this.prevHydroMonitorId = null
    this.gridWidth = elBody.offsetWidth - 20
  }
 
  updateTableByIndex(index, response, timestamp){
    let tables = this.state.tables; 
    let table = tables[index]
    let history = this.state.history;
    let update = false;
    table.colors = [];
    let rows = table.filter ? table.filtered_rows : table.rows;

    if (response.data) {
      response.data.map((value) => {
        if (!table.filter || table.selecteds_vars.includes(value.device_id) ) {
          if (rows[value.device_id]) {
              if (history[value.device_id].timestamp < timestamp ) {
                  if (rows[value.device_id][1] != value.data.power.toFixed(2)) {
                    rows[value.device_id] = [value.device_id, value.var_group ? value.var_group : '-' , value.device_name, value.data.power.toFixed(2)]
                    history[value.device_id] = { "value": value.data.power, "timestamp" : timestamp }
                    table.colors.push(value.device_id);
                    update = true
                  }
              }
          } else {
            rows[value.device_id] = [value.device_id, value.var_group ? value.var_group : '-'  , value.device_name, value.data.power.toFixed(2)]
            history[value.device_id] = { "value": value.data.power, "timestamp" : timestamp }
            update = true
          }
        }
      })
    } else if (response.rows) {
      update = true;
      response.rows.map((value) => {
        if (!table.filter || table.selecteds_vars.includes(value.device_id) ) {
          rows.push([value.ioa, value.values[0], moment(value.values[1]).format('DD-MM-YYYY HH:mm:ss')])
          table.colors.push(value.ioa);
        }
      })
    }

    if (update && !table.side) {
      table.loading = false;
      tables[index] = table;


      // const prefs = JSON.parse(localStorage.getItem('hydroPreferences'));
      // prefs.modules[0].submodules = prefs.modules[0].submodules.map(submodule => submodule.id === 54 ? {...submodule, selectedVars: submodule.selectedVars.map((vars, varsIt) => varsIt === index ? tables[index].selecteds_vars : vars)} : submodule)
      // localStorage.setItem('hydroPreferences', JSON.stringify(prefs))

      this.setState({
        tables: tables,
      })
    }
  }

  updateTableByEvent(procedure_name, id, data, timestamp){
    if (id == this.state.subpark) {
      procedure_name === "real_time_analog" && this.updateTableByIndex(0, data, timestamp)
      procedure_name === "real_time_analog" && this.updateTableByIndex(1, data, timestamp)
      procedure_name === "real_time_analog" && this.updateTableByIndex(2, data, timestamp)
      procedure_name === "real_time_single" && this.updateTableByIndex(3, data, timestamp)
    }

  } 
  
  groupByPlant(collection, property, date = false) {
    var i = 0, val, index, values = [], result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      if (date) {
        val = new Date(val).toISOString().replace('T', ' ').split('.')[0].slice(0,16); 
      }
        index = values.indexOf(val);
        collection[i] =  Object.values(collection[i]).slice(0,2)
        
        if (index > -1)
            result[val].push(collection[i]);
        else {
            values.push(val);
            result[val] = [];
            result[val].push(collection[i]);
        }
    }
    return result;
  }

  clickPark(park_id) {
    this.state.tables.map(table => {
        table.loading = true;
        table.side = false;
        table.rows = [];
        table.filtered_rows = [];
        table.selecteds_vars = [];
    })

    this.setState({
      subpark: park_id,
    })
  }
 
  componentDidMount () {

    getsUserPreferences()
      .then(dynamoData => {
        const data = dynamoData.Items[0]
        if (data) {
          this.setState({tables:data.data})

        }

      })
      .catch(error => console.error('componentWillMount getDynamoClientMeta', error))




    Auth.currentSession()
      .then((data) => {
        const {jwtToken} = data.accessToken
        
        const url = `${URL_WEBSOCKET_API}/monitor`

        const socket = io(url, {
          query: {
            token: jwtToken
          }
        })

        socket.io.reconnect();


        this.setState({socket})

        socket.on('connect', (data) => {
          console.log('connect', data)
        })

        PROCEDURE_NAMES.forEach(([procedure_name, ids]) => {
          ids.forEach((id) => {
            socket.on(`${procedure_name}#${id}`, (data) => {

                // console.log('UPDATING TABLE COMPONENT DID MOUNT', data);
                // console.log(`${procedure_name}#${id}`);

                this.updateTableByEvent(procedure_name, id, data, moment());

              // this.setState({
              //   [`${procedure_name}#${id}`]: data,
              //   [`${procedure_name}#${id}_update`]: moment()
              // })
            })
          })
        })
        

        socket.on('connect_error', data => console.log('connect_error', data))
        socket.on('connect_timeout', data => console.log('connect_timeout', data))
        socket.on('connecting', data => console.log('connecting', data))
        socket.on('disconnect', data => console.log('disconnect', data))
        socket.on('error', data => console.log('error', data))
        socket.on('reconnect', data => console.log('reconnect', data))
        socket.on('reconnect_attempt', data => console.log('reconnect_attempt', data))
        socket.on('reconnect_failed', data => console.log('reconnect_failed', data))
        socket.on('reconnect_error', data => console.log('reconnect_error', data))
        socket.on('reconnecting', data => console.log('reconnecting', data))
        socket.on('ping', data => console.log('ping', data))
        socket.on('pong', data => console.log('pong', data))


        return axios.get(`${process.env.REACT_APP_API_1}/v1/api/pch/get-data/variables`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        }).then(({data}) => {

            let aux_table = {}
          
            data.map((rows, gropupIndex) => {
              rows.group.length > 1 && (rows.group.map((group, gropupIndex) => {
                group.system.map((system, systemIndex) => {
                  system.variables.map((variable, variableIndex) => {
                    aux_table[variable.id] = {'group': group.tag, 'system': system.tag, 'variable': variable.name, 'variable_id': variable.id}
                  })
                }) 
              }))

            }) 

          
            this.setState({plantsInfo: aux_table})

        })
    });
  }

  toggleVariablesView(table_id, filter = false, reset) {
    let tables = this.state.tables;
    let table = tables[table_id]
    table.filter = filter
    table.side = !table.side

    if (table.filter) {
      this.setState({firstLoading: false})
       table.filtered_rows = [];
       if (table.tableName) {

          table.name = table.tableName

       }


       tables.map(tbl => {

         tbl.rows.map(row => {

            if(table.selecteds_vars.includes(row[0])){

                table.filtered_rows[row[0]] = row
            } 

        })
      })
    } 


    if (reset) {

      table.filtered_rows = []
      table.selecteds_vars = []

    }

    this.setState({tables: tables})

    saveUserPreferences(this.state.tables)
      .then(dynamoData => {

    
      })
      .catch(error => console.error('componentWillMount getDynamoClientMeta', error))



   
  }


  updateTableData(index, param, value) {
    
    let tables = this.state.tables;
    let table = tables[index]
    table[param] = value;

    this.setState({tables: tables})

  }


  selectAll(variables, index) {
    
    let tables = this.state.tables;
    let table = tables[index]

    table.selecteds_vars = table.selecteds_vars.length > 0 ? [] : variables.map(variable => variable[0])
    this.setState({tables: tables})

  }

  selectVar(name, table_id) {
    let tables = this.state.tables;
    let table = tables[table_id]

    const index = table.selecteds_vars.indexOf(name)

    if(index == -1) {
      let selecteds_vars = table.selecteds_vars;
      selecteds_vars.push(name)
      table.selecteds_vars = selecteds_vars;
      this.setState({tables: tables})

    } else {
      let to_change = table.selecteds_vars
      to_change.splice(index, 1)
      
      table.selecteds_vars = to_change;
      this.setState({tables: tables})
    }
  }




   componentWillUnmount(){


    saveUserPreferences(this.state.tables)
      .then(dynamoData => {

    
      })
      .catch(error => console.error('componentWillMount getDynamoClientMeta', error))


    if(this.state.socket) {
      this.state.socket.disconnect()
      this.setState({socket: null})
    }
  }


  getFrontIcons(table_num) {
    const {classes} = this.props
    return (
      [
        <IconButton 
          aria-label="Select Variables" 
          className={classes.margin}
          onClick={this.toggleVariablesView.bind(this, table_num, null, false)}
          style={{width: 32, height: 32, color: 'white'}}
        >
          <Icon style={{fontSize: 16}}>settings</Icon>
        </IconButton>
      ]
    )
  }

  getBackIcons(table_num) {
    const {classes} = this.props
    return (
      [
        <IconButton 
          aria-label="Accept and Back to Table" 
          className={classes.margin}
          onClick={this.toggleVariablesView.bind(this, table_num, true, false)}
          style={{width: 32, height: 32, color: 'black'}}
        >
          <Icon style={{fontSize: 16}}>done</Icon>
        </IconButton>,

        <IconButton 
          aria-label="Reject and Back to Table" 
          className={classes.margin}
          onClick={this.toggleVariablesView.bind(this, table_num, false, true)}
          style={{width: 32, height: 32, color: 'black'}}
        >
          <Icon style={{fontSize: 16}}>clear</Icon>
        </IconButton>
      ]
    )
  }






  render () {
    const {classes} = this.props

    return (
      <React.Fragment>
        <div  style={{
          backgroundColor: '#333333',
          minHeight: '800px'
        }}>
           <HydroHeaderComponent subpark={this.state.subpark} clickPark={this.clickPark}/>

           <Grid container spacing={16} style={{margin: 8, marginLeft: 16}} >
            {
              this.state.tables.map((table, indexTable) => {

                return(
                    <Grid key={indexTable} item xs={indexTable == 3 ? 3 : 3}
                      className={classes.table} direction="column" align="center"
                      style={{padding: 0, margin: 0}}
                    >
                        {
                          //!this.state.error_table1 ?
                          !table.loading ?
                            
                            <FlipContainer
                              {...this.table_base_props}
                              FrontButtons={this.getFrontIcons.bind(this)(indexTable)}
                              BackButtons={this.getBackIcons.bind(this)(indexTable)}
                              Front={
                                <ChangeableTable 
                                  table_num={indexTable}
                                  table_colored_rows={this.state.table_colors} 
                                  table_data={this.state.tables[indexTable]} 
                                  table_name={`${this.state.subpark == 1 ? 'PCH Pipoca' : 'PCH Serra das Agulhas'} - ${table.name}`} 
                                  fontsize_header={14}
                                  fontsize_rows={12}
                                />
                              }
                              Back={
                                <VariablesSelector
                                  table_num={indexTable}
                                  plantsInfo={indexTable == 3 ? this.state.tables[indexTable] : this.state.firstLoading ? this.state.tables[0].rows :  this.state.tables[0].rows.concat(this.state.tables[1].rows)} 
                                  plant_id={this.state.subpark}
                                  table={this.state.tables[indexTable]}
                                  excludeVariables={[]}
                                  execute_filter={false}
                                  selectVar={this.selectVar}
                                  selectAll={this.selectAll}
                                  updateTableData={this.updateTableData}
                                  selecteds_vars={this.state.tables[indexTable].selecteds_vars}
                                />
                              }
                              change_side={table.side}
                            />
                              

                          :
                            //<p>Sorry, this table is broken</p>
                          
                            <div style={{
                              backgroundColor: '#333333',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center'
                            }}>
                              <CircularProgress size={(30)} />
                              <div style={{
                                marginTop: '20px',
                                color: 'white'
                              }}>
                                Atualizando Tabela ...
                              </div>
                            </div>
                        }
                    </Grid>
                  )
            }
            )}
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles()(HydroMonitorComponent)