import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import * as R from 'ramda'
import uuidv4 from 'uuid/v4'

import ReportsNewSelectedPageComponent from './components/ReportsNewSelectedPageComponent/ReportsNewSelectedPageComponent';
import templateModel from '../../../../../../../../data/Reports/report-template.json'
import pageModel from '../../../../../../../../data/Reports/report-page.json'
import ReportsSidebarPagesComponent from './components/ReportsSidebarPagesComponent/ReportsSidebarPagesComponent';


const styles = () => ({
  pageContent: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  pageSidebarContent: {
    width: '200px',
    height: '100%'
  },
  pageSelectedContent: {
    flex: 1,
    borderLeft: `1px solid ${grey['200']}`
  }
})

class ReportsNewComponent extends Component {
  constructor (props) {
    super (props)

    this.state = R.assoc('id', uuidv4())(templateModel)
  }

  componentDidMount () {


    if (this.props.location.state.data) {


      this.setState({
        pages: this.props.location.state.data.pages.map(page => {
           page.widgets.map(widget => {
              widget.widgetType =  widget.widget;
              widget.grid =  widget.position;
              return widget;
        })

          return page;
        }).filter(page => page).map((page, indexPage) => {
                if (indexPage === 0) {
                  return R.assoc('selected', true, page)
                }

                return page
            })
      ,
      edit : this.props.location.state.data ? this.props.location.state.data : false
      })

    }else{

      this.setState({
        pages: [
          R.assoc('id', uuidv4())(
            R.assoc('selected', true)(pageModel)
          )
        ], edit:false
      })

    }

  }

  goSelectPage (id) {
    this.setState(state => ({
      pages: state.pages.map(page => {
        if (page.id === id) {
          return R.assoc('selected', true)(page)
        }

        return R.assoc('selected', false)(page)
      })
    }))
  }

  gridLayoutChange = (positions) => {
    this.setState(state => {
      return {
        pages: state.pages.map(page => {
          if (!page.selected)
            return page

          const widgets = page.widgets.map(widget => {
            const grid = R.find(R.propEq('i', `${widget.id}`), positions)
            return R.assoc('grid', grid)(widget)
          })

          return R.assoc('widgets', widgets)(page)
        })
      }
    })
  }


    removeWidget = (id) => {


     this.setState(state => {
          return {
            pages: state.pages.map(page => {
              const pageSelected = R.find(R.propEq('selected', true), state.pages)


              if (page.id === pageSelected.id) {

                  const widgets = pageSelected.widgets.map(widget => {

                  
                    if (widget.id === id) {
                        return false;
                    }

                    return widget
                  }).filter(widget => widget)



                  return R.assoc('widgets', widgets, page)


              }

              return page
            })
          }
        })


    }

    editWidget = (widgetEdit) => {
      
        this.setState(state => {
          return {
            pages: state.pages.map(page => {
              const pageSelected = R.find(R.propEq('selected', true), state.pages)


              if (page.id === pageSelected.id) {

                  const widgets = pageSelected.widgets.map(widget => {

                  
                    if (widget.id === widgetEdit.id) {
                  


                      return {
                        ...widgetEdit,
                        update: true,
                        data: false
                      }
                    }

                    return widget
                  })


                  return R.assoc('widgets', widgets, page)


              }

              return page
            })
          }
        })
    }

  addWidget = (widget) => {
    this.setState(state => {
      return {
        pages: state.pages.map(page => {
          const pageSelected = R.find(R.propEq('selected', true), state.pages)

          if (page.id === pageSelected.id) {
            return {
              ...page,
              widgets: R.append(widget, pageSelected.widgets)
            }
          }

          return page
        })
      }
    })
  }

  verifyRenderSelectedPage = pages => {
    const page = R.find(R.propEq('selected', true), pages)

    if (!page)
      return ''

    return (
      <ReportsNewSelectedPageComponent
        edit={this.state.edit}
        pages={pages}
        page={page}
        history={this.props.history}
        gridLayoutChange={this.gridLayoutChange.bind(this)}
        addWidget={this.addWidget.bind(this)}
        editWidget={this.editWidget.bind(this)}
        removeWidget={this.removeWidget.bind(this)}

      />
    )
  }

  verifyRenderSidebarComponent = pages => {
    return pages.length > 0
      ? (
        <ReportsSidebarPagesComponent
          pages={pages}
          goSelectPage={this.goSelectPage.bind(this)}
          newPage={this.newPage.bind(this)}
          deletePage={this.deletePage.bind(this)}

        />
      )
      : ''
  }


  

  deletePage = () => {
    
    if (window.confirm("Delete Page?")) {

        this.setState(state => {
          return {
            pages: state.pages.map(page => {
              const pageSelected = R.find(R.propEq('selected', true), state.pages)

              if (page.id === pageSelected.id) {
                return false;
              }

              return page
            }).filter(page => page).map((page, indexPage) => {
                if (indexPage === 0) {
                  return R.assoc('selected', true, page)
                }

                return page
            })
          }
        })


    }
 
    return false
 
 }


  newPage = () => {
    this.setState(state => {
      const pages = state.pages
        .map(page => R.assoc('selected', false)(page))
      
      return {
        pages: R.append(
          R.assoc('id', uuidv4()
        )(
          R.assoc('selected', true)(pageModel)
        ), pages)
      }
    })
  }

  render () {
    const {classes} = this.props
    const {pages} = this.state

    return (
      <div className={classes.pageContent} >
        <div className={classes.pageSidebarContent} >
          {this.verifyRenderSidebarComponent(pages)}
        </div>
        <div className={classes.pageSelectedContent} >
          {this.verifyRenderSelectedPage(pages)}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ReportsNewComponent)
