// *****************    PLEASE USE NEW API     *****************************

import {getDataGraphOldProvider} from '../providers/get-data-old-provider'

export const getDataOld = ({type, name}, params) => {
  return dispatch => {
    dispatch({ type, payload: { result: [] } })

    getDataGraphOldProvider(name, params)
      .then((data) => {
        dispatch({ type, payload: data })
      })
      .catch(error => {
        console.log('Error getting data from old api', error)
        dispatch({ type: 'ASASasdasd23e2wd', payload: {} })
      })
  }
}
