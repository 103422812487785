import { keyframes } from 'styled-components';

const appearGradient = (color1, color2) => keyframes`
    to {
        background-image: linear-gradient(90deg, ${color1} 100%, ${color2} 0);
    }
`;

const styles = theme => ({
    root: {
        width: '100%'
    },
    table: {
        // minWidth: '50%',
        tableLayout: 'auto !important'
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    cellWrapper: {
        maxWidth: '45vw',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        verticalAlign: 'baseline',
        // maxHeight: '2vh !important',
        minHeight: '2vh !important',
        // height: '2vh !important'
    },
    iconHeader: {
        padding: '1vh 1vw 1vh 1vw'
    },
    iconWrapper: {
        fontSize: '1.8em',
        verticalAlign: 'middle',
        color: '#bdbdbd'
        // padding: '0 0 0 1vw !important'
    },
    footerWrapper: {
        textAlign: 'right',
        width: '100%'
    },
    footerContent: {
        width: '100%'
    },
    lesserSpacer: {
        flex: '0 0 auto',
        alignSelf: 'center !important',
        marginLeft: '1.5vw',
    },
    actionsChip: {
        width: '100%',
        height: '3vh',
        padding: '0 1vw 0 0'
        // fontSize: '0.5em',
    },
    actionsAvatar: {
        width: '18%',
        height: 'inherit'
    },
    spacer: {
        flex: '2 2 100%',
        alignSelf: 'center !important'
    },
    title: {
        flex: '0 0 auto',
        alignSelf: 'center !important'
    },
    titleBlack: {
        color: 'black !important'
    },
    titleDarkGrey: {
        color: '#7f7f7f'
    },
    titleWhite: {
        color: 'white',
        borderColor: 'white'
    },
    labelDarkGrey: {
        color: '#666 !important'
    },
    individualActions: {
        backgroundColor: '#98ff82',

        // animation: `${appearGradient('#98ff82', '#69c456')} 1.5s ease`
        // 'animation-iteration-count': 'infinite',
        
        // background: 'linear-gradient(to right, red 34%, blue 65%)',
        // 'background-size': '300% 100%',
        // transition: 'all 1s ease'
    },
    groupActions: {
        backgroundColor: '#3a58ff',
        color: 'white',

        // animation: `${appearGradient('#3a58ff', '#1f34a8')} 1.5s ease`
    },
    bgAction: {
        backgroundColor: '#bdbdbd',
        color: 'white !important',
        width: '100%'
    },
    bgActionAvatar: {
        backgroundColor: 'white !important',
        color: '#666 !important'
    },
    rowSelected: {
        backgroundColor: '#ffd4b7',

        '&:hover': {
            backgroundColor: '#ffe7d8'
        }
    },
    actionFab: {
        color: 'white',
        backgroundColor: 'transparent',
        margin: theme.spacing.unit,
        width: '42px !important',
        height: '42px !important',
        boxShadow: 'none !important',
    },
    smallWhiteText: {
        color: 'white !important',
        fontSize: '1.5em',
        verticalAlign: 'middle'
    },
    right: {
        textAlign: 'right',
        padding: 0,
        margin: 0
    },
    left: {
        textAlign: 'left',
        padding: 0,
        margin: 0
    },
    horizontalAlign: {
        display: 'inline-flex',
        width: '100%',
        textAlign: 'center',
        justifyContent: 'space-around'
    },
    verticalAlign: {
        display: 'inline-flex',
        width: '100%',
        textAlign: 'center',
        flexDirection: 'column'
    },
    disableOverflowX: {
        overflowX: 'hidden !important'
    },
    lesserPadding: {
        padding: '2vw 2vh 2vw 2vh'
    },
    filter: {
        textAlign: 'right !important',
        margin: '4vh 2vw 0 0 !important'
    }
});

export default styles;