import React from "react";
import { withStyles } from "@material-ui/core";
import * as R from "ramda";
import grey from "@material-ui/core/colors/grey";
import _ from "lodash";
import { averageGeolocationHelper } from "../../../../../../../../helpers/average-geolocation-helper";
import moment from 'moment-timezone'

import OPCPortfolioItem from "../OPCPortfolioItem/OPCPortfolioItem";
import PortfolioHeaderComponent from "../../../../../../../../components/Header/PortfolioHeaderComponent";
import PortfolioSubparkComponent from "../../../../../../../../components/Header/PortfolioSubparkComponent";
import ParksGrid from "../../../../../../../../components/Panel/ParksGrid";
import PortfolioContainer from "../../../../../../../../components/Container/PortfolioContainer";
import getStatusColorRealtime, { getStatusByTag } from '../../../../../../../../helpers/get-status-color-realtime';
import getAllSubparks from '../../../../adaptor';
import BasicLoading from "../../../../../../../../components/Loading/BasicLoading";

moment.locale('en');

const GRID_WIDTH_PADDING = 10;

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%"
  },
  tableTh: {
    color: grey["500"],
    textAlign: "center",
    padding: "8px"
  },
  tableTd: {
    textAlign: "center",
    padding: "8px",
    color: grey["400"]
  }
};

class OPCPortfolioList extends React.Component {
  constructor(props) {
    super(props);

    const elBody = window.document.getElementsByTagName("BODY")[0];

    this.state = {
      gridWidth: elBody.offsetWidth - GRID_WIDTH_PADDING * 2,
      selectedSubpark: null,
      dialogWtg: false,
      center: {
        latitude: 0,
        longitude: 0
      }
    };
  }


  closeDialogWtg = () => {
    this.setState({
      dialogWtg: false,
      selectedSubpark: null
    })
  }


  selectSubpark = (subpark) => {
    this.setState({
      selectedSubpark: subpark.id,
      dialogWtg: true,
      center: averageGeolocationHelper(
        subpark.wtgs
      )
    });

  };

  getSubparkByID = id => {
    const subparks = getAllSubparks(this.props.items);

    return subparks.find(subpark => subpark.id === id);
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName("body")[0];

    this.setState({
      gridWidth: body.offsetWidth - GRID_WIDTH_PADDING * 2
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizePageWatch);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizePageWatch);
  }


  render() {

    const { classes } = this.props;

    let timeAll = [];
    let tags = [];
    let powerAll = 0;
    let ratedPowerAll = 0;
    let WtgStoppedAll = 0;
    let WtgAll = 0;

    const parks = this.props.items.map((park, indexPark) => {

      park.subparks.map((subpark, indexSubpark) => {

        subpark.wtgs.map((wtg, indexWtg) => {

          const stateTag = R.find(R.propEq('var_id', 1000))(wtg.realtime) || R.find(R.propEq('var_id', 3))(wtg.realtime)
          const state = stateTag ? stateTag.value : ''

          const maintenanceTag = R.find(R.propEq('var_id', 4))(wtg.realtime)
          const maintenance = maintenanceTag ? maintenanceTag.value_bool : ''

          const powerLimitation = wtg.realtime.filter(R.propEq('is_alarm', true)).some(({ value_bool }) => value_bool)


          let status = {}

          if (stateTag && stateTag.value_string) {
            status = getStatusByTag(stateTag.value_string)
            if (status.color && (status.color == 'red' || status.color == 'blue' || status.color == 'purple' || status.color == 'grey')) {
              WtgStoppedAll++
            }
          } else {
            status = getStatusColorRealtime(state, wtg.model_id, maintenance, powerLimitation)
            if (status.color && status.color == 'red') {
              WtgStoppedAll++
            }
          }




          timeAll = timeAll.concat(wtg.realtime.filter(tag => tag.var_id === 1 || tag.var_id === 2 || tag.var_id === 3 || tag.var_id === 4 || tag.var_id === 1000))
          tags = tags.concat(wtg.realtime);
          ratedPowerAll += wtg.model.specs.rated_power;
          WtgAll++;


        })


      })

      return park;
    })

    const timeAllSort = timeAll.sort((a, b) => {
      return moment.utc(a.time).diff(moment.utc(b.time))
    })

    powerAll = R.sum(
      tags.filter(tag => tag.var_id === 1).map(tag => tag.value)
    );

    const windMedian = (R.sum(
      tags.filter(tag => tag.var_id === 2).map(tag => tag.value)
    ) / tags.filter(tag => tag.var_id === 2).length);

    let itemsGrid = {};

    itemsGrid = parks.map((park, indexPark) => {

      return (
        <div
          key={indexPark}
          data-grid={{
            x: 0,
            y: 0,
            w: 3,
            h: 4
          }}
        >
          <OPCPortfolioItem item={park} selectSubpark={this.selectSubpark.bind(this)} />
        </div>
      )

    })


    return (
      <div
        style={{
          minHeight: "720px",
          width: "100%",
          color: "white",
          overflow: "auto"
        }}
      >
        {
          this.props.items.length > 0 ?

            <PortfolioContainer>
              <PortfolioHeaderComponent classes={classes} items={this.props.items} timeAllSort={timeAllSort} windMedian={windMedian} ratedPowerAll={ratedPowerAll} WtgAll={WtgAll} WtgStoppedAll={WtgStoppedAll} powerAll={powerAll} />
              <ParksGrid itemsGrid={itemsGrid} classes={classes} />
              {
                this.state.selectedSubpark &&
                <PortfolioSubparkComponent
                  center={this.state.center}
                  closeDialogWtg={this.closeDialogWtg}
                  classes={classes}
                  dialogWtg={this.state.dialogWtg}
                  selectedSubpark={this.getSubparkByID(this.state.selectedSubpark)}
                  powerAll={powerAll}
                  ratedPowerAll={ratedPowerAll}
                  windMedian={windMedian}
                  timeAllSort={timeAllSort} />
              }

            </PortfolioContainer>
            :
            <BasicLoading />
        }

      </div>
    );
  }
}

export default withStyles(styles)(OPCPortfolioList);