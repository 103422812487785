import React from 'react';
import { Icon, Button } from '@material-ui/core';
import useContainer from './Container';
import CircularLoading from '../../../../../../../components/Loading/CircularLoading';
import BaseViewDialog from '../../../../../../../new-components/Dialog';
import View from '../../../../../../../new-components/Table/View';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';


const MultipleAcquisitionView = props => {

    const {
        finishMount,
        importDialogOpened,
        openImportDialog,
        setData,
        options,
        storages,
        saveInfo,
        records,
        setRecords,
        dataUploaded,
        setDataUploaded,
        stateOptions
    } = props;

    const { t } = useTranslation();

    const assetsManagemnt = props['assets-management'];
    const { importMask } = assetsManagemnt || {};

    const { processFile, confirmImport, fieldFormatterForPreview, handleClickPreviewRow = () => { } } = useContainer({
        tag: importMask,
        storages,
        stateOptions,
        options,
        records,
        saveInfo
    });

    const close = () => {
        setData(null);
        finishMount(false);
        saveInfo(false);
        setRecords([]);
        setDataUploaded(false);
        openImportDialog(false);
    };

    return (
        <BaseViewDialog
            title={t('Import - Preview')}
            confirm={t('Register')}
            disableTopClose
            disableConfirm={dataUploaded === false}
            disableDelete={true}
            opened={importDialogOpened}
            Open={openImportDialog}
            dialogProps={{
                style: {
                    zIndex: 99999
                }
            }}
            contentProps={{
                style: {
                    overflowX: 'hidden'
                }
            }}
            onCancel={() => {
                setData(null);
                finishMount(false);
                saveInfo(false);
                setRecords([]);
                setDataUploaded(false);
                openImportDialog(false);
            }}
            onConfirm={() => {

                let situation = 'error'
                let message = 'An unexpected error ocurred'

                confirmImport()
                    .then(res => {
                        message = res;
                        situation = 'success';
                    })
                    .catch(res => {
                        message = res;
                    })
                    .finally(() => {

                        toast[situation](message, {
                            position: 'top-right',
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true
                        });

                        close();
                    });
            }}
        >
            {
                dataUploaded === false ?
                    <>
                        <input
                            accept='.xlsx'
                            style={{ display: 'none' }}
                            id='upload-file'
                            multiple
                            type='file'
                            onChange={event => {
                                const file = event.target.files[0];

                                processFile(file)
                                    .then(data => {

                                        setRecords(data);
                                        setDataUploaded(true);
                                    })
                                    .catch(() => {
                                        const message = `${t('Failed to Process File')}. ${t('Please, check if there is something wrong with the file and try again')}`;

                                        toast.error(message, {
                                            position: 'top-right',
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: true
                                        });

                                        close();
                                    });

                                setDataUploaded(null);
                            }}
                        />
                        <label htmlFor='upload-file'>
                            <Button
                                variant="raised"
                                component='span'
                                style={{
                                    textTransform: 'none',
                                    height: 'inherit',
                                    width: '100%',
                                    height: 400,
                                    border: '4px dashed #dbdbdb'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around',
                                        height: '100%',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Icon
                                        style={{
                                            fontSize: '15em',
                                            color: '#dbdbdb'
                                        }}
                                    >
                                        attach_file
                                    </Icon>
                                    <h3 style={{ textAlign: 'center' }} >{t('Click Here to Import File')}</h3>
                                </div>
                            </Button>
                        </label>
                    </>
                    : dataUploaded === true ?

                        <View
                            records={records}
                            fieldFormatter={fieldFormatterForPreview}
                            rowClickAction={handleClickPreviewRow}
                        />
                        :
                        <div
                            style={{
                                minHeight: 400
                            }}
                        >
                            <CircularLoading>
                                <span>{t('Please wait for the file to finish processing')}</span>
                            </CircularLoading>
                        </div>
            }
        </BaseViewDialog >
    );
};

export default MultipleAcquisitionView;