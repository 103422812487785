import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'
import * as R from 'ramda'
import moment from 'moment-timezone'
import { Icon } from 'react-icons-kit'
import {download} from 'react-icons-kit/fa/download'
import {trash} from 'react-icons-kit/fa/trash'
import { getTemplateAllProvider, deleteTemplateProvider } from '../../../../../../../../providers/get-report-data-provider'
import {send} from 'react-icons-kit/fa/send'
import {pencil} from 'react-icons-kit/fa/pencil'
import { ROUTE_MAIN_REPORTS_MANAGEMENT_NEW } from '../../../../../../../../constants/route-constant';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { toast } from 'react-toastify'



const styles = () => ({
  tableItems: {
    borderCollapse: 'collapse',
    width: '100%',
    '& tr:hover': {
      backgroundColor: '#f5f5f5'
    },
    '& th, & td': {
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    }
  },
  actionButton: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
})

class ReportsListTemplateComponent extends Component {
  constructor (props) {
    super (props)

    this.state = {
      items: []
    }
  }

  deleteTemplate = item => () => {

      if (window.confirm("Delete Template?")) {

      deleteTemplateProvider(item)
          .then(() => {
              
           getTemplateAllProvider()
              .then(data => {
                
                this.setState({ items: data })
                
                toast.success('Template deleted successfully!', {
                    position: "top-right",
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                  })


              })
              .catch(error => {
                console.error('getTemplateAllProvider', error)
              })

          })
          .catch(error => {
            toast.error('Error deleting!', {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            })
          })

      }

      
  }


  goEditTemplate = item => () => {

    this.props.goPage(ROUTE_MAIN_REPORTS_MANAGEMENT_NEW, item)
  }





  componentDidMount () {
    getTemplateAllProvider()
      .then(data => {
        this.setState({ items: data })
      })
      .catch(error => {
        console.error('getTemplateAllProvider', error)
      })
  }

  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          overflow: 'auto'
        }}
      >
        <Table
          className={classes.tableItems}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                Title
              </TableCell>
              <TableCell>
                Created
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'center'
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.items.map(item => {
              return (
                <TableRow
                  key={item.id}
                  style={{

                  }}
                >
                  <TableCell>
                    {item.description}
                  </TableCell>
                  <TableCell>
                    {moment(item.created_at).format('LL - LT')}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    {
                      item.status === 'DONE'
                      ? (
                        <React.Fragment>
                          <Icon
                            icon={pencil}
                            onClick={this.goEditTemplate(item).bind(this)}
                            className={classes.actionButton}
                            style={{
                              marginLeft: 10
                            }}
                          />
                        
                          <Icon
                            icon={trash}
                            onClick={this.deleteTemplate(item).bind(this)}
                            className={classes.actionButton}
                            style={{
                              marginLeft: 10
                            }}
                          />
                        </React.Fragment>
                      )
                      : <React.Fragment>
                          
                          <Icon
                            icon={pencil}
                            onClick={this.goEditTemplate(item).bind(this)}
                            className={classes.actionButton}
                            style={{
                              marginLeft: 10
                            }}
                          />
                        
                          <Icon
                            icon={trash}
                            onClick={this.deleteTemplate(item).bind(this)}
                            className={classes.actionButton}
                            style={{
                              marginLeft: 10
                            }}
                          />

                        </React.Fragment>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default withStyles(styles)(ReportsListTemplateComponent)
