import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import {withStyles, Button, Icon} from '@material-ui/core'
import FilterThreeBodyComponent from './components/FilterThreeBodyComponent/FilterThreeBodyComponent'
import FromDeviceIconGrey from '../../assets/icons/from-device-grey.svg'

const FILTER_THREE_BODY_ID = '62CACDF8884688D937CF1E9C1CBBE'

const styles = {
  button: {
    '&:hover': {
        backgroundColor: 'white',
        color: '#FF3300'
    },
    backgroundColor:'white',
    borderRadius: '25px 0 0 25px',
    textTransform: 'capitalize',
    height: '100%',
    padding:8,
    color: 'black',
}
}

class FilterThreeComponent extends React.Component {

  
  constructor (props) {
    super (props)

    this.buttonMenuElRef = React.createRef()
  }

  // componentDidMount () {
  //   console.log('buttonMenuElRef', this.buttonMenuElRef)
  // }

  toggleMenu = () => {
    const {open} = this.props

    if (open) {
      this.props.closeMenu()
    } else {
      this.props.openMenu()
    }
  }

  setIdThree = (data, hashParent = null) => {
    return data.map((item, indexItem) => {
      const hash = hashParent ? `${hashParent}#${indexItem}` : `${indexItem}`

      return {
        ...R.assoc(
          'data',
          item.data.length > 0
            ? this.setIdThree(item.data, hash)
            : [],
          item
        ),
        hash
      }
    })
  }

  render () {

    const {classes, device, buttonStyle} = this.props


    const data = this.setIdThree(this.props.data)

    return (
      <div
        ref={this.buttonMenuElRef}
        style={{
          height: 'inherit',
          width: 'auto',
        }}
      >
        <Button
          disableRipple="true"
          className={classes.button} 
          onClick={this.toggleMenu.bind(this)}
        >
          {/* <Icon
            className={classes.icon}
            style={{ color: "#bdbdbd", marginRight: "5px" }}
            >
            toys
          </Icon> */}
          <img
              src={FromDeviceIconGrey}
              style={{
                  fill: '#dbdbdb',
                  transform: 'translateY(-2px)',
                  width: 28,
                  marginRight: 2,
                  '& path': {
                    fill: '#dbdbdb'
                  }
              }}
          />
            {this.props.label}
        </Button>

        {
          this.props.open
          ?
          (
            <FilterThreeBodyComponent
              device={device}
              parentElRef={this.buttonMenuElRef}
              FILTER_THREE_BODY_ID={FILTER_THREE_BODY_ID}
              closeMenu={this.props.closeMenu}
              dataHash={data}
              {...this.state}
              {...this.props}
            />
          )
          :
          null
        }
      </div>
    )
  }
}

FilterThreeComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  device: PropTypes.bool.isRequired,
  rootElRef: PropTypes.element.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

export default withStyles(styles)(FilterThreeComponent)
