import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDateFilter } from '../../../../../actions/DateFilterActions';
import moment from "moment-timezone";


export default props => {

    const fetchedPages = (props && props.calendar && props.calendar.pages) || [];

    const [pages, setPages] = useState(fetchedPages);
    const [datarange, setData_range] = useState({ startDate: '2019-03-01', endDate: '2019-03-02' });

    const dispatch = useDispatch();

    const dashboard = useSelector(({ Dashboard }) => Dashboard);
    const { DateFilter } = dashboard || {};
    const { data_range } = DateFilter || {};
    const { startDate, endDate } = data_range || {};

    const selectPage = id => {
        const newPages = pages.map(page => ({ ...page, selected: page.id === id }));
        setPages(newPages);
    };

    const getSelectedPage = () => {
        return pages.find(page => page.selected);
    };

    useEffect(() => {
        if (!startDate || !endDate) {
            dispatch(updateDateFilter(null, moment('2019-03-01'), moment('2019-03-02')));
        }
    }, []);


    return {
        mode: 'view',
        executeFilter: () => { setData_range({ startDate, endDate }); },
        module_field: 'calendarpages',
        page: getSelectedPage(),
        calendarpages: pages,
        goToPage: selectPage,
        data_range: datarange
    };
};