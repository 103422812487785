import React, {Component, Fragment} from 'react';
import {
    Icon,
    IconButton,
    Typography
} from '@material-ui/core';

import CircularLoading from '../Loading/CircularLoading';

class DefaultErrorHandler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null,
            loading: false
        }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        console.log('Default Error Handler Catched An Error!!', error, errorInfo);
    }

    refreshingPhase = () => {
        this.setState({
            loading: 'triggered'
        })
    }

    componentDidUpdate() {
        const { loading } = this.state;

        if(loading === 'triggered') {
            setTimeout(() => this.setState({
                error: null,
                errorInfo: null,
                loading: false
            }), 800);

            this.setState({
                loading: 'in-progress'
            })
        }
    }

    render() {
        const { error, loading } = this.state;
        if(error) {
            return (
                <div style={{
                    width: '100%', 
                    height: 'inherit',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    overflow: 'auto'
                }} >
                    {
                        !loading ? (
                            <Fragment>
                                <div style={{display: 'inline-flex'}}>
                                    {/* <IconButton onClick={this.refreshingPhase} >
                                        <Icon style={{color: '#a0c4ff'}}>refresh</Icon>
                                    </IconButton> */}
                                    <IconButton onClick={() => window.location.reload(true)} >
                                        <Icon style={{color: '#a0c4ff'}}>refresh</Icon>
                                    </IconButton>
                                </div>
                                <Typography variant='h5'>Sorry, an error ocurred!</Typography>
                                <p>Please click on button above to refresh entire page.</p>
                                <p style={{fontSize: '.7em'}} >If the error persists, you can send
                                an email to <a href='mailto:contato@delfosim.com?&subject=Error on Delfos IM Dashboard'>contato@delfosim.com</a> to report this problem.</p>
                            </Fragment>
                        )
                        :
                        (
                            <CircularLoading>
                                <p>Trying to recover from error.</p>
                            </CircularLoading>
                        )
                    }
                </div>
            );
        } else {
            return (this.props.children);
        }
    }
}

export default DefaultErrorHandler;