import React, {Component} from 'react';
import moment from 'moment-timezone';
import Paper from '@material-ui/core/Paper';
import { DateRangePicker } from 'react-date-range';

import { toast }  from 'react-toastify';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import './styles/DateSelectorStyles.css';
import styles from './styles/BaseSelectorStyles';

class DateSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            maxDate: moment().startOf('day'),
        };
    }

    handleSelect = ({date}) => {
        let startDate = moment(date.startDate).startOf('day');
        let endDate = moment(date.endDate).startOf('day');
        const { handleChangeDates } = this.props;
        const { maxDate } = this.state;

        if (startDate > maxDate || endDate > maxDate) {
            if(startDate > maxDate && endDate > maxDate) {
                this.showError(maxDate);
            }

            startDate = startDate > maxDate ? maxDate : startDate;
            endDate = endDate > maxDate ? maxDate : endDate;
        }  

        const newDate = {
            startDate: startDate,
            endDate: endDate,
        };

        this.setState({
            date: newDate
        });
    }

    showError = lastDate => {
        const aux = lastDate.format('YYYY-MM-DD');
        const message = "Out of range! End date: " + aux;

        toast.info(message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 3000,
            draggablePercent: 60
        });
    }

    componentDidMount() {
        const { date } = this.props;

        this.setState({
            date
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { date } = this.state;
        const { retrieveData } = this.props;

        if (JSON.stringify(date) !== JSON.stringify(prevState.date) &&
            date && 
            date.startDate && 
            date.endDate
        ) {
            retrieveData({ 
                startDate: date.startDate.format('YYYY-MM-DD'), 
                endDate: date.endDate.format('YYYY-MM-DD')
            });
        }

        if(!date ||
            !date.startDate ||
            !date.endDate
        ) {
            this.setState({
                date: {
                    startDate: moment().subtract(8, 'days'),
                    endDate: moment().subtract(1, 'days'),
                }
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { date, maxDate } = this.state;

        return (
            <Paper className={classes.root}>
                {
                    date && 
                    date.startDate && 
                    date.endDate && (
                        <DateRangePicker
                            className='scrollable-datepicker'
                            ranges={[{
                                startDate: date.startDate.toDate(),
                                endDate: date.endDate.toDate(),
                                key: 'date',
                            }]}
                            months={1}
                            // scroll={{enabled: true}}
                            onChange={this.handleSelect}
                            maxDate={maxDate.toDate()}
                            dateDisplayFormat={'YYYY-MM-DD'}
                            // showMonthAndYearPickers={false}
                        />
                    )    
                }
            </Paper>
        );
    }
}

export default withStyles(styles)(DateSelector);