import React, {Component} from 'react'

import { AuthContent, AuthWelcomeContent, AuthFormContent } from './AuthStyle'
import AuthRouter from './AuthRouter';

class Auth extends Component {
    render () {
        return (
            <AuthContent>
                <AuthWelcomeContent>
                    {/* WELCOME CONTENT */}
                </AuthWelcomeContent>
                <AuthFormContent>
                    <AuthRouter {...this.props} />
                </AuthFormContent>
            </AuthContent>
        )
    }
}

export default Auth
