import React, {Component} from 'react'

import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, withStyles } from '@material-ui/core';



class GraphicHydroBase extends Component {
  constructor (props) {
    super (props);

    this.state = {
      showExport: false,
    }

  }

  showLoading = () => (
    <div style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={(30)} />
      <div style={{
        marginTop: '20px'
      }}>
        Loading...
      </div>
    </div>
  )


  generateArrayWithInterval(array, start = 0, salt = 100){

    let ticks = [];

    for (var i = start; i < array.length; i = i+salt) {

        ticks.push(i);        
    }


    return ticks;
  }









  render(){

    return(
     <div></div>
    ) 

  }


}

export default GraphicHydroBase;
