import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Select, MenuItem, Input, IconButton, Tooltip } from '@material-ui/core'
import { EditModeContent } from './EditModeStyle'
import * as R from 'ramda'
// import ManagementHeaderComponent from './ManagementPage/components/ManagementHeaderComponent'
import { editModeChangeTitle, resetChange, editModeChangePosition, editModeRemoveWidget, editModeNewWidget, editModeEditWidget, editModeRemove, editModeNew } from '../../../../actions/EditModeAction'
import GridLayout from 'react-grid-layout'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import WidgetIcon from '@material-ui/icons/Widgets'
import PageIcon from '@material-ui/icons/NoteAdd'
import RefreshIcon from '@material-ui/icons/Refresh'

import Button from '@material-ui/core/Button'
import GraphicWidgetEdit from '../../../../components/GraphicModel/GraphicWidgetEdit';
import { graphicsForNewWidget } from '../../../../constants/graph-types';
import Modules from '../../../../data/META/modules-registry.json';
import GraphicsCategory from '../../../../data/META/graphics-registry-category.json';
import pageTypes from '../../../../data/META/page-type.json';

import Select2 from '../../../../components/Menu/Select';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import DeleteDialog  from './DeleteDialog'
import DeleteWidgetDialog  from './DeleteWidgetDialog'

import {
  CHANGE_ROUTE,
  REDUCER_NAVIGATION_SELECTED_MODULE,
  REDUCER_NAVIGATION_SELECTED_SUBMODULE
} from '../../../../constants/reducers/navigation-events';

const images_context = require.context('../../../../assets/preview_images/', false, /\.(png)|(svg)$/)
const images_keys = images_context.keys().map(key => key)
const images = {}

images_keys.map(key => {
  let treated_key = key.split('/')
  treated_key = treated_key[treated_key.length - 1].split('.')[0]
  images[treated_key] = images_context(key)
})

const styles = theme => ({
  inputPageTitle: {
    fontSize: 16,
    marginRight: '20px',
    minWidth: '100%',
    '&:focus': {
      outline: 0
    }
  },
  widgetNew : {
    backgroundColor: '#ededed',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 320,
    width: '100%',
  }
})

const FILTER_PADDING = 30

class EditMode extends Component {



  constructor (props) {

    super (props)
    const elBody = window.document.getElementsByTagName('BODY')[0]
    this.filterByPageType.bind(this)

    const {classes, Management,  history, Navigation} = props

 
    const path_tree = this.props.prevPath.pathname;

    const route = path_tree.split('/');

    const module_name = route[3]

    const REDUCER_NAME = module_name === 'virtual-clone' ?  'envelope' : module_name;

    const MODULE = Modules.filter( module => module.id == Navigation.module_id);
    let submodule_id = Navigation.submodule_id? Navigation.submodule_id : 0;
    let SUBMODULE = null;

    let positions = [];

    if (Navigation.submodule_id) {
        SUBMODULE = MODULE[0].submodules.filter( sm => sm.id == Navigation.submodule_id);
    }else{

        SUBMODULE = MODULE[0].submodules;
    }


    const Page = this.props[REDUCER_NAME];
    
    const ACTION_GET_DATA = SUBMODULE[0].actions[0];
    const ACTION_CLEAR_DATA = SUBMODULE[0].actions[1];



    const managementSelected = R.find(R.propEq('selected', true))(Page)

    
    this.state = {
      gridWidth: (elBody.offsetWidth - (FILTER_PADDING * 2) - 10),
      dialogDeleteOverview: false,
      dialogDelete: false,
      deleteWidgetId: null,
      GraphicsCategory: GraphicsCategory,
      selectedWidget:null,
      dialogNewWidget: false,
      dialogResetChanges:false,
      dialogNewPage: false,
      originalPage: Page,
      managementSelected: null,
      widgetNew: {

      },
      pageNew: {

      },
      // doc: props.location.state.selected_submodule_id,
      dialogEditWidget: false,
      widgetEdit: {

      }
    }
  }

  calculatePositionWidget = (grids)  => {

    let final_position = {};

    if (grids.length > 0) {

      let coordinates = [];

      grids.forEach(function(grid) {
          if(coordinates[grid.y]){
            coordinates[grid.y].push(grid.x);
          }else{
            coordinates[grid.y] = [];
            coordinates[grid.y].push(grid.x);
          }
      });


      let last_y_index = coordinates.length -1 ;
      let last_y = coordinates.pop();

      let last_x = Math.max.apply(null, last_y);

      if (last_x + 4 < 12 ) {

          final_position.x = last_x + 4;
          final_position.y = last_y_index;

      }else{
        final_position.x = 0
        final_position.y = last_y_index + 2
      }


      return final_position;


    }

    final_position.x = 0;
    final_position.y = 0;

    return final_position;

  }


  titlePageChange = (management, reducer_name, action) => event => {
    let value = this.props.editModeChangeTitle(management.id, reducer_name, action, event.target.value, this.props)
    this.props.updateProps(reducer_name.toLowerCase(),value )


  }

  gridLayoutChange = (management_id, reducer_name, action) => positions => {
    let value =  this.props.editModeChangePosition(management_id, reducer_name, action, positions, this.props)
    this.props.updateProps(reducer_name.toLowerCase(),value )
  }



  openDialogRemove = (widget_id) => () => {
    this.setState({
      deleteWidgetId: widget_id,
      dialogDelete: true
    })
  }

  openDialogNewPage = () => {
    this.setState({
      dialogNewPage: true,
      selectedWidget:null,
      pageNew: {

      }
    })
  }


  openDialogReset = () => {

    this.setState({
      dialogResetChanges: true,

    })
  }


  openDialogNew = () => {

    this.setState({
      dialogNewWidget: true,
      GraphicsCategory:GraphicsCategory,
      selectedWidget:null,
      widgetNew: {

      }
    })
  }

  openDialogEdit = widget => () => {

    const selected = R.find(R.propEq('value', widget.type))(GraphicsCategory[0].options)

    this.setState({
      dialogEditWidget: true,
      selectedWidget: selected,
      widgetEdit: {
        ...widget
      }
    })
  }



  resetChanges = (id, reducer, action) => () => {

    this.setState({
      dialogResetChanges: false,
    })

      window.location.reload();


  }

  closeDialogRemove = () => {
    this.setState({
      dialogDelete: false
    })
  }

  closeDialogNew = () => {
    this.setState({
      dialogNewWidget: false,
      widgetNew: {

      }
    })
  }

    closeDialogNewPage = () => {
    this.setState({
      dialogNewPage: false,
      pageNew:{

      }
    })
  }



  closeDialogEdit = () => {
    this.setState({
      dialogEditWidget: false,
      widgetEdit: {

      }
    })
  }

   filterByPageType(options, page){

      let final_options = [];

      options.forEach(function(category) {


        if (!category.finalOptions) {
              category.finalOptions = [];
        }

        category.finalOptions = [];
        category.options.forEach(function(opt) {
          if(!opt.disabled) {
            if (opt.page_type && opt.page_type.includes(page.type) ||
            opt.page_type && opt.page_type.includes(page.page_type)
            ) {
              category.finalOptions.push(opt)
            }
          }
        });

        let new_category = {...category};
        new_category.options = category.finalOptions;

        final_options.push(new_category);


      });


      return final_options;

   }




  closeDialogResetChanges = () => {
    this.setState({
      dialogResetChanges: false
    })
  }


  changeValueSelectTypeNew = (widget) => {
    this.setState({
      selectedWidget: widget
    })


    this.setState(state => {
      const newItem = {
        widgetNew: {
          ...state.widgetNew,
          type: widget.value
        }
      }

      return newItem
    })
  }

  changeValueNavigateTypeNew = (event, widget) => {
    this.setState({
      selectedWidget: widget
    })


    this.setState(state => {
      const newItem = {
        widgetNew: {
          ...state.widgetNew,
          type: widget.value
        }
      }

      return newItem
    })
  }

 changeValueTypeNewPage = (selected, managementSelected ) => {

    const itemTitle = {
      pageNew: {
        ...this.state.pageNew,
        type: selected.id
      }
    }

    this.setState(itemTitle)


  }


  changeValueSelectTypeEdit = (widget) => {
    this.setState({
      selectedWidget: widget
    })

    this.setState(state => {
      const newItem = {
        widgetEdit: {
          ...state.widgetEdit,
          type: widget.value
        }
      }

      return newItem
    })
  }

  changeValueTitleNew = (grids, event) => {
    const positions = this.calculatePositionWidget(grids);

    const itemTitle = {
      widgetNew: {
        ...this.state.widgetNew,
        titleHeader: event.target.value,
         grid: {
            "x": positions.x,
            "y": positions.y,
            "w": 4,
            "h": 2
          }
      }
    }

    this.setState(itemTitle)
  }

  changeValueTitleNewPage = (managementSelected, event) => {


    const itemTitle = {
      pageNew: {
        ...this.state.pageNew,
        name: event.target.value,
        filter: managementSelected.filter
      }
    }

    this.setState(itemTitle)

  }


  changeValueTitleEdit = event => {
    const itemTitle = {
      widgetEdit: {
        ...this.state.widgetEdit,
        titleHeader: event.target.value
      }
    }

    this.setState(itemTitle)
  }

  saveNewPage = (management_id, reducer_name, action) => () => {

    let value = this.props.editModeNew(management_id, reducer_name, action, this.state.pageNew, this.props);
    this.props.updateProps(reducer_name.toLowerCase(),value )
    this.closeDialogNewPage()
  }

  saveNewItem = (management_id, reducer_name, action, grids=null) => () => {
    const positions = this.calculatePositionWidget(grids)
    let new_widget = this.state.widgetNew
    new_widget.grid = {
      x: positions.x,
      y: positions.y,
      w: 4,
      h: 2
    }


    let value = this.props.editModeNewWidget(management_id, reducer_name, action, new_widget, this.props)

    this.props.updateProps(reducer_name.toLowerCase(),value )
    this.closeDialogNew()
  }

  saveUpdateItem = (management_id, reducer_name, action) => () => {

    let value = this.props.editModeEditWidget(management_id, reducer_name, action, this.state.widgetEdit, this.props)
    this.props.updateProps(reducer_name.toLowerCase(), value)
    this.closeDialogEdit()
  }



  openDialogReset = () => {

    this.setState({
      dialogResetChanges: true
    })

  }

  render () {

 


    const path_tree = this.props.prevPath.pathname;

    const route = path_tree.split('/');

    const module_name = route[3]

    const REDUCER_NAME = module_name === 'virtual-clone' ?  'envelope' : module_name;


    const {classes, Management,  history, Navigation, Dashboard} = this.props

    const MODULE = Modules.filter( module => module.id == Navigation.module_id);
    let submodule_id = Navigation.submodule_id? Navigation.submodule_id : 0;
    let SUBMODULE = null;

    let positions = [];

    if (Navigation.submodule_id) {
        SUBMODULE = MODULE[0].submodules.filter( sm => sm.id == Navigation.submodule_id);
    }else{

        SUBMODULE = MODULE[0].submodules;
    }

    const Page = this.props[REDUCER_NAME];

    const ACTION_GET_DATA = SUBMODULE[0].actions[0];
    const ACTION_CLEAR_DATA = SUBMODULE[0].actions[1];

    const managementSelected =  R.find(R.propEq('selected', true))(Page)

    return (
      <EditModeContent>

        <div
          style={{
            width: `calc(100% - ${(FILTER_PADDING * 2)})`,
            minHeight: '64px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: FILTER_PADDING,
            marginRight: FILTER_PADDING
          }}
        >
          <div
            style={{
              flex: 1
            }}
          >
            {managementSelected? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row'
              }}>
                <input
                  type="text"
                  value={managementSelected.name}
                  className={classes.inputPageTitle}
                  onChange={this.titlePageChange(managementSelected, REDUCER_NAME, ACTION_GET_DATA).bind(this)}
                />


                {/* DELETE OVERVIEW */}
                <DeleteDialog   open={this.state.dialogDeleteOverview}   {...this.props}    />
              </div>
            ): ''}
          </div>
          <div
            style={{
              flex: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
          </div>
        </div>
        <div
          style={{
            width: `calc(100% - ${(FILTER_PADDING * 2) + 10})`,
            height: '100%',
            marginLeft: FILTER_PADDING - 10,
            marginRight: FILTER_PADDING - 10
          }}
        >
          {this.state.gridWidth && managementSelected ? (
            <div>
              <GridLayout
                // className={classes.gridElement}
                cols={12}
                rowHeight={200}
                items={50}
                width={this.state.gridWidth}
                compactType={'horizontal'}
                onLayoutChange={this.gridLayoutChange(managementSelected.id, REDUCER_NAME, ACTION_GET_DATA).bind(this)}
              >
                {managementSelected.widgets.map((widget, indexWidget) => {

                  positions.push(widget.grid);
                  return (
                    <div
                      key={widget.id}
                      data-grid={widget.grid}
                    >
                      <GraphicWidgetEdit
                        widget={widget}
                        goEdit={this.openDialogEdit(widget).bind(this)}
                        goRemove={this.openDialogRemove(widget.id).bind(this)}
                      />
                    </div>
                  )
                })}
              </GridLayout>
            </div>
          ) : (
            <div>
              Loading...
            </div>
          )}
          <div
            style={{
              minHeight: '100px'
            }}
          >

            <div
              style={{
                position: 'fixed',
                bottom: '140px',
                right: '20px'
              }}
            >
              <Tooltip 
                title="Add Widget"
                placement="left"
              >
                <Button
                  variant="fab"
                  color="primary"
                  aria-label="Add"
                  onClick={this.openDialogNew.bind(this)}
                >
                  <WidgetIcon />
                </Button>
              </Tooltip>
            </div>


            <div
              style={{
                position: 'fixed',
                bottom: '90px',
                right: '26px'
              }}
            >
              <Tooltip 
                title="Add Page"
                placement="left"
              >
                <Button
                  variant="fab"
                  color="primary"
                  aria-label="Add Page"
                  onClick={this.openDialogNewPage.bind(this)}
                  mini
                >
                  <PageIcon />
                </Button>
              </Tooltip>
            </div>



          </div>
        </div>


        {/* DELETE WIDGET */}
        {/* <DeleteWidgetDialog   {...this.props}  deleteWidgetId = {this.state.deleteWidgetId}
            dialogDelete = {this.state.dialogDelete}  closeDialogRemove={this.closeDialogRemove}  /> */}


        {/* NEW WIDGET */}
        <Dialog
          onClose={this.closeDialogNew}
          open={this.state.dialogNewWidget}
          aria-labelledby="dialog_widget_new"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="dialog_widget_new">
            New Widget
          </DialogTitle>
          <DialogContent>

           <Grid container spacing={24}>

              <Grid item xs={7}>
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="widget-title-new">
                      Title
                    </InputLabel>
                    <Input
                      id="widget-title-new"
                      value={this.state.widgetNew.titleHeader}
                      onChange={this.changeValueTitleNew.bind(this, positions)}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                  >
                    <Select2 options={this.filterByPageType(GraphicsCategory, managementSelected)} label="Widget Type"
                      value={this.state.selectedWidget}
                      onChange={this.changeValueSelectTypeNew.bind(this)}
                      onKeyDown={this.changeValueNavigateTypeNew.bind(this)}
                       inputProps={{
                          name: 'widgetType',
                          id: 'widget-type-select',
                        }}
                        renderValue={selected => {
                          if (selected) {
                            const type = R.find(R.propEq('type', selected))(graphicsForNewWidget)
                            return type.description
                          }

                          return 'None'
                        }}

                     />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={5}>
                  <Card className={classes.card} style={{display: this.state.selectedWidget ? 'block':'none', height: '100%'}}>
                      <CardMedia
                        className={classes.media}
                      />
                      <br/>
                      <br/>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h6">
                          Preview
                        </Typography>
                        {
                          this.state.selectedWidget ?
                            <img src={images['preview' in this.state.selectedWidget ? this.state.selectedWidget.preview : this.state.selectedWidget.value]} style={{width:'100%'}}/>
                          :
                            null
                        }
                      </CardContent>
                  </Card>

              </Grid>
          </Grid>


          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialogNew} color="primary">
              Cancel
            </Button>
            {managementSelected ? (
              <Button
                onClick={this.saveNewItem(managementSelected.id, REDUCER_NAME, ACTION_GET_DATA, positions).bind(this)}
                color="primary"
              >
                Save
              </Button>
            ) : ''}
          </DialogActions>
        </Dialog>

        {/* EDIT WIDGET */}
        <Dialog
          onClose={this.closeDialogEdit}
          open={this.state.dialogEditWidget}
          aria-labelledby="dialog_widget_edit"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="dialog_widget_edit">
            Edit Widget
          </DialogTitle>
          <DialogContent>
              <Grid container spacing={24}>
                <Grid item xs={7}>
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="widget-title-edit">
                        Title
                      </InputLabel>
                      <Input
                        id="widget-title-new"
                        value={this.state.widgetEdit.titleHeader}
                        onChange={this.changeValueTitleEdit.bind(this)}
                      />
                    </FormControl>
                    <FormControl
                      className={classes.formControl}
                    >
                      <Select2 options={this.filterByPageType(GraphicsCategory, managementSelected)} label="Tipo de widget"
                        value={this.state.selectedWidget}
                        onChange={this.changeValueSelectTypeEdit.bind(this)}
                        onKeyDown={this.changeValueNavigateTypeNew.bind(this)}
                         inputProps={{
                            name: 'widgetType',
                            id: 'widget-type-select',
                          }}
                          renderValue={selected => {
                            if (selected) {
                              const type = R.find(R.propEq('type', selected))(graphicsForNewWidget)
                              return type.description
                            }

                            return 'None'
                          }}

                       />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={5}>

                    <Card className={classes.card} style={{display: this.state.selectedWidget ? 'block':'none', height: '100%'}}>
                        <CardMedia
                          className={classes.media}
                          image= {
                            this.state.selectedWidget ?
                              images['preview' in this.state.selectedWidget ? this.state.selectedWidget.preview : this.state.selectedWidget.value]
                            :
                              null
                          }
                          title="Contemplative Reptile"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h6" component="h6">
                            Preview
                          </Typography>
                            {
                              this.state.selectedWidget ?
                                <img src={images['preview' in this.state.selectedWidget ? this.state.selectedWidget.preview : this.state.selectedWidget.value]} style={{width:'100%'}}/>
                              :
                                null
                            }
                        </CardContent>
                    </Card>

                </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialogEdit} color="primary">
              Cancel
            </Button>
            {managementSelected ? (
              <Button
                onClick={this.saveUpdateItem(managementSelected.id, REDUCER_NAME, ACTION_GET_DATA).bind(this)}
                color="primary"
              >
                Update
              </Button>
            ) : ''}
          </DialogActions>
        </Dialog>

        {/* NEW PAGE */}
        <Dialog
          onClose={this.closeDialogNewPage}
          open={this.state.dialogNewPage}
          aria-labelledby="dialog_widget_new"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="dialog_widget_new">
            New Page
          </DialogTitle>
          <DialogContent>

           <Grid container spacing={24}>

              <Grid item xs={12}>
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="widget-title-new">
                      Title
                    </InputLabel>
                    <Input
                      id="widget-title-new"
                      value={this.state.pageNew.tile}
                      onChange={this.changeValueTitleNewPage.bind(this, managementSelected)}
                    />
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                  >
                    <Select2 options={pageTypes} label="Tipo de pagina"
                      onChange={this.changeValueTypeNewPage.bind(this)}
                      onKeyDown={this.changeValueNavigateTypeNew.bind(this)}
                       inputProps={{
                          name: 'widgetType',
                          id: 'widget-type-select',
                        }}
                        renderValue={selected => {
                          if (selected) {
                            const type = R.find(R.propEq('type', selected))(pageTypes)
                            return type.description
                          }

                          return 'None'
                        }}

                     />
                  </FormControl>
                </div>
              </Grid>
          </Grid>


          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialogNewPage} color="primary">
              Cancel
            </Button>
            {managementSelected ? (
              <Button
                onClick={this.saveNewPage(managementSelected.id, REDUCER_NAME, ACTION_GET_DATA).bind(this)}
                color="primary"
              >
                Save
              </Button>
            ) : ''}
          </DialogActions>
        </Dialog>


        {/* RESET ACTION */}
        <Dialog
          onClose={this.closeDialogResetChanges}
          open={this.state.dialogResetChanges}
          aria-labelledby="dialog_widget_new"
          fullWidth={false}
          maxWidth="md"
        >
          <DialogTitle id="confirmation-dialog-title">Do you want to reset your changes?</DialogTitle>
          <DialogActions>
            <Button variant="contained" onClick={this.closeDialogResetChanges} style={{backgroundColor:"#e65100", color:"#fff"}}>
              No
            </Button>
             <Button onClick={this.resetChanges(managementSelected.id, REDUCER_NAME, ACTION_GET_DATA)}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>


      </EditModeContent>
    )
  }
}

const mapStateToProps = ({Dashboard, User, Application, Navigation}) => {

  const {Page} = Dashboard
  const {parks_info} = User
  return { Dashboard, Page,  parks: parks_info, Application, Navigation }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
  editModeChangeTitle,
  editModeChangePosition,
  editModeRemoveWidget,
  editModeNewWidget,
  editModeEditWidget,
  editModeRemove,
  editModeNew,
  resetChange,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditMode))
