import React from 'react'
import {withStyles} from '@material-ui/core'
import {Auth} from 'aws-amplify'
import * as R from 'ramda'

import { ROUTE_AUTH } from '../../../../constants/route-constant';

import {logout, destroy} from '../../../../actions/AuthAction'
import ConfirmationDialogRaw from '../../../../components/Dialog/ConfirmationDialogRaw'
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@material-ui/core'
import {Typography, Icon, IconButton, Menu, MenuItem, CircularProgress, Button} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import { updateDateFilter } from "../../../../actions/DateFilterActions";
import { savePreferences, clearPreferences } from '../../../../actions/SendDataActions'
import { CognitoChangePassword } from '../../../../actions/ApplicationActions'
import moment from "moment-timezone";

import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'

import { toast } from 'react-toastify'

const styles = {
  progressSaving: {
    color: orange[500],
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1000
  }
}

class HeaderAllAvatarComponent extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      userMenuAnchorEl: false,
      menu_open: false,
      old_password: null,
      new_password: null,
      dialogLogout:false,
      dialogChangePassword:false,
      saving: false,
      saveOk: null,
      clearConfirm: false,
      clearing: false,
      clearOk: null,
      prefBtAnchor: null,
      passwords_matches: true,
      new_password: '',
      old_password: '',
      confirm_password: '',
      value: 'Delfos'
    }
  }

  menuToggle = (param, value) => e => {
    if (value) {
      this.setState({ [param]: e.currentTarget, menu_open: true, clearConfirm: false, dialogLogout: false })
    } else {
      this.setState({ [param]: null, menu_open: false, clearConfirm: false, dialogLogout: false })
    }
  }

  componentDidUpdate() {


    if(this.state.saving && this.state.saveOk != null) {
      setTimeout(() => this.setState({saving: false, saveOk: null, menu_open: false}), 1000)
    } else if(this.state.saving) {
      savePreferences(this.props['data'])
        .then(result => {
          this.setState({saving: true, saveOk: true})
        })
        .catch(error => {
          this.setState({saving: true, saveOk: false})
        })
    }

    if(this.state.clearing && this.state.clearOk != null) {
      setTimeout(() => this.setState({clearing: false, clearOk: null, menu_open: false}), 1000)
    } else if(this.state.clearing) {
      clearPreferences()
        .then(result => {
          this.setState({clearing: true, clearOk: true, clearConfirm: false})
        })
        .catch(error => {
          this.setState({clearing: true, clearOk: false, clearConfirm: false})
        })
    }
  }

  closeDialogChangePassword = () =>{
    this.setState({ dialogChangePassword: false, menu_open: false, new_password: '', confirm_password: '', old_password: '', passwords_matches: true })
  }

  handleClose = value => {
    this.setState({ value, dialogLogout: false, clearConfirm: false, menu_open: false });
  }

  logoutBtClick(event){
    this.setState({
      dialogLogout: true
    })
  }

  changePassword(){
    if(this.state.new_password.length > 0 && this.state.passwords_matches) {
      const pass_changed = this.props.CognitoChangePassword(this.state.old_password, this.state.new_password)
      // console.log(pass_changed)
      pass_changed.then(({result, details}) => {
        if(!result) {
          console.log('ERROR DETAILS', details)
          toast.error(
            details.code == 'NotAuthorizedException' ?
              'Incorrect old password. Please provide the correct one.'
            :
              details.message,
            {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            }
          )
          this.setState({new_password: '', confirm_password: '', old_password: '', passwords_matches: false})
        } else {
          this.setState({new_password: '', confirm_password: '', old_password: '', passwords_matches: true, user_menu_open: false, user_menu_anchorEl: null})
        }
      }).catch(error => {
        this.setState({new_password: '', confirm_password: '', old_password: '', passwords_matches: true})
      })
    }
  }

  changePasswordBtClick(event){
    this.setState({
      dialogChangePassword: true,
      menu_open: false
    })
  }

  savePreferencesBtClick(event) {
    this.setState({
      saving: true,
      prefBtAnchor: event.currentTarget.getBoundingClientRect()
    })
  }

  clearPreferencesBtClick(event) {
    this.setState({
      clearConfirm: true
    })
  }

  clearPrefs() {
    this.setState({
      clearConfirm: false,
      clearing: true
    })
  }

  executeLogout = async () => {
    console.log('On execute logout')
    const { logout } = this.props;
    
    try {
      console.log('Trying to log out')
      logout(null, true);
    } catch (err) {
      console.log('Error after trying normal logout: ', err)
      console.log('Forcing logout...')
      try {
        logout(null, false, true);
      } catch(err2) {
        console.log('Error after trying forced logout: ', err2)
        console.log('Show toast')
      }
    }
  }

  render () {
    const {classes, user} = this.props


    return (
      <div
        style={{
          height: '100%',
          minWidth: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {
          this.props.isDark
          ?
          (
            <span
              style={{
                color: 'white'
              }}
            >
              {user.name}
            </span>
          )
          :
          (
            <span>
              {user.name}
            </span>
          )
        }
        
        <IconButton
          onClick={this.menuToggle('userMenuAnchorEl', true).bind(this)}
        >
          <Icon
            style={{
              color:'#bdbdbd',
              fontSize:'36px'
            }}>
              account_circle
          </Icon>
        </IconButton>

        <Menu
          id="user-menu-options"
          anchorEl={this.state.userMenuAnchorEl}
          open={this.state.menu_open}
          onClose={this.menuToggle('userMenuAnchorEl', false).bind(this)}
          style={{zIndex: 9999}}
        >
          <MenuItem
            disabled={true}
            disableGutters={true}
          >
            Preferences
          </MenuItem>
          <MenuItem
            key={'SavePrefs'}
            disableGutters={true}
            disabled={this.state.saving}
            onClick={this.savePreferencesBtClick.bind(this)}
          >
            <Icon
              className={classes.icon}
              style={{
                color:'#bdbdbd',
                marginRight: '5px'
              }}>
                save
            </Icon>
            <Typography
              variant="h2"
              component="p"
              style={{
                fontSize: '1rem',
                margin: 0
              }}
            >
              Save
            </Typography>
            {
              this.state.saving && this.state.saveOk == null ?
                <CircularProgress
                  size={24}
                  className={classes.progressSaving}
                  style={{
                    top: '25%',
                    left: '47%'
                  }}
                />
              :
                null
            }
            {
              this.state.saving && this.state.saveOk != null ?
                <Icon
                  size={24}
                  className={classes.progressSaving}
                  style={{
                    top: '25%',
                    left: '45%'
                  }}
                >
                  {this.state.saveOk ? 'check' : 'close'}
                </Icon>
              :
                null
            }
          </MenuItem>
          <MenuItem
            key={'ClearPrefs'}
            disableGutters={true}
            disabled={this.state.clearing}
            onClick={this.clearPreferencesBtClick.bind(this)}
          >
            <Icon
              className={classes.icon}
              style={{
                color:'#bdbdbd',
                marginRight: '5px'
              }}>
                delete
              </Icon>
            <Typography
              variant="h2"
              component="p"
              style={{
                fontSize: '1rem',
                margin: 0
              }}
            >
              Clear
            </Typography>
            <ConfirmationDialogRaw
              logout={this.clearPrefs.bind(this)}
              classes={{
                paper: classes.paper
              }}
              open={this.state.clearConfirm}
              onClose={this.handleClose}
              value={this.state.value}
              message='Your preference will be cleared, are you sure?'
            />
            {
              this.state.clearing && this.state.clearOk == null ?
                <CircularProgress
                  size={24}
                  className={classes.progressSaving}
                  style={{
                    top: '25%',
                    left: '47%'
                  }}
                />
              :
                null
            }
            {
              this.state.clearing && this.state.clearOk != null ?
                <Icon
                  size={24}
                  className={classes.progressSaving}
                  style={{
                    top: '25%',
                    left: '45%'
                  }}
                >
                  {this.state.clearOk ? 'check' : 'close'}
                </Icon>
              :
                null
            }
          </MenuItem>
          <MenuItem
            key={'ChangePassword'}
            onClick={this.changePasswordBtClick.bind(this)}
            disableGutters={true}
          >
            <Icon
              className={classes.icon}
              style={{
                color:'#bdbdbd',
                marginRight: '5px'
              }}>
                lock
              </Icon>
            <Typography
              variant="h2"
              component="p"
              style={{
                fontSize: '1rem',
                margin: 0
              }}
            >
            Change Password
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={this.logoutBtClick.bind(this)}
            disableGutters={true}
          >
            <Icon
              className={classes.icon}
              style={{
                color:'#bdbdbd',
                marginRight: '5px'
              }}>
                power_settings_new
              </Icon>
            <Typography
              variant="h2"
              component="p"
              style={{
                fontSize: '1rem',
                margin: 0
              }}
            >
              Sign Out
            </Typography>
            <ConfirmationDialogRaw
              logout={() => {
                console.log('ON CONFIRMATION DIALOG RAW LOGOUT')
                this.executeLogout();
                this.handleClose();


                // const { page, parks, executeFilter, Dashboard } = this.props;
                // const startDate = parks[0] ? moment(parks[0].t_end).subtract(8, 'days').startOf('day').format('YYYY-MM-DD') :  null
                // const endDate =   parks[0] ? moment(parks[0].t_end).startOf('day').format('YYYY-MM-DD') : null
              
                // if (startDate) {

                //    this.props.updateDateFilter(
                //           'page.id',
                //           moment(startDate).startOf("day"),
                //           moment(endDate).startOf("day"),
                //           'module_field',
                //           'reducer_name'
                //         );                 
                // }     

 

                // Auth.signOut()
                //   .then(() => {
                //     localStorage.clear()
                //     this.props.toggleAuth(false)
                //     this.props.history.push(ROUTE_AUTH)
                //   })
                //   .catch(error => {
                //     console.error('Auth.signOut', error)
                //     localStorage.clear()
                //     this.props.toggleAuth(false)
                //     this.props.history.push(ROUTE_AUTH)
                //   })
              }}
              destroy={this.props.destroy}
              classes={{
                paper: classes.paper
              }}
              open={this.state.dialogLogout}
              onClose={this.handleClose}
              value={this.state.value}
              message='Do you really want to leave?'
            />
          </MenuItem>
        </Menu>

        <Dialog
          onClose={this.closeDialogChangePassword}
          open={this.state.dialogChangePassword}
          aria-labelledby='dialog_overview_delete'
        >
          <DialogTitle id='dialog_overview_delete'>
            Change Password
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <TextField
                className={classes.textField}
                label='Current Password'
                placeholder='password'
                type='password'
                value={this.state.old_password}
                onChange={(e) => this.setState({old_password: e.target.value})}
              />
              <br/>
              <TextField
                className={classes.textField}
                label='New Password'
                placeholder='new_password'
                type='password'
                value={this.state.new_password}
                style={{
                  background: !this.state.passwords_matches ? red[500] : null,
                  color: !this.state.passwords_matches ? 'white' : null
                }}
                InputProps={{
                  style: {
                    color: !this.state.passwords_matches ? 'white' : null
                  }
                }}
                floatingLabelFocusStyle={{
                  color: !this.state.passwords_matches ? 'white' : null
                }}
                onChange={(e) => this.setState({new_password: e.target.value})}
              />
              <br/>
              <TextField
                className={classes.textField}
                label='Confirm New Password'
                placeholder='new_password'
                type='password'
                value={this.state.confirm_password}
                style={{
                  background: !this.state.passwords_matches ? red[500] : null,
                  color: !this.state.passwords_matches ? 'white' : null
                }}
                InputProps={{
                  style: {
                    color: !this.state.passwords_matches ? 'white' : null
                  }
                }}
                onChange={(e) => this.setState({confirm_password: e.target.value, passwords_matches: this.state.new_password.match(e.target.value)})}
              />

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialogChangePassword} style={{color: orange[500]}}>
              Cancel
            </Button>
              <Button
                onClick={this.changePassword.bind(this)}
                style={{color: orange[500]}}
                autoFocus
              >
                Save
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = ({Dashboard, User, Application, Navigation}) => {

  const {Page} = Dashboard
  // const {parks_info} = User
  return { Dashboard, Page, Application, Navigation }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout,
  updateDateFilter,
  destroy,
  CognitoChangePassword
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderAllAvatarComponent))
