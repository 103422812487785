import {store} from '../store/ProviderStore'
import * as R from 'ramda'
import { REDUCER_MANAGEMENT_GET_DATA } from '../constants/reducers/management-get-data';

// import { addCheckpoint } from '../providers/get-data-api-n';

const removeItemFromArray = (item_id, array) => {
  const indexItem = array.indexOf(item_id);

  array.splice(indexItem, 1)

  return array
}

const verifyContain = (device_id, devices) => {
  if (R.contains(device_id, devices)) {
    return removeItemFromArray(device_id, devices)
  }

  return R.append(device_id, devices)
}


export const goCheckDevice = (module_field, module_id, device_id, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    const module = R.find(R.propEq('id', module_id))(Module)

    const {filter} = module
    const {devices} = filter

    const devicesNew = verifyContain(device_id, devices)

    const setInFilterOfModule = R.assocPath(['filter', 'devices'], devicesNew, module)

    return dispatch({
      type: reducer_name,
      payload: Module.map(itemM => {
        if (itemM.id !== module_id) {
          return itemM
        }

        return setInFilterOfModule
      })
    })
  }
}

export const goCheckSubpark = (module_field, module_id, subpark_id, reducer_name) => {
  return dispatch => {
    const {Dashboard, User} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []
    const {parks_info} = User

    const module = R.find(R.propEq('id', module_id))(Module)

    const {filter} = module
    const {devices} = filter

    const subparkMap = parks_info.filter(park => {
      return R.contains(subpark_id, park.subparks.map(subpark => subpark.id))
    })
    .map(park => {
      return R.find(R.propEq('id', subpark_id))(park.subparks)
    })

    if (subparkMap.length <= 0) {
      return null
    }

    const subpark = subparkMap[0]

    const devicesFilter = subpark.wtgs.filter(wtg => {
      return R.contains(wtg.id, devices)
    })

    if (devicesFilter.length === subpark.wtgs.length) {
      const devicesDiference = R.difference(devices, devicesFilter.map(wtg => wtg.id))

      const moduleNew = R.assocPath(['filter', 'devices'], devicesDiference, module)

      return dispatch({
        type: reducer_name,
        payload: Module.map(itemM => {
          if (itemM.id !== module_id) {
            return itemM
          }

          return moduleNew
        })
      })
    }

    const devicesNew = R.uniq( R.flatten( R.append( subpark.wtgs.map(wtg => wtg.id), devices) ) )

    const moduleNew = R.assocPath(['filter', 'devices'], devicesNew, module)

    return dispatch({
      type: reducer_name,
      payload: Module.map(itemM => {
        if (itemM.id !== module_id) {
          return itemM
        }

        return moduleNew
      })
    })
  }
}

const getDevicesIdFromPark = (park) => {
  const resultIdsArray = park.subparks.map(subpark => {
    return subpark.wtgs.map(wtg => wtg.id)
  })

  return R.flatten(resultIdsArray)
}

export const goCheckPark = (module_field, module_id, park_id, reducer_name) => {
  return dispatch => {
    const {Dashboard, User} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []
    const {parks_info} = User

    const module = R.find(R.propEq('id', module_id))(Module)

    const {filter} = module
    const {devices} = filter

    const park = R.find( R.propEq('id', park_id) )(parks_info)

    const parkDevicesIds = getDevicesIdFromPark(park)

    const containIds = parkDevicesIds.filter(parkDevId => {
      return R.contains(parkDevId, devices)
    })

    if (containIds.length === parkDevicesIds.length) {
      const devicesDiference = R.difference(devices, parkDevicesIds)

      const moduleNew = R.assocPath(['filter', 'devices'], devicesDiference, module)

      return dispatch({
        type: reducer_name,
        payload: Module.map(itemM => {
          if (itemM.id !== module_id) {
            return itemM
          }

          return moduleNew
        })
      })
    }

    const devicesNew = R.uniq( R.flatten( R.append( parkDevicesIds, devices) ) )

    const moduleNew = R.assocPath(['filter', 'devices'], devicesNew, module)

    return dispatch({
      type: reducer_name,
      payload: Module.map(itemM => {
        if (itemM.id !== module_id) {
          return itemM
        }

        return moduleNew
      })
    })
  }
}

// const executeCheckpoint = (reducer_name, Module, module_id, dispatch, retry_count=0, jwtToken=null) => {
//   const retries = retry_count
//   return addCheckpoint(null, jwtToken)
//     .then(result => {
//       return dispatch({
//         type: reducer_name,
//         jwtToken: jwtToken,
//         payload: Module.map(module => {
//           if (module.id === module_id) {
//             const widgets = module.widgets.map(widget => {
//               return R.assoc('update', true, widget)
//             })
//
//             return R.assoc('widgets', widgets, module)
//           }
//
//           return module
//         })
//       })
//     })
//     .catch(result => {
//       console.error('executeCheckpoint', result)
//       if(retries < 3) {
//         executeCheckpoint(reducer_name, Module, module_id, dispatch, retries + 1)
//       }
//     })
// }

export const changeModuleAll = (module_field) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    
  }
}

export const changeUpdateWidgets = (module_field, module_id, reducer_name, jwtToken=null) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    return dispatch({
      type: reducer_name,
      jwtToken: jwtToken,
      payload: Module.map(module => {
        if (module.id === module_id) {
          const widgets = module.widgets.map(widget => {
            return R.assoc('update', true, widget)
          })

          return R.assoc('widgets', widgets, module)
        }
        return module
      })
    })

    // return executeCheckpoint(reducer_name, Module, module_id, dispatch, jwtToken)
    //   .then(result => {
    //     return 'jwtToken' in result ? result.jwtToken : null
    //   })
    //   .catch(error => {
    //     console.error('changeUpdateWidgets', error)
    //   })
  }
}

export const updateParksFilter = (module_field, module_id, reducer_name, subparks, devices) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    return dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        if (module.id === module_id) {
          const widgets = module.widgets.map(widget => R.assoc('data', null, (R.assoc('update', true, widget))));
          const modulesChangedWidgets = R.assoc('widgets', widgets, module);

          const filters = module.filter;
          filters['devices'] = devices;
          filters['subparks'] = subparks;

          const modulesRes = R.assoc('filter', filters, modulesChangedWidgets);
          return modulesRes;
        }

        return module;
      })
    })

    // return executeCheckpoint(reducer_name, Module, module_id, dispatch, jwtToken)
    //   .then(result => {
    //     return 'jwtToken' in result ? result.jwtToken : null
    //   })
    //   .catch(error => {
    //     console.error('changeUpdateWidgets', error)
    //   })
  }
}

export const updateParksEnvelopeFilter = (module_field, module_id, reducer_name, subparks, devices, parks_info) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    return dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        if (module.id === module_id) {
          const widgets = module.widgets.map(widget => R.assoc('data', null, (R.assoc('update', true, widget))));
          const modulesChangedWidgets = R.assoc('widgets', widgets, module);

          const filters = module.filter;
          filters['devices'] = devices;
          filters['subparks'] = subparks;

          // const subparkFiltered = parks_info.subparks.filter(subpark => subparks.indexOf(subpark.id) >= 0);
          // filters['envelope_id'] = subparkFiltered.wtgs && subparkFiltered.wtgs.filter(device => devices.indexOf(device.id) >= 0).envelope[0].id;

          const modulesRes = R.assoc('filter', filters, modulesChangedWidgets);
          return modulesRes;
        }

        return module;
      })
    })
  }
}

export const updateDateFilter = (page_id, startDate, endDate, module_field, reducer_name) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const Module = module_field in Dashboard ? Dashboard[module_field] : []

    // console.log('ON UPDATING DATE FILTER', Module)

    return dispatch({
      type: reducer_name,
      payload: Module.map(module => {
        const widgets = module.widgets.map(widget => R.assoc('data', null, (R.assoc('update', true, widget))));
        const modulesChangedWidgets = R.assoc('widgets', widgets, module);

        const filters = module.filter;
        // console.log('PEGUEI O FILTRO', filters)
        const dateRange = {
          startDate: startDate.format('YYYY-MM-DD 00:00:00'),
          endDate: endDate.format('YYYY-MM-DD 23:59:59')
        }

        filters['data_range'] = dateRange;

        const modulesRes = R.assoc('filter', filters, modulesChangedWidgets);
        // console.log(modulesRes)
        return modulesRes;
      })
    })
  }
}