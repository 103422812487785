import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

const BasicLoading = props => (
    <div style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <CircularProgress size={(30)} />
        <div style={{
          marginTop: '20px',
          color:'white'
        }}>
          Loading...
        </div>
      </div>
);

export default BasicLoading;