export default (pages, filters, decorators) => (
  pages.map(page => {
    if(filters) {
      page.filter = filters.global ? filters.global : null

      if(filters.per_page) {
        let page_filter = filters.per_page.filter(a_page => a_page.page_id == page.id)
        page.filter = page_filter.length == 1 ? page_filter[0].content : page.filter
      }

      if(filters.individual) {
        if (page.widgets) {
          page.widgets = page.widgets.map(widget => {
            let filter = filters.individual.filter(individual => individual.widget_id == widget.id)
            filter = filter.length == 1 ? filter[0] : null
            widget.filter = filter ? filter.content : null

            return widget
          })
        }
      }
    } else {
      page.filter = null
    }

    if(decorators) {
      page.widgets = page.widgets.map(widget => {
        let decorator = decorators.filter(decorator => decorator.widget_id == widget.id)
        decorator = decorator.length == 1 ? decorator[0] : null
        widget.preProcessDecorators = decorator ? decorator.preProcessDecorators : null
        widget.postProcessDecorators = decorator ? decorator.postProcessDecorators : null

        return widget
      })
    }

    return page
  })
)
