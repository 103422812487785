export const BAR_DECORATOR_LIST = [
  {
    name: 'Order',
    params_names: ['Descendant'],
    params_input_types: ['checkbox'],
    default_params_values: [],
    params_post_treatment: res_params => Array(
      `${res_params[0] ? `DESC` : `ASC`}`
    ),
    reverse_params_treatment: params => Array(
      params[0] == 'DESC' ? true : params[0] == 'ASC' ? false : null
    )
  },
  {
    name: 'TopBottom',
    params_names: ['Amount', 'Top', 'Bottom'],
    params_input_types: ['text_field_number', 'checkbox', 'checkbox'],
    default_params_values: [],
    params_post_treatment: res_params => Array(
      res_params[0],
      `${res_params[1] ? `top` : ``} | ${res_params[2] ? `bottom` : ``}`
    ),
    reverse_params_treatment: params => Array(
      params[0],
      ...params[1].replace(/ /g, '').split('|').filter(element => element && element != '')
                  .map((element, index) => (
                    (index == 0 && element.toLowerCase() == 'top') ||
                    (index == 1 && element.toLowerCase() == 'bottom')
                  ))
    ),
    pre_conditions: [
      {
        name: 'Order',
        params: [true]
      }
    ],
    excludes: [
    ]
  },
  {
    name: 'StackBars',
    params_names: [],
    params_input_types: [],
    default_params_values: []
  },
  {
    name: 'Pareto',
    params_names: [],
    params_input_types: [],
    default_params_values: [],
    pre_conditions: [
      {
        name: 'Order',
        params: [true]
      },
      {
        name:'TopBottom',
        params:[
          5,
          'top'
        ]
      }
    ],
    excludes: [
    ]
  }
];
