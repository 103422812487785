import React from 'react'
import {grey} from '@material-ui/core/colors'

import FilterComponent from './components/FilterComponent/FilterComponent'

const FILTER_HEIGHT = 56

// USAR DRAGGABLE DA SHOPIFY

class OverviewPage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <div
          style={{
            height: FILTER_HEIGHT,
            width: '100%',
            borderBottom: `1px solid ${grey['200']}`
          }}
        >
          <FilterComponent />
        </div>
        <div
          style={{
            height: `calc(100% - ${FILTER_HEIGHT}px)`
          }}
        >
          
        </div>
      </div>
    )
  }
}

export default OverviewPage
