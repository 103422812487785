import React, {
    useState,
    useEffect,
    useCallback
} from 'react';

import {
    useSelector,
    useDispatch
} from 'react-redux';

import moment from 'moment';

const GlobalDevicesContainer = props => {
    const {
        children,
        defaultDevices,
        defaultSubparks,
        executeFilter,
        dispatchFilter,
        onExecuteFilter=()=>null
    } = props;

    const filters = useSelector(state => state.Dashboard && {
        devices: state.Dashboard.DeviceFilter && state.Dashboard.DeviceFilter.devices,
        subparks: state.Dashboard.DeviceFilter && state.Dashboard.DeviceFilter.subparks,
        startTime: state.Dashboard.DateFilter && state.Dashboard.DateFilter.data_range && state.Dashboard.DateFilter.data_range.startDate,
        endTime: state.Dashboard.DateFilter && state.Dashboard.DateFilter.data_range && state.Dashboard.DateFilter.data_range.endDate
    });

    const parks_info = useSelector(state => Object.values(state.DevicesHierarchy || {}));

    const dispatch = useDispatch();

    const [ mounted, setMounted ] = useState(false);
    const [ devices, setDevices ] = useState(filters && filters.devices || defaultDevices);
    const [ subparks, setSubparks ] = useState(filters && filters.subparks || defaultSubparks);

    useEffect(
        () => {
            if(!mounted) {
                setMounted(true);
                dispatch({type: 'REDUCER_UPDATE_DEVICE', payload:{devices, subparks}});
                onExecuteFilter(devices, subparks);
            }
        },
        [
            devices,
            subparks,
            mounted
        ]
    );

    const customCloseAction = useCallback(
        (devicesInfo) => {
            if(mounted) {
                const {
                    device_list,
                    subparks_list
                } = devicesInfo;

                setDevices(device_list);
                setSubparks(subparks_list);

                const executeAction = (aDevices, aSubparks) => dispatch({type: 'REDUCER_UPDATE_DEVICE', payload:{ devices: aDevices, subparks: aSubparks }});
                onExecuteFilter(device_list, subparks_list, executeAction, filters);
            }
        },
        [
            devices,
            subparks,
            mounted,
            setDevices,
            setSubparks,
            filters
        ]
    );

    // window.setSubparks = setSubparks;

    return React.cloneElement(
        children,
        {
            parks_info,
            customCloseAction,
            Dashboard: {
                DeviceFilter: {
                    devices,
                    subparks
                }
            }
        }
    );
};

export default GlobalDevicesContainer;