import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from '../styles/ActionStyle';
import { withTranslation } from 'react-i18next';


class UnapproveForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.verticalAlign}>
                <Typography variant='headline' >{t('eventsUnapproveWarningTitle')}</Typography>
            </div>
        );
    }
};

export default withTranslation()(withStyles(styles)(UnapproveForm));