import React, {Component} from 'react'
import {
  Route,
  Redirect
} from 'react-router-dom'

import { AuthRouterContent } from './AuthStyle'
import LoginComponent from './components/LoginComponent/LoginComponent';

class AuthRouter extends Component {
  render () {
    return (
      <AuthRouterContent>
        <Route exact path="/auth" render={props => (
          <Redirect to={{
            pathname: "/auth/login",
            state: { from: props.location }
          }} />
        )} />
        <Route path="/auth/login" render={props => <LoginComponent {...props} toggleAuth={this.props.toggleAuth} />} />
      </AuthRouterContent>
    )
  }
}

export default AuthRouter
