import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import drilldown from "highcharts/modules/drilldown.js";
import * as HighchartsMore from 'highcharts/js/highcharts-more'
import Pareto from 'highcharts/modules/pareto.js';
import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import {
  withStyles,
  TableSortLabel,
  CircularProgress,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Popper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider,
  Icon,
  Button
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GraphicBarDecorators from './decorators/GraphicBarDecorators';
import {
  BAR_DECORATOR_LIST
} from '../../../constants/decorator-constant';

import { goToWTGPage } from '../../../actions/LinkActions'
// import { popUpWidgetPage } from '../../../actions/PopUpActions'
import PagePopUp from '../../../components/PopUp/PagePopUp'
import { goToPage } from '../../../actions/NavigationActions'
import { changeUpdateWidgets } from '../../../actions/MenuTreeAction'
import { store } from '../../../store/ProviderStore'

import styles from './styles/styles.js';
import NoPrint from '../../../components/Print/NoPrint'

require("highcharts/modules/annotations")(Highcharts);

// HighchartsMore(Highcharts);
// Highcharts.setOptions({
//     plotOptions: {
//         series: {
//             animation: false
//         }
//     }
// });
Pareto(Highcharts);
drilldown(Highcharts);

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2E2E2E',
    color: theme.palette.common.white
  }
}))(TableCell);


class GraphicBar extends Component {
  constructor(props) {
    super(props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      colors: [
        '#bdbdbd',
        '#767676',
        '#E62C20',
        '#4C65A7',
        '#B414DC',
        '#FCDC34',
        '#FC9018',
        '#0A70D2'
      ],
      columns: [],
      column_sort: 0,
      is_crescent: false
    }

    this.chartWillRender = this.chartWillRender.bind(this);
    // this.chartDidRender = this.chartDidRender.bind(this);
    this.showPopUp = this.showPopUp.bind(this)
  }

  componentDidMount() {
    this.props.getSettings(this.getSettings.bind(this));

    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }

  // chartDidRender (state, updating, loading) {
  //   let postProcessDecorators = this.props.graphic.postProcessDecorators;
  //
  //   if(postProcessDecorators) {
  //
  //     setTimeout(() => {
  //       if(!updating && !loading) {
  //         setTimeout(() => {
  //           postProcessDecorators.map(decorator_json => {
  //             const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
  //             const result = decorator(state);
  //
  //             state = result;
  //           });
  //         }, 3000);
  //       } else {
  //         this.chartDidRender(state, updating, loading);
  //       }
  //     }, 200)
  //   }
  // }

  chartWillRender(state) {
    let preProcessDecorators = this.props.graphic.preProcessDecorators;

    if (preProcessDecorators) {
      preProcessDecorators.map(decorator_json => {
        const decorator = GraphicBarDecorators[decorator_json.name](...decorator_json.params);
        const result = decorator(state);

        state = result;
      });
    }

    return state;

  }

  numberChange = text_field => event => {
    let new_state = new Object();
    new_state[text_field] = parseInt(event.target.value);
    this.setState(new_state);
  };

  checkboxToggle = checkbox => event => {
    let new_state = new Object();
    new_state[checkbox] = event.target.checked;
    this.setState(new_state);
  };

  switchToggle = (switch_name, decorator) => event => {
    let new_state = new Object();
    new_state[switch_name] = event.target.checked;
    this.setState(new_state);

    if ('excludes' in decorator && decorator.excludes.constructor == Array) {
      let to_exclude = BAR_DECORATOR_LIST.filter(a_decorator => decorator.name != a_decorator.name && decorator.excludes.indexOf(a_decorator.name) >= 0);
      to_exclude.map(a_decorator => {
        let to_exclude_name = `${a_decorator.name}__switch`;
        let to_exclude_state = new Object();
        to_exclude_state[to_exclude_name] = this.state[to_exclude_name] ? !event.target.checked : false;
        this.setState(to_exclude_state);
        return to_exclude_name;
      });
    }

    if ('pre_conditions' in decorator && decorator.pre_conditions.constructor == Array) {
      let pre_conditions_names = decorator.pre_conditions.map(pre_condition => pre_condition.name);
      let decorators_pre_conditions = BAR_DECORATOR_LIST.filter(a_decorator => pre_conditions_names.indexOf(a_decorator.name) >= 0);

      decorators_pre_conditions.map((a_decorator, pre_condition_index) => {
        let pre_condition_name = `${a_decorator.name}__switch`;

        if (!this.state[pre_condition_name]) {
          let pre_condition_state = new Object();
          pre_condition_state[pre_condition_name] = event.target.checked;
          this.setState(pre_condition_state);
        }

        let pre_condition_disabled_name = `${a_decorator.name}__switch_disabled`;
        let pre_condition_disabled_state = new Object();
        pre_condition_disabled_state[pre_condition_disabled_name] = event.target.checked;
        this.setState(pre_condition_disabled_state);

        a_decorator.params_input_types.map((input, index) => {
          if (input.match('checkbox')) {
            let checkbox_name = `${a_decorator.name}_checkbox_${a_decorator.params_names[index]}`;
            let new_checkbox = new Object();
            new_checkbox[checkbox_name] = decorator.pre_conditions[pre_condition_index].params[index];
            this.setState(new_checkbox);

            let new_checkbox_disable = new Object();
            new_checkbox_disable[`${checkbox_name}_disabled`] = event.target.checked;
            this.setState(new_checkbox_disable);
          } else if (input.match('text_field')) {
            if (input.split('_').pop() == 'number') {
              let text_field_name = `${decorator.name}_text-field-number_${decorator.params_names[index]}`;
              let new_number = new Object();
              new_number[text_field_name] = decorator.pre_conditions[pre_condition_index].params[index];
              this.setState(new_number);

              let new_number_disable = new Object();
              new_number_disable[`${text_field_name}_disabled`] = event.target.checked;
              this.setState(new_number_disable);
            }
          }
        });

        return pre_condition_name;
      });
    }
  };

  caulculateFontSizeHead(grid) {


    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }


  setFilter(params) {

    let order = !this.state.is_crescent
    this.setState({ column_sort: params.index + 1, is_crescent: order })
  }

  orderRows(rows, column_sort, is_crescent) {

    let sort_anchor = rows.map((row, index) => {

      return { idx: index, sort_value: Object.values(row)[column_sort] && parseFloat(Object.values(row)[column_sort].split(' ')[0]) }

    }).sort((a, b) => {

      return a.sort_value < b.sort_value ?
        is_crescent ? -1 : 1
        :
        a.sort_value > b.sort_value ?
          is_crescent ? 1 : -1
          :
          0
    })

    return rows.map((row, index) => rows[sort_anchor[index].idx])

  }



  getSettings() {
    let data = BAR_DECORATOR_LIST.map(decorator => (
      this.state[`${decorator.name}__switch`] ?
        {
          name: `${decorator.name}`,
          params: 'params_post_treatment' in decorator && decorator.params_post_treatment ?
            decorator.params_post_treatment(
              Object.keys(this.state)
                .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('__switch')))
                .map(key => this.state[key])
            )
            : Object.keys(this.state)
              .filter(key => key.match(`${decorator.name}`) && !Boolean(key.match('__switch')))
              .map(key => this.state[key])
        }
        : null
    )).filter(decorator => decorator);

    return data;
  }

  showPopUp(event) {
    // PagePopUp(
    //   event.point.category,
    //   'Device',
    //   30,
    //   'REDUCER_DEVICE_GET_DATA'
    // )
    this.setState({ popUp: true })
  }

  render() {
    const { classes, showTable, showSettings, showHelp, graphic, loading, font_size, reducer_name } = this.props;
    const { data, averageColor } = graphic;


    BAR_DECORATOR_LIST.map(decorator => {
      let switch_name = `${decorator.name}__switch`;

      if (Object.keys(this.state).indexOf(switch_name) == -1) {
        let switch_state = false;

        if (switch_name in this.state) {
          switch_state = this.state[switch_name];
        } else if ('preProcessDecorators' in graphic && graphic.preProcessDecorators) {
          if (graphic.preProcessDecorators.filter(a_decorator => a_decorator.name == decorator.name).length > 0) {
            switch_state = true;
          }
        }

        let new_switch = new Object();
        new_switch[switch_name] = switch_state;
        this.setState(new_switch);

        // this.state[`${decorator.name}__switch_disabled`]

        decorator.params_input_types.map((input, index) => {
          if (input.match('checkbox')) {
            let checkbox_name = `${decorator.name}_checkbox_${decorator.params_names[index]}`;
            let new_checkbox = new Object();

            try {
              if (!switch_state) { throw 'Switch is Off'; }
              if (checkbox_name in this.state) {
                new_checkbox[checkbox_name] = this.state[checkbox_name];
              } else {
                let preProcessDecorator = graphic.preProcessDecorators.filter(a_decorator => a_decorator.name == decorator.name)[0];
                new_checkbox[checkbox_name] = decorator.reverse_params_treatment(preProcessDecorator.params)[index];
                if (Object(new_checkbox[checkbox_name]).constructor != Boolean) { throw 'Invalid checkbox state;' }
              }
            } catch (e) {
              try {

                new_checkbox[checkbox_name] = decorator.default_params_values[index];
                if (Object(new_checkbox[checkbox_name]).constructor != Boolean) { throw 'Invalid checkbox state;' }
              } catch (e2) {

                new_checkbox[checkbox_name] = false;
              }
            } finally {
              this.setState(new_checkbox);
            }
          } else if (input.match('text_field')) {
            if (input.split('_').pop() == 'number') {
              let text_field_name = `${decorator.name}_text-field-number_${decorator.params_names[index]}`;
              let new_number = new Object();

              try {
                if (!switch_state) { throw 'Switch is Off'; }
                if (text_field_name in this.state) {
                  new_number[text_field_name] = this.state[text_field_name];
                } else {
                  let preProcessDecorator = graphic.preProcessDecorators.filter(a_decorator => a_decorator.name == decorator.name)[0];
                  new_number[text_field_name] = decorator.reverse_params_treatment(preProcessDecorator.params)[index];
                  if (Object(new_number[text_field_name]).constructor != Number) { throw 'Invalid text field number state'; }
                }
              } catch (e) {
                try {
                  new_number[text_field_name] = decorator.default_params_values[index];
                  if (Object(new_number[text_field_name]).constructor != Number) { throw 'Invalid text field number state'; }
                } catch (e2) {
                  new_number[text_field_name] = 0;
                }
              } finally {
                this.setState(new_number);
              }
            }
          }
        });
      }
    });

    var on_render_state = {
      ...this.state,
      data: data ? data : {
        x_axis: null,
        series: [],
        extra_y_axis: []
      }
    };

    // console.log('XXXXXXXXXXXXX', on_render_state.data)


    let decoratorUsed = [];
    Object.keys(on_render_state).map(key => {
      if (on_render_state[key] === true)
        decoratorUsed.push(key)
    })
    decoratorUsed = decoratorUsed[0];

    let withDecorator = this.chartWillRender(on_render_state);

    const data_table = withDecorator.data ? withDecorator.data : on_render_state.data;


    const dataValues = data_table && data_table.x_axis ?
      data_table.x_axis.map((axis, indexAxis) => {

        return R.flatten([
          axis,
          data_table.series ? data_table.series.map(serie => {
            if (serie.type != 'pareto' && serie.values && serie.values[indexAxis] != null) {
              let value = serie.values[indexAxis]

              if (value.constructor == Number) {
                if (Number.isInteger(value)) {
                  return `${serie.values[indexAxis]} ${serie.unit}`
                } else {
                  return `${serie.values[indexAxis].toFixed(1)} ${serie.unit}`
                }
              }
            } else {
              return graphic.nullEqualsTo ? `${graphic.nullEqualsTo} ${serie.unit}` : 'No Data'
            }
          }) : []
        ])
      }) : [];

    const hasAverage = dataValues[dataValues.length - 1] && dataValues[dataValues.length - 1][0] === 'Average';

    let average_row = hasAverage ? dataValues.pop() : [];
    let sorted_table = [];

    if (this.state.column_sort >= 0) {
      if (dataValues) {
        sorted_table = this.orderRows(dataValues, this.state.column_sort, this.state.is_crescent)
      }
    }

    if (hasAverage) {
      sorted_table.push(average_row);
    }

    // const dataValues = data_table && data_table.x_axis && data_table.series && data_table.features ?
    //   data_table.series.map((column, column_index) => (
    //     data_table.x_axis.map((row_label, row_index) => ({
    //
    //     }))
    //   ))
    // :
    //   null

    const tooltip_formatter = (item, index) => {
      let html_body = null;
      let tooltips = item.tooltip;

      try {
        let tooltip = tooltips[index];
        if (!tooltip) { throw 'Tooltip value null' };

        if (tooltip.constructor == String) {
          html_body = tooltip;
        } else {
          // put json format here
          throw 'Tooltip needs to treat json';
        }
      } catch (e) {

        let value = String(item.values[index]);
        let point_index = value.indexOf('.');
        html_body = `
          <b>${item.name}</b>: ${point_index > 0 ? value.slice(0, point_index + 3) : value} ${item.unit}
        `;
      }
      html_body = `<div style="background-color: rgb(255, 255, 255, 0.9); padding: 10px; border-radius: 5px">${html_body}</div>`;
      return html_body;
    };

    const y_max = 'y_max' in graphic ? graphic.y_max : null
    const y_min = 'y_min' in graphic ? graphic.y_min : null

    let params = {
      colors: on_render_state.colors,
      title: {
        text: null
      },
      chart: {
        zoomType: 'xy',
        events: {
          drilldown: function (e) {
            if (!e.seriesOptions) {
              try {
                const clickedSeries = on_render_state.data.drilldown[e.point.name];
                this.addSingleSeriesAsDrilldown(e.point, clickedSeries);
                this.addSingleSeriesAsDrilldown(e.point, {
                  type: 'pareto',
                  name: 'Pareto',
                  yAxis: 1,
                  zIndex: 10,
                  baseSeries: clickedSeries.id,
                  tooltip: {
                    valueDecimals: 2,
                    valueSuffix: '%'
                  }
                });
                this.applyDrilldown();
                this.xAxis[0].setCategories(clickedSeries.data.map(d => d[0]));
              } catch { }
            }
          },
          drillup: function () {
            this.xAxis[0].setCategories(undefined);
          }
        }
      },
      annotations: [{
        labelOptions: {
          shape: 'connector',
          align: 'right',
          justify: false,
          crop: true,
          style: {
            fontSize: '0.8em',
            textOutline: '1px white'
          }
        },
        labels: on_render_state.data && on_render_state.data.labels && on_render_state.data.labels.filter(label => label.text)
      }],
      xAxis: {
        type: 'category',
        labels: {
          formatter() {
            let curr_color = '#666666';
            let color_x_axis = on_render_state.data.x_axis_color;
            if (color_x_axis) {
              let index = on_render_state.data.x_axis.indexOf(this.value);
              if (index >= 0) {
                curr_color = color_x_axis[index];
              }
            }

            try {
              return `<span style="color: ${curr_color}">${this.value.constructor == String && this.value.match('2018') ? `${this.value.split('T')[0].split('-')[1]}-${this.value.split('T')[0].split('-')[2]} ${this.value.split('T')[1].split(':')[0]}:${this.value.split('T')[1].split(':')[1]}` : this.value}</span>`
            } catch (error) {
              return `<span style="color: ${curr_color}">${this.value}</span>`
            }
          },
          style: {
            fontSize: `${font_size * 0.7}px`
          }
        }
      },
      yAxis: on_render_state.data.series && on_render_state.data.multipleAxes ?
        on_render_state.data.series.map((serie, index) => ({
          title: {
            text: serie.name && serie.unit ? `${serie.name} (${serie.unit})` : ''
          },
          labels: {
            style: {
              fontSize: `${font_size * 0.7}px`
            }
          },
          floor: 0,
          opposite: index != 0
        }))
        :
        new Array({
          title: {
            text: graphic.grid.h >= 2 ? on_render_state.data.y_name ? on_render_state.data.y_name + ' (' + on_render_state.data.y_unit + ')' : '' : null,
          },
          max: y_max,
          min: y_min,
          // tickInterval: y_interval,
          labels: {
            style: {
              fontSize: `${font_size * 0.7}px`
            }
          }
        }).concat(on_render_state.data.extra_y_axis ?
          {
            ...on_render_state.data.extra_y_axis,
            labels: {
              style: {
                fontSize: `${font_size * 0.7}px`
              }
            }
          } : []),
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true
      },
      legend: {
        floating: graphic.floating_legend != null ? graphic.floating_legend : false,
        enabled: graphic.grid.h > 1 && graphic.grid.w > 2
      },


      tooltip: {
        shared: graphic.sharedTooltip || graphic.grid.h < 2 || graphic.grid.w < 2,
        outside: graphic.grid.h < 2 || graphic.grid.w < 2,
        crosshairs: graphic.grid.h < 2 || graphic.grid.w < 2,
        useHTML: true,
        style: {
          fontSize: `${font_size * 0.9}px`
        }

      },

      plotOptions: {
        column: {
          stacking: on_render_state.data.enable_stacking
        },
        spline: {
          marker: {
            enabled: false,
            symbol: 'circle',
            fillColor: undefined,
            radius: 2
          }
        },
        series: {
          turboThreshold: 1000000,
          cursor: graphic.WtgLink ? 'pointer' : 'arrow',
          ...graphic.WtgLink ? {
            events:
            {
              click: function (event) {
                store.dispatch(goToWTGPage(
                  event.point.category,
                  'Device',
                  30,
                  'REDUCER_DEVICE_GET_DATA'
                ))
                store.dispatch(goToPage('/dashboard/main/device'))
                store.dispatch(changeUpdateWidgets(
                  'Device',
                  30,
                  'REDUCER_DEVICE_GET_DATA'
                ))
              }
            }
          }
            :
            {}
        },
      },
      series: on_render_state.data && on_render_state.data.series ?
        on_render_state.data.series.map((serie, serieIndex) => {
          if (serie.type === 'bar') {

            return {
              color: serie.colors && serie.colors.constructor === Array ? serie.colors[0] : null,
              type: 'column',
              name: serie.name,
              yAxis: on_render_state.data.multipleAxes ? serieIndex : undefined,
              data: serie.values ? serie.values.map((value, index) => ({
                name: on_render_state.data ? on_render_state.data.x_axis[index] : null,
                drilldown: on_render_state && on_render_state.data && on_render_state.data.x_axis && on_render_state.data.drilldown && on_render_state.data.drilldown[on_render_state.data.x_axis[index]],
                x: index,
                y: value,
                id: tooltip_formatter(serie, index),
                color: on_render_state.data.x_axis[index] == 'Average' ?
                  (averageColor || '#80bfff')
                  :
                  (serie.colors ? (serie.colors[index] ? serie.colors[index] : null) : null)
              })
              ) : [],
              tooltip: {
                enabled: !(graphic.disable_tooltip),
                useHTML: true,
                pointFormat: '{point.id}',
                headerFormat: '',
                snap: 500,
                followPointer: true
              }
            }
          }

          if (serie.type === 'line') {
            return {
              color: serie.colors && serie.colors.constructor === Array ? serie.colors[0] : null,
              type: 'spline',
              name: serie.name,
              data: serie.values ? serie.values.map((value, index) => ({
                name: on_render_state.data ? on_render_state.data.x_axis[index] : null,
                x: index,
                y: value,
                id: tooltip_formatter(serie, index)
              })
              ) : [],
              unit: 'unit' in serie ? serie.unit : null,
              zIndex: 'zIndex' in serie ? serie.zIndex : 0,
              yAxis: 'yAxis' in serie ? serie.yAxis : 0,
              tooltip: {
                enabled: !(graphic.disable_tooltip),
                useHTML: true,
                pointFormat: '{point.id}',
                headerFormat: '',
                snap: 500,
                followPointer: true
              },
              marker: {
                enabled: graphic.showMarkers === true,
                lineWidth: 3,
                radius: 4,
                lineColor: serie.colors && serie.colors.constructor === Array ? serie.colors[0] : null,
                fillColor: serie.colors && serie.colors.constructor === Array ? serie.colors[0] : null,
              }
            }
          }

          return null
        })
          .filter(elm => elm)
        : null,
      drilldown: {
        series: []
      }
    }

    const heightContainer = this.props.isAnalysis ? '100%' : 'inherit'
    const heightTable = this.props.isAnalysis ? '50%' : '100%'

    return (
      <div className={classes.flipContainer}>
        {this.state.popUp ? <PagePopUp /> : null}
        <div
          style={{
            transform: showTable || showSettings || showHelp ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}

        >
          <div className={classes.flipContainerFront} style={{ display: showTable || showSettings || showHelp ? 'none' : undefined }}>
            <div style={{ height: heightContainer }}>

              <HighchartsReact
                containerProps={{
                  style: {
                    width: '100%',
                    height: heightTable,
                    overflow: 'hidden',
                    display: showTable ? 'none' : 'block'
                  }
                }}
                ref={this.refChart}
                highcharts={Highcharts}
                // constructorType={'stockChart'}
                options={params}
              />

              {
                this.props.isAnalysis ?

                  <Table style={{ marginTop: 30, height: '50%' }} >
                    <TableHead>
                      <TableRow>
                        <CustomTableCell colSpan={1}></CustomTableCell>
                        {on_render_state.data && on_render_state.data.features ?
                          on_render_state.data.features.map((elm, index) => {
                            if (elm === '0') {
                              return (
                                <CustomTableCell colSpan={1} key={index}>
                                  {''}
                                </CustomTableCell>
                              )
                            }

                            return (
                              <CustomTableCell style={{
                                fontSize: this.caulculateFontSizeHead(graphic.grid),
                                padding: '1px 2px 1px'
                              }} colSpan={1} key={index}>
                                {elm}



                                <Button onClick={this.setFilter.bind(this, { name: elm, index: index })} mini style={{
                                  paddingRight: '30px'
                                }}>
                                  <Icon>arrow_drop_down</Icon>
                                </Button>
                              </CustomTableCell>
                            )
                          }) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sorted_table.map((row, indexRow) => {
                        return (
                          <TableRow className={classes.row} key={indexRow}
                            style={{
                              backgroundColor: `${row[0]}` == 'Average' && `${indexRow}` == `${sorted_table.length - 1}` ? (averageColor || '#80bfff') : null
                            }}>
                            {row.map((cell, indexCell) => {
                              return (
                                <CustomTableCell
                                  style={{
                                    fontSize: this.caulculateFontSizeHead(graphic.grid),
                                    padding: '1px 2px 1px',
                                    height: '2px',
                                    backgroundColor: `${cell}` == 'Average' ? (averageColor || '#80bfff') : null
                                  }} key={indexCell}>
                                  {cell}
                                </CustomTableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>

                  : null
              }


            </div>

          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable || showSettings || showHelp ? undefined : 'none' }}>
            {
              showTable ?
                <Table id="pageAnalysis">
                  <TableHead>
                    <TableRow className={classes.rowHead}>
                      <CustomTableCell></CustomTableCell>
                      {on_render_state.data && on_render_state.data.features ?
                        on_render_state.data.features.map((elm, index) => {
                          if (elm === '0') {
                            return (
                              <CustomTableCell key={index}>
                                {''}
                              </CustomTableCell>
                            )
                          }

                          return (
                            <CustomTableCell
                              style={{
                                fontSize: this.caulculateFontSizeHead(graphic.grid),
                                padding: '1px 2px 1px'
                              }} key={index}>
                              {elm}



                              <Button onClick={this.setFilter.bind(this, { name: elm, index: index })} mini style={{
                                paddingRight: '30px'
                              }}>
                                <Icon>arrow_drop_down</Icon>
                              </Button>
                            </CustomTableCell>
                          )
                        }) : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sorted_table && sorted_table.length && sorted_table.map((row, indexRow) => {
                      return (
                        <TableRow className={classes.rowBody} key={indexRow}
                          style={{
                            backgroundColor: `${row && row.length > 0 && row[0]}` == 'Average' && `${indexRow}` == `${sorted_table.length - 1}` ? (averageColor || '#80bfff') : null
                          }}>
                          {row && row.length && row.map((cell, indexCell) => {
                            return (
                              <CustomTableCell
                                component={indexCell === 0 ? "th" : null}
                                scope={indexCell === 0 ? "row" : null}
                                style={{
                                  fontSize: this.caulculateFontSizeHead(graphic.grid),
                                  //padding: '1px 2px 1px',
                                  //height: '2px',
                                  backgroundColor: `${cell}` == 'Average' ? (averageColor || '#80bfff') : null
                                }} key={indexCell}>
                                {cell}
                              </CustomTableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>

                :

                showSettings ?
                  <List>
                    {
                      // <MenuItem className={classes.applyBtn}>
                      //   <Typography align='center' style={
                      //     {
                      //       width: '100%',
                      //       color: '#ffffff'
                      //     }
                      //   }>Apply Settings</Typography>
                      // </MenuItem>
                    }

                    {BAR_DECORATOR_LIST.map((decorator, index) => (
                      <div key={index}>
                        <ListItem
                          key={decorator.name}
                        >
                          <Switch
                            checked={this.state[`${decorator.name}__switch`]}
                            disabled={this.state[`${decorator.name}__switch_disabled`]}
                            onChange={this.switchToggle(`${decorator.name}__switch`, decorator)}
                            value={decorator.name}
                          />
                          <ListItemText inset primary={`${decorator.name}`} className={classes.liLabel} />
                        </ListItem>
                        <Collapse in={this.state[`${decorator.name}__switch`]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <ListItem>
                              <Grid container spacing={24}>
                                {
                                  decorator.params_input_types.map((param_type, param_index) => {
                                    if (param_type.match('text_field')) {
                                      let type = param_type.split('_').pop();
                                      let text_field_state = `${decorator.name}_text-field-${type}_${decorator.params_names[param_index]}`;
                                      return (
                                        <Grid key={param_index} item xs={2} className={classes.liSubItems}>
                                          <TextField
                                            className={classes.liSubItemsTextArea}
                                            label={`${decorator.params_names[param_index]}`}
                                            value={this.state[text_field_state]}
                                            onChange={this.numberChange(text_field_state)}
                                            type={type}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            disabled={this.state[`${text_field_state}_disabled`]}
                                          />
                                        </Grid>
                                      )
                                    } else if (param_type.match('checkbox')) {
                                      let checkbox_state = `${decorator.name}_checkbox_${decorator.params_names[param_index]}`;
                                      return (
                                        <Grid key={param_index} item xs={2} className={classes.liSubItems}>
                                          <span>{`${decorator.params_names[param_index]}`}</span>
                                          <br />
                                          <Checkbox
                                            checked={this.state[checkbox_state]}
                                            onChange={this.checkboxToggle(checkbox_state)}
                                            value={decorator.params_names[param_index]}
                                            disabled={this.state[`${checkbox_state}_disabled`]}
                                          />
                                        </Grid>
                                      )
                                    }
                                  })
                                }
                              </Grid>
                            </ListItem>
                          </List>
                        </Collapse>
                        <Divider />
                      </div >
                    ))}
                  </List>

                  :

                  showHelp ?

                    <h1 style={{
                      width: '100%',
                      height: 'auto',
                      textAlign: 'center'
                    }}> HELP!!! </h1>

                    :

                    <NoPrint>
                      <h1 style={{
                        width: '100%',
                        height: 'auto',
                        textAlign: 'center'
                      }}>Returning to Chart...</h1>
                    </NoPrint>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicBar);
