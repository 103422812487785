import React, {Component} from 'react';
import * as R from 'ramda';

const Order = (order='DESC', ordered_by=null) => {
  const real_Order = state => {
    state.data_without_order = JSON.parse(JSON.stringify(state.data));

    try {
      let data = JSON.parse(JSON.stringify(state.data)); //the most efficient deep copy
      let series = Object.assign([], data.series);
      let x_axis = Object.assign([], data.x_axis);
      let series_values = series.map(element => {
        let serie = {};
        serie[element.name] = {
          values: element.values,
          tooltip: element.tooltip
        };
        return serie;
      }).reduce((acc, curr) => ({...acc, ...curr}));

      let series_correctness = Object.entries(series_values).reduce((acc, curr, index, array) => index == array.length - 1 && acc != false ? acc[1].values.length == curr[1].values.length : curr);
      if(!series_correctness) {throw 'Values does not match X Axis';}

      let serie_average_values = [];
      let serie_average_tooltip = [];

      let avg_pos = x_axis.indexOf('Average');
      if(avg_pos >= 0) {
        x_axis = x_axis.filter(value => value != 'Average');
        Object.keys(series_values).map(key => {
            serie_average_values = serie_average_values.concat(series_values[key].values.filter((value, index) => index == avg_pos));
            series_values[key].values = series_values[key].values.filter((value, index) => index != avg_pos);

            if(series_values[key].tooltip) {
              serie_average_tooltip = serie_average_tooltip.concat(series_values[key].tooltip.filter((value, index) => index == avg_pos));
              series_values[key].tooltip = series_values[key].tooltip.filter((value, index) => index != avg_pos);
            } else {
              serie_average_tooltip = serie_average_tooltip.concat(null);
              series_values[key].tooltip = null;
            }
        });
      }

      let sort_function = null;

      if (order == 'DESC') {
        sort_function = (value1, value2) => value2 - value1;
      } else if (order == 'ASC')
        sort_function = (value1, value2) => value1 - value2;
      else {
        throw 'No valid ordering rule';
      }

      if(!ordered_by) {ordered_by = Object.keys(series_values)[0];}
      let before_sort = Object.assign([], series_values[ordered_by].values);
      series_values[ordered_by].values = series_values[ordered_by].values.sort(sort_function);

      const sort_order = series_values[ordered_by].values.map(item => {
        let index = before_sort.indexOf(item);
        before_sort[index] = null;
        return index;
      });

      // 
      // ;
      // ;

      x_axis = sort_order.map(index => x_axis[index]);

      Object.keys(series_values).map((key, index) => {
          if(key != ordered_by) {
              series_values[key].values = sort_order.map(index => series_values[key].values[index]);
          }

          if(series_values[key].tooltip) {
            series_values[key].tooltip = sort_order.map(index => series_values[key].tooltip[index]);
          }
      });

      if(serie_average_values.length > 0 && serie_average_tooltip.length > 0) {
          x_axis = x_axis.concat('Average');
          Object.keys(series_values).map((key, index) => {
            series_values[key].values = series_values[key].values.concat(serie_average_values[index]);
            if(series_values[key].tooltip) {
              series_values[key].tooltip = series_values[key].tooltip.concat(serie_average_tooltip[index]);
            }
          });
      } else {
        throw 'Error mounting average serie';
      }

      data.x_axis = x_axis;
      data.device_list = x_axis;

      data.series.map(element => {
        element.tooltip = series_values[element.name].tooltip;
        element.values = series_values[element.name].values;
      });

      state.data = JSON.parse(JSON.stringify(data));

    } catch (e) {
      ;
      state.data = JSON.parse(JSON.stringify(state.data_without_order));
      delete state.data_without_order;
    } finally {
      // ;
      // ;
      return (state);
    }
  };

  return(real_Order);
};

export default Order;
