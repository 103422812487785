import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps"
import { injectGlobal } from 'styled-components'
import * as R from 'ramda'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import getStatusColorRealtime, { getStatusByTag } from '../../../../../../../../helpers/get-status-color-realtime';
import { ROUTE_MAIN_DEVICE } from '../../../../../../../../constants/route-constant';

injectGlobal`
  a[href^="http://maps.google.com/maps"]{display:none !important}
  a[href^="https://maps.google.com/maps"]{display:none !important}

  .gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
  }


  table.infowindowtable {
    border-collapse: collapse;
    width: 100%;

    th, td {
      text-align: left;
      padding: 8px;
    }
    
    tr:nth-child(even) {background-color: #f2f2f2;}
  }
`

class GoogleMapItemComponent extends React.Component {
  constructor(props) {
    super(props)

    this.map = React.createRef()
    this.state = {
      infoWindowList: {}
    }
  }

  render() {
    const { items, defaultZoom = 15 } = this.props

    const wtgs = R.flatten(items.map(subpark => subpark.wtgs))


    return (
      <GoogleMap
        ref={this.map}
        defaultZoom={defaultZoom}
        defaultCenter={{
          lat: this.props.center.latitude,
          lng: this.props.center.longitude
        }}
        defaultOptions={{
          styles: [],
          mapTypeControl: false,
          rotateControl: false,
          scaleControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.TOP_RIGHT
          },
          streetViewControl: false,
          panControl: false,
          disableDefaultUI: true,
          mapTypeId: 'satellite'
        }}
      >
        {_.sortBy(wtgs, ['name']).map((item, index) => {
          const powerTag = R.find(R.propEq('var_id', 1))(item.realtime)
          const power = powerTag ? powerTag.value : ''

          const maintenanceTag = R.find(R.propEq('var_id', 4))(item.realtime)
          const maintenance = maintenanceTag ? maintenanceTag.value_bool : ''

          const stateTag = R.find(R.propEq('var_id', 1000))(item.realtime) || R.find(R.propEq('var_id', 3))(item.realtime)
          const state = stateTag ? stateTag.value : ''

          const windTag = R.find(R.propEq('var_id', 2))(item.realtime)
          const wind = windTag ? windTag.value : ''

          const powerLimitation = item.realtime.filter(R.propEq('is_alarm', true)).some(({ value_bool }) => value_bool)

          let status = {}

          if (stateTag && stateTag.value_string) {
            status = getStatusByTag(stateTag.value_string)
          } else {
            status = getStatusColorRealtime(state, item.model_id, maintenance, powerLimitation)
          }

          return (
            <Marker
              key={index}
              icon={{
                url: status.icon,
                labelOrigin: new window.google.maps.Point(12, 50)
                // size: {
                //   width: 100,
                //   height: 100
                // }
              }}
              label={{
                color: '#fff',
                fontSize: '12px',
                fontWeight: "bold",
                text: item.name,
              }}
              position={{
                lat: item.latitude,
                lng: item.longitude
              }}
              onClick={() => {
                this.setState(state => ({
                  infoWindowList: {
                    ...state.infoWindowList,
                    [item.id]: true
                  }
                }))
              }}
            >
              {
                this.state.infoWindowList[item.id]
                  ?
                  (
                    <InfoWindow
                      onCloseClick={() => {
                        this.setState(state => ({
                          infoWindowList: {
                            ...state.infoWindowList,
                            [item.id]: false
                          }
                        }))
                      }}
                    >
                      <div
                        style={{
                          padding: 10,
                          backgroundColor: 'white',
                          color: 'black'
                        }}
                      >
                        <table
                          className="infowindowtable"
                        >
                          <tbody>
                            <tr>
                              <td>
                                Turbine:
                            </td>
                              <td>
                                <Link
                                  to={`${ROUTE_MAIN_DEVICE}?wtgs=[${item.id}]`}
                                  style={{
                                    textDecoration: 'none'
                                  }}
                                >
                                  {item.name}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Status:
                            </td>
                              <td>
                                {`${status.name} (${state || ''})`}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Power (kW):
                            </td>
                              <td>
                                {Number.isNaN(power) && power ? 0.0 : (Number(power)).toFixed(1)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Wind (m/s):
                            </td>
                              <td>
                                {Number.isNaN(wind) && wind ? 0.0 : (Number(wind)).toFixed(1)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </InfoWindow>
                  )
                  :
                  null
              }
            </Marker>
          )
        })}
      </GoogleMap>
    )
  }
}

const MapWithAMarker = withScriptjs(withGoogleMap(GoogleMapItemComponent))

class OPCMapComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <MapWithAMarker
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPJbD4u-Cm7JZW6jXb2BZvfrZxhfJCCUw&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ minHeight: `720px` }} />}
          containerElement={<div style={{ minHeight: `720px` }} />}
          mapElement={<div style={{ minHeight: `720px` }} />}
          {...this.props}
        />
      </React.Fragment>
    )
  }
}

export default OPCMapComponent
