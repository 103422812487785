import React, {Component} from 'react'
import moment from 'moment-timezone'
import axios from 'axios'
import {Auth} from 'aws-amplify'

import io from 'socket.io-client'
import { URL_NODE_API } from '../../../constants/url-constant'

import {
    IconButton,
    Icon
  } from '@material-ui/core'

import FlipContainer from '../../Container/FlipContainer'
import BlinkTable from '../../Table/BlinkTable'
import VariablesSelector from '../../../modules/Dashboard/pages/HydroPage/pages/HydroMonitorComponent/components/VariablesSelector'

export default class GraphicRealTimeTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            error_ocurred: false,
            to_variables: false,

            FrontButtonsTop: '2%',
            FrontButtonsRight: '5%',
            BackButtonsTop: '2%',
            BackButtonsRight: '5%',

            data: null,
            rows_changed: null,
            last_update: null
        }

        this.parks_info = null
    }

    getIcons() {
        return (
            {
                stop: (<Icon style={{fontSize: 16, color: 'red'}}>stop</Icon>), 
                warning: (<Icon style={{fontSize: 16, color: 'yellow'}}>stop</Icon>),
                ok: (<Icon style={{fontSize: 16, color: 'red'}}>play_arrow</Icon>),
                '-----': (<Icon style={{fontSize: 16, color: 'black'}}>report_problem</Icon>)
            }
        )
    }

    toggleVariablesView() {
        let actual = this.state.to_variables
        this.setState({
          to_variables: !actual
        })
    }

    getFrontIcons() {
        const {classes} = this.props
        return (
            [
                <IconButton 
                    aria-label="Select Variables" 
                    className={classes.margin}
                    onClick={this.toggleVariablesView.bind(this)}
                    style={{width: 32, height: 32}}
                >
                    <Icon style={{fontSize: 16}}>settings</Icon>
                </IconButton>
            ]
        )
    }
    
    getBackIcons() {
        const {classes} = this.props
        return (
            [
                <IconButton 
                    aria-label="Accept and Back to Table" 
                    className={classes.margin}
                    onClick={this.toggleVariablesView.bind(this)}
                    style={{width: 32, height: 32}}
                >
                    <Icon style={{fontSize: 16}}>done</Icon>
                </IconButton>,

                <IconButton 
                    aria-label="Reject and Back to Table" 
                    className={classes.margin}
                    onClick={this.toggleVariablesView.bind(this)}
                    style={{width: 32, height: 32}}
                >
                    <Icon style={{fontSize: 16}}>clear</Icon>
                </IconButton>
            ]
        )
    }

    componentDidMount() {
        const { procedure_name, payload_id, data_type, subpark_ids, wtgs_ids } = this.props

        Auth.currentSession()
            .then((data) => {
                const {jwtToken} = data.accessToken
                
                const url = `${process.env.REACT_APP_API_1}/wind-monitor`

                const socket = io(url, {
                    query: {
                        token: jwtToken
                    }
                })

                socket.on('connect', (data) => {
                })

                const parks_url = 'https://delfos-api-1.delfosim.com/v1/api/user/parks'

                axios.get(parks_url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': jwtToken
                    }
                })
                .then(({data}) => {
                    const {parks_info} = data

                    parks_info.forEach(park => {
                        park.subparks.forEach(subpark => {
                            if(subpark_ids == null || subpark_ids==subpark.id) {
                                socket.on(`subpark#${park.id}#${subpark.id}`, (data) => {
                                    if(data.data && data.data.constructor == Array) {
                                        const actual_data = this.state.data
                                        const incomming_data = data.data

                                        // const merged_data = actual_data != null && actual_data.header != null && actual_data.rows != null ?
                                        //     {
                                        //         header: incomming_data.header,
                                        //         rows: actual_data.rows.concat(
                                        //             incomming_data.rows.filter((row, index) => actual_data.rows[index].length == row.length)
                                        //         )
                                        //     }
                                        // :
                                        //     incomming_data

                                        this.setState({
                                            data: this.formatDataByType({actual_data, incomming_data}, data_type),
                                            last_update: moment()
                                        })
                                        // this.formatDataByType(data, data_type),
                                    }
                                })
                            }
                        })
                    })
                })
                .catch(error => console.error('/user/parks error', error))

                // console.log(`${procedure_name}#${payload_id}`)

                // socket.on(`${procedure_name}#${payload_id}`, (data) => {
                //     console.log(`ON SOCKET ${procedure_name}#${payload_id}`, data)
                //     this.setState({
                //         data: this.formatDataByType(data, data_type),
                //         last_update: moment()
                //     })
                // })
                
                socket.on('connect_error', data => console.log('connect_error', data))
                socket.on('connect_timeout', data => console.log('connect_timeout', data))
                socket.on('connecting', data => console.log('connecting', data))
                socket.on('disconnect', data => console.log('disconnect', data))
                socket.on('error', data => console.log('error', data))
                socket.on('reconnect', data => console.log('reconnect', data))
                socket.on('reconnect_attempt', data => console.log('reconnect_attempt', data))
                socket.on('reconnect_failed', data => console.log('reconnect_failed', data))
                socket.on('reconnect_error', data => console.log('reconnect_error', data))
                socket.on('reconnecting', data => console.log('reconnecting', data))
                socket.on('ping', data => console.log('ping', data))
                socket.on('pong', data => console.log('pong', data))
            })
            .catch(error => console.error('auth', error))

        // Auth.currentSession()
        //     .then(({accessToken}) => {
        //         const {jwtToken} = accessToken

        //         return axios.get(`${process.env.REACT_APP_API_1}/v1/api/pch/get-data`, {
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': jwtToken
        //             }
        //         })
        //         .then(({data}) => this.setState({plantsInfo: data}))
        //     })
        //     .catch(error => console.error('error', error))
    }
    
    componentDidCatch(error, info) {
        const error_obj = JSON.parse(error.message)
        console.groupCollapsed('Error Catched')
        console.log(error.stack)
        console.groupEnd()
        
        if(error_obj.on_table) {
            this.setState({
                error_ocurred: true
            })
        }
    }
    
    checkListNull(some_list) {
        if(some_list == null) { return true }
        return some_list.map(value => value == null).reduce((acc, curr) => acc && curr)
    }
    
    formatDataByType(data, data_type) {
        // console.log('ON FORMATTING DATA', data, data_type)
        switch(data_type) {
            case 1:
                return (
                    {
                        header: ['Variable Name', 'Value'],
                        rows: !this.checkListNull(data) ? 
                                data.map((data, index) => (
                                    !this.checkListNull(data.device_name, data.data) && !this.checkListNull(data.data.power) ?
                                        [
                                            data.device_name, 
                                            data.data.power.toFixed(1)
                                        ]
                                    :
                                        null
                                )).filter(row => row != null)
                            :
                                [[]]
                    }
                )

            case 2:
                return (
                    {
                        header: !this.checkListNull(data.columns) ? data.columns : [],
                        rows: !this.checkListNull(data) && !this.checkListNull(data.rows) ?
                                data.rows.map((row, index) => (
                                    !this.checkListNull(row.values) ?
                                        [
                                            row.values[0], 
                                            moment(row.values[1]).format('YYYY-MM-DD HH:mm:ss')
                                        ]
                                    :
                                        null
                                )).filter(row => row != null)
                            :
                                [[]]
                    }
                )

            case 3:
                const { wtgs_ids } = this.props
                const { actual_data, incomming_data } = data

                console.log(incomming_data)

                const devices_names_in = Array.from(new Set(incomming_data.map(row => row.device_name)))
                
                return (
                    {
                        header: ['Device Name', 'Status', 'Wind Speed', 'Active Power', 'Last Update'],
                        rows: !this.checkListNull(incomming_data) ?
                            actual_data ? actual_data.rows.filter(row => devices_names_in.indexOf(row[0]) >= 0) : [].concat(
                                    incomming_data.map((row, index) => (
                                        (row.data != null && 
                                        (wtgs_ids == null || wtgs_ids.constructor != Array || 
                                        (wtgs_ids.constructor == Array && wtgs_ids.indexOf(row.device_id) >= 0))) ?
                                            [
                                                row.device_name,
                                                row.data.status,
                                                row.data.wind_speed,
                                                row.data.power,
                                                row.data.sample_time
                                            ]
                                        :
                                            null
                                    ))
                                    .filter(row => row != null)
                                ).sort((a, b) => a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0)
                            :
                                [[]]
                    }
                )

            default:
                return data
        }
    }
    
    getRowsChanges(curr, next) {
        next.rows.map((row, index) => (
            curr.length > index ?
                row.map((cell, cell_index) => cell != curr.rows[index][cell_index]).reduce((acc, curr) => acc || curr)
            :
                true
        ))
    }
    
    componentWillUpdate(nextProps, nextState) {
        const out_update = nextProps.last_update
        const in_update = this.state.last_update
        
        const { data_type } = nextProps

        if(out_update && out_update > in_update) {
            nextState.data = this.formatDataByType(this.props.data, data_type)
        } else {
            let curr_data = this.state.data
            let next_data = nextState.data

            // console.log('ON Will Update', curr_data, next_data)
            if(curr_data && next_data) {
                nextState.data = next_data
                nextState.rows_changed = this.getRowsChanges(curr_data, next_data)
            }
        }

        // console.log('Incomming data', nextState.data)
    }

    render() {
        const { classes, payload_id } = this.props

        return (
            !this.state.error_ocurred ?
                <FlipContainer
                    FrontButtonsTop={this.state.FrontButtonsTop}
                    FrontButtonsRight={this.state.FrontButtonsRight}
                    BackButtonsTop={this.state.BackButtonsTop}
                    BackButtonsRight={this.state.BackButtonsRight}

                    FrontButtons={this.getFrontIcons.bind(this)}
                    BackButtons={this.getBackIcons.bind(this)}

                    Front={
                        <BlinkTable
                            table_data={this.state.data} 
                            table_colored_rows={this.state.rows_changed} 
                            fontsize_header={10}
                            fontsize_rows={8}
                            icons={this.getIcons()}
                            column_types={this.props.column_types}
                        />
                    }

                    Back={
                        <VariablesSelector
                            plantsInfo={this.state.plantsInfo} 
                            plant_id={payload_id}
                        />
                    }

                    change_side={this.state.to_variables}
                />
            :
              <p>Sorry, this table is broken</p>
        )
    }
}