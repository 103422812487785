import {Auth} from 'aws-amplify'
import axios from 'axios'
import {store} from '../store/ProviderStore'
import {URL_API} from '../constants/url-constant'

export const sendTablesAlarms = async (old_tables, new_tables) => {
  const curr_session = await Auth.currentSession()
  const jwtToken = 'accessToken' in curr_session && 'jwtToken' in curr_session.accessToken ?
      curr_session.accessToken.jwtToken
    :
      null

  const URL = URL_API + '/crud_status/status_save'

  const payload = {
    old: old_tables,
    new: new_tables
  }

  const {status} = await axios.post(URL, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return status >= 200 && status < 300
}

export const sendTablesDowntimes = async (old_tables, new_tables) => {
  const curr_session = await Auth.currentSession()
  const jwtToken = 'accessToken' in curr_session && 'jwtToken' in curr_session.accessToken ?
      curr_session.accessToken.jwtToken
    :
      null

  const URL = URL_API + '/crud_status/downtime_save'

  const payload = {
    old: old_tables,
    new: new_tables
  }

  const {status} = await axios.post(URL, payload, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return status >= 200 && status < 300
}

export const executeBaseRoutine = async (routine_name, params) => {
  const curr_session = await Auth.currentSession()
  const jwtToken = curr_session.accessToken.jwtToken

  const URL = URL_API + '/crud_status/' + routine_name

  const {status} = await axios.post(URL, params, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return status >= 200 && status < 300
}
