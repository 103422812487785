import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import styles from './styles/BaseSelectorStyles';

const COL_WIDTH = {
    width: 'calc(100% / 2)'
};

class ScadaList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scadas: null,
            selectAll: false
        };
    }

    componentDidMount() {
        const { fullScadaList } = this.props;

        if(fullScadaList) {
            const scadas = Object.values(fullScadaList).map(scada => ({
                [scada.id]: {
                    ...scada,
                    selected: false,
                    enabled: false
                }
            })).reduce((acc, curr) => ({...acc, ...curr}));
    
            this.setState({
                scadas
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { scadas } = this.state;
        const { retrieveData, selectedDevices, scadaIdsByDevice } = this.props;

        if (JSON.stringify(scadas) !== JSON.stringify(prevState.scadas)) {
            retrieveData({ 
                scadas: Object.values(scadas).filter(scada => scada.selected)
                    .map(scada => scada.id)
            });
        }

        if (JSON.stringify(selectedDevices) !== JSON.stringify(prevProps.selectedDevices)) {
            try {
                const enabledScadaIdsArray = [...new Set(selectedDevices.map(deviceId => scadaIdsByDevice[deviceId]))];
                const enabledScadaIds = enabledScadaIdsArray.length > 0 ? enabledScadaIdsArray.reduce((acc, curr) => [...acc, ...curr]) : [];                
                    
                this.setState(prevState => {
                    const scadasArray = Object.values(prevState.scadas);

                    return {
                        scadas: {
                            ...scadasArray.map(scada => ({
                                [scada.id]: {
                                    ...scada,
                                    selected: false,
                                    enabled: enabledScadaIds.indexOf(scada.id) >= 0
                                }
                            })).reduce((acc, curr) => ({...acc, ...curr}))
                        }
                    };
                });
            } catch (err) {
                console.log('Error on enabling scadas: ', err.toString())
            }
        }
    }

    handleClick = scadaId => event => {
        this.setState(prevState => ({
            selectAll: false,
            scadas: {
                ...prevState.scadas,
                [scadaId]: {
                    ...prevState.scadas[scadaId],
                    selected: prevState.scadas[scadaId].enabled && 
                        !prevState.scadas[scadaId].selected
                }
            }
        }));
    }

    handleClickAll = event => {
        setTimeout(() => this.setState({selectAll: false}), 300);
        this.setState(prevState => ({
            selectAll: !prevState.selectAll,
            scadas: Object.values(prevState.scadas).map(scada => ({
                [scada.id]: {
                    ...scada,
                    selected: scada.enabled && !prevState.selectAll
                }
            })).reduce((acc, curr) => ({...acc, ...curr}))
        }));
    }

    render () {
        const { classes } = this.props;
        const { scadas, selectAll } = this.state;

        const scadasAmt = scadas && Object.values(scadas).length;

        return (
            <Paper className={classes.root}>
                {
                    scadas && (
                        <div className={classes.table} >
                            <List 
                                className={classes.column}
                                style={COL_WIDTH}
                            >
                                <ListItem
                                    key={`download-scadas-action-header`}
                                    style={{
                                        textAlign: 'center'
                                    }}
                                    className={classes.rowContent}
                                >
                                    <ListItemText 
                                        primary='Actions'
                                    />
                                </ListItem>
                                <ListItem
                                    key='download-scadas-action'
                                    align='left'
                                    style={{
                                        height: '85%'
                                    }}
                                    className={classes.rowContent}
                                    disabled={
                                        !Object.values(scadas).map(scada => scada.enabled)
                                            .reduce((acc, curr) => acc || curr)
                                    }
                                >
                                    <IconButton
                                        classes={{
                                            root: classes.selectBt
                                        }}
                                        onClick={this.handleClickAll}
                                    >
                                        <Icon
                                            classes={{
                                                root: classes.smallBt
                                            }}
                                        >
                                            {
                                                selectAll ? 'radio_button_checked' : 'radio_button_unchecked'
                                            }
                                        </Icon>
                                    </IconButton>
                                    <ListItemText 
                                        classes={{
                                            root: classes.cellLeafText,
                                            primary: classes.cellLeafText
                                        }}
                                        primary='Select All' 
                                    />
                                </ListItem>
                            </List> 
                            <List 
                                className={classes.column}
                                style={COL_WIDTH}
                            >
                                <ListItem
                                    key={`download-scadas-header`}
                                    style={{
                                        textAlign: 'center'
                                    }}
                                    className={classes.rowContent}
                                >
                                    <ListItemText 
                                        primary='Scada' 
                                    />
                                </ListItem>
                                {
                                    Object.values(scadas).map(scada => (
                                        <ListItem
                                            key={`scada-${scada.id}`}
                                            align='left'
                                            className={classes.rowContent}
                                            disabled={!scada.enabled}
                                        >
                                            <IconButton
                                                classes={{
                                                    root: classes.selectBt
                                                }}
                                                onClick={this.handleClick(scada.id)}
                                            >
                                                <Icon
                                                    classes={{
                                                        root: classes.smallBt
                                                    }}
                                                >
                                                    {
                                                        scada.selected ? 'check_box' : 'check_box_outline_blank'
                                                    }
                                                </Icon>
                                            </IconButton>
                                            <ListItemText 
                                                classes={{
                                                    root: classes.cellLeafText,
                                                    primary: classes.cellLeafText
                                                }}
                                                primary={scada.name} 
                                            />
                                        </ListItem>
                                    ))
                                }
                            </List>                   
                        </div>
                    )
                }
            </Paper>
        );
    }
}

export default withStyles(styles)(ScadaList);