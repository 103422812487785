import { keyframes } from 'styled-components';

const styles = theme => ({
    root: {
        width: '100%',
        // overflowY: 'auto'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        zIndex: '999999999 !important'
    },
    popperContainer: {
        zIndex: 999999999
    },
    textField: {
        flex: 1
    },
    commentBt: {
        height: 'inherit',
        textAlign: 'center',
        verticalAlign: 'middle'
    },
    horizontalAlign: {
        display: 'inline-flex',
        width: '100%',
        textAlign: 'center',
        justifyContent: 'space-around'
    },
    verticalAlign: {
        display: 'inline-flex',
        width: '100%',
        textAlign: 'center',
        flexDirection: 'column'
    },
    lateralPaggingSM: {
        padding: '0 2vw 0 2vw'
    },
    verticalDivider: {
        width: '1px !important',
        backgroundColor: '#666 !important',
        height: '100% !important'
    },
    transparentBg: {
        opacity: .5,
        pointerEvents: 'none'
    },
    sliderRoot: {
        width: '75%',
        display: 'flex',
        alignItems: 'center',
        margin: '8vh 0 4vh 12.5%'
    },
    sliderThumbLabel: {
        marginTop: '-5%'
    },
    sliderThumbContainer: {
        display: 'block',
        verticalAlign: 'middle',
        textAlign: 'center',
        marginTop: '-2vh',
        width: 'auto',
        height: 'auto',
        overflow: 'visible'
    },
    slider: {
        width: 'auto !important',
        height: 'auto !important'
    }
});

export default styles;