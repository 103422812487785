import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/ActionStyle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import './styles/ExtraStyles.css';
import {
    SELECT_CHOICES
} from './placeholder-data/FixedDataFromAPI';
import { withTranslation } from 'react-i18next';


class SelectorInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            selectedOption: -1,
            choices: []
        }
    }

    handleChange = event => {
        this.setState({
            selectedOption: Number(event.target.value)
        });
    };

    handleToggle = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedOption !== this.state.selectedOption) {
            const {
                sendData,
                selector,
                deviceAlarms = SELECT_CHOICES.alarm,
                deviceClassifications = SELECT_CHOICES.classification,
                deviceResponsibilities = SELECT_CHOICES.responsibility,
                deviceSystems,
                side
            } = this.props;

            const { selectedOption, choices } = this.state;

            const selectedValue = choices.length > 0 && selectedOption >= 0 ? selectedOption : '';
            const selectedName = choices[selectedValue];

            const selectedAlarm = deviceAlarms[selectedOption];
            const selectedClassification = deviceClassifications[selectedOption];
            const selectedResposibility = deviceResponsibilities[selectedOption];
            const selectedSystem = deviceSystems[selectedOption]

            const selectedChoice = (selector.id === 'alarm' && selectedAlarm && String(selectedAlarm.id)) ||
                (selector.id === 'system' && selectedSystem && String(selectedSystem.id)) ||
                (selector.id === 'classification' && selectedClassification && String(selectedClassification.id)) ||
                (selector.id === 'responsibility' && selectedResposibility && String(selectedResposibility.id)) ||
                null;

            sendData({ [`${side != null ? `${side}_` : ''}${selector.id}`]: Number(selectedChoice) });
        }
    }

    componentDidMount() {
        const {
            selector,
            selectedRows,
            actionType,
            deviceAlarms = SELECT_CHOICES.alarm,
            deviceClassifications = SELECT_CHOICES.classification,
            deviceResponsibilities = SELECT_CHOICES.responsibility,
            deviceSystems
        } = this.props;

        const choices = (selector.id === 'alarm' && deviceAlarms.map(alarm => alarm.name)) ||
            (selector.id === 'system' && deviceSystems.map(system => system.name)) ||
            (selector.id === 'classification' && deviceClassifications.map(classification => classification.name)) ||
            (selector.id === 'responsibility' && deviceResponsibilities.map(responsibility => responsibility.name)) ||
            [];

        const preSelectedOption = choices.length > 0 &&
            ((actionType === 'edit' || actionType === 'merge' || actionType === 'split') && choices.length > 0 && choices.indexOf(selectedRows[0][selector.id]));

        const selectedOption = preSelectedOption !== false ? preSelectedOption : this.state.selectedOption;

        this.setState({
            choices,
            selectedOption
        });
    }

    render() {
        const {
            classes,
            selector,
            t
        } = this.props;

        const { open, selectedOption, choices } = this.state;

        const selectTitle = `select-title-${selector.type}${selector.id}`;
        const selectedValue = choices.length > 0 && selectedOption >= 0 ? selectedOption : '';
        const selectedName = choices[selectedValue];

        return (
            <React.Fragment>
                <FormControl classes={{
                    root: classes.formControl
                }}
                >
                    <InputLabel htmlFor={selectTitle}>
                        {t(selector.name)}
                    </InputLabel>
                    <Select
                        open={open}
                        onClose={this.handleToggle}
                        onOpen={this.handleToggle}
                        value={selectedValue}
                        onChange={this.handleChange}
                        inputProps={{
                            name: t(selector.name),
                            id: selectTitle,
                        }}
                    >
                        {
                            choices.map((value, index) => (
                                <MenuItem value={index}>{t(value)}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    }
};

export default withTranslation()(withStyles(styles)(SelectorInput));