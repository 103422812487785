import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import moment from 'moment-timezone'
import {
  withStyles,
  Button
} from '@material-ui/core'
import GridLayout from 'react-grid-layout'
import * as R from 'ramda'
import GraphicBarHydro2 from '../../../../../../components/GraphicModel/GraphicBar/GraphicBarHydro2';
import GraphicGaugeHydro from '../../../../../../components/GraphicModel/GraphicGauge/GraphicGaugeHydro';
import GraphicLevelFluency from '../../../../../../components/GraphicModel/GraphicLevelFluency/GraphicLevelFluency';
import GraphicInteractiveTableHydro from '../../../../../../components/GraphicModel/GraphicInteractiveTable/GraphicInteractiveTableHydro';
import GraphicInteractiveTableHydro2 from '../../../../../../components/GraphicModel/GraphicInteractiveTable/GraphicInteractiveTableHydro2';
import GraphicInteractiveTableHydro3 from '../../../../../../components/GraphicModel/GraphicInteractiveTable/GraphicInteractiveTableHydro3';
import io from 'socket.io-client'
import {Auth} from 'aws-amplify'
import { URL_WEBSOCKET_API } from '../../../../../../constants/url-constant';



import GraphicHydro from '../../../../../../components/GraphicModel/Hydro/GraphicHydro';
import GraphicWidget from '../../../../../../components/GraphicModel/GraphicWidget'

const PROCEDURE_NAMES = [

  ['series_monthly_availability', [1, 2]],
  ['series_history_sumulative_generation_data_24h', [1, 2]],
  ['table_alarm_data2', [1, 2]],
  ['table_hydro_analysis', [1, 2]],
  ['box_analog_nivelmontante', [1, 2]],
  ['series_history_fluence_data_24h', [1, 2]],
  ['power_gauge_with_references', [1, 2]],
  ['series_history_actpower_data_24h', [1, 2]],
  ['table_history_act_power_accumulation_over_the_year', [1, 2]]


]





const GRID_WIDTH_PADDING = 10

const styles = theme => ({
  gridElement: {
    width: '100%'
  }
})

class HydroMainComponent extends Component {
  constructor (props) {
    super (props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    this.state = {
      gridWidth: elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),
      subpark:1,
      socket: null,
      'gridWidth': elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),

    }

    this.gridContainerAnchor = React.createRef()
    this.currentDateRange = null
    this.prevManagementId = null
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName('body')[0]

    this.setState({
      gridWidth: body.offsetWidth - (GRID_WIDTH_PADDING * 2)
    })
  }

  componentWillUnmount(){

    if(this.state.socket) {
      this.state.socket.disconnect()
      this.setState({socket: null})
      window.removeEventListener('resize', this.resizePageWatch)
      clearInterval(this.interval);


    }
  }

  checkData(updatedData, jwtToken){

    if (!updatedData) {


          const url = `${URL_WEBSOCKET_API}/monitor`

          const socket = io(url, {
            query: {
              token: jwtToken
            }
          })


         PROCEDURE_NAMES.forEach(([procedure_name, ids]) => {
              ids.forEach((id) => {
                socket.on(`${procedure_name}#${id}`, (data) => {
                  updatedData = true


                    console.log(`${procedure_name}#${id}`);

                  this.setState({
                    [`${procedure_name}#${id}`]: data,
                    [`${procedure_name}#${id}_update`]: moment()
                  })
                })
              })
            })
            

          socket.io.connect();

          this.setState({socket})


    }

  }

  clickPark(park_id, event) {
    this.setState({
      subpark: park_id
    })
  }


  componentDidMount() {
    window.addEventListener('resize', this.resizePageWatch)
    let updatedData = false;

    Auth.currentSession()
      .then((data) => {
        const {jwtToken} = data.accessToken
        
        const url = `${URL_WEBSOCKET_API}/monitor`

        const socketIo = io(url, {
          query: {
            token: jwtToken
          }
        })

        socketIo.io.connect();


        this.setState({socket:socketIo})

        const socket = this.state.socket;

        socket.on('connect', (data) => {
          console.log('connect', data)
        })

        PROCEDURE_NAMES.forEach(([procedure_name, ids]) => {
          ids.forEach((id) => {
            socket.on(`${procedure_name}#${id}`, (data) => {
              updatedData = true

              this.setState({
                [`${procedure_name}#${id}`]: data,
                [`${procedure_name}#${id}_update`]: moment()
              })
            })
          })
        })
        

       this.interval = setInterval(() => {
        this.checkData(updatedData, jwtToken);    
        }, 5000)

        socket.on(`ug_is_available`, (data) => {

          this.setState({
            [`ug_is_available`]: data,
            [`ug_is_available_update`]: moment()
          })
        })



        socket.on('connect_error', data => console.log('connect_error', data))
        socket.on('connect_timeout', data => console.log('connect_timeout', data))
        socket.on('connecting', data => console.log('connecting', data))
        socket.on('disconnect', data => console.log('disconnect', data))
        socket.on('error', data => console.log('error', data))
        socket.on('reconnect', data => console.log('reconnect', data))
        socket.on('reconnect_attempt', data => console.log('reconnect_attempt', data))
        socket.on('reconnect_failed', data => console.log('reconnect_failed', data))
        socket.on('reconnect_error', data => console.log('reconnect_error', data))
        socket.on('reconnecting', data => console.log('reconnecting', data))
        socket.on('ping', data => console.log('ping', data))
        socket.on('pong', data => console.log('pong', data))
    });



  }



  render () {
    const {classes, Management, parks, history, widgets} = this.props
 

    return (
        <div
          style={{
            maxHeight: '100%',
            width: this.state.gridWidth,
            margin: '0 auto'
          }}
        >

          <div
            style={{marginTop: 8, display: 'flex',            
            backgroundColor:'white'}}
          >
            <div style={{flex: 5}}></div>
            <h4 style={{flex: 1, textAlign: 'right', color: 'white'}}>Power Plants</h4>
            <Button 
              style={{
                flex: 1, 
                backgroundColor: this.state.subpark == 1 ? 'orange' : '#d9d9d9', 
                color: this.state.subpark == 1 ? 'white' : 'black',
                margin: 8,
                marginRight: 0
              }}
              onClick={this.clickPark.bind(this, 1)}
            >
              Pipoca            
            </Button>
          
            <Button 
              style={{
                flex: 1, 
                backgroundColor: this.state.subpark == 2 ? 'orange' : '#d9d9d9', 
                color: this.state.subpark == 2 ? 'white' : 'black',
                margin: 8,
                marginLeft: 0
              }}
              onClick={this.clickPark.bind(this, 2)}
            >
              Serra Das Agulhas        
            </Button>
          </div>


        <div
          style={{
            padding: '0 0 0 15px'
          }}
        >
          {this.state.gridWidth ? (
            <GridLayout
              className={classes.gridElement}
              cols={12}
              rowHeight={140}
              items={50}
              width={this.state.gridWidth}
              compactType={'horizontal'}
              isDraggable={false}
              isResizable={false}
            >
              {
                widgets.map((widget, index) => {
                  return (
                    <div style={{
                      overflow: 'hidden'
                    }} key={widget.id} data-grid={widget.grid} >
                        
                          <GraphicWidget
                            widget={widget}
                            module_id={'Management'}
                            style={{
                              margin: '10px'
                            }}
                          >
                          {
                            widget.type === 'hydro_bar' ?
                                <GraphicHydro items={this.state}  subpark={this.state.subpark}  classes={classes} widget={widget} graphic={widget} />
                                : widget.type === 'gauge_hydro' ?
                                      <GraphicGaugeHydro items={this.state}  subpark={this.state.subpark}  classes={classes} widget={widget} graphic={widget} />
                                :  widget.type === 'bullet_hydro' ?
                                      <GraphicLevelFluency items={this.state}  subpark={this.state.subpark}  classes={classes} widget={widget} graphic={widget} />
                                :   widget.type === 'interactive_table_hydro' ?
                                      <GraphicInteractiveTableHydro items={this.state}  subpark={this.state.subpark}  classes={classes} widget={widget} graphic={widget} />
                                :   widget.type === 'table_hydro' ?
                                      <GraphicInteractiveTableHydro2 items={this.state}  subpark={this.state.subpark}  classes={classes} widget={widget} graphic={widget} /> 
                                :   widget.type === 'production_table' ?
                                      <GraphicInteractiveTableHydro3 items={this.state}  subpark={this.state.subpark}  classes={classes} widget={widget} graphic={widget} /> : <div>No Widget</div>

                          }



                          </GraphicWidget>
                        

                    </div>
                  );
                })
              }
            </GridLayout>
          ) : '' }
        </div>
       






       </div>
    )
  }
}


export default withStyles()(HydroMainComponent)