import { Button, Icon, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { fillQuadrant, findMinMax, plotLines } from './GraphicScatterUtil.js';
import styles from './styles/styles.js';


const NO_DATA = 'No Data Available...';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#2E2E2E',
    color: theme.palette.common.white
  }
}))(TableCell);

const tooltip_formatter = (point, x_axis, x_unit, y_axis, y_unit) => {

  var quadrants = ['A', 'B', 'C', 'D']
  if (point.tooltip) {
    let html_body = null;
    let tooltip = point.tooltip ? point.tooltip.map(t => {
      return `${t[0]} - ${t[1]}`
    })
      : null;
    var text = `${point.class}<br>`;
    text += `<b>Alarm List: </b><br>${tooltip.join('<br>')}<br>`
    text += `<b>Sample Time: </b> ${moment(point.datetime).format()}<br>`

    if (x_axis) {
      text += `<b>${x_axis}: </b>${point.x ? point.x.toFixed(1) : 0} ${x_unit}<br>`
    }
    if (y_axis) {
      text += `<b>${y_axis}: </b>${point.y ? point.y.toFixed(1) : 0} ${y_unit}<br>`
    }

    html_body = `<div>${text}</div>`;
    return html_body;
  }
  else if (point.series) {
    if (!quadrants.includes(point.series.name)) {
      let text = `<b>WTG: </b>${point.series.name}<br><b>${x_axis}:</b> ${point.x ? point.x.toFixed(2) : 0} ${x_unit}<br><b>${y_axis}:</b> ${point.y ? point.y.toFixed(2) : 0} ${y_unit}`;
      return text
    }
    var text = `${point.class}<br>`
    text += `<b>Sample Time: </b> ${moment(point.datetime).format()}<br>`

    if (x_axis) {
      text += `<b>${x_axis}: </b>${point.x ? point.x.toFixed(1) : 0} ${x_unit}<br>`
    }
    if (y_axis) {
      text += `<b>${y_axis}: </b>${point.y ? point.y.toFixed(1) : 0} ${y_unit}<br>`
    }

    var html_body = `<div>${text}</div>`;
    return html_body

  } else {
    var text = `${point.class}<br>`
    text += `<b>Sample Time: </b> ${moment(point.datetime).format()}<br>`

    if (x_axis) {
      text += `<b>${x_axis}: </b>${point.x ? point.x.toFixed(1) : 0} ${x_unit}<br>`
    }
    if (y_axis) {
      text += `<b>${y_axis}: </b>${point.y ? point.y.toFixed(1) : 0} ${y_unit}<br>`
    }

    var html_body = `<div>${text}</div>`;
    return html_body
  }
};

class GraphicScatter extends Component {
  constructor(props) {
    super(props)
    const { graphic } = this.props
    const { id, type } = graphic
    const { data } = graphic
    this.refChart = React.createRef()

    this.state = {
      chart_id: [type, id].join('-'),
      data: data,
      original_data: null,
      mouse_x: null,
      mouse_y: null,
      colors: ['#0A70D2', '#FC9018', '#22BA1A', '#FCDC34', '#D47C35', '#F4645C', '#C0D2FC', '#4C65A7', '#8C7277'],
      columns: [],
      column_sort: 1,
      is_crescent: false
    }
  }

  componentDidMount() {
    try {
      this.props.sendRefChart(this.refChart);
    } catch (err) {
      console.log('Error on getting refchart', err);
    }
  }

  reflow() {
    this.refChart.current.chart.reflow()
  }

  caulculateFontSizeHead(grid) {


    if (grid.h < 2 || grid.w < 2) {
      return "8px";
    }

    if (grid.w < 6) {
      return "10px";
    }

    if (grid.w < 8) {
      return "11px";
    }

    return "12px";

  }

  setFilter(params) {
    let order = !this.state.is_crescent
    this.setState({ column_sort: params.index + 1, is_crescent: order })
  }


  orderRows(rows, column_sort, is_crescent) {

    let sort_anchor = rows.map((row, index) => {

      return { idx: index, sort_value: parseFloat(row[column_sort]) }

    }).sort((a, b) => {

      return a.sort_value < b.sort_value ?
        is_crescent ? -1 : 1
        :
        a.sort_value > b.sort_value ?
          is_crescent ? 1 : -1
          :
          0
    })

    return rows.map((row, index) => rows[sort_anchor[index].idx])

  }

  removeNulls(array) {

    var filtered = array.filter(function (el) {
      return el != null;
    });
    return filtered
  }


  shouldComponentUpdate(nextProps, nextState) {

    if (this.props.showExport != nextProps.showExport) {
      return false;
    }

    return true

  }

  legendItemClick(e) {
    if (e && e.target && this.props.filterTable) {
      const name = e.target.name ? e.target.name : '';

      const filter = (e && e.target && e.target.chart && e.target.chart.legend && e.target.chart.legend.allItems) ?
        e.target.chart.legend.allItems.filter(item => item.name === name ? !item.visible : item.visible).map(item => item.name)
        :
        [];

      this.props.filterTable(filter);
    }
  }

  getTableHeader() {

    if (this.props && this.props.graphic && this.props.graphic.id === 121) {
      return [null, "Power Factor", "Reference"];
    };

    if (this.props.graphic && this.props.graphic.data && this.props.graphic.data.scatter && this.props.graphic.data.scatter[0]) {
      const axis = this.props.graphic.data.scatter[0];
      const { x_axis, y_axis } = this.props.graphic.data;
      return [null, axis.wtg ? 'WTG' : 'Date', axis.class, x_axis ? `${x_axis.x_name}${x_axis.x_unit}` : '', y_axis ? `${y_axis.y_name}${y_axis.y_unit}` : ''];
    }

    return [];
  };

  render() {






    const { classes, showTable, graphic, tableFilter } = this.props;

    const { data: rawData } = graphic
    let data = null;

    try {
      data = typeof(rawData) === 'string' ? JSON.parse(rawData.replace(/NaN/g, 'null')) : rawData;
    } catch(err) {
      console.log('Error on scatter ', err)
      console.log(rawData)
      return (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {/* <CircularProgress className={classes.progress} size={(graphic.size * 30)} /> */}
          <div style={{
            marginTop: '20px'
          }}>
            Trying to resolve data...
          </div>
        </div>
      )
    }

    let dataValues = [];

    if (graphic.id === 121) {

      data.features = ["Power Factor", "Reference"];
      dataValues.push(['', data.power_factor && data.power_factor.toFixed(2), data.reference && data.reference.toFixed(2)]);

    } else {
      if (tableFilter) {
        const filteredScatter = data && data.scatter ?
          data.scatter.filter(s => tableFilter.includes(s.wtg))
          :
          [];
        dataValues = filteredScatter.map((axis) => {
          return [axis.datetime && "", axis.wtg ? axis.wtg : axis.datetime && moment(axis.datetime).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss'), axis.class && axis.class, axis.x && axis.x.toFixed(2), axis.y && axis.y.toFixed(2)]

        });
      } else {
        dataValues = data && data.features && data.scatter ?
          data.scatter.map((axis) => {
            return [axis.datetime && "", axis.wtg ? axis.wtg : axis.datetime && moment(axis.datetime).tz('America/Fortaleza').format('YYYY-MM-DD HH:mm:ss'), axis.class && axis.class, axis.x && axis.x.toFixed(2), axis.y && axis.y.toFixed(2)]

          }) : [];
      }
    }

    const canBeFiltered = data && data.scatter && data.scatter[0] && data.scatter[0].wtg && true;


    let sorted_table = null;

    if (this.state.column_sort >= 0) {
      if (dataValues) {
        sorted_table = this.orderRows(dataValues, this.state.column_sort, this.state.is_crescent)
      }
    }


    if (!data || Array.isArray(data) || !data.x_axis) {
      return (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div style={{
            marginTop: '20px'
          }}>
            {NO_DATA}
          </div>
        </div>
      )
    }
    let type_chart = graphic.type
    let data_to_use = data;

    let serie = []




    if (data.lines) {

      var filtered = this.removeNulls(data.lines);

      filtered.map((line, index) => {

        serie.push({
          data: [],
          name: line.tooltip
        })

        line.series.map(point => {
          serie[index].data.push(point)
        })

        serie[index].type = 'line'
        serie[index].zIndex = 5
        serie[index].tooltip = {
          enabled: true,
          useHTML: true,
          pointFormat: line.tooltip,
          headerFormat: '',
          snap: 500,
          shared: true
        }

        serie[index].marker = {
          enabled: false
        }

        serie[index].color = line.tooltip == 'Contractual Power Curve' ? 'black' : line.tooltip == 'Park Average' ? '#e0707b' : line.tooltip == 'WTG Average' ? '#7370e0' : line.tooltip == 'Intervention' ? '#1a8cff' : this.state.colors[index]

      })
    }

    if (data.scatter && data.scatter.length > 0) {
      let scatterSerie = []
      data.scatter.map(point => {
        var existClass
        scatterSerie.forEach(s => {
          if (s.name == point.class) {
            existClass = true
          }
        })
        if (!existClass) {
          scatterSerie.push({ name: point.class, data: [] })
        }
      })
      if (data.hasOwnProperty('lines')) {

        var filtered_scatter = this.removeNulls(data.scatter);

        filtered_scatter.map(point => {

          scatterSerie.forEach((scatter, index) => {
            if (scatter.name == point.class && (point.x != null || point.y != null)) {
              scatter.data.push(point)
              scatter.tooltip = {
                enabled: true,
                useHTML: true,
                pointFormat: tooltip_formatter(point, data.x_axis.x_name, data.x_axis.x_unit, data.y_axis.y_name, data.y_axis.y_unit),
              }
              scatter.type = 'scatter'
              scatter.color = point.class == 'Information' ? '#10ac84' : point.class == 'Warning' ? '#efb121' : point.class == 'Stop' ? '#ee5253' : point.class == 'Power Limitation' ? '#ff9f43' : point.class == 'Inline' ? 'green' : point.class == 'Outline' ? 'red' : point.class == 'No Operation' ? 'red' : point.class == 'Warning' ? '#fffa65' : point.class == 'Intervention' ? '#1a8cff' : this.state.colors[index]
              scatter.visible = false
            }
          })
        })
        scatterSerie.forEach(scatter => {
          scatter.data.length != 0 && serie.push(scatter)
        })
        var max_y = graphic.y_max ? graphic.y_max : data.scatter.map(scatter => scatter.y)


        var filtered_lines = this.removeNulls(data.lines);
        var num_lines = filtered_lines.length;

        for (let index = 0; index < num_lines; index++) {
          const line = filtered_lines[index];
          line.series.map(l => max_y.push(l.y))
        }

        max_y = Math.max.apply(Math, max_y);

        var min_y = filtered_scatter.map(scatter => scatter.y)
          .concat(filtered_lines.map(lines => lines.series.map(series => series.y).reduce((prev, curr) => prev < curr ? prev : curr))
            .reduce((prev, curr) => prev < curr ? prev : curr)
          ).reduce((prev, curr) => prev < curr ? prev : curr)

        var min_x = filtered_scatter.map(scatter => scatter.x)
          .concat(filtered_lines.map(lines => lines.series.map(series => series.x).reduce((prev, curr) => prev < curr ? prev : curr))
            .reduce((prev, curr) => prev < curr ? prev : curr)
          ).reduce((prev, curr) => prev < curr ? prev : curr)

      } else {

        var filtered_scatter = this.removeNulls(data.scatter);

        serie = filtered_scatter.map(scatter => {
          return { name: scatter.wtg, data: [[scatter.x, scatter.y]], zIndex: 10 }
        })

        var max_y = Math.ceil(findMinMax(filtered_scatter)[1] / 10) * 10
      }

    }
    if (data.scatter == null) {
      data.scatter = [];
    }




    var x_name = typeof (data.x_axis) == 'object' ? data.x_axis.x_name + ' ' + data.x_axis.x_unit : data.x_name
    var y_name = typeof (data.y_axis) == 'object' ? data.y_axis.y_name + ' ' + data.y_axis.y_unit : data.y_name
    var params = {
      // colors,
      chart: {
        type: 'scatter',
        zoomType: 'xy',
        // events: {
        //   load: function(){
        //       this.myTooltip = null
        //       this.myTooltip = new Highcharts.Tooltip(this, this.options.tooltip);
        //   }
        // }
      },
      title: {
        text: null
      },
      xAxis: {
        title: {
          text: graphic.grid.h >= 2 ? x_name : null
        },
        max: type_chart == 'powercurve_scatter' ? 20 : null,
        min: data.hasOwnProperty('lines') ? min_x : 0
      },
      yAxis: {
        title: {
          text: graphic.grid.h >= 2 ? y_name : null
        },
        max: max_y,
        min: data.hasOwnProperty('lines') ? min_y : 0
      },
      tooltip: {
        enabled: true,
        //outside: true,
        formatter: function () {
          let text = tooltip_formatter(this.point, data.x_axis.x_name, data.x_axis.x_unit, data.y_axis.y_name, data.y_axis.y_unit)
          return text
        }
      },
      legend: {
        floating: graphic.floating_legend != null ? graphic.floating_legend : false,
        enabled: graphic.grid.h > 1 && graphic.grid.w > 2
      },
      exporting: {
        enabled: true
      },
      plotOptions: {
        series: {
          marker: {
            radius: graphic.grid.h > 2 ? 5 : 2,
            symbol: 'circle'
          },
          events: {
            legendItemClick: canBeFiltered ? this.legendItemClick.bind(this) : undefined
          }
        },
        scatter: {
          turboThreshold: 100000000,
          zIndex: 10
        }

      },
      credits: {
        enabled: false
      },
      series: serie
    }

    params = data_to_use.use_quadrant == true ? fillQuadrant(data_to_use, params, max_y) : params
    params = data_to_use.plotLineX != null || data_to_use.plotLineY != null ? plotLines(data, params) : params

    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront} style={{ display: showTable ? 'none' : undefined }}>
            {
              params.series && params.series.length > 0 ?
                <HighchartsReact
                  containerProps={{
                    style: {
                      width: '100%',
                      height: 'calc(100% - 80px)',
                      marginTop: 30,
                      display: showTable ? 'none' : 'block',
                      marginBottom: 50
                    }
                  }}
                  ref={this.refChart}
                  highcharts={Highcharts}
                  options={params}
                />
                :
                <div style={{
                  width: '100%',
                  height: 'calc(100% - 80px)',
                  marginTop: 30,
                  display: showTable ? 'none' : 'flex',
                  marginBottom: 50,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  {NO_DATA}
                </div>
            }
            {
              this.props.isAnalysis &&

              <Table style={{ marginTop: 30 }} className={classes.table}>
                <TableHead>
                  <TableRow className={classes.rowHead}>
                    <CustomTableCell colSpan={1}></CustomTableCell>

                    {data && data.features ?
                      data.features.map((elm, index) => {
                        if (elm === '0') {
                          return (
                            <CustomTableCell colSpan={1} key={index}>
                              {''}
                            </CustomTableCell>
                          )
                        }

                        return (
                          <CustomTableCell colSpan={1} key={index} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}>
                            {elm}

                            <Button onClick={this.setFilter.bind(this, { name: elm, index: index })} mini style={{
                              paddingRight: '30px'
                            }}>
                              <Icon>arrow_drop_down</Icon>
                            </Button>


                          </CustomTableCell>
                        )
                      }) : null}

                  </TableRow>
                </TableHead>
                <TableBody>

                  {
                    sorted_table.map((row, indexRow) => {
                      return (
                        <TableRow className={classes.row} key={indexRow}>
                          {row.map((cell, indexCell) => {
                            return (
                              <CustomTableCell key={indexCell} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}>
                                {cell}
                              </CustomTableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })

                  }

                </TableBody>
              </Table>
            }
          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable ? undefined : 'none' }}>
            {<Table className={classes.table} style={{ display: !showTable ? 'none' : 'table' }}>
              <TableHead>
                <TableRow className={classes.rowHead}>
                  {this.getTableHeader().map((elm, index) => {
                    if (elm === '0' || !elm) {
                      return (
                        <CustomTableCell colSpan={1} key={index}>
                          {''}
                        </CustomTableCell>
                      )
                    }

                    return (
                      <CustomTableCell colSpan={1} key={index} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}>
                        {elm}
                      </CustomTableCell>
                    )
                  })}

                </TableRow>
              </TableHead>
              <TableBody>

                {
                  sorted_table.map((row, indexRow) => {
                    return (
                      <TableRow className={classes.row} key={indexRow}>
                        {row.map((cell, indexCell) => {
                          return (
                            <CustomTableCell key={indexCell} style={{ fontSize: this.caulculateFontSizeHead(graphic.grid), padding: '1px 2px 1px' }}>
                              {cell}
                            </CustomTableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })

                }

              </TableBody>
            </Table>}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicScatter);
