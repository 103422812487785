import React, {
    useState,
    useEffect
} from 'react';
import {
    Selector,
    TextField,
    SelectorDayView,
    ComponentImage
} from './components';
import * as R from 'ramda'
import { useTranslation } from 'react-i18next';


const View = props => {

    const {
        feedDataOut,
        options,
        error,
        storages,
        activeResource,
        formProps = {},
        modelImg
    } = props;
    const { t } = useTranslation();

    const stateOptions = [
        { id: 'new', name: t('New') },
        { id: 'recondicioned', name: t('Reconditioned') },
        { id: 'under-analysis', name: t('Under Analysis') }
    ];

    let extraInputs = [];

    if (activeResource) {
        const entries = Object.entries(activeResource);

        const alreadyUsedInputs = [
            'Serial Number',
            'Supplier',
            'Acquisition Date',
            'Acquisition Value',
            'Fabrication Date',
            'Received Date',
            'Expiration Date',
            'End Of Warranty',
            'Invoice Number',
            "Component's Documents",
            'Comments',
            'Manufacturer',
            'Placement',
            'id',
            'undefined',
            'Component Model',
            'Current Location',
            'Component Status',
            'Application Date',
            'Application Supplier',
            'Work Order ID',
            'To Application Expiration',
            'To Application Warranty',
            'To Next Application',
            "Application's Documents",
            'Part Number'
        ];

        extraInputs = entries.filter(entry => !alreadyUsedInputs.find(input => entry[0] === input));
    } else {
        extraInputs = [['NCM', null], ['ICMS', null], ['Frete', null]]
    }

    const defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() + 2);

    const component = activeResource && R.find(R.propEq('name', activeResource['Component Model']))(Object.values(options));
    const storage = activeResource && R.find(R.propEq('name', activeResource['Current Location']))(Object.values(storages));
    const state = activeResource && R.find(R.propEq('name', activeResource['Component Status']))(Object.values(stateOptions));

    let inputInitialState = {
        id1: {
            fieldName: 'Component Model',
            data: component ? component.id : undefined,
            acquisitionField: 'component_model_id',
            required: true
        },
        id2: {
            fieldName: 'Acquisition Date',
            data: activeResource && activeResource['Acquisition Date'] ? new Date(String(activeResource['Acquisition Date'])) : new Date(),
            acquisitionField: 'acquired_date',
            required: true
        },
        id3: {
            fieldName: 'Entry Stock',
            data: storage ? storage.id : undefined,
            acquisitionField: 'storage_id',
            required: true
        },
        id5: {
            fieldName: 'Supplier',
            data: activeResource ? activeResource['Supplier'] : undefined,
            acquisitionField: 'supplier',
            required: false
        },
        id6: {
            fieldName: 'Serial Number',
            data: activeResource ? activeResource['Serial Number'] : undefined,
            acquisitionField: 'serial_number',
            required: false
        },
        id7: {
            fieldName: 'Invoice Number',
            data: activeResource ? activeResource['Invoice Number'] : undefined,
            acquisitionField: 'invoice_number',
            required: true
        },
        id8: {
            fieldName: 'Expiration Date',
            data: activeResource && activeResource['Expiration Date'] ? new Date(String(activeResource['Expiration Date'])) : defaultDate,
            acquisitionField: 'expire_date',
            required: true
        },
        id9: {
            fieldName: 'End Of Warranty',
            data: activeResource && activeResource['End Of Warranty'] ? new Date(String(activeResource['End Of Warranty'])) : defaultDate,
            acquisitionField: 'warranty',
            required: true
        },
        id10: {
            fieldName: "Component's Documents",
            data: activeResource ? activeResource["Component's Documents"] : undefined,
            acquisitionField: 'documents',
            required: false
        },
        id11: {
            fieldName: 'Component State',
            data: state ? state.id : undefined,
            acquisitionField: 'state',
            required: true
        },
        id12: {
            fieldName: 'Acquisition Value',
            data: activeResource ? activeResource['Acquisition Value'] : undefined,
            acquisitionField:
                'acquisition_value',
            required: false
        },
        id13: {
            fieldName: 'Received Date',
            data: activeResource && activeResource['Received Date'] ? new Date(String(activeResource['Received Date'])) : new Date(),
            acquisitionField: 'received_date',
            required: true
        },
        id14: {
            fieldName: 'Fabrication Date',
            data: activeResource && activeResource['Fabrication Date'] ? new Date(String(activeResource['Fabrication Date'])) : new Date(),
            acquisitionField: 'manufacturing_date',
            required: false
        },
        id15: {
            fieldName: 'Comments',
            data: activeResource ? activeResource['Comments'] : undefined,
            acquisitionField: 'observation',
            required: false
        },
        id16: {
            fieldName: 'Manufacturer',
            data: activeResource ? activeResource['Manufacturer'] : undefined,
            acquisitionField: 'manufacturer',
            required: false
        },
        id17: {
            fieldName: 'Placement',
            data: activeResource ? activeResource['Placement'] : undefined,
            acquisitionField: 'placement',
            required: false
        }
    };

    inputInitialState = activeResource ? inputInitialState : {
        ...inputInitialState,
        id18: {
            fieldName: 'Quantity',
            data: 1,
            acquisitionField: 'quantity',
            required: false,
        }
    };


    let inputsToAdd = []
    if (extraInputs.length > 0) {
        inputsToAdd = extraInputs.filter(input => extraInfoHeadersToAcquisitionField[input[0]]).reduce((acc, curr, index) => {

            return {
                ...acc,
                [`id${Object.values(inputInitialState).length + index + 3}`]: {
                    fieldName: curr[0],
                    data: curr[1],
                    acquisitionField: extraInfoHeadersToAcquisitionField[curr[0]],
                    required: false,
                    extra_info: true
                }
            };
        }, {});

        inputInitialState = { ...inputInitialState, ...inputsToAdd };
    }

    const [inputs, setInputValue] = useState(inputInitialState);

    const changeValueAdapter = id => value => {

        if (id == 'id12') {
            if (value < 0) {
                value = 0;
            }
        }

        if (id == 'id18') {
            if (value < 1) {
                value = 1;
            }
        }

        const minValue = inputs[id].minValue;

        let data = (id === 'id10' && value.split(',')) ||
            (minValue && value < minValue ? minValue : value)

        if (typeof inputs[id].data === 'number') {
            data = Number(value);
        }

        const payload = id === 'id2' ?
            {
                [id]: {
                    ...inputs[id],
                    data: minValue && value < minValue ? minValue : value
                },
                id13: {
                    ...inputs.id13,
                    data: inputs.id13.data.getTime() - value.getTime() < 0 ?
                        value
                        :
                        inputs.id13.data
                },
                id14: {
                    ...inputs.id14,
                    data: inputs.id14.data.getTime() - value.getTime() > 0 ?
                        value
                        :
                        inputs.id14.data
                },
                ...(inputs.id14.data.getTime() - value.getTime() < 0 ? {
                    id8: {
                        ...inputs.id8,
                        data: inputs.id8.data.getTime() - inputs.id14.data.getTime() < 0 ?
                            value
                            :
                            inputs.id8.data
                    },
                    id9: {
                        ...inputs.id9,
                        data: inputs.id9.data.getTime() - inputs.id14.data.getTime() < 0 ?
                            value
                            :
                            inputs.id9.data
                    }
                } : {})
            }
            : id === 'id14' ?
                {
                    [id]: {
                        ...inputs[id],
                        data: minValue && value < minValue ? minValue : value
                    },
                    id8: {
                        ...inputs.id8,
                        data: inputs.id8.data.getTime() - value.getTime() < 0 ?
                            value
                            :
                            inputs.id8.data
                    },
                    id9: {
                        ...inputs.id9,
                        data: inputs.id9.data.getTime() - value.getTime() < 0 ?
                            value
                            :
                            inputs.id9.data
                    }
                }
                :
                {
                    [id]: {
                        ...inputs[id],
                        data
                    }
                };

        setInputValue({
            ...inputs,
            ...payload
        });
    }

    const changeSelectorAdapter = id => value => {
        const minValue = inputs[id].minValue;

        const payload = id === 'id1' ?
            {
                [id]: {
                    ...inputs[id],
                    data: minValue && value < minValue ? minValue : value
                },
                id6: {
                    ...inputs.id6,
                    data: `${options && options[value] && options[value].part_number ? options[value].part_number : ''}`
                }
            }
            :
            {
                [id]: {
                    ...inputs[id],
                    data: minValue && value < minValue ? minValue : value
                }
            };

        setInputValue({
            ...inputs,
            ...payload
        });
    }

    useEffect(() => feedDataOut(inputs), [inputs]);

    const renderExtraInputs = () => {

        return Object.entries(inputs).filter(entry => entry[1].extra_info && entry[1].fieldName !== 'Placement').map((entry, index) => {

            return (
                <TextField
                    key={index}
                    style={{ flex: '1 0 20%', padding: 1, margin: '5px 0px' }}
                    label={entry[1].fieldName}
                    value={entry[1].data}
                    changeValueFunction={changeValueAdapter(entry[0])}
                    type={typeof entry[1].data}
                />
            );
        });
    };

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                ...(formProps.style || {})
            }}
        >
            <Selector
                style={{ flex: `1 0 ${activeResource ? 49 : 33}%`, padding: 1, margin: '5px 0px' }}
                label={`${t('Component')} *`}
                required={inputs['id1'].required || false}
                error={error}
                subLabel={t('Model')}
                options={options}
                value={inputs['id1'].data}
                changeValueFunction={changeSelectorAdapter('id1')}
            />
            <TextField
                style={{ flex: `1 0 ${activeResource ? 49 : 33}%`, padding: 1, margin: '5px 0px' }}
                label={!inputs['id6'].data && `${t('Serial Number')} *`}
                required={inputs['id6'].required || false}
                error={error}
                placeholder={t('Serial Number')}
                value={inputs['id6'].data}
                changeValueFunction={changeValueAdapter('id6')}
            />
            {
                activeResource ?
                    ''
                    :
                    <TextField
                        style={{ flex: '1 0 33%', padding: 1, margin: '5px 0px' }}
                        label={t('Quantity')}
                        value={inputs['id18'] && inputs['id18'].data}
                        min={1}
                        type={'number'}
                        error={error}
                        required={true}
                        changeValueFunction={changeValueAdapter('id18')}
                    />
            }
            <TextField
                style={{ flex: '1 0 20%', padding: 1, margin: '5px 0px' }}
                label={t('Manufacturer')}
                required={inputs['id16'].required || false}
                error={error}
                placeholder={t('Manufacturer')}
                value={inputs['id16'].data}
                changeValueFunction={changeValueAdapter('id16')}
            />
            <TextField
                style={{ flex: '1 0 20%', padding: 1, margin: '5px 0px' }}
                label={t('Supplier')}
                required={inputs['id5'].required || false}
                error={error}
                placeholder={t('Supplier')}
                value={inputs['id5'].data}
                changeValueFunction={changeValueAdapter('id5')}
            />
            <SelectorDayView
                style={{ flex: '1 0 20%', padding: 1, margin: '5px 0px' }}
                label={`${t('Acquisition Date')} *`}
                value={inputs['id2'].data}
                changeValueFunction={changeValueAdapter('id2')}
            />
            <TextField
                style={{ flex: '1 0 20%', padding: 1, margin: '5px 0px' }}
                label={`${t('Acquisition Value')} (R$)`}
                placeholder={t('Acquisition Value')}
                required={inputs['id12'].required || false}
                error={error}
                min={0}
                value={inputs['id12'].data}
                changeValueFunction={changeValueAdapter('id12')}
                type='number'
                inputProps={{
                    step: '0.01',
                    pattern: '^\d*(\.\d{0,2})?$',
                    min: '0'
                }}
            />
            <SelectorDayView
                style={{ flex: '1 0 33%', padding: 1, margin: '5px 0px' }}
                label={t('Fabrication Date')}
                value={inputs['id14'].data}
                changeValueFunction={changeValueAdapter('id14')}
                maxDate={inputs['id2'].data}
            />
            <SelectorDayView
                style={{ flex: '1 0 33%', padding: 1, margin: '5px 0px' }}
                label={`${t('Received Date')} *`}
                value={inputs['id13'].data}
                changeValueFunction={changeValueAdapter('id13')}
                minDate={inputs['id2'].data}
            />
            <SelectorDayView
                style={{ flex: '1 0 33%', padding: 1, margin: '5px 0px' }}
                label={`${t('Expiration Date')} *`}
                value={inputs['id8'].data}
                changeValueFunction={changeValueAdapter('id8')}
                minDate={inputs['id14'].data}
            />
            <Selector
                style={{ flex: '1 0 20%', padding: 1, margin: '5px 0px' }}
                label={`${t('Entry Stock')} *`}
                required={inputs['id3'].required || false}
                error={error}
                subLabel={t('Storage')}
                options={storages}
                value={inputs['id3'].data}
                changeValueFunction={changeSelectorAdapter('id3')}
            />
            <TextField
                style={{ flex: '1 0 20%', padding: 1, margin: '5px 0px' }}
                label={t('Placement')}
                placeholder={`${t('Placement')} ${t('in the stock')}`}
                value={inputs['id17'].data}
                changeValueFunction={changeValueAdapter('id17')}
            />
            <Selector
                style={{ flex: '1 0 20%', padding: 1, margin: '5px 0px' }}
                label={`${t('Component State')} *`}
                required={inputs['id11'].required || false}
                error={error}
                subLabel={t('State')}
                options={stateOptions}
                value={inputs['id11'].data}
                changeValueFunction={changeSelectorAdapter('id11')}
            />
            <SelectorDayView
                style={{ flex: '1 0 33%', padding: 1, margin: '5px 0px' }}
                label={`${t('End Of Warranty')} *`}
                value={inputs['id9'].data}
                changeValueFunction={changeValueAdapter('id9')}
                minDate={inputs['id14'].data}
            />
            <TextField
                style={{ flex: '1 0 49%', padding: 1, margin: '5px 0px' }}
                label={`${t('Invoice Number')} *`}
                placeholder={t('Invoice Number')}
                value={inputs['id7'].data}
                changeValueFunction={changeValueAdapter('id7')}
            />
            <TextField
                style={{ flex: '1 0 49%', padding: 1, margin: '5px 0px' }}
                label={t('Assoc Documents')}
                placeholder={t('Assoc Documents')}
                value={inputs['id10'].data}
                changeValueFunction={changeValueAdapter('id10')}
            />
            <TextField
                style={{ flex: '1 0 100%', padding: 1, margin: '5px 0px' }}
                label={t('Comments')}
                placeholder={t('Comments')}
                value={inputs['id15'].data}
                changeValueFunction={changeValueAdapter('id15')}
            />
            {renderExtraInputs()}
            {modelImg && <ComponentImage modelId={inputs && inputs.id1 && inputs.id1.data} edit={false} />}
        </div>
    )
}

export default View;

const extraInfoHeadersToAcquisitionField = {
    'Filial': 'FILIAL',
    'Empresa': 'EMPRESA',
    'Depósito': 'DEPOSITO',
    'Série Nota Fiscal': 'SERIE_NOTA_FISCAL',
    'Sequência Item Nota Fiscal': 'SEQUENCIA_ITEM_NOTA_FISCAL',
    'NCM': 'ncm',
    'ICMS': 'icms',
    'Número da Peça Customizado': 'original_part_number',
    'Frete': 'frete'
};