import React, {Component} from 'react'
import {withStyles} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import moment from 'moment-timezone'

const styles = theme => ({
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& th, & td': {
      textAlign: 'left',
      padding: '8px',
      borderBottom: `1px solid ${grey[200]}`
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#f2f2f2'
    }
  },
  itemTable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 0',
    borderBottom: `1px solid ${grey['200']}`,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: `${grey[200]}`,
      cursor: 'pointer'
    },
    '& div': {
      flex: 1
    }
  }
})

class InventoryAssetsConsumableTab extends Component {
  toggleChildrenItem = (id) => {
    this.props.toggleChildrenItem('consumableSystems', id)
  }

  render () {
    const {classes, items} = this.props

    

    if (this.props.itemsLoading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          <div
            style={{
              marginTop: '20px'
            }}
          >
            Loading...
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        {this.props.systems.map((system, indexSystem) => {
          return (
            <div
              key={indexSystem}
            >
              <div
                className={classes.itemTable}
                onClick={() => this.toggleChildrenItem(system.id)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'flex'
                  }}
                >
                  {
                    system.show
                    ? (
                      <ArrowDropDownIcon
                        style={{
                          marginLeft: 5
                        }}
                      />
                    )
                    : (
                      <ArrowRightIcon
                        style={{
                          marginLeft: 5
                        }}
                      />
                    )
                  }

                  <div
                    style={{
                      marginLeft: 10
                    }}
                  >
                    {system.name}
                  </div>
                </div>
                <div>
                  {system.data.length}
                </div>
              </div>
              <div
                style={{
                  display: system.show ? 'flex' : 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'auto'
                }}
              >
                <div
                  style={{
                    width: `calc(100% - 40px)`,
                    margin: '20px 0'
                  }}
                >
                  <table
                    className={classes.table}
                  >
                    <thead>
                      <th>
                        Component Slot
                      </th>
                      <th>
                        Name
                      </th>
                      <th>
                        Manufacturer
                      </th>
                      <th>
                        Manufacturer Part Number
                      </th>
                      <th>
                        Serial Number
                      </th>
                      <th>
                        Supplier
                      </th>
                      <th>
                        Acquired Date
                      </th>
                      <th>
                        Procedure Date
                      </th>
                    </thead>
                    <tbody>
                      {system.data.map(subitem => {
                        return (
                          <tr
                            key={subitem.id}
                          >
                            <td>
                              {subitem.consumable_slot}
                            </td>
                            <td>
                              {subitem.consumable_model}
                            </td>
                            <td>
                              {subitem.manufacturer}
                            </td>
                            <td>
                              {subitem.manufacturer_part_number}
                            </td>
                            <td>
                              {subitem.serial_number}
                            </td>
                            <td>
                              {subitem.supplier}
                            </td>
                            <td>
                              {subitem.acquired_date ? moment(subitem.acquired_date).format('YYYY-MM-DD') : ''}
                            </td>
                            <td>
                              {subitem.procedure_date ? moment(subitem.procedure_date).format('YYYY-MM-DD') : ''}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        })}

        {/* <div
          style={{
            position: 'absolute',
            right: '20px',
            bottom: '20px'
          }}
        >
          <Button
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.button}
          >
            <AddIcon />
          </Button>
        </div> */}
      </div>
    )
  }
}

export default withStyles(styles)(InventoryAssetsConsumableTab)

