import { 
    REDUCER_AUTH_VERIFY, 
    REDUCER_AUTH_LOGOUT,
    REDUCER_AUTH_PARKS_INFO, 
    REDUCER_AUTH_LOGIN 
} from '../constants/reducers/auth-action-events';

import moment from 'moment-timezone';

const DEFAULT_STATE = {
    'some-cognito-sub': {
        id: 'some-cognito-sub',
        // isAuthenticated: false,
        loginTime: moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss'),
        logoutTime: null,
        username: 'sample-user',
        clientId: 0,
        role: 'example',
        accessEnabled: false,
        name: 'Sample User'
    }
}

export default (state = DEFAULT_STATE, action) => {
    const { 
        type,
        authTimestamp,
        id,
        username,
        name,
        clientId,
        role,
        accessEnabled,
        keepAlive
    } = action;

    const loginTime = moment(new Date(authTimestamp)).tz('UTC').format('YYYY-MM-DD HH:mm:ss');

    switch (type) {
        case REDUCER_AUTH_LOGIN:
            // if(!accessEnabled) {
            //     return state;
            // }
            
            // const usersArray = Object.values(state).map(user => ({
            //     [user.id]: {
            //         ...user,
            //         isAuthenticated: false
            //     }
            // }));

            // const users = usersArray.length > 0 ? 
            //     usersArray.reduce((acc, curr) => ({...acc, ...curr}))
            // :
            //     []

            return {
                // ...users,
                ...state, 
                [id]: {
                    loginTime,
                    logoutTime: null,
                    id,
                    username,
                    clientId,
                    role,
                    accessEnabled,
                    name
                    // isAuthenticated: true
                }
            }
        case REDUCER_AUTH_LOGOUT:
            if(!id) {
                return state;
            }

            if(keepAlive) {
                return {
                    ...state, 
                    [id]: {
                        ...state[id],
                        logoutTime: moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss'),
                        // isAuthenticated: false
                    }
                };
            } else {
                const newState = JSON.parse(JSON.stringify(state));
                delete newState[id];
                return newState;
            }
            
        // case REDUCER_AUTH_VERIFY:
        //     const {isAuthenticated: isAuthenticatedVerify} = payload
            
        //     return {...state, isAuthenticated: isAuthenticatedVerify}
        // case REDUCER_AUTH_PARKS_INFO:
        //     const {result: resultParksInfo} = payload
            
        //     return {...state, parks_info: resultParksInfo}
        default:
            return state;
    }
}
