import { Auth } from 'aws-amplify'
import axios from 'axios'
import * as R from 'ramda'
import { store } from '../store/ProviderStore'
import { URL_NODE_API } from '../constants/url-constant'

export const addCheckpoint = async (state=null, user_token=null, reset=false) => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken

  const URL = URL_NODE_API + '/user/preference/save'

  if (reset) {
    state = {};
  }

  const {status} = await axios.post(URL, {state: state}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })

  return status >= 200 && status < 300 ? jwtToken : null
}

export const getActualState = async () => {
  const {accessToken} = await Auth.currentSession()
  const {jwtToken} = accessToken
  const URL = URL_NODE_API + '/user/preference'

  const {data, status} = await axios.get(URL, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwtToken
    }
  })


  return status >= 200 && status < 300 ? data : null
}
