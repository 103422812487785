import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { PaginatorTable } from '../../../../../../../new-components/Table';
import BigIconMessage from '../../BigIconMessage';
import TimeIntervalSlider from '../../../../EventsPage/components/TimeIntervalSlider';
import SliderTooltipLabel from '../../../../EventsPage/components/SliderTooltipLabel';
import '../../../../EventsPage/components/styles/ExtraStyles.css';
import { useTranslation } from 'react-i18next';

const TimeToDateSliderLabel = SliderTooltipLabel(val => new Date(val).toISOString().split('.')[0].replace('T', ' '));

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    }
}));

const conformData = (movement, key) => {
    if (key === 'destiny_name' && movement.destiny_data && movement.destiny_data.placement) {
        return `${movement.destiny_name} (${movement.destiny_data.placement})`;
    }
    if (key === 'origin_name' && movement.origin_data && movement.origin_data.placement) {
        return `${movement.origin_name} (${movement.origin_data.placement})`;
    }
    return movement[key];
};

const View = props => {

    const {
        rawHistories,
        movementsHeadMap,
        modulePage
    } = props;
    const { t } = useTranslation();
    let records = [];

    rawHistories.map(movement => {

        let newObject = {}

        Object.keys(movement).map(key => {
            newObject[movementsHeadMap[key]] = conformData(movement, key)
        })

        records.push(newObject)

    })


    const classes = useStyles();

    return (
        <div style={{ margin: '16px' }}>
            {
                rawHistories.length > 0 ?
                    <Paper className={classes.root}>
                        <div>
                            <PaginatorTable
                                records={records}
                                fieldFormatter={(index, head, row) => {
                                    if (row[head] == null) {
                                        return '--';
                                    }

                                    switch (head) {
                                        case 'In':
                                            return row[head].split('T')[0];
                                        default:
                                            return row[head];
                                    }
                                }}
                                displayHeaders={[
                                    'Component Model',
                                    'Part Number',
                                    'Serial Number',
                                    'Event',
                                    'In',
                                    'From',
                                    'To',
                                    'Approved By'
                                ]}
                                disableSelect={true}
                                resizeFactor={1}
                                rowsPerPageOptions={[
                                    10,
                                    20,
                                    30,
                                    50,
                                    100,
                                    150,
                                    200
                                ]}
                                cellMinWidth={'8.5vw'}
                                cellMaxWidth={'13vw'}
                                filterLogic={head => (val, filters) => {
                                    if (filters.length === 0 || !val) {
                                        return false;
                                    }

                                    switch (head) {
                                        case 'In':
                                            const tVal = new Date(val).getTime();
                                            const tLower = filters.length > 0 ? filters[0] : 0;
                                            const tUpper = filters.length > 1 ? filters[1] : 999999999999999999999999999999999999;
                                            return !(tVal >= tLower && tVal <= tUpper);

                                        case 'documents':
                                            return !(filters[0][0] == val[0])
                                        default:
                                            return !(filters.indexOf(val) >= 0);
                                    }
                                }}
                                useCustomDisplay={[
                                    'In'
                                ]}
                                filterDisplay={head => (filterList, onChange, index, column) => {
                                    switch (head) {
                                        case 'In':
                                            const tValues = records.filter(row => (
                                                row[head] != null
                                            )).map(row => new Date(row[head]).getTime());

                                            const tDateMax = Math.max(...tValues);
                                            const tDateMin = Math.min(...tValues);

                                            return (
                                                <TimeIntervalSlider
                                                    title={`${head != 'In' ? t(head) : t('Moved between')} (${t('in range')})`}
                                                    submit={value => onChange(value, index, column)}
                                                    LabelComponent={TimeToDateSliderLabel}
                                                    defaultValue={column.filterList || [tDateMin, tDateMax]}
                                                    sliderProps={{
                                                        max: tDateMax,
                                                        min: tDateMin,
                                                        step: 24 * 3600
                                                    }}
                                                />
                                            );
                                        default:
                                            return undefined;
                                    }
                                }}
                                chipFormatter={{
                                    ['In']: val => {
                                        if (val.length > 0) {
                                            return `${t('Moved between')} ${new Date(val[0]).toISOString().split('T')[0] || '--'} ${t('and')} ${new Date(val[1]).toISOString().split('T')[0] || '--'}`;
                                        } else {
                                            return undefined;
                                        }
                                    }
                                }}
                            />
                        </div>
                    </Paper>
                    :
                    <BigIconMessage
                        iconName='report_off'
                        title={t('Your have no movements')}
                        message={`${t("This can be caused by not having made acquisitions")}. ${t("But if it is not the case, you should send an email to")} contato@delfosim.com`}
                    />
            }
        </div>
    );
};

export default View;