import {Auth} from 'aws-amplify'
import axios from 'axios'
import { toast } from 'react-toastify'

import { REDUCER_AUTH_LOGOUT, REDUCER_AUTH_VERIFY, REDUCER_AUTH_LOGIN } from '../constants/reducers/auth-action-events';
import { addCheckpoint, getActualState } from '../providers/get-data-api-n';
import {getDataOld} from '../actions/DataGetOld'
import { parks_info } from '../constants/graph-types';
import moment from "moment-timezone";

import {getDataGraphOldProvider} from '../providers/get-data-old-provider'

import { store } from '../store/ProviderStore';

import { getAuthedUserId } from '../helpers/users-helper';

export const getData = () => {
  return dispatch => {
    const apiName = 'https://0nkdr8bd1a.execute-api.us-east-1.amazonaws.com/dev/data-index';

    axios.get(apiName, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'eyJraWQiOiJvalFtNTNcL2I5ZytyS09TWXg4TTFaNFlzZnVWYzNkOG9qeDU3M2Z3ODk4WT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0MjJlYzAxMC02YTEwLTQwM2ItOTM4NC1mNjA2Yzg0YzEzM2QiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfWWVzU3piZHBQIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjp0cnVlLCJjb2duaXRvOnVzZXJuYW1lIjoiNDIyZWMwMTAtNmExMC00MDNiLTkzODQtZjYwNmM4NGMxMzNkIiwiYXVkIjoiNnRtdWp2ajNtY2ppbTVmN2VrM2VobW1nbGYiLCJldmVudF9pZCI6ImNmM2E4ZmUyLWFhNzQtMTFlOC1iNWRkLTA3MzIyNzQxYzY1NiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNTM1NDI3OTMwLCJwaG9uZV9udW1iZXIiOiIrNTU4NTk5OTY4MTAwMSIsImV4cCI6MTUzNTQzMTUzMCwiaWF0IjoxNTM1NDI3OTMwLCJlbWFpbCI6Imx1Y2FzLnRlbGVpbmZvQGdtYWlsLmNvbSJ9.TUiJEpu8a-2VVqOgh-Y6vK2uN8zokZn1LUWM0TAqv6zuZ5qBhlHsX45yfvF2HV6iNXq8xiQe0IpXzV8wMPonG85sZUDhtSMvBg-xAhSLGvvS6tSsuQ0jXh-iOkMJs-hE-i5TPqiMstRxyENGXuTJtMsxkfcxYyRLMnNYz7jK3XjNgHCiKTdbcZbGCTawCT_cqP3aunUkNUdHXQWsXuRyHESgnxJfSIqnDw9SJ75dEYmrKN9jkeRMD4rIy_bxp1WTp0X37DgrBER6lAbOpcZFP8FEAzWq6VHyDvKqaj_za5pvzFfYIhJ5NrPS_FXOOxNYDcEYU3j6UmNSxPNspzb1NA'
      },
      crossDomain: true
    })
      .then(response => {

        dispatch({
          type: 'ERROR',
          payload: response
        })
      })
      .catch(error => {
        console.error(error)

        dispatch({
          type: 'ERROR',
          payload: error
        })
      })
  }
}

export const verifyAuth = () => {
  return dispatch => {
    Auth.currentSession()
      .then(() => {
        dispatch({
          type: REDUCER_AUTH_VERIFY,
          payload: {isAuthenticated: true}
        })
      })
      .catch(error => {
        console.error(error)
        dispatch({ type: REDUCER_AUTH_VERIFY, payload: {isAuthenticated: false} })
      })
  }
}

export const destroy = (reset=true) => {
    Auth.currentSession()
      .then(result => {
        const {accessToken} = result
        const {jwtToken} = accessToken

        // const res = (async () => await addCheckpoint({}, jwtToken))();
      })
      .catch(error => {
        // dispatch({ type: REDUCER_AUTH_LOGOUT, payload: {isAuthenticated: false} })
      })
}

// export const logout = (reset=false) => {
//   return dispatch => {
//     Auth.currentSession()
//       .then(result => {
//         const {accessToken} = result
//         const {jwtToken} = accessToken

//         return Auth.signOut()
//           .then(() => {
//             localStorage.clear()
//             const caches_cleared = (async () => await caches.keys().then(keys => Promise.all(keys.map(key => caches.delete(key)))))()
//             dispatch({
//               type: REDUCER_AUTH_LOGOUT,
//               payload: {isAuthenticated: false, reset: true}
//             })
//           })

//           // return addCheckpoint({}, jwtToken, reset)
//           // .then(() => {
//           //   dispatch({
//           //     type: REDUCER_AUTH_LOGOUT,
//           //     payload: {isAuthenticated: false, reset: true}
//           //   })
//           //
//           //
//           //   return Auth.signOut()
//           //     .then(() => {
//           //
//           //       localStorage.clear()
//           //
//           //     })
//           //
//           // })

//       })
//       .catch(error => {
//         console.error('logout', 'currentSession', error)
//         dispatch({ type: REDUCER_AUTH_LOGOUT, payload: {isAuthenticated: false} })
//         localStorage.clear()
//       })
//   }
// }

export const logout = (userId, keepAlive=false, force=false) => async dispatch => {
    try {
        const id = getAuthedUserId();        

        if(!id && !force) {
            throw new Error('No selected user, unable to execute logout.');
        }

        await Promise.resolve(Auth.signOut());

        if(force) {
          return (
              dispatch({ 
                  type: REDUCER_AUTH_LOGOUT, 
                  id: null,
                  keepAlive: false
              })
          );
        } else {
          return (
              dispatch({ 
                  type: REDUCER_AUTH_LOGOUT, 
                  id,
                  keepAlive
              })
          );
        }
    } catch(err) {
        console.log('Error signing out!', err);
        return (
            dispatch({ 
                type: REDUCER_AUTH_LOGOUT, 
                error: err
            })
        );
    }
}

window.Logout = logout();
window.LogoutKeepAlive = logout(null, true);
window.LogoutForce = logout(null, false, true);
window.Auth = Auth;
window.Store = store;

window.AddUser = ({
  username,
  password,
  email='samuel.lime@delfosim.com',
  phone_number='+5585996684665',
  name,
  clientId,
  role='admin'
}) => {
  const nameTokens = name.split(' ');

  Auth.signUp({
      username,
      password,
      attributes: {
          email,
          phone_number,
          name,
          family_name: nameTokens[nameTokens.length - 1],
          nickname: nameTokens[0],
      'custom:client': clientId,
      'custom:role': role
      }
  });
}

// Auth.signUp({
//     'username': 'eduardo.braga',
//     'password': '134679258',
//     'attributes': {
//         'email': 'eduardo.braga@delfosim.com',
//         'phone_number': '+5585988578747',
//         'name': 'Eduardo Test',
//         'family_name': 'Test',
//         'nickname': 'Dudu',
// 		'custom:client': '98',
// 		'custom:role': 'dev'
//     }
// });

// export const login = (username, password) => {
//   return dispatch => {
//     Auth.signIn(username, password)
//       .then((data) => {
//         if (data.challengeName && data.challengeName === "NEW_PASSWORD_REQUIRED") {
//           return Auth.completeNewPassword(data, 'Delfos@#2018')
//         }

//         return data
//       })
//       .then((data) => {
//         const {type: pinfo_type, name: pinfo_name} = parks_info
//         console.log(parks_info)
//         getDataGraphOldProvider(pinfo_name, {})
//           .then(result => {
//             const caches_cleared = (async () => await caches.keys().then(keys => Promise.all(keys.map(key => caches.delete(key)))))()

//             dispatch({
//               type: pinfo_type,
//               payload: result
//             })
//             dispatch({
//               type: REDUCER_AUTH_LOGIN,
//               payload: { isAuthenticated: true }
//             })
//           })
//           .catch(error => {
//             console.log('cannot get parks info', error)
//           })
//       })
//       .catch(error => {
//         console.error('login', error)
//         dispatch({ type: REDUCER_AUTH_LOGOUT, payload: {isAuthenticated: false} })
//         toast.error('User or password invalid!', {
//           position: "top-right",
//           // autoClose: 5000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           // draggable: true
//         })
//       })
//   }
// }

export const login = (u_name, password) => async dispatch => {
    try {
        // console.log('BEFORE GET AUTHED INFO')
        const authedInfo = await Promise.resolve(Auth.signIn(u_name, password));
        // console.log('ON NEW LOGIN ACTION', authedInfo);

        const { signInUserSession } = authedInfo;
        const { idToken } = signInUserSession;
        const { payload } = idToken;
        const { 
            auth_time: authTimestamp,
            sub: id,
            ['cognito:username']: username,
            ['custom:client']: clientId,
            ['custom:role']: role,
            email_verified: accessEnabled,
            name
        } = payload;

        // if(!accessEnabled) {

        // }

        return (
            dispatch({
                type: REDUCER_AUTH_LOGIN,
                authTimestamp: authTimestamp * 1000,
                id,
                username,
                clientId,
                role,
                accessEnabled,
                name
            })
        );
    } catch (err) {
        console.error('ON login ERROR: ', err);
        return (
            dispatch({ 
                type: REDUCER_AUTH_LOGOUT, 
                error: err
            })
        );
    }
}

//  TODO
// export const clearAuthError 