import * as R from 'ramda'
import moment from 'moment-timezone'

import { REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA } from '../../../../../../constants/reducers/widget-page-action-events';
import {store} from '../../../../../../store/ProviderStore'
import graphTypes from '../../../../../../constants/graph-types'
import { getDataGraphOldProvider } from '../../../../../../providers/get-data-old-provider';

export const updateFilterSubparks = (id_page, subparks) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const {DelfosPreviousManagement} = Dashboard

    const management = R.find(R.propEq('id', id_page))(DelfosPreviousManagement)

    const managementFilter = R.assocPath(['filter', 'subparks'], subparks, management)
    const managements = DelfosPreviousManagement.filter(elm => elm.id !== management.id)

    dispatch({
      type: REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA,
      payload: R.append(managementFilter, managements)
    })
  }
}

export const updateFilterTimeRange = (id_page, timeRange) => {
  return dispatch => {
    const {Dashboard} = store.getState()
    const {DelfosPreviousManagement} = Dashboard

    const management = R.find(R.propEq('id', id_page))(DelfosPreviousManagement)

    const managementFilter = R.assocPath(['filter', 'data_range'], timeRange, management)
    const managements = DelfosPreviousManagement.filter(elm => elm.id !== management.id)

    dispatch({
      type: REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA,
      payload: R.append(managementFilter, managements)
    })
  }
}

export const toggleWidgetGlobalFilter = (id_page, id_widget) => {
  return dispatch => {
    const {Dashboard, User} = store.getState()
    const {DelfosPreviousManagement} = Dashboard
    const {parks_info} = User

    const management = R.find(R.propEq('id', id_page))(DelfosPreviousManagement)
    const managements = DelfosPreviousManagement.filter(elm => elm.id !== management.id)

    const {widgets} = management

    const widget = R.find(R.propEq('id', id_widget))(widgets)
    const widgetsOthers = widgets.filter(elm => elm.id !== id_widget)

    const {data_range, subparks: subparksFilter} = widget.filter
    const subparks = R.flatten(parks_info.map(elm => elm.subparks))

    const dataRange = {
      startDate: widget.filterGlobal
        ? (data_range.startDate? data_range.startDate: moment().subtract(2, 'days').format('YYYY-MM-DD'))
        : (management.filter.data_range.startDate? management.filter.data_range.startDate: moment().subtract(2, 'days').format('YYYY-MM-DD')),
      endDate: widget.filterGlobal
        ? (data_range.endDate? data_range.endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'))
        : (management.filter.data_range.endDate? management.filter.data_range.endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')),
    }

    const subparksToFilter = widget.filterGlobal
      ? (subparksFilter.length > 0? subparksFilter: R.slice(1, 3, subparks))
      : (management.filter.subparks.length > 0? management.filter.subparks: R.slice(1, 3, subparks))

    const graphType = R.find(R.propEq('type', widget.type))(graphTypes)

    const params = {
      subpark: subparksToFilter.map(elm => elm.id),
      time_range: [
        dataRange.startDate,
        dataRange.endDate
      ],
      timezone: 'America/Fortaleza'
    }

    getDataGraphOldProvider(graphType.name, params)
      .then(data => {
        const widgetToggleFilter = R.assoc('filterGlobal', !widget.filterGlobal, widget)
        const widgetResult = R.assoc('data', data.result, widgetToggleFilter)
        const widgetsResult = R.append(widgetResult, widgetsOthers)
        const managementResult = R.assoc('widgets', widgetsResult, management)

        dispatch({
          type: REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA,
          payload: R.append(managementResult, managements)
        })
      })
      .catch(error => {
        console.error('getDataGraphOldProvider', error)
        dispatch({ type: 'ASASasdasd23e2wd', payload: {} })
      })
  }
}
