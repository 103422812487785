import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/ActionStyle';
import Slider from '@material-ui/lab/Slider';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { toast } from 'react-toastify';
import { SelectorContext } from './Contexts';
import { withTranslation } from 'react-i18next';


class TimeSlider extends Component {
    constructor(props) {
        super(props);

        const startTime = moment().subtract(2, 'days');
        const endTime = moment().subtract(1, 'days');
        const splitPoint = moment().subtract(1.5, 'days');
        const sliderWidth = Math.abs(endTime.diff(startTime));
        const sliderPoint = sliderWidth / 2;

        this.state = {
            startTime,
            endTime,
            splitPoint,
            sliderWidth,
            sliderPoint
        };
    }

    recalculateBounds = (startTime, sliderWidth, cutPoint = 2) => {
        const sliderPoint = sliderWidth / cutPoint;
        const splitPoint = startTime.clone().add(sliderPoint, 'ms');

        return {
            splitPoint,
            sliderPoint
        };
    }

    handleChange = (event, value) => {
        const { sendData } = this.context;
        const { startTime, sliderWidth, endTime } = this.state;
        const cutPoint = 100 / value;

        const newState = this.recalculateBounds(startTime, sliderWidth, cutPoint);

        const disabled = newState.splitPoint.format('YYYY-MM-DD HH:mm:ss') == startTime.format('YYYY-MM-DD HH:mm:ss') || newState.splitPoint.format('YYYY-MM-DD HH:mm:ss') == endTime.format('YYYY-MM-DD HH:mm:ss')

        let message = false;

        if (disabled && !message) {

            message = true

            toast.warn(`Split date not can be equals start or end time!`, {
                position: 'top-right',
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });



        } else {
            sendData({ cut_time: newState.splitPoint.format('YYYY-MM-DD HH:mm:ss') });
        }

        this.setState({
            ...newState
        });




    };

    componentDidMount() {
        const { selectedRows, actionType, sendData } = this.context;

        if (actionType === 'split') {
            const startTime = moment.min(selectedRows.map(row => moment.tz(row['start_time'], 'America/Fortaleza')));
            const endTime = moment.max(selectedRows.map(row => moment.tz(row['end_time'], 'America/Fortaleza')))
            const sliderWidth = Math.abs(endTime.diff(startTime));

            const newState = this.recalculateBounds(startTime, sliderWidth);

            sendData({ cut_time: newState.splitPoint.format('YYYY-MM-DD HH:mm:ss') });

            this.setState({
                ...newState,
                startTime,
                endTime,
                sliderWidth
            });
        }
    }

    render() {
        const { classes, t } = this.props;
        const {
            startTime,
            endTime,
            sliderWidth,
            sliderPoint,
            splitPoint
        } = this.state;

        const value = (sliderPoint / sliderWidth) * 100;

        return (
            <div className={classes.verticalAlign}>
                <div className={classes.sliderRoot}>
                    <Slider
                        classes={{ thumb: classes.slider }}
                        value={value}
                        aria-labelledby="label"
                        onChange={this.handleChange}
                        thumb={
                            <div className={classes.sliderThumbContainer}>
                                <Typography id="label" variant='button'>{splitPoint.format('YYYY-MM-DD HH:mm:ss')}</Typography>
                                <Icon>alarm_off</Icon>
                            </div>
                        }
                    />
                </div>
                <div className={classes.horizontalAlign}>
                    <Typography variant='subtitle2'>{`${t('Start Time')}: ${startTime.format('YYYY-MM-DD HH:mm:ss')}`}</Typography>
                    <Typography variant='subtitle2'>{`${t('End Time')}: ${endTime.format('YYYY-MM-DD HH:mm:ss')}`}</Typography>
                </div>
            </div>
        );
    }
}

TimeSlider.contextType = SelectorContext;

export default withTranslation()(withStyles(styles)(TimeSlider));