import MUIDataTable from "mui-datatables";
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import {
    OverflowTextView
} from './components/TableCell';

const getMuiTheme = params => {
    const {
        hideCheckboxes = true,
        hideMasterCheckbox = true,
        hideToolbar = true,
        actionsBar,
        background,
        backgroundDefault
    } = params || {};

    return createMuiTheme({
        overrides: {
            MUIDataTableBodyRow: {
                root: {
                    padding: 0,
                    backgroundPosition: 'center !important',
                    transition: 'background 0.3s !important',
                    background: `${backgroundDefault || 'white'} !important`,

                    '&:hover': {
                        background: `${background || '#fccfbd'} !important`
                    },

                    '&:active': {
                        background: `${background || '#ff8a5c'} !important`,
                        backgroundSize: '100% !important',
                        transition: 'background 0s !important'
                    }
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    padding: 6
                },
                cellStacked: {
                    background: 'inherit !important',
                    backgroundColor: 'inherit !important'
                }
            },
            MUIDataTableSelectRow: {
                root: {
                    display: !hideCheckboxes ? undefined : 'none',
                    padding: 0
                }
            },
            MUIDataTableSelectCell: {
                root: {
                    display: !hideCheckboxes ? undefined : 'none',
                    padding: 0
                },
                headerCell: {
                    visibility: !hideMasterCheckbox ? undefined : 'hidden',
                    borderBottom: '1px solid #e0e0e0'
                },
                fixedHeader: {
                    background: 'inherit !important',
                    backgroundColor: 'inherit !important'
                }
            },
            MUIDataTableHeadCell: {
                fixedHeader: {
                    zIndex: 'none !important'
                }
            },
            MUIDataTableToolbarSelect: {
                root: {
                    visibility: !hideToolbar ? undefined : 'hidden',
                    padding: 0,
                    paddingTop: actionsBar ? 0 : undefined,
                    paddingBottom: actionsBar ? 0 : undefined,
                },
                title: {
                    display: actionsBar ? 'none' : undefined
                }
            }
        }
    });
};

const MuiDataTableView = props => {

    const refTable = useRef(null);
    const { t } = useTranslation();

    const {
        records = [],
        displayHeaders,
        disableSelect = false,
        headFormatter = (val) => val,
        fieldFormatter = (index, head, row) => row[head],
        filterFormatter = null,
        filterLogic = undefined,
        filterDisplay = undefined,
        columnStyleFormatter = (head) => { },
        rowClickAction = (index, headerCell, row) => () => null,
        rowsPerPageOptions = [8, 16, 32],
        hideCheckboxes = true,
        actionsBar,
        title,
        onDownload = (buildHead, buildBody, columns, data) => {

            const { current } = refTable || {};
            const { state } = current || {};
            const { displayData } = state || {};

            const filteredData = data.filter(row => {
                return displayData.find(displayRow => {
                    return displayRow.dataIndex === row.index;
                });
            });

            const displayColumns = columns.map(column => {
                return { ...column, name: column.label };
            });

            let result = buildHead(displayColumns);
            if (filteredData.length > 0) {
                result += buildBody(filteredData);
            }

            return result;
        },
        initialFilterList = {},
        onFilterChange = undefined,
        onColumnSortChange = undefined,
        sortState = {},
        useCustomDisplay = [],
        chipFormatter = {},
        drillsDown,
        download
    } = props;

    const columns = displayHeaders.map(headerId => {

        let options = {
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => (
                <OverflowTextView
                    key={`header-${tableMeta.columnIndex}`}
                    align='left'
                    padding='checkbox'
                    colSpan={2}
                    style={{
                        minWidth: '40px',
                        maxWidth: '400px',
                        ...columnStyleFormatter(headerId)
                    }}
                >
                    {
                        updateValue != undefined ?
                            fieldFormatter(
                                tableMeta.columnIndex,
                                headerId,
                                tableMeta.rowData.map((val, index) => (
                                    {
                                        [displayHeaders[index]]: val
                                    }
                                )).reduce((acc, curr) => ({ ...acc, ...curr }), {})
                            )
                            :
                            fieldFormatter(
                                tableMeta.columnIndex,
                                headerId,
                                records[tableMeta.rowIndex]
                            )
                    }
                </OverflowTextView>
            )
        }

        const filterChoices = [
            ...new Set(
                records.filter(row => row[headerId] != null)
                    .map((row, index) => filterFormatter ?
                        filterFormatter(index, headerId, row)
                        :
                        fieldFormatter(index, headerId, row)
                    )
            )
        ].filter(value => value != undefined);

        const shouldFilter = filterChoices.length > 0;

        if (shouldFilter) {
            options = {
                ...options,
                ...{
                    filter: shouldFilter,
                    filterType: useCustomDisplay.indexOf(headerId) >= 0 ? 'custom' : 'multiselect',
                    filterOptions: {
                        names: useCustomDisplay.indexOf(headerId) >= 0 ? [] : filterChoices,
                        logic: filterLogic && filterLogic(headerId),
                        display: filterDisplay && filterDisplay(headerId)
                    },
                    ...(initialFilterList[headerId] && initialFilterList[headerId].length > 0 ? { filterList: initialFilterList[headerId] } : {}),
                    ...(sortState[headerId] ? { sortDirection: sortState[headerId] } : {}),
                    ...(chipFormatter[headerId] ? { customFilterListRender: chipFormatter[headerId] } : {})
                }
            }
        }


        return {
            name: headerId,
            label: headFormatter(headerId) ? t(headFormatter(headerId)) : t(headerId),
            options
        }
    });

    const options = {
        page: 0,
        rowsPerPageOptions,
        sort: records.length > 1,
        filter: records.length > 0,
        download: download !== undefined ? download : records.length > 0,
        rowsSelected: records.map((row, index) => row.selected ? index : null).filter(selected => selected != null),
        print: false,
        selectableRowsOnClick: !disableSelect,
        selectableRows: hideCheckboxes ? 'single' : 'multiple',
        onRowsSelect: ([{ index, dataIndex: rowIndex }], allRowsSelected) => {
            if (rowClickAction) {
                rowClickAction(rowIndex, records[rowIndex])()
            }
        },
        customSort: (data, colIndex, order) => {
            const canSort = data.map(row => row.data[colIndex] ? true : false).reduce((acc, curr) => acc || curr);

            if (canSort) {
                const sortedData = data.sort((row1, row2) => {
                    const val1 = row1.data[colIndex] || '-99999999';
                    const val2 = row2.data[colIndex] || '-99999999';
                    return (val1 < val2 ? -1 : 1) * (order === 'desc' ? 1 : -1);
                });

                return sortedData;
            } else {
                return data;
            }
        },
        customToolbarSelect: actionsBar,
        onDownload,
        onFilterChange,
        onColumnSortChange,
        onTableChange: action => {
            if (drillsDown && refTable && refTable.current && refTable.current.changePage && action === 'rowsSelect') {
                refTable.current.changePage(0);
            }
        },
        textLabels: {
            body: {
                noMatch: t("Sorry, no matching records found"),
                toolTip: t("Sort"),
            },
            pagination: {
                rowsPerPage: `${t('Rows per page')}:`
            },
            toolbar: {
                search: t("Search"),
                downloadCsv: `${t('Download')} CSV`,
                viewColumns: t("View Columns"),
                filterTable: t("Filter Table"),
            },
            filter: {
                title: t("Filters"),
                reset: t("Reset"),
            },
            viewColumns: {
                title: t("Show Columns"),
            }
        }
    }

    return (
        <MuiThemeProvider
            theme={
                getMuiTheme({ ...props })
            }
        >
            <MUIDataTable
                innerRef={refTable}
                title={title}
                data={records}
                columns={columns}
                options={options}
            />
        </MuiThemeProvider>
    );
};

export default MuiDataTableView;