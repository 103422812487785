import React from 'react'
import {Route} from 'react-router-dom'

import AssetListPage from './pages/AssetListPage/AssetListPage';
import { ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_EDIT, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CREATE } from '../../../../../../../../constants/route-constant';
import AssetChangePage from './pages/AssetChangePage/AssetChangePage';

class AssetPage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          exact
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET}
          render={props => (<AssetListPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_CREATE}
          render={props => (<AssetChangePage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_ASSET_EDIT}
          render={props => (<AssetChangePage {...props} />)}
        />
      </div>
    )
  }
}

export default AssetPage
