import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/EventsTableStyle';
import {
    ACTION_ICONS
} from './placeholder-data/FixedDataFromAPI';
import { DialogContext } from './Contexts';
import { withTranslation } from 'react-i18next';

const ActionButton = ({ classes, action, selectedAmt = 0, t }) => (
    <Tooltip
        title={t(action.name)}
        enterDelay={100}
        placement='top-end'
    >
        <div className={classes.title}>
            <DialogContext.Consumer>
                {
                    provider => {
                        return (
                            <Fab
                                className={`${classes.actionFab} ${selectedAmt == 0 ? classes.labelDarkGrey : ''}`}
                                onClick={provider.toggleDialog}
                            >
                                <Icon>{ACTION_ICONS[action.id]}</Icon>
                            </Fab>
                        )
                    }
                }
            </DialogContext.Consumer>
        </div>
    </Tooltip>
);

export default withTranslation()(withStyles(styles)(ActionButton));