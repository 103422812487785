import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles/EventsTableStyle';
import MainForm from './MainForm';
import TimeSlider from '../TimeSlider';
import {
    SELECTORS
} from '../placeholder-data/FixedDataFromAPI';

class SplitForm extends Component { 
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const selectors = SELECTORS.filter(selector => selector.type !== 'date');

        return (
            <div className={`${classes.verticalAlign} ${classes.disableOverflowX}`} >
                <div className={classes.horizontalAlign} >
                    <TimeSlider />
                </div>
                <div className={classes.horizontalAlign} >
                    <div className={`${classes.verticalAlign} ${classes.lateralPaggingSM}`} >
                        <MainForm isVertical={true} selectors={selectors} side={'left'} />
                    </div>
                    <div className={classes.verticalDivider} />
                    <div className={`${classes.verticalAlign} ${classes.lateralPaggingSM}`} >
                        <MainForm isVertical={true} selectors={selectors} side={'right'} />
                    </div>
                </div>
            </div>
        );
    }
};

export default withStyles(styles)(SplitForm);