import React, {
    Fragment
} from 'react';

import {
    Tooltip,
    MenuItem,
    Avatar,
    Icon,
    Typography
} from '@material-ui/core';

const LABELS_BY_EVENT = {
    powerLimitations: 'Power Limitation',
    maintenances: 'Maintenance'
}

const COLORS_BY_EVENT = {
    powerLimitations: '#fedc56',
    maintenances: '#9dc183'
}

const ICONS_BY_EVENT = {
    powerLimitations: 'flash_off',
    maintenances: 'build'
}

const NotificationItem = props => {
    const { 
        id,
        content,
        eventType,
        setOpen,
        setAnchorEl
    } = props;

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };
    
    return (
        <MenuItem 
            key={id} 
            onClick={handleClose}
            style={{
                height: '100%',
                padding: 8
            }}
        >
            <Tooltip
                title={LABELS_BY_EVENT[eventType]}
            >
                <Avatar 
                    style={{
                        marginRight: 16, 
                        backgroundColor: COLORS_BY_EVENT[eventType],
                        width: 32,
                        height: 32
                    }}
                >
                    <Icon 
                        style={{
                            color: 'white',
                            fontSize: 24
                        }}
                    >
                        {ICONS_BY_EVENT[eventType]}
                    </Icon>
                </Avatar>
            </Tooltip>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    overflow: 'hidden'
                }}
            >
                <Tooltip
                    title={`Alarm: ${content.alarm}`}
                >
                    <Typography
                        noWrap 
                        variant='h3'
                        component='h5'
                        style={{
                            fontSize: '.8em',
                            marginBottom: '2px'
                        }}
                    >
                        {content.alarm}
                    </Typography>
                </Tooltip>
                <Tooltip
                    title={content.park.map(park => (
                        <p>{park}</p>
                    ))}
                >
                    <Typography
                        noWrap 
                        variant='body1'
                        component='p'
                        style={{
                            fontSize: '.6em',
                            // color: '#5e5e5e'
                            color: 'black'
                        }}
                    >
                        at Parks: {content.park.join(', ')}
                    </Typography>
                </Tooltip>
                <Tooltip
                    title={
                        <div 
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                                width: '100%'
                            }}
                        >
                            {
                                content.device.join(', ')
                                // content.device.map(device => (
                                //     <p 
                                //         style={{
                                //             flex: 1, 
                                //             minWidth: '20%',
                                //             maxWidth: '20%'
                                //         }}
                                //     >
                                //         {device}
                                //     </p>
                                // ))
                            }
                        </div>
                    }
                >
                    <Typography
                        noWrap 
                        variant='body1'
                        component='p'
                        style={{
                            fontSize: '.6em',
                            // color: '#5e5e5e'
                            color: 'black'
                        }}
                    >
                        in Devices: {content.device.join(', ')}
                    </Typography>
                </Tooltip>
                <Typography
                    noWrap 
                    variant='body1'
                    component='p'
                    style={{
                        fontSize: '.55em',
                        // color: '#5e5e5e',
                        marginTop: '6px',
                        color: 'black'
                    }}
                >
                    {content.startTime.replace('T' , ' ').split('.')[0]} ~ {content.endTime ? content.endTime.replace('T' , ' ').split('.')[0] : 'now'}
                </Typography>
            </div>
        </MenuItem>
    );
};

export default NotificationItem;