import React, {Fragment} from 'react'
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'

import configureStore from './configureStore'

export const { store, persistor } = configureStore()

window.Persistor = persistor;

const ProviderStore = ({children, loading}) => {
    return (
        <Provider store={store}>
            <PersistGate loading={loading} persistor={persistor}>
                <Fragment>
                    {children}
                </Fragment>
            </PersistGate>
        </Provider>
    )
}

export default ProviderStore
