import React from 'react'

class ServicePage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        ServicePage
      </div>
    )
  }
}

export default ServicePage
