import { REDUCER_UPDATE_DATE } from '../../../../constants/reducers/global-filter';
import moment from 'moment-timezone';


const DEFAULT_STATE = {
  data_range: {
    startDate: `${moment().subtract(8, 'days').format('YYYY-MM-DD')} 00:00:00`,
    endDate: `${moment().subtract(1, 'days').format('YYYY-MM-DD')} 23:59:59`
  }
};

export default (state = DEFAULT_STATE, action) => {
  const { type } = action;

  switch (type) {
    case REDUCER_UPDATE_DATE:
      return action.payload;
    default:
      return state;
  };
};