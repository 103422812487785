import React, {Component, Switch} from 'react'
import {Route, Redirect} from 'react-router-dom'

import {
  Tabs,
  Tab,
  Typography,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  CircularProgress,
  Chip,
  Avatar,
  TextField,
  Paper,
  AppBar,
  Toolbar
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import {styles} from '../Header/HeaderWithStyle'
import { HeaderContent, AppBarLoading, reverse_loading, loading } from '../Header/HeaderStyle'
import LogoComponent from '../Header/LogoComponent'

class HeaderPlaceholder extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  render() {
    const {classes} = this.props

    return (
      <React.Fragment>
        <HeaderContent>
          <AppBarLoading
            id={'HeaderPlaceholderAppBar'}
            elevation={0}
          >
            <Toolbar className={classes.appBarToolbar}>
              <LogoComponent />
              <Chip
                label=''
                color={'#bdbdbd'}
                style={{
                  'animation': `${reverse_loading} 3s ease`,
                  'animation-iteration-count': 'infinite',
                  'z-index': 999,
                  'width': '8%',
                  'margin-right': '2%'
                }}
              />
              <Chip
                label=''
                color={'#bdbdbd'}
                style={{
                  'animation': `${reverse_loading} 3s ease`,
                  'animation-iteration-count': 'infinite',
                  'z-index': 999,
                  'width': '8%',
                  'margin-right': '2%'
                }}
              />
              <Chip
                label=''
                color={'#bdbdbd'}
                style={{
                  'animation': `${reverse_loading} 3s ease`,
                  'animation-iteration-count': 'infinite',
                  'z-index': 999,
                  'width': '8%',
                  'margin-right': '2%'
                }}
              />
              <Chip
                label=''
                color={'#bdbdbd'}
                style={{
                  'animation': `${reverse_loading} 3s ease`,
                  'animation-iteration-count': 'infinite',
                  'z-index': 999,
                  'width': '8%',
                  'margin-right': '2%'
                }}
              />
              <div style={{ flex: 1 }}></div>
              <Icon style={{ color:'#bdbdbd', fontSize:'36px'}}>account_circle</Icon>
            </Toolbar>
          </AppBarLoading>
        </HeaderContent>
        <div className={classes.appBarToolbarSubRoot}>
          <Toolbar className={classes.appBarToolbarSub} variant="dense"
            style={{
              'animation': `${loading} 3s ease`,
              'animation-iteration-count': 'infinite',
              'z-index': 999
            }}
          >
            <Chip
              label=''
              color={'#bdbdbd'}
              style={{
                'animation': `${reverse_loading} 3s ease`,
                'animation-iteration-count': 'infinite',
                'z-index': 999,
                'width': '10%',
                'margin-right': '1%'
              }}
            />
            <Chip
              label=''
              color={'#bdbdbd'}
              style={{
                'animation': `${reverse_loading} 3s ease`,
                'animation-iteration-count': 'infinite',
                'z-index': 999,
                'width': '10%',
                'margin-right': '1%'
              }}
            />
            <Chip
              label=''
              color={'#bdbdbd'}
              style={{
                'animation': `${reverse_loading} 3s ease`,
                'animation-iteration-count': 'infinite',
                'z-index': 999,
                'width': '10%',
                'margin-right': '1%'
              }}
            />
            <Chip
              label=''
              color={'#bdbdbd'}
              style={{
                'animation': `${reverse_loading} 3s ease`,
                'animation-iteration-count': 'infinite',
                'z-index': 999,
                'width': '10%',
                'margin-right': '1%'
              }}
            />
          </Toolbar>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(HeaderPlaceholder)
