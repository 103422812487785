const styles = theme => ({
    root: { 
        width: '100%',
        height: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: '20%',
        display: 'inline-flex',
        height: 'inherit',
        overflowY: 'auto'
    },
    column: {
        flex: 1
    },
    rowContent: {
        display: 'inline-flex',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        alignItems: 'center',
        padding: '0 !important',
        height: '26px',
        borderRight: '1px rgba(224, 224, 224, 1) solid',
        borderBottom: '1px rgba(224, 224, 224, 1) solid',

        '&:hover': {
            textOverflow: 'initial',
            overflow: 'visible'
        }
    },
    selectBt: {
        padding: '4px'
    },
    cellLeafText: {
        fontSize: '.75em',

        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    cellText: {
        fontSize: '.75em',
        cursor: 'pointer',

        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    smallBt: {
        fontSize: '14px'
    }
});

export default styles;