import { getDataGraphNewProvider, getDataEnvelopeProvider, executeCustomProvider, executePredictionProvider } from '../providers/get-data-old-provider';
import { store } from '../store/ProviderStore'
import moment from "moment-timezone";

const getWidgetData = (filter, widgetType, customProviderInfo) => {
    if (filter && filter.predictive) {
        const { Dashboard } = store.getState();
        const { DeviceFilter } = Dashboard || {};
        const { devices } = DeviceFilter || {};

        const payload = {
            start_time: filter.data_range.startDate.split(' ')[0],
            end_time: moment(filter.data_range.endDate).add(1, 'days').startOf('day').format('YYYY-MM-DD'),
            device_id: devices && devices.length > 0 ? devices[0] : filter.devices[0],
            model_id: filter.envelope_id,
            path: filter.apiRoute || '/'
        };

        const data = executePredictionProvider(payload);
        return data;
    }

    const params = filter.hasOwnProperty('envelope_id') ?
        {
            time_range: [
                filter.data_range.startDate,
                moment(filter.data_range.endDate).add(1, 'days').startOf('day').format('YYYY-MM-DD')
            ],
            device_list: filter.devices,
            procedure_name: widgetType,
            timezone: 'America/Fortaleza',
            envelope_id: filter.envelope_id
        }
        :
        {
            time_range: [
                filter.data_range.startDate,
                moment(filter.data_range.endDate).add(1, 'days').startOf('day').format('YYYY-MM-DD')
            ],
            subpark_list: filter.subparks,
            device_list: filter.devices,
            procedure_name: widgetType,
            timezone: 'America/Fortaleza'
        };

    if (customProviderInfo) {
        const data = executeCustomProvider({ ...params, ...customProviderInfo });
        return data;
    }

    if (filter.hasOwnProperty('envelope_id')) {
        return getDataEnvelopeProvider(params)
            .catch(e => ({ error: true, message: e }));
    } else {
        try {
            if (params.device_list.length === 1) {
                const { Client } = store.getState() || {};
                const filteredDevice = Client.devices.find(device => device.id === params.device_list[0]);
                if (filteredDevice) {
                    window._paq.push([
                        'trackSiteSearch',
                        filteredDevice.name,
                        false,
                        false
                    ]);
                }
            } else {
                const { Client } = store.getState() || {};
                Client.parks.forEach(park => {
                    const parksSubparks = Client.subparks.filter(subpark => subpark.park_id === park.id);
                    const selectedSubparks = parksSubparks.filter(subpark => params.subpark_list.find(subparkId => subparkId === subpark.id));

                    if (parksSubparks.length === selectedSubparks.length) {
                        window._paq.push([
                            'trackSiteSearch',
                            park.name,
                            false,
                            false
                        ]);
                    } else {
                        selectedSubparks.forEach(subpark => {
                            window._paq.push([
                                'trackSiteSearch',
                                subpark.name,
                                false,
                                false
                            ]);
                        });
                    }
                });
            }
            window._paq.push([
                'trackSiteSearch',
                `${params.time_range[0]} ${params.time_range[1]}`,
                false,
                false
            ]);
        } catch { }


        return getDataGraphNewProvider(null, null, params)
            .catch(e => ({ error: true, message: e }));
    }
};

export const applyFilter = ({ filter, widgets, type, predictive }) => {
    let unique = false;

    if (type === 'FILTER_TYPE_WTG_ENVELOPE' || type === 'FILTER_TYPE_WTG') {
        unique = true;
    }



    const { Dashboard, Client } = store.getState()

    filter.data_range = Dashboard.DateFilter.data_range ? Dashboard.DateFilter.data_range : filter && filter.data_range ? filter.data_range : {};

    if (Dashboard.DeviceFilter.devices && Dashboard.DeviceFilter.devices[0] !== undefined) {

        filter.devices = unique ? [Dashboard.DeviceFilter.devices[0]] : Dashboard.DeviceFilter.devices;

    }


    if (Dashboard.DeviceFilter.subparks && Dashboard.DeviceFilter.subparks[0] !== undefined) {

        filter.subparks = Dashboard.DeviceFilter.subparks;

    }

    if (!unique && filter && filter.devices && filter.devices.length <= 1) {
        const devices = Client.devices.filter(device => filter.subparks.find(subpark => subpark === device.subpark_id)).map(d => d.id);
        filter.devices = devices;
    }


    if (Dashboard.EnvelopeFilter.envelope_id !== undefined && filter.envelope_id) {

        filter.envelope_id = Dashboard.EnvelopeFilter.envelope_id;

    }


    const treatedWidgets = widgets.map(widget => ({
        ...widget,
        filter: filter,
        data: getWidgetData({ ...filter, predictive, apiRoute: widget.apiRoute }, widget.type, widget.providerInfo),
        loading: true
    }));

    return treatedWidgets;
};