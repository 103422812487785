import grey from '@material-ui/core/colors/grey'
import orange from '@material-ui/core/colors/orange'

import { STYLE_SUBHEADER_TOOLBAR } from '../../constants/style-constant'

const styles = theme => ({
  paperMenuBackground: {},
  appBar: {
    backgroundColor: 'white',
    zIndex: 999
  },
  appBarPlaceholder: {
    backgroundColor: '#f3f3f3',
    background: '#f3f3f3',
    zIndex: 999
  },
  buttonIconDropDown: {
    marginLeft: '1px'
  },
  appBarToolbar: {
    borderBottom: `1px solid ${grey['200']}`
  },
  appBarToolbarSub: {
    borderBottom: `1px solid ${grey['200']}`,
    minHeight: '0',
    height: STYLE_SUBHEADER_TOOLBAR,
    paddingLeft: '0'
  },
  buttonMenu: {
    textTransform: 'capitalize',
    fontWeight: '200',
    fontSize: '16px',
    borderRadius: '0'
  },
  buttonMenuSubHeader: {
    textTransform: 'capitalize',
    fontWeight: '200',
    fontSize: '16px',
    minHeight: '40px',
    borderRadius: '0'
  },
  buttonMenuSubHeaderSelect: {
    textTransform: 'capitalize',
    fontWeight: '200',
    fontSize: '16px',
    borderRadius: '0',
    backgroundColor: orange['700'],
    color: 'white',
    minHeight: '40px',
    '&:hover': {
      backgroundColor: orange['900'],
    }
  },
  progressSaving: {
    color: orange[500],
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1000
  }
})

export {styles}
