import React, {Component} from 'react'
import {withStyles, List, ListSubheader, ListItem, ListItemText, Paper, Checkbox} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { WIDGET_HEADER_HEIGHT } from '../../constants/widget-constant'
import * as R from 'ramda'
import { FILTER_TYPE_WTGS } from '../../constants/filter-types';

const styles = theme => ({
  paperElement: {
    border: `1px solid ${grey['200']}`,
    borderRadius: 0,
    flex: 1
  },
  paperHeader: {
    paddingLeft: '10px',
    minHeight: `${WIDGET_HEADER_HEIGHT}px`,
    borderBottom: `1px solid ${grey['200']}`,
    display: 'flex',
    alignItems: 'center'
  },
  listItems: {
    width: '100%',
    minWidth: 300,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
})

class FilterWtgs extends Component {
  changeValueFilter = wtg => () => {
    this.props.changeValueFilter(FILTER_TYPE_WTGS, wtg)
  }

  render () {
    const {classes, parks_info} = this.props

    const items = R.flatten(parks_info.map(park => {
      const subparks = park.subparks.map(subpark => {
        const item = R.assoc('name', `${subpark.name} - ${park.park}`, subpark)

        return item
      })

      return subparks
    }))

    return (
      <Paper className={classes.paperElement} elevation={0}>
        <div className={classes.paperHeader}>
          <div>
            Devices
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
        <List className={classes.listItems} subheader={<li />}>
          {items.map((subpark, indexSubpark) => (
            <li
              key={`filter_wtgs_subpark_${indexSubpark}`}
              className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader>
                  {subpark.name}
                </ListSubheader>
                {subpark.wtgs.map((wtg, indexWtg) => (
                  <ListItem
                    key={`filter_wtgs_wtg_${indexWtg}`}
                    role={undefined}
                    dense
                    button
                    onClick={this.changeValueFilter(wtg).bind(this)}
                  >
                    <Checkbox
                      checked={false}
                      tabIndex={-1}
                    />
                    <ListItemText primary={wtg.name} />
                  </ListItem>
                ))}
              </ul>
            </li>
          ))}
        </List>
      </Paper>
    )
  }
}

export default withStyles(styles)(FilterWtgs)
