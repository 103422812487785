import React from 'react';
import useContainer from './Container';
import useStyles from './styles';
import NestedList from './NestedList';
import CircularLoading from '../../../../components/Loading/CircularLoading';
import BaseGlobalFilter from '../../../../components/Header/BaseGlobalFilter';


export default props => {

    const { loading, items, filter } = useContainer();

    const { root } = useStyles();

    return loading ?
        <CircularLoading>
            <span>Getting your reports.</span>
        </CircularLoading>
        :
        <>
            <BaseGlobalFilter {...filter}  {...props} />
            <div className={root}>
                <NestedList title={'Reports'} items={items} />
            </div>;
        </>
};