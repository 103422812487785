import React, {
    Fragment,
    useState
} from 'react';

import {
    withStyles,
    Icon,
    IconButton,
    Badge
} from '@material-ui/core';

import styles from './NotificationsButtonStyles';

import NotificationsContainer from './NotificationsContainer';

const NotificationsButtonView = props => {
    const {
        classes
    } = props;

    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ open, setOpen ] = useState(false);
    const [ incomming, setIncomming ] = useState(0);

    const handleClick = event => {
        // setIncomming(0);
        setOpen(!open);
        setAnchorEl(event.currentTarget);
    }

    return (
        <Fragment>
            <IconButton
                onClick={handleClick}
            >
                <Badge 
                    color='secondary' 
                    badgeContent={incomming} 
                    className={classes.margin}
                >
                    <Icon 
                        className={[
                            classes.buttonIcon, 
                            open && classes.buttonSelected, 
                            incomming > 0 && classes.incommingUpdates
                        ]}
                    >
                        {incomming > 0 ? 'notifications_active' : 'notifications'}
                    </Icon>
                </Badge>
            </IconButton>
            <NotificationsContainer 
                open={open}
                incomming={incomming}
                setOpen={setOpen}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setIncomming={setIncomming}
            />
        </Fragment>
    );
};

export default withStyles(styles)(NotificationsButtonView);