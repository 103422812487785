import React, { Component, } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularLoading from '../../../../components/Loading/CircularLoading';
import moment from 'moment';

import { getWtgStatus, getAlarms, getClassifications, getResponsibilities, getSystems } from './components/data-get/requests';
import adaptDataToStatusTable from './components/data-get/adaptDataToStatusTable';
import treatWtgStatus from './components/data-get/treatWtgStatus';

import FilterDateComponent from '../../../../components/FilterDateComponent/FilterDateComponent';
import { PageContext } from './components/Contexts';

import EventsTableFull from './components/EventsTableFull';

import GlobalDateRangeContainer from './components/GlobalDateRangeContainer';
import GlobalDevicesContainer from './components/GlobalDevicesContainer';

import { Icon } from '@material-ui/core';

import { Redirect } from 'react-router-dom';

import SelectorView from '../../../../components/Filter/Selector/View';
import { MultioptionsFab } from '../../../../new-components/Buttons';

import { withTranslation } from 'react-i18next';

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        overflowY: 'auto'
    },
    internal: {
        padding: '0 0 4vh 3.2vw',
        marginTop: theme.spacing.unit * 3,
    }
});

class EventsTablePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currTables: null,
            display: 1,
            filter: null,
            executeFilter: true,
            dateMenuOpen: false
        };

        window.addEventListener('resize', () => this.forceUpdate());
    }

    setFixedInfo = async () => {
        const classifications = await getClassifications();
        const responsibilities = await getResponsibilities();
        const systems = await getSystems();

        const { parksInfo } = this.props;

        const allScadaIdsNotTreatered = parksInfo.map(park => (
            park.scada &&
            park.scada.constructor === Array &&
            park.scada.map(scada => scada.id) ||
            []
        ))

        const allScadaIds = allScadaIdsNotTreatered && allScadaIdsNotTreatered.length > 0 ? [...new Set(allScadaIdsNotTreatered.reduce((acc, curr) => [...acc, ...curr], []))] : [];
        const allAlarmsArray = await Promise.all(allScadaIds.map(async scadaId => (
            {
                [scadaId]: await Promise.resolve(getAlarms({ scada_ids: [scadaId] }))
            }
        )));

        const allAlarmsPerScadaId = allAlarmsArray && allAlarmsArray.reduce((acc, curr) => ({ ...acc, ...curr }), {});

        this.setState({
            classifications,
            responsibilities,
            systems,
            allAlarmsPerScadaId
        })
    }

    refreshTables = async (force = false) => {
        const {
            filter,
            executeFilter,
            classifications,
            systems,
            responsibilities,
            allAlarmsPerScadaId
        } = this.state;

        if (executeFilter || force) {

            const PARAMS = filter && {
                ...filter,
                start_time: filter.start_time.format('YYYY-MM-DD'),
                end_time: filter.end_time.format('YYYY-MM-DD')
            };

            if (!PARAMS) {
                this.forceUpdate();
                return;
            }

            const RAW_DATA = await getWtgStatus(PARAMS);

            const rawTables = treatWtgStatus(RAW_DATA);

            const currTables = await Promise.all(rawTables.map(async table => {
                const alarmsArray = await table &&
                    table.scada_ids &&
                    allAlarmsPerScadaId &&
                    table.scada_ids.constructor === Array &&
                    table.scada_ids.map(scada_id => allAlarmsPerScadaId[scada_id]) ||
                    [];

                const Alarms = alarmsArray && alarmsArray.reduce((acc, curr) => [...acc, ...curr], []);

                return {
                    ...table,
                    alarms: Alarms,
                    systems,
                    classifications,
                    responsibilities
                }
            }));


            const treatedCurrTables = await adaptDataToStatusTable(currTables);

            const infoPerDevices = treatedCurrTables &&
                treatedCurrTables.map(deviceTable => ({
                    [deviceTable.deviceId]: {
                        id: deviceTable.deviceId,
                        name: deviceTable.device,
                        alarms: deviceTable.alarms,
                        systems: deviceTable.systems,
                        classifications: deviceTable.classifications,
                        responsibilities: deviceTable.responsibilities
                    }
                })).reduce((acc, curr) => ({ ...acc, ...curr }), {});

            // const records = this.tablesToRecords(treatedCurrTables).map(record => {
            //     record.checked = record.checked && record.checked == true ?  'Yes' : 'No' 
            //     return record
            // })

            const records = this.tablesToRecords(treatedCurrTables);

            this.setState({
                currTables: treatedCurrTables,
                executeFilter: false,
                records,
                infoPerDevices
            });
        }
    }

    componentDidMount() {
        this.setFixedInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            devices,
            subparks
        } = this.props;

        const { filter,
            executeFilter,
            systems,
            classifications,
            responsibilities,
            allAlarmsPerScadaId
        } = this.state;

        if (filter == null) {
            this.setState({
                filter: {
                    device_ids: devices,
                    subpark_ids: subparks,
                    start_time: moment.tz('America/Fortaleza').subtract(30, 'days'),
                    end_time: moment.tz('America/Fortaleza'),
                    timezone: 'America/Fortaleza'
                },
                executeFilter: true
            });
        } else if (
            executeFilter &&
            systems &&
            classifications &&
            responsibilities &&
            allAlarmsPerScadaId
        ) {
            if (filter.start_time && typeof (filter.start_time) === 'string') {
                try {
                    filter.start_time = moment.tz(filter.start_time, 'America/Fortaleza');
                } catch (err) {
                    console.log('Error on adjusting filter start time input', err, ' on filter ', filter);
                }
            }

            if (filter.end_time && typeof (filter.end_time) === 'string') {
                try {
                    filter.end_time = moment.tz(filter.end_time, 'America/Fortaleza');
                } catch (err) {
                    console.log('Error on adjusting filter start time input', err, ' on filter ', filter);
                }
            }

            this.refreshTables();
        } else {
            setTimeout(() => this.forceUpdate, 300)
        }
    }

    changeDateFilter = ({ date }) => {
        const { filter } = this.state;
        const start_time = moment.tz(date.startDate, filter.timezone);
        const end_time = moment.tz(date.endDate, filter.timezone);

        this.setState({
            filter: {
                ...filter,
                start_time,
                end_time
            },
            changedDateFilter: true,
            executeFilter: false
        })
    }

    executeDateFilter = () => {
        this.setState({
            records: null,
            currTables: null,
            dateMenuOpen: false,
            executeFilter: true,
            // changedDateFilter: false,
            // dispatchFilter: true
        });
    }

    changeDisplay = (e) => {
        this.setState({
            display: e.target.value
        });

    }

    openDateMenu = () => {
        this.setState({
            dateMenuOpen: true,
            executeFilter: false
        });
    }

    tablesToRecords = tables => {
        const records = tables &&
            tables.constructor === Array &&
            tables.map(table => table.rows.map(row => ({
                selected: false,
                deviceId: table.deviceId,
                deviceName: table.device,
                ...row
            }))).reduce((acc, curr) => ([...acc, ...curr]), []);

        return records;
    }

    onActionSuccess = success => {
        this.setState({ success });
    };

    render() {

        const { t } = this.props;

        const {
            records,
            filter,
            dateMenuOpen,
            infoPerDevices,
            executeFilter,
            goToPage
        } = this.state;

        const {
            device_ids: devices,
            subpark_ids: subparks
        } = filter || {};

        if (!records) {
            return (
                <CircularLoading
                    loading={t('loading')}
                >
                    <p>{t('events_loading_parks')}</p>
                </CircularLoading>
            );
        }


        return (
            <div className={this.props.classes.root} >
                {
                    <div
                        style={{
                            margin: '1vh 1vw 0 1vw',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}
                    >
                        <GlobalDevicesContainer
                            defaultDevices={devices}
                            defaultSubparks={subparks}
                            onExecuteFilter={(devices, subparks, dispatchAction = () => null, filters = {}) => {
                                const {
                                    startTime,
                                    endTime
                                } = filters;


                                this.setState({
                                    filter: {
                                        ...filter,
                                        device_ids: devices,
                                        subpark_ids: subparks,
                                        start_time: startTime || filter.start_time,
                                        end_time: endTime || filter.end_time
                                    },
                                    executeFilter: true,
                                    dateMenuOpen: false
                                });

                                dispatchAction(devices, subparks);
                            }}
                        >
                            <SelectorView
                                page={{
                                    type: 'FILTER_TYPE_SUBPARK'
                                }}
                            />
                        </GlobalDevicesContainer>
                        <GlobalDateRangeContainer
                            tz={filter.timezone}
                            defaultStartTime={filter.start_time}
                            defaultEndTime={filter.end_time}
                            onExecuteFilter={(start, end) => {
                                this.setState({
                                    filter: {
                                        ...filter,
                                        start_time: start,
                                        end_time: end
                                    },
                                    executeFilter: true,
                                    dateMenuOpen: false
                                })
                            }}
                        >
                            <FilterDateComponent
                                open={dateMenuOpen}
                                openMenu={this.openDateMenu}
                                label={'Select Date'}
                                buttonStyle={{
                                    borderRadius: '0 25px 25px 0',
                                    height: 44,
                                    paddingRight: 16,
                                    background: 'white',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}
                            />
                        </GlobalDateRangeContainer>
                    </div>
                }
                <br />
                <PageContext.Provider value={{ refreshTables: this.refreshTables, onActionSuccess: this.onActionSuccess }} >
                    {
                        executeFilter &&
                        <CircularLoading
                            loading={t('loading')}
                        >
                            <p>{t('events_updating_data')}</p>
                        </CircularLoading>
                    }
                    {
                        records &&
                        <div
                            className={this.props.classes.internal}
                            style={{
                                width: window.innerWidth * 0.95
                            }}
                        >

                            <EventsTableFull
                                display={this.state.display}
                                records={records}
                                data={
                                    {
                                        rows: records
                                    }
                                }
                                infoPerDevices={infoPerDevices}
                                tableIndex={0}
                                title={t('park_events')}
                                onRowClick={(index, row) => () => {
                                    const newRecords = [
                                        ...records.slice(0, index),
                                        { ...row, selected: !row.selected },
                                        ...records.slice(index + 1)
                                    ];
                                    this.setState({ records: newRecords, success: null });
                                }}
                            />
                        </div>
                    }
                </PageContext.Provider>
                {
                    goToPage &&
                    <Redirect to={goToPage} />
                }
                <MultioptionsFab
                    openedIcon={<Icon>lock_opened</Icon>}
                    activatedIcon={<Icon>lock</Icon>}
                    style={{
                        background: '#28a745'
                    }}
                    baseIcon={<Icon>more_vert</Icon>}
                    Hidden={executeFilter}
                    actions={[
                        {
                            icon: <Icon>date_range</Icon>,
                            name: t('analysis_per_day'),
                            onClick: () => this.setState({ goToPage: '/dashboard/analysis2/operational?analysis-id=122' })
                        },
                        {
                            icon: <Icon>event_note</Icon>,
                            name: t('analysis_per_month'),
                            onClick: () => this.setState({ goToPage: '/dashboard/analysis2/operational?analysis-id=123' })
                        },
                        {
                            icon: <Icon>toys</Icon>,
                            name: t('analysis_per_device'),
                            onClick: () => this.setState({ goToPage: '/dashboard/analysis2/operational?analysis-id=124' })
                        },
                        {
                            icon: <Icon>device_hub</Icon>,
                            name: t('analysis_per_subpark'),
                            onClick: () => this.setState({ goToPage: '/dashboard/analysis2/operational?analysis-id=125' })
                        }
                    ]}
                />
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(EventsTablePage));