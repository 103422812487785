import React, {Component} from 'react'
import moment from 'moment-timezone'
import grey from '@material-ui/core/colors/grey'
import {
  withStyles
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button
} from '@material-ui/core'

import CircularLoading from '../../../../../../../components/Loading/CircularLoading'

import { keyframes } from 'styled-components'

const grey_row_changed = keyframes`
  0% {
    background-color: #333333;
  }

  20% {
    background-color: white;
  }

  100% {
    background-color: #333333;
  }
`

const white_row_changed = keyframes`
  0% {
    background-color: #262626;
  }

  20% {
    background-color: white;
  }

  100% {
    background-color: #262626;
  }
`

const grey_row_changed_font = keyframes`
  0% {
    color: white;
  }

  20% {
    color: black;
  }

  100% {
    color: white;
  }
`

const white_row_changed_font = keyframes`
  0% {
    color: white;
  }

  20% {
    color: black;
  }

  100% {
    color: white;
  }
`


const var_names_1 = [
  'real_time_analog_1#1', 
  'real_time_analog_2#1',
  'real_time_single#1'
]

const var_names_2 = [ 
  'real_time_analog_1#2',
  'real_time_analog_2#2',
  'real_time_single#2'
]



const WIDGET_HEADER_HEIGHT = 40

const styles = {
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  tableTh: {
    color: grey['500'],
    textAlign: 'center',
    padding: '8px'
  },
  tableTd: {
    textAlign: 'center',
    padding: '8px',
    color: grey['400']
  }
}


class ChangeableTable extends React.Component {
  constructor (props) {
    super (props)
  }

  render () {
    const {classes, table_data, table_colored_rows, table_name, fontsize_header, fontsize_rows, table_num, buttons} = this.props
    let whiteRow = true;
   
    
    if(!table_data) {
      return <CircularLoading />
    }

    if(table_data.header == null || table_data.header.constructor != Array) {
      throw new Error(JSON.stringify(
        { 
          message: 'Bad Format: Header', 
          on_table: table_num
        }
      ))
    }

    if(table_data.rows == null || table_data.rows.constructor != Array) {
      throw new Error(JSON.stringify(
        { 
          message: 'Bad Format: Rows', 
          on_table: table_num 
        }
      ))
    }

    const table_data_mapped = table_data.rows.map(row => row != null && row.constructor == Array)

    if(table_data_mapped.length > 0 && !table_data_mapped.reduce((acc, curr) => acc && curr)) {
      throw new Error(JSON.stringify(
        { 
          message: 'Row Format: Some row is not an array', 
          on_table: table_num 
        }
      ))
    } 

    // console.log(table_colored_rows)

    // console.log('some value changed?', this.state.table1_changed, this.state.table2_changed, this.state.table3_changed)

    return (
      /*<div style={{ 
        backgroundColor: '#333333',
        color: 'white',
        border: `1px solid ${grey['900']}`,
        width: '100%',
        height: '100%'      
      }}>
        <div style={{
          width: '100%', 
          display: 'flex', 
          backgroundColor: '#333333', 
          color: 'white'}} >
          <h6 style={{textAlign: 'left', paddingLeft: 16}}>{table_name}</h6>
        </div>
        */

      <div
        style={{
          color: 'white',
          border: `1px solid ${grey['900']}`,
          width: '100%',
          height: '100%'
        }}
      >

       <div
          style={{
            height: WIDGET_HEADER_HEIGHT,
            width: '100%',
            borderBottom: '',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              marginLeft: 10,
              color: grey['500']
            }}
          >
            {table_name}
          </div>
        </div>

       


       <div
         style={{
           height: `calc(100% - ${WIDGET_HEADER_HEIGHT}px)`,
           width: '100%',
           //overflow: 'auto'
         }}
       >

        <Table /*style={{padding: '0 !important', border: 'solid 1px #262626', backgroundColor: '#333333', color: 'white', width: '100%', borderBottomColor: '#262626', borderBottomWidth: 10}}*/

        style={{
          borderCollapse: 'collapse',
          border: `1px solid ${grey['900']}`,
          width: '100%'
        }}

        className={classes.table}
        
        >
          <TableHead>
            <TableRow 
              className={classes.tableTh}
              style={{
                height: 10,
                color: grey['500'],
                borderCollapse: 'collapse'
              }}
            >
              {
                table_data.header.map(cell => (
                  <TableCell 
                  colSpan={1} 
                  className={classes.tableTh}
                  style={{fontSize: fontsize_header, 
                    padding: '0 !important', 
                    border: 'transparent',
                    height: 'auto !important', 
                    color: grey['500'],
                    textAlign: 'center',
                    padding: '8px',
                    fontWeight: 'bold'}}
                  
                    >
                      {cell}
                    </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              table_data.filter ? table_data.filtered_rows.map((row, index) => 
              {
                whiteRow = !whiteRow
                return (
                  <TableRow 
                  className={classes.tableTd}
                  style={{
                    /*maxHeight: '36px',
                    height: 0,
                    color: grey['400'],*/
                    textAlign: 'center',
                    padding: '8px',
                    color: grey['400'],
                    borderCollapse: 'collapse',
                    border: 'transparent',
                    backgroundColor: whiteRow ? '#333333' : '#262626',

                    animation: table_data.colors.length > 0 && table_data.colors.includes(index) ? `${whiteRow ? grey_row_changed : white_row_changed} 2s ease` : '',
                  
                  }}>
                  {
                    row.map(cell => (
                      <TableCell colSpan={1} style={{
                        fontSize: fontsize_rows, 
                        /*padding: '0 !important', 
                        height: 'auto !important',
                        color: 'white',*/
                        textAlign: 'left',
                        padding: '8px',
                        color: grey['400'],
                        border: 'transparent',
                        animation: table_data.colors.length > 0 && table_data.colors.includes(index) ? `${whiteRow ? grey_row_changed_font : white_row_changed_font} 2s ease` : '',
                      }}>{cell}</TableCell>
                    ))
                  }
                  </TableRow>
                  )
                }
              ) : table_data.rows.map((row, index) => 
              {
                whiteRow = !whiteRow
                return (
                  <TableRow 
                  className={classes.tableTd}
                  style={{
                    /*maxHeight: '36px',
                    height: 0,
                    color: grey['400'],*/
                    textAlign: 'center',
                    padding: '8px',
                    color: grey['400'],
                    borderCollapse: 'collapse',
                    border: 'transparent',
                    backgroundColor: whiteRow ? '#333333' : '#262626',

                    animation: table_data.colors.length > 0 && table_data.colors.includes(index) ? `${whiteRow ? grey_row_changed : white_row_changed} 2s ease` : '',
                  
                  }}>
                  {
                    row.map(cell => (
                      <TableCell colSpan={1} style={{
                        fontSize: fontsize_rows, 
                        /*padding: '0 !important', 
                        height: 'auto !important',
                        color: 'white',*/
                        textAlign: 'left',
                        padding: '8px',
                        color: grey['400'],
                        border: 'transparent',
                        animation: table_data.colors.length > 0 && table_data.colors.includes(index) ? `${whiteRow ? grey_row_changed_font : white_row_changed_font} 2s ease` : '',
                      }}>{cell}</TableCell>
                    ))
                  }
                  </TableRow>
                  )
                }
              )
            }
          </TableBody>
        </Table>
      </div>
      </div>
      
    
    )
  }
}

export default withStyles()(ChangeableTable)