import FilterParkComponent from "./components/FilterParkComponent";
import FilterSubparkComponent from "./components/FilterSubparkComponent";
import FilterDeviceComponent from "./components/FilterDeviceComponent";
import FilterDateRangeComponent from "./components/FilterDateRangeComponent";
import FilterEnvelopeComponent from "./components/FilterEnvelopeComponent";

export default {
  parks: FilterParkComponent,
  subparks: FilterSubparkComponent,
  devices: FilterDeviceComponent,
  date_range: FilterDateRangeComponent,
  envelopes: FilterEnvelopeComponent,
}