import React from 'react'
import io from 'socket.io-client'
import { Auth } from 'aws-amplify'
import * as R from 'ramda'
import { Icon } from '@material-ui/core'
import { toast } from 'react-toastify'

import { URL_WEBSOCKET_API } from '../../../../../../constants/url-constant'
import OPCMapComponent from './components/OPCMapComponent/OPCMapComponent'
import { averageGeolocationHelper } from '../../../../../../helpers/average-geolocation-helper';
import WindRoseComponent from '../../../../../../components/WindRoseComponent/WindRoseComponent';
import adaptDevicesV2ToDevices from '../../adaptor';

import { startRefreshTokenLoop } from '../../services';

const HEADER_LABEL = 54

class OPCMapPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      socket: null,
      data: [],
      items: [],
      center: {
        latitude: 0,
        longitude: 0
      }
    }
  }

  componentWillUnmount() {
    if (this.state.socket) {
      this.state.socket.disconnect()
      this.setState({ socket: null })
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    Auth.currentSession()
      .then(({ accessToken }) => {
        const { jwtToken } = accessToken

        const url = `${URL_WEBSOCKET_API}/opc-coletor`

        const socket = io(url, {
          query: {
            token: jwtToken
          }
        })

        this.setState({ socket })

        socket.on('connect', (data) => console.log('connect', data))
        socket.on('connect_error', data => console.log('connect_error', data))
        socket.on('connect_timeout', data => console.log('connect_timeout', data))
        socket.on('connecting', data => console.log('connecting', data))
        socket.on('disconnect', data => console.log('disconnect', data))
        socket.on('error', data => console.log('error', data))
        socket.on('reconnect', data => console.log('reconnect', data))
        socket.on('reconnect_attempt', async data => {
          console.log('reconnect_attempt', data)

          try {
            const { accessToken: accessTokenRec } = await Auth.currentSession()
            const { jwtToken: jwtTokenRec } = accessTokenRec

            socket.io.opts.query = { token: jwtTokenRec }
          } catch (error) {
            console.error('ERROR AUTH', error)

            toast.error('Login again!', {
              position: "top-right",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            })
          }
        })
        socket.on('reconnect_failed', data => console.log('reconnect_failed', data))
        socket.on('reconnect_error', data => console.log('reconnect_error', data))
        socket.on('reconnecting', data => console.log('reconnecting', data))
        socket.on('ping', data => console.log('ping', data))
        socket.on('pong', data => console.log('pong', data))

        socket.on('devices_v2', parks => {
          const items = adaptDevicesV2ToDevices(parks);

          if (this.state.center.latitude === 0 || this.state.center.longitude === 0) {
            this.setState({ center: averageGeolocationHelper(R.flatten(items.map(subpark => subpark.wtgs))) });
          }

          this.setState({ items });
        })
      })
      .catch(error => {
        this.setState({ loading: false })
        console.error("SOCKET CONN ERROR", error)
      })

    startRefreshTokenLoop(1000 * 60 * 15);
  }

  render() {
    if (this.state.items.length <= 0) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          Loading...
        </div>
      )
    }

    const tags = R.flatten(this.state.items.map(subpark => subpark.wtgs.map(wtg => wtg.realtime)))

    const powerAll = R.sum(tags.filter(tag => tag.var_id === 1).map(tag => tag.value))
    const windMedian = R.median(tags.filter(tag => tag.var_id === 2).map(tag => tag.value))

    return (
      <div
        style={{
          minHeight: "720px",
          width: '100%'
        }}
      >
        <OPCMapComponent
          items={this.state.items}
          center={this.state.center}
        />

        <div
          style={{
            position: 'fixed',
            right: 80,
            bottom: 70,
            height: 300,
            width: 300
          }}
        >
          <WindRoseComponent
            items={this.state.items}
          />
        </div>

        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            color: 'white',
            backgroundImage: 'linear-gradient(to top, black, transparent)',
            padding: '15px 0px'
          }}
        >
          <div
            style={{
              height: HEADER_LABEL,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginLeft: 20
                }}
              >
                Turbines
                <span
                  style={{
                    marginLeft: 5,
                    fontWeight: 900
                  }}
                >
                  {R.flatten(this.state.items.map(subpark => subpark.wtgs)).length}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginLeft: 20
                }}
              >
                Power (MW)
                <span
                  style={{
                    marginLeft: 5,
                    fontWeight: 900
                  }}
                >
                  {(powerAll / 1000).toFixed(1)}
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginLeft: 20
                }}
              >
                Wind Speed (m/s)
                <span
                  style={{
                    marginLeft: 5,
                    fontWeight: 900
                  }}
                >
                  {windMedian.toFixed(1)}
                </span>
              </div>
            </div>

            <div style={{ flex: 1 }} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginRight: 20
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: 20
                }}
              >
                <Icon style={{ fontSize: 16, color: "green" }}>
                  fiber_manual_record
                        </Icon>
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: 'smaller'
                  }}
                >
                  Running
                        </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: 20
                }}
              >
                <Icon style={{ fontSize: 16, color: "purple" }}>
                  fiber_manual_record
                            </Icon>
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: 'smaller'
                  }}
                >
                  Maintenance (Corrective)
                            </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: 20
                }}
              >
                <Icon style={{ fontSize: 16, color: "blue" }}>
                  fiber_manual_record
                            </Icon>
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: 'smaller'
                  }}
                >
                  Maintenance (Preventive)
                            </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: 20
                }}
              >
                <Icon style={{ fontSize: 16, color: "red" }}>
                  fiber_manual_record
                            </Icon>
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: 'smaller'
                  }}
                >
                  Stopped
                            </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: 20
                }}
              >
                <Icon style={{ fontSize: 16, color: "orange" }}>
                  fiber_manual_record
                            </Icon>
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: 'smaller'
                  }}
                >
                  Power limitation
                            </span>
              </div>


              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: 20
                }}
              >
                <Icon style={{ fontSize: 16, color: "yellow" }}>
                  fiber_manual_record
                            </Icon>
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: 'smaller'
                  }}
                >
                  Warning
                            </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: 20
                }}
              >
                <Icon style={{ fontSize: 16, color: "#878500" }}>
                  fiber_manual_record
                            </Icon>
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: 'smaller'
                  }}
                >
                  Low wind
                            </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginLeft: 20
                }}
              >
                <Icon style={{ fontSize: 16, color: "grey" }}>
                  fiber_manual_record
                            </Icon>
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: 'smaller'
                  }}
                >
                  No Communication
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OPCMapPage
