import React, { Component } from 'react'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import { Tooltip, withStyles, Icon } from '@material-ui/core';
import gearbox from '../../../assets/envelope/gearbox.png';
import turbine_electrical from '../../../assets/envelope/turbine_electrical.png';
import generator from '../../../assets/envelope/generator.png';
import grid_connection from '../../../assets/envelope/grid_connection.png';
import pitch_system from '../../../assets/envelope/pitch_system.png';
import production_status from '../../../assets/envelope/production_status.png';
import rotor from '../../../assets/envelope/rotor.png';
import yaw_system from '../../../assets/envelope/yaw_system.png';
import nacelle from '../../../assets/envelope/nacelle.png';
import frequency_converter from '../../../assets/envelope/frequency_converter.png';

import styles from './styles/styles.js';

class GraphicImage extends Component {
  constructor(props) {
    super(props)
    const { graphic } = this.props
    const { id, type } = graphic
    const { data } = graphic

    this.refChart = React.createRef()

    this.state = {
      chart_id: [type, id].join('-'),
      data: data,
      original_data: null,
      mouse_x: null,
      mouse_y: null,
      colors: ['#0A70D2', '#FC9018', '#22BA1A', '#FCDC34', '#D47C35', '#F4645C', '#C0D2FC', '#4C65A7', '#8C7277']
    }
  }

  componentDidMount() {
    // this.props.onRef(this)
  }

  reflow() {
    this.refChart.current.chart.reflow()
  }

  render() {

    const { classes, showTable, graphic } = this.props;
    let { data } = graphic;
    let image;

    switch (data.name) {
      case 'gearbox':
        image = gearbox
        break;
      case 'generator':
        image = generator
        break;
      case 'turbine_electrical':
        image = turbine_electrical
        break;
      case 'nacelle':
        image = nacelle
        break;
      case 'grid_connection':
        image = grid_connection
        break;
      case 'pitch_system':
        image = pitch_system
        break;
      case 'production_status':
        image = production_status
        break;
      case 'rotor':
        image = rotor
        break;
      case 'frequency_converter':
        image = frequency_converter
        break;
      case 'yaw_system':
        image = yaw_system
        break;
      default:
        break;
    }

    if (Array.isArray(data)) {
      return (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {/* <CircularProgress className={classes.progress} size={(graphic.size * 30)} /> */}
          <div style={{
            marginTop: '20px'
          }}>
            Carregando dados...
          </div>
        </div>
      )
    }
    return (
      <div className={classes.flipContainer}>
        <div
          style={{
            transform: showTable ? 'rotateX(180deg)' : 'none'
          }} className={classes.flipper}
        >
          <div className={classes.flipContainerFront} style={{ display: showTable ? 'none' : undefined }}>
            <Tooltip title={data.alt}>
              {
                image ? 
                  <div style={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                  }} />
                :
                  <div 
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Icon style={{fontSize: '10em', color: '#dbdbdb'}}>photo_size_select_actual</Icon>
                    <p>System Image Not Available</p>
                  </div>
              }              
            </Tooltip>
          </div>
          <div className={classes.flipContainerBack} style={{ display: showTable ? undefined : 'none' }}>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(GraphicImage);
