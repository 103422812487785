import React, {Component} from 'react'

class FilterEnvelopeComponent extends Component {
  render () {
    return (
      <div>
        FilterEnvelopeComponent
      </div>
    )
  }
}

export default FilterEnvelopeComponent
