import React from 'react'
import * as R from 'ramda'
import grey from '@material-ui/core/colors/grey'
import {
  MenuItem,
  Icon
} from '@material-ui/core'
import FilterThreeItemComponent from '../FilterThreeItemComponent/FilterThreeItemComponent';
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

const MENU_WIDTH = 200
const MENU_MARGIN_SIZE = 20
const MENU_INDEX_DIVIDER = '#'
const MENU_EXTENSION_ID = 'MENU_EXTENSION_'

class FilterThreeBodyComponent extends React.Component {
  constructor (props) {
    super (props)

    const body = window.document.getElementsByTagName('body')[0]

    this.state = {
      bodyWidth: body.offsetWidth,
      bodyHeight: body.offsetHeight,
      rootLeft: 980,
      rootTop: 300,
      rootHeight: 100,
      rootWidth: 100,
      menuLeft: 0
    }
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName('body')[0]

    const bodyWidth = body.offsetWidth
    const bodyHeight = body.offsetHeight

    const rootLeft = this.props.parentElRef.current.offsetLeft
    const rootTop = this.props.parentElRef.current.offsetTop
    const rootHeight = this.props.parentElRef.current.offsetHeight
    const rootWidth = this.props.parentElRef.current.offsetWidth

    const menuLeft = (MENU_WIDTH + rootLeft) > bodyWidth
      ? (bodyWidth - MENU_WIDTH) - MENU_MARGIN_SIZE
      : rootLeft - MENU_MARGIN_SIZE

    this.setState({
      bodyWidth,
      bodyHeight,
      rootLeft,
      rootTop,
      rootHeight,
      rootWidth,
      menuLeft
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizePageWatch)

   
    const rootLeft = 837
    const rootTop = this.props.filterFixed ? 1 : 95
    const rootHeight = 100
    const rootWidth = 100

    const menuLeft = (MENU_WIDTH + rootLeft) > this.state.bodyWidth
      ? (this.state.bodyWidth - MENU_WIDTH) - MENU_MARGIN_SIZE
      : rootLeft - MENU_MARGIN_SIZE

    this.setState({
      rootLeft,
      rootTop,
      rootHeight,
      rootWidth,
      menuLeft
    })
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizePageWatch)
  }

  mapCheckedDataTrue = (data, value) => {
    return data.map((item) => {
      return {
        ...R.assoc(
          'checked',
          value,
          R.assoc(
            'data',
            this.mapCheckedDataTrue(item.data, value),
            item
          )
        )
      }
    })
  }

  mapCheckedData = (data, hash) => {



    return data.map(item => {
      if (item.hash === hash) {
        if (item.checked) {
          return {
            ...R.assoc(
              'checked',
              false,
              R.assoc(
                'data',
                this.mapCheckedDataTrue(item.data, false),
                item
              )
            )
          }
        } else {
          return {
            ...R.assoc(
              'checked',
              true,
              R.assoc(
                'data',
                this.mapCheckedDataTrue(item.data, true),
                item
              )
            )
          }
        }
      } else {

        
        if(!this.props.device){
         
          return {
            ...R.assoc(
              'checked',
              false,
              R.assoc(
                'data',
                this.mapCheckedData(item.data, hash),
                item
              )
            )
          }

        }
        else{
            
          return R.assoc(
            'data',
            this.mapCheckedData(item.data, hash),
            item
          )
        }

      }
    })
  }

  changeItemChecked = hash => this.props.changeData(this.mapCheckedData(this.props.dataHash, hash))

  renderDataMenu = (data, parentLeft, parentTop, extra = false) => {

    if(!extra || this.props.level == 3){
      
      return data.map((item, indexItem, itemArray) => {
        return (
          <FilterThreeItemComponent
            device={this.props.device}
            changeItemChecked={this.changeItemChecked.bind(this)}
            showExtensionMenu={this.showExtensionMenu.bind(this)}
            renderDataMenu={this.renderDataMenu}
            bodyHeight={this.state.bodyHeight}
            MENU_WIDTH={MENU_WIDTH}
            parentLeft={parentLeft}
            itemArray={itemArray}
            parentTop={parentTop}
            key={indexItem}
            item={item}
          />
        )
      })

    }


  }

  onClick = (item) => {

    if(!this.props.device && item.data.length > 0) {
      return;
    }

    this.changeItemChecked(item.hash)
  }

  showExtensionMenu = (hash, array) => {
    const el = window.document.getElementById(hash)

    el.style['display'] = 'block'
    
    array.map((item) => {
      if (hash !== item.hash) {
        const elOther = window.document.getElementById(item.hash)

        elOther.style['display'] = 'none'
      }
    })
  }
  
  render () {
    const {
      FILTER_THREE_BODY_ID, 
      device
    } = this.props

    return (
      <div
        id={FILTER_THREE_BODY_ID}
        style={{
          height: '100%',
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 9999
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            // backgroundColor: 'black'
          }}
          onClick={this.props.closeMenu}
        />
        <div
          style={{
            backgroundColor: 'white',
            width: MENU_WIDTH,
            position: 'fixed',
            left: this.state.menuLeft,
            top: this.state.rootTop + this.state.rootHeight,
            border: `1px solid ${grey['200']}`
          }}
        >
          {
            this.props.dataHash.map((item, indexItem, itemArray) => {
              const left = (this.state.menuLeft - MENU_WIDTH) + 1
              const top = this.state.rootTop + this.state.rootHeight

              return (
                <div
                  key={indexItem}
                >
                  <MenuItem
                    onMouseEnter={() => this.showExtensionMenu(item.hash, itemArray)}
                    onClick={() => this.onClick(item)}
                  >
                    {
                      this.props.device ?
                          
                        item.checked
                        ?
                        (
                          <Icon>
                            check_box
                          </Icon>
                        )
                        :
                        item.indeterminate
                        ?
                        (
                          <IndeterminateCheckBoxIcon/>                                     
                        )
                        :                        
                        (
                          <Icon>
                            check_box_outline_blank
                          </Icon>
                        )
                      : null
                      
                    }
                    {item.name} 
                  </MenuItem>
                  <div
                    id={item.hash}
                    style={{
                      border: `1px solid ${grey['200']}`,
                      backgroundColor: 'white',
                      width: MENU_WIDTH,
                      position: 'fixed',
                      overflow: 'auto',
                      display: 'none',
                      maxHeight: this.state.bodyHeight - top,
                      left,
                      top,
                    }}
                  >
                    {
                      this.renderDataMenu(item.data, left, top)
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default FilterThreeBodyComponent
