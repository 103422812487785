import styled from 'styled-components'

const AppFull = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

const AppContent = styled(AppFull)``

const AppRouterContent = styled(AppFull)``

export {AppFull, AppContent, AppRouterContent}