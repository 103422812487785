import React, { 
    useState,
    useEffect 
} from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NotificationsView from './NotificationsView';

import { notificationsCheckpoint, setNotifications } from '../../../../actions/UserActions';
import { getNotifications, getAllNotifications } from '../../../../providers/user-provider';

const fetchNotifications = async (props) => {
    const {
        hasInfo,
        setNotifications
    } = props;

    const notifications =  await getAllNotifications();
    setNotifications(notifications);
};

const NotificationsContainer = props => {
    const { 
        Notifications, 
        incomming,
        setIncomming, 
        open,
        notificationsCheckpoint,
        setNotifications
    } = props;

    const hasInfo = Object.keys(Notifications).length > 0;

    const data = JSON.parse(JSON.stringify(Notifications));
    delete data.id;
    delete data.lastUpdate;
    delete data.user;

    const { lastUpdate } = Notifications;

    const [ fetched, fetch ] = useState(false);

    if(!fetched) {
        fetchNotifications({...props, hasInfo});
        fetch(true);
    }

    // const [ interval, setIntervalValue ] = useState(null);

    // useEffect(() => {
    //     if(!interval) {
    //         fetchNotifications({...props, hasInfo});
    //         setIntervalValue(setInterval(() => fetchNotifications({...props, hasInfo}), 30*60*1000));
    //     }
        
    //     return (() => {
    //         clearInterval(interval);
    //         setNotifications(null);
    //     });
    // });

    useEffect(() => {
        if(setIncomming && !open) {
            const incomming = Object.values(data).filter(chunk => (
                new Date(chunk.id).getTime() > new Date(lastUpdate).getTime()
            )).map(chunk => (
                Object.values(chunk).map(val => (
                    typeof(val) !== 'object' ? 0 : Object.keys(val).length
                )).reduce((acc, curr) => acc + curr, 0)
            )).reduce((acc, curr) => acc + curr, 0);

            setIncomming(incomming);
        }
    });

    useEffect(() => {
        if(open && incomming > 0) {
            setIncomming(0);
            notificationsCheckpoint();
        }
    });

    return (
        hasInfo && (
            <NotificationsView
                {...props}
                data={data}
            />
        )
    );
}

const mapStateToProps = ({ Notifications }) => {
    return ({
        Notifications
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    notificationsCheckpoint,
    setNotifications
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);