import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'
import {
  withStyles
} from '@material-ui/core'
import GridLayout from 'react-grid-layout'
import * as R from 'ramda'

import { AppStyleContent } from './ModuleStyle'

import { updateFilterSubparks, updateFilterTimeRange } from './actions/UpdateFilterAction'

import WidgetDriver from '../../../../../components/Driver/WidgetDriver'
import BaseGlobalFilter from '../../../../../components/Header/BaseGlobalFilter'
import Selector from '../../../../../components/Filter/Selector'

import PageWidgetPlaceholder from '../../../../../components/Placeholder/PageWidgetPlaceholder'

import { REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA } from "../../../../../constants/reducers/widget-page-action-events";
import { applyFilter } from '../../../../../helpers/filter-helper';


const PAGE_FIELD = 'management';
const GRID_WIDTH_PADDING = 20

const styles = theme => ({
  gridElement: {
    width: '100%'
  }
})

class ManagementPage extends Component {
  constructor(props) {
    super(props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    // const { delfos_1_0 } = this.props;
    // const { [PAGE_FIELD]: Module } = delfos_1_0 || {};
    const { DelfosPreviousManagement } = this.props;
    const pageSelected = R.find(R.propEq('selected', true))(DelfosPreviousManagement);

    this.state = {
      gridWidth: elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),
      widgets: [],
      loading: false,
      currPage: pageSelected,
      forceUpdateFilters: false
    }

    this.gridContainerAnchor = React.createRef()
    this.currentDateRange = null
    this.prevManagementId = null
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName('body')[0]

    this.setState({
      gridWidth: body.offsetWidth - (GRID_WIDTH_PADDING * 2)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizePageWatch);
    // this.executeFilter(this.state.currPage);
    this.setState({ forceUpdateFilters: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizePageWatch)
  }

  async componentDidUpdate(prevProps, prevState) {
    const { DelfosPreviousManagement } = this.props;
    const pageSelected = R.find(R.propEq('selected', true))(DelfosPreviousManagement);

    const { forceUpdateFilters } = this.state;

    if ((forceUpdateFilters || prevProps.editMode) && pageSelected.widgets.length != prevState.widgets.length ) {
     
      if(!this.props.Dashboard.DeviceFilter.subparks){

        this.props.updateEnvelopeFilter(pageSelected.filter.devices, pageSelected.filter.subparks )  
      
      
      }
        
        
        this.executeFilter(pageSelected);
    }

    const promisesToResolve = this.state.widgets
      .filter(widget => Promise.resolve(widget.data) === widget.data)

    if (promisesToResolve.length > 0) {
      const widgetData = this.state.widgets.map(widget => widget.data);
      const promisesRes = await Promise.all(widgetData);



      const widgets = this.state.widgets.map((widget, index) => ({
        ...widget,
        data: promisesRes[index],
        loading: false
      }));



      this.setState({
        widgets
      });
    }

    // const promisesToResolve = this.state.widgets
    //   .filter(widget => Promise.resolve(widget.data) === widget.data)

    // console.log('promisesToResolve', promisesToResolve)
    // if(promisesToResolve.length > 0) {
    //   const widgetData = this.state.widgets.map(widget => widget.data)[0];
    //   const promiseRes = await Promise.resolve(widgetData);
    //   console.log('PROMISES RES', promiseRes, this.state.widgets);
    //   const widgets = this.state.widgets.map((widget, index) => index === 0 ? ({...widget, data: promiseRes[0]}) : widget);
    //   this.setState({
    //     widgets
    //   });
    // }
  }




  changeLoading = loading => {
    if (this.state.loading != loading) {
      this.setState({
        loading
      });
    }
  };



  executeFilter = payload => {

    payload = this.state.currPage;

    const { DelfosPreviousManagement } = this.props;
    // let page = R.find(R.propEq('selected', true))(this.props.delfos_1_0[PAGE_FIELD]);
    const page = R.find(R.propEq('selected', true))(DelfosPreviousManagement);
    payload.filter = page.filter;

    const widgets = applyFilter(payload);

    this.setState({
      widgets
    });
  };

  selectPage = pageId => {
    // const { delfos_1_0 } = this.props;
    // const { [PAGE_FIELD]: Module } = delfos_1_0 || {};
    // const currPage = R.find(R.propEq('id', pageId))(Module);
    const { DelfosPreviousManagement } = this.props;
    const currPage = R.find(R.propEq('id', pageId))(DelfosPreviousManagement);
    const widgets = applyFilter(currPage);
    this.setState({
      currPage,
      widgets
    });
  };

  render() {
    const { classes, parks, history } = this.props;
    const { widgets, currPage } = this.state;



    if (!widgets || widgets.length == 0) {
      return (
        <AppStyleContent>
          <PageWidgetPlaceholder />
        </AppStyleContent>
      )
    }

    return (
      <AppStyleContent>

          <BaseGlobalFilter
            mode={'view'}
            disabled={this.state.loading}
            history={history}
            page={currPage}
            reducer_name={REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA}
            module_field={'DelfosPreviousManagement'}
            executeFilter={this.executeFilter}
            goToPage={this.selectPage}
            submodule={true}
            {...this.props}
          >
            <Selector updateFilters={this.executeFilter}   page={currPage} />
          </BaseGlobalFilter>
           



        <div
          style={{
            padding: '0 0 0 15px', height:'1300px'
          }}
        >
          {this.state.gridWidth ? (
            <GridLayout
              className={classes.gridElement}
              cols={12}
              rowHeight={200}
              items={50}
              width={this.state.gridWidth}
              compactType={'horizontal'}
              isDraggable={false}
              isResizable={false}
            >
              {
                widgets.map((widget, index) => {
                  return (
                    <div style={{
                    }} key={widget.id} data-grid={widget.grid} >
                      <WidgetDriver key={`${index}${widget.id}`}
                        classes={classes}
                        showTable={false}
                        changeLoading={this.changeLoading}
                        selectedPage={currPage}
                        parks={parks}
                        ignoreIcons={true}
                        history={history}
                        widget={widget}
                        isAnalysis={false}
                        reducer_name={REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA}
                        module_id={'DelfosPreviousManagement'}
                      />
 
                      <br/>

                      <WidgetDriver key={`${index}${widget.id}`}
                        showTable={true}
                        classes={classes}
                        changeLoading={this.changeLoading}
                        selectedPage={currPage}
                        parks={parks}
                        ignoreIcons={true}
                        history={history}
                        widget={widget}
                        isAnalysis={false}
                        reducer_name={REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA}
                        module_id={'DelfosPreviousManagement'}
                      /> 

                    </div>
                  );
                })
              }
            </GridLayout>
          ) : ''}
        </div>
      </AppStyleContent>
    )
  }
}

const mapStateToProps = ({ Dashboard, User }) => {
  const { DelfosPreviousManagement } = Dashboard
  const { parks_info } = User

  return { DelfosPreviousManagement, parks: parks_info, Dashboard }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateFilterSubparks, updateFilterTimeRange }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManagementPage))
