import React, {Component} from 'react'
import {withStyles, List, ListSubheader, ListItem, ListItemText, Paper, Checkbox} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import * as R from 'ramda'

import { WIDGET_HEADER_HEIGHT } from '../../constants/widget-constant'
import { FILTER_TYPE_SUBPARKS } from '../../constants/filter-types'

const styles = theme => ({
  paperElement: {
    border: `1px solid ${grey['200']}`,
    borderRadius: 0,
    flex: 1
  },
  paperHeader: {
    paddingLeft: '10px',
    minHeight: `${WIDGET_HEADER_HEIGHT}px`,
    borderBottom: `1px solid ${grey['200']}`,
    display: 'flex',
    alignItems: 'center'
  },
  listItems: {
    width: '100%',
    minWidth: 300,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
})

class FilterSubparks extends Component {
  changeValueFilter = (subpark, checked) => () => {
    const {parks_info, selectedPage, widget} = this.props

    const {filter: filterSelectedPage} = selectedPage
    const {filter: filterWidget} = widget

    const devices = filterWidget.devices.length > 0
      ? filterWidget.devices
      : filterSelectedPage.devices.length > 0
        ? filterSelectedPage.devices
        : []

    if (!checked) {
      const devicesNew = R.flatten(parks_info.map(park => {
        return park.subparks.filter(subparkMap => {
          return subparkMap.id === subpark.id
        })
        .map(subparkMap => subparkMap.wtgs.map(wtg => wtg.id))
      }))

      this.props.changeValueFilter(FILTER_TYPE_SUBPARKS, R.uniq(R.concat(devices, devicesNew)))
    }

    if (checked) {
      const idsRemove = R.flatten(parks_info.map(park => {
        return park.subparks.filter(subp => subp.id === subpark.id)
        .map(subp => subp.wtgs)
      }))
      .map(wtg => wtg.id)

      const devicesNew = devices.filter(id => {
        return !R.contains(id, idsRemove)
      })

      this.props.changeValueFilter(FILTER_TYPE_SUBPARKS, R.uniq(devicesNew))
    }
  }

  render () {
    const {classes, parks_info, selectedPage, widget} = this.props

    const {filter: filterSelectedPage} = selectedPage
    const {filter: filterWidget} = widget

    const devices = filterWidget.devices.length > 0
      ? filterWidget.devices
      : filterSelectedPage.devices.length > 0
        ? filterSelectedPage.devices
        : []

    const subparksSelected = R.flatten(parks_info.map(park => {
      return park.subparks.filter(subpark => {
        const filter = subpark.wtgs.filter(wtg => {
          return R.contains(wtg.id, devices)
        })

        return filter.length > 0
      })
    })
    .filter(park => park.length > 0))
    .map(subpark => subpark.id)

    return (
      <Paper className={classes.paperElement} elevation={0}>
        <div className={classes.paperHeader}>
          <div>
            Subparks
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
        <List className={classes.listItems} subheader={<li />}>
          {parks_info.map((park, indexPark) => (
            <li
              key={`filter_subpark_park_${indexPark}`}
              className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader>
                  {park.park}
                </ListSubheader>
                {park.subparks.map((subpark, indexSubpark) => (
                  <ListItem
                    key={`filter_subpark_subpark_${indexSubpark}`}
                    role={undefined}
                    dense
                    button
                    onClick={this.changeValueFilter(subpark, R.contains(subpark.id, subparksSelected)).bind(this)}
                  >
                    <Checkbox
                      checked={R.contains(subpark.id, subparksSelected)}
                      tabIndex={-1}
                    />
                    <ListItemText primary={subpark.name} />
                  </ListItem>
                ))}
              </ul>
            </li>
          ))}
        </List>
      </Paper>
    )
  }
}

export default withStyles(styles)(FilterSubparks)
