import { Auth } from 'aws-amplify'
import axios from 'axios'
import {
  URL_NODE_API_ASSET,
  URL_NODE_API,
  URL_API
} from '../constants/url-constant'

export const getAssetProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getAssetByIdProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset/by-id?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}


export const updateResource = async (params, route) => {

  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { sub } = payload || {};

  if (sub == null) {
    return null;
  }

  const URL = `${URL_NODE_API}/inventory/dev/${route}/${parseInt(params.id)}`;

  try {
    const { data } = await axios.put(
      URL,
      {
        resource: [{
          ...params
        }]
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      }
    )

    return data;
  } catch (err) {
    return { error: `Your acquisition action could not be finished due error: ${err.toString()}` };
  }
}




export const deleteResource = async (params, route) => {


  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { sub } = payload || {};

  if (sub == null) {
    return null;
  }

  const URL = `${URL_NODE_API}/inventory/dev/${route}/${parseInt(params.id)}`;

  try {
    const { data } = await axios.delete(
      URL,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      },
      {
        resource: [{
          ...params
        }]
      }
    )

    return data;
  } catch (err) {
    return { error: `Your acquisition action could not be finished due error: ${err.toString()}` };
  }

}

export const createResource = async (params, route) => {

  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { sub } = payload || {};

  if (sub == null) {
    return null;
  }

  const URL = `${URL_NODE_API}/inventory/dev/${route}`;

  try {
    const { data } = await axios.post(
      URL,
      {
        resource: [{
          ...params,
          user_hash: sub
        }]
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      }
    )

    return data;
  } catch (err) {
    let errMessage = (err && err.response && err.response.data) || err.toString();

    if (errMessage === "error: duplicate key value violates unique constraint \"unique_part_number\"") {
      errMessage = 'unique_modal_part_number';
    }

    return { error: errMessage };
  }
}


export const createAssetProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const updateAssetProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset`

  const { data } = await axios.put(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const deleteAssetProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset?id=${id}`

  const { data } = await axios.delete(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}



export const getAssetModelProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getAssetModelByIdProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model/by-id?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const updateAssetModelProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model`

  const { data } = await axios.put(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const deleteAssetModelProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model?id=${id}`

  const { data } = await axios.delete(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const createAssetModelProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getAssetModelServiceProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-service`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getAssetModelServiceByIdProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-service/by-id?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const createAssetModelServiceProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-service`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const updateAssetModelServiceProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-service`

  const { data } = await axios.put(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const deleteAssetModelServiceProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-service?id=${id}`

  const { data } = await axios.delete(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getAssetModelComponentProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-component`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getAssetModelComponentByIdProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-component/by-id?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const createAssetModelComponentProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-component`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const updateAssetModelComponentProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-component`

  const { data } = await axios.put(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const deleteAssetModelComponentProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-component?id=${id}`

  const { data } = await axios.delete(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getAssetModelConsumableProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-consumable`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getAssetModelConsumableByIdProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-consumable/by-id?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const createAssetModelConsumableProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-consumable`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const updateAssetModelConsumableProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-consumable`

  const { data } = await axios.put(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const deleteAssetModelConsumableProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model-consumable?id=${id}`

  const { data } = await axios.delete(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getSystemProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/system`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const moveComponentServProvider = async (date, component, storage, assets_component) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  let params = {
    "_date": date,
    "_component_id": component.id,
    "_new_storage_id": storage.id,
    "_new_asset_component_id": assets_component.id
  };



  const URL = `${URL_NODE_API_ASSET}/inventory/movement/move-component`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const executeServProvider = async (date, suplier, service) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  let params = {
    "_date": date,
    "_service_id": 1,
    "_supplier": suplier,
    "_asset_service_id": service.id
  };



  const URL = `${URL_NODE_API_ASSET}/inventory/movement/execute-service`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getStorageIds = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/storage`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getComponentIds = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model/component`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getComponentModelProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/component`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getComponentModelByIdProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/component/by-id?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const createComponentModelProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/component`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const updateComponentModelProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/component`

  const { data } = await axios.put(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const deleteComponentByIdProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/component?id=${id}`

  const { data } = await axios.delete(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getConsumableModelProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/consumable`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getConsumableModelByIdProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/consumable/by-id?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const createConsumableModelProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/consumable`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const updateConsumableModelProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/consumable`

  const { data } = await axios.put(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const deleteConsumableModelProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/consumable?id=${id}`

  const { data } = await axios.delete(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getStorageProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/storage`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getStorageByIdProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/storage/by-id?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const createStorageProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/storage`

  const { data } = await axios.post(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const updateStorageProvider = async (params) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/storage`

  const { data } = await axios.put(
    URL,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const deleteStorageProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/storage?id=${id}`

  const { data } = await axios.delete(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getStorageComponentProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/storage/component-item?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getStorageConsumableProvider = async (id) => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/storage/consumable-item?id=${id}`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getAssetComponentIds = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model/asset-component`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getMovementProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/movement`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getMovAssetCompProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/movement/asset-component`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getMovAssetConsProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/movement/asset-consumable`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getMovAssetServProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/movement/asset-service`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}


export const getMovAssetServIds = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model/asset-model-service`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getMovServIds = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/asset-model/asset-service`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}



export const getMovCompProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/movement/component`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getMovConsProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/movement/consumable`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}


export const getMovementComponentProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/movement/movement-component`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}

export const getMovementCosumableProvider = async () => {
  const { accessToken } = await Auth.currentSession()
  const { jwtToken } = accessToken

  const URL = `${URL_NODE_API_ASSET}/inventory/movement/movement-consumable`

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data
}



export const getAlertObjects = async () => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_NODE_API}/inventory/dev/component?warning=true`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data;
}

export const getComponentsHistory = async () => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_NODE_API}/inventory/dev/components-history`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data;
}

export const getComponentsInfo = async () => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_NODE_API}/inventory/dev/components-info`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data;
}

export const getComponentsHistoryNew = async id => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_NODE_API}/inventory/dev/components-history`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      },
      params: {
        id
      }
    }
  )

  return data;
}



export const getResourceProvider = async (route) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_NODE_API}/inventory/dev/${route}`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data;
}


export const getAcquiredComponentsGrouped = async () => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_NODE_API}/inventory/dev/acquired-components-agregated`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  return data;
}


export const getAcquiredComponents = async (component_model_id, storage_id) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_NODE_API}/inventory/dev/acquired-components`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      },
      params: {
        component_model_id,
        storage_id
      }
    }
  )

  return data;
}

export const deleteAcquisition = async (component) => {



  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { username } = payload || {};

  if (username == null) {
    return null;
  }

  const URL = `${URL_NODE_API}/inventory/dev/component/${component.id}`;



  try {
    const { data } = await axios.delete(
      URL,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      },
      {
        resource: [{
          ...component,
          user_hash: username
        }]
      }
    )

    return data;
  } catch (err) {
    return { error: `Your acquisition action could not be finished due error: ${err.toString()}` };
  }
}

export const updateAcquisition = async (component, acquisition) => {

  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { username } = payload || {};

  if (username == null) {
    return null;
  }

  const URL = `${URL_NODE_API}/inventory/dev/component/${component.id}`;

  try {
    const { data } = await axios.put(
      URL,
      {
        resource: [{
          ...acquisition,
          user_hash: username
        }]
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      }
    )

    return data;
  } catch (err) {
    return { error: err.toString() };
  }
}

const handleAcquisitionError = e => {

  let error = e.toString();

  const uniqueConstraint = 'unique_component_model_invoice_serial_number_unit';
  if (e.response && e.response.data && e.response.data.constraint === uniqueConstraint) {
    error = 'Unique ID error';
  }

  return { error };
};

export const singleAcquisition = async acquisition => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { username } = payload || {};

  if (username == null) {
    return null;
  }

  const URL = `${URL_NODE_API}/inventory/dev/register-acquisitions`;

  try {
    const { data } = await axios.post(
      URL,
      {
        acquisitions: [{
          ...acquisition,
          user_hash: username
        }]
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      }
    );

    return data;
  } catch (error) {
    return handleAcquisitionError(error);
  };
};

export const multipleAcquisitions = async acquisitions => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { username } = payload || {};

  if (username == null) {
    return null;
  }

  const URL = `${URL_NODE_API}/inventory/dev/register-acquisitions`;

  try {
    const { data } = await axios.post(
      URL,
      {
        acquisitions: acquisitions.map(acquisition => ({
          ...acquisition,
          user_hash: username
        }))
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      }
    );

    return data;
  } catch (error) {
    return handleAcquisitionError(error);
  };
};

export const getComponentsModelsInfo = async (extractedInfo = ['id', 'name', 'part_number', 'tag']) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_NODE_API}/inventory/dev/get-all-component-models`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  );

  const { allComponentModels } = data || {};

  if (allComponentModels) {
    return allComponentModels.map(model => ({
      [model.id]: {
        ...extractedInfo.map(toExtract => ({
          [toExtract]: model[toExtract]
        })).reduce((acc, curr) => ({ ...acc, ...curr }))
      }
    })).reduce((acc, curr) => ({ ...acc, ...curr }));
  } else {
    return null;
  }
}

export const getStoragesInfo = async (extractedInfo = ['id', 'name', 'tag']) => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken } = accessToken;

  const URL = `${URL_NODE_API}/inventory/dev/get-all-storages-info`;

  const { data } = await axios.get(
    URL,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      }
    }
  )

  const { allStoragesInfo } = data || {};

  if (allStoragesInfo) {

    return allStoragesInfo;

  } else {
    return null;
  }
}

export const singleMovement = async movement => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { username } = payload || {};


  if (username == null) {
    return null;
  }

  const URL = `${URL_NODE_API}/inventory/dev/move-components`;

  try {
    const { data } = await axios.post(
      URL,
      {
        movements: [{
          ...movement,
          user_hash: username
        }]
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      }
    )


    return data;
  } catch (err) {
    return { error: `Your movement action could not be finished due error: ${err.toString()}` };
  }
}

export const multipleMovements = async movements => {
  const { accessToken } = await Auth.currentSession();
  const { jwtToken, payload } = accessToken;
  const { sub } = payload || {};

  if (sub == null) {
    return null;
  }

  const URL = `${URL_NODE_API}/inventory/dev/move-components`;

  try {
    const { data } = await axios.post(
      URL,
      {
        movements: movements.map(movement => ({
          ...movement,
          user_hash: sub
        }))
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      }
    )

    return data;
  } catch (err) {
    return { error: `Your movement actions could not be completly finished due error: ${err.toString()}` };
  }
}


const getUserToken = () => (new Promise((resolve, reject) => {
  Auth.currentSession()
    .then(result => {
      const { accessToken } = result;
      const { jwtToken } = accessToken;

      resolve(jwtToken);
    })
    .catch(reject);
}));

export const importModelImage = async (id, file) => {

  let output = {};

  let formData = new FormData();
  formData.append('file', file);
  formData.append('info', JSON.stringify({ id }))

  getUserToken()
    .then(token => {
      axios.post(`${URL_API}/inventory/import_image`, formData, {
        headers: { 'Authorization': token }
      })
        .then(({ data }) => { output = { data } })
        .catch(error => { output = { error: error.toString() } });
    })
    .catch(error => {
      output = { error: error.toString() }
    });

  return output;
};