export const GRAPHIC_TYPE_HYDRO_GAUGE = 'gauge_hydro'
export const GRAPHIC_TYPE_AVAILABILITY_PER_MONTH_HYDRO = 'availability_per_month_hydro'
export const GRAPHIC_TYPE_INTERACTIVE_TABLE_HYDRO = 'interactive_table_hydro'
export const GRAPHIC_TYPE_INTERACTIVE_TABLE_HYDRO_2 = 'interactive_table_hydro_2'
export const GRAPHIC_TYPE_POWER_CURVE_SCATTER_HYDRO = 'powercurve_scatter_hydro'


export const GRAPHIC_TYPE_KEY_MATRICS = 'key_matrics'
export const GRAPHIC_TYPE_HEAT_MAP = 'heatmap'

export const GRAPHIC_TYPE_MAP = 'graphic_type_map'

export const GRAPHIC_TYPE_BAR = 'availability_comparative_per_subpark'
export const GRAPHIC_TYPE_AVAILABILITY_COMPARATIVE_PER_WTG = 'availability_comparative_per_wtg'
export const GRAPHIC_TYPE_AVAILABILITY_COMPARATIVE_PER_MONTH = 'availability_comparative_per_month'

export const GRAPHIC_TYPE_SYSTEM_IMAGE = 'system_image'

export const GRAPHIC_TYPE_SCATTER_PLOT = 'evelope_score_x_y_timeseries'
export const GRAPHIC_TYPE_PRODUCTION_BALANCE = 'production_balance'
export const GRAPHIC_TYPE_WIND_SPEED_COMPARATIVE_PER_DAY= 'wind_speed_comparative_per_day'
export const GRAPHIC_TYPE_GANTT_CHART_BOP_AVAILABILITY= 'bop_availability'
export const GRAPHIC_TYPE_AVAILABILITY_COMPARATIVE_PER_DAY= 'availability_comparative_per_day'
export const GRAPHIC_TYPE_CONN_POINT_PRODUCTION_COMPARATIVE_PER_SUBPARK = 'conn_point_production_comparative_per_subpark'
export const GRAPHIC_TYPE_CONN_POINT_PRODUCTION_COMPARATIVE_PER_DAY = 'conn_point_production_comparative_per_day'
export const GRAPHIC_TYPE_CONN_POINT_PRODUCTION_COMPARATIVE_PER_MONTH = 'conn_point_production_comparative_per_month'

export const GRAPHIC_TYPE_PRODUCTION_COMPARATIVE_PER_WTG = 'production_comparative_per_wtg'
export const GRAPHIC_TYPE_PRODUCTION_COMPARATIVE_PER_SUBPARK = 'production_comparative_per_subpark'
export const GRAPHIC_TYPE_PRODUCTION_COMPARATIVE_PER_DAY = 'production_comparative_per_day'

export const GRAPHIC_TYPE_CONN_POINT_CF_COMPARATIVE_PER_SUBPARK = 'conn_point_cf_comparative_per_subpark'
export const GRAPHIC_TYPE_CONN_POINT_CF_COMPARATIVE_PER_DAY = 'conn_point_cf_comparative_per_day'
export const GRAPHIC_TYPE_CONN_POINT_CF_COMPARATIVE_PER_MONTH = 'conn_point_cf_comparative_per_month'

export const GRAPHIC_TYPE_WIND_SPEED_COMPARATIVE_PER_WTG = 'wind_speed_comparative_per_wtg'
export const GRAPHIC_TYPE_WIND_SPEED_COMPARATIVE_PER_SUBPARK = 'wind_speed_comparative_per_subpark'
export const GRAPHIC_TYPE_WIND_SPEED_COMPARATIVE_PER_MONTH = 'wind_speed_comparative_per_month'



export const GRAPHIC_TYPE_BOX_PLOT_DEFAULT = 'BOX_PLOT_DEFAULT'



export const GRAPHIC_TYPE_POWER_PERFORMANCE_COMPARATIVE_PER_WTG = 'power_performance_comparative_per_wtg'
export const GRAPHIC_TYPE_POWER_PERFORMANCE_COMPARATIVE_PER_DAY = 'power_performance_comparative_per_day'
export const GRAPHIC_TYPE_POWER_PERFORMANCE_COMPARATIVE_PER_MONTH = 'power_performance_comparative_per_month'
export const GRAPHIC_TYPE_POWER_PERFORMANCE_COMPARATIVE_PER_SUBPARK = 'power_performance_comparative_per_subpark'

export const GRAPHIC_TYPE_TABLE_WORK_ORDER = 'table_work_order'
export const GRAPHIC_TYPE_TABLE_HEALTH_SCORE = 'table_health_score'

export const GRAPHIC_TYPE_PRODUCTION_GAUGE = 'production_gauge'

export const GRAPHIC_TYPE_BOXPLOT_YAW = 'boxplot_yaw'

export const GRAPHIC_TYPE_PRODUCED_VERSUS_PRODUCIBLE = 'produced_versus_producible'
export const GRAPHIC_TYPE_DOWNTIMES_PER_FAULT = 'downtimes_per_fault'
export const GRAPHIC_TYPE_KEY_PREDICTION_METRICS = 'key_prediction_metrics'
export const GRAPHIC_TYPE_KEY_METRICS = 'key_metrics'
export const GRAPHIC_TYPE_POWER_PERFORMANCE_GAUGE = 'power_performance_gauge'
export const GRAPHIC_TYPE_STOPPED_TURBINE = 'stopped_turbine_metrics'
export const GRAPHIC_TYPE_COMPLEX_AVERAGE_POWER_SUMMARY = 'complex_average_power_summary'
export const GRAPHIC_TYPE_TABLE_LOST_PRODUCTION_SYSTEM = 'table_lost_production_per_system'
export const GRAPHIC_TYPE_TABLE_SCORE_SUBPARK = 'table_score_per_subpark'
export const GRAPHIC_TYPE_TABLE_ACTIVE_ENVELOPE_ALARMS = 'table_active_envelope_alarms'
export const GRAPHIC_TYPE_SCORE_TIMELINE = 'score_timeline'
export const GRAPHIC_TYPE_POWER_CURVE_SCATTER = 'powercurve_scatter'
export const GRAPHIC_TYPE_DIRTYDOZEN = 'dirtydozen'
export const GRAPHIC_TYPE_WIND_DIRECTION_TIMESERIES = 'wind_direction_timeseries'
export const GRAPHIC_TYPE_ENVELOPE_ALARM_TIMELINE = 'envelope_alarm_timeline'
export const GRAPHIC_TYPE_ALARM_TIMELINE = 'alarm_timeline'
export const GRAPHIC_TYPE_ENVELOPE_HEALTH_SCORE_GAUGE = 'envelope_health_score_gauge'
export const GRAPHIC_TYPE_WORK_ORDER_DOWNTIMES = 'workorder_data'
export const GRAPHIC_TYPE_MODEL_SCORE = 'evelope_score_x_y_timeseries'
