import React, {Component} from 'react'
import {withStyles, Button} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { Icon } from 'react-icons-kit'
import {fileText} from 'react-icons-kit/fa/fileText'
import { ROUTE_MAIN_REPORTS_MANAGEMENT_NEW } from '../../../../../../constants/route-constant';

const styles = () => ({
  headerText: {
    color: grey['700'],
    fontSize: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonHeader: {
    height: '100%',
    borderRadius: 0,
    border: `1px solid ${grey['200']}`,
    color: grey['700'],
    marginLeft: 10
  }
})

class ReportsHeaderTemplateComponent extends Component {
  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div
          className={classes.headerText}
        >
          <Icon icon={fileText} size={30} />
          <span
            style={{
              marginLeft: 10
            }}
          >
            Templates
          </span>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            height: '100%'
          }}
        >
          <Button
            className={classes.buttonHeader}
            onClick={() => this.props.goPage(ROUTE_MAIN_REPORTS_MANAGEMENT_NEW)}
          >
            Generate template
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ReportsHeaderTemplateComponent)
