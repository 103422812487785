import React, {Component} from 'react'
import moment from 'moment-timezone'
import {
  withStyles
} from '@material-ui/core'

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import * as R from 'ramda'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
  Checkbox,
  TextField,
  Icon
} from '@material-ui/core'

import CircularLoading from '../../../../../../../components/Loading/CircularLoading'

import { keyframes } from 'styled-components'

const grey_row_changed = keyframes`
  0% {
    background-color: #f9f9f9
  }

  20% {
    background-color: #ffa25b
  }

  100% {
    background-color: #d9d9d9
  }
`

const white_row_changed = keyframes`
  0% {
    background-color: #f9f9f9
  }

  20% {
    background-color: #ffa25b
  }

  100% {
    background-color: white
  }
`

const var_names_1 = [
  'real_time_analog_1#1', 
  'real_time_analog_2#1',
  'real_time_single#1'
]

const var_names_2 = [ 
  'real_time_analog_1#2',
  'real_time_analog_2#2',
  'real_time_single#2'
]

class VariablesSelector extends React.Component {
  constructor (props) {
    super (props)

   this.state = {
      variableSearch:'',
      tableName:null
    }

  }



  shouldComponentUpdate(nextProps, nextState){

      if (this.state.variableSearch != nextState.variableSearch || this.state.tableName != nextState.tableName){

        return true;
      }


      if (this.props != nextProps){

        return true;
      }


      return false;
  }

  setParam = (param, value, callback = null) => {

    this.props.updateTableData(this.props.table_num, param, value)

    this.setState({
      [param]: value
    }, () => {
      if (callback) {
        callback()
      }
    })
  }


  componentWillUnmount() {
    // const { excludeVariables, execute_filter, table_num } = this.props

    // console.log('UNMOUNTING COMPONENT', execute_filter)

    // if(execute_filter) {
    //     console.log('BEFORE EXCLUDE VARS', this.state.ignored_vars, table_num)
    //     excludeVariables(this.state.ignored_vars, table_num)
    // }
  }

  // componentDidUpdate() {
  //   // console.log('ON UPDATE EXECUTE FILTER?', execute_filter)
  //   const { excludeVariables, execute_filter, table_num } = this.props


  //   if(execute_filter) {
  //       excludeVariables(this.state.ignored_vars, table_num)
  //   }
  // }

  render () {

    const {classes, plantsInfo, plant_id, fontsize, selecteds_vars, table_num, table} = this.props

    const font_size = fontsize ? fontsize : 10

    const variables = table_num == 3 ? plantsInfo.rows : plantsInfo
    
    return (
      <div style={{padding: 0, margin: 0}}>
        <div style={{width: '100%', display: 'flex'}} >
          <form className={classes.container} noValidate autoComplete="off">

              <TextField
                id='dialog-studio-filter-variables-text'
                className={classes.textField}
                value={this.state.tableName? this.state.tableName : table.name }
                onChange={e => this.setParam('tableName', e.target.value)}
                style={{ marginLeft: 6, marginTop: 8}} 

              />
              <br/>
              <TextField
                id='dialog-studio-filter-variables-text'
                label='Buscar...'
                className={classes.textField}
                value={this.state.variableSearch}
                onChange={e => this.setParam('variableSearch', e.target.value)}
                style={{ marginLeft: 6, marginBottom: 8}} 


              />


  
          </form>
        </div>
        <Table className={classes.table} style={{padding: '0 !important', border: 'solid 1px #d9d9d9', 
        width: '101%', backgroundColor:'white'}}>
            <TableHead>
                <TableRow style={{
                    height: 15,

                }}  onClick={() => this.props.selectAll(variables, table_num)} >
                    <TableCell 
                        padding="checkbox" 
                        style={{
                            height: 'auto !important',
                            textAlign: 'left'
                        }}
                    >
                        <Checkbox
                            indeterminate={this.props.selecteds_vars.length < variables.length && this.props.selecteds_vars.length > 0}
                            checked={this.props.selecteds_vars.length == variables.length}
                            style={{ width: font_size, height: font_size, color:'black' }}
                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: font_size + 5 }} />}
                            checkedIcon={<CheckBoxIcon style={{ fontSize: font_size + 5}} />}
                            indeterminateIcon={<Icon style={{ fontSize: font_size + 5, color: '#5b5b5b'}} >indeterminate_check_box</Icon>}
                        />
                    </TableCell>
                    <TableCell
                        key={'header1'}
                        padding={'default'}
                        style={{
                            height: 'auto !important',
                            textAlign: 'left',
                            fontSize: font_size + 2
                        }}
                    >
                       {table_num == 2 ? "Alarme" : "Variável"} 
                    </TableCell>
                    { table_num != 2 ?
                      <TableCell
                          key={'header1'}
                          padding={'default'}
                          style={{
                              height: 'auto !important',
                              textAlign: 'left',
                              fontSize: font_size + 2
                          }}
                      >
                          Grupo
                      </TableCell>
                      : null
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    variables.filter(variable => {
                             
                        if (this.state.variableSearch.length < 3) {
                          return true
                        }

                        if (this.state.variableSearch === `${variable[0]}`) {
                          return true
                        }

                        const variableName = R.toUpper(table_num == 3 ? variable[1] : variable[2])
                        const variableSearch = R.toUpper(this.state.variableSearch)

                        if (variableName.search(variableSearch) >= 0) {
                          return true
                        } else {
                          return false
                        }
                      
                    }).map(variable => (
                        <TableRow style={{
                            maxHeight: '36px',
                            height: 0
                        }}  onClick={() => this.props.selectVar(variable[0], table_num)}>
                            <TableCell 
                                colSpan={1} 
                                padding="checkbox"
                                style={{
                                    height: 'auto !important',
                                    textAlign: 'left'
                                }}
                            >
                                <Checkbox
                                    checked={this.props.selecteds_vars.indexOf(variable[0]) != -1}
                                    style={{width: 8, height: 8}}
                                    style={{ width: font_size, height: font_size, color:'black' }}
                                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: font_size + 3}} />}
                                    checkedIcon={<CheckBoxIcon style={{ fontSize: font_size + 3}} />}
                                />
                            </TableCell>
                            <TableCell 
                                colSpan={1} 
                                padding={'default'}
                                style={{
                                    textAlign: 'left',
                                    height: 'auto !important',
                                    fontSize: font_size
                                }}
                            >
                                { table_num == 3 ? variable[1] : variable[2] }
                            </TableCell>
                        
                            { table_num != 3 ?
                                <TableCell
                                    key={'header1'}
                                    padding={'default'}
                                    style={{
                                        height: 'auto !important',
                                        textAlign: 'left',
                                        fontSize: font_size + 2
                                    }}
                                >
                                  { variable[1] }
                                </TableCell>
                                : null
                              }

                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
      </div>  
    )
  }
}

export default withStyles()(VariablesSelector)