import React from 'react';
import {
    withStyles,
    Button,
    Icon,
    Tooltip,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import Calendar from '../../../../components/Calendar/Calendar';
import BigCalendar from 'react-big-calendar';
import Labels from './components/Labels';
import 'react-big-calendar/lib/less/styles.less';
import {
    getMaintenances,
    getTags,
    newMaintenance,
    updateMaintenance,
    getEquipments,
    getStatus,
    getHelpers,
    newTag,
    newEquipment,
    getMaintenancesEquipments,
    getMaintenancesTags,
    deleteMaintenance
} from '../../../../providers/maintenance-provider';
import TagsInput from 'react-tagsinput';
import TagSuggest from './components/TagSuggest';
import CircularLoading from '../../../../components/Loading/CircularLoading';
import { LocalizedUtils, ptBrLocale } from './helpers/Time';
import InputWrapper from './components/InputWrapper';
import AlertDialog from './components/AlertDialog';

import { objectArraysAreEqual } from '../../../../helpers/comparison-helper';

import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.


const styles = {
    spaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
};

const localizer = BigCalendar.momentLocalizer(moment)


const TIMES_PROCEDURES = [
    ['series_history_analog_data_1h', '1h'],
    ['series_history_analog_data_3h', '3h'],
    ['series_history_analog_data_24h', '24h']
]

export const EVENTS_COLORS = {
    Programado: '#DFAD16',
    Feito: '#02732A',
    Cancelado: '#D62F2F',
    'Limitação ONS': '#4C7DE6',
    Preventiva: '#802E0E',
    'Corretiva Programada': '#000',
    'Corretiva Não-Programada': '#999',
    'Limitação': '#143B8F'
};

export const changeEventBackgroundColor = ({ selectedStatus }) => {
    if (selectedStatus && selectedStatus.id && Object.values(EVENTS_COLORS)[selectedStatus.id - 1]) {
        return { style: { backgroundColor: Object.values(EVENTS_COLORS)[selectedStatus.id - 1] } };
    }
};


class HydroCalendarComponent extends React.Component {
    constructor(props) {
        super(props);

        this.clickPark = this.clickPark.bind(this);

        this.state = {
            plantsSelectAnchorEl: null,
            newEvent: false,
            subpark: 1,
            allEvents: [],
            timeSelectAnchorEl: null,
            variablesSelectAnchorEl: null,
            plantSelected: null,
            timeSelected: null,
            selectedEqui: null,
            comments: null,
            startEvent: null,
            endEvent: null,
            variablesSelected: null,
            plantsInfo: [],
            tags: [],
            allTags: [],
            equipments: [],
            allEquips: [],
            start_date: null,
            end_date: null,
            selectedStatus: null,
            param: null,
            loading: false,
            events: null,
            event: null,
            inputName: '',
            toDelete: null,
            goToPage: null
        };
    }



    setParam = (param, value, callback = null) => {
        this.setState({
            [param]: value
        }, () => {
            if (callback) {
                callback()
            }
        })
    }

    handleChangeTags = (inputTags) => {
        const { allTags } = this.state;
        const allTagsNames = this.mapToOneProp(allTags, 'name');
        const filteredTags = inputTags.filter(tag => allTagsNames.indexOf(tag) === -1);
        const newTags = inputTags.length > 0 ? filteredTags.map(tag => ({ name: tag, color: this.randomRGBColor() })) : [];

        this.setState({
            tags: inputTags,
            allTags: [...allTags, ...newTags]
        });
    }

    handleChangeEquips = (inputEquips) => {
        const { allEquips } = this.state;
        const allEquipsNames = this.mapToOneProp(allEquips, 'name');
        const filteredEquips = inputEquips.filter(tag => allEquipsNames.indexOf(tag) === -1);
        const newEquips = inputEquips.length > 0 ? filteredEquips.map(tag => ({ name: tag })) : [];

        this.setState({
            equipments: inputEquips,
            allEquips: [...allEquips, ...newEquips]
        });
    }


    deleteEvent = () => {
        const { event } = this.state

        if (!event) {
            this.setState({ newEvent: false });
            return;
        }

        this.setState({ alertDialog: true });
    };

    confirmDelete = () => {
        const { event, events, allEvents } = this.state;

        const newEvents = events.filter(ev => event.id !== ev.id);
        const newAllEvents = allEvents.filter(ev => event.id !== ev.id);

        this.setState({
            events: newEvents,
            allEvents: newAllEvents,
            newEvent: false,
            toDelete: event,
            alertDialog: false
        });
    };

    cancelDelete = () => {
        this.setState({ alertDialog: false });
    };

    mapToOneProp = (arr, prop) => arr.map(element => element[prop]);

    getTitle = (name, tags, equips) => {
        let title = '';

        if (name) {
            title = title.concat(name);
        }

        if (Array.isArray(equips) && equips.length > 0) {
            title = title.concat(` ▶ ${this.mapToOneProp(equips, 'name').join(', ')}`);
        }

        if (Array.isArray(tags) && tags.length > 0) {
            title = title.concat(` (${this.mapToOneProp(tags, 'name').join(', ')})`);
        }

        return title.length > 0 ? title : 'Manutenção';
    };

    saveEvent = (e) => {
        const {
            startEvent,
            endEvent,
            subpark,
            tags: tagsNames,
            allTags,
            selectedStatus,
            allEquips,
            equipments: equipNames,
            comments,
            events,
            allEvents,
            event,
            inputName
        } = this.state;

        const start = startEvent;
        const end = endEvent;

        const isEditing = event != null;

        const tags = allTags.filter(tag => tagsNames.indexOf(tag.name) >= 0);
        const equipments = allEquips.filter(equipment => equipNames.indexOf(equipment.name) >= 0);

        const title = this.getTitle(inputName, tags, equipments);
        const name = inputName;

        // editing event
        if (isEditing) {
            const newEventsState = events.map((ev, indexEvent) => (
                event.id == ev.id ?
                    {
                        id: event.id,
                        start,
                        end,
                        title,
                        tags,
                        equipments,
                        subpark,
                        selectedStatus,
                        comments,
                        name
                    }
                    :
                    ev
            ));

            const newAllEventsState = allEvents.map((ev, indexEvent) => (
                event.id == ev.id ?
                    {
                        start,
                        end,
                        title,
                        tags,
                        equipments,
                        subpark,
                        selectedStatus,
                        comments: comments,
                        name
                    }
                    :
                    ev
            ));

            this.setState({
                eddited_id: event.id,
                events: newEventsState,
                allEvents: newAllEventsState
            });

        } else {
            // creating event
            this.setState({
                events: [
                    ...events,
                    {
                        start,
                        end,
                        title,
                        tags,
                        equipments,
                        subpark,
                        selectedStatus,
                        comments: comments,
                        name
                    }
                ],
                allEvents: [
                    ...allEvents,
                    {
                        start,
                        end,
                        title,
                        subpark,
                        tags,
                        equipments,
                        selectedStatus,
                        comments: comments,
                        name
                    }
                ]
            });
        }

        this.setState({
            tags: [],
            equipments: [],
            event: null,
            newEvent: false,
            selectedStatus: null,
            comments: null,
            isEditing,
            inputName: '',
            startEvent: null,
            endEvent: null
        });
    }

    clickPark(park_id, event) {
        this.setState({
            subpark: park_id
        })

        let events = this.state.allEvents.map((ev, indexEvent) => {
            ev['start'] = new Date(ev['start'])
            ev['end'] = new Date(ev['end'])

            if (ev.subpark && ev.subpark != park_id) {
                return null;
            }

            return ev;

        }).filter(ev => ev != null)

        this.setState({ events: events })
    }

    async componentDidUpdate(prevProps, prevState) {
        const { events, allEvents, isEditing, firstLoad, eddited_id, toDelete, event } = this.state;
        const { events: prevEvents, allEvents: prevAllEvents } = prevState;

        if (
            !firstLoad && (
                !objectArraysAreEqual(events, prevEvents) ||
                !objectArraysAreEqual(allEvents, prevAllEvents)
            )
        ) {
            if (isEditing === false) {
                const targetEvents = events.splice(prevEvents.length);
                let newEvents = [];

                for (let event of targetEvents) {
                    newEvents.push(JSON.parse(JSON.stringify(event)));
                    newEvents[newEvents.length - 1]['tags'] = [];
                    newEvents[newEvents.length - 1]['equipments'] = [];

                    for (let tag of event.tags) {
                        const res = await Promise.resolve(newTag(tag));
                        newEvents[newEvents.length - 1].tags.push(res);
                    }

                    for (let equipment of event.equipments) {
                        const res = await Promise.resolve(newEquipment(equipment));
                        newEvents[newEvents.length - 1].equipments.push(res);
                    }
                }

                const res = await Promise.all(newEvents.map(event => (
                    newMaintenance({
                        ...event,
                        tag_ids: event.tags.map(tag => tag.id),
                        equip_ids: event.equipments.map(equipment => equipment.id)
                    })
                )));

                newEvents = newEvents.map((event, index) => ({
                    ...event,
                    id: res[index]._maintenance_id[0]
                }));

                const newState = {
                    events: [...prevEvents, ...newEvents],
                    allEvents: [...prevAllEvents, ...newEvents]
                }

                this.setState({
                    ...newState,
                    isEditing: null
                });
            } else if (isEditing === true && eddited_id != null) {

                const targetEvent = events.find(event => event.id === eddited_id);

                if (targetEvent) {
                    let newEvent = JSON.parse(JSON.stringify(targetEvent));

                    const newTags = JSON.parse(JSON.stringify(newEvent.tags));
                    const newEquips = JSON.parse(JSON.stringify(newEvent.equipments));

                    newEvent['tags'] = [];
                    newEvent['equipments'] = [];

                    for (let tag of newTags) {
                        const res = await Promise.resolve(newTag(tag));
                        newEvent.tags.push(res);
                    }

                    for (let equipment of newEquips) {
                        const res = await Promise.resolve(newEquipment(equipment));
                        newEvent.equipments.push(res);
                    }

                    const res = await Promise.resolve(
                        updateMaintenance({
                            ...newEvent,
                            tag_ids: newEvent.tags.map(tag => tag.id),
                            equip_ids: newEvent.equipments.map(equipment => equipment.id)
                        })
                    );

                    const newState = {
                        events: events.map(event => (
                            event.id === res._maintenance_id[0] ?
                                newEvent
                                :
                                event
                        )),
                        allEvents: allEvents.map(event => (
                            event.id === res._maintenance_id[0] ?
                                newEvent
                                :
                                event
                        ))
                    }

                    this.setState({
                        ...newState,
                        isEditing: null
                    });
                }
            }
        }

        if (firstLoad) {
            this.setState({
                firstLoad: false
            });
        }

        if (toDelete != null) {
            try {
                const res = await Promise.resolve(deleteMaintenance(event));

                this.setState({
                    isEditing: null,
                    toDelete: null,
                    tags: [],
                    equipments: [],
                    event: null,
                    newEvent: false,
                    selectedStatus: null,
                    comments: null,
                    inputName: '',
                    startEvent: null,
                    endEvent: null
                });

            } catch (err) {
                console.log('ERROR ON DELETE!!', err)
            }

        }
    }

    editEvent = (event) => {
        this.setState({
            event,
            newEvent: true,
            inputName: event.name,
            selectedStatus: event.selectedStatus,
            equipments: event.equipments.map(equip => equip.name),
            tags: event.tags.map(tag => tag.name),
            comments: event.comments,
            startEvent: event.start,
            endEvent: event.end,
        });
    }

    addEvent = view => ({ start, end }) => {
        const endEvent = (view === 'month' && moment.duration(moment(end).diff(moment(start))).asHours() === 0) || view === 'year' ? moment(end).add(1, 'day').toDate() : end;

        this.setState(state => ({
            newEvent: true,
            startEvent: start,
            endEvent,
            selectedStatus: state.allStatus && state.allStatus.find(status => status.id === 1)
        }));
    }

    closeDialogNewEvent = (e) => {
        this.setState({
            tags: [],
            equipments: [],
            event: null,
            newEvent: false,
            selectedStatus: null,
            comments: null,
            inputName: ''
        });
    }

    handleChangeStart = (e) => {
        this.setState({
            start_date: e
        });
    }

    handleChangeEnd = (e) => {
        this.setState({
            end_date: e
        });
    }

    changeValueSelectStatus = value => {
        this.setState({
            selectedStatus: value
        });
    }

    setLocaleToBr = () => {
        moment.locale('pt-br', ptBrLocale);

        moment.locale('pt-br');
    };

    async componentDidMount() {
        const allMaintenances = await getMaintenances();

        const maintenanceIds = allMaintenances.map(event => event.id);
        const equipmentsByMaintenances = await getMaintenancesEquipments(maintenanceIds);
        const tagsByMaintenances = await getMaintenancesTags(maintenanceIds);

        const allTags = await getTags();
        const allEquips = await getEquipments();
        const allStatus = await getStatus();
        const allHelpers = await getHelpers();

        const allEvents = allMaintenances.map(event => {

            const tags = tagsByMaintenances.filter(tag => tag.maintenance_id === event.id)
                .map(tag => ({ id: tag.tag_id, name: tag.tag_name, color: tag.tag_color }));
            const equipments = equipmentsByMaintenances.filter(equipment => equipment.maintenance_id === event.id)
                .map(equipment => ({ id: equipment.equipment_id, name: equipment.equipment_name }));

            const title = this.getTitle(event.name, tags, equipments);

            return ({
                title,
                start: event.start_time,
                end: event.end_time,
                comments: event.comment,
                selectedStatus: { id: event.status_id, label: event.status_name },
                subpark: 1,
                tags,
                equipments,
                id: event.id,
                name: event.name
            })
        });

        const events = allEvents;

        this.setLocaleToBr();

        this.setState({
            events,
            allEvents,
            allTags,
            allEquips,
            allStatus,
            allHelpers,
            firstLoad: true
        });
    }

    randomRGBColor = () => (
        `rgb(${Math.random() * 180}, ${Math.random() * 180}, ${Math.random() * 180})`
    );

    renderTag = contentArray => ({ tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other }) => {
        const contentFiltered = contentArray.filter(content => content.name === tag);
        const possibleColor = contentFiltered.length === 1 && contentFiltered[0].color;
        const color = possibleColor || '#bdbdbd';

        return (
            <Button
                variant='outlined'
                size='small'
                style={{
                    margin: '.5vh .5vw .5vh .5vw',
                    width: 'auto',
                    fontSize: '.7em',
                    borderColor: color,
                    color: color,
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    wordBreak: 'break-word',
                    textAlign: 'left',

                    ':hover': {
                        backgroundColor: 'transparent'
                    }
                }}
                key={key} {...other}
            >
                {getTagDisplayValue(tag)}
                <Icon
                    style={{
                        margin: '0 0 0 1vh',
                        fontSize: '1.1em'
                    }}
                    onClick={(e) => onRemove(key)}
                >
                    close
                </Icon>
            </Button>
        );
    }


    render() {
        const { classes, filter_calendar } = this.props;
        const { spaceBetween } = classes;
        const {
            allTags,
            allEquips,
            allStatus,
            allHelpers,
            tags,
            equipments,
            events,
            inputName,
            selectedStatus,
            event,
            newEvent,
            comments,
            startEvent,
            endEvent,
            alertDialog
        } = this.state;

        const suggestTags = (
            allTags &&
            allTags.constructor === Array &&
            allTags.filter(tag => tags.indexOf(tag.name) === -1)
        ) || [];

        const suggestEquips = (
            allEquips &&
            allEquips.constructor === Array &&
            allEquips.filter(equipment => equipments.indexOf(equipment.name) === -1)
        ) || [];

        if (events == null) {
            return (
                <CircularLoading>
                    <p>Please wait while we are loading your calendar...</p>
                </CircularLoading>
            );
        }


        let data = [];
        if (this.state.param) {
            data["table_alarm_data2#" + this.state.plantSelected.plant_id] = this.state.param;
        }

        return (
            <MuiPickersUtilsProvider utils={LocalizedUtils}>
                <div
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                >

                    <div
                        style={{
                            height: `calc(100 % - 80 %)`
                        }}
                    >
                        {filter_calendar &&
                            <div
                                style={{
                                    marginTop: 8, display: 'flex',
                                    backgroundColor: 'white'
                                }}
                            >
                                <div style={{ flex: 5 }}></div>

                                <h4 style={{ flex: 1, textAlign: 'right', color: 'white' }}>Power Plants</h4>
                                <Button
                                    style={{
                                        flex: 1,
                                        backgroundColor: this.state.subpark == 1 ? 'orange' : '#d9d9d9',
                                        color: this.state.subpark == 1 ? 'white' : 'black',
                                        margin: 8,
                                        marginRight: 0
                                    }}
                                    onClick={this.clickPark.bind(this, 1)}
                                >
                                    Pipoca
                </Button>

                                <Button
                                    style={{
                                        flex: 1,
                                        backgroundColor: this.state.subpark == 2 ? 'orange' : '#d9d9d9',
                                        color: this.state.subpark == 2 ? 'white' : 'black',
                                        margin: 8,
                                        marginLeft: 0
                                    }}
                                    onClick={this.clickPark.bind(this, 2)}
                                >
                                    Serra Das Agulhas
                </Button>
                            </div>

                        }

                        <div
                            style={{
                                height: '80%',
                                width: '100%'
                            }}
                        >
                            <div style={{ height: '500px', width: '100%' }}>
                                <div className={spaceBetween} style={{ padding: '0px 25px' }}>
                                    <Labels names={this.mapToOneProp(allStatus, 'label')} colors={Object.values(EVENTS_COLORS)} />
                                    {
                                        allHelpers && allHelpers.calendar ?
                                            <Tooltip title={allHelpers.calendar} placement='left' interactive>
                                                <Icon style={{ fontSize: 16 }}>help</Icon>
                                            </Tooltip>
                                            :
                                            ''
                                    }
                                </div>
                                <br />
                                <Calendar
                                    localizer={localizer}
                                    addEvent={this.addEvent.bind(this)} editEvent={this.editEvent.bind(this)}
                                    eventPropGetter={changeEventBackgroundColor}
                                    {...this.state}
                                />
                            </div>


                            <Dialog
                                onClose={this.closeDialogNewEvent}
                                open={newEvent}
                                aria-labelledby="dialog_overview_delete"
                                scroll='body'
                                fullWidth
                            >
                                <DialogTitle id="dialog_overview_delete">
                                    {event ? "Editar Intervenção" : "Adicionar Intervenção"}
                                </DialogTitle>
                                <DialogContent>

                                    <div className={spaceBetween}>
                                        <InputWrapper
                                            title='Descrição' style={{ flex: 2, paddingRight: 12 }}
                                            tooltip={allHelpers && allHelpers.name}
                                        >
                                            <TextField
                                                fullWidth
                                                value={inputName}
                                                onChange={e => this.setState({ inputName: e.target.value })}
                                                variant='outlined'
                                                margin='dense'
                                            />
                                        </InputWrapper>

                                        <InputWrapper
                                            title='Tipo' style={{ flex: 1, paddingLeft: 12 }}
                                            tooltip={allHelpers && allHelpers.status}
                                        >
                                            <TextField
                                                select
                                                variant="outlined"
                                                value={selectedStatus && selectedStatus.id}
                                                onChange={({ target }) => {
                                                    if (target) {
                                                        const clickedStatus = allStatus.find(status => status.id === target.value);
                                                        this.changeValueSelectStatus(clickedStatus);
                                                    }
                                                }}
                                                fullWidth
                                                margin='dense'
                                                inputProps={{ name: "age", id: "outlined-age-simple", teste: 'Eu sou um teste' }}
                                            >
                                                {
                                                    allStatus.map(status => <MenuItem status={status} value={status.id}>{status.label}</MenuItem>)
                                                }
                                            </TextField>
                                        </InputWrapper>
                                    </div>
                                    <InputWrapper
                                        title='Equipamento(s)'
                                        tooltip={allHelpers && allHelpers.equipment}
                                    >
                                        <TagsInput
                                            renderInput={TagSuggest(suggestEquips)}
                                            value={equipments}
                                            onChange={this.handleChangeEquips}
                                            inputProps={{
                                                placeholder: 'Inserir Novo'
                                            }}
                                            onlyUnique={true}
                                            renderTag={this.renderTag(allEquips)}
                                            className={null}
                                        />
                                    </InputWrapper>

                                    <div className={spaceBetween}>
                                        <InputWrapper
                                            title='Início'
                                            tooltip={allHelpers && allHelpers.start_time}
                                            style={{ flex: 1, paddingRight: 12 }}
                                        >
                                            <DateTimePicker
                                                variant='inline' ampm={false} value={startEvent} maxDate={endEvent}
                                                onChange={date => {
                                                    if (date.diff(endEvent) < 0) {
                                                        this.setState({ startEvent: date._d });
                                                    }
                                                }}
                                                format='DD/MM/YY HH:mm'
                                                inputVariant="outlined"
                                                fullWidth
                                                margin='dense'
                                            />
                                        </InputWrapper>

                                        <InputWrapper
                                            title='Fim'
                                            tooltip={allHelpers && allHelpers.end_time}
                                            style={{ flex: 1, paddingLeft: 12 }}
                                        >
                                            <DateTimePicker
                                                variant='inline' ampm={false} value={endEvent} minDate={startEvent}
                                                onChange={date => {
                                                    if (date.diff(startEvent) > 0) {
                                                        this.setState({ endEvent: date._d });
                                                    }
                                                }}
                                                format='DD/MM/YY HH:mm'
                                                inputVariant="outlined"
                                                fullWidth
                                                margin='dense'
                                            />
                                        </InputWrapper>
                                    </div>

                                    <InputWrapper
                                        title='Subpark(s)'
                                        tooltip={allHelpers && allHelpers.subparks}
                                    >
                                        <TagsInput
                                            renderInput={TagSuggest(suggestTags)}
                                            value={tags}
                                            onChange={this.handleChangeTags}
                                            inputProps={{
                                                placeholder: 'Inserir Novo'
                                            }}
                                            onlyUnique={true}
                                            renderTag={this.renderTag(allTags)}
                                            className={null}
                                        />
                                    </InputWrapper>

                                    <InputWrapper
                                        title='Observações'
                                        tooltip={allHelpers && allHelpers.obs}
                                    >
                                        <TextField
                                            multiline
                                            value={comments}
                                            onChange={e => this.setState({ comments: [e.target.value] })}
                                            fullWidth
                                            variant='outlined'
                                            margin='dense'
                                            placeholder={`Limitação: XXX MW\nMotivo: Atender condições sistêmicas (geração acima da prevista)\nObservação: Ao términio da limitação, aprox. de XXX MW`}
                                        />
                                    </InputWrapper>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.closeDialogNewEvent} color="primary">Cancelar</Button>
                                    {event ? <Button onClick={this.deleteEvent} color="primary" autoFocus>Deletar</Button> : ''}
                                    <Button onClick={this.saveEvent} color="primary" autoFocus>Salvar</Button>
                                </DialogActions>
                            </Dialog>
                            <AlertDialog
                                open={alertDialog} title='Deletar Evento?' content={`Você está prestes a deletar o seguinte evento: ${event && event.name}`}
                                cancel='Cancelar' submit='Deletar' handleCancel={this.cancelDelete} handleSubmit={this.confirmDelete}
                            />
                        </div>
                    </div>
                </div>
            </MuiPickersUtilsProvider>
        )
    }
}

export default withStyles(styles)(HydroCalendarComponent)
