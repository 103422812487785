const docs = {
    'analysis': {
        id: 'analysis',
        name: 'Analysis',
        content: {
            'api-ve': {
                id: 'api-ve',
                name: 'Instruções de uso - API',
                file: require('./API-VE.pdf')
            },
            'analysis-docs': {
                id: 'analysis-docs',
                name: 'Documentação das Análises',
                file: require('./DOCUMENTAÇÃO_DAS_ANÁLISES.pdf')
            },
            'analysis-docs-reports': {
                id: 'analysis-docs-reports',
                name: 'Documentação das Análises - Reports',
                file: require('./DOCUMENTAÇÃO_DAS_ANÁLISES_-_REPORTS.pdf')
            }
        }
    },
    'methodology': {
        id: 'methodology',
        name: 'Methodology',
        content: {
            'elec-loss-methodology': {
                id: 'elec-loss-methodology',
                name: 'Metodologia preliminar de cálculo das perdas elétricas',
                file: require('./Metodologia - Cálculo de perdas elétricas.pdf')
            },
            'downtimes-classif': {
                id: 'downtimes-classif',
                name: 'Classificação de Downtimes',
                file: require('./METODOLOGIA_DE_CLASSIFICAÇÃO_DE_DOWNTIMES.pdf')
            },
            'energy-kpi': {
                id: 'analysis-docs-reports',
                name: 'Energy KPI',
                file: require('./energy_kpi.pdf')
            }
        }
    },
    'manuals': {
        id: 'manuals',
        name: 'Manuals',
        content: {
            'inv-manual': {
                id: 'inv-manual',
                name: 'Eventos/Disponibilidade Contratual',
                file: require('./Instrução de trabalho_Disponibilidade_contratual.pdf')
            }
        }
    }
}

export default docs;