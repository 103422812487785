import { REDUCER_DELFOS_1_0_PERFORMANCE_GET_DATA, REDUCER_DELFOS_1_0_PERFORMANCE_CLEAR } from "../../../../../../constants/reducers/widget-page-action-events";
import { REDUCER_SET_META_DATA, REDUCER_CHECKPOINT } from '../../../../../../constants/reducers/get-meta-data'
import adjustPages from '../../../../../../helpers/merge-page-info'

const DEFAULT_STATE = {}

export default (state = DEFAULT_STATE, action) => {
  const {type} = action

  switch (type) {
    case REDUCER_DELFOS_1_0_PERFORMANCE_GET_DATA:
      return action.payload;
    case REDUCER_DELFOS_1_0_PERFORMANCE_CLEAR:
      return DEFAULT_STATE;
    case REDUCER_CHECKPOINT:
      return 'payload' in action &&
              action.payload &&
              'checkpoint' in action.payload &&
              action.payload.checkpoint &&
              'DelfosPreviousPerformance' in action.payload.checkpoint &&
              action.payload.checkpoint.DelfosPreviousPerformance ?
                action.payload.checkpoint.DelfosPreviousPerformance
              :
                DEFAULT_STATE
    case REDUCER_SET_META_DATA:
      return 'payload' in action &&
              action.payload &&
              'delfos_1_0' in action.payload &&
              action.payload.delfos_1_0 ?
                adjustPages(action.payload.delfos_1_0.performance.pages, action.payload.delfos_1_0.performance.filters, action.payload.delfos_1_0.performance.decorators)
              :
                DEFAULT_STATE
    default:
      return state;
  }
}
