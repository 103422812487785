import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles, Button, Icon } from "@material-ui/core";
import * as R from "ramda";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { MenuTreeContent, styles } from "./MenuTreeStyle";
import {toast} from 'react-toastify';

import {
  goCheckDevice,
  goCheckSubpark,
  goCheckPark,
  changeUpdateWidgets,
  updateParksFilter,
  updateParksEnvelopeFilter
} from "../../actions/MenuTreeAction";
import {
  STYLE_HEADER_TOOLBAR,
  STYLE_SUBHEADER_TOOLBAR
} from "../../constants/style-constant";
import {
  FILTER_TYPE_WTG,
  FILTER_TYPE_WTG_ENVELOPE
} from "../../constants/management-type";
import {
  PAGE_TYPE_ALARMS,
  PAGE_TYPE_DOWNTIME
} from "../../constants/page-constant";
import {
  retrieveDataAlarmsModule,
  retrieveDataDowntimeModule
} from "../../actions/RetrieveDataAction";
import {
  updateEnvelopeFilter,
  updateEnvelopeFilterByDevice
} from "../../actions/EnvelopeFilterActions";

const ID_MENU_TREE_PARK = "menu_tree_parks_";
const ID_MENU_TREE_SUBPARK = "menu_tree_subparks_";
const ID_MENU_TREE_WTG = "menu_tree_wtgs_";
const ID_MENU_TREE_ENVELOPE = "menu_tree_envelope_";

let MENU_WIDTH = 200;
let MENU_HEIGHT = 400;
let MENU_HEIGHT_WTG = 600;

class MenuTree extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();
    this.menuTreeListPrimary = React.createRef();
    this.subparkMenuTreeListRef = {};
    this.wtgMenuTreeListRef = {};
    this.curr_page = null;

    this.currToken = null;

    this.state = {
      menuTreeListPrimaryDisplay: "none",
      menuTreeListPrimaryTop: 0,
      menuTreeListPrimaryLeft: 0,

      menuButtonPosition: "relative",
      menuButtonTop: 0,
      menuButtonLeft: 0,

      updateWidgetInterval: null,
      parks: [],
      subparks: [],
      devices: [],
      wtgUnique: false,
      ready: false
    };
  }

  getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }

  buttonMouseEnter = e => {
    const { offsetTop, offsetHeight, offsetLeft } = e.currentTarget;
    const position = this.getOffset(e.currentTarget);
    const height = offsetTop + offsetHeight;
    const viewHeight = window.document.getElementsByTagName("HTML")[0];

    viewHeight.style.overflow = "hidden";

    this.setState({
      menuTreeListPrimaryDisplay: "block",
      menuTreeListPrimaryTop: position.top + 40,
      menuTreeListPrimaryLeft: position.left,
      menuTreeListMaxHeight: `${viewHeight.offsetHeight - height}px`,

      menuButtonPosition: "relative",
      menuButtonTop: height,
      menuButtonLeft: offsetLeft
    });
  };

  parkMouseEnter = id => event => {
    const { target } = event || {};
    const { left, top } = target.getBoundingClientRect() || {};

    const el = window.document.getElementById(`${ID_MENU_TREE_PARK}${id}`);
    const elParent = el.parentNode;
    const { offsetLeft: parentLeft } = elParent;

    const height = STYLE_HEADER_TOOLBAR + STYLE_SUBHEADER_TOOLBAR - 2;
    const viewHeight = window.document.getElementsByTagName("HTML")[0];
    const elChildren = window.document.getElementById(
      `${ID_MENU_TREE_SUBPARK}${id}`
    );

    elChildren.style["display"] = "block";
    elChildren.style["top"] = `${Number(top) - 1}px`;
    elChildren.style["left"] = `${Number(left) - MENU_WIDTH - 2}px`;
    elChildren.style["max-height"] = `${viewHeight.offsetHeight - height}px`;
  };

  parkMouseLeave = id => event => {
    const elChildren = window.document.getElementById(
      `${ID_MENU_TREE_SUBPARK}${id}`
    );

    elChildren.style["display"] = "none";
    elChildren.style["top"] = "-999999px";
    elChildren.style["left"] = "-9999999px";
    elChildren.style["max-height"] = "0";
  };

  subparkMouseEnter = id => event => {
    const { target } = event || {};
    const { left, top } = target.getBoundingClientRect() || {};
    const tHeight = target.offsetHeight || {};

    const el = window.document.getElementById(`${ID_MENU_TREE_SUBPARK}${id}`);
    const elParent = el.parentNode;
    const { top: parentTop } = elParent.getBoundingClientRect();

    const height = STYLE_HEADER_TOOLBAR + STYLE_SUBHEADER_TOOLBAR - 2;
    const viewHeight = window.document.getElementsByTagName("HTML")[0];
    const elChildren = window.document.getElementById(
      `${ID_MENU_TREE_WTG}${id}`
    );

    elChildren.style["display"] = "block";
    elChildren.style["top"] = `${
      tHeight <= 30 ? Number(top) - 1 : parentTop
    }px`;
    elChildren.style["left"] = `${Number(left) - MENU_WIDTH - 2}px`;
    elChildren.style["max-height"] = `${viewHeight.offsetHeight - height}px`;
  };

  subparkMouseLeave = id => event => {
    const elChildren = window.document.getElementById(
      `${ID_MENU_TREE_WTG}${id}`
    );

    elChildren.style["display"] = "none";
    elChildren.style["top"] = "-999999px";
    elChildren.style["left"] = "-9999999px";
    elChildren.style["max-height"] = "0";
  };

  menuLoseFocus = () => {
    // const {User} = this.props
    const { parks_info } = this.props.User;

    const htmlElm = window.document.getElementsByTagName("HTML")[0];
    htmlElm.style.overflow = "auto";

    this.setState({
      menuTreeListPrimaryDisplay: "none"
    });

    // const idsParks = parks_info
    //   .map(elm => elm.id)

    // idsParks.forEach(idPark => {
    //   const elmParks = window.document.getElementById(`${ID_MENU_TREE_SUBPARK}${idPark}`)
    //   elmParks.style['display'] = 'none'
    // })

    // const subparks = R.flatten(parks_info.map(elm => elm.subparks))
    // const idsSubparks = subparks
    //   .map(elm => elm.id)

    // idsSubparks.forEach(idSubpark => {
    //   const elmSubparks = window.document.getElementById(`${ID_MENU_TREE_WTG}${idSubpark}`)
    //   elmSubparks.style['display'] = 'none'
    // })

      // I CHANGED THIS
    // this.props.updateEnvelopeFilter(
    //   this.state.device_id,
    //   this.state.subpark_id
    // );

    const wtgs = R.flatten(
      parks_info.map(park => {
        return park.subparks.map(subpark => {
          return subpark.wtgs.map(wtg => {
            return {
              ...wtg,
              subpark_id: subpark.id,
              subpark_name: subpark.name,
              park_id: park.id,
              park_name: park.name
            };
          });
        });
      })
    );

    const curr_wtgs = wtgs.filter(
      wtg => [this.state.device_id].indexOf(wtg.id) >= 0
    );



    const { devices, subparks } = this.state;
    const devicesFlat = devices
      .reduce((acc, curr) => [...acc, ...curr])
      .reduce((acc, curr) => [...acc, ...curr]);
    const subparksFlat = subparks.reduce((acc, curr) => [...acc, ...curr]);

    const devicesTreated = devicesFlat
      .filter(device => device.selected !== null)
      .map(device => device.id);
    const subparksTreated = subparksFlat
      .filter(subpark => subpark.selected !== null)
      .map(subpark => subpark.id);


      if (curr_wtgs[0]) {
        const cur_envelopes_ids = curr_wtgs[0].envelope.map(env => env.id);
  
        this.props.updateEnvelopeFilterByDevice(cur_envelopes_ids[0]);
      }


    if(devicesTreated.length > 0) {
      this.goUpdate(this.curr_page, devicesTreated, subparksTreated);
      this.curr_page.filter.devices = devicesTreated;
      this.curr_page.filter.subparks = subparksTreated;
      // executeFilter(this.curr_page);

      
      this.props.updateEnvelopeFilter(
        this.state.device_id,
        this.state.subpark_id
      );

      
      this.props.executeFilter(this.curr_page);
    
    } else {
      toast.error('Filter will not be executed, please select at least one device!', {
        position: 'top-right',
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      });

      const info = this.extractParksInfo(this.props);
      this.setState({
        ...info
      });
    }
  };

  goUpdate(page, devicesTreated, subparksTreated) {
    // const { devices, subparks } = this.state;
    // const devicesFlat = devices.reduce((acc, curr) => ([...acc, ...curr])).reduce((acc, curr) => ([...acc, ...curr]));
    // const subparksFlat = subparks.reduce((acc, curr) => ([...acc, ...curr]));

    // const devicesTreated = devicesFlat.filter(device => (device.selected !== null)).map(device => (device.id));
    // const subparksTreated = subparksFlat.filter(subpark => (subpark.selected !== null)).map(subpark => (subpark.id));

    const { filter } = page || {};
    const { devices: pDevices, subparks: pSubparks } = filter || {};

    if (
      !page.type ||
      (page.type != FILTER_TYPE_WTG_ENVELOPE &&
        page.type != PAGE_TYPE_ALARMS &&
        page.type != PAGE_TYPE_DOWNTIME)
    ) {
      if (
        JSON.stringify(pDevices) !== JSON.stringify(devicesTreated) ||
        JSON.stringify(pSubparks) !== JSON.stringify(subparksTreated)
      ) {
        this.props.updateParksFilter(
          this.props.module_field,
          page.id,
          this.props.reducer_name,
          subparksTreated,
          devicesTreated
        );
      }
    }

    if (page.type && page.type === PAGE_TYPE_ALARMS) {
      // this.props.updateParksFilter(this.props.module_field, page.id, this.props.reducer_name, subparksTreated, devicesTreated, false);
      this.props.retrieveDataAlarmsModule(
        this.props.module_field,
        page.id,
        this.props.reducer_name
      );
    }

    if (page.type && page.type === FILTER_TYPE_WTG_ENVELOPE) {
      if (
        JSON.stringify(pDevices) !== JSON.stringify(devicesTreated) ||
        JSON.stringify(pSubparks) !== JSON.stringify(subparksTreated)
      ) {
        const { parks_info } = this.props.User;
        this.props.updateParksEnvelopeFilter(
          this.props.module_field,
          page.id,
          this.props.reducer_name,
          subparksTreated,
          devicesTreated,
          parks_info
        );
        // this.props.updateParksFilter(this.props.module_field, page.id, this.props.reducer_name, subparksTreated, devicesTreated);
      }
    }

    //   if(page.type && page.type == PAGE_TYPE_DOWNTIME) {
    //     this.props.retrieveDataDowntimeModule(this.props.module_field, page.id, this.props.reducer_name)
    //   }
  }

  goToggleDevice = (
    parkIndex,
    subparkIndex,
    deviceIndex,
    isWtgUnique
  ) => () => {
    this.setState(prevState => {
      let devices = prevState.devices;
      let subparks = prevState.subparks;
      let parks = prevState.parks;

      if (isWtgUnique) {
        devices = devices.map(pDevices =>
          pDevices.map(sDevices =>
            sDevices.map(Device => ({
              ...Device,
              selected: null
            }))
          )
        );

        subparks = subparks.map(pSubparks =>
          pSubparks.map(Subpark => ({
            ...Subpark,
            selected: null
          }))
        );

        parks = parks.map(Park => ({
          ...Park,
          selected: null
        }));
      }

      const devicesNewState = devices;
      devicesNewState[parkIndex][subparkIndex][deviceIndex].selected =
        devicesNewState[parkIndex][subparkIndex][deviceIndex].selected === null
          ? "checked"
          : null;

      const filteredDevices = devicesNewState[parkIndex][subparkIndex].filter(
        device => device.selected !== null
      );
      const deviceAmt = filteredDevices.length;
      const subparksNewState = subparks;
      subparksNewState[parkIndex][subparkIndex].selected =
        deviceAmt === 0
          ? null
          : deviceAmt < devicesNewState[parkIndex][subparkIndex].length
          ? "indeterminate"
          : "checked";

      const filteredSubparks = subparksNewState[parkIndex].filter(
        subpark => subpark.selected !== null
      );
      const subparkAmt = filteredSubparks.length;
      const parksNewState = parks;
      parksNewState[parkIndex].selected =
        subparkAmt === 0
          ? null
          : subparkAmt < subparksNewState[parkIndex].length
          ? "indeterminate"
          : "checked";

      let deviceFilter = devicesNewState[parkIndex][subparkIndex][deviceIndex];
      let subparkFilter = filteredSubparks[0];

      return {
        parks: parksNewState,
        subparks: subparksNewState,
        devices: devicesNewState,
        device_id: deviceFilter.id,
        subpark_id: subparkFilter.id
      };
    });
  };

  goToggleSubpark = (parkIndex, subparkIndex, isWtgUnique) => () => {
    if (!isWtgUnique) {
      this.setState(prevState => {
        const subparksNewState = prevState.subparks;
        subparksNewState[parkIndex][subparkIndex].selected =
          subparksNewState[parkIndex][subparkIndex].selected === null
            ? "checked"
            : null;
        const devicesNewState = prevState.devices;
        devicesNewState[parkIndex][subparkIndex] = devicesNewState[parkIndex][
          subparkIndex
        ].map(device => ({
          ...device,
          selected: device.selected === null ? "checked" : null
        }));

        const filteredSubparks = subparksNewState[parkIndex].filter(
          subpark => subpark.selected !== null
        );
        const subparkAmt = filteredSubparks.length;
        const parksNewState = prevState.parks;
        parksNewState[parkIndex].selected =
          subparkAmt === 0
            ? null
            : subparkAmt < subparksNewState[parkIndex].length
            ? "indeterminate"
            : "checked";

        return {
          parks: parksNewState,
          subparks: subparksNewState,
          devices: devicesNewState
        };
      });
    }
  };

  goTogglePark = (parkIndex, isWtgUnique) => () => {
    if (!isWtgUnique) {
      this.setState(prevState => {
        const parksNewState = prevState.parks;
        parksNewState[parkIndex].selected =
          parksNewState[parkIndex].selected === null ? "checked" : null;
        const subparksNewState = prevState.subparks;
        subparksNewState[parkIndex] = subparksNewState[parkIndex].map(
          subpark => ({
            ...subpark,
            selected: parksNewState[parkIndex].selected
          })
        );
        const devicesNewState = prevState.devices;
        devicesNewState[parkIndex] = devicesNewState[parkIndex].map(subpark =>
          subpark.map(device => ({
            ...device,
            selected: parksNewState[parkIndex].selected
          }))
        );

        return {
          parks: parksNewState,
          subparks: subparksNewState,
          devices: devicesNewState
        };
      });
    }
  };

  verifyParentSelected(list) {
    const itemsSelected = list.filter(item => item.selected);

    if (itemsSelected.length > 0) {
      if (itemsSelected.length === list.length) {
        return "checked";
      }

      return "indeterminate";
    }

    return null;
  }

  extractParksInfo = ({ User, page }) => {
    const { parks_info } = User;
    const { filter } = page || {};
    const { devices, subparks } = filter || {};

    const devicesInfo = parks_info.map(pInfo =>
      pInfo.subparks.map(sInfo =>
        sInfo.wtgs.map(wInfo => ({
          id: wInfo.id,
          name: wInfo.name,
          selected: devices.indexOf(wInfo.id) >= 0 ? "checked" : null
        }))
      )
    );

    const subparksInfo = parks_info.map((pInfo, pIndex) =>
      pInfo.subparks.map((sInfo, sIndex) => {
        const dAmt = devicesInfo[pIndex][sIndex].length;
        const dSelectedAmt = devicesInfo[pIndex][sIndex].filter(
          device => device.selected !== null
        ).length;

        return {
          id: sInfo.id,
          name: sInfo.name,
          selected:
            dSelectedAmt === 0
              ? null
              : dSelectedAmt < dAmt
              ? "indeterminate"
              : "checked"
        };
      })
    );

    const parksInfo = parks_info.map((pInfo, pIndex) => {
      const sAmt = subparksInfo[pIndex].length;
      const sSelectedAmt = subparksInfo[pIndex].filter(
        subpark => subpark.selected !== null
      ).length;

      return {
        id: pInfo.id,
        name: pInfo.park,
        selected:
          sSelectedAmt === 0
            ? null
            : sSelectedAmt < sAmt
            ? "indeterminate"
            : "checked"
      };
    });

    return {
      parks: parksInfo,
      subparks: subparksInfo,
      devices: devicesInfo
    };
  };

  componentDidMount() {
    const { User, page } = this.props;
    const { parks_info } = User;

    const info = this.extractParksInfo(this.props);

    this.setState({
      ...info,
      wtgUnique:
        page.type === FILTER_TYPE_WTG || page.type === FILTER_TYPE_WTG_ENVELOPE,
      ready:
        parks_info !== null &&
        parks_info.constructor === Array &&
        parks_info.length > 0
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.ready || prevProps.page.id !== this.props.page.id) {
      setTimeout(() => {
        const { User, page } = this.props;
        const { parks_info } = User;

        const info = this.extractParksInfo(this.props);

        this.setState({
          ...info,
          wtgUnique:
            page.type == FILTER_TYPE_WTG ||
            page.type == FILTER_TYPE_WTG_ENVELOPE,
          ready:
            parks_info !== null &&
            parks_info.constructor === Array &&
            parks_info.length > 0
        });
      }, 500);
    }
  }

  render() {
    const { parks, subparks, devices, wtgUnique } = this.state;
    const { classes, children, page, buttonMenuSubHeader } = this.props;
    this.curr_page = page;

    return (
      <MenuTreeContent>
        <Button
          disableRipple="true"
          ref={this.buttonRef}
          className={`${classes.buttonMenu} ${buttonMenuSubHeader}`}
          // onMouseEnter={this.buttonMouseEnter.bind(this)}
          onClick={this.buttonMouseEnter.bind(this)}
          disabled={!this.state.ready || this.props.disabled}
        >
          {children}
        </Button>
        <div
          style={{
            display: this.state.menuTreeListPrimaryDisplay,
            height: "100vh",
            width: "100%",
            position: "fixed",
            zIndex: 999999,
            top: 0,
            left: 0
            // backgroundColor: 'rgba(0,0,0,.5)'
          }}
          onClick={this.menuLoseFocus.bind(this)}
        />
        <div
          // ref={this.menuTreeListPrimary}
          style={{
            display: this.state.menuTreeListPrimaryDisplay,
            top: this.state.menuTreeListPrimaryTop,
            left: this.state.menuTreeListPrimaryLeft,
            zIndex: 9999999999,
            position: "fixed",
            width: MENU_WIDTH,
            maxHeight: this.state.menuTreeListMaxHeight,
            overflow: "auto",
            border: "1px solid #eeeeee",
            backgroundColor: "white"
          }}
        >
          {parks.map((park, parkIndex) => (
            <div
              id={`${ID_MENU_TREE_PARK}${park.id}`}
              key={`${ID_MENU_TREE_PARK}${park.id}${parkIndex}`}
              onMouseEnter={this.parkMouseEnter(park.id)}
              onMouseLeave={this.parkMouseLeave(park.id)}
            >
              <div
                className={classes.contentButtonList}
                onClick={this.goTogglePark(parkIndex, wtgUnique)}
              >
                {!wtgUnique && (
                  <div>
                    {park.selected === "checked" && <CheckBoxIcon />}
                    {!park.selected && <CheckBoxOutlineBlankIcon />}
                    {park.selected === "indeterminate" && (
                      <IndeterminateCheckBoxIcon />
                    )}
                  </div>
                )}
                <div
                  style={{
                    marginLeft: 10,
                    fontSize: "12px"
                  }}
                >
                  {park.name}
                </div>
              </div>
              <div
                id={`${ID_MENU_TREE_SUBPARK}${park.id}`}
                key={`${ID_MENU_TREE_SUBPARK}${park.id}${parkIndex}`}
                style={{
                  border: "1px solid #eeeeee",
                  backgroundColor: "white",
                  display: "none",
                  top: 0,
                  left: 0,
                  position: "fixed",
                  zIndex: 9999999,
                  width: MENU_WIDTH,
                  maxHeight: MENU_HEIGHT,
                  overflow: "auto"
                }}
              >
                {subparks[parkIndex].map((subpark, subparkIndex) => (
                  <div
                    id={`${ID_MENU_TREE_SUBPARK}${subpark.id}`}
                    key={`${ID_MENU_TREE_SUBPARK}${subpark.id}${subparkIndex}`}
                    onMouseEnter={
                      wtgUnique && this.subparkMouseEnter(subpark.id)
                    }
                    onMouseLeave={
                      wtgUnique && this.subparkMouseLeave(subpark.id)
                    }
                  >
                    <div
                      onClick={this.goToggleSubpark(
                        parkIndex,
                        subparkIndex,
                        wtgUnique
                      )}
                      className={classes.contentButtonList}
                    >
                      {!wtgUnique && (
                        <div>
                          {subpark.selected === "checked" && <CheckBoxIcon />}
                          {!subpark.selected && <CheckBoxOutlineBlankIcon />}
                          {subpark.selected === "indeterminate" && (
                            <IndeterminateCheckBoxIcon />
                          )}
                        </div>
                      )}
                      <div
                        style={{
                          marginLeft: 10,
                          fontSize: "12px"
                        }}
                      >
                        {subpark.name}
                      </div>
                    </div>
                    <div
                      id={`${ID_MENU_TREE_WTG}${subpark.id}`}
                      key={`${ID_MENU_TREE_WTG}${subpark.id}${subparkIndex}`}
                      style={{
                        border: "1px solid #eeeeee",
                        backgroundColor: "white",
                        display: "none",
                        top: 0,
                        left: 0,
                        position: "fixed",
                        zIndex: 9999999,
                        width: MENU_WIDTH,
                        maxHeight: MENU_HEIGHT_WTG,
                        overflow: "auto"
                      }}
                    >
                      {wtgUnique &&
                        devices[parkIndex][subparkIndex].map(
                          (wtg, wtgIndex) => (
                            <div
                              id={`${ID_MENU_TREE_WTG}${wtg.id}`}
                              key={`${ID_MENU_TREE_WTG}${wtg.id}${wtgIndex}`}
                            >
                              <div
                                className={classes.contentButtonList}
                                onClick={this.goToggleDevice(
                                  parkIndex,
                                  subparkIndex,
                                  wtgIndex,
                                  wtgUnique
                                )}
                              >
                                {wtgUnique ? (
                                  <div>
                                    {wtg.selected === "checked" && (
                                      <Icon>radio_button_checked</Icon>
                                    )}
                                    {wtg.selected === null && (
                                      <Icon>radio_button_unchecked</Icon>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {wtg.selected === "checked" && (
                                      <CheckBoxIcon />
                                    )}
                                    {wtg.selected === null && (
                                      <CheckBoxOutlineBlankIcon />
                                    )}
                                  </div>
                                )}
                                <div
                                  style={{
                                    marginLeft: 10,
                                    fontSize: "12px"
                                  }}
                                >
                                  {wtg.name}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </MenuTreeContent>
    );
  }
}

const mapStateToProps = ({ User }) => ({ User });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goCheckDevice,
      goCheckSubpark,
      goCheckPark,
      updateEnvelopeFilter,
      updateEnvelopeFilterByDevice,
      changeUpdateWidgets,
      retrieveDataAlarmsModule,
      retrieveDataDowntimeModule,
      updateParksFilter,
      updateParksEnvelopeFilter
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles(MenuTree.props))(MenuTree));
