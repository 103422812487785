import React, {Component} from 'react'
import Dimensions from 'react-dimensions'
import GridLayout from 'react-grid-layout'
import GraphicWidgetEdit from '../../../../../../../../../../../../components/GraphicModel/GraphicWidgetEdit';
import GraphicsCategory from '../../../../../../../../../../../../data/META/graphics-registry-category.json';
import * as R from 'ramda'
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Select, TextField, Input, IconButton, Button, MenuItem } from '@material-ui/core'
import uuidv4 from 'uuid/v4'



class ReportsNewSelectedGridComponent extends Component {

  constructor (props) {
    super (props)
    this.state = {
      dialogDelete: false,
      selectedWidget:null,
      newWidgetType: null,
      deleteWidgetId: null,
      GraphicsCategory: GraphicsCategory,
      dialogEditWidget: false,
      newDialog: false,
      widgetEdit: {

      }
    }
  }


  editWidget = () => {
   

       this.setState({
        dialogEditWidget: false
      })

      this.props.editWidget(this.state.widgetEdit)
  }


  deleteWidget = () => {


       this.setState({
        dialogDelete: false
      })

    
      this.props.removeWidget(this.state.deleteWidgetId);


  }
  gridLayoutChange = (data) => {
    this.props.gridLayoutChange(data)
  }

  goEditWidget = (data) => {
    this.props.goEditWidget(data)
  }

  goRemoveWidget = (data) => {
    this.props.goRemoveWidget(data)
  }


  closeDialog = dialog => () => {

      this.setState({
        dialogEditWidget: false
      })


    this.setState({ [dialog]: false, newWidgetDescription: '', newWidgetType: null })
  }


  changeEditWidgetType = (widget) => {


      const newItem = {
            ...this.state.widgetEdit,
            widgetType: widget.target.value
        }

       this.setState({
        selectedWidget: widget,
        widgetEdit: newItem
      })

    
    return newItem;

  }

  changeEditWidgetDescription = (e) => {


      const newItem = {
            ...this.state.widgetEdit,
            description: e.target.value
        }

       this.setState({
        widgetEdit: newItem
      })

    
    return newItem;

  }

  // 

    

  onNewWidgetDescription = (e) => this.setState({ newWidgetDescription: e.target.value })


  openDialogEdit = widget => () => {


    const selected = R.find(R.propEq('value', widget.type))(GraphicsCategory[0].options)

    this.setState({
      dialogEditWidget: true,
      selectedWidget: selected,
      widgetEdit: {
        ...widget
      }
    })
  }

  openDialogRemove = (widget_id) => () => {

    this.setState({
      deleteWidgetId: widget_id,
      dialogDelete: true
    })
  }


  render () {

    const {newDialog, dialogDelete} = this.state


    return (
      <div>
        <GridLayout
          // className='layout'
          cols={6}
          rowHeight={(this.props.containerHeight / 10)}
          width={this.props.containerWidth}
          onLayoutChange={this.gridLayoutChange.bind(this)}
          compactType={'horizontal'}
        >
          {this.props.widgets.map(widget => {
            return (
              <div
                key={widget.id}
                data-grid={widget.grid}
                style={{
                  // backgroundColor: 'yellow'
                }}
              >
                <GraphicWidgetEdit
                  widget={{
                    ...widget,
                    titleHeader: widget.description ? widget.description : widget.widgetType.description
                  }}
                  goEdit={this.openDialogEdit(widget).bind(this)}
                  goRemove={this.openDialogRemove(widget.id).bind(this)}
                />
              </div>
            )
          })}


        </GridLayout>

        <Dialog
          onClose={this.closeDialog('editDialog').bind(this)}
          aria-labelledby="dialod-reports-selected-new"
          open={this.state.dialogEditWidget}
        >
          <DialogTitle id="dialod-reports-selected-new">
            Edit widget
          </DialogTitle>
          <div>
            <form
              // className={classes.container}
              noValidate
              autoComplete="off"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 500,
                padding: 10
              }}
            >
              <FormControl>
                <InputLabel
                  htmlFor="age-simple"
                >
                  Widget type
                </InputLabel>
                <Select
                  value={this.state.widgetEdit.widgetType}
                  onChange={this.changeEditWidgetType.bind(this)}
                  inputProps={{
                    name: 'description',
                    id: 'id',
                  }}
                >
                  {R.flatten(this.props.types.map(group => group.widgets)).map((widget) => {
                    return (
                      <MenuItem
                        key={widget.id}
                        value={widget}
                      >
                        {widget.description}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <TextField
                label='Description'
                value={this.state.widgetEdit.description}
                onChange={this.changeEditWidgetDescription.bind(this)}
              />
              
              <div
           
              >
                <br/>

                <Button
                  variant="contained"
                  onClick={this.editWidget.bind(this)}
                                 style={{
                      float: 'right'
                    }}

                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  onClick={this.closeDialog('editDialog').bind(this)}
                   style={{
                      float: 'left'
                    }}

                >
                  Cancel
                </Button>



              </div>
            </form>
          </div>
        </Dialog>

        <Dialog
            onClose={this.closeDialog('dialogDelete').bind(this)}
            open={this.state.dialogDelete}
            aria-labelledby="dialog_overview_delete"
          >
            <DialogTitle id="dialog_overview_delete">
              Delete overview
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you really want to delete overview?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog('dialogDelete').bind(this)} color="primary">
                Back
              </Button>
                <Button
                  onClick={this.deleteWidget.bind(this)}
                  color="primary"
                  autoFocus
                >
                  Delete
                </Button>
            </DialogActions>
          </Dialog>



      </div> 
    )
  }
}

export default Dimensions()(ReportsNewSelectedGridComponent)
