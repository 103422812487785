import React, {Component} from 'react'
import View from './View'  
import {
    updateEnvelopeFilter,
    updateEnvelopeFilterByDevice
  } from "../../../actions/EnvelopeFilterActions";
  
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    withStyles
  
} from '@material-ui/core'
import * as R from 'ramda'



const styles = theme => ({
    gridElement: {
      width: '100%'
    }
  })


class Container extends Component {
    
    render () {

        const { children } = this.props;


        return (
            <View {...this.props} >
                
                {children}
            </View>
        )
  }
}

const mapStateToProps = ({ 
  Dashboard, 
  User, 
  DevicesHierarchy, 
  Client, 
  Navigation,
  Filter
}) => {
    
  const currModule = Navigation.currModule;
  let moduleName = '';

  if(currModule){
     moduleName = Navigation.module_id  == 2 ?  "DelfosPrevious"+currModule.name : currModule.name === "Virtual Clone" ? "Envelope" :  currModule.name; 

  }
 


  const Module = Dashboard[moduleName]
  const { subparks, devices, parks } = Client

  let devicesObject = {}
  devices.length > 0 && devices.map((device) => {

    devicesObject[device.id] = device

  })



  const pageSelected = false

  return { 
    Dashboard, 
    pageSelected, 
    Module, 
    currModule, 
    parks_info: Object.values(DevicesHierarchy), 
    subparks, 
    devices : devicesObject, 
    parks,
    Filter
  }

};


  const mapDispatchToProps = (dispatch) => bindActionCreators({updateEnvelopeFilter}, dispatch);
  
  export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Container))
  
