import React from 'react'
import {Route} from 'react-router-dom'

import ComponentListPage from './pages/ComponentListPage/ComponentListPage';
import { ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT_EDIT, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT_CREATE } from '../../../../../../../../constants/route-constant';
import ComponentChangePage from './pages/ComponentChangePage/ComponentChangePage';

class ComponentPage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          exact
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT}
          render={props => (<ComponentListPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT_CREATE}
          render={props => (<ComponentChangePage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_COMPONENT_EDIT}
          render={props => (<ComponentChangePage {...props} />)}
        />
      </div>
    )
  }
}

export default ComponentPage
