import styled from 'styled-components'

import { AppFull } from '../../AppStyle';
import bgLogin from '../../assets/images/bg_default.jpg'

const FORM_CONTENT_MAX_WIDTH = 500

const AuthContent = styled(AppFull)`
  
`

const AuthWelcomeContent = styled.div`
  /* background-color: yellow; */
  height: 100%;
  width: calc(100% - ${FORM_CONTENT_MAX_WIDTH}px);
  background-image: url(${bgLogin});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`
const AuthFormContent = styled.div`
  /* background-color: white; */
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  max-width: ${FORM_CONTENT_MAX_WIDTH}px;
  height: inherit;
`

const AuthRouterContent = styled(AppFull)``

export {AuthContent, AuthWelcomeContent, AuthFormContent, AuthRouterContent}
