import React from 'react'
import axios from 'axios'
import {Auth} from 'aws-amplify'
import {withStyles, Button, Menu, MenuItem, Paper, CircularProgress} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import moment from 'moment-timezone'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GraphicInteractiveTableHydro from '../../../../../../components/GraphicModel/GraphicInteractiveTable/GraphicInteractiveTableHydro';
import GraphicWidget from '../../../../../../components/GraphicModel/GraphicWidget'
import DatePicker from 'react-datepicker'

import { HYDRO_STYLE_HEADER_FILTER } from '../../constants/style-constant'

const styles = {
  button: {
    borderRadius: 0
  }
}

const TIMES_PROCEDURES = [
  ['series_history_analog_data_1h', '1h'],
  ['series_history_analog_data_3h', '3h'],
  ['series_history_analog_data_24h', '24h']
]

class HydroAlarmComponent extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      plantsSelectAnchorEl: null,
      timeSelectAnchorEl: null,
      variablesSelectAnchorEl: null,
      plantSelected: null,
      timeSelected: null,
      variablesSelected: null,
      plantsInfo: [],
      start_date:null,
      end_date:null,
      param: null,
      loading:false,
    }
  }

  showLoading = () => (
    <div style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={(30)} />
      <div style={{
        marginTop: '20px'
      }}>
        Loading...
      </div>
    </div>
  )


  handleChangeStart = (e) =>  {

    this.setState({ start_date: e })

  }

  handleChangeEnd = (e) =>  {

    this.setState({ end_date: e })

  }

  componentDidMount() {
    Auth.currentSession()
      .then(({accessToken}) => {
        const {jwtToken} = accessToken

        return axios.get(`${process.env.REACT_APP_API_1}/v1/api/pch/get-data`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        })
          .then(({data}) => this.setState({plantsInfo: data}))
      })
      .catch(error => console.error('error', error))
  }

  menuToggle = (param, value) => e => {
    if (value) {
      this.setState({ [param]: e.currentTarget })
    } else {
      this.setState({ [param]: null })
    }
  }

  goFilter = () => {

    this.setState({ loading : true });
    

    Auth.currentSession()
      .then(({accessToken}) => {
        const {jwtToken} = accessToken

        const paramData = {
          procedure_name: 'table_alarm_data',
          plant_id: this.state.plantSelected.plant_id,
          ioa: 1,
          start_time: this.state.start_date,
          end_time: this.state.end_date
        }

        return axios.post(
          `${process.env.REACT_APP_API_1}/v1/api/pch/get-data`, 
          paramData,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': jwtToken
            }
          }
        )
          .then(({data}) => {
              this.setState({ loading : false });

            this.setState({ param: data[0].result })
          }) .catch(error => {
          
          this.setState({ loading : false });
            console.error('error', error)

         })
      })
      .catch(error => {
          
          this.setState({ loading : false });
            console.error('error', error)

         })
        
  }

  render() {
    const {classes} = this.props


   if (this.state.loading) {
      return (
          <Paper className={classes.paperElement} elevation={0}>
              {this.showLoading()}
          </Paper>
        )
  }


    let data = [];
    if (this.state.param) {

       data["table_alarm_data2#"+this.state.plantSelected.plant_id] = this.state.param;
      
    }

    return (
      <div
        style={{
          minHeight: '800px',
          width: '100%'
        }}
      >
        <div
          style={{
            height: HYDRO_STYLE_HEADER_FILTER,
            width: '100%',
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                height: '100%',
                width: `100%`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingLeft: 20,
                paddingRight: 20
              }}
            >
              <div
                style={{
                  flex: 1
                }}
              >
                Histórico de Alarmes
              </div>
              <div
                style={{
                  border: `1px solid ${grey['200']}`
                }}
              >
                <Button
                  className={classes.button}
                  onClick={this.menuToggle('plantsSelectAnchorEl', true).bind(this)}
                >
                  {this.state.plantSelected ? this.state.plantSelected.plant : 'Selecionar Usina'}
                </Button>
                <Menu
                  id='plants-select-menu'
                  anchorEl={this.state.plantsSelectAnchorEl}
                  open={Boolean(this.state.plantsSelectAnchorEl)}
                  onClose={this.menuToggle('plantsSelectAnchorEl', false).bind(this)}
                >
                  {
                    this.state.plantsInfo.map((plant, indexPlant) => {
                      return (
                        <MenuItem
                          key={indexPlant}
                          onClick={e => {
                            this.menuToggle('plantsSelectAnchorEl', false)(e)
                            this.setState({ plantSelected: plant })
                          }}
                        >
                          {plant.plant}
                        </MenuItem>
                      )
                    })
                  }
                </Menu>


                <DatePicker
                    customInput={<Button className={classes.button}>  {this.state.start_date? moment(this.state.start_date).format("DD-MM-YYYY HH:mm") : 'Data Inicial'  } </Button>}
                    showTimeSelect
                    selectsStart
                    selected={this.state.start_date}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="time"
                    onChange={this.handleChangeStart}
                    maxDate={new Date()}

                />

                <DatePicker
                    customInput={<Button className={classes.button}> {this.state.end_date? moment(this.state.end_date).format("DD-MM-YYYY HH:mm") : 'Data Final'  } </Button>}
                    showTimeSelect
                    selectsEnd
                    selected={this.state.end_date}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    onChange={this.handleChangeEnd}
                    timeCaption="time"
                    maxDate={new Date()}
                    popperPlacement="bottom-start"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                          boundariesElement: 'viewport'
                        }
                      }}
                />

                <Button
                  className={classes.button}
                  onClick={this.goFilter.bind(this)}
                  style={{
                    borderLeft: `1px solid ${grey['200']}`,
                    backgroundColor: 'orange',
                    color: 'white'
                  }}
                >
                  Filtrar
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: `calc(100% - ${HYDRO_STYLE_HEADER_FILTER}px)`
          }}
        >
          <div
            style={{
              height: '80%',
              width: '100%'
            }}
          >
            {
              this.state.param ?
              (
              <div>
                  <GraphicInteractiveTableHydro items={data}  subpark={this.state.plantSelected.plant_id}  classes={classes} widget={''} graphic={''} />

              </div>
              )
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(HydroAlarmComponent)
