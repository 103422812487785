import { Auth } from 'aws-amplify'
import axios from 'axios'
import { URL_NODE_API } from '../constants/url-constant'

// export const getScadaData = async (params) => {

//   let dataType = params.dataType;
//   let response  = await getAlarmData(params);

//   return response;

// }

// export const getAlarmData = async (params) => {


//   const {accessToken} = await Auth.currentSession()
//   const {jwtToken} = accessToken
//   let response = [];

//   if (params.dataType.filter(data => data.id == 1 && data.checked == true).length > 0){

//       const URL_ALARMS = `${URL_NODE_API}/management/data/alarms`

//       let alarmParms = {
//        wtgs: params.devices,
//        time_range:[params.startDate.format(), params.endDate.format()],
//         "timezone": "America/Fortaleza"
//       }


//       const alarm =  axios.post(
//         URL_ALARMS,
//         alarmParms,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': jwtToken
//           }
//         }
//       )


//       response.push(await alarm)
//   }

//   return  getTimeSeriesData(params, response) 

// }



// export const getTimeSeriesData = async (params, response) => {


//   const {accessToken} = await Auth.currentSession()
//   const {jwtToken} = accessToken

//   if (params.dataType.filter(data => data.id == 2 && data.checked == true).length > 0){

//       const URL_TIME_SERIES = `${URL_NODE_API}/management/data/timeseries`

//       let seriesParms = {
//        wtgs: params.devices,
//        vars: params.variables_info.map(variable => String(variable.variable_code) ),
//        scada_id: params.scadaSelected.id,
//        time_range:[params.startDate.format(), params.endDate.format()],
//         "timezone": "America/Fortaleza"
//       }


//       const serie =  axios.post(
//         URL_TIME_SERIES,
//         seriesParms,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': jwtToken
//           }
//         }
//       )


//        response.push(await serie);



//   }

//   return getDowntimesData(params, response) 


// }

// export const getDowntimesData = async (params, response) => {


//   const {accessToken} = await Auth.currentSession()
//   const {jwtToken} = accessToken



//   if (params.dataType.filter(data => data.id == 3 && data.checked == true).length > 0){

//     const URL_DOWNTIMES = `${URL_NODE_API}/management/data/downtimes`

//       let downtimesParms = {
//        wtgs: params.devices,
//        time_range:[params.startDate.format(), params.endDate.format()],
//         "timezone": "America/Fortaleza"
//       }


//       const downtime =  axios.post(
//         URL_DOWNTIMES,
//         downtimesParms,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': jwtToken
//           }
//         }
//       )


//        response.push(await downtime);

//     }

//     return response;


// }

export const getAlarmData = async ({devices, startDate, endDate}) => {
    const {accessToken} = await Auth.currentSession();
    const {jwtToken} = accessToken;
  
    const URL_ALARMS = `${URL_NODE_API}/management/data/alarms`;
  
    const alarmParms = {
        wtgs: devices,
        time_range:[startDate, endDate],
        timezone: 'America/Fortaleza'
    }
  
    const { data } = await axios.post(
        URL_ALARMS,
        alarmParms,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        }
    );

    return data;
}

export const getTimeSeriesData = async ({devices, variablesCodes, scadaId, startDate, endDate}) => {
    const {accessToken} = await Auth.currentSession();
    const {jwtToken} = accessToken;

    const URL_TIME_SERIES = `${URL_NODE_API}/management/data/timeseries`;

    const seriesParms = {
        wtgs: devices,
        vars: variablesCodes,
        scada_id: scadaId,
        time_range:[startDate, endDate],
        timezone: 'America/Fortaleza'
    };

    const { data } = await axios.post(
        URL_TIME_SERIES,
        seriesParms,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        }
    );

    return data;
}

export const getDowntimesData = async ({devices, startDate, endDate}) => {
    const {accessToken} = await Auth.currentSession();
    const {jwtToken} = accessToken;
  
    const URL_DOWNTIMES = `${URL_NODE_API}/management/data/downtimes`;
  
    const downtimesParms = {
        wtgs: devices,
        time_range:[startDate, endDate],
        timezone: 'America/Fortaleza'
    };
  
    const { data } = await axios.post(
        URL_DOWNTIMES,
        downtimesParms,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        }
    );

    return data;
}