import React, { useState } from 'react';
import WidgetDriver from '../../components/Driver/WidgetDriver';
import MultioptionsFab from '../Buttons/MultioptionsFab';
import { Icon, Fab, Tooltip } from '@material-ui/core';
import { Redirect } from 'react-router-dom'

const ViewKPIChartTable = props => {
    const {
        widgets,
        changeLoading,
        parks, 
        history, 
        selectedPage,
        data,
        moduleLoading,
        modulesIds
    } = props;

    const [
        KPIWidget,
        ChartWidget,
        TableWidget
    ] = widgets;

    const [ redirect, setRedirect ] = useState();

    return (
        <>
            <div style={{margin: 24}}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: 400
                }} className='fit-page'>
                    <div style={{margin: 6, flex: 3}}>
                        <WidgetDriver 
                            showTable={false}
                            changeLoading={changeLoading}
                            unique={true}
                            ignoreIcons={true}
                            widget={{
                                ...KPIWidget,
                                loading: moduleLoading || data[0] == null,
                                data: data[0] || [],
                                isPieChartKPI: true
                            }}
                            isAnalysis={false}
                            reducer_name={'REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA'}
                            module_id={'DelfosPreviousManagement'}
                            {...{parks, history, selectedPage}}
                        />
                    </div>
                    <div style={{margin: 6, flex: 8}}>
                        <WidgetDriver 
                            showTable={false}
                            changeLoading={changeLoading}
                            unique={true}
                            ignoreIcons={true}
                            widget={{
                                ...ChartWidget,
                                loading: moduleLoading || data[1] == null,
                                data: data[1] || [],
                                grid: {
                                    w: 8,
                                    h: 4
                                }
                            }}
                            isAnalysis={false}
                            reducer_name={'REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA'}
                            module_id={'DelfosPreviousManagement'}
                            {...{parks, history, selectedPage}}
                        />
                    </div>
                </div>
                <div style={{margin: 6, height: 600}}>
                    <WidgetDriver
                        unique={true}
                        changeLoading={changeLoading}
                        ignoreIcons={true}
                        isAnalysis={false}
                        reducer_name={'REDUCER_DELFOS_1_0_MANAGEMENT_GET_DATA'}
                        module_id={'DelfosPreviousManagement'}
                        {...(
                            TableWidget != null ?
                                { 
                                    showTable: false, 
                                    widget: {
                                        ...TableWidget,
                                        loading: moduleLoading || data[2] == null,
                                        data: data[2] || [],
                                        grid: {
                                            w: 12,
                                            h: 4
                                        }
                                    } 
                                }
                            :
                                { 
                                    showTable: true, 
                                    widget: {
                                        ...ChartWidget,
                                        loading: moduleLoading || data[1] == null,
                                        data: data[1] || [],
                                        grid: {
                                            w: 12,
                                            h: 4
                                        }
                                    } 
                                }
                        )}
                        {...{parks, history, selectedPage}}
                    />
                </div>
            </div>
            {
                modulesIds.indexOf(503) >= 0 &&
                    <Tooltip
                        title='Edit Contractual Availabilities'
                    >
                        <Fab 
                            onClick={
                                () => {
                                    setRedirect('/dashboard/operation-and-maintenance/events');
                                    setTimeout(() => setRedirect(null), 100);
                                }
                            }
                            style={{
                                position: 'absolute',
                                bottom: '4%',
                                right: '2%',
                                backgroundColor: '#bdbdbd',
                                color: 'black'
                            }}
                        >
                            <Icon>redo</Icon>
                        </Fab>
                    </Tooltip>
            }
            {
                redirect &&
                modulesIds.indexOf(503) >= 0 &&
                    <Redirect to={redirect} />
            }
        </>
    );
};

export default ViewKPIChartTable;