import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import moment from 'moment-timezone'
import {
  withStyles,
  IconButton
} from '@material-ui/core'
import GridLayout from 'react-grid-layout'
import * as R from 'ramda'

import { PredictionContent } from './PredictionStyle'

import { updateFilterSubparks, updateFilterTimeRange } from './actions/UpdateFilterAction'

import WidgetDriver from '../../../../components/Driver/WidgetDriver'
import BaseGlobalFilter from '../../../../components/Header/BaseGlobalFilter'
import PageWidgetPlaceholder from '../../../../components/Placeholder/PageWidgetPlaceholder'
import DeleteIcon from '@material-ui/icons/Delete'
import Selector from '../../../../components/Filter/Selector'

import { REDUCER_PREDICTION_GET_DATA } from "../../../../constants/reducers/widget-page-action-events"
import { applyFilter } from '../../../../helpers/filter-helper';


const PAGE_FIELD = 'prediction';
const GRID_WIDTH_PADDING = 20

const styles = theme => ({
  gridElement: {
    width: '100%'
  }
})

class PredictionPage extends Component {
  constructor (props) {
    super (props)

    const elBody = window.document.getElementsByTagName('BODY')[0]

    const { [PAGE_FIELD]: Module } = this.props;
    const pageSelected = R.find(R.propEq('selected', true))(Module);

    this.state = {
      gridWidth: elBody.offsetWidth - (GRID_WIDTH_PADDING * 2),
      widgets: [],
      enableEdit: false,
      dialogDeleteOverview: false,
      currPage: pageSelected,
      forceUpdateFilters: false
    }

    this.gridContainerAnchor = React.createRef()
    this.currentDateRange = null
    this.prevManagementId = null
  }

  resizePageWatch = () => {
    const body = window.document.getElementsByTagName('body')[0]

    this.setState({
      gridWidth: body.offsetWidth - (GRID_WIDTH_PADDING * 2)
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizePageWatch);
    this.setState({forceUpdateFilters: true});
    // const { [PAGE_FIELD]: Module } = this.props;
    // const moduleSelected = R.find(R.propEq('selected', true))(Module);
    // this.executeFilter(moduleSelected);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizePageWatch)
  }

  async componentDidUpdate (prevProps, prevState) {

    const { [PAGE_FIELD]: Module } = this.props;
    const { forceUpdateFilters } = this.state;

    const promisesToResolve = this.state.widgets
      .filter(widget => Promise.resolve(widget.data) === widget.data)
    

    let currPage = R.find(R.propEq('selected', true))(Module);


    if ((forceUpdateFilters || prevProps.editMode) && currPage.widgets.length != prevState.widgets.length ) {

      if(!this.props.Dashboard.DeviceFilter.subparks){

        this.props.updateEnvelopeFilter(currPage.filter.devices, currPage.filter.subparks )  
      
      
      }

      this.executeFilter(currPage);

    }

    if(promisesToResolve.length > 0) {
      const widgetData = this.state.widgets.map(widget => widget.data);
      const promisesRes = await Promise.all(widgetData);
      const widgets = this.state.widgets.map((widget, index) => ({
        ...widget, 
        data: promisesRes[index],
        loading: false
      }));
      
      this.setState({
        widgets
      });
    }


    // const promisesToResolve = this.state.widgets
    //   .filter(widget => Promise.resolve(widget.data) === widget.data)
      
    // console.log('promisesToResolve', promisesToResolve)
    // if(promisesToResolve.length > 0) {
    //   const widgetData = this.state.widgets.map(widget => widget.data)[0];
    //   const promiseRes = await Promise.resolve(widgetData);
    //   console.log('PROMISES RES', promiseRes, this.state.widgets);
    //   const widgets = this.state.widgets.map((widget, index) => index === 0 ? ({...widget, data: promiseRes[0]}) : widget);
    //   this.setState({
    //     widgets
    //   });
    // }
  }

  executeFilter = payload => {
    const widgets = applyFilter(payload);

    this.setState({
      widgets,
      currPage:payload
    });
  };

  selectPage = pageId => {
    const { [PAGE_FIELD]: Module } = this.props;



    let newModule = Module.map(management => {
    
          if (management.id === pageId) {
            return R.assoc('selected', true, management)
          }

            return R.assoc('selected', false, management)
    })

    const currPage = R.find(R.propEq('id', pageId))(Module);
    const widgets = applyFilter(currPage);

    this.props.updateProps('prediction',newModule )


    this.setState({
      currPage,
      widgets
    });
  };


  render () {
    const { classes, parks, history } = this.props;
    const { widgets, currPage } = this.state;


    if (!widgets || widgets.length == 0) {
      return (
        <PredictionContent>
          <BaseGlobalFilter
            mode={'view'}
            history={history}
            page={currPage}
            reducer_name={REDUCER_PREDICTION_GET_DATA}
            module_field={'Prediction'}
            executeFilter={this.executeFilter}
            goToPage={this.selectPage}
            enableEdit={() => this.setState({enableEdit: true})}
            {...this.props}


          >
            <Selector updateFilters={this.executeFilter}   page={currPage} />
         </BaseGlobalFilter>

            <PageWidgetPlaceholder />
            
        </PredictionContent>
      )
    }

    return (
      <PredictionContent>
        <BaseGlobalFilter
          mode={'view'}
          history={history}
          page={currPage}
          reducer_name={REDUCER_PREDICTION_GET_DATA}
          module_field={'Prediction'}
          executeFilter={this.executeFilter}
          goToPage={this.selectPage}
          {...this.props}
          enableEdit={() => this.setState({enableEdit: true})}

          
        >
            <Selector updateFilters={this.executeFilter}   page={currPage} />
        </BaseGlobalFilter>
        <div
          style={{
            padding: '0 0 0 15px'
          }}
        >



          {this.state.gridWidth ? (
            <GridLayout
              className={classes.gridElement}
              cols={12}
              rowHeight={200}
              items={50}
              width={this.state.gridWidth}
              compactType={'horizontal'}
              isDraggable={this.props.editMode}
              isResizable={this.props.editMode}
              preventCollision={true}
            >
              {
                widgets && widgets.map((widget, index) => {
                  return (
                    <div style={{
                      overflow: 'hidden',
                       backgroundColor: this.props.editMode ? '#0000003d' : 'none',
                      paddingBottom: this.props.editMode ? '3px' : 'none',
                    }} key={widget.id} data-grid={widget.grid} >

                      { /*this.props.editMode && <div  style={{ backgroundColor: 'white', textAlign: 'center' }} >
                        <IconButton
                          onClick={this.props.openDialogRemove(widget.id)}
                        >
                          <DeleteIcon />
                        </IconButton>

                        </div>
                      */}
                      <WidgetDriver key={`${index}${widget.id}`}
                        classes={classes}
                        selectedPage={currPage}
                        parks={parks}
                        history={history}
                        widget={widget}
                        reducer_name={REDUCER_PREDICTION_GET_DATA}
                        module_id={'Prediction'}
                        isEditing={this.props.editMode}
                        openRemoveDialog={this.props.openDialogRemove(widget.id)}
                      />
                    </div>
                  );
                })
              }
            </GridLayout>
          ) : '' }
        </div>
      </PredictionContent>
    )
  }
}

const mapStateToProps = ({Dashboard, User}) => {
  const {Prediction} = Dashboard
  const {parks_info} = User

  return { Prediction, parks: parks_info, Dashboard }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({updateFilterSubparks, updateFilterTimeRange}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PredictionPage))
