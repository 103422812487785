import {store} from '../store/ProviderStore'
import * as R from 'ramda'
import {
  getDataAlarmsProvider
} from '../providers/get-data-old-provider'
import moment from 'moment-timezone'

export const goToWTGPage = (wtg_name, location_module_field, location_page_id, reducer_name) => {
  return dispatch => {
    const {Dashboard, User} = store.getState()
    const {parks_info} = User

    const Module = location_module_field in Dashboard ? Dashboard[location_module_field] : []

    const filterLocation = Module.filter(page => location_page_id == page.id)
    const locationPage = filterLocation.length > 0 ? filterLocation[0] : null

    if(locationPage) {
      locationPage.selected = true
      const {filter} = locationPage

      const filterWTG = parks_info[0].subparks.filter(subpark => {
        let filterWTGName = subpark.wtgs.filter(wtg => wtg.name == wtg_name)
        return filterWTGName.length > 0
      })

      const selectedWTG = filterWTG.length > 0 ?
        filterWTG[0].wtgs.filter(wtg => wtg.name == wtg_name)[0]
      :
        null

      if(selectedWTG) {
        filter.devices = [selectedWTG.id]
        filter.subparks = [parseInt(String(selectedWTG.id).slice(0, 2))]
        locationPage.filter = filter
      }

      const newStateModule = Module.map(page => {
        if(page.id != locationPage.id) {
          page.selected = false
          return page
        } else {
          return locationPage
        }
      })

      dispatch({
        type: reducer_name,
        payload: newStateModule
      })
    } else {
      
    }
  }
}
