import React, {
    useState,
    useEffect,
    useRef,
    Fragment
} from 'react';
import chance from 'chance';
import moment from 'moment';
import {
    FormControl,
    FormHelperText,
    Tooltip,
    TextField,
    IconButton,
    Icon,
    Button
} from '@material-ui/core';
import DatePicker from '../DatePicker';
import BaseViewDialog from '../../../Base';
import { useTranslation } from 'react-i18next';

const Chance = new chance(121211313131212123131);

const DayView = props => {

    const {
        id = Chance.hash(),
        label,
        value,
        maxDate = moment().add(5, 'years').toDate(),
        minDate = moment.tz('2017-01-01', 'America/Fortaleza').toDate(),
        changeValueFunction,
        textProps,
        style = undefined
    } = props;

    const { t } = useTranslation();

    const displayValue = value ? value.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];

    const [textValue, setTextValue] = useState(displayValue);
    const [inputAnchor, setInputAnchor] = useState(textValue);
    const [error, displayError] = useState(null);
    const [result, execResult] = useState(false);
    const [open, setOpen] = useState(false);

    const inputNode = useRef();

    const matchTextToDate = text => {
        const dateMatch = text.match(/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/g);
        const matches = dateMatch && dateMatch.length === 1;
        return matches;
    };

    const timeBoundsAccept = text => {
        const currTime = moment(text).toDate().getTime();
        const maxTime = maxDate && maxDate.getTime();
        const minTime = minDate && minDate.getTime();
        return ((!maxTime || maxTime - currTime >= 0) && (!minTime || currTime - minTime >= 0));
    }

    const clearError = () => {
        displayError(null);
    };

    const onFocusOut = () => {
        const { current } = inputNode;
        const { value } = current || {};

        if (!matchTextToDate(value)) {
            displayError(`
                ${t('The inputed value')} ${value} ${t('not matches the correct date format, please use this format')}: ${t('YYYY-MM-DD')}.
            `);
        } else if (!timeBoundsAccept(value)) {
            displayError(`
                ${t('The inputed value')} ${value} ${t("isn't in allowed bounds")}: 
                (${minDate.toISOString().split('T')[0]}, ${maxDate.toISOString().split('T')[0]})
            `);
        }

        execResult(true);
    };

    useEffect(() => {
        const { current } = inputNode;
        if (current) {
            current.addEventListener('focusout', onFocusOut);
            current.addEventListener('keydown', event => {
                if (event.keyCode == 13) {
                    clearError();
                    onFocusOut();
                }
            });
        }
    }, [inputNode]);

    useEffect(() => {
        if (error) {
            setTimeout(() => setTextValue(inputAnchor), 300);
        } else {
            clearError();
        }
    }, [error]);

    useEffect(() => {
        if (changeValueFunction) {
            changeValueFunction(moment(textValue).toDate());
        }

        execResult(false);
    }, [result]);

    useEffect(() => {
        if (matchTextToDate(textValue) && timeBoundsAccept(textValue)) {
            setInputAnchor(textValue);
        }
    }, [textValue]);

    useEffect(() => {
        if (value) {
            setTextValue(value.toISOString().split('T')[0]);
        }
    }, [value]);

    return (
        <FormControl
            variant='outlined'
            style={style}
        >
            <TextField
                id={id}
                aria-describedby={`${id}-text`}
                variant='outlined'
                label={label}
                value={textValue}
                onChange={event => {
                    if (error) {
                        displayError(false);
                    }
                    setTextValue(event.target.value);
                }}
                error={error}
                InputProps={{
                    endAdornment: (
                        <Fragment>
                            <IconButton
                                edge='end'
                                onClick={event => setOpen(true)}
                            >
                                <Icon>today</Icon>
                            </IconButton>
                            <BaseViewDialog
                                opened={open}
                                disableDelete={true}
                                Open={setOpen}
                                dialogProps={{
                                    style: {
                                        zIndex: 99999
                                    },
                                    transitionDuration: 0,
                                    maxWidth: 'sm',
                                    PaperProps: {
                                        style: {
                                            width: 420
                                        }
                                    }
                                }}
                                disableTopClose
                                disableCancel
                                disableConfirm
                                title={`${label}: ${displayValue}`}
                            >
                                <DatePicker
                                    {...props}
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    changeValueFunction={day => {
                                        setTimeout(() => setOpen(false), 300);
                                        changeValueFunction(day);
                                    }}
                                />
                            </BaseViewDialog>
                        </Fragment>
                    )
                }}
                inputRef={inputNode}
                {...(textProps || {})}
            />
            {
                error &&
                <FormHelperText id={`${id}-text`}>
                    <Tooltip
                        title={
                            <div>
                                <p>{error}</p>
                                <p>{t('Click to close this message!')}</p>
                            </div>
                        }
                    >
                        <Button
                            style={{
                                textTransform: 'initial',
                                color: 'red',
                                padding: 0,
                                margin: 0,
                                fontSize: '.7em',
                                whiteSpace: 'nowrap',
                                maxWidth: '120px'
                            }}
                            onClick={() => clearError()}
                        >
                            {t('Invalid input format!')}
                            </Button>
                    </Tooltip>
                </FormHelperText>
            }
        </FormControl>
    )
}

export default DayView;