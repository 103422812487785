import React, {Component} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as HighchartsMore from 'highcharts/js/highcharts-more'
import Pareto from 'highcharts/modules/pareto.js';
import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import static_data from './data.json';
import moment from 'moment-timezone'

import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  CircularProgress,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Paper,
  Popper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider,
  Icon,
  Button
} from '@material-ui/core';
import GraphicBarDecorators from './decorators/GraphicBarDecorators';
import {
  BAR_DECORATOR_LIST
} from '../../../constants/decorator-constant';

import { goToWTGPage } from '../../../actions/LinkActions'
// import { popUpWidgetPage } from '../../../actions/PopUpActions'
import PagePopUp from '../../../components/PopUp/PagePopUp'
import { goToPage } from '../../../actions/NavigationActions'
import { changeUpdateWidgets } from '../../../actions/MenuTreeAction'
import {store} from '../../../store/ProviderStore'

import styles from './styles/styles.js';

// HighchartsMore(Highcharts);
Highcharts.setOptions({
    plotOptions: {
        series: {
            animation: false
        }
    }
});
Pareto(Highcharts);


class GraphicBarHydro extends Component {
  constructor (props) {
    super (props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      params:null,
      colors: [
        '#bdbdbd',
        '#767676',
        '#E62C20',
        '#4C65A7',
        '#B414DC',
        '#FCDC34',
        '#FC9018',
        '#0A70D2'
      ],
      columns: [],
      data:null,
      column_sort: 0,
      is_crescent: false
    }

  }


  showLoading = () => (
    <div style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={(30)} />
      <div style={{
        marginTop: '20px'
      }}>
        Loading...
      </div>
    </div>
  )



  render(){
    const {items, subpark, classes} = this.props;
    let {data} = this.state;





    if (subpark == 1) {
      if (items["series_history_sumulative_generation_data_24h#1"]) {
        data = items["series_history_sumulative_generation_data_24h#1"];
      }   
    }
    if (subpark == 2) {
      if (items["series_history_sumulative_generation_data_24h#2"]) {
        data = items["series_history_sumulative_generation_data_24h#2"];
      }   
    }
    



     if (!data) {
        return (
            <Paper className={classes.paperElement} elevation={0}>
                {this.showLoading()}
            </Paper>
          )
    }

       
    const tooltip_formatter = (item, index) => {
      let html_body = null;
      let tooltips = item.tooltip;

      try {
        let tooltip = tooltips[index];
        if(!tooltip) {throw 'Tooltip value null'};

        if(tooltip.constructor == String) {
          html_body = tooltip;
        } else {
          // put json format here
          throw 'Tooltip needs to treat json';
        }
      } catch(e) {

        let value = String(item.values[index]);
        let point_index = value.indexOf('.');
        html_body = `
          <b>${item.name}</b>: ${point_index > 0 ? value.slice(0, point_index + 3) : value} ${item.unit}
        `;
      }
      html_body = `<div>${html_body}</div>`;
      return html_body;
    };


     const params = {
          title: {
            text: 'Generation'
          },
    
          subtitle: {
            text: null
          },
          xAxis: [{
            categories: data.x_axis
          }],
    
          yAxis: {
            title: {
              text: 'Values'
            }
          },
          plotOptions: {
              series: {
                turboThreshold: 1000000
              },
          },
          credits: {
            enabled: false
          },
          series: data.series.map((serie, serieIndex) => {
            return {
              type: 'spline',
              name: serie.name,
              data: serie.values ? serie.values.map((value, index) => ({
                  x: index,
                  y: value,
                  id: tooltip_formatter(serie, index)
                })
              ) : [],
              tooltip: {
                enabled: true,
                useHTML: true,
                pointFormat: '{point.id}',
                headerFormat: '',
                snap: 500,
                followPointer: true
              }
            }
        })
        }





    return (
            <div> 
              <HighchartsReact
                  containerProps={{
                    style: {
                      width: '100%',
                      height: '100%',
                      marginTop: 30,
                      overflow: 'hidden'
                    }
                  }}
                  highcharts={Highcharts}
                  options={params}
                  />
               </div>)
  }


}

export default withStyles(styles)(GraphicBarHydro);
