import React from 'react'
import styled from 'styled-components'
import {withStyles, IconButton, CircularProgress, Button, Dialog, DialogTitle, DialogActions, Paper, TextField, Typography} from '@material-ui/core'
import {Edit, Delete, Add} from '@material-ui/icons'
import {grey} from '@material-ui/core/colors'
import {Link} from 'react-router-dom'
import * as R from 'ramda'

import { toast } from 'react-toastify';
import { getMovementComponentProvider, getMovementCosumableProvider } from '../../../../../../providers/asset-provider';

const PADDING_PAGE = 30
const PAPER_PADDING_PAGE = 20

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  thead tr {    
    height: 65px;
  }

  tbody tr:hover {
    background-color: ${grey['200']};
    cursor: pointer;
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`

const styles = {
  paper: {
    width: `calc(100% - ${(PADDING_PAGE * 3)}px)`,
    padding: PAPER_PADDING_PAGE
  },
  formControl: {
    width: '100%'
  },
  textField: {
    // width: `calc(100% - ${PAPER_PADDING_PAGE}px)`
    width: '100%'
  }
}

class MovementPage extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      loading: false,
      id: null,
      search: '',
      components: [],
      consumables: [],
    }
  }

  componentDidMount () {
    this.setState({loading: true})

    Promise.all([
      getMovementComponentProvider(),
      getMovementCosumableProvider()
    ])
      .then(data => {
        this.setState({
          loading: false,
          components: data[0],
          consumables: data[1]
        })
      })
      .catch(error => {
        this.setState({loading: false})
        console.error('error getMovementComponentProvider, getMovementCosumableProvider', error)
      })
  }

  render () {
    const {classes} = this.props

    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: grey['100']
            backgroundColor: 'white'
          }}
        >
          <CircularProgress />
          <div
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div
          style={{
            height: '100%',
            width: '100%',
            overflow: 'auto',
            padding: PADDING_PAGE,
            backgroundColor: grey['100']
          }}
        >
          {/* <Paper
            className={classes.paper}
            style={{
              marginBottom: PAPER_PADDING_PAGE
            }}
          >
            <TextField
              label="Search"
              className={classes.textField}
              value={this.state.search}
              onChange={e => this.setState({search: e.target.value})}
            />
          </Paper> */}

          <Paper
            className={classes.paper}
          >
            <Typography variant="h5" component="h3">
              Components
            </Typography>
            <TableStyled>
              <thead>
                <tr>
                  <th>
                    Procedure
                  </th>
                  <th>
                    Date
                  </th>
                  <th>
                    Previous
                  </th>
                  <th>
                    Storage
                  </th>
                  <th>
                    User
                  </th>
                  <th>
                    Model
                  </th>
                  <th>
                    Part number
                  </th>
                  <th>
                    Serial number
                  </th>
                  <th>
                    Supplier
                  </th>
                  <th>
                    Manufacturer
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.components
                    .map((item, indexItem) => {
                      return (
                        <tr
                          key={indexItem}
                        >
                          <td>
                            {item.procedure}
                          </td>
                          <td>
                            {item.log_time}
                          </td>
                          <td>
                            {item.previous}
                          </td>
                          <td>
                            {item.now}
                          </td>
                          <td>
                            {item.user_hash}
                          </td>
                          <td>
                            {item.model}
                          </td>
                          <td>
                            {item.part_number}
                          </td>
                          <td>
                            {item.serial_number}
                          </td>
                          <td>
                            {item.supplier}
                          </td>
                          <td>
                            {item.manufacturer}
                          </td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </TableStyled>
          </Paper>
{/* 
          <Paper
            className={classes.paper}
            style={{
              marginTop: PAPER_PADDING_PAGE
            }}
          >
            <Typography variant="h5" component="h3">
              Consumables
            </Typography>
            <TableStyled>
              <thead>
                <tr>
                  <th>
                    Procedure
                  </th>
                  <th>
                    Date
                  </th>
                  <th>
                    Origin
                  </th>
                  <th>
                    Destiny
                  </th>
                  <th>
                    Quantity
                  </th>
                  <th>
                    User
                  </th>
                  <th>
                    Model
                  </th>
                  <th>
                    Part number
                  </th>
                  <th>
                    Supplier
                  </th>
                  <th>
                    Manufacturer
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.consumables
                    .map((item, indexItem) => {
                      return (
                        <tr
                          key={indexItem}
                        >
                          <td>
                            {item.procedure}
                          </td>
                          <td>
                            {item.log_time}
                          </td>
                          <td>
                            {item.origin}
                          </td>
                          <td>
                            {item.destiny}
                          </td>
                          <td>
                            {item.moved_quantity}
                          </td>
                          <td>
                            {item.user_hash}
                          </td>
                          <td>
                            {item.model}
                          </td>
                          <td>
                            {item.part_number}
                          </td>
                          <td>
                            {item.supplier}
                          </td>
                          <td>
                            {item.manufacturer}
                          </td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </TableStyled>
          </Paper> */}

          <div
            style={{
              height: 70
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MovementPage)
