import React from 'react';
import Templates from '../AnalysisTemplate';
import useContainer from './Container';

const View = props => {
    const {
        metaData,
        ...extraProps
    } = props;

    const containerProps = useContainer(props);

    const Template = Templates[metaData.templateId];

    return (
        <Template {...metaData} {...extraProps} {...containerProps} />
    );
};

export default View;