import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import elementResizeEvent from 'element-resize-event'
import highchartsParamHelper from '../../../../helpers/highcharts-param-helper'

const ID_ELEMENT_CHART = '87654567654567ytr56tfr56t-studio-body-chart-component'

class StudioBodyChartComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      params: {}
    }

    this.refChart = React.createRef()
  }

  componentDidMount () {
    const el = window.document.getElementById(ID_ELEMENT_CHART)

    elementResizeEvent(el, () => this.refChart.current.chart.reflow())
  }

  render() {
    const params = highchartsParamHelper(this.props)

    return (
      <div
        id={ID_ELEMENT_CHART}
        style={{
          height: '100%',
          width: '100%',
          maxWidth: 'none',
          overflow: 'auto'
        }}
      >
        <HighchartsReact
          containerProps={{
            style: {
              width: '100%',
              height: '100%',
              // marginTop: 30,
              overflow: 'auto'
            }
          }}
          ref={this.refChart}
          highcharts={Highcharts}
          // constructorType={'stockChart'}
          options={params}
        />
      </div>
    )
  }
}

export default StudioBodyChartComponent
