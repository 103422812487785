import React, {
    useState
} from 'react';

import {
    FormGroup,
    Slider
} from '@material-ui/core';

const TimeIntervalSlider = props => {
    const {
        submit,
        title,
        LabelComponent,
        sliderProps={},
        defaultValue
    } = props;
    
    const [ interval, changeInterval ] = useState(defaultValue || sliderProps.value || [20, 50]);

    return (
        <FormGroup 
            row
            style={{
                marginTop: '-2vh'
            }}
        >
            {
                title &&
                    <span style={{fontSize: '.8em'}}>{title}</span>
            }
            <Slider
                component='div'
                value={interval}
                onChange={(event, value) => changeInterval(value)}
                onChangeCommitted={(event, value) => submit(value)}
                ValueLabelComponent={LabelComponent}
                {...sliderProps}
                style={{
                    margin: 24,
                    width: '30vw',
                    position: 'relative',
                    top: '1.5vh',
                    ...(sliderProps.style || {})
                }}
            />
        </FormGroup>
    );
};

export default TimeIntervalSlider;