import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as R from "ramda";
import { Auth } from "aws-amplify";

import PropTypes from "prop-types";

import * as ROUTES from "../../constants/route-constant";
import HeaderComponent from "../../components/Header/HeaderComponent";
import HeaderPlaceholder from "../../components/Placeholder/HeaderPlaceholder";
import { store } from "../../store/ProviderStore";
import { getDynamoClientMeta } from "../../providers/dynamo-client-meta-provider";
import {
//   setMetaData,
    setMetaDataOld,
    setLastCheckpoint
} from "../../actions/RetrieveDataAction";
import { getDataOld } from "../../actions/DataGetOld";
import { getActualState } from "../../providers/get-data-api-n";
import { parks_info } from "../../constants/graph-types";
import HeaderAllComponent from "../../components/HeaderAllComponent/HeaderAllComponent";
import {
  STYLE_SUBHEADER_TOOLBAR,
  STYLE_HEADER_TOOLBAR
} from "../../constants/style-constant";

import DefaultErrorHandler from "../../components/Handler/DefaultErrorHandler";
import { Menu, MenuItem, Button, CircularProgress } from "@material-ui/core";
import EditableContainer from '../../components/Container/EditableContainer'

import AppContext from "../../App"

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import { getCurrentUserFromStore } from '../../helpers/users-helper';

import { getMetadata, needsMetadata, getOldParksInfo } from '../../providers/meta-provider';
import { getNotifications, getAllNotifications } from '../../providers/user-provider';
import { deviceHierarchy } from '../../helpers/data-conforming-helper';

// import CircularLoading from '../../components/Loading/CircularLoading';

import { setMetadata } from '../../actions/ClientActions';
import { logout } from '../../actions/AuthAction';
import { navigate } from '../../actions/NavigationActions';

let scrollOffset = 0

class Dashboard extends React.Component {
    constructor (props) {
        super (props);
        this.state = {
            prevPath:'',
            editMode:false,
            update:false,
            hasSetConfig: false,
            mountedDashboard: false
        };
    }

    setDynamoData = dynamoData => {
        const data = dynamoData.Items[0]
        store.dispatch(setMetaDataOld(data))

        const pagesToTreat = Object.keys(data).filter(key => (
            data[key].hasOwnProperty('filters') && 
            data[key].hasOwnProperty('pages') &&
            data[key].hasOwnProperty('decorators')
        ));
        
        pagesToTreat.map(key => (
            data[key] = data[key].pages.map(page => ({
                ...page,
                filter: data[key].filters.hasOwnProperty('per_page') 
                        && data[key].filters.per_page.filter(perPage => perPage.page_id === page.id).length === 1
                        ? data[key].filters.per_page.filter(perPage => perPage.page_id === page.id)[0].content
                        : data[key].filters.global,
                widgets: page.widgets.map(widget => {
                    const decorators = data[key].decorators.filter(dec => dec.widget_id === widget.id)[0];
                    return {
                        ...widget,
                        preProcessDecorators: decorators && decorators.preProcessDecorators,
                        postProcessDecorators: decorators && decorators.postProcessDecorators,
                    }
                })
            }))
        ));



        const delfos1_0_pagesToTreat = Object.keys(data.delfos_1_0).filter(key => (
            data.delfos_1_0[key].hasOwnProperty('filters') && 
            data.delfos_1_0[key].hasOwnProperty('pages') &&
            data.delfos_1_0[key].hasOwnProperty('decorators')
        ));
        
        delfos1_0_pagesToTreat.map(key => (
            data.delfos_1_0[key] = data.delfos_1_0[key].pages.map(page => ({
                ...page,
                filter: data.delfos_1_0[key].filters.hasOwnProperty('per_page') 
                        && data.delfos_1_0[key].filters.per_page.filter(perPage => perPage.page_id === page.id).length === 1
                        ? data.delfos_1_0[key].filters.per_page.filter(perPage => perPage.page_id === page.id)[0].content
                        : data.delfos_1_0[key].filters.global,
                widgets: page.widgets.map(widget => {
                    const decorators = data.delfos_1_0[key].decorators.filter(dec => dec.widget_id === widget.id)[0];
                    return {
                        ...widget,
                        preProcessDecorators: decorators && decorators.preProcessDecorators,
                        postProcessDecorators: decorators && decorators.postProcessDecorators,
                    }
                })
            }))
        ));

        return data;


    }

    // componentDidUpdate(prevProps, prevState) {
    //     const { currModule, Modules } = this.props;

    //     if(!prevProps.currModule || prevProps.currModule.route !== currModule.route) {
    //         const { Items } = configData || {};
    //         const config = Items && Items[0];
    //         const { modules } = config;

    //         this.setState({
    //             modules
    //         });
    //     }
    // }

    async componentDidMount () {
        try {
            const { 
                setMetadata, 
                Client, 
                AuthedUser,
                Notifications,
                currUser
            } = this.props;

            const { metadataScore } = Client || {};
            
            // const hasMetadataChanged = await needsMetadata(metadataScore);
            const hasMetadataChanged = false;
            let metadata = null;
            
            if(hasMetadataChanged) {
                metadata = await getMetadata(metadataScore);
            //     this.setState({
            //         loading: false
            //     });
            }

            const configData = await getDynamoClientMeta();
            // const checkpoint = await getActualState();

            const oldParksInfo = await getOldParksInfo();
            // console.log('Checkpoint', checkpoint)

            const { Items } = configData || {};
            const config = Items && Items[0];

            const needsGetHistory = Notifications && Notifications.user !== AuthedUser;
            const notifications = !needsGetHistory ? await getNotifications() : await getAllNotifications();
            
            const treatedConfigData = this.setDynamoData(configData);

            setMetadata({
                meta: metadata, 
                config, 
                // checkpoint, 
                notifications, 
                AuthedUser,
                
                parks_info: oldParksInfo.parksInfo
            });

            this.setState({
                mountedDashboard: true,
                ...treatedConfigData,
                update:false,
                user: currUser
            });
        } catch(err) {
            console.log('Error on configuring dashboard metadata', err)

            if(err === 'No current user' || (err.code && err.code === 'NotAuthorizedException')) {
                console.log('I not have a current user!');
                const { currUser, logout } = this.props;
                logout(currUser.id, true);
            }
        }
    }

    // getData = () {        
    //    getDynamoClientMeta()
    //     .then(dynamoData => {


    //     const data = this.setDynamoData(dynamoData);


    //       getActualState()
    //             .then(userPreferences => {
    //                 const payload = userPreferences && userPreferences.state && Object.keys(userPreferences.state).length > 0
    //                     ? { checkpoint: userPreferences.state }
    //                     : null
                    


    //                 if (payload) {
    //                     Auth.currentAuthenticatedUser()
    //                             .then(user => {
    //                                 this.setState({
    //                                     ...payload.checkpoint,
    //                                     update:false,
    //                                     user: user.attributes
    //                                 });
    //                             })
    //                             .catch(data => {
    //                                 this.setState({
    //                                     ...data,
    //                                     update:false,
    //                                     user: {}
    //                                 })
    //                             })



    //                 } else {



    //                         Auth.currentAuthenticatedUser()
    //                             .then(user => {
    //                                 this.setState({
    //                                     ...data,
    //                                     update:false,
    //                                     user: user.attributes
    //                                 });
    //                             })
    //                             .catch(data => {
    //                                 this.setState({
    //                                     ...data,
    //                                     update:false,
    //                                     user: {}
    //                                 })
    //                             })

    //                 }





    //             }).catch(error => console.error('componentWillMount getDynamoClientMeta', error))

                




    //         // store.dispatch(getDataOld(parks_info, {}));
    //     })
    //     .catch(error => console.error('componentWillMount getDynamoClientMeta', error))
    // }


    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
          this.setState({ prevPath: this.props.location })
        }
      }
    


    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    toggleEdtitMode = () => {

        this.setState({ editMode: !this.state.editMode });
    };

    updateProps = (props, value) => {


    
        let state = this.state;
        state[props] = value
        this.setState({ state });

    };

    headerRefComponent = (ref) => {
        this.setState({ headerRef: ref })
    }

    moduleRender = Component => props => {        
        return (
            <DefaultErrorHandler>
                <EditableContainer {...this.state} updateProps={this.updateProps}>
                    <Component
                        updateProps={this.updateProps}
                        navbarComponent={this.state.fixedFilter}
                        {...props}
                        {...this.state}
                        {...this.props}
                    />
                </EditableContainer>
            </DefaultErrorHandler>
        );
    };

    updateYScroll = async event => {
        // console.log("scroll y!!!!")
        let element = event.target
        let scrollY = element.scrollTop   
    
        if(scrollOffset > scrollY){
          this.state.headerRef.parentNode.setAttribute('style', 'transition: all 500ms cubic-bezier(.24,.74,.74,.7); margin-top: 0')
        } else {
          this.state.headerRef.parentNode.setAttribute('style', 'transition: all 500ms cubic-bezier(.24,.74,.74,.7); margin-top: -65px;')          
        }
    
        // if(scrollY > 85 && this.state.fixedFilter !== true){
        //   this.setState({ fixedFilter: true })
        // } else if(scrollY < 85 && this.state.fixedFilter !== false) {
        //   this.setState({ fixedFilter: false })
        // }
    
        scrollOffset = scrollY
    }

    render () {
        // const { modules, user } = this.state;
        const { pages, currUser, Modules, parksInfo } = this.props;
        
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%'
                }}
                // onScroll={this.updateYScroll}
            >
                <HeaderAllComponent
                    modules={Modules}
                    {...this.props}
                    {...this.state}
                    user={currUser}
                    toggleEdtitMode={this.toggleEdtitMode}
                    editMode={this.state.editMode}
                    data={this.state}
                    handleRef={this.headerRefComponent}                       
                />

                <div
                    className="scrollPage"
                    // onScroll={this.updateYScroll}
                    style={{
                        // NÃOOO MUDEM MAIS ESSA ALTURA PQP....
                        // height: {STYLE_HEADER_TOOLBAR} ?  `calc(100% - ${STYLE_SUBHEADER_TOOLBAR}px)` : `calc(100% - ${STYLE_SUBHEADER_TOOLBAR + STYLE_HEADER_TOOLBAR}px)`,                       
                        // width: '100%',
                        // overflowY: 'auto'
                    }}
                    // onScroll={() => console.log("scroll y!!!!")}
                >
                    {
                        parksInfo &&
                        Object.keys(this.state).length > 0 && (
                            pages.map((router, indexRouter) => (
                                <React.Fragment
                                    key={indexRouter}
                                >
                                    <Route
                                        path={router.path}
                                        render={this.moduleRender(router.component)}
                                    />
                                </React.Fragment>
                            ))
                        )
                    }
                    {
                        parksInfo &&
                        Modules && Modules.length > 0 && Modules.map((itemModule, itemModuleIndex) => (
                            itemModule.submodules && itemModule.submodules.length > 0 && itemModule.route !== R.head(itemModule.submodules).route && (
                                <Route
                                    key={itemModuleIndex}
                                    exact path={itemModule.route}
                                    render={(props) => (
                                        <Redirect
                                            to={{
                                                pathname: R.head(itemModule.submodules).route,
                                                state: { from: props.location }
                                            }}
                                        />
                                    )}
                                />          
                            )
                        )).filter(item => item)
                    }
                    {
                        parksInfo &&
                        Modules && Modules.length > 0 && (
                            <React.Fragment>
                                <Route
                                    exact path={ROUTES.ROUTE_DASHBOARD}
                                    render={(props) => (
                                        <Redirect
                                            to={{
                                                pathname: R.head(Modules).route,
                                                state: { from: props.location }
                                            }}
                                        />
                                    )}
                                />
                            </React.Fragment>
                        )
                    }
                </div>

                {
                    

                    /* <div
                    style={{
                        height: 'auto',
                        width: '100%'
                    }}
                    // onScroll={() => console.log("scroll y!!!!")}
                >
                    {
                        
                    }
                </div>

                <div
                    className="scrollPage"
                    // onScroll={this.updateYScroll}
                    style={{
                        // NÃOOO MUDEM MAIS ESSA ALTURA PQP....
                        // height: {STYLE_HEADER_TOOLBAR} ?  `calc(100% - ${STYLE_SUBHEADER_TOOLBAR}px)` : `calc(100% - ${STYLE_SUBHEADER_TOOLBAR + STYLE_HEADER_TOOLBAR}px)`,                       
                        // width: '100%',
                        // overflowY: 'auto'
                    }}
                    // onScroll={() => console.log("scroll y!!!!")}
                >
                    {
                        Object.keys(this.state).length > 0 && (
                            pages.map((router, indexRouter) => (
                                <React.Fragment
                                    key={indexRouter}
                                >
                                    <Route
                                        path={router.path}
                                        render={this.moduleRender(router.component)}
                                    />
                                </React.Fragment>
                            ))
                        )
                    }
                    {
                        modules && modules.length > 0 && modules.map((itemModule, itemModuleIndex) => (
                            itemModule.submodules && itemModule.submodules.length > 0 && itemModule.route !== R.head(itemModule.submodules).route && (
                                <Route
                                    key={itemModuleIndex}
                                    exact path={itemModule.route}
                                    render={(props) => (
                                        <Redirect
                                            to={{
                                                pathname: R.head(itemModule.submodules).route,
                                                state: { from: props.location }
                                            }}
                                        />
                                    )}
                                />          
                            )
                        )).filter(item => item)
                    }
                    {
                        modules && modules.length > 0 && (
                            <React.Fragment>
                                <Route
                                    exact path={ROUTES.ROUTE_DASHBOARD}
                                    render={(props) => (
                                        <Redirect
                                            to={{
                                                pathname: R.head(modules).route,
                                                state: { from: props.location }
                                            }}
                                        />
                                    )}
                                />
                            </React.Fragment>
                        )
                    }
                </div>                */}

            </div>
        )

    //     }

    //     return( 
    //         <div style={{
    //             position: 'absolute',
    //             height: '100%',
    //             width: '100%',
    //             top: 0,
    //             left: 0,
    //             backgroundColor: 'rgba(255,255,255,.5)',
    //             display: 'flex',
    //             flexDirection: 'column',
    //             justifyContent: 'center',
    //             alignItems: 'center'
    //           }}>
    //             <CircularProgress size={(30)} />
    //             <div style={{
    //               marginTop: '20px'
    //             }}>
    //               Loading...
    //             </div>
    //           </div>
    //           )

      
    // }
}
}

Dashboard.contextType = AppContext;

const mapStateToProps = ({ 
    AuthedUser, 
    Users, 
    Navigation, 
    Modules, 
    Client,
    Notifications,

    User
}) => {
    const currUser = Users[AuthedUser];
    const { currModule } = Navigation;

    const { parks_info: parksInfo } = User;

    return ({
        currUser,
        currModule,
        Modules,
        Client,
        Notifications,
        AuthedUser,

        parksInfo
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setMetadata,
    logout,
    navigate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
