import * as R from 'ramda'
import moment from 'moment-timezone'

import { CUSTOM_AXIS_WTG, CUSTOM_AXIS_DATETIME, CUSTOM_AXIS_VARIABLE, CUSTOM_AXIS_SERIES } from '../../../../../constants/custom-axis-types'

const mapCustomAxis = (customAxis, turbines, data) => {
  return customAxis.map((item) => {
    const {
      opposite,
      labels,
      title
    } = item

    if (item.type.type === CUSTOM_AXIS_WTG) {
      return {
        opposite,
        labels,
        title,
        categories: turbines.map(turbine => turbine.name)
      }
    } else if (item.type.type === CUSTOM_AXIS_DATETIME) {
      const result = {
        opposite,
        labels,
        title,
        type: 'datetime',
        categories: data.map(dataItem => dataItem.sample_time)
      }

      return result
    } else if (item.type.type === CUSTOM_AXIS_VARIABLE) {
      const dataAxis = data.map(dataItem => {
        return dataItem[`var_${item.variable.variable_code}`]
      })

      const max = R.reduce(R.max, 0, dataAxis)
      const min = R.reduce(R.min, Infinity, dataAxis)

      return {
        opposite,
        labels,
        title,
        type: 'linear',
        min,
        max
      }
    } else if (item.type.type === CUSTOM_AXIS_SERIES) {
      return {
        opposite,
        labels,
        title
      }
    } else {
      return false
    }
  })
    .filter(item => item)
}

const verifyCustomAxis = (customAxisX, customAxisY, turbines, data) => {
  const xAxis = mapCustomAxis(customAxisX, turbines, data)
  const yAxis = mapCustomAxis(customAxisY, turbines, data)

  return {
    xAxis,
    yAxis,
    data
  }
}

export default (props) => {
  const deviceIdUniq = R.uniq(props.data.map(item => item.id))

  const valuesPerDeviceId = deviceIdUniq.map(id => {
    const items = props.data.filter(item => item.id === id)

    return items
  })

  const dateTime = R.uniq(props.data.map(item => moment(item.sample_time).format('HH:mm:ss')))

  const valuesPerVariableId = props.selectVariables
    .map(variable => {
      const data = props.data.map(item => {
        return item[`var_${variable.variable_code}`]
      })

      return {
        name: variable.variable_name,
        type: 'line',
        // yAxis: 1,
        data,
        // tooltip: {
        //   valueSuffix: ' mm'
        // }
      }
    })

  const valuesAll = R.flatten(valuesPerVariableId.map(item => item.data))

  const turbines = R.flatten(
    props.dataMenu.map(park => {
      const subparks = park.data.map(subpark => {
        return subpark.data.filter(wtg => wtg.checked)
      })

      return subparks
    })
  )

  const {xAxis, yAxis, data: dataXAxis} = verifyCustomAxis(
    props.customAxisX,
    props.customAxisY,
    turbines,
    props.data
  )


  // console.log('values', valuesAll, valuesPerDeviceId, valuesPerVariableId, dateTime, xAxis, yAxis)

  const series = props.selectVariables
    .map(variable => {
      // const data = props.data.map(item => {
      //   return item[`var_${variable.variable_code}`]
      // })

      const data = valuesPerDeviceId.map(valueDevice => {
        const dataValueDevice = valueDevice.map(item => {
          return item[`var_${variable.variable_code}`]
        })

        return R.median(dataValueDevice)
      })

      return {
        name: variable.variable_name,
        type: 'line',
        // yAxis: 1,
        data,
        // tooltip: {
        //   valueSuffix: ' mm'
        // }
      }
    })

  return {
    chart: {
      zoomType: 'xy'
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    plotOptions: {
      series: {
        turboThreshold: valuesAll.length
      }
    },
    credits: {
      enabled: false
    },
    yAxis,
    xAxis,
    tooltip: {
      shared: true
    },
    // legend: {
    //   layout: 'vertical',
    //   align: 'left',
    //   x: 120,
    //   verticalAlign: 'top',
    //   y: 100,
    //   floating: true,
    //   backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
    // },
    series: [
      ...series
    ]
  }
}