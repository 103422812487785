import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@material-ui/core';
import { MultioptionsFab } from '../../../../../../new-components/Buttons';
import {
    ComponentAcquisitionForm,
    ResourceCreateForm,
    MultipleComponentAcquisitionsForm
} from '../Forms';
import { importMasks } from '../Forms/ComponentAcquisition';
import moment from 'moment';

const View = props => {

    const {
        acquisitionDialogOpened,
        openResourceDialog,
        activeResource,
        openAcquisitionDialog,
        setActiveResource,
        importDialogOpened,
        modulePage,
        openImportDialog,
        rawHistories
    } = props;


    const assetsManagemnt = props['assets-management'];
    const { importMask } = assetsManagemnt || {};
    const { t } = useTranslation();

    const actions = modulePage.type === "CRUD" ?
        [
            {
                icon: <Icon>create</Icon>,
                name: `${t('New')} ${t(modulePage.name)}`,
                onClick: () => {
                    setActiveResource(null);
                    openResourceDialog(true)
                }
            }
        ]
        :
        [
            {
                icon: <Icon>create</Icon>,
                name: t('New Acquisition'),
                onClick: () => {
                    openAcquisitionDialog(true)
                    setActiveResource(null);
                }
            }
        ];

    if (importMasks[importMask] && (modulePage.type !== "CRUD" || modulePage.route === 'componentModel')) {
        actions.push(
            {
                icon: <Icon>cloud_upload</Icon>,
                name: t('Import'),
                onClick: () => {
                    openImportDialog(true)
                }
            }
        );
    }

    if (modulePage.enable_sapiens) {
        actions.push(
            {
                icon: <Icon>cloud_download</Icon>,
                name: 'Sapiens Download',
                onClick: () => {
                    const usingColumns = [
                        'FILIAL',
                        'PRODUTO',
                        'DATA',
                        'QUANTIDADE',
                        'EMPRESA_ORIGEM',
                        'DEPOSITO_ORIGEM'
                    ];

                    const rows = rawHistories.map(rowData => {
                        const { filial, procedure_date: date, destiny_tag, origin_tag, product } = rowData;

                        const originTokens = origin_tag && origin_tag.length > 0 && origin_tag[0].split('_');
                        const destinyTokens = destiny_tag && destiny_tag.length > 0 && destiny_tag[0].split('_');

                        if(originTokens && destinyTokens) {
                            return {
                                filial,
                                date,
                                product,
                                amount: 1,
                                sourceCompany: originTokens[0],
                                sourceStorage: originTokens[1],
                                destinyCompany: destinyTokens[0],
                                destinyStorage: destinyTokens[1]
                            };
                        }
                    }).filter(row => row != null && row.sourceStorage.indexOf('TURB') === -1);

                    const clusteredRows = rows.reduce((acc, curr) => {
                        const { sourceCompany, sourceStorage, destinyCompany, destinyStorage, amount, date, product } = curr;

                        const usingDate = moment(date).tz('UTC').format('DD/MM/YYYY');

                        const key = [ sourceCompany, sourceStorage, destinyCompany, destinyStorage, usingDate ].join('___');

                        if(acc.hasOwnProperty(key)) {
                            return {
                                ...acc,
                                [key]: {
                                    ...curr,
                                    amount: acc.amount + amount,
                                    product: [...(acc.product || []), ...(product || [])],
                                    date: usingDate
                                }
                            }
                        } else {
                            return {
                                ...acc,
                                [key]: {
                                    ...curr,
                                    date: usingDate
                                }
                            };
                        }

                    }, {});

                    const usingRows = Object.entries(clusteredRows).map(([, value]) => ([
                        value.filial,
                        value.product.length > 0 ? value.product[0] : '',
                        value.date,
                        `${value.amount},00000`,
                        value.sourceCompany,
                        value.sourceStorage
                    ]));

                    const dateValues = Object.entries(clusteredRows).map(([, value]) => moment(value.date, 'DD/MM/YYYY')).sort((a, b) => a > b ? 1 : a < b ? -1 : 0);

                    const csvContent = `data:text/csv;charset=utf-8,${usingColumns.join(';')}\n${usingRows.map(row => row.join(';')).join('\n')}`;
                    
                    const minDate = dateValues[0] ? dateValues[0].format('DD/MM/YYYY') : 'invalid_date';
                    const maxDate = dateValues.slice(-1)[0] && dateValues.slice(-1)[0].format('DD/MM/YYYY');

                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute('download', `sapiens_movements_${minDate}${minDate !== maxDate ? `_${maxDate}` : ''}.csv`);
                    document.body.appendChild(link);
                    link.click();
                }
            }
        );
    }

    return (
        <>
            <MultioptionsFab
                openedIcon={<Icon>lock_opened</Icon>}
                activatedIcon={<Icon>lock</Icon>}
                style={{
                    background: '#28a745'
                }}
                Hidden={
                    acquisitionDialogOpened ||
                    importDialogOpened
                }
                actions={actions}
            />
            {modulePage.type === "CRUD" ? <ResourceCreateForm {...props} /> : <ComponentAcquisitionForm {...props} activeResource={activeResource} />}
            <MultipleComponentAcquisitionsForm {...props} />
        </>
    );
};

export default View;