import React from 'react'
import {Route} from 'react-router-dom'

import ConsumableListPage from './pages/ConsumableListPage/ConsumableListPage';
import { ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE_EDIT, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE_CREATE } from '../../../../../../../../constants/route-constant';
import ConsumableChangePage from './pages/ConsumableChangePage/ConsumableChangePage';

class ConsumablePage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          exact
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE}
          render={props => (<ConsumableListPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE_CREATE}
          render={props => (<ConsumableChangePage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_CONSUMABLE_EDIT}
          render={props => (<ConsumableChangePage {...props} />)}
        />
      </div>
    )
  }
}

export default ConsumablePage
