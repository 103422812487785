import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import View from './View';

const Container = props => {
    return(
        <View {...props} />
    );
}

const mapStateToProps = ({Dashboard, User}) => {
    return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
    }
    , dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Container);