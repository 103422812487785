import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import BigCalendar from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {TextField, Select, Input, MenuItem, InputLabel, Checkbox, ListItemText, FormControl} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import * as R from 'ramda'

export default class CalendarToolbar extends Toolbar {



	constructor(...args) {
	    super(...args)

	    this.state = {
	      tagSearch: this.props.tagSearch
	    }


	}


	changeSelect = (e) => {


		this.props.setParam('tagSearch',e.target.value)
  
	}




	render() {

		const view = this.props.view;
		const classes = this.props;


		return (

			 <div className="rbc-toolbar">
					{true ? 
						<div className="rbc-btn-group" >
							<button className="rbc-btn" type="button" onClick={() => this.navigate('TODAY')}>Hoje</button>
							<button className="rbc-btn" type="button" onClick={() => this.navigate('PREV')}>Voltar</button>
							<button className="rbc-btn" type="button" onClick={() => this.navigate('NEXT')}>Próximo</button>
						</div>
						: <button className="rbc-btn" type="button" onClick={() => this.navigate('TODAY')}>Ano Atual</button>

					}


				
			        {/* <FormControl className={classes.formControl} style={{marginBottom: 15, marginLeft: 10, width:'25%'}}>

          				<InputLabel htmlFor="select-multiple-checkbox">Buscar Tags...</InputLabel>
						<Select
						    multiple
						    value={this.state.tagSearch}
				            renderValue={selected => selected.join(', ')}
							onChange={ (e) =>  this.changeSelect(e)}
						  >
						    {R.uniq(this.props.allTags).map(name => (
							    <MenuItem key={name} value={name}>
				               		 <Checkbox checked={this.state.tagSearch.indexOf(name) > -1} 

				               		 />
				                <ListItemText primary={name} />
				              </MenuItem>
						    ))}
						  </Select>

        			</FormControl> */}

					<div className="rbc-toolbar-label"><strong>{this.props.label}</strong></div>
					<div className="rbc-btn-group">
						<button className="rbc-btn" type="button" onClick={this.view.bind(null, 'year')}>Anual</button>
						<button className="rbc-btn" type="button" onClick={this.view.bind(null, 'month')}>Mensal</button>
						<button className="rbc-btn" type="button" onClick={this.view.bind(null, 'week')}>Semanal</button>
						<button className="rbc-btn" type="button" onClick={this.view.bind(null, 'day')}>Diário</button>
					</div>
			</div>
		);
	}
}