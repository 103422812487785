import React from 'react'
import grey from '@material-ui/core/colors/grey'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {Route, Link} from 'react-router-dom'
import styled from 'styled-components'

import AssetModelDetailComponent from '../AssetModelDetailComponent/AssetModelDetailComponent';
import AssetModelComponentComponent from '../AssetModelComponentComponent/AssetModelComponentComponent';
import AssetModelConsumableComponent from '../AssetModelConsumableComponent/AssetModelConsumableComponent';
import AssetModelServiceComponent from '../AssetModelServiceComponent/AssetModelServiceComponent';
import { ROUTE_DASHBOARD_ASSET_ASSET_MODEL } from '../../../../../../../../constants/route-constant';

const styles = {
  button: {
    borderRadius: 0,
    height: '100%',
    borderRight: `1px solid ${grey['200']}`
  },
}

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: black;
`

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  tbody tr:hover {
    background-color:#f5f5f5;
    cursor: pointer;
  }
`

class AssetModelContentComponent extends React.Component {
  render () {
    const {classes} = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <div
          style={{
            height: 40,
            width: '100%',
            borderBottom: `1px solid ${grey['200']}`
          }}
        >
          <LinkStyled
            to={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}`}
          >
            <Button
              className={classes.button}
              style={{
                backgroundColor: `${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}` === this.props.location.pathname
                  ? 'orange'
                  : 'white',
                color: `${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}` === this.props.location.pathname
                  ? 'white'
                  : 'black'
              }}
            >
              Details
            </Button>
          </LinkStyled>
          <LinkStyled
            to={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}/component`}
          >
            <Button
              className={classes.button}
              style={{
                backgroundColor: `${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}/component` === this.props.location.pathname
                  ? 'orange'
                  : 'white',
                color: `${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}/component` === this.props.location.pathname
                  ? 'white'
                  : 'black'
              }}
            >
              Components
            </Button>
          </LinkStyled>
          <LinkStyled
            to={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}/consumable`}
          >
            <Button
              className={classes.button}
              style={{
                backgroundColor: `${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}/consumable` === this.props.location.pathname
                  ? 'orange'
                  : 'white',
                color: `${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}/consumable` === this.props.location.pathname
                  ? 'white'
                  : 'black'
              }}
            >
              Consumables
            </Button>
          </LinkStyled>
          <LinkStyled
            to={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}/service`}
          >
            <Button
              className={classes.button}
              style={{
                backgroundColor: `${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}/service` === this.props.location.pathname
                  ? 'orange'
                  : 'white',
                color: `${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/${this.props.match.params.id}/service` === this.props.location.pathname
                  ? 'white'
                  : 'black'
              }}
            >
              Services
            </Button>
          </LinkStyled>
        </div>
        <div
          style={{
            height: 'calc(100% - 40px)',
            width: '100%'
          }}
        >
          <Route
            exact
            path={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/:id`}
            render={props => {
              return (
                <AssetModelDetailComponent
                  {...props}
                  setParam={this.props.setParam}
                  assetModel={this.props.assetModel}
                />
              )
            }}
          />

          <Route
            path={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/:id/component`}
            render={props => {
              return (
                <AssetModelComponentComponent
                  {...props}
                  setParam={this.props.setParam}
                  assetModel={this.props.assetModel}
                />
              )
            }}
          />

          <Route
            path={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/:id/consumable`}
            render={props => {
              return (
                <AssetModelConsumableComponent
                  {...props}
                  setParam={this.props.setParam}
                  assetModel={this.props.assetModel}
                />
              )
            }}
          />

          <Route
            path={`${ROUTE_DASHBOARD_ASSET_ASSET_MODEL}/:id/service`}
            render={props => {
              return (
                <AssetModelServiceComponent
                  {...props}
                  setParam={this.props.setParam}
                  assetModel={this.props.assetModel}
                />
              )
            }}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(AssetModelContentComponent)
