import { setNotificationsCheckpoint } from '../providers/user-provider';
import {
    VIEW_NOTIFICATIONS,
    SET_NOTIFICATIONS
} from './types';

export const notificationsCheckpoint = () => async dispatch => {
    const res = await setNotificationsCheckpoint();
    const lastUpdate = res.currUpdate && res.currUpdate.replace('T', ' ').split('.')[0];

    return dispatch({
        type: VIEW_NOTIFICATIONS,
        lastUpdate
    });
}

export const setNotifications = notifications => ({
    type: SET_NOTIFICATIONS,
    notifications
});
