import {
    CHANGE_ROUTE,
    REDUCER_NAVIGATION_SELECTED_MODULE,
    REDUCER_NAVIGATION_SELECTED_SUBMODULE,
    FROM_PAGE
  } from '../constants/reducers/navigation-events';
  
  import {  
      SET_METADATA,
      NAVIGATE
  } from '../actions/types';
  
  import variables from '../data/META/navigation-variables';
  
  import { REDUCER_AUTH_LOGOUT, REDUCER_AUTH_LOGIN } from "../constants/reducers/auth-action-events";
  
  const DEFAULT_STATE = {
      ...variables.filter(variable => variable.name == 'DEFAULT_STATE').map(variable => variable.value)[0],
      module_depth: variables.filter(variable => variable.name == 'MODULE_DEPTH').map(variable => variable.value)[0],
      submodule_depth: variables.filter(variable => variable.name == 'SUBMODULE_DEPTH').map(variable => variable.value)[0],
      currModule: null
  }
  
  const currModuleFromMetadata = (config, state) => {
          const { modules, startingModuleId } = config || {};
      const flatModules = modules && 
          modules.length > 0 && 
          modules.map(someModule => {
              const { id, name, route, submodules, type } = someModule;
              return ([
                  { id, name, route, submodules, type },
                  ...submodules.map(submodule => {
                      const { id, name, route, type } = submodule;
                      return ({ id, name, route, type });
                  })
              ]);
          }).reduce((acc, curr) => ([...acc, ...curr]));
  
      const startingModuleFilter = startingModuleId && flatModules ? flatModules.filter(someModule => someModule.id === startingModuleId) : [];
      const startingModule = startingModuleFilter.length > 0 && startingModuleFilter[0];
      const startingSubmodule = startingModule && startingModule.submodules && startingModule.submodules[0];
  
      const firstModule = modules && modules[0];
      const firstSubmodule = firstModule && firstModule.submodules && firstModule.submodules[0];
      const { id, name, route, type } = state.currModule || startingSubmodule || startingModule || firstSubmodule || {
          id: 0, 
          name: 'Root', 
          route: '/',
          type: null
      };
  
      return { id, name, route, type };
  }
  
  export default (state = DEFAULT_STATE, action) => {
      const {
          type: actionType, 
          payload,
          config,
          toModule
      } = action;
  
      switch (actionType) {
          case REDUCER_NAVIGATION_SELECTED_MODULE:
              return {...state, ...payload}
          case REDUCER_NAVIGATION_SELECTED_SUBMODULE:
              return {...state, ...payload}
          case CHANGE_ROUTE:
              return {...state, route: payload}
          case FROM_PAGE:
              return {...state, edit_from: payload}
          case REDUCER_AUTH_LOGOUT:
              return DEFAULT_STATE;
          case REDUCER_AUTH_LOGIN:
              return 'payload' in action &&
                  action.payload &&
                  'checkpoint' in action.payload &&
                  action.payload.checkpoint &&
                  'Navigation' in action.payload.checkpoint &&
                  action.payload.checkpoint.Navigation ?
                      action.payload.checkpoint.Navigation
                  :
                      DEFAULT_STATE;
          case SET_METADATA:
              const currModule = currModuleFromMetadata(config, state);
  
              return {
                  ...state,
                  currModule
              }
          case NAVIGATE:
              if(!toModule) {
                  return state;
              }
  
              const { submodules } = toModule;
              const { id, name, route, type } = (
                  !submodules || 
                  submodules.length === 0 || 
                  Object.keys(submodules).length === 0
              ) ?
                  toModule
              :
                  submodules[0];
  
              return {
                  ...state,
                  currModule: { id, name, route, type }
              }
          default:
              return state;
    }
  }
  