import React, {Component} from 'react';
import * as R from 'ramda';

const TopBottom = (amt=5, type='top | bottom', ordered_by=null) => {
  const real_TopBottom = state => {
    state.data_without_top_bottom = JSON.parse(JSON.stringify(state.data));

    try {
      let data = JSON.parse(JSON.stringify(state.data)); //the most efficient deep copy
      let series = Object.assign([], data.series);
      let x_axis = Object.assign([], data.x_axis);
      let series_values = series.map(element => {
        let serie = {};
        serie[element.name] = {
          values: element.values,
          tooltip: element.tooltip
        };
        return serie;
      }).reduce((acc, curr) => ({...acc, ...curr}));

      let series_correctness = Object.entries(series_values).reduce((acc, curr, index, array) => index == array.length - 1 && acc != false ? acc[1].values.length == curr[1].values.length : curr);
      if(!series_correctness) {throw 'Values does not match X Axis';}

      let serie_average_values = [];
      let serie_average_tooltip = [];

      let avg_pos = x_axis.indexOf('Average');
      if(avg_pos >= 0) {
        x_axis = x_axis.filter(value => value != 'Average');
        Object.keys(series_values).map(key => {
            serie_average_values = serie_average_values.concat(series_values[key].values.filter((value, index) => index == avg_pos));
            series_values[key].values = series_values[key].values.filter((value, index) => index != avg_pos);

            if(series_values[key].tooltip) {
              serie_average_tooltip = serie_average_tooltip.concat(series_values[key].tooltip.filter((value, index) => index == avg_pos));
              series_values[key].tooltip = series_values[key].tooltip.filter((value, index) => index != avg_pos);
            } else {
              serie_average_tooltip = serie_average_tooltip.concat(null);
              series_values[key].tooltip = null;
            }
        });
      }

      let types = type.replace(/ /g, '').split('|').filter(element => element && element != '');
      let merge_range = [];

      if(types.indexOf('top') >= 0 && types.indexOf('bottom') >= 0) {
        merge_range.push(amt);
        merge_range.push(x_axis.length - amt < amt ? amt : x_axis.length - amt);
      } else if(types.indexOf('top') >= 0) {
        merge_range.push(amt);
        merge_range.push(x_axis.length);
      } else if(types.indexOf('bottom') >= 0) {
        merge_range.push(0);
        merge_range.push(x_axis.length - amt < amt ? amt : x_axis.length - amt);
      } else {
        throw 'No valid rule for filtering';
      }
      let to_merge = merge_range[1] - merge_range[0];
      x_axis = x_axis.slice(0, merge_range[0])
              .concat(to_merge > 0 ? `Others (Total: ${to_merge})` : [])
              .concat(x_axis.slice(merge_range[1], x_axis.length));

      Object.keys(series_values).map((key, index) => {
          series_values[key].values = series_values[key].values.slice(0, merge_range[0])
              .concat(to_merge > 0 ? R.mean(series_values[key].values.slice(merge_range[0], merge_range[1])) : [])
              .concat(series_values[key].values.slice(merge_range[1], series_values[key].values.length));

          if(series_values[key].tooltip) {
            series_values[key].tooltip = series_values[key].tooltip.slice(0, merge_range[0])
              .concat(to_merge > 0 ? null : [])
              .concat(series_values[key].tooltip.slice(merge_range[1], series_values[key].tooltip.length));
          }
      });

      if(serie_average_values.length > 0 && serie_average_tooltip.length > 0) {
          x_axis = x_axis.concat('Average');
          Object.keys(series_values).map((key, index) => {
            series_values[key].values = series_values[key].values.concat(serie_average_values[index]);
            if(series_values[key].tooltip) {
              series_values[key].tooltip = series_values[key].tooltip.concat(serie_average_tooltip[index]);
            }
          });
      } else {
        throw 'Error mounting average serie';
      }

      data.x_axis = x_axis;
      data.device_list = x_axis;

      data.series.map(element => {
        element.tooltip = series_values[element.name].tooltip;
        element.values = series_values[element.name].values;
      });

      state.data = JSON.parse(JSON.stringify(data));

    } catch (e) {
      // ;
      state.data = JSON.parse(JSON.stringify(state.data_without_top_bottom));
      delete state.data_without_top_bottom;
    } finally {
      state.data_to_table = state.data_without_top_bottom ? JSON.parse(JSON.stringify(state.data_without_top_bottom)) : null;
      return (state);
    }
  };
  return(real_TopBottom);
};

export default TopBottom;
