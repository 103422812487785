import styled from 'styled-components'

import { AppFull } from '../../../../../AppStyle';
// import { STYLE_HEADER_TOOLBAR, STYLE_SUBHEADER_TOOLBAR } from '../../../../../constants/style-constant';

const AppStyleContent = styled(AppFull)`
  overflow: auto;
  max-height: 100%;
`

export {AppStyleContent}
