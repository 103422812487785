import React from 'react'
import BigCalendar from 'react-big-calendar'
// import './styles.less'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './big-calendar-extra.css'
import 'moment/locale/pt'  // without this line it didn't work
import { withStyles, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Select, MenuItem, Input, IconButton, Tooltip, TextField } from '@material-ui/core'
import YearViewComponent from './YearViewComponent';
import CalendarToolbar from './Toolbar';
import * as R from 'ramda'


const propTypes = {}



class Calendar extends React.Component {
  constructor(...args) {
    super(...args)

    this.state = {
      toolbar: true,
      tagSearch: [],
      currView: 'month',
      supressNavigate: false
    }


  }


  filterEvents = (events) => {

    let filteredEvents = [];


    events.map((ev, indexEvent) => {

      let finds = 0;

      if (this.state.tagSearch.length < 1) {

        return filteredEvents.push(ev)
      }



      ev.tags && ev.tags.map((tag, indexTag) => {


        return this.state.tagSearch.map((ts, indexTs) => {

          const variableName = R.toUpper(tag)
          const tagSearch = R.toUpper(ts)


          if (variableName.search(tagSearch) >= 0) {
            finds += 1;

          }


          if (finds == this.state.tagSearch.length) {
            return filteredEvents.push(ev)

          }

        })


      })

    });


    filteredEvents = filteredEvents.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end)
    }));
    return R.uniq(filteredEvents);

  }


  CustomToolbarCapsule = (props) => {
    return class CustomToolbarInstance extends CalendarToolbar {
      static defaultProps = { ...props }
    }
  }

  setParam = (param, value, callback = null) => {

    this.setState({
      [param]: value
    }, () => {
      if (callback) {
        callback()
      }
    })
  }


  handleToolbarStatus = () => {

    this.setState({
      toolbar: !this.state.toolbar,
    })
  }



  handleSelect = ({ start, end }) => {
    const title = window.prompt('New Event name')
    if (title)
      this.setState({
        events: [
          ...this.state.events,
          {
            start,
            end,
            title,
          },
        ],
      })
  }

  render() {
    const { localizer } = this.props
    const setParam = this.setParam
    const tagSearch = this.state.tagSearch
    const allTags = this.props.allTags
    const { currView } = this.state;

    return (
      <BigCalendar
        selectable
        localizer={localizer}
        style={{
          paddingLeft: 25,
          paddingRight: 25
        }}
        culture='pt'
        components={{
          toolbar: this.CustomToolbarCapsule({ setParam, tagSearch, allTags })

        }}
        views={{
          month: true,
          week: true,
          day: true,
          year: YearViewComponent,
          agenda: false,
        }}
        events={this.filterEvents(this.props.events)}
        defaultView={BigCalendar.Views.MONTH}
        scrollToTime={new Date()}
        changeToolbar={this.handleToolbarStatus}
        defaultDate={new Date()}
        onSelectEvent={this.props.editEvent}
        onSelectSlot={this.props.addEvent(currView)}
        onView={view => {
          this.setState({ currView: view });
        }}
        onNavigate={(date, view) => {
          this.setState({ currView: view });
        }}
        eventPropGetter={this.props.eventPropGetter}
      />
    )
  }
}

Calendar.propTypes = propTypes

export default Calendar