import React from 'react';

import { makeStyles, Paper } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import moment from 'moment-timezone';
import { Calendar } from 'react-date-range';
import locale from 'react-date-range/src/locale/pt';
import { useTranslation } from 'react-i18next';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';


const useStyles = makeStyles({
    paperElement: {
        border: `1px solid ${grey['200']}`,
        borderRadius: 0,
        textAlign: 'center'
    },
    paperHeader: {
        paddingLeft: '10px',
        minHeight: `200px`,
        borderBottom: `1px solid ${grey['200']}`,
        display: 'flex',
        alignItems: 'center'
    },
});


const DayPickerView = props => {

    const {
        changeValueFunction,
        value = moment().toDate(),
        maxDate = moment().add(5, 'years').toDate(),
        minDate = moment.tz('2017-01-01', 'America/Fortaleza').toDate(),
        calendarProps = {},
        paperProps = {},
        style = undefined
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    let localeProps = {};

    if (t('user_language_hardcoded') === 'pt-BR') {
        localeProps = {
            locale
        };
    };



    return (
        <Paper
            style={style}
            className={classes.paperElement}
            elevation={0}
            {...paperProps}
        >
            <Calendar
                onChange={day => {
                    changeValueFunction && changeValueFunction(day)
                }}
                date={value}
                maxDate={maxDate}
                minDate={minDate}
                direction='horizontal'
                color='#ff3300'
                months={1}
                locale={locale}
                {...calendarProps}
                {...localeProps}
            />
        </Paper>
    );
};

export default DayPickerView;