import React from 'react'
import {Route} from 'react-router-dom'
import { ROUTE_DASHBOARD_ASSET_CONTROL_OVERVIEW, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY, ROUTE_DASHBOARD_ASSET_CONTROL_MOVEMENT, ROUTE_DASHBOARD_ASSET_CONTROL_STOCK } from '../../../../constants/route-constant'

import OverviewPage from './pages/OverviewPage/OverviewPage'
import RegistryPage from './pages/RegistryPage/RegistryPage';
import MovementPage from './pages/MovementPage/MovementPage';
import StockPage from './pages/StockPage/StockPage';

class AssetControlPage extends React.Component {
  render () {
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_OVERVIEW}
          render={props => (<OverviewPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY}
          render={props => (<RegistryPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_MOVEMENT}
          render={props => (<MovementPage {...props} />)}
        />

        <Route
          path={ROUTE_DASHBOARD_ASSET_CONTROL_STOCK}
          render={props => (<StockPage {...props} />)}
        />
      </div>
    )
  }
}

export default AssetControlPage
