import React, { Component } from 'react'

import * as R from 'ramda'
import { ROUTE_MAIN_PCH_MONITOR } from '../../constants/route-constant';
import {
    withStyles,
    Tooltip,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    Input,
    InputLabel
} from '@material-ui/core';

import graphTypes from '../../constants/graph-types';
import { editModeChangeTitle, resetChange, editModeChangePosition, editModeRemoveWidget, editModeNewWidget, editModeEditWidget, editModeRemove, editModeNew } from '../../actions/EditModeAction'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CSVLink } from "react-csv";
import { processDataToExport } from "../../providers/data-graphic-provider";
import XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify'


const images_context = require.context('../../assets/preview_images/', false, /\.(png)|(svg)$/)
const images_keys = images_context.keys().map(key => key)
const images = {}

images_keys.map(key => {
    let treated_key = key.split('/')
    treated_key = treated_key[treated_key.length - 1].split('.')[0]
    images[treated_key] = images_context(key)
})


const styles = {
    buttonRight: {
        borderRadius: 0,
        textTransform: 'capitalize',
        fontSize: 12
    },
    buttonLeft: {
        borderRadius: 0,
        textTransform: 'capitalize',
        height: '100%'
    }
}


class ExportContainer extends React.Component {
    constructor(props) {
        super(props)

        const elBody = window.document.getElementsByTagName('BODY')[0]

        this.state = {
            widgets: [],
            widgetEdit: [],
            wtgUnique: false,
            dialogNewWidget: false,
            selectedWidget: [],
            dialogDeleteOverview: false,
            dialogDelete: false,
            dialogRefresh: false,
            widgetNew: [],
            pageNew: false,
            loading: false,
            edited: false,
            managementSelected: []
        }



    }





    goExport(widget, treated = false) {

        processDataToExport(widget, 'excel').then((response) => {
            let dataValues = response.data;

            if (dataValues.length > 0) {

                let names = response.names.length < dataValues[0].length ? [""].concat(response.names) : response.names;

                let values = [];

                if (treated) {


                    values = dataValues;

                } else {

                    values = dataValues.map((data, index) => {
                        let value = {}
                        names.map((name, Nameindex) => {
                            value[name] = data[Nameindex]
                        })
                        return value;
                    })

                }


                let worksheet = XLSX.utils.json_to_sheet(values);
                let new_workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");

                XLSX.writeFile(new_workbook, widget.titleHeader + '.xlsx')


            } else {

                toast.error("Sorry we could not export your graphic data, please try again in few minutes.", {
                    position: "top-right",
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true
                })


            }




            this.props.closeDialogExportOverview();



        }, (error) => {


            console.log(error);
        });



    }



    render() {

        const { children, showExport, widget } = this.props
        const tableDataExcel = {
            'headers': ['Value'],
            'cross_headers': [],
            'rows': []
        };

        return (
            <React.Fragment>

                {children}

                <Dialog
                    onClose={this.props.closeDialogExportOverview}
                    open={showExport}
                    aria-labelledby="dialog_overview_export"
                >
                    <DialogTitle id="dialog_overview_export">
                        Export Graphic Data
                     </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please, select the file format to export.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>


                        <Button
                            color="primary"
                            style={{ backgroundColor: "green", color: "#fff" }}
                            autoFocus
                            onClick={(e) => this.goExport(widget)}

                        >
                            <i class="material-icons">description</i> Excel
                        </Button>


                        <Button
                            color="primary"
                            style={{ backgroundColor: "green", color: "#fff" }}
                            autoFocus
                            onClick={this.props.downloadImage}

                        >
                            <i class="material-icons">image</i> Image
                        </Button>


                        {/* <Button onClick={this.props.closeDialogExportOverview} color="primary">
                            Back
                        </Button> */}
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        )
    }
}




const mapStateToProps = ({ Dashboard, User, Application, Navigation }) => {

    const { Page } = Dashboard
    const { parks_info } = User
    return { Dashboard, Page, parks: parks_info, Application, Navigation }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    editModeChangeTitle,
    editModeChangePosition,
    editModeRemoveWidget,
    editModeNewWidget,
    editModeEditWidget,
    editModeRemove,
    editModeNew,
    resetChange,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExportContainer))
