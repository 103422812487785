import React, {Component} from 'react'
import GridLayout from "react-grid-layout";


const HEADER_LABEL = 80;

const GRID_WIDTH_PADDING = 10;

class ParksGrid extends Component {


    constructor(props) {
        super(props);
    
        const elBody = window.document.getElementsByTagName("BODY")[0];
    
        this.state = {
          gridWidth: elBody.offsetWidth - GRID_WIDTH_PADDING * 2
        };
    }
    



    render () {

        const { classes } = this.props;

        // console.log('this.props.itemsGrid', this.props.itemsGrid)

        return (
            <div
            style={{
              height: `calc(100% - ${HEADER_LABEL}px)`,
              width: "100%"
            }}
          >
            <GridLayout
              className={classes.gridElement}
              width={this.state.gridWidth}
              cols={6}
              rowHeight={100}
              compactType={"horizontal"}
              // onLayoutChange={data => console.log("onLayoutChange", data)}
              draggableHandle={'.move-grid-widget'}
            >
              {this.props.itemsGrid ? this.props.itemsGrid : null}
            </GridLayout>
        </div>)
  }
}

export default ParksGrid
