import React from 'react'
import styled from 'styled-components'
import {withStyles, IconButton, CircularProgress, Button, Dialog, DialogTitle, DialogActions, Paper, TextField} from '@material-ui/core'
import {Edit, Delete, Add} from '@material-ui/icons'
import {grey} from '@material-ui/core/colors'
import {Link} from 'react-router-dom'
import * as R from 'ramda'

import { getStorageProvider, deleteStorageProvider } from '../../../../../../../../../../providers/asset-provider'
import { ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE, ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE_CREATE } from '../../../../../../../../../../constants/route-constant';
import { toast } from 'react-toastify';

const PADDING_PAGE = 30
const PAPER_PADDING_PAGE = 20

const TableStyled = styled.table`
  border-collapse: collapse;
  width: 100%;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  thead tr {    
    height: 65px;
  }

  tbody tr:hover {
    background-color: ${grey['200']};
    cursor: pointer;
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`

const styles = {
  paper: {
    width: `calc(100% - ${(PADDING_PAGE * 3)}px)`,
    padding: PAPER_PADDING_PAGE
  },
  formControl: {
    width: '100%'
  },
  textField: {
    // width: `calc(100% - ${PAPER_PADDING_PAGE}px)`
    width: '100%'
  }
}

class StorageListPage extends React.Component {
  constructor (props) {
    super (props)

    this.state = {
      items: [],
      loading: false,
      id: null,
      search: ''
    }
  }

  componentDidMount () {
    this.setState({loading: true})

    getStorageProvider()
      .then(items => {
        this.setState({
          loading: false,
          items
        })
      })
      .catch(error => {
        this.setState({loading: false})
        console.error('error getStorageProvider', error)
      })
  }

  render () {
    const {classes} = this.props

    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: grey['100']
            backgroundColor: 'white'
          }}
        >
          <CircularProgress />
          <div
            style={{
              marginTop: 20
            }}
          >
            Loading...
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div
          style={{
            height: '100%',
            width: '100%',
            overflow: 'auto',
            padding: PADDING_PAGE,
            backgroundColor: grey['100']
          }}
        >
          <Paper
            className={classes.paper}
            style={{
              marginBottom: PAPER_PADDING_PAGE
            }}
          >
            <TextField
              label="Search"
              className={classes.textField}
              value={this.state.search}
              onChange={e => this.setState({search: e.target.value})}
            />
          </Paper>

          <Paper
            className={classes.paper}
          >
            <TableStyled>
              <thead>
                <tr>
                  <th>
                    Name
                  </th>
                  <th
                    style={{
                      width: 50,
                      textAlign: 'center'
                    }}
                  >
                    Edit
                  </th>
                  <th
                    style={{
                      width: 50,
                      textAlign: 'center'
                    }}
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.items.map((item, indexItem) => {
                    return (
                      <tr
                        key={indexItem}
                      >
                        <td>
                          {item.name}
                        </td>
                        <td>
                          <LinkStyled
                            to={`${ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE}/${item.id}/edit`}
                          >
                            <IconButton
                              aria-label="Edit"
                              className={classes.margin}
                              >
                              <Edit
                                fontSize="small"
                                />
                            </IconButton>
                          </LinkStyled>
                        </td>
                        <td>
                          <IconButton
                            aria-label="Delete"
                            className={classes.margin}
                            onClick={() => {
                              this.setState({
                                id: item.id,
                                dialogDelete: true,
                              })
                            }}
                          >
                            <Delete
                              fontSize="small"
                            />
                          </IconButton>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </TableStyled>
          </Paper>

          <div
            style={{
              height: 70
            }}
          />

          <div
            style={{
              position: 'fixed',
              right: 20,
              bottom: 10
            }}
          >
            <LinkStyled
              to={ROUTE_DASHBOARD_ASSET_CONTROL_REGISTRY_STORAGE_CREATE}
            >
              <Button
                variant='fab'
                color="primary"
                >
                <Add />
              </Button>
            </LinkStyled>
          </div>
        </div>
        
        {/* DELETE ASSET MODEL */}
        <Dialog
          open={this.state.dialogDelete}
          onClose={() => this.setState({dialogDelete: false, id: null})}
        >
          <DialogTitle>
            Delete model
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => this.setState({dialogDelete: false, id: null})}
            >
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.setState({loading: true})
                
                deleteStorageProvider(this.state.id)
                  .then(() => {
                    this.setState({
                      dialogDelete: false,
                      id: '',
                    })

                    getStorageProvider()
                      .then((items) => {
                        this.setState({
                          items,
                          loading: false
                        })
                      })
                      .catch(error => {
                        console.error('getStorageProvider', error)
                        this.setState({
                          loading: false
                        })
                      })

                    toast.success('Success!', {
                      position: "top-right",
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true
                    })
                  })
                  .catch(error => {
                    console.error('updateAssetModelProvider', error)
                    this.setState({
                      loading: false
                    })
                    toast.error('Save error', {
                      position: "top-right",
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true
                    })
                  })
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(StorageListPage)
