import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'

export default theme => ({
  rootContent: {
    height: '100%',
    width: '100%',
    color: grey[700],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between'
  },
  primaryDiv: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  primaryContent: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  primaryContentIndicator: {
    textAlign: 'center'
  },
  primaryContentValue: {
    textAlign: 'center',
    borderBottom: `2px solid ${grey[200]}`,
    marginBottom: '10px'
  },
  subitemsDiv: {
    flex: 1
  },
  subitemsContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  subitemsItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  flipContainer: {
    width: '100%',
    height: `100%`,
    perspective: '1000px',
  },
  flipContainerFront: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    transform: 'rotateX(0deg)',
    backfaceVisibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between'
  },
  flipContainerBack: {
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'rotateX(180deg)',
  },
  flipper: {
    height: '100%',
    width: '100%',
    transition: '0.6s',
    transformStyle: 'preserve-3d',
    position: 'relative',
  },
  tableContent: {
    width: '100%',
    height: '100%'
  },
  tableCellHeaderValue: {
    padding: 0,
    textAlign: 'center'
  },
  tableCellValue: {
    padding: 0
  },
  rowHead: {
    height: 40
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }
})