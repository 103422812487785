import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles, Button, Icon } from '@material-ui/core'

import { MenuTreeContent } from './MenuTreeStyle'
import { changeUpdateWidgets } from '../../actions/MenuTreeAction';
import { selectWtgAndEnvelope } from '../../actions/RetrieveDataAction'
import { updateEnvelopeFilter, updateEnvelopeFilterByDevice } from '../../actions/EnvelopeFilterActions'



const ID_MENU_TREE_SYSTEM = 'menu_tree_systems_';
const ID_MENU_TREE_ENVELOPE = 'menu_tree_envelopes_';

const MENU_WIDTH = 200;
const MENU_WIDTH_ENVELOPE = 350;
const MENU_HEIGHT = 400;

const styles = theme => ({
  buttonMenu: {
    zIndex: 1200,
    minHeight: '44px!important'
  },
  contentButtonList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
    cursor: 'pointer',
    paddingLeft: 10,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.1)'
    }
  }
})

class EnvelopeMenuTree extends Component {
  state = {
    menuTreeListPrimaryDisplay: 'none',
    menuTreeListPrimaryTop: 0,
    menuTreeListPrimaryLeft: 0,

    menuButtonPosition: 'relative',
    menuButtonTop: 0,
    menuButtonLeft: 0,

    updateWidgetInterval: null,

    showMenu: null
  }

  constructor(props) {
    super(props)

    this.buttonRef = React.createRef()
    this.menuTreeListPrimary = React.createRef()
    this.subparkMenuTreeListRef = {}
    this.wtgMenuTreeListRef = {}

    this.systems_info = []
    this.envelope_selected = null
  }

  getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }


  buttonMouseEnter = (e) => {
    const { offsetTop, offsetHeight, offsetLeft } = e.currentTarget

    const height = offsetTop + offsetHeight

    const position = this.getOffset(e.currentTarget);


    const viewHeight = window.document.getElementsByTagName('HTML')[0]
    viewHeight.style.overflow = 'hidden'

    this.setState({
      menuTreeListPrimaryDisplay: 'block',
      menuTreeListPrimaryTop: position.top + 40,
      menuTreeListPrimaryLeft: position.left,
      menuTreeListMaxHeight: `${(viewHeight.offsetHeight - height)}px`,

      menuButtonPosition: 'relative',
      menuButtonTop: height,
      menuButtonLeft: offsetLeft,

      showMenu: [true]
    })
  }

  menuLoseFocus = () => {
    const htmlElm = window.document.getElementsByTagName('HTML')[0];
    htmlElm.style.overflow = 'auto';

    this.setState({
      menuTreeListPrimaryDisplay: 'none',
      showMenu: null
    });

    const { devices: devices_id, subparks: subpark_id, envelope_id } = this.props.page.filter;
    const { id: page_id } = this.props.page;
    const { module_field, reducer_name } = this.props

    if (envelope_id != this.envelope_selected) {
      this.props.selectWtgAndEnvelope(module_field, reducer_name, page_id, subpark_id, devices_id, this.envelope_selected);
      const changedPage = { ...this.props.page, filter: { ...this.props.page.filter, envelope_id: this.envelope_selected } };
      this.props.updateEnvelopeFilterByDevice(this.envelope_selected);

      this.props.executeFilter(changedPage);
    }
  }

  verifyParentSelected(list) {
    const itemsSelected = list.filter(item => item.selected)

    if (itemsSelected.length > 0) {
      if (itemsSelected.length === list.length) {
        return 'checked'
      }

      return 'indeterminate'
    }

    return null
  }

  render() {
    const { User, classes, children, page, buttonMenuSubHeader, module_field, reducer_name } = this.props
    const { parks_info: parks_info_data } = User

    if (!page.filter || !parks_info_data || parks_info_data.length == 0) {
      return ''
    }

    const { devices: devices_id, subparks: subparks_id, envelope_id } = page.filter;
    const curr_wtg_id = devices_id[0];
    const curr_subpark_id = parseInt(String(curr_wtg_id).substring(0, 2))

    const envelopes_info = parks_info_data.map(park => {
      let curr_subpark = park.subparks.filter(subpark => subpark.id == curr_subpark_id);
      if (curr_subpark.length == 0) { return null; }
      curr_subpark = curr_subpark[0];

      let curr_wtg = curr_subpark.wtgs.filter(wtg => wtg.id == curr_wtg_id)[0];
      return curr_wtg.envelope;
    }).filter(park => park != null)[0];

    const systems_info = envelopes_info && new Array(...new Set(envelopes_info.map(envelope => envelope.system)));

    if (!systems_info) {
      return (
        <MenuTreeContent>
          <Button
            disabled={true}
          >
            No System Info
          </Button>
        </MenuTreeContent>
      );
    }

    let envelopes_per_system = systems_info.map((system, systemIndex) => envelopes_info
      .filter(envelope => envelope.system == system)
    );

    envelopes_per_system = envelopes_per_system.map(system_envelope => system_envelope.map(envelope => {
      envelope.name = envelope.name == 'Average Generator Speed 10M vs Generator Speed St. Dev. 10M' ? 'Generator Speed I'
        : envelope.name == 'Average Active Power 10M vs Trafo 3 winding temperature 10M' ? 'Trafo Temperature III'
          : envelope.name == 'Average Active Power 10M vs Average Reactive Power 10M' ? 'Reactive Power'
            : envelope.name == 'Average Active Power 10M vs Bearing N.D.E. Temperature 10M' ? 'NDE Bearing Temperature'
              : envelope.name == 'Average Active Power 10M vs Average Stator Active Power 10M' ? 'Stator Act. Power'
                : envelope.name == 'Average Rotor Speed 10M vs Rotor Speed St. Dev. 10M' ? 'Rotor Speed I'
                  : envelope.name == 'Average Active Power 10M vs Trafo 2 winding temperature 10M' ? 'Trafo Temperature II'
                    : envelope.name == 'Average Active Power 10M vs Generator windings temperature 2 10M' ? 'Gen. Windings Temperature II'
                      : envelope.name == 'Average Active Power 10M vs Generators sliprings temperature 10M' ? 'Gen. Slipring'
                        : envelope.name == 'Average Active Power 10M vs Generator windings temperature 3 10M' ? 'Gen. Windings Temperature III'
                          : envelope.name == 'Average Active Power 10M vs Gearbox bearing temperature 10M' ? 'Gearbox Bearing Temperature'
                            : envelope.name == 'Average Active Power 10M vs Average Rotor Speed 10M' ? 'Rotor Speed II'
                              : envelope.name == 'Average Wind Speed 10M vs Average Active Power 10M' ? 'Measured Power Curve'
                                : envelope.name == 'Average Active Power 10M vs Average Generator Speed 10M' ? 'Generator Speed II'
                                  : envelope.name == 'Average Generator Speed 10M vs Average Rotor Speed 10M' ? 'Rotor Speed III'
                                    : envelope.name == 'Average Active Power 10M vs Bearing D.E. Temperature 10M' ? 'DE Bearing Temperature'
                                      : envelope.name == 'Average Active Power 10M vs Average Grid Voltage 10M' ? 'Grid Voltage'
                                        : envelope.name == 'Average Active Power 10M vs Yaw Misalignment = (WTG Nacelle Position) - (MetMast Wind Dir)' ? 'Yaw Misalignment'
                                          : envelope.name == 'Average Active Power 10M vs Gearbox oil temperature 10M' ? 'Gearbox Oil'
                                            : envelope.name == 'Average Active Power 10M vs Hidraulic group pressure 10M' ? 'Hidraulic Group'
                                              : envelope.name == 'Average Active Power 10M vs Average Pitch Angle 10M' ? 'Pitch Angle'
                                                : envelope.name == 'Average Active Power 10M vs Generator windings temperature 1 10M' ? 'Gen. Windings Temperature I'
                                                  : envelope.name == 'Average Active Power 10M vs Trafo 1 winding temperature 10M' ? 'Trafo Temperature I'
                                                    : envelope.name
      return envelope;
    }));

    if (!this.envelope_selected) {
      if (envelope_id) {
        this.envelope_selected = envelope_id;
      } else {
        this.envelope_selected = envelopes_per_system[0][0].id;
      }
    } else {
      let flat_envelopes = [].concat.apply([], envelopes_per_system.map(envelopes => envelopes.map(envelope => envelope.id)));

      if (flat_envelopes.indexOf(this.envelope_selected) == -1) {
        this.envelope_selected = envelopes_per_system[0][0].id;
        this.props.selectWtgAndEnvelope(module_field, reducer_name, page.id, curr_subpark_id, curr_wtg_id, this.envelope_selected);

        const changedPage = { ...this.props.page, filter: { ...this.props.page.filter, envelope_id: this.envelope_selected } };
        this.props.updateEnvelopeFilterByDevice(this.envelope_selected);

        this.props.executeFilter(changedPage);
      };
    };

    this.systems_info = [...systems_info];

    return (
      <MenuTreeContent>
        <Button
          disableRipple="true"
          ref={this.buttonRef}
          className={`${classes.buttonMenu} ${buttonMenuSubHeader}`}
          onClick={this.buttonMouseEnter.bind(this)}
        >
          {children}
        </Button>
        <div
          style={{
            display: this.state.menuTreeListPrimaryDisplay,
            height: '100vh',
            width: '100%',
            position: 'fixed',
            zIndex: 999999,
            top: 0,
            left: 0,
          }}
          onClick={this.menuLoseFocus.bind(this)}
        />
        <div
          style={{
            display: this.state.menuTreeListPrimaryDisplay,
            top: this.state.menuTreeListPrimaryTop,
            left: this.state.menuTreeListPrimaryLeft,
            zIndex: 9999999999,
            position: 'fixed',
            width: MENU_WIDTH,
            maxHeight: this.state.menuTreeListMaxHeight,
            overflow: 'auto',
            border: '1px solid #eeeeee',
            backgroundColor: 'white'
          }}
        >
          {
            systems_info.map((system, systemIndex) => {
              return (
                <div
                  id={`${ID_MENU_TREE_SYSTEM}${system.toLowerCase().replace(/ /g, '_')}`}
                  key={systemIndex}
                >
                  <div
                    onMouseEnter={() => {
                      if (this.state.showMenu) {
                        this.setState({
                          showMenu: [true, system]
                        });
                      }
                    }}
                    className={classes.contentButtonList}
                  >
                    <div style={{
                      marginLeft: 10,
                      fontSize: '12px',
                      display: `${this.state.showMenu ? (this.state.showMenu[0] ? 'block' : 'none') : 'none'}`
                    }}>
                      {system}
                    </div>
                  </div>
                  <div
                    id={`${ID_MENU_TREE_SYSTEM}${system.toLowerCase().replace(/ /g, '_')}`}
                    style={{
                      border: '1px solid #eeeeee',
                      backgroundColor: 'white',
                      display: `${this.state.showMenu ? (this.state.showMenu[1] == system ? 'block' : 'none') : 'none'}`,
                      top: this.state.menuTreeListPrimaryTop,
                      left: this.state.menuTreeListPrimaryLeft - (MENU_WIDTH + 150),
                      position: 'fixed',
                      zIndex: 9999999,
                      width: MENU_WIDTH_ENVELOPE,
                      maxHeight: MENU_HEIGHT,
                      overflow: 'auto',
                    }}
                  >
                    {
                      envelopes_per_system[systemIndex].map((envelope, envelopeIndex) => {
                        return (
                          <div
                            id={`${ID_MENU_TREE_ENVELOPE}${envelope.id}`}
                            key={envelopeIndex}
                          >
                            <div
                              onClick={() => {
                                this.envelope_selected = envelope.id;
                                this.forceUpdate();
                              }}
                              className={classes.contentButtonList}
                            >
                              <div>
                                {this.envelope_selected == envelope.id ? (<Icon>radio_button_checked</Icon>) : (<Icon>radio_button_unchecked</Icon>)}
                              </div>
                              <div style={{
                                marginLeft: 10,
                                fontSize: '12px'
                              }}>
                                {envelope.name}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </MenuTreeContent>
    );
  };
};

const mapStateToProps = ({ User }) => ({ User });

const mapDispatchToProps = (dispatch) => bindActionCreators({ selectWtgAndEnvelope, changeUpdateWidgets, updateEnvelopeFilterByDevice }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EnvelopeMenuTree));