import React, {
    useState,
    useEffect,
    useRef
} from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
    Table,
    TableBody,
    TableHead,
    TableFooter,
    TableRow
} from '@material-ui/core';

import {
    DefaultTableCell,
    DefaultTablePaginator
} from './components';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 500
    },
    tableWrapper: {
        overflowX: 'auto'
    },
    interactiveRow: {
        cursor: 'pointer',
        transition: 'background 0.3s',

        '&:hover': {
            background: ({ background }) => `${background || '#fccfbd'}`
        }
    },
    ripple: {
        cursor: 'pointer',
        backgroundPosition: 'center',
        transition: 'background 0.3s',

        '&:hover': {
            background: ({ background }) => `${background || '#fccfbd'} radial-gradient(circle, transparent 1%, ${background || '#fccfbd'} 1%) center/15000%`
        },

        '&:active': {
            backgroundColor: ({ background }) => `${background || '#ff8a5c'}`,
            backgroundSize: '100%',
            transition: 'background 0s'
        }
    }
}));

const View = props => {
    const {
        records = [],
        displayHeaders,
        headFormatter = (val) => val,
        fieldFormatter = (index, head, val) => val,
        maxWidth = null,
        rowClickAction = (index, headerCell, row) => () => null,
        resizeFactor = 0.92,
        rowsPerPageOptions = [8, 16, 32],
        cellMaxWidth,
        cellMinWidth
    } = props;

    if (records.length === 0) {
        return null;
    }

    const { t } = useTranslation();

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [limitWidth, setLimitWidth] = useState(maxWidth);

    const headers = displayHeaders || Object.keys(records[0]);

    const tableRef = useRef();

    const handleResize = () => {
        const { current: table } = tableRef;
        if (maxWidth == null) {
            setLimitWidth(table && table.parentNode.getBoundingClientRect().width * resizeFactor);
        }
    };

    useEffect(() => {
        handleResize();
    }, [tableRef]);

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    });

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, records.length - page * rowsPerPage);


    const rowColor = {
        true: "#b3e6be",
        false: "white"

    }

    return (
        <Table
            ref={tableRef}
            className={classes.table}
            size='small'
        >
            <div
                className={classes.tableWrapper}
                style={{
                    width: limitWidth
                }}
            >
                <TableHead>
                    <TableRow>
                        {
                            headers.map(head => (
                                <DefaultTableCell
                                    key={`header-${head}`}
                                    align='left'
                                    padding='checkbox'
                                    colSpan={2}
                                    style={{
                                        minWidth: '40px',
                                        maxWidth: '160px',
                                        padding: 16
                                    }}
                                >
                                    {t(headFormatter(head))}
                                </DefaultTableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).filter(row => props.filter ? props.filter == 1 ? !row.checked : props.filter == 2 ? row.checked : true : true).map((row, rowIndex) => (
                            <TableRow
                                style={{ backgroundColor: rowColor[row.checked ? true : false] }}
                                key={`row-${rowIndex}`}
                                className={[classes.interactiveRow, classes.ripple]}
                                onClick={rowClickAction(page * rowsPerPage + rowIndex, row)}
                            >
                                {
                                    headers.map((headerCell, index) => {
                                        return (
                                            index === 0 ?
                                                <DefaultTableCell
                                                    align='left'
                                                    component='th'
                                                    scope='row'
                                                    padding='default'
                                                    colSpan={2}
                                                    style={{
                                                        minWidth: cellMinWidth || '40px',
                                                        maxWidth: cellMaxWidth || '160px',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        verticalAlign: 'baseline'
                                                    }}
                                                >
                                                    {t(fieldFormatter(index, headerCell, row))}
                                                </DefaultTableCell>
                                                :
                                                <DefaultTableCell
                                                    align='left'
                                                    padding='default'
                                                    colSpan={2}
                                                    style={{
                                                        minWidth: cellMinWidth || '40px',
                                                        maxWidth: cellMaxWidth || '160px',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        verticalAlign: 'baseline'
                                                    }}
                                                >
                                                    {t(fieldFormatter(index, headerCell, row))}
                                                </DefaultTableCell>
                                        );
                                    })
                                }
                            </TableRow>
                        ))
                    }
                    {
                        emptyRows > 0 && (
                            <TableRow style={{ height: 33 * emptyRows }}>
                                <DefaultTableCell colSpan={24} />
                            </TableRow>
                        )
                    }
                </TableBody>
            </div>
            <TableFooter>
                <DefaultTablePaginator
                    count={records.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                />
            </TableFooter>
        </Table>
    );
};

export default View;