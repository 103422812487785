import React, {
    useRef,
    useState,
    useEffect
} from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import chance from 'chance';
import { useTranslation } from 'react-i18next';


const Chance = new chance(121211313131212123131);

const OutlinedView = props => {
    const {
        id = Chance.hash(),
        label,
        error,
        required,
        type = 'text',
        value,
        changeValueFunction,
        style = undefined,
        labelProps = {}
    } = props;

    const { t } = useTranslation();

    const labelRef = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        if (labelRef.current) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
    }, [labelWidth]);

    return (
        <FormControl
            variant='outlined'
            style={style}
        >
            {
                label &&
                <InputLabel
                    ref={labelRef}
                    htmlFor={id}
                    {...labelProps}
                >
                    {label}
                </InputLabel>
            }
            <OutlinedInput
                id={id}
                inputProps={{ min: "0" }}
                type={type}
                onChange={event => changeValueFunction(event.target.value)}
                labelWidth={labelWidth}
                {...props}
            />
            {
                error && required && !value &&
                <FormHelperText id={`${id}`} style={{ marginTop: -2, marginBottom: 2 }}>
                    <span
                        style={{
                            textTransform: 'initial',
                            color: 'red',
                            padding: 0,
                            margin: 0,
                            marginBottom: -2,
                            fontSize: '.7em',
                            whiteSpace: 'nowrap',
                            maxWidth: '120px'
                        }}
                    >
                        {t('Please, fill this field!')}
                    </span>
                </FormHelperText>
            }
        </FormControl>
    )
}

export default OutlinedView;