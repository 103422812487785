import React, {Component} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import * as HighchartsMore from 'highcharts/js/highcharts-more'
import Pareto from 'highcharts/modules/pareto.js';
import * as R from 'ramda'
import { WIDGET_HEADER_HEIGHT } from '../../../constants/widget-constant';
import static_data from './data.json';
import moment from 'moment-timezone'

import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  CircularProgress,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Paper,
  Popper,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Switch,
  Collapse,
  ExpandLess,
  ExpandMore,
  Divider,
  Icon,
  Button
} from '@material-ui/core';

import { goToWTGPage } from '../../../actions/LinkActions'
// import { popUpWidgetPage } from '../../../actions/PopUpActions'
import PagePopUp from '../../../components/PopUp/PagePopUp'
import { goToPage } from '../../../actions/NavigationActions'
import { changeUpdateWidgets } from '../../../actions/MenuTreeAction'
import {store} from '../../../store/ProviderStore'

import styles from './styles/styles.js';

// HighchartsMore(Highcharts);
Highcharts.setOptions({
    plotOptions: {
        series: {
            animation: false
        }
    }
});
Pareto(Highcharts);


class GraphicLevelFluency extends Component {
  constructor (props) {
    super (props);

    this.refChart = React.createRef();

    this.state = {
      chart: null,
      params:null,
      colors: [
        '#bdbdbd',
        '#767676',
        '#C0D2FC',
        '#F4645C',
        '#E62C20',
        '#4C65A7',
        '#B414DC',
        '#FCDC34',
        '#FC9018',
        '#0A70D2'
      ],
      columns: [],
      data:null,
      column_sort: 0,
      is_crescent: false
    }

  }

  componentDidMount() {
    try {
      this.props.sendRefChart(this.refChart);
    } catch(err) {
      console.log('Error on getting refchart', err);
    }
  }

  showLoading = () => (
    <div style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,.5)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={(30)} />
      <div style={{
        marginTop: '20px'
      }}>
        Loading...
      </div>
    </div>
  )



  shouldComponentUpdate(nextProps, nextState){

    let {items} =  nextProps;
    let event_name = nextProps.widget.event.concat('#', nextProps.subpark);
    let old_event_name = this.props.widget.event.concat('#', this.props.subpark);

    if (this.props.subpark != nextProps.subpark || this.props.showTable != nextProps.showTable){
      return true;
    }


    if (this.state.column_sort != nextState.column_sort || this.state.is_crescent != nextState.is_crescent) {

          return true;
    }
    

    if (items[event_name]) {

      if (items[event_name] ===  this.props.items[old_event_name]) {

          return false;
      }

        return true;

    }

    return false;

  }




  render(){
    const {items, subpark, classes, widget, showTable, showSettings} = this.props;
    let {data} = this.state;

    

    let font_size = 12;

    let graphic = widget;
    let event_name = widget.event.concat('#', subpark);

    data = items[event_name];

    if (!data) {
      return (
          <Paper className={classes.paperElement} elevation={0}>
              {this.showLoading()}
          </Paper>
        )
    }
       
    const tooltip_formatter = (item, index) => {
      let html_body = null;
      let tooltips = item.tooltip;

      try {
        let tooltip = tooltips[index];
        if(!tooltip) {throw 'Tooltip value null'};

        if(tooltip.constructor == String) {
          html_body = tooltip;
        } else {
          // put json format h
          throw 'Tooltip needs to treat json';
        }
      } catch(e) {

        let value = String(item.values[index]);
        let point_index = value.indexOf('.');
        html_body = `
          <b>${item.name}</b>: ${point_index > 0 ? value.slice(0, point_index + 3) : value} ${item.unit}
        `;
      }
      html_body = `<div>${html_body}</div>`;
      return html_body;
    };


    const params = {
      colors: this.state.colors,

      chart: {
        type: 'column',
        alignTicks:false,
        //margin:[15,15,35,15],
        width:100,
        height:300,
        marginLeft: 50,
        alignTicks:false
      },
      credits:{enabled:false},
      exporting:{enabled:true},
      legend:{enabled:false},
      title: {
          text: ''
      },
      subtitle: {
          text: ''
      },
      xAxis: {
        max: 5,
        labels:''
      },
      scrollbar: {
        enabled: false
       },
      yAxis: {

        title: {
          text: ''
      },
        opposite: true,          
          min:data.limits.lim_inf,
          max: data.limits.lim_sup,
          endOnTick: false,       
          labels:{
            x: 2,
            y: -2,            
            style:{fontSize:'10px'}
          },
        plotBands:
          [
            {from: data.limits.lim_inf, to: data.limits.lower_red, color: '#ff0000'},
            {from: data.limits.lower_red, to: data.limits.lower_yellow, color: '#ffff00'},
            {from: data.limits.lower_yellow, to: data.limits.upper_yellow, color: '#00ff00'},
            {from: data.limits.upper_yellow, to: data.limits.upper_red, color: '#ffff00'},
            {from: data.limits.upper_red, to: data.limits.lim_sup, color: '#ff0000'},            
          ]
      },
      tooltip:{
          enabled:true,
          backgroundColor:'rgba(255, 255, 255, .85)',
          borderWidth:0,
          shadow:true,
          style:{fontSize:'7px',padding:0},
          formatter:function() {
            return this.series.name + ": <strong>" + Highcharts.numberFormat(this.y,2) + "</strong>";
          }
      },
      plotOptions: {
        series: {                 
            },
      },
      series:[      
          {
            name:'Value',
            type: 'scatter',
            data: [[0, data.value]],
            marker: {
                symbol:'url(http://www.veryicon.com/icon/32/System/Crystal%20Clear%20Actions/Right%20Arrow.png)',
            }
          }        
      ]
      
    }





    return (
            <div> 
              <HighchartsReact
                  containerProps={{
                    style: {
                      width: '100%',
                      height: '100%',
                      marginTop: 10,
                      overflow: 'hidden'
                    }
                  }}
                  highcharts={Highcharts}
                  options={params}
                  />
               </div>)
  }


}

export default withStyles(styles)(GraphicLevelFluency);
