import React, {
    useState,
    useRef,
    useCallback,
    useEffect
} from 'react';

import { renderToString } from 'react-dom/server';
import { usePrevious } from '../../../hooks';
import { EVENTS_COLORS } from '../../../modules/Dashboard/pages/CalendarPage/CalendarPage';
import { useTranslation } from 'react-i18next';


const formatLabel = (point, isInside, chart) => {
    const fontSizePP = chart && (Math.sqrt(Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2)) / Math.sqrt(Math.pow(chart.plotWidth, 2) + Math.pow(chart.plotHeight, 2))) * 100;

    const Component = (
        <div style={{ fontSize: `${fontSizePP}%` }} >
            <p
                className={isInside && 'is-inside'}
                style={{
                    color: isInside ? 'white' : '#878787',
                    fontSize: '.25em',
                    padding: 0,
                    margin: 0,
                    textShadow: `${isInside ? '1px 1px 2px' : '0px 0px 0px'} black`
                }}
            >
                {point.name}
            </p>
            <h1
                className={isInside && 'is-inside'}
                style={{
                    color: isInside ? 'white' : 'black',
                    fontSize: '.5em',
                    padding: 0,
                    margin: 0,
                    textShadow: `${isInside ? '1px 1px 2px' : '0px 0px 0px'} black`
                }}
            >
                {`${Number.isInteger(point.y) ? point.y : point.y.toString().substring(0, point.y.toString().indexOf('.') + 3)}%`}
            </h1>
        </div>
    );

    return renderToString(Component);
};


const useContainer = props => {
    const {
        sendRefChart,
        tableFilter,
        graphic
    } = props;

    const {
        showLegend = true,
        forceOutside,
    } = graphic || {};

    const chart = useRef();
    const prevTableFilter = usePrevious(tableFilter);
    const { t } = useTranslation();

    const createSlice = ({ name, value, color, valuesSum }) => {

        const slice = {
            name: t(name),
            y: value,
            sliced: true,
            selected: true,
            color: EVENTS_COLORS[name] ? EVENTS_COLORS[name] : color,
            dataLabels: {
                useHTML: true,
                distance: !forceOutside ? ((value / valuesSum > .15) ? -40 : 40) : undefined,
                formatter: function () { return formatLabel(this.point, !forceOutside && (value / valuesSum > .15), this.series.chart); }
            }
        };

        if (prevTableFilter) {
            slice.visible = !prevTableFilter.find(f => name === f);
        }

        return slice
    }

    const optionsBase = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            styledMode: true,
            type: 'pie'
        },
        legend: {
            enabled: showLegend
        },
        title: {
            text: ''
        },
        tooltip: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: false,
                cursor: 'arrow',
                showInLegend: true
            },
            series: {
                point: {
                    events: {
                        legendItemClick: function (e) {
                            if (props.filterTable) {
                                props.filterTable(this.name, this.visible);
                                e.preventDefault();
                            }
                        }
                    }
                }
            }
        },
        series: [{
            name: 'Percentuals',
            colorByPoint: true,
            data: []
        }],
        credits: {
            enabled: false
        },
        exporting: {
            enabled: true
        }
    };

    const [options, setOptions] = useState(optionsBase);

    const setSlices = useCallback(
        info => {
            try {
                const valuesSum = info.map(slice => slice.value || 0).reduce((acc, curr) => acc + curr);
                const newOptions = {
                    ...options,
                    series: [{
                        ...options.series[0],
                        data: info.map(slice => createSlice({ ...slice, valuesSum }))
                    }]
                };
                setOptions(newOptions);
            } catch (err) {
                console.error('Could not create pie chart, initial data is wrong.')
            }
        },
        [
            options,
            setOptions
        ]
    );

    useEffect(
        () => sendRefChart(chart),
        [
            chart
        ]
    );

    useEffect(() => {
        if (tableFilter && chart && chart.current && chart.current.chart) {
            chart.current.chart.series[0].points.forEach(point => {
                point.update({ visible: !tableFilter.find(f => point.name === f) }, false);
            });
            chart.current.chart.redraw();
        }
    }, [tableFilter, chart, options]);

    return ({
        chart,
        options,
        setSlices
    });
};

export default useContainer;